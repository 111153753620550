import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import "./style.css";

const AboutDownload = () => {
    return (
        <Box sx={{ flexGrow: 1, background: '#0CA8F6' }}>
            <Box className='mainContainer' sx={{marginBottom: '0'}}>
                <Grid container spacing={2} sx={{ pt: 3 }}>
                    <Grid item xs={12} sm={6} md={6} lg={6}  sx={{display: 'flex', alignItems: 'end'}}>
                        <img src='assets/images/downloadApp.png' alt='downloadApp'/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} className='GridbottomDivSocil'  sx={{ textAlign: 'center',}}>
                        <div className='bottomDivSocil'>
                            <Typography className='downLoadTypo'>Download the app now </Typography>
                            <Typography className='neverMissTypo'>Never miss any entertainment!</Typography>
                            <Stack direction="row" spacing={3} sx={{ display: 'block', mt: 2 }} className='imagesStoreS'>
                                <img src='assets/images/googleplay.png' alt='googleImg'  className='mtLeftMargin'/>
                                <img src='assets/images/app Gallery.png' alt='appGalImg' className='mtLeftMargin'/>
                                <img src='assets/images/appstore.png' alt='appStoreImg' className='mtLeftMargin'/>
                            </Stack>
                        </div>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default AboutDownload