import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import TextField from '@mui/material/TextField';
import "./style.css";


const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2 },
  { width: 768, itemsToShow: 3 },
  { width: 1200, itemsToShow: 5 }
];



const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(0),
  color: theme.palette.text.secondary,
}));


const SearchBar = () => {
  return (
    <Box sx={{ flexGrow: 1 }} className="mainContainer">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography className='SearchResultTypo'>Search Result  : 
          <TextField
          id="standard-helperText"          
          defaultValue="Search Keyword"          
          variant="standard"
          sx={{marginLeft: '10px'}}
        /></Typography>
        </Grid>


        <Grid item lg={3} xs={12} md={3} sm={3} sx={{ width: '100%' }}>
          <Item sx={{ width: '100%' }} className='boxShadowNone'>
            <div className='CardDiv' style={{ position: 'relative' }}>
              <CardMedia
                component="img"
                image="assets/images/Rectangle 19.png"
                alt="Paella dish"
              />
              <IconButton aria-label="add to favorites" style={{ backgroundColor: '#2C2C2C', color: '#FFFFFF', position: 'absolute', right: '8px', bottom: '8px' }} className='AbsoluteClass'>
                <FavoriteBorderIcon />
              </IconButton>

            </div>
            <CardContent style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between', padding: '0', marginTop: '2%' }}>
              <Typography style={{ fontWeight: '700', fontSize: '18px', color: '#2C2C2C', lineHeight: '21.6px' }}>
                Family Fun-Day
                Kaygee The Vibe
              </Typography>
              <IconButton aria-label="share">
                <ShareOutlinedIcon />
              </IconButton>
            </CardContent>
            <Typography style={{ fontWeight: '400', fontSize: '14px', color: '#686868', padding: '10px 0px' }}>
              Sat, 09 Jul 11:30 PM
            </Typography>
          </Item>
        </Grid>

        <Grid item lg={3} xs={12} md={3} sm={3} sx={{ width: '100%' }}>
          <Item sx={{ width: '100%' }} className='boxShadowNone'>
            <div className='CardDiv' style={{ position: 'relative' }}>
              <CardMedia
                component="img"
                image="assets/images/Rectangle 19.png"
                alt="Paella dish"
              />
              <IconButton aria-label="add to favorites" style={{ backgroundColor: '#2C2C2C', color: '#FFFFFF', position: 'absolute', right: '8px', bottom: '8px' }} className='AbsoluteClass'>
                <FavoriteBorderIcon />
              </IconButton>

            </div>
            <CardContent style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between', padding: '0', marginTop: '2%' }}>
              <Typography style={{ fontWeight: '700', fontSize: '18px', color: '#2C2C2C', lineHeight: '21.6px' }}>
                Family Fun-Day
                Kaygee The Vibe
              </Typography>
              <IconButton aria-label="share">
                <ShareOutlinedIcon />
              </IconButton>
            </CardContent>
            <Typography style={{ fontWeight: '400', fontSize: '14px', color: '#686868', padding: '10px 0px' }}>
              Sat, 09 Jul 11:30 PM
            </Typography>
          </Item>
        </Grid>

        <Grid item lg={3} xs={12} md={3} sm={3} sx={{ width: '100%' }}>
          <Item sx={{ width: '100%' }} className='boxShadowNone'>
            <div className='CardDiv' style={{ position: 'relative' }}>
              <CardMedia
                component="img"
                image="assets/images/Rectangle 19.png"
                alt="Paella dish"
              />
              <IconButton aria-label="add to favorites" style={{ backgroundColor: '#2C2C2C', color: '#FFFFFF', position: 'absolute', right: '8px', bottom: '8px' }} className='AbsoluteClass'>
                <FavoriteBorderIcon />
              </IconButton>

            </div>
            <CardContent style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between', padding: '0', marginTop: '2%' }}>
              <Typography style={{ fontWeight: '700', fontSize: '18px', color: '#2C2C2C', lineHeight: '21.6px' }}>
                Family Fun-Day
                Kaygee The Vibe
              </Typography>
              <IconButton aria-label="share">
                <ShareOutlinedIcon />
              </IconButton>
            </CardContent>
            <Typography style={{ fontWeight: '400', fontSize: '14px', color: '#686868', padding: '10px 0px' }}>
              Sat, 09 Jul 11:30 PM
            </Typography>
          </Item>
        </Grid>

        <Grid item lg={3} xs={12} md={3} sm={3} sx={{ width: '100%' }}>
          <Item sx={{ width: '100%' }} className='boxShadowNone'>
            <div className='CardDiv' style={{ position: 'relative' }}>
              <CardMedia
                component="img"
                image="assets/images/Rectangle 19.png"
                alt="Paella dish"
              />
              <IconButton aria-label="add to favorites" style={{ backgroundColor: '#2C2C2C', color: '#FFFFFF', position: 'absolute', right: '8px', bottom: '8px' }} className='AbsoluteClass'>
                <FavoriteBorderIcon />
              </IconButton>

            </div>
            <CardContent style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between', padding: '0', marginTop: '2%' }}>
              <Typography style={{ fontWeight: '700', fontSize: '18px', color: '#2C2C2C', lineHeight: '21.6px' }}>
                Family Fun-Day
                Kaygee The Vibe
              </Typography>
              <IconButton aria-label="share">
                <ShareOutlinedIcon />
              </IconButton>
            </CardContent>
            <Typography style={{ fontWeight: '400', fontSize: '14px', color: '#686868', padding: '10px 0px' }}>
              Sat, 09 Jul 11:30 PM
            </Typography>
          </Item>
        </Grid>

        <Grid item lg={3} xs={12} md={3} sm={3} sx={{ width: '100%' }}>
          <Item sx={{ width: '100%' }} className='boxShadowNone'>
            <div className='CardDiv' style={{ position: 'relative' }}>
              <CardMedia
                component="img"
                image="assets/images/Rectangle 19.png"
                alt="Paella dish"
              />
              <IconButton aria-label="add to favorites" style={{ backgroundColor: '#2C2C2C', color: '#FFFFFF', position: 'absolute', right: '8px', bottom: '8px' }} className='AbsoluteClass'>
                <FavoriteBorderIcon />
              </IconButton>

            </div>
            <CardContent style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between', padding: '0', marginTop: '2%' }}>
              <Typography style={{ fontWeight: '700', fontSize: '18px', color: '#2C2C2C', lineHeight: '21.6px' }}>
                Family Fun-Day
                Kaygee The Vibe
              </Typography>
              <IconButton aria-label="share">
                <ShareOutlinedIcon />
              </IconButton>
            </CardContent>
            <Typography style={{ fontWeight: '400', fontSize: '14px', color: '#686868', padding: '10px 0px' }}>
              Sat, 09 Jul 11:30 PM
            </Typography>
          </Item>
        </Grid>

        <Grid item lg={3} xs={12} md={3} sm={3} sx={{ width: '100%' }}>
          <Item sx={{ width: '100%' }} className='boxShadowNone'>
            <div className='CardDiv' style={{ position: 'relative' }}>
              <CardMedia
                component="img"
                image="assets/images/Rectangle 19.png"
                alt="Paella dish"
              />
              <IconButton aria-label="add to favorites" style={{ backgroundColor: '#2C2C2C', color: '#FFFFFF', position: 'absolute', right: '8px', bottom: '8px' }} className='AbsoluteClass'>
                <FavoriteBorderIcon />
              </IconButton>

            </div>
            <CardContent style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between', padding: '0', marginTop: '2%' }}>
              <Typography style={{ fontWeight: '700', fontSize: '18px', color: '#2C2C2C', lineHeight: '21.6px' }}>
                Family Fun-Day
                Kaygee The Vibe
              </Typography>
              <IconButton aria-label="share">
                <ShareOutlinedIcon />
              </IconButton>
            </CardContent>
            <Typography style={{ fontWeight: '400', fontSize: '14px', color: '#686868', padding: '10px 0px' }}>
              Sat, 09 Jul 11:30 PM
            </Typography>
          </Item>
        </Grid>

        <Grid item lg={3} xs={12} md={3} sm={3} sx={{ width: '100%' }}>
          <Item sx={{ width: '100%' }} className='boxShadowNone'>
            <div className='CardDiv' style={{ position: 'relative' }}>
              <CardMedia
                component="img"
                image="assets/images/Rectangle 19.png"
                alt="Paella dish"
              />
              <IconButton aria-label="add to favorites" style={{ backgroundColor: '#2C2C2C', color: '#FFFFFF', position: 'absolute', right: '8px', bottom: '8px' }} className='AbsoluteClass'>
                <FavoriteBorderIcon />
              </IconButton>

            </div>
            <CardContent style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between', padding: '0', marginTop: '2%' }}>
              <Typography style={{ fontWeight: '700', fontSize: '18px', color: '#2C2C2C', lineHeight: '21.6px' }}>
                Family Fun-Day
                Kaygee The Vibe
              </Typography>
              <IconButton aria-label="share">
                <ShareOutlinedIcon />
              </IconButton>
            </CardContent>
            <Typography style={{ fontWeight: '400', fontSize: '14px', color: '#686868', padding: '10px 0px' }}>
              Sat, 09 Jul 11:30 PM
            </Typography>
          </Item>
        </Grid>
        <Grid item lg={3} xs={12} md={3} sm={3} sx={{ width: '100%' }}>
          <Item sx={{ width: '100%' }} className='boxShadowNone'>
            <div className='CardDiv' style={{ position: 'relative' }}>
              <CardMedia
                component="img"
                image="assets/images/Rectangle 19.png"
                alt="Paella dish"
              />
              <IconButton aria-label="add to favorites" style={{ backgroundColor: '#2C2C2C', color: '#FFFFFF', position: 'absolute', right: '8px', bottom: '8px' }} className='AbsoluteClass'>
                <FavoriteBorderIcon />
              </IconButton>

            </div>
            <CardContent style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between', padding: '0', marginTop: '2%' }}>
              <Typography style={{ fontWeight: '700', fontSize: '18px', color: '#2C2C2C', lineHeight: '21.6px' }}>
                Family Fun-Day
                Kaygee The Vibe
              </Typography>
              <IconButton aria-label="share">
                <ShareOutlinedIcon />
              </IconButton>
            </CardContent>
            <Typography style={{ fontWeight: '400', fontSize: '14px', color: '#686868', padding: '10px 0px' }}>
              Sat, 09 Jul 11:30 PM
            </Typography>
          </Item>
        </Grid>

        <Grid item lg={3} xs={12} md={3} sm={3} sx={{ width: '100%' }}>
          <Item sx={{ width: '100%' }} className='boxShadowNone'>
            <div className='CardDiv' style={{ position: 'relative' }}>
              <CardMedia
                component="img"
                image="assets/images/Rectangle 19.png"
                alt="Paella dish"
              />
              <IconButton aria-label="add to favorites" style={{ backgroundColor: '#2C2C2C', color: '#FFFFFF', position: 'absolute', right: '8px', bottom: '8px' }} className='AbsoluteClass'>
                <FavoriteBorderIcon />
              </IconButton>

            </div>
            <CardContent style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between', padding: '0', marginTop: '2%' }}>
              <Typography style={{ fontWeight: '700', fontSize: '18px', color: '#2C2C2C', lineHeight: '21.6px' }}>
                Family Fun-Day
                Kaygee The Vibe
              </Typography>
              <IconButton aria-label="share">
                <ShareOutlinedIcon />
              </IconButton>
            </CardContent>
            <Typography style={{ fontWeight: '400', fontSize: '14px', color: '#686868', padding: '10px 0px' }}>
              Sat, 09 Jul 11:30 PM
            </Typography>
          </Item>
        </Grid>

        <Grid item lg={3} xs={12} md={3} sm={3} sx={{ width: '100%' }}>
          <Item sx={{ width: '100%' }} className='boxShadowNone'>
            <div className='CardDiv' style={{ position: 'relative' }}>
              <CardMedia
                component="img"
                image="assets/images/Rectangle 19.png"
                alt="Paella dish"
              />
              <IconButton aria-label="add to favorites" style={{ backgroundColor: '#2C2C2C', color: '#FFFFFF', position: 'absolute', right: '8px', bottom: '8px' }} className='AbsoluteClass'>
                <FavoriteBorderIcon />
              </IconButton>

            </div>
            <CardContent style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between', padding: '0', marginTop: '2%' }}>
              <Typography style={{ fontWeight: '700', fontSize: '18px', color: '#2C2C2C', lineHeight: '21.6px' }}>
                Family Fun-Day
                Kaygee The Vibe
              </Typography>
              <IconButton aria-label="share">
                <ShareOutlinedIcon />
              </IconButton>
            </CardContent>
            <Typography style={{ fontWeight: '400', fontSize: '14px', color: '#686868', padding: '10px 0px' }}>
              Sat, 09 Jul 11:30 PM
            </Typography>
          </Item>
        </Grid>
      </Grid>
    </Box>
  )
}

export default SearchBar