import React from 'react'
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Box } from '@mui/system';
import { Grid, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ListItem from '@mui/material/ListItem';
import Button from '@mui/material/Button';
import {useTranslation} from 'react-i18next'
import "./style.css";
import Navbar from '../../Components/Navbar';


const VenueList = () => {
    const {t} = useTranslation('common')
    return (
        <div style={{}}>
            <Navbar/>
            <Box sx={{ flexGrow: 1, margin: 'auto', fontFamily: 'Lato', padding: '20px' }} className='commonWidth'>
                <Grid container spacing={2.5} className='MobResp'>

                    <Grid item md={12} sm={12} sx={{ textAlign: 'center' }} className='positionRel'>
                        <CloseIcon className='closeIcon' />
                        <Typography className='VenueListTypo'>Venue List</Typography>
                        <div className='hrLine'></div>
                    </Grid>
                </Grid>

                <Grid container spacing={2.5} sx={{padding: '20px 0px'}}>
                    <Grid item md={10}>
                        <Typography className='streetTypo'>2503 Ode Street</Typography>
                        <List>
                            <ListItem disablePadding>
                                <ListItemButton className='leftPaadingRm'>
                                    <ListItemIcon>
                                        {/* <InboxIcon /> */}
                                        <img src='assets/images/calander.png' alt='' ></img>
                                    </ListItemIcon>
                                    {/* <ListItemText primary="Sat, 09 Jul 2022, 11:30 PM" /> */}
                                    <Typography className='commonTypoText'>Sat, 09 Jul 2022, 11:30 PM</Typography>
                                </ListItemButton>
                            </ListItem>

                            <ListItem disablePadding>
                                <ListItemButton className='leftPaadingRm'>
                                    <ListItemIcon>
                                        {/* <InboxIcon /> */}
                                        <img src='assets/images/location.png' alt=''></img>
                                    </ListItemIcon>
                                    {/* <ListItemText primary="Sat, 09 Jul 2022, 11:30 PM" /> */}
                                    <Typography className='commonTypoText'>2503 Ode Street, Maokeng Ext, Thembisa, Tembisa</Typography>
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid item md={2} className='mapViewGrid'>
                        <Button className='mapBtn'>
                            <img src='assets/images/Vector 7.png' className='mapImg' alt=''></img>
                            <Typography>{t('Map_View')}</Typography>
                        </Button>
                    </Grid>                    
                </Grid>
                <div className='hrLine'></div>

                <Grid container spacing={2.5} sx={{padding: '20px 0px'}}>
                    <Grid item md={10}>
                        <Typography className='streetTypo'>2503 Ode Street</Typography>
                        <List>
                            <ListItem disablePadding>
                                <ListItemButton className='leftPaadingRm'>
                                    <ListItemIcon>
                                        {/* <InboxIcon /> */}
                                        <img src='assets/images/calander.png' alt=''></img>
                                    </ListItemIcon>
                                    {/* <ListItemText primary="Sat, 09 Jul 2022, 11:30 PM" /> */}
                                    <Typography className='commonTypoText'>Sat, 09 Jul 2022, 11:30 PM</Typography>
                                </ListItemButton>
                            </ListItem>

                            <ListItem disablePadding>
                                <ListItemButton className='leftPaadingRm'>
                                    <ListItemIcon>
                                        {/* <InboxIcon /> */}
                                        <img src='assets/images/location.png' alt=''></img>
                                    </ListItemIcon>
                                    {/* <ListItemText primary="Sat, 09 Jul 2022, 11:30 PM" /> */}
                                    <Typography className='commonTypoText'>2503 Ode Street, Maokeng Ext, Thembisa, Tembisa</Typography>
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid item md={2} className='mapViewGrid'>
                        <Button className='mapBtn'>
                            <img src='assets/images/Vector 7.png' className='mapImg' alt=''></img>
                            <Typography>{t('Map_View')}</Typography>
                        </Button>
                    </Grid>                    
                </Grid>
                <div className='hrLine'></div>

                <Grid container spacing={2.5} sx={{padding: '20px 0px'}}>
                    <Grid item md={10}>
                        <Typography className='streetTypo'>2503 Ode Street</Typography>
                        <List>
                            <ListItem disablePadding>
                                <ListItemButton className='leftPaadingRm'>
                                    <ListItemIcon>
                                        {/* <InboxIcon /> */}
                                        <img src='assets/images/calander.png' alt='' ></img>
                                    </ListItemIcon>
                                    {/* <ListItemText primary="Sat, 09 Jul 2022, 11:30 PM" /> */}
                                    <Typography className='commonTypoText'>Sat, 09 Jul 2022, 11:30 PM</Typography>
                                </ListItemButton>
                            </ListItem>

                            <ListItem disablePadding>
                                <ListItemButton className='leftPaadingRm'>
                                    <ListItemIcon>
                                        {/* <InboxIcon /> */}
                                        <img src='assets/images/location.png' alt='' ></img>
                                    </ListItemIcon>
                                    {/* <ListItemText primary="Sat, 09 Jul 2022, 11:30 PM" /> */}
                                    <Typography className='commonTypoText'>2503 Ode Street, Maokeng Ext, Thembisa, Tembisa</Typography>
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid item md={2} className='mapViewGrid'>
                        <Button className='mapBtn'>
                            <img src='assets/images/Vector 7.png' className='mapImg' alt='' ></img>
                            <Typography>{t('Map_View')}</Typography>
                        </Button>
                    </Grid>                    
                </Grid>
                <div className='hrLine'></div>
                <Grid container spacing={2.5} sx={{padding: '20px 0px'}}>
                    <Grid item md={10}>
                        <Typography className='streetTypo'>2503 Ode Street</Typography>
                        <List>
                            <ListItem disablePadding>
                                <ListItemButton className='leftPaadingRm'>
                                    <ListItemIcon>
                                        {/* <InboxIcon /> */}
                                        <img src='assets/images/calander.png' alt='' ></img>
                                    </ListItemIcon>
                                    {/* <ListItemText primary="Sat, 09 Jul 2022, 11:30 PM" /> */}
                                    <Typography className='commonTypoText'>Sat, 09 Jul 2022, 11:30 PM</Typography>
                                </ListItemButton>
                            </ListItem>

                            <ListItem disablePadding>
                                <ListItemButton className='leftPaadingRm'>
                                    <ListItemIcon>
                                        {/* <InboxIcon /> */}
                                        <img src='assets/images/location.png' alt='' ></img>
                                    </ListItemIcon>
                                    {/* <ListItemText primary="Sat, 09 Jul 2022, 11:30 PM" /> */}
                                    <Typography className='commonTypoText'>2503 Ode Street, Maokeng Ext, Thembisa, Tembisa</Typography>
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid item md={2} className='mapViewGrid'>
                        <Button className='mapBtn'>
                            <img src='assets/images/Vector 7.png' className='mapImg' alt='' ></img>
                            <Typography>{t('Map_View')}</Typography>
                        </Button>
                    </Grid>                    
                </Grid>
                <div className='hrLine'></div>
            </Box>
        </div>
    )
}

export default VenueList