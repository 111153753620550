/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef } from 'react'
import { Link, useLocation, useNavigate } from "react-router-dom";


export default function SlidingContainer(props) {
    const closeRef = useRef()
    const navigate = useNavigate();

    const HandleMenuBar = (url) => {
        navigate(`${url}`);
        closeRef.current.click()
      };
     
    const handleLogin = ()=>{
        props.handleOpen()
        closeRef.current.click()
    }  

    const handlelogout = ()=>{
        props.logoutUser()
        closeRef.current.click()
    } 
    
  return (
    <>
        {/* <!-- Floating Container (for top-right menus) Start --> */}
        <div className="gridlove-sidebar-action-overlay"></div>

        <div className="gridlove-sidebar-action-wrapper">
            <span ref={closeRef} className="gridlove-action-close" role="img" aria-label="X lg - large preview"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"></path>
            </svg></span>

            <div className="gridlove-sidebar-action-inside">
                {/* <!-- ['My Favourites' Sliding Container] --> */}

                {/* <!-- ['Menu' Sliding Container] --> */}
                <div className="glsa-section-menu">
                    <div className="d-flex align-items-center justify-content-between mb-3">
                        <h2 className="fw-semi-bold">{props.lagT('hey')}! { props.isloading && localStorage.getItem('updated_profile') ? JSON.parse(localStorage.getItem('updated_profile')).fullname   : props.userName }</h2>
                        {props.isloading && <img
            src={  props.UserAvatar  ?  props.UserAvatar : "assets/images/profile.png"}
            alt="profile"
            className="userProfile"
          />}
                        {!props.isloading && <a className="btn btn-primary py-2 px-4" onClick={handleLogin}>{props.lagT('sign_in')}</a>}
                    </div>
                    
                    <h3 className="mb-3 pb-2 fw-regular">{props.lagT('menu')}</h3>
                    
                    <div className="hidden-lg-up widget gridlove-box widget_nav_menu">
                    <ul className="tr-menu-list">
                            {props.isloading && <><li className="tr-menu-item"><a onClick={() => HandleMenuBar("/MyProfile")}><span className="trmi-icon"><img src="assets/images/userIcon.png" alt="my-profile"/></span> {props.lagT('my_profile')}</a></li>
                            <li className="tr-menu-item"><a onClick={() => HandleMenuBar("/MyBooking")}><span className="trmi-icon"><img src="assets/images/profile-icon/my-bookings.png" alt="my-bookings"/></span> {props.lagT('my_bookings')}</a></li>
                            <li className="tr-menu-item"><a onClick={() => HandleMenuBar("/MyWallet")}><span className="trmi-icon"><img src="assets/images/profile-icon/ticketkore-wallet.png" alt="wallet"/></span> {props.lagT('wallet')}</a></li>
                            <li className="tr-menu-item"><a onClick={() => HandleMenuBar("/Offers")}><span className="trmi-icon"><img src="assets/images/profile-icon/offers.png" alt="Offers"/></span> {props.lagT('offers')}</a></li>
                            <li className="tr-menu-item"><a onClick={() => HandleMenuBar("/MyFavorite")}><span className="trmi-icon"><img src="assets/images/profile-icon/my-favorites.png" alt="MyFavorite"/></span> {props.lagT('my_favorites')}</a></li>
                            <li className="tr-menu-item"><a onClick={props.handleClickOpenNotification}><span className="trmi-icon"><img src="assets/images/notification.png" alt="notification"/></span> {props.lagT('notification')}</a></li>
                            </>
                            }
                            <li className="tr-menu-item"><a onClick={() => HandleMenuBar("/aboutus")}><span className="trmi-icon"><img src="assets/images/profile-icon/company.png" alt="User"/></span> {props.lagT('compay')}</a></li>
                            <li className="tr-menu-item"><a onClick={() => HandleMenuBar("/solution")}><span className="trmi-icon"><img src="assets/images/profile-icon/solutions.png" alt="Solutions"/></span> {props.lagT('solutions')}</a></li>
                            <li className="tr-menu-item"><a onClick={() => HandleMenuBar("/Partnership")}><span className="trmi-icon"><img src="assets/images/profile-icon/partnership.png" alt="Partnership"/></span> {props.lagT('patnership')}</a></li>
                            <li className="tr-menu-item"><a onClick={() => HandleMenuBar("/Features")}><span className="trmi-icon"><img src="assets/images/profile-icon/features.png" alt="Features"/></span> {props.lagT('features')}</a></li>
                            <li className="tr-menu-item"><a onClick={() => HandleMenuBar("/hardware")}><span className="trmi-icon"><img src="assets/images/profile-icon/hardware.png" alt="hardware"/></span> {props.lagT('hardware')}</a></li>
                            <li className="tr-menu-item"><a onClick={() => HandleMenuBar("/Buy")}><span className="trmi-icon"><img src="assets/images/profile-icon/buy.png" alt="buy"/></span> {props.lagT('buy')}</a></li>
                            <li className="tr-menu-item"><a onClick={() => HandleMenuBar("/howitworks")}><span className="trmi-icon"><img src="assets/images/profile-icon/getstarted.png" alt="getstarted"/></span> {props.lagT('get_started')}</a></li>
                            <li className="tr-menu-item"><a onClick={() => HandleMenuBar("/pricing")}><span className="trmi-icon"><img src="assets/images/profile-icon/price-tag.png" alt="Pricing"/></span> {props.lagT('pricing')}</a></li>
                            <li className="tr-menu-item"><a onClick={() => HandleMenuBar("/termscondition")}><span className="trmi-icon"><img src="assets/images/terms.png" alt="Terms of use"/></span> {props.lagT('term_of_use')}</a></li>
                            <li className="tr-menu-item"><div className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" data-bs-toggle="dropdown"><span className="trmi-icon"><img src="assets/images/client_login.png" alt="vendor" /></span>
                                {props.lagT('client_login')}</a>
                                <div className="dropdown-menu border-light m-0">
                                    <a href="https://ticketkore.io/admin/vendor"
                                     target="_blank"
                                     rel ='noreferrer'
                                     onClick={()=>{
                                     setTimeout(()=>{
                                     window.location.reload()
                                      },500)
                                     }} className="dropdown-item">{props.lagT('vendor_login')}</a>
                                    <a href="https://ticketkore.io/admin/organizer"
              target="_blank"
              style={{ textDecoration: "none" }}
              rel ='noreferrer'
              onClick={()=>{
                setTimeout(()=>{
                  window.location.reload()
                },500)}} className="dropdown-item">{props.lagT('organizer_login')}</a>
                                </div>
                            </div></li>
                            <li className="tr-menu-item"><a onClick={() => HandleMenuBar("/contactus")}><span className="trmi-icon"><img src="assets/images/profile-icon/contact.png" alt="Contact Us"/></span>{props.lagT('contact_us')}</a></li>
                            <li className="tr-menu-item"><a onClick={props.submit}><span className="trmi-icon"><img src="assets/images/profile-icon/delete-account.png" alt="Delete Account"/></span>{props.lagT('delete_account')}</a></li>   
                            {props.isloading && <li className="tr-menu-item"><a onClick={handlelogout}><span className="trmi-icon"><img src="assets/images/profile-icon/logout.png" alt="Logout"/></span>{props.lagT('logout')}</a></li>}  
                        </ul>
                    </div>
                </div>
            </div>


        </div>
        {/* <!-- Floating Container (for top-right menus) End --></ul> */}
        
    </>
  )
}