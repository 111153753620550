/* Function name : checkValidationFormAllControls;
 * (Use for function for all common validation
 * check field validation use
 * range,required,min,max,email,phone,alphabet,number,password
 * and confirm password matching,password format);
 * Parameter:pass the full control;
 * return error object with true and false;
 */
export function checkValidationFormAllControls(
  controls,
  check,
  arrayData = []
) {
  var i = 0;
  var msg = "";
  var errorMessageObject = {};
  var pattern = "";
  if (arrayData.length > 0) {
    for (i = 0; i < controls.length; i++) {
      //var ngReflectName=controls.attributes.indexOf("ng-reflect-name");
      //Check All Required filled based on the all controlles required property
      if (controls[i].required && !controls[i].value) {
        errorMessageObject[controls[i].id] =
          controls[i].id.replace(/_/g, " ").charAt(0).toUpperCase() +
          controls[i].id
            .replace(/_\d+/g, " ")
            .slice(1)
            .replace(/[^a-zA-Z ]/g, " ")
            .replace(/  +/g, " ") +
          "is required.";
      }
      //Check All required filled based on type=radio input box
      else if (controls[i].type === "radio" && !controls[i].validity.valid) {
        errorMessageObject[controls[i].id] =
          controls[i].name.replace(/_/g, " ").charAt(0).toUpperCase() +
          controls[i].name
            .replace(/_\d+/g, " ")
            .slice(1)
            .replace(/[^a-zA-Z ]/g, " ")
            .replace(/  +/g, " ") +
          "is required.";
      } else if (
        controls[i].type === "checkbox" &&
        !controls[i].validity.valid
      ) {
        errorMessageObject[controls[i].id] =
          controls[i].name.replace(/_/g, " ").charAt(0).toUpperCase() +
          controls[i].name
            .replace(/_/g, " ")
            .slice(1)
            .replace(/[^a-zA-Z ]/g, " ")
            .replace(/  +/g, " ") +
          "is required.";
      }
    }
  } else if (!controls.length) {
    errorMessageObject = {};

    //Check All Required filled based on the all controlles required property
    if (controls.required && !controls.value) {
      errorMessageObject[controls.name] =
        controls.name.replace(/_/g, " ").charAt(0).toUpperCase() +
        controls.name.replace(/_/g, " ").slice(1) +
        " is required.";
    }
    //Check All required filled based on type=radio input box
    else if (controls.type === "radio" && !controls.validity.valid) {
      errorMessageObject[controls.name] =
        controls.name.replace(/_/g, " ").charAt(0).toUpperCase() +
        controls.name.replace(/_/g, " ").slice(1) +
        " is required.";
    } else if (controls.type === "file" && !controls.validity.valid) {
      errorMessageObject[controls.name] =
        controls.name.replace(/_/g, " ").charAt(0).toUpperCase() +
        controls.name.replace(/_/g, " ").slice(1) +
        " is required.";
    } else if (controls.type === "file" && controls.validity.valid) {
      if (controls.getAttribute("validFileExtention")) {
        if (
          controls
            .getAttribute("validFileExtention")
            .split(",")
            .indexOf(controls.value.split(".").pop()) !== -1
        ) {
        } else {
          errorMessageObject[controls.name] = "only support pdf and image";
        }
        //return filename.split('.').pop();
        //errorMessageObject[controls.name] =
      }
    }
    //Check Email required and email format filled based on type=email input box
    else if (controls.type === "email" && controls.value && controls.name) {
      //var pattern = new RegExp(controls.pattern);
      //check pattern of email
      pattern = /^\w+([-]?\w+)*@\w+([-]?\w+)*(\w{2,4})+$/;
      if (pattern.test(controls.value)) {
      }
      //just for testing
      else if (
        controls.name === "email_id_or_mobile_number" &&
        !controls.value
      ) {
        errorMessageObject[controls.name] =
          "Please enter a valid " +
          controls.name.replace(/_/g, " ").charAt(0) +
          controls.name.replace(/_/g, " ").slice(1) +
          ". Mobile number should be of 10 digits.";
      }

      //end
      else if (controls.name === "email_id_or_mobile_number") {
        errorMessageObject[controls.name] =
          "Please enter a valid " +
          controls.name.replace(/_/g, " ").charAt(0) +
          controls.name.replace(/_/g, " ").slice(1) +
          ". Mobile number should be of 10 digits.";
      } else {
        errorMessageObject[controls.name] =
          controls.name.replace(/_/g, " ").charAt(0) +
          controls.name.replace(/_/g, " ").slice(1) +
          " address incorrect.";
      }
    }
    //Check Password required and password format filled based on type=password input box
    else if (controls.type === "password" && controls.value) {
      pattern = new RegExp(controls.pattern);
      if (pattern.test(controls.value)) {
        if (controls.getAttribute("match")) {
          if (
            controls[i - 2].id ===
              controls.getAttribute("match").split(",")[0] &&
            controls.id === controls.getAttribute("match").split(",")[1]
          ) {
            if (controls[i - 2].value !== controls.value) {
              errorMessageObject[controls.name] =
                controls
                  .getAttribute("match")
                  .split(",")[0]
                  .replace(/_/g, " ")
                  .charAt(0)
                  .toUpperCase() +
                controls
                  .getAttribute("match")
                  .split(",")[0]
                  .replace(/_/g, " ")
                  .slice(1) +
                " and " +
                controls
                  .getAttribute("match")
                  .split(",")[1]
                  .replace(/_/g, " ")
                  .charAt(0)
                  .toUpperCase() +
                controls
                  .getAttribute("match")
                  .split(",")[1]
                  .replace(/_/g, " ")
                  .slice(1) +
                " is not same.";
            }
          }
        }
      } else {
        errorMessageObject[controls.name] =
          controls.name.replace(/_/g, " ").charAt(0).toUpperCase() +
          controls.name.replace(/_/g, " ").slice(1) +
          " must be alphanumeric and minimum of 8 digits. It is mandatory to use numbers and special characters in the password.";
      }
    } else if (controls.type === "text" && controls.value && controls.name) {
      msg = "Invalid";
      if (controls.getAttribute("message")) {
        msg = controls.getAttribute("message");
      }
      pattern = new RegExp(controls.pattern);

      if (pattern.test(controls.value)) {
      } else if (controls.name === "Aadhaar_number") {
        errorMessageObject[controls.name] =
          controls.name.replace(/_/g, " ") +
          " " +
          "should be of" +
          " " +
          controls.maxLength +
          " digits.";
      } else if (controls.name === "Driving_licence_number") {
        errorMessageObject[controls.name] =
          controls.name.replace(/_/g, " ") +
          " " +
          "should be of" +
          " " +
          controls.maxLength +
          " digits.";
      } else if (controls.name === "Passport_number") {
        errorMessageObject[controls.name] =
          controls.name.replace(/_/g, " ") +
          " " +
          "should be of" +
          " " +
          controls.maxLength +
          " digits.";
      } else {
        errorMessageObject[controls.name] =
          msg +
          " " +
          controls.name.replace(/_/g, " ").charAt(0) +
          controls.name.replace(/_/g, " ").slice(1) +
          ".";
      }
    }

    //check All pattern based on type = tel filed
    else if (controls.type === "tel" && controls.value) {
      if (controls.value.length !== controls.maxLength) {
        // Please enter a valid mobile number/email id. Mobile number should be of 10 digits.
        // Email id or mobile number is not valid 10 digit number.
        errorMessageObject[controls.name] =
          "Please enter a valid " +
          controls.name.replace(/_/g, " ").charAt(0) +
          controls.name.replace(/_/g, " ").slice(1) +
          "." + 
          controls.name.replace(/_/g, " ").charAt(0).toUpperCase() +
          controls.name.replace(/_/g, " ").slice(1) +
          " should be of " +
          controls.maxLength +
          " digits.";
      }
      msg = " is not valid format.";

      if (controls.getAttribute("message")) {
        msg = controls.getAttribute("message");
      }
      pattern = new RegExp(controls.pattern);

      if (pattern.test(controls.value)) {
      } else {
        errorMessageObject[controls.name] =
          controls.name.replace(/_/g, " ").charAt(0).toUpperCase() +
          controls.name.replace(/_/g, " ").slice(1) +
          " " +
          msg;
      }
    } else if (controls.type === "checkbox" && !controls.validity.valid) {
      errorMessageObject[controls.name] =
        controls.name.replace(/_/g, " ").charAt(0).toUpperCase() +
        controls.name.replace(/_/g, " ").slice(1) +
        " is required.";
    }
  } else {
    errorMessageObject = {};
    for (i = 0; i < controls.length; i++) {
      //Check All Required filled based on the all controlles required property
      if (controls[i].required && !controls[i].value) {
        errorMessageObject[controls[i].name] =
          controls[i].name.replace(/_/g, " ").charAt(0).toUpperCase() +
          controls[i].name.replace(/_/g, " ").slice(1) +
          " is required.";
      }
      //Check All required filled based on type=radio input box
      else if (controls[i].type === "radio" && !controls[i].validity.valid) {
        errorMessageObject[controls[i].name] =
          controls[i].name.replace(/_/g, " ").charAt(0).toUpperCase() +
          controls[i].name.replace(/_/g, " ").slice(1) +
          " is required.";
      } else if (controls[i].type === "file" && !controls[i].validity.valid) {
        errorMessageObject[controls[i].name] =
          controls[i].name.replace(/_/g, " ").charAt(0).toUpperCase() +
          controls[i].name.replace(/_/g, " ").slice(1) +
          " is required.";
      } else if (controls[i].type === "file" && controls[i].validity.valid) {
        if (controls[i].getAttribute("validFileExtention")) {
          if (
            controls[i]
              .getAttribute("validFileExtention")
              .split(",")
              .indexOf(controls[i].value.split(".").pop()) !== -1
          ) {
          } else {
            errorMessageObject[controls[i].name] = "only support pdf and image";
          }
          //return filename.split('.').pop();
          //errorMessageObject[controls[i].name] =
        }
      }
      //Check Email required and email format filled based on type=email input box
      else if (
        controls[i].type === "email" &&
        controls[i].value &&
        controls[i].name
      ) {
        //var pattern = new RegExp(controls[i].pattern);
        //check pattern of email
        pattern = /^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,4})+$/;
        if (pattern.test(controls[i].value)) {
        }
        //just for testing
        else if (
          controls[i].name === "email_id_or_mobile_number" &&
          !controls[i].value
        ) {
          errorMessageObject[controls[i].name] =
            "Please enter a valid " +
            controls[i].name.replace(/_/g, " ").charAt(0) +
            controls[i].name.replace(/_/g, " ").slice(1) +
            ". Mobile number should be of 10 digits.";
        }

        //end
        else if (controls[i].name === "email_id_or_mobile_number") {
          errorMessageObject[controls[i].name] =
            "Please enter a valid " +
            controls[i].name.replace(/_/g, " ").charAt(0) +
            controls[i].name.replace(/_/g, " ").slice(1) +
            ". Mobile number should be of 10 digits.";
        } else {
          errorMessageObject[controls[i].name] =
            controls[i].name.replace(/_/g, " ").charAt(0).toUpperCase() +
            controls[i].name.replace(/_/g, " ").slice(1) +
            " address incorrect.";

          //errorMessageObject[controls[i].name] = 'Enter valid '+controls[i].name.replace(/_/g, ' ').charAt(0)+ controls[i].name.replace(/_/g, ' ').slice(1) + " address.";
        }
      }
      //Check Password required and password format filled based on type=password input box
      else if (controls[i].type === "password" && controls[i].value) {
        pattern = new RegExp(controls[i].pattern);
        if (pattern.test(controls[i].value)) {
          if (controls[i].getAttribute("match")) {
            if (
              controls[i - 2].id ===
                controls[i].getAttribute("match").split(",")[0] &&
              controls[i].id === controls[i].getAttribute("match").split(",")[1]
            ) {
              if (controls[i - 2].value !== controls[i].value) {
                errorMessageObject[controls[i].name] =
                  controls[i]
                    .getAttribute("match")
                    .split(",")[0]
                    .replace(/_/g, " ")
                    .charAt(0)
                    .toUpperCase() +
                  controls[i]
                    .getAttribute("match")
                    .split(",")[0]
                    .replace(/_/g, " ")
                    .slice(1) +
                  " and " +
                  controls[i]
                    .getAttribute("match")
                    .split(",")[1]
                    .replace(/_/g, " ")
                    .charAt(0)
                    .toUpperCase() +
                  controls[i]
                    .getAttribute("match")
                    .split(",")[1]
                    .replace(/_/g, " ")
                    .slice(1) +
                  " is not same.";
              }
            }
          }
        } else {
          errorMessageObject[controls[i].name] =
            controls[i].name.replace(/_/g, " ").charAt(0).toUpperCase() +
            controls[i].name.replace(/_/g, " ").slice(1) +
            " must be alphanumeric and minimum of 8 digits. It is mandatory to use numbers and special characters in the password.";
        }
      } else if (
        controls[i].type === "text" &&
        controls[i].value &&
        controls[i].name
      ) {
        msg = "Invalid";
        if (controls[i].getAttribute("message")) {
          msg = controls[i].getAttribute("message");
        }
        pattern = new RegExp(controls[i].pattern);

        if (pattern.test(controls[i].value)) {
        } else if (controls[i].name === "Aadhaar_number") {
          errorMessageObject[controls[i].name] =
            controls[i].name.replace(/_/g, " ") +
            " " +
            "should be of" +
            " " +
            controls[i].maxLength +
            " digits.";
        } else if (controls[i].name === "Driving_licence_number") {
          errorMessageObject[controls[i].name] =
            controls[i].name.replace(/_/g, " ") +
            " " +
            "should be of" +
            " " +
            controls[i].maxLength +
            " digits.";
        } else if (controls[i].name === "Passport_number") {
          errorMessageObject[controls[i].name] =
            controls[i].name.replace(/_/g, " ") +
            " " +
            "should be of" +
            " " +
            controls[i].maxLength +
            " digits.";
        } else {
          errorMessageObject[controls[i].name] =
            msg +
            " " +
            controls[i].name.replace(/_/g, " ").charAt(0) +
            controls[i].name.replace(/_/g, " ").slice(1) +
            ".";
        }
      }

      //check All pattern based on type = tel filed
      else if (controls[i].type === "tel" && controls[i].value) {
        if (
          controls[i].value.length < controls[i].minLength ||
          controls[i].value.length > controls[i].maxLength
        ) {
          errorMessageObject[controls[i].name] =
            "Please enter a valid " +
            controls[i].name.replace(/_/g, " ").charAt(0) +
            controls[i].name.replace(/_/g, " ").slice(1) +
            ". " +  
            controls[i].name.replace(/_/g, " ").charAt(0).toUpperCase() +
            controls[i].name.replace(/_/g, " ").slice(1) +
            " should be between " +
            controls[i].minLength +
            " and " +
            controls[i].maxLength +
            " digits.";
        }
        msg = " is not valid format.";

        if (controls[i].getAttribute("message")) {
          msg = controls[i].getAttribute("message");
        }
        var pattern = new RegExp(controls[i].pattern);

        if (pattern.test(controls[i].value)) {
          // errorMessageObject[controls[i].name] = controls[i].name.replace(/_/g, ' ').charAt(0).toUpperCase() + controls[i].name.replace(/_/g, ' ').slice(1) +' '+ msg;
        } else {
          errorMessageObject[controls[i].name] =
            controls[i].name.replace(/_/g, " ").charAt(0).toUpperCase() +
            controls[i].name.replace(/_/g, " ").slice(1) +
            " " +
            msg;
        }
      } else if (
        controls[i].type === "checkbox" &&
        !controls[i].validity.valid
      ) {
        errorMessageObject[controls[i].name] =
          controls[i].name.replace(/_/g, " ").charAt(0).toUpperCase() +
          controls[i].name.replace(/_/g, " ").slice(1) +
          " is required.";
      }
    }
  }

  //   if (Object.keys(errorMessageObject).length !=== 0) {
  //     errorMessageObject["valid"] = true;
  //   } else {
  //     errorMessageObject["valid"] = false;
  //   }

  //   if(this.errorMultipleFormValidationMessageObject.length>0)
  //   {

  //     return this.errorMultipleFormValidationMessageObject;
  //   }
  if (Object.keys(errorMessageObject).length !== 0) {
    errorMessageObject["valid"] = true;
  } else {
    errorMessageObject["valid"] = false;
  }
  return errorMessageObject;
}

export default checkValidationFormAllControls;