import React from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Button, Typography } from '@mui/material'
import "./style.css";
import { Link } from 'react-router-dom';

const PageNotFoundComponent = () => {
  return (
    <Box className='mainContainer'>
      <Grid container spacing={2} className='pageNotGrid'>
        <Grid item xs={12} sm={12} md={12} sx={{textAlign: 'center'}}>
          <Typography className='pageNotTypo'>Page Not Found</Typography>
          <img src='assets/images/notfound.png' alt='page_notFound' className='page_notFoundImg'/>
          <div className='backToHomeDiv'>
            <Link to="/" style={{textDecoration: "none"}}><Button className='backToHomeBtn'>  Back to Home   </Button></Link>
          </div>
        </Grid>
      </Grid>
    </Box>
  )
}

export default PageNotFoundComponent