import React from 'react'
import Box from "@mui/material/Box";
import Grid from '@mui/material/Grid';
import "./style.css";
import { Typography } from '@mui/material';
import Button from "@mui/material/Button";



const SeatBookingComponent = () => {
  return (
    <Box className="mainContainer">
      <Grid container spacing={2} className="seatIoGrid">
        <Grid item xs={7} sm={9} md={10} lg={10}>
          <Typography className='seatIoGridTypo1'>Phomolong Comes Alive with Mashabela</Typography>
          <Typography className='seatIoGridTypo2'>Cape Town, Western Cape South Africa  I  Sun, 25 Oct at 11:00 PM </Typography>
        </Grid>
        <Grid item xs={5} sm={3} md={2} lg={2} className="SeatBtn_Grid">
          <div className='seat_io_Div'>
            <img src='assets/images/seat_io_img.png' alt='ticket' />
            <button className='seat_io_Btn'>1 Ticket(s)</button>
          </div>
        </Grid>
        <div className='hrlineBtm'></div>

        <Grid item xs={12} sm={12} md={12} lg={12} className='seatBookingImgIO'>
          <img src='assets/images/ticket_io_book.png' alt='ticket' />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} className='seatBtnProced'>
<Button className='prcdBtnSeatIO'>
Proceed
</Button>
        </Grid>
      </Grid>
    </Box>
  )
}

export default SeatBookingComponent