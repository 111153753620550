import React from 'react'
import { Box, Card } from '@mui/material'
import { Grid } from '@mui/material'
import { Typography } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check';
import {useTranslation} from 'react-i18next'
import './style.css'


const FeaturesDetails = () => {
    const {t} = useTranslation("common")
    return (
        <Box className='mobRmMarginFeat' sx={{ marginBottom: '80px' }}>
            <Box className='mainContainer'>
                <Grid container spacing={2} sx={{ paddingTop: '35px' }}>
                    <Grid item xs={12} sm={6} md={6} style={{ paddingLeft: '15px' }}>
                        <Typography className='featPlatf'><span style={{ borderBottom: '2px solid #0CA8F6' }}>{t("Platform")}</span></Typography>
                        <Typography className='featuOur'>{t(`Our approach to the ticketing technology was inspired by more decade worth of experience in live experience, has resulted in the smart ticketing platform for tomorrow`)}.</Typography>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} style={{ paddingLeft: '15px', textAlign: 'center' }}>
                        <img src='assets/images/featureImgN.png' alt='feature' />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} style={{paddingBottom:'30px' }}>
                        <Typography className='featuTypoMiddle'>{t(`Event End-To-End Ecosystem Features For Tomorrow`)}.</Typography>
                    </Grid>
                </Grid>
            </Box>

            <Box>
                <Grid container spacing={2} sx={{ paddingTop: '35px' }}>
                    <Grid item xs={12} sm={6} md={6} style={{ background: '#FBEAFF' }}>
                        <div className='disF_AligC'>
                            <img src='assets/images/vendorImg.png' alt='vendor' />
                            <div className='newDivConCommon'>
                                <Typography className='vendorTypoF'>{t("Vendor_Management")}</Typography>
                                <Typography className='vendorTypoC'>{t("Appoint and manage your event vendors with ease on the platform")}.</Typography>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} style={{ background: '' }}>
                        <div className='disF_AligC'>
                            <img src='assets/images/badges.png' alt='badges' />
                            <div className='newDivConCommon'>
                                <Typography className='vendorTypoF'>{t("Badges")}</Typography>
                                <Typography className='vendorTypoC'>{t("Design and print event badges for your attendees")}.</Typography>
                            </div>
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} style={{ background: '' }}>
                        <div className='disF_AligC'>
                            <img src='assets/images/eventMgtImg.png' alt='eventMgt' />
                            <div className='newDivConCommon'>
                                <Typography className='vendorTypoF'>{t("Event_Management_Tools")}</Typography>
                                <Typography className='vendorTypoC'>{t("Access the tools and support to create and list your event on our platform with ease in just few minutes")}.</Typography>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} style={{ background: '#EAFFF9' }}>
                        <div className='disF_AligC'>
                            <img src='assets/images/audienceCheck.png' alt='audienceCheck' />
                            <div className='newDivConCommon'>
                                <Typography className='vendorTypoF'>{t("Audience Check-In")}</Typography>
                                <Typography className='vendorTypoC'>{t("Give your audience the unmatched experience with our android mobile scanning app")}.</Typography>
                            </div>
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} style={{ background: '#EAEBFF' }}>
                        <div className='disF_AligC'>
                            <img src='assets/images/walletimg.png' alt='wallet' />
                            <div className='newDivConCommon'>
                                <Typography className='vendorTypoF'>{t("Wallet")}</Typography>
                                <Typography className='vendorTypoC'>{t(`Provide cashless wallet to attendee for the seamless acquisition of goods
                                    from event vendors`)}.</Typography>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} style={{ background: '' }}>
                        <div className='disF_AligC'>
                            <img src='assets/images/accessCtrl.png' alt='vendor' />
                            <div className='newDivConCommon'>
                                <Typography className='vendorTypoF'>{t("Access_Control")}</Typography>
                                <Typography className='vendorTypoC'>{t(`Control access to any area of the venue with our integrated zoning
                                    functionality`)}.</Typography>
                            </div>
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} style={{ background: '' }}>
                        <div className='disF_AligC'>
                            <img src='assets/images/box_Pos.png' alt='box_Pos' />
                            <div className='newDivConCommon'>
                                <Typography className='vendorTypoF'>{t("Box Office Pos")}</Typography>
                                <Typography className='vendorTypoC'>{t(`Ticketkore Smart POS gives your event the edge and allows you to securely accept any form of payments at the door and print thermal tickets with QR Code`)}.</Typography>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} style={{ background: '#FFFEEA' }}>
                        <div className='disF_AligC'>
                            <img src='assets/images/international.png' alt='vendor' />
                            <div className='newDivConCommon'>
                                <Typography className='vendorTypoF'>{t("International Ticketing")}</Typography>
                                <Typography className='vendorTypoC'>{t(`Create and sell event tickets in multiple currencies and countries from single platform. Give attendees an option to pay with the currency they are familiar with`)}.</Typography>
                            </div>
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} style={{ background: '#FFF1EA' }}>
                        <div className='disF_AligC'>
                            <img src='assets/images/talent.png' alt='talent' />
                            <div className='newDivConCommon'>
                                <Typography className='vendorTypoF'>{t("Talent Engagement")}</Typography>
                                <Typography className='vendorTypoC'>{t(`Talent search shows entrant registration and voting which rely on audience feedback for voting designed great experience and happy users`)}.</Typography>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} style={{ background: '' }}>
                        <div className='disF_AligC'>
                            <img src='assets/images/seating_map.png' alt='seating_map' />
                            <div className='newDivConCommon'>
                                <Typography className='vendorTypoF'>{t("Seating Map")}</Typography>
                                <Typography className='vendorTypoC'>{t(`Multi-Layered seating map allows attendees to select their own preferred seats with build in social distancing optimization`)}.</Typography>
                            </div>
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} style={{ background: '' }}>
                        <div className='disF_AligC'>
                            <img src='assets/images/dataimg.png' alt='dataAnalysis' />
                            <div className='newDivConCommon'>
                                <Typography className='vendorTypoF'>{t("Data Analytics")}</Typography>
                                <Typography className='vendorTypoC'>{t("The audience tracked is integrated with Google Analytics for ease reporting in real time. The organiser will receive real time updates of customer behaviour, letting them know what is happening at the event")}.</Typography>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} style={{ background: '#FFEAEF' }}>
                        <div className='disF_AligC'>
                            <img src='assets/images/New_Rfid.png' alt='New_Rfid' />
                            <div className='newDivConCommon'>
                                <Typography className='vendorTypoF'>{t("RFID")}</Typography>
                                <Typography className='vendorTypoC'>{t(`Issue tags to attendees for a fully contactless experience With the adoption of
the NFC technologies, the audience does not need to carry cash or bank cards. All
payments can be done via the NFC enabled wristband by scanning the wristband
with POS terminal devices at the counter`)}.</Typography>
                            </div>
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} style={{ background: '#EAEBFF' }}>
                        <div className='disF_AligC'>
                            <img src='assets/images/digitalimg.png' alt='digital' />
                            <div className='newDivConCommon'>
                                <Typography className='vendorTypoF'>{t("Digital_Ticketing")}</Typography>
                                <Typography className='vendorTypoC'>{t(`Digital ticketing is the new normal.  Digital ticketing powers the contactless entry, and gives creators access to a powerful intelligence to know who is in the venue at any given time and convenience for the audience`)}.</Typography>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} style={{ background: '' }}>
                        <div className='disF_AligC'>
                            <img src='assets/images/marketing.png' alt='marketing' />
                            <div className='newDivConCommon'>
                                <Typography className='vendorTypoF'>{t('Marketing_and_Social_Media')}</Typography>
                                <Typography className='vendorTypoC'>{t(`Expand your event reach with our integrated social media engagement solutions, integrated with your Ticketkore. With over 2.3 billion monthly active social media users you can definitely find customers on a platform of your choice and sell where your customers are`)}.</Typography>
                            </div>
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} style={{ background: '' }}>
                        <div className='disF_AligC'>
                            <img src='assets/images/marketing.png' alt='marketing' />
                            <div className='newDivConCommon'>
                                <Typography className='vendorTypoF'>{t("Exclusivity And Privacy (RSVP)")}</Typography>
                                <Typography className='vendorTypoC'>{t("Create and manage")} <span style={{ color: '#0CA8F6' }}> {t("BY-INVITATION ONLY")} </span>{t("events. Simply send invitation code to those selected few to rsvp or buy tickets to your event")}.</Typography>

                                <Typography className='vendorTypoC'>{t("Track event invitees from invitation with RSVP link to to check-in. The Ticketkore turnkey platform takes care of your event invitation to guest list management, and it has the following features")}:
                                </Typography>
                                <div className='displayFlexNew'>
                                    <div className='displayFlexWidth'>
                                        <Typography className='newCheckIcon' sx={{ display: 'flex', alignItem: 'center' }}><CheckIcon sx={{ color: 'rgba(12, 168, 246, 1)', paddingRight: '10px' }} /> {t("Private Event Listing and Security")}</Typography>
                                        <Typography className='newCheckIcon' sx={{ display: 'flex', alignItem: 'center' }}><CheckIcon sx={{ color: 'rgba(12, 168, 246, 1)', paddingRight: '10px'  }} /> {t("Guest list management")}</Typography>
                                        <Typography className='newCheckIcon' sx={{ display: 'flex', alignItem: 'center' }}><CheckIcon sx={{ color: 'rgba(12, 168, 246, 1)', paddingRight: '10px'  }} /> {t("Email and SMS Invitation")}</Typography>
                                    </div>
                                    <div className='displayFlexWidth'>
                                        <Typography className='newCheckIcon' sx={{ display: 'flex', alignItem: 'center' }}><CheckIcon sx={{ color: 'rgba(12, 168, 246, 1)', paddingRight: '10px'  }} /> {t("Seating")}</Typography>
                                        <Typography className='newCheckIcon' sx={{display: 'flex', alignItem: 'center'}}><CheckIcon sx={{color: 'rgba(12, 168, 246, 1)', paddingRight: '10px' }}/> {t("Guest Check in")}</Typography>

                                    </div>
                                </div>
                            </div>


                        </div>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default FeaturesDetails