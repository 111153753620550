import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";


import Button from "@mui/material/Button";
import { Alert, Avatar, FormControlLabel, Snackbar, Stack } from "@mui/material";
import RadioGroup from "@mui/material/RadioGroup";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import http from "../../Services/http";
import Modal from "@mui/material/Modal";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import getSymbolFromCurrency from "currency-symbol-map";
import HTMLReactParser from "html-react-parser";
import Backdrop from "@mui/material/Backdrop";
import DoneIcon from '@mui/icons-material/Done';
import "./style.css";
import TextField from '@mui/material/TextField';
import {useTranslation} from 'react-i18next'
import { useForm } from "react-hook-form";



import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { CalendarPicker } from '@mui/x-date-pickers/CalendarPicker';


//loader and toast
import { NotificationManager } from "react-notifications";
import { SpinnerCircular } from "spinners-react";

//components
import FloorPlan from "../FloorPlan";
// import CloseIcon from "@mui/icons-material/Close";
import parse from "html-react-parser";
import ShowLocationDates from "../ShowlocationDate&TimePopUp";
 
// import Backdrop from "@mui/material/Backdrop";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "620px !important",
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  p: 3,
  width: "95%",
};
// Notify Css
const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "500px",
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  p: 3,
  width: "95%",
};


function TabPanel(props) {
  
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  if (index == 1) {
  }
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function SelectLocation(props) {
  const {t} = useTranslation('common')
  const [openNotify, setOpenNotify] = React.useState(false);

  const [organisationCheck, setOrganisationCheck] = useState(false)
  const handleCloseNotify = () => {
    setOpenNotify(false)

  } 
  const [openCompanyModel, setopenCompanyModel] = React.useState(false);
  const handleopenCompanyModel = () => setopenCompanyModel(true);
  const handleCloseCompanyModel = () => setopenCompanyModel(false);
  const [alertbtn, setalertBtn] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [selLocID, setSelLocID] = useState("")
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const eventD = location.state.eventdetailspage1.eventdetailspage1;
  const [Privateeventbookticketdata, setPrivateLocationTime] = useState(
    location.state.eventdetailspage1.eventdetailspage1
  );
console.log(eventD)
  const [selectedBookticketdata, setselectedBookticketdata] = useState({});
  const [selectedTicketData, setselectedTicketData] = useState({});

  const [Eventsid, setEventsid] = useState(Privateeventbookticketdata.id);
  const [count, setCount] = React.useState(0);
  const [selectedValue, setSelectedValue] = React.useState("");
  const [selectedTimeValue, setSelectedTimeValue] = React.useState("");

  const [selectedTimeDate, setSelectedTimeDate] = React.useState("");

  const [value, setValue] = React.useState(0);
  const [first, setFirst] = React.useState(true);
  const [second, setSecond] = React.useState(false);
  const [third, setThird] = React.useState(false);

  const [stepOne, setstepOne] = React.useState(`${t("Select_Location")}`);
  const [stepTwo, setsteptwo] = React.useState(`${t("Select_Time")}`);
  const [stepTwoDuration, setstepTwoDuration] = React.useState("");
  const [stepThird, setstepThird] = React.useState(`${t("Seat_Booking")}`);
useEffect(()=>{
  setstepOne(`${t("Select_Location")}`)
  setsteptwo(`${t("Select_Time")}`)
  setstepThird(`${t("Seat_Booking")}`)
},[t])
  const [stepTimeOnSelectRadio, setTimeOnSelectRadio] = React.useState([]);
  const [objValue, setobjValue] = React.useState([]);
  // console.log(objValue , 'obj')
  const [totalTicketAmount, setTotalTicketAmount] = React.useState(0);

  // const [countAddson, setCountAddson] = React.useState(0);
  const [bookingData, setbookingData] = React.useState([]);
  const [privateEventBookingCheck, setLocationExist] = React.useState(0);
  const [remainingQty, setRemainingQty] = React.useState(0);
  // const [remainAddonQty, setRemainingAddonQty] = React.useState(0);

  const [selectedPrice, setSelectedPrice] = React.useState(0);
  const [selectedId, setSelectedTicketId] = React.useState(0);
  const [ticketFree, setTicketFree] = React.useState(0);
  const [Addon, setAddon] = useState([]);

  const [chillerCount, setchillerCount] = useState(0);
  const [TempServiceArr, setTempServiceArr] = useState([]);
  // const [totalAddon, setTotalAddon] = useState(0);
  const [holdingAddons, setHoldingAddons] = useState([]);
  const [checkPrivateEvent, setcheckPrivateEvent] = useState(false);
  const [freeAddonQty, setFreeAdonQty] = useState(0);
  const [isRadioCheck, setIsRadioCheck] = useState(false);
  // addons total
  const [totalTicketAmountChillers, setTotalTicketAmountChillers] =
    React.useState(0);
  const [openFloorPlan, setOpenFloorPlan] = useState(false);

  const [openalert, setOpenAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");






  const [date, setDate] = React.useState(dayjs());



  const disableDates = (date) => {
    let datevalue = date.date();
    let activeDates = []
    eventD.allEventLocationAndDateTime[selctedTimeandDate].date_list.forEach((active_dates) => {
      activeDates.push(parseInt(active_dates.slice(8, 10)))
    })
    return !activeDates.includes(datevalue)
  }



 

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  //organisation check
  const OrganisationCount = () => {
    setCount(count + 1)
    getTotalPrice(count + 1);
    setOrganisationCheck(true)
    handleCloseNotify() 
    // IncreaseTicketCount()
  }


  // seat.io data

  // const [selSeatLabel , setSelSeatLabel] = useState([])
  // const [selSeatLabels , setSelSeatLabels] = useState([])
  // const [holdToken , setHoldToken] = useState('')



  const EditTickets = (data) => {
    setOpenFloorPlan(data);
    // setUpcomingData(check);
  };
  const [openDate, setOpenDate] = useState(false)
  const [selectedLocationData ,setSelectedLocationData] = useState({})
  const handleOpenDates = (data) => {
    setOpenDate(true)
    setSelectedLocationData(data)
    
  }
  const handleCloseDate = () => {
    setOpenDate(false)
  }



  const [RadioID, setRadioID] = useState(0);
  const [callFromSeatIo, setCallFromSeatIo] = useState(false);
  const releaseChart = () => {
    let selSeatLabel = localStorage.getItem("label")
      ? JSON.parse(localStorage.getItem("label"))
      : "";
    let Chart_Id = localStorage.getItem("Chart_Id")
      ? JSON.parse(localStorage.getItem("Chart_Id"))
      : "";
    let postData = {
      objects: String(selSeatLabel.map((lable) => lable.label)),
      chart_event_id: Chart_Id,
    };
    http("orders/releaseChartObjects", "POST", postData).then((res) => {
      if (res.data.status === 1) {
        localStorage.removeItem("label");
        localStorage.removeItem("labels");
        localStorage.removeItem("holdToken");
        localStorage.removeItem("Chart_Id");
        //  setOpenFloorPlan(true)
      }
    });
  };
  useEffect(() => {
    if (parseInt(Privateeventbookticketdata.event_social_type) === 1) {
      setcheckPrivateEvent(false);
    } else {
      let arrAlltimewithlocation = [];

      Privateeventbookticketdata.allEventLocationAndDateTime.forEach((res) => {
        res.event_date_time.forEach((rest) => {
          rest.time.forEach((ele) => {
            ele.datet = rest.date;
            ele.location = res;
            arrAlltimewithlocation.push(ele);
          });
        });
      });
      let arrBookTimeSlot = {};
      arrBookTimeSlot.allEventLocationAndDateTime = [];

      arrAlltimewithlocation.forEach((res) => {
        arrBookTimeSlot.allEventLocationAndDateTime.push(res);
      });
      setPrivateLocationTime(arrBookTimeSlot);
      setcheckPrivateEvent(true);
    }

    updateSeatBooking()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const updateSeatBooking = ()=>{
    if(eventD.upgradeSeatDetails){
      eventD.allEventLocationAndDateTime.forEach((data,idx) =>{
        if(data.venue_address === eventD.venue_address){

          funcSelectValue(idx)
          //
          // data?.event_date_time.forEach((data1,index1)=>{
          //   if(data1.date === eventD.event_date){
          //     data1?.time.forEach((data2,index2)=>{
          //       if(data2.time === eventD.event_time){
          //         setTimeOnSelectRadio(
          //           Privateeventbookticketdata.allEventLocationAndDateTime[index1]
          //             .event_date_time
          //         )
          //         setsteptwo(
          //           dateFormated(stepTimeOnSelectRadio[index1].date) +
          //           "\n" +
          //           stepTimeOnSelectRadio[index1].time[index2].time
          //         );
          //       }
          //     })
          //   }
          // })
         
    //       setSecond(true);
    // setOpen1(false);
    // setValue(2);
    // setbookingData(eventD.upgradeSeatDetails)
        }

      })
  }
  }
  useEffect(()=>{
    if(eventD.upgradeSeatDetails && stepTimeOnSelectRadio.length > 0){
      eventD.allEventLocationAndDateTime.forEach((data,idx) =>{
        if(data.venue_address === eventD.venue_address){
          data?.event_date_time.forEach((data1,index1)=>{
            if(data1.date === eventD.event_date){
              data1?.time.forEach((data2,index2)=>{
                if(data2.time === eventD.event_time){
                  funcSelectTimeValue(index1,index2)
                }
              })
            }
          })
         
          setSecond(true);
    setOpen1(false);
    setValue(2);
        }

      })
  }
  },[stepTimeOnSelectRadio])

  useEffect(()=>{
    if(eventD.upgradeSeatDetails){
      setbookingData(eventD.upgradeSeatDetails)
    }
  },bookingData)
  /**get date event date format by date  */
  const dateFormated = (date) => {
    let dateNew = new Date(date);

    let day = dateNew.toLocaleString("en-us", { weekday: "short" });
    let newdate = new Date(date).getDate();
    let month = dateNew.toLocaleString("default", { month: "short" });
    return day + "," + newdate + " " + month;
  };
  //calender
  const [selctedTimeandDate, setSelectedTimeandDate] = useState(0);
  const [selectedDate, setSelectedDate] = React.useState(null);

  const { register, handleSubmit, formState: { errors }, } = useForm();


  const [open, setOpen] = React.useState(false);

  //
  const [open1, setOpen1] = React.useState(false);
  // const handleOpen1 = () => setOpen1(true);
  const handleClose1 = (indx, index) => {
    setRadioID(0);
    setLocIndVal (-1)
    setIsRadioCheck(false);
    setOpen1(false);
    // setValue(0);
  };

  const handleOpen = () => {
    setchillerCount(0);
    setTotalTicketAmountChillers(0);
    setOpen(true);
    setTempServiceArr([]);
  };
  const handleClose = () => {
    setOpen(false);
  };

  /**get date event date format by date  */
  // const dateFormat = (date) => {
  //   let dateNew = new Date(date);

  //   let day = dateNew.toLocaleString("en-us", { weekday: "short" });
  //   let newdate = new Date(date).getDate();
  //   let month = dateNew.toLocaleString("default", { month: "short" });
  //   return day + "," + newdate + " " + month;
  // };

  // 
  const notifyTicket = (ticketID) => {

    let postData = {
      event_id: params.id,
      location_id: selLocID,
      ticket_id: ticketID
    }
    http("ticket_soldout_notifications/notify_me", "POST", postData).then((res) => {

      if (res.data.status) {

        let obj = { location_id: selLocID }
        getAllTicketByLocationByIdByEventId(obj)
        NotificationManager.success(res.data.msg)
      }

      else {
        NotificationManager.error(res.data.msg)
      }
    })
  }

  // Func select Value
  const [LocIndVal,setLocIndVal] = useState(-1)
  const funcSelectValue = (index) => {
 
    setLocIndVal(index)
    // console.log(index , 'value of radio btn')
    setDate('')
    setSelectedTimeandDate(index)
 

    if (checkPrivateEvent || eventD.multiday_scan === "1") {

      checkPrivateEvent ? setSelectedTimeDate(
        dateFormated(
          Privateeventbookticketdata.allEventLocationAndDateTime[index].datet
        ) +
        "\n" +
        Privateeventbookticketdata.allEventLocationAndDateTime[index].time
      ) : <></>

      eventD.multiday_scan === "1" && !checkPrivateEvent ? setSelectedTimeValue(Privateeventbookticketdata.allEventLocationAndDateTime[index].event_date_time[0].time[0]) : setSelectedTimeValue({
        location_id:
          Privateeventbookticketdata.allEventLocationAndDateTime[index]
            .location_id,
      });
      setobjValue(
        Privateeventbookticketdata.allEventLocationAndDateTime[index]
      )

      checkPrivateEvent ? Privateeventbookticketdata.allEventLocationAndDateTime[
        index
      ].location.event_name = eventD.event_name : <></>

     

      checkPrivateEvent ? setselectedBookticketdata(
        Privateeventbookticketdata.allEventLocationAndDateTime[index].location
      ) : <></>
      checkPrivateEvent ? setstepOne(
        Privateeventbookticketdata.allEventLocationAndDateTime[index].location
          .venue_address
      ) : setstepOne(
        Privateeventbookticketdata.allEventLocationAndDateTime[index].venue_address
      )
      checkPrivateEvent ? setSelectedValue(
        Privateeventbookticketdata.allEventLocationAndDateTime[index].location
          .venue_address
      ) : <></>

      let obj = {};

      obj.location_id =
        Privateeventbookticketdata.allEventLocationAndDateTime[
          index
        ].location_id;
      eventD.multiday_scan === "1" && !checkPrivateEvent ? getAllTicketByLocationByIdByEventId(Privateeventbookticketdata.allEventLocationAndDateTime[index].event_date_time[0].time[0]) : getAllTicketByLocationByIdByEventId(obj);
      setOpen1(true);
      // setValue(2);
      // setFirst(true);
      // setThird(true);
    } else {
      setobjValue(
        Privateeventbookticketdata.allEventLocationAndDateTime[index]
      );
      setSelectedValue(
        Privateeventbookticketdata.allEventLocationAndDateTime[index]
          .venue_address
      );

      setstepOne(
        Privateeventbookticketdata.allEventLocationAndDateTime[index].venue_address
      );
      setTimeOnSelectRadio(
        Privateeventbookticketdata.allEventLocationAndDateTime[index]
          .event_date_time
      );
      setValue(1);
      // setSecond(true);
    }

    //setsteptwo(Privateeventbookticketdata.allEventLocationAndDateTime[index].event_date_time[0].date);
  };

  // Continue

  const funcSelectTimeValue = (index, newindex) => {
    setIsRadioCheck(true);
    setSelectedTimeValue(stepTimeOnSelectRadio[index].time[newindex]);
    setsteptwo(
      dateFormated(stepTimeOnSelectRadio[index].date) +
      "\n" +
      stepTimeOnSelectRadio[index].time[newindex].time
    );

    setSelectedTimeDate(
      dateFormated(stepTimeOnSelectRadio[index].date) +
      "\n" +
      stepTimeOnSelectRadio[index].time[newindex].time
    );
    setstepTwoDuration(stepTimeOnSelectRadio[index].time[newindex].duration);
    getAllTicketByLocationByIdByEventId(
      stepTimeOnSelectRadio[index].time[newindex]
    );
    setOpen1(true);
    // setValue(2);
    // setSecond(true);
  };



  // fvb
  const TermsConditionPopup = () => {
    setSecond(true);
    setOpen1(false);
    setValue(2);
  };
  
  //Add button function
  const addQtyStateChange = (e, index) => {
    const clone = [...bookingData];
    if (count === 0 || selectedId !== clone[index].id) {
      setOrganisationCheck(false)
    }
    setCount(1);
    clone.map((e) => (e.addqty = false));
    clone[index].addqty = true;
    setselectedTicketData(clone[index]);
    setSelectedTicketId(clone[index].id);
    setSelectedPrice(clone[index].ticket_price);
    setTicketFree(clone[index].is_ticket_free);
    setTotalTicketAmount(1 * clone[index].ticket_price);

    if (
      parseInt(clone[index].ticket_charges_status) === 2 &&
      parseInt(clone[index].event_social_type) === 2
    ) {
      setRemainingQty(1);
      setAlertMsg("Only 1 Ticket(s) Book");
      setOpenAlert(true);
    } else if (parseInt(clone[index].grouping_allowed) === parseInt(clone[index].remaining_quantity)) {
      setAlertMsg("Only 1 Ticket(s) Book");
      setOpenAlert(true);
    } else {
      setRemainingQty(
        parseInt(clone[index].remaining_quantity) >= parseInt(clone[index].max_ticket_limit)
          ? parseInt(clone[index].max_ticket_limit)
          : parseInt(clone[index].remaining_quantity)
      );
    }

    setbookingData(() => [...clone]);
  };

  const getTotalPrice = (countnum) => {
    setTotalTicketAmount(countnum * selectedPrice);
  };

  const [maxlimit, setMaxLimit] = useState(0)
  const IncreaseTicketCount = (max_limit, remainQty, groupallowed) => {
    setMaxLimit(max_limit)
    // {parseInt(element.grouping_allowed) === 0 }


    if (count >= parseInt(max_limit) && !organisationCheck && parseInt(groupallowed)) {
      setOpenNotify(true)


      return
    } else if (!parseInt(groupallowed)) {
      setCount(count + 1)
      if (count + 1 >= remainQty || !parseInt(groupallowed) && count + 1 >= parseInt(max_limit)) {
        setOpenAlert(true);
        setAlertMsg(`you can book ${max_limit} tickets in one order`);
      }
    }
    if (count + 1 >= remainQty) {
      setOpenAlert(true);
      setAlertMsg("No more ticket availabel to book in this seat category");
    }
    setCount(count + 1);
    getTotalPrice(count + 1);

    // if (count + 1 === remainQty) {
    //   // if(count + 1 >= remainingQty){
    //   setAlertMsg(
    //     "you can book 20 tickets (including all seat category type) in one order"
    //   );
    //   setOpenAlert(true);
    //   // }
    // } else {
    //   count + 1 >= remainQty ? (
    //     NotificationManager.info(
    //       "No more ticket availabel to book in this seat category"
    //     )
    //   ) : (
    //     <></>
    //   );
    // }
  };
  const handleSkipChange = () => {
    setCompanyName('')
    setCompanyAddress('')
    setCompanyNumber('')
    handleCloseCompanyModel()
    getAddons()

  }
  // Upcoming events data
  const getAllTicketByLocationByIdByEventId = (obj) => {

    let postData = {
      eventid: params.id,
      locationid: obj.location_id,
    };
    setSelLocID(obj.location_id)
    http("events/getAllTicketsByEventId", "POST", postData)
      .then((res) => {
        const data = res.data.data ?? [];
        data.map((e) => (e.addqty = false));
        if (data.length > 1) {
          setLocationExist(0);
        } else {
          const isExist = data[0].location_order_exists
            ? data[0].location_order_exists
            : 0;
          setLocationExist(isExist);
          data[0].event_social_type === "2" &&
            data[0].ticket_charges_status === "2"
            ? setFreeAdonQty(data[0].private_free_addon_qty)
            : setFreeAdonQty(0);
        }
        setbookingData(data);
      })
      .catch((err) => { });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 1) {
      // setFirst(false);

      setOpen1(true);

      // setSecond(true);
      setCount(0);

      setRemainingQty(0);
      // setThird(false);
    } else if (newValue == 2) {
      setFirst(false);
      setOpen1(false);
      // setSecond(false);
      setThird(true);
      getAllTicketByLocationByIdByEventId();
    } else {
      setFirst(true);
      setOpen1(false);
      // setSecond(false);
      setsteptwo("Select Time");
      setstepTwoDuration("");
      setSecond(false);
      setThird(false);
      setCount(0);
      setRemainingQty(0);
      setRadioID(0);
      setDate(dayjs())
      setSelectedDate(null)
      setLocIndVal(-1)
      // console.log(eventD.allEventLocationAndDateTime[selctedTimeandDate].date_list, 'select time')
      setCalTimeData({})
      setSelCalTime('')
      setCalSelDateData([])

    }
  };
  const [companyname, setCompanyName] = useState('')
  const [companynumber, setCompanyNumber] = useState('')
  const [companyaddress, setCompanyAddress] = useState('')



  let Autocountry = localStorage.getItem("autoCountry")
    ? JSON.parse(localStorage.getItem("autoCountry"))
    : {};

  const getAddons = (
    event,
    selSeatLabel,
    holdToken,
    selSeatLabels,
    seatIO = false
  ) => {
    setopenCompanyModel(false)
    if (
      localStorage.getItem("label") &&
      localStorage.getItem("Chart_Id") &&
      !seatIO
    ) {
      releaseChart();
    }
    setIsLoading(true);
    if (
      location.state.eventdetailspage1.eventdetailspage1.seat_io === "1" &&
      !seatIO
    ) {
      setOpenFloorPlan(true);
    } else {
      let postData = {
        event_id: params.id,
        country_id: localStorage.getItem("autoCountry")
          ? JSON.parse(localStorage.getItem("autoCountry")).id
          : "",
      };

      http("add_onServices/list", "POST", postData).then((res) => {

        if (parseInt(res.data.status) === 0) {
          setIsLoading(false);
          Ordertemp();
          navigate(`/order-preview/?flag=${Autocountry.sortname}`, {
            state: {
              eventD: eventD,
              quantity: count,
              ticketId: selectedId,
                hybrid: localStorage.getItem("hybrid"),
              is_ticket_free: ticketFree,
             
              location:
                Object.keys(selectedBookticketdata).length > 0
                  ? selectedBookticketdata
                  : objValue,
              skip: false,
              time: selectedTimeValue,
              eventData:
                Object.keys(selectedBookticketdata).length > 0
                  ? selectedBookticketdata
                  : Privateeventbookticketdata,
              Selecteddatetime: selectedTimeDate,
              holdingAddons: [],
              // offers: ,
              totalTicketAmountChillers: totalTicketAmountChillers,
              checkPrivateEvent: checkPrivateEvent,
              selSeatLabel: localStorage.getItem("label")
                ? JSON.parse(localStorage.getItem("label"))
                : "",
              holdToken: localStorage.getItem("holdToken")
                ? JSON.parse(localStorage.getItem("holdToken"))
                : "",
              selSeatLabels: localStorage.getItem("labels")
                ? JSON.parse(localStorage.getItem("labels"))
                : "",
              cpNum: companynumber,
              cpAdd: companyaddress,
              cpName: companyname,
              groupCheck: count > parseInt(maxlimit) ? 1 : 0,
              calSelDate: date,
               location_date_range:  eventD.multiday_scan === "1" ? objValue.locationDateRange : false,
               upgrade_cat:eventD.upgradeSeatDetails ? eventD.upgrade_cat:0,
               ex_category_id:eventD.upgradeSeatDetails ? eventD.previousCatId:0
            } 
          });
        } else {
          setIsLoading(false);
          // debugger
          handleOpen();
          // Addon
          Object.keys(res.data.data).forEach((key) => {
            res.data.data[key].forEach((element) => {
              element.web_action_type_name = key;
            });
          });

          let objToArray = Object.values(res.data.data);
          objToArray.map((res) => {
            res.map((ele) => {
              ele.remainQty = ele.remaining_qty
                ? ele.remaining_qty
                : ele.remaining_quantity;
              ele.count = 0;
              ele.added = false;
            });
          });
          setAddon(objToArray);
        }
      });
    }
  };

  const CallBackForSeatIOPayment = (data) => {
    setCallFromSeatIo(data);
  };
  // Order+

  const Ordertemp = () => {
    let arr = [];
    let arrHoldingAddons = [];
    Addon.forEach((res) => {
      res.forEach((ele) => {
        let obj = {};
        if (ele.added && ele.added == true) {
          obj.action_id = ele.action_id;
          obj.action_type = ele.action_type;
          obj.service_quantity = ele.service_quantity;
          obj.event_id = ele.event_id;
          obj.user_id = ele.user_id;
          arr.push(obj);
          arrHoldingAddons.push(ele);
        }
      });
    });

    setHoldingAddons(arrHoldingAddons);
    let postData = {
      services: arr,
    };

    http("orders/services_selection_for_order_temp", "POST", postData).then(
      (res) => { 
        if (res.data.status == 1) {
          let userDetails = localStorage.getItem("loginUser")
            ? JSON.parse(localStorage.getItem("loginUser"))
            : {};
          let postData = {
            tickets: [
              {
                event_id: params.id,
                ticket_id: selectedId,
                ticket_quantity: count,
                user_id: userDetails.id,
                is_ticket_free: ticketFree,
              },
            ],
          };
          Tickethold(postData, arrHoldingAddons);
        }
      }
    );
  };
 const CheckTemp = () => {
   
let arrHoldingAddons = [];
   
          let userDetails = localStorage.getItem("loginUser")
            ? JSON.parse(localStorage.getItem("loginUser"))
            : {};
          let postData = {
            tickets: [
              {
                event_id: params.id,
                ticket_id: selectedId,
                ticket_quantity: count,
                user_id: userDetails.id,
                is_ticket_free: ticketFree,
              },
            ],
          };
          TicketholdSkip(postData, arrHoldingAddons);
        };
     
  const TicketholdSkip = (postData, arrAdons) => {
    // alert("rewrrewer");
    http("orders/ticket_selection_for_order_temp", "POST", postData).then(
      (res) => { 
        if (res.data.status == 1) {

  const hybrid = res.data.data.filter(datas => datas.ticket_id == selectedId).map(filteredData => (
             filteredData.hybrid 
   ));
   localStorage.setItem('hybrid',hybrid)

           // const filtered = res.data.data.filter(datas => datas.ticket_id.includes(selectedId));
// const is_ticket_free = res.data.data.filter(datas => datas.ticket_id == selectedId).map(filteredData => (
//              return filteredData.free
//    ));

          
            navigate(`/order-preview/?flag=${Autocountry.sortname}`, {
                    state: {
                      eventD: eventD,
                      quantity: count,
                      ticketId: selectedId,
                      hybrid:(hybrid[0])?hybrid[0]:0,
                      is_ticket_free: ticketFree,
                      skip: true,
                      location:
                        Object.keys(selectedBookticketdata).length > 0
                          ? selectedBookticketdata
                          : objValue,
                      time: selectedTimeValue,
                      eventData:
                        Object.keys(selectedBookticketdata).length > 0
                          ? selectedBookticketdata
                          : Privateeventbookticketdata,
                      Selecteddatetime: selectedTimeDate,
                      checkPrivateEvent: checkPrivateEvent,
                      selSeatLabel: localStorage.getItem("label")
                        ? JSON.parse(localStorage.getItem("label"))
                        : "",
                      holdToken: localStorage.getItem("holdToken")
                        ? JSON.parse(localStorage.getItem("holdToken"))
                        : "",
                      selSeatLabels: localStorage.getItem("labels")
                        ? JSON.parse(localStorage.getItem("labels"))
                        : "",
                      cpNum: companynumber,
                      cpAdd: companyaddress,
                      cpName: companyname,
                      groupCheck: count > parseInt(maxlimit) ? 1 : 0,
                      calSelDate: date,
                      location_date_range:  eventD.multiday_scan === "1" ? objValue.locationDateRange : false,
                      upgrade_cat:eventD.upgradeSeatDetails ? eventD.upgrade_cat:0,
                      ex_category_id:eventD.upgradeSeatDetails ? eventD.previousCatId:0
                    } 
                  })
        }
      }
    );
  };
 const Tickethold = (postData, arrAdons) => {
    // alert("rewrrewer");
    http("orders/ticket_selection_for_order_temp", "POST", postData).then(
      (res) => { 
        if (res.data.status == 1) {

  const hybrid = res.data.data.filter(datas => datas.ticket_id == selectedId).map(filteredData => (
             filteredData.hybrid 
   ));
   localStorage.setItem('hybrid',hybrid)

           // const filtered = res.data.data.filter(datas => datas.ticket_id.includes(selectedId));
// const is_ticket_free = res.data.data.filter(datas => datas.ticket_id == selectedId).map(filteredData => (
//              return filteredData.free
//    ));

          
          navigate(`/order-preview/?flag=${Autocountry.sortname}`, {
            state: {
              eventD: eventD,
              quantity: count,
              ticketId: selectedId,
              hybrid: (hybrid[0])?hybrid[0]:0,
              is_ticket_free: ticketFree,
              location:
                Object.keys(selectedBookticketdata).length > 0
                  ? selectedBookticketdata
                  : objValue,
              skip: false,
              time: selectedTimeValue,
              eventData:
                Object.keys(selectedBookticketdata).length > 0
                  ? selectedBookticketdata
                  : Privateeventbookticketdata,
              Selecteddatetime: selectedTimeDate,
              holdingAddons: arrAdons,
              totalTicketAmountChillers: totalTicketAmountChillers,
              checkPrivateEvent: checkPrivateEvent,
              selSeatLabel: localStorage.getItem("label")
                ? JSON.parse(localStorage.getItem("label"))
                : "",
              holdToken: localStorage.getItem("holdToken")
                ? JSON.parse(localStorage.getItem("holdToken"))
                : "",
              selSeatLabels: localStorage.getItem("labels")
                ? JSON.parse(localStorage.getItem("labels"))
                : "",
              cpNum: companynumber,
              cpAdd: companyaddress,
              cpName: companyname,
              groupCheck: count > parseInt(maxlimit) ? 1 : 0,
              calSelDate: date,
              location_date_range:  eventD.multiday_scan === "1" ? objValue.locationDateRange : false,
              upgrade_cat:eventD.upgradeSeatDetails ? eventD.upgrade_cat:0,
              ex_category_id:eventD.upgradeSeatDetails ? eventD.previousCatId:0
            } 
          });
        }
      }
    );
  };

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const Addonscount = (ele, price) => {

    ele.count = ele.count + 1;
    let userDetails = localStorage.getItem("loginUser")
      ? JSON.parse(localStorage.getItem("loginUser"))
      : {};
    ele.action_id = ele.id;
    ele.action_type = ele.web_action_type;
    ele.event_id = params.id;
    ele.user_id = userDetails.id;
    ele.service_quantity = ele.count;
    ele.added = true;
    setchillerCount(chillerCount + 1);
    setTotalTicketAmountChillers(
      parseFloat(totalTicketAmountChillers) + parseFloat(price)
    );
    ele.count < freeAddonQty ? <></>
      : ele.count < (count > ele.remainQty ? ele.remainQty : count) ? (
        <></>
      ) : (
        // NotificationManager.info("Max Addon Already Selected")
        setOpenAlert(true)
      )
    setAlertMsg("Max Addon Already Selected");
  };
  const AddonscountMinus = (ele, price) => {
    chillerCount <= 0 ? setchillerCount(0) : setchillerCount(chillerCount - 1);
    ele.count = ele.count - 1;
    let userDetails = localStorage.getItem("loginUser")
      ? JSON.parse(localStorage.getItem("loginUser"))
      : {};
    ele.action_id = ele.id;
    ele.action_type = ele.web_action_type;
    ele.event_id = params.id;
    ele.user_id = userDetails.id;
    ele.service_quantity = ele.count;

    ele.added = ele.count <= 0 ? false : true;
    setTotalTicketAmountChillers(totalTicketAmountChillers - parseFloat(price));
  };

  //package details
  const [toogleOpenPackage, setToogleOpenPackage] = useState(false)
  const openPackageDetails = (e, index) => {
    const clone = [...bookingData];
    clone.map((e) => (e.packageOpen = false));
    clone[index].packageOpen = true;
    setbookingData(() => [...clone]);
    setToogleOpenPackage(!toogleOpenPackage)

  }



  // state declared for storing calender selected date data 
  const [CalSelDateData, setCalSelDateData] = useState([]);
  const [selCalTime, setSelCalTime] = useState('');
  const [selCalTimeData, setCalTimeData] = useState({});

  const closePopup = (message) => {
    setOpenDate(message);
  };

  // calender function select time and value
  const CalSelectedTimeValue = () => {
    // console.log(selCalTimeData.event_end_time, 'cal sel data')
    setsteptwo(`${dateFormated(selectedDate)} ` + " " + `${selCalTimeData.time}`)
    setstepTwoDuration(`${selCalTimeData.duration}`)
    setOpen1(true);
    setSelectedTimeValue(selCalTimeData)
    getAllTicketByLocationByIdByEventId(selCalTimeData)
    setSelectedTimeDate(`${dateFormated(selectedDate)} ` + " " + `${selCalTimeData.time}`)
  }


  function getDayName(dateStr, locale) {
    var date = new Date(dateStr);
    return date.toLocaleDateString(locale, { weekday: "long" });
  }


    const hy = localStorage.getItem("hybrid");

  return (
    <>
      {openFloorPlan ? (
        <FloorPlan
          getAddon={getAddons}
          eventData={
            Object.keys(selectedBookticketdata).length > 0
              ? selectedBookticketdata
              : Privateeventbookticketdata
          }
          stepOne={stepOne}
          stepTwo={stepTwo}
          quantity={count}
          selectedTicketData={selectedTicketData}
          editTickets={EditTickets}
          CallBackForSeatIOPayment={CallBackForSeatIOPayment}
        />
      ) : (
        <Box className="mainContainer">
          <Grid container>
            <Grid xs={12} md={12}>
              <Typography className="layoutTitle">
                {Privateeventbookticketdata.event_name}
              </Typography>
              <div className="hLine"></div>
            </Grid>
          </Grid>

          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            variant="scrollable"
            className="MainTAbsTop"
            TabIndicatorProps={{
              style: {
                display: "none",
              },
            }}
          >
            <Tab
              value={0}
              label={
                <div
                  className="custmDivRel"
                  style={{
                    border: "1px solid #0CA8F6",
                    backgroundColor: "#F4FBFF",
                  }}
                >
                  <Typography className="selectTypo">{stepOne.slice(0 ,40)}...</Typography>
                  <div
                    className="divCircleAbs"
                    style={{
                      border:
                        first && !second && !third
                          ? "1px solid #0CA8F6"
                          : "1px solid #D9D9D9",
                      backgroundColor: first ? "#0CA8F6" : "#FFF",
                    }}
                  >
                    <img src="assets/images/check.png" alt="" />
                  </div>
                </div>
              }
              {...a11yProps(0)}
              className="TabsDiv"
              sx={{ minWidth: "33.33%" }}
            />
            {checkPrivateEvent || eventD.multiday_scan === "1" ? (
              ""
            ) : (
              <Tab
                value={1}
                disabled={second ? false : true}
                label={
                  <div
                    className="custmDivRel"
                    style={{
                      border:
                        first && second && !third
                          ? "1px solid #0CA8F6"
                          : "1px solid #D9D9D9",
                      backgroundColor: second || third ? "#F4FBFF" : "#FFF",
                    }}
                  >
                    <Typography className="selectTypo">
                      {stepTwo}{" "}
                      {stepTwoDuration ? `, Duration: ${stepTwoDuration}` : ""}
                    </Typography>

                    <div
                      className="divCircleAbs"
                      style={{
                        border:
                          second && !first && !third
                            ? "1px solid #0CA8F6"
                            : "1px solid #D9D9D9",
                        backgroundColor: second ? "#0CA8F6" : "#FFF",
                      }}
                    >
                      <img src="assets/images/check.png" alt="" />
                    </div>
                  </div>
                }
                {...a11yProps(1)}
                className="TabsDiv"
                sx={{ minWidth: "33.33%" }}
              />
            )}
            <Tab
              disabled={third ? false : true}
              value={2}
              label={
                <div
                  className="custmDivRel"
                  style={{
                    border:
                      third && !second && !first
                        ? "1px solid #0CA8F6"
                        : "1px solid #D9D9D9",
                    backgroundColor:
                      third && !second && !first ? "#F4FBFF" : "#FFF",
                  }}
                >
                  <Typography className="selectTypo">{stepThird}</Typography>
                  <div
                    className="divCircleAbs"
                    style={{
                      border:
                        third && !second && !first
                          ? "1px solid #0CA8F6"
                          : "1px solid #D9D9D9",
                      backgroundColor: second && !first ? "#F4FBFF" : "#FFF",
                    }}
                  >
                    <img src="assets/images/check.png" alt="" />
                  </div>
                </div>
              }
              {...a11yProps(2)}
              className="TabsDiv"
              sx={{ minWidth: "33.33%" }}
            />
          </Tabs>

          <TabPanel value={value} index={0}>
            <Box
              sx={{ flexGrow: 1 }}
              style={{ position: "relative", marginTop: "30px" }}
            >
              <div style={{}} className="ArrowIcons">
                <img src="assets/images/Polygon1.png" alt=""></img>
              </div>
              <Grid
                container
                className="boxShadowTabPanel"
                justifyContent="space-between"
              >
                <Grid xs={12} md={12} sx={{ mb: 4 }}>
                  <div
                    className=" flexItem"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      onChange={(e) => funcSelectValue(e.target.value)}
                      value = {LocIndVal}
                    // defaultValue=''
                    >
                      <Grid container justifyContent="space-between">
                        {Privateeventbookticketdata.allEventLocationAndDateTime.map(
                          (ele, index) => {
                            // console.log(ele  , typeof(ele?.event_date_time) , 'ele')
                            return (
                              <Grid
                                xs={12}
                                sm={12}
                                md={12}
                                lg={5.9}
                                className={
                                  ele.flagForHide === 1 
                                    ? " commonBorder disable-time"
                                   : ele.flagForHide === 0  ? "commonBorder" : ele?.event_date_time[0] && ele?.event_date_time[0]?.time[0]?.flagForHide === 1 ?" commonBorder disable-time" : "commonBorder"
                                }
                                sx={{
                                  alignItems: "center",
                                  marginBottom: "25px",
                                  display: "flex",
                                }}
                              >
                                {/* {console.log(ele , 'location ele')} */}
                                <FormControlLabel
                                  value={index}
                                  disabled={ele.flagForHide === 1  ?  true: ele.flagForHide === 0  ? false : ele?.event_date_time[0]  && ele?.event_date_time[0]?.time[0]?.flagForHide === 1 ? true: false}
                                  control={
                                    <Radio
                                      name="selectboxradio"
                                      size="large"
                                      // value=""
                                      // checked={
                                      //   checkPrivateEvent ||  eventD.multiday_scan === "1" ? RadioID === 0 ? false : true : <></>
                                      // }
                                      sx={{ color: "#d9d9d9" }}
                                    />
                                  }
                                /> 
                                <Box style={{ paddingLeft: "10px" }}>
                                  <Typography className="cstmOrderStr">
                                    {ele.venue_name
                                      ? ele.venue_name
                                      : ele.location.venue_name}
                                  </Typography>
                                  <div
                                    className="flexItem"
                                    style={{ alignItems: "center" }}
                                  >
                                    <img
                                      src="assets/images/calander.png"
                                      className="ImgRightM10"
                                      alt=""
                                    ></img>

                                    {/* {ele.event_date_time.map((response, index) => {0 */}
                                    {/* return ( */}

                                    <>
                                      <Typography className="dateTypo">
                                        {/* {dateFormated (response.date)}  */}
                                        {ele.multiday_scan === "1" ? ele.locationDateRange: ele.location
                                          ? dateFormated(ele.datet) +
                                          " " +
                                          ele.time
                                          : ele.event_date_time.length > 1
                                            ? dateFormated(
                                              ele.event_date_time[0].date
                                            ) +
                                            " - " +
                                            dateFormated(
                                              ele.event_date_time[
                                                ele.event_date_time.length - 1
                                              ].date
                                            )
                                            : dateFormated(
                                              ele.event_date_time[0].date
                                            )}
                                      </Typography>
                                      {/* Sat, 09 Jul 2022 */}
                                    </>
                                    {/* );
                                })} */}

                                { eventD.multiday_scan === "1" && !checkPrivateEvent ?    <Button className="seat_Booking_info" onClick={()=>handleOpenDates(checkPrivateEvent?ele.location : ele)}>
                                      <img
                                        src="assets/images/info_icon.png"
                                        className="seat_Booking_info-icon"
                                        alt=""
                                      />
                                      info
                                    </Button>: <></>}
                                  </div>

                                  <div className="flexItem">
                                    <img
                                      alt="location-img"
                                      src="assets/images/location.png"
                                      className="ImgRightM10"
                                    ></img>
                                    <Typography className="dateTypo">
                                      {ele.venue_name
                                        ? ele.venue_name
                                        : ele.location.venue_name + " , "}{" "}
                                      {ele.venue_address
                                        ? ele.venue_address
                                        : ele.location.venue_address}{" "}
                                      ,
                                      {ele.city_name
                                        ? ele.city_name
                                        : ele.location.city_name}
                                      ,
                                      {ele.province_name
                                        ? ele.province_name
                                        : ele.location.province_name}
                                    </Typography>
                                  </div>
                                </Box>
                              </Grid>
                            );
                          }
                        )}
                      </Grid>
                    </RadioGroup>
                  </div>
                </Grid>



              </Grid>
            </Box>
          </TabPanel>

          {/* Select Time Panel */}

          {checkPrivateEvent || eventD.multiday_scan === "1" ? (
            ""
          ) : (
            <TabPanel value={value} index={1}>
              <Box
                sx={{ flexGrow: 1 }}
                style={{ position: "relative", marginTop: "30px" }}
              >
                <div style={{}} className="SelectTime">
                  <img alt="" src="assets/images/Polygon1.png"></img>
                </div>
                {/* {console.log(new Date(eventD.allEventLocationAndDateTime[selctedTimeandDate].date_list[0]), 'slice')} */}
                {eventD.allEventLocationAndDateTime[selctedTimeandDate].calendar_flag === "1" ?
                  <>
                    <Grid item xs={12} sm={12} md={12} lg={6} className="mob-calander-top-Grid-pl">
                      <Box className="LocalizationProvider-Box-shadow">
                        <LocalizationProvider dateAdapter={AdapterDayjs} className="LocalizationProvider-Box-shadow-new-cstm">
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                              <CalendarPicker
                                orientation="landscape"
                                openTo="day"
                                disablePast={true}

                                date={selectedDate}
                                minDate={eventD.allEventLocationAndDateTime[selctedTimeandDate].date_list[0]}
                                maxDate={eventD.allEventLocationAndDateTime[selctedTimeandDate].date_list[eventD.allEventLocationAndDateTime[selctedTimeandDate].date_list.length - 1]}
                                defaultCalendarMonth={dayjs(eventD.allEventLocationAndDateTime[selctedTimeandDate].date_list[0])}

                                shouldDisableDate={disableDates}
                                // shouldDisableMonth = {disableMonths}
                                // shouldDisableYear = {disableYears}

                                onChange={(newDate) => {
                                  setSelectedDate(newDate)
                                  setSelCalTime('')
                                  setCalTimeData([])
                                  // console.log(`${newDate.year()}-${newDate.month() < 10 ? '0' : ''}${newDate.month() + 1}-${newDate.date() > 9 ? newDate.date() : `0${newDate.date()}`}`, 'check one')
                                  stepTimeOnSelectRadio.map((time) => {
                                    // console.log(time.date, 'check')

                                    setDate(`${newDate.year()}-${newDate.month() + 1}-${newDate.date()}`)
                                    if (time.date === `${newDate.year()}-${newDate.month() < 10 ? '0' : ''}${newDate.month() + 1}-${newDate.date() > 9 ? newDate.date() : `0${newDate.date()}`}`) {
                                      // console.log(time, 'selected')
                                      setCalSelDateData(time.time)
                                    }
                                  })
                                }}
                                className="LocalizationProvider-Box-calander" 
                              />
                            </Grid>
                          </Grid>
                        </LocalizationProvider>


                      </Box>
                    </Grid>

                    {CalSelDateData.length > 0 ? <Grid item xs={12} sm={12} md={12} lg={6} className="calander-date-mai-grid">
                      {selectedDate ? <Box className="calander-date-box" sx={{ mt: 2 }}>
                        <span><img src="assets/images/calander.png" className="ImgRightM10"
                          alt=""
                        ></img></span><span>{dateFormated(selectedDate)} </span>
                      </Box> : <></>}

                      <Box className="time-conatiner-box">
                        {CalSelDateData.map((times, newindex) => (
                          <Box className="time-conatiner-box-inner">
                            <Button className={selCalTime === times.time ? "time-box" : "time-boreder-box"} onClick={(e) => {
                              setSelCalTime(e.target.textContent.trimStart())
                              // console.log(e.target.innerText , '====' ,   e.target.textContent )
                              setCalTimeData(times)
                            }} >  {times.time}</Button> 
                          </Box>
                        ))}

                      </Box>

                      {Object.keys(selCalTimeData).length > 0 && selCalTimeData?.event_artist.length > 0 ?
                        <Box className="Artist-Details-conatiner">
                          <Typography className="Artist-Name-text">Artist Details</Typography>
                          <div className="hLine"></div>
                          <Box className="artist-img-main-box-loction">
                            {selCalTimeData.event_artist.map((artist_details) => (
                              <Box sx={{ paddingTop: '20px' }} className="artist-img-main-box-img artist-img-main-box-loction-inner">
                                {/* <img  alt="" /> */}
                                <Avatar src={`${artist_details.artist_photo}`} className="artist-img-avatar" />
                                <Typography className="artist-img-main-box-artist-name">{artist_details.artist_name} </Typography>
                              </Box>))}

                          </Box>

                        </Box> : <></>}

                      {Object.keys(selCalTimeData).length > 0 ? <Box sx={{ textAlign: 'center', paddingTop: '70px' }}>
                        <Button className="calander-proceds-button"
                          onClick={() => CalSelectedTimeValue()}
                        >{t('Proceed')}</Button>
                      </Box> : <></>}
                    </Grid> : <></>}
                  </>
                  : <>

                    <Grid
                      container
                      spacing={2}
                      className="boxShadowTabPanel"
                      justifyContent="flex-start"
                    >
                      <>
                        {stepTimeOnSelectRadio
                          ? stepTimeOnSelectRadio.map((element, indx) => (
                            <>
                              {element.time.map((res, newindx) => (
                                <>
                               
                                  <Grid
                                    xs={12}
                                    sm={12}
                                    md={5.6}
                                    lg={3.7}
                                    sx={{ mb: 4 }}
                                    mx={1}
                                   
                                  >
                                    <>
                                      <Box className="commonBorder flexGrid">
                                        <RadioGroup
                                          aria-labelledby="demo-radio-buttons-group-label"
                                          // defaultValue="addressOne"
                                          className={
                                            res.flagForHide === 1
                                              ? "disable-time"
                                              : ""
                                          }
                                          name="radio-buttons-group"
                                          onChange={(e) => {
                                            funcSelectTimeValue(indx, newindx);
                                            setRadioID(res.location_id);
                                          }}
                                        >
                                          {/* {console.log(res.location_id , 'loc id ============ ')} */}
                                          <div className=" flexGrid">
                                            <FormControlLabel
                                              value={indx}
                                              control={
                                                <Radio
                                                  checked={
                                                    RadioID === res.location_id
                                                  }
                                                  name="selectboxradio"
                                                  className={
                                                    res.flagForHide === 1
                                                      ? "disable-time"
                                                      : ""
                                                  }
                                                  disabled={
                                                    res.flagForHide === 1
                                                      ? true
                                                      : false
                                                  }
                                                  size="large"
                                                  sx={{ color: "#d9d9d9" }}
                                                />
                                              }
                                            />

                                            <div style={{ paddingLeft: "10px" }}>
                                              <div
                                                className="flexItem"
                                                style={{ alignItems: "center" }}
                                              >
                                                <img
                                                  src="assets/images/calander.png"
                                                  className="ImgRightM10"
                                                  alt=""
                                                ></img>
                                                <Typography className="dateTypo">
                                                  {dateFormated(element.date)}
                                                </Typography>
                                              </div>
                                              <div
                                                className="flexItem"
                                                style={{ alignItems: "center" }}
                                              >
                                                <img
                                                  src="assets/images/timer.png"
                                                  className="ImgRightM10"
                                                  alt=""
                                                ></img>

                                                <Typography className="dateTypo">
                                                  {res.time}
                                                </Typography>
                                              </div>
                                            </div>
                                          </div>

                                        </RadioGroup>
                                      </Box>
                                    </>
                                  </Grid>
                                </>
                              ))}
                            </>
                          ))
                          : ""}
                      </>

                    </Grid>
                  </>}
              </Box>
            </TabPanel>
          )}
          {/* End Select Time Panel */}

          {/* Seat Booking */}
          <TabPanel value={value} index={2}>
            <Box
              sx={{ flexGrow: 1 }}
              style={{ position: "relative", marginTop: "30px" }}
            >
              <div className={checkPrivateEvent || eventD.multiday_scan === "1" ? "SelectTime" : "SelectBook"}>
                <img
                  alt=""
                  src="assets/images/Polygon1.png"
                  className={checkPrivateEvent || eventD.multiday_scan === "1" ? "" : "SelectBookImg"}
                ></img>
              </div>
              <Grid
                container
                className="boxShadowTabPanel"
                justifyContent="space-between"
              >
                {bookingData
                  ? bookingData.map((element, indx) => (
                    <>
                      <Grid xs={12} md={5.8} sx={{ mb: 4 }}>
                        <div
                          className="flexItem commonBorder flexWrapMobile"
                        // style={{ alignItems: "center" }}
                        >
                          <div className="GnrlDivGrid">
                            <img src="assets/images/seat.png" alt="" />
                          </div>
                          <div className="rightDiv">
                            <div className="GnrlDivTYpo">
                              <div>
                                <Typography className="genralTypoTxt">
                                  {element.ticket_classs}
                                </Typography>
                                {parseInt(element.remaining_quantity) === 0 && !checkPrivateEvent ?
                                  <Typography className="priceTypo">
                                    <span style={{ color: "#ED0404" }}>
                                      {t("Sold_Out")}
                                    </span>
                                  </Typography> :
                                  <Typography className="priceTypo">
                                    {t('Price')}:{" "}
                                    <span style={{ color: "#0CA8F6" }}>
                                      {" "}
                                      {getSymbolFromCurrency(element.currency)}
                                      {parseFloat( element.ticket_price).toFixed(2) + " "}
                                    </span>
                                  </Typography>}


                              </div>
                              {/*  */}
                              {element.addqty && count !== 0 ? (
                                <div className="gnrlImgGrid">
                                  <Button
                                    aria-label="reduce"
                                    disabled={count <= 0 ? true : false}
                                    onClick={() => {
                                      setCount(Math.max(count - 1, 0));
                                      getTotalPrice(Math.max(count - 1, 0));
                                      setalertBtn(false);
                                    }}
                                    sx={{
                                      padding: "0px !important",
                                      margin: "0px !important",
                                    }}
                                  >
                                    <img
                                      src="assets/images/minus.png"
                                      alt="minus"
                                    ></img>
                                  </Button>
                                  <div className="detailBottomL"></div>
                                  <Box className="Textnumbers">{count}</Box>
                                  <Button
                                    aria-label="increase"
                                    disabled={

                                      parseInt(element.ticket_charges_status) === 2 && parseInt(element.event_social_type) === 2 || count >= parseInt(element.remaining_quantity) || !parseInt(element.grouping_allowed) && count >= parseInt(element.max_ticket_limit) ? true : false
                                    }
                                    onClick={() => IncreaseTicketCount(element.max_ticket_limit, element.remaining_quantity, element.grouping_allowed)}
                                    sx={{
                                      padding: "0px !important",
                                      margin: "0px !important",
                                    }}
                                  >
                                    <img
                                      src="assets/images/plus.png"
                                      alt="plus"
                                    ></img>
                                  </Button>
                                </div>
                              ) : (
                                <div className="gnrlImgGrid">
                                  {parseInt(element.remaining_quantity) === 0 && element.notified_me === 0 && !checkPrivateEvent ? (
                                    <Button
                                      className="AddButtonCstm"
                                      onClick={() => notifyTicket(element.id)}
                                    >
                                      {t("Notify_Me")}
                                    </Button>
                                  ) : parseInt(element.remaining_quantity) === 0 && element.notified_me === 1 && !checkPrivateEvent ? (
                                    <Button
                                      disabled={true}
                                      sx={{ background: '#DADADA', color: "#060251 !important", textTransform: 'capitalize', p: 1.5, fontSize: '18px', fontWeight: 700 }}

                                    >
                                      <DoneIcon sx={{ color: '#060251', mr: 2 }} />  Notified
                                    </Button>
                                  ) : parseInt(element.remaining_quantity) === 0 && checkPrivateEvent ? (

                                    <Button
                                      disabled={true}
                                      className="AddButtonCstm"
                                    >
                                      {t("Sold_Out")}
                                    </Button>

                                  ) : (
                                    <Button
                                      onClick={(e) => {
                                        addQtyStateChange(e, indx);
                                      }}
                                      className={
                                        privateEventBookingCheck === 0
                                          ? "AddButtonCstm"
                                          : "add-btn-disable"
                                      }
                                      disabled={
                                        privateEventBookingCheck === 0
                                          ? false
                                          : true
                                      }
                                    >
                                      {t("Add")}{" "}
                                    </Button>
                                  )}

                                </div>

                              )}
                            </div>
                            <div className="leftPadding">

                              {element.ticket_description_flag ? <Button className="detlTypo" onClick={(e) => { openPackageDetails(e, indx) }} sx={{ textTransform: 'capitalize' }}>
                                {t("Details")}       <img src={"assets/images/down-arrow.png"} className='package-drop' alt='dropdown-icon' />
                              </Button>
                                : <></>}

                              <div className="detailBottomL"></div>
                              {toogleOpenPackage && element?.packageOpen ? <p>
                                {HTMLReactParser(element.ticket_description)}
                              </p> : <></>}
                              <Typography className="FcomesTypo">
                                {element.private_free_allowed_user}
                                {/* First Come First Serve Basis Seating */}
                              </Typography>
                            </div>
                          </div>
                        </div>

                      </Grid>


                    </>
                  ))
                  : ""}
              </Grid>
              <div>

                {bookingData.length > 0 ? (
                  privateEventBookingCheck == 0 ? (
                    <Grid
                      container
                      spacing={2}
                      alignItems="center"
                      sx={{
                        backgroundColor: "#F6F6F6",
                        margin: "20px 0px",
                        padding: "10px 0px",
                        width: "100%",
                      }}
                    >
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        sx={{ textAlign: "right", paddingRight: "10px" }}
                      >
                        <Typography className="BottomTotalP">
                          {t("Total_Price")}:{" "}
                          {parseFloat(totalTicketAmount).toFixed(2)}
                        </Typography>
                        <Typography className="BottomTickets">
                          ({count} {t("Tickets")})
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} sx={{}}>
                        {count <= 0 ? (
                          <Button
                            style={{
                              background: "grey",
                              border: "1px solid grey",
                            }}
                            disabled={true}
                            className="NewviewBtn1"
                          >
                            {t("Proceed")} 
                          </Button>
                        ) : (
                          <>

                            {!organisationCheck || count <= parseInt(maxlimit) ? <Button className="ProcedBtn" onClick={getAddons}>
                            {t("Proceed")} 
                            </Button> :
                              <Button className="ProcedBtn" onClick={handleopenCompanyModel}>
                                {t("Proceed")} 
                              </Button>}
                          </>
                        )}
                      </Grid>
                    </Grid>
                  ) : (
                    <Box sx={{ textAlign: "center" }}>
                      <Button
                        variant="contained"
                        sx={{
                          background: "#0C0251",
                          mt: 4,
                          fontFamily: "unset",
                          textTransform: "capitalize",
                          fontSize: "20px",
                          fontWeight: 600,
                        }}
                      >
                        {t("Accepted")}
                      </Button>
                    </Box>
                  )
                ) : (
                  ""
                )}
              </div>
            </Box>
          </TabPanel>

          {/* End Seat Booking */}
        </Box>
      )}
 <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        fullScreen={fullScreen}
      >
      
        <Box sx={style}>
          <Box sx={{ padding: "0px" }}>
            <div className="popUPTopHeadder">
              <Typography className="ArrowBack">
                <ArrowBackIosIcon
                  sx={{ cursor: "pointer" }}
                  onClick={handleClose}
                />
                {t("Add_on_Services")}
              </Typography>
              <Button
                className="PopTopHeadderBtn"

                 onClick={() => {
                                  CheckTemp();
                                }}
              
              >
                {t("Skip")}
              </Button>
            </div>
            <div className="hLine"></div>
          </Box>
          {isLoading ? (
            <SpinnerCircular
              style={{ width: "1.2rem", height: "1.2rem" }}
              color="light"
            />
          ) : (
            <Box
              sx={{
                padding: "0px",
                overflowY: "auto",
                height: "50vh",
              }}
            >
              {/* Vivek */}

              {Addon.map((res) =>
                res.map((chiller) => (
                  <>
                    <Grid
                      container
                      spacing={2}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Grid item xs={12} md={3}>
                        <div className="popUptshirtsImg">
                          <img
                            // src={
                            //   chiller.image
                            //     ? `${chiller.image}`
                            //     : `assets/images/Chillars.png`
                            // }
                            alt=""
                            src={
                              chiller.image
                                ? chiller.image
                                : chiller.web_action_type === 3
                                  ? "assets/images/Chillars.png"
                                  : chiller.web_action_type === 2 &&
                                    chiller.parking_type === "1"
                                    ? "assets/images/two-wheelers.png"
                                    : chiller.web_action_type === 2 &&
                                      chiller.parking_type === "2"
                                      ? "assets/images/parkingN.png"
                                      : ""
                            }
                          ></img>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <Typography className="tshirtTypoPop">
                          {chiller.title}
                        </Typography>
                        <Typography className="SizeTypoPop">
                          {chiller.extra_fields ? "Size: " : ""}
                          <span style={{ color: "#0CA8F6" }}>
                            {chiller.extra_fields
                              ? chiller.extra_fields.fields_data[0].values[0]
                                .label
                              : ""}{" "}
                          </span>{" "}
                        </Typography>
                        <Typography className="SizePricePop">
                          {t("Price")}:{" "}
                          <span style={{ color: "#0CA8F6" }}>
                            {getSymbolFromCurrency(chiller.currency)}
                            {chiller.price}
                          </span>{" "}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <div className="gnrlImgGrid">
                          {chiller.remainQty <= 0 ? (
                            <Button
                              sx={{ background: "#060251" }}
                              variant="contained"
                              disabled={true}
                            >
                              {t("Sold_Out")}
                            </Button>
                          ) : chiller.count === 0 ? (
                            <Button
                              sx={{ background: "#060251" }}
                              variant="contained"
                              onClick={() => {
                                Addonscount(chiller, chiller.price);
                                // if(count == chiller.count){
                                //   Addonscountmatch()
                                // }
                              }}
                            >
                              {t("Add")}
                            </Button>
                          ) : (
                            <>
                              <Button
                                aria-label="reduce"
                                disabled={chiller.count <= 0 ? true : false}
                                onClick={() => {
                                  AddonscountMinus(chiller, chiller.price);
                                }}
                                sx={{
                                  padding: "0px !important",
                                  margin: "0px !important",
                                }}
                              >
                                <img src="assets/images/minus.png" alt=""></img>
                              </Button>
                              <div className="detailBottomL"></div>
                              <Box className="Textnumbers">{chiller.count}</Box>
                              <Button
                                aria-label="increase"
                                disabled={
                                  chiller.count < freeAddonQty
                                    ? chiller.count >= chiller.remainQty
                                    : chiller.count <
                                      (count > chiller.remainQty
                                        ? chiller.remainQty
                                        : count)
                                      ? false
                                      : true
                                }
                                onClick={() => {
                                  Addonscount(chiller, chiller.price);
                                }}
                                sx={{
                                  padding: "0px !important",
                                  margin: "0px !important",
                                }}
                                className={chiller.count < freeAddonQty
                                  ? chiller.count >= chiller.remainQty
                                  : chiller.count <
                                    (count > chiller.remainQty
                                      ? chiller.remainQty
                                      : count)
                                    ? ""
                                    : "fade-btn"}
                              >
                                <img src="assets/images/plus.png" alt=""></img>
                              </Button>
                            </>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                    <div className="hLine3"></div>
                  </>
                ))
              )}
            </Box>
          )}

          <Grid
            container
            spacing={2}
            alignItems="center"
            sx={{
              backgroundColor: "#F6F6F6",
              margin: "0px 0px 0px",
              padding: "10px 0px",
              width: "100%",
            }}
          >
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              sx={{ textAlign: "right", paddingRight: "10px" }}
            >
              {totalTicketAmountChillers ? (
                <Typography className="BottomTotalP">
                  {t("Total_Price")}:{" "}
                  {parseFloat(totalTicketAmountChillers).toFixed(2)}
                </Typography>
              ) : (
                0
              )}
              <Typography className="BottomTickets">
                ({chillerCount}) {t("Items_Selected")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} sx={{}}>
              <Button
                disabled={chillerCount <= 0 ? true : false}
                className={
                  chillerCount <= 0 ? "disableProceedBtn" : " ProcedBtn"
                }
                onClick={Ordertemp}
              >
                {t("Proceed")}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      {/* Terms And condition Modal */}
      {/* </div>
          </Box>
        </TabPanel> */}
      {/* <FloorPlan/> */}
      {/* End Seat Booking */}

 <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={openalert}
          autoHideDuration={1000}
          onClose={handleCloseAlert}
        >
          <Alert
            onClose={handleCloseAlert}
            severity="info"
            sx={{ width: "80%", fontFamily: "unset" }}
          >
            {alertMsg}
          </Alert>
        </Snackbar>
      </Stack>  
      <Modal
        open={openNotify}
        backdrop="static"
        BackdropComponent={Backdrop}
        onClose={handleCloseNotify}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style1} className="notify-model-box">
          <Typography id="modal-modal-description" className="notify-text-modal" sx={{ mt: 2 }}>
            {t("Your_ticket_count_has_reached")} {parseInt(maxlimit)} {t("Would_you_like_to_add_group_ticketing?")}
          </Typography>
          <Box style={{ textAlign: 'end', marginTop: '20px' }}>
            <Button className="notify-no-btn" onClick={handleCloseNotify}>{t("No")}</Button>
            <Button className="notify-Yes-btn" onClick={OrganisationCount} >{t("yes")}</Button>
          </Box>
        </Box>

      </Modal>
<Modal
        open={open1}
       
         aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        fullScreen={fullScreen}
      >
       <Box
          sx={style}
          className="ModalClassBox"
          style={{ position: "relative", maxWidth: "fit-content" }}
        >
          <Typography
            id="modal-modal-description"
            className="ModalPhomolong"
            sx={{
              textAlign: "center",
              padding: "20px 0px",
              borderBottom: "1px solid #ddd",
            }}
          >
            Terms And Condition{" "}
          </Typography>

           <Grid
            container
            sx={{
              height: "350px",
              paddingBottom: "0px",
              overflowY: "scroll",
            }}
          >
           <Grid
              item
              xs={12}
              sx={{ textAlign: "right", padding: "16px", mt: "2" }}
            // className="ModalTopGrid"
            >
            <Typography
                sx={{
                  margin: "15px 0px",
                  textAlign: "initial",
                }}
              >
                {Privateeventbookticketdata.event_terms_condition
                  ? parse(Privateeventbookticketdata.event_terms_condition)
                  : 'Term and Condition'}
              </Typography>

</Grid>
          </Grid>
            <Box
            sx={{
              display: "flex",
              alignItems: "baseline",
              borderTop: "1px solid #ddd",
            }}
            justifyContent="center"
          >
            <Button class="btn bookTicketBtn" onClick={handleClose1}>
              {t("Cancel")}
            </Button>
            <Button class="btn viewBtn" onClick={(e) => TermsConditionPopup(e)}>
            {t("Accepted")}
            </Button>
          </Box>
          </Box>



      </Modal>

        <Modal
        open={openCompanyModel}
        backdrop="static"
        BackdropComponent={Backdrop}
        onClose={handleCloseCompanyModel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style1} className="company-model-box">
          <form >
            <Box className="card-seat-Booking-company">
              <Box className="company-Skip-box" sx={{ padding: '20px' }}>
                <Typography id="modal-modal-description" className="company-text-modal" sx={{ mt: 2 }}>
                  {t("Enter_Details")}
                </Typography>
                <Button className="company-Skip-btn"
                  onClick={handleSkipChange}
                >{t("Skip")}</Button>
              </Box>
              <Box className="" sx={{ paddingTop: '20px', paddingLeft: '20px', paddingRight: '20px' }}>
                <div className="hLine"></div>
              </Box>
              <Box className="" sx={{ padding: '20px' }}>
                <Typography className="box-company-name-text ">
                {t("Company_Name(optional)")}
                </Typography>
                <TextField
                  variant="standard"
                  placeholder={`${t("Company_Name")}`}
                  fullWidth
                  margin="normal"
                  value={companyname}
                  onChange={(e) => {
                    setCompanyName(e.target.value)

                  }}
                  className="company-name-textfield"
                  inputProps={{ maxLength: 40 }}

                />

                <Typography className="box-company-name-text" sx={{ mt: 2 }}>
                  {t("Company_VAT_Number(optional)")}
                </Typography>
                <TextField
                  variant="standard"
                  placeholder={`${t("Company_VAT_Number")}`}
                  fullWidth
                  margin="normal"
                  className="company-name-textfield"
                  type="text"
                  value={companynumber}
                  onChange={(e) => {
                    setCompanyNumber(e.target.value)

                  }}
                  inputProps={{ maxLength: 30 }}
                  onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}

                />
                <Typography className="box-company-name-text" sx={{ mt: 2 }}>
                  {t("Company_Address(optional)")}
                </Typography>
                <TextField
                  variant="standard"
                  placeholder={`${t("Company_Address")}`}
                  fullWidth
                  margin="normal"
                  value={companyaddress}
                  onChange={(e) => {
                    setCompanyAddress(e.target.value)

                  }}
                  className="company-name-textfield"
                  inputProps={{ maxLength: 50 }}

                />
              </Box>
              <Box className="company-detail-btn-box">
                <Button className="company-back-btn" onClick={handleCloseCompanyModel}>Back</Button>

                <Button
                  className={companyname.length > 0 || companynumber.length > 0 || companyaddress.length > 0 ? "company-cuntn-btn" : "continue-disable"}
                  disabled={companyname.length > 0 || companynumber.length > 0 || companyaddress.length > 0 ? false : true}
                  onClick={getAddons}
                >{t("Continue")}</Button>

              </Box>
            </Box>
          </form>
        </Box>

      </Modal>
        <Modal
        keepMounted
        open={openDate}
        onClose={handleCloseDate}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        className="seat-Modal-class-Date"
      >
      <ShowLocationDates    selectedLocationData = {selectedLocationData}  closePopup={closePopup} />
      </Modal>


        </>
  );
}
