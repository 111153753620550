/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography"; 
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import Carousel, { consts } from "react-elastic-carousel";
import React, { useEffect, useRef, useState  } from "react";
import http from "../../Services/http";
import { useNavigate, useLocation } from "react-router-dom"; 
import CloseIcon from "@mui/icons-material/Close";
import Modal from "@mui/material/Modal";
import ModalPopup from "../ModalPopup";
import OwlCarousel from 'react-owl-carousel';
import "./style.css";  
import { NotificationManager } from "react-notifications";
import SocialSharePopup from "../SocialSharePopup"
import { ManagePageReloadState } from "../../Services/ManagePageReloadState";
import { Snackbar } from "@mui/material";
import {useTranslation} from "react-i18next";
import {getLanguageSource} from '../../Services/translateString'
const breakPoints = [
  { width: 1, itemsToShow: 2, itemPadding: [10, 3] },
  { width: 550, itemsToShow: 3, itemPadding: [10, 5] },
  { width: 768, itemsToShow: 4, itemPadding: [10, 8] },
  { width: 1200, itemsToShow: 5, itemPadding: [10, 12] },
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "95%",
  maxWidth: 600,
  bgcolor: "#FFFFFF",
  boxShadow: 24,
  overflow: "auto",
};


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(0),
  color: theme.palette.text.secondary,
}));

const Comingsoon = (props) => {
  const {t} = useTranslation("common");
  const { CheckCoomingSoonData } = props;

  const dateFormated = (date) => {
    let dateNew = new Date(date);
    let day = dateNew.toLocaleString("en-us", { weekday: "short" });
    let newdate = new Date(date).getDate();
    let month = dateNew.toLocaleString("default", { month: "short" });
    return day + "," + newdate + " " + month;
  };
 
  const options = {
    autoplay: true,
    smartSpeed: 1000,
    margin: 0,
    loop: false,
    center: false,
    dots: false,
    autoWidth:true,
    nav: true,
    navText : [
        '<i class="fas fa-chevron-left"></i>',
        '<i class="fas fa-chevron-right"></i>'
    ],
    responsive: {
  0:{
            items:1
        },
        576:{
            items:1
        },
        768:{
            items:2
        },
        992:{
            items:2
        },
        1100:{
            items:3
        }
    }
};

  const history = useNavigate();
  const [CCBoardPopUp, setCCBoardPopUp] = useState(false);
  
  const CopiedToclipBoardPopUp = (data) => { 
  
    setOpen1(data)
  setCCBoardPopUp(true)
};

const handleCloseCpy = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  setCCBoardPopUp(false);
};
const action = (
  <React.Fragment>
 
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleCloseCpy}
    >
     
      <CloseIcon fontSize="small" />
    </IconButton>
  </React.Fragment>
);



  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();

  const [comingsoon, setcomingsoon] = useState([]);


  // Module Hoc Function
  const [openLogin, setOpenLogin] = React.useState(false);
  const handleClose = () => {
    setOpenLogin(false);
  };

  // Sharemod
  const [open1, setOpen1] = React.useState(false);
  const handleClose1 = () => setOpen1(false);
  const [EventName , setEventName] = useState('');

  const shareTicketOnSocialMedia = (e, params ,event_name) => {
    setseletedeventID(params.id)
    // if (!localStorage.getItem("token")) {
    //   setOpenLogin(true);
    // } else {
    //   setOpen1(true);
    //   setEventName(event_name.replaceAll(/[^a-zA-Z0-9 ]/g , ""))
    // }
    setOpen1(true);
      setEventName(event_name.replaceAll(/[^a-zA-Z0-9 ]/g , ""))
  }; 

  const Favourites = (params,id) => {
    let postData = {
      eventid: params.id,
    };
    http("events/favourite_event", "POST", postData).then((res) => {
      if (
        res.data.msg === "success! event is added in your favourite section"
      ) {
        document.getElementById(id).classList.add('selected')
        NotificationManager.success(res.data.msg, "", 800);
      } else {
        document.getElementById(id).classList.add('selected')
        NotificationManager.success(
          "event is removed in your favourite section",
          "",
          800
        );
      }
    });
  };

  const checkLoggedIn = (e, params, id) => {
    if (!localStorage.getItem("token")) {
      setOpenLogin(true);
    } else {
      Favourites(params,id);
    }
  };

  const [seletedeventID, setseletedeventID] = useState(0); 
  let Autocountry =localStorage.getItem("autoCountry")
    ? JSON.parse(localStorage.getItem("autoCountry"))
    : {}; 
   
  const HomepageComingevent = () => {
    const HomepageComingeventData = {
      // country_id: localStorage.getItem("autoCountry") ? JSON.parse(localStorage.getItem("autoCountry")).id : '' ,
      country_id: localStorage.getItem("autoCountry")
      ? JSON.parse(localStorage.getItem("autoCountry")).id
      : "",
    };
    http(
      "coming_soon/homepage_coming_soon_events",
      "POST",
      HomepageComingeventData
    ).then((res) => { 
 
      const data = res.data.data ?? [];
      console.log(data)
      const tempUpcomingData = []
      data.forEach((data)=>{
        tempUpcomingData.push(data)
        // tempUpcomingData.push({...data,event_thumbnail:'img/coming-soon-events-thumb-001.jpg'})
        // tempUpcomingData.push({...data,event_thumbnail:'img/event-thumb-004.jpg'})
        // tempUpcomingData.push({...data,event_thumbnail:'img/event-thumb-002.jpg'})
        // tempUpcomingData.push({...data,event_thumbnail:'img/event-thumb-003.jpg'})
      })
      if (data.length > 0) {
        translateDynamicContent(tempUpcomingData)
        // setcomingsoon(tempUpcomingData ?? []);
        // if (data.length > 5) {
        //   setShow(true);
        // } else {
        //   setShow(false);
        // }
      } 
    });
  };

  const upComingComingRef = useRef()

  useEffect(() => {
    HomepageComingevent();
    ManagePageReloadState.getPageReloadState().subscribe(res =>{
      if(res.state){
        HomepageComingevent();
       }
    })
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
let intervalObject
  useEffect(()=>{
    if(comingsoon?.length > 1){
      clearInterval(intervalObject)
      let bannerIndex = 0
   intervalObject = setInterval(()=>{
    if(bannerIndex < comingsoon.length){
      if(upComingComingRef?.current?.style){
      upComingComingRef.current.style.backgroundImage = `url(${comingsoon[bannerIndex].event_thumbnail})`
      }
      bannerIndex =bannerIndex + 1
    }
    else{
      bannerIndex = 0
      if(upComingComingRef?.current?.style){
        upComingComingRef.current.style.backgroundImage = `url(${comingsoon[bannerIndex].event_thumbnail})`
      }
    }
     },3500)
    }
    ()=> clearInterval(intervalObject)
    
  },[comingsoon])

  const [show, setShow] = React.useState(false);

  const handleonviewallClick = (e, id) => {
    history(`/ViewAll/${"Comingsoon"}/${"?flag=" + query.get("flag")}`, {
      state: { id: 2, name: "Comingsoon" },
    });
  };

  const handleeventdetails = (e, id , event_name) => {
    let modifyEventName = event_name.replaceAll(/[^a-zA-Z0-9 ]/g , "")
    history(`/events/${modifyEventName.replaceAll(' ', '-')}/` + id + "?ts=cs&flag=" + Autocountry.sortname);
  };

  const getComingTranslateText = (id,text)=>{
    if(document.getElementById(id)){
      document.getElementById(id).innerHTML = text
      return
    }
    return text
  }
  const translateDynamicContent =  (data)=>{

  
    let eventConts = 0
     
   data?.forEach(async (comingSoonEvents, idx)=>{
    let eventName = comingSoonEvents.event_name
    eventConts = eventConts + 1
    
     const translatedString = await getLanguageSource([comingSoonEvents.event_name])
      comingSoonEvents.event_name = translatedString[0].translatedText || comingSoonEvents.event_name;
      eventName = comingSoonEvents.event_name
   
      if(document.getElementById(`comingSoonEvents_Name${idx}`)){
        document.getElementById(`comingSoonEvents_Name${idx}`).innerHTML = eventName
        
       }
     
     if(idx === data.length - 1){
     setcomingsoon(data ?? []);
        if (data.length > 5) {
          setShow(true);
        } else {
          setShow(false);
        }
      }
    })
  
  
}
const updateEventName = (idx,eventName)=>{
  console.log('eventName',eventName)
  if(document.getElementById(`comingSoonEvents_Name${idx}`)){
   // document.getElementById(`comingSoonEvents_Name${idx}`).innerHTML = eventName
    
   }
   else{
    return eventName
   }
}
  useEffect(()=>{
    if(localStorage.getItem('lang') &&  comingsoon && comingsoon.length > 0){
      translateDynamicContent(comingsoon)
    }
    },[localStorage.getItem('lang')])

  return (
    <>

  {/* <!-- Coming Soon Events Start --> */}
  {comingsoon.length > 0 && <div ref={upComingComingRef} className="overflow-hidden py-5 coming-soon-events" style={{backgroundImage: `url(${comingsoon[0].event_thumbnail})`}}>
            <div className="container px-4">
                <div className="section-heading mb-5 mx-auto wow fadeInUp" data-wow-delay="0.1s">
                    <div className="row justify-content-between align-items-center ">
                        <div className="col-lg-6">
                            <p className="head-sub fw-semi-bold text-uppercase mb-1">{t('discover_the_fun!')}</p>
                            <h2 className="fw-semi-bold">{t('Coming_Soon_Events')}</h2>
                        </div>
                        {comingsoon.length > 4 ?<div className="heading-btns col-lg-6 d-flex align-items-center justify-content-end gap-4">
                            <a onClick={handleonviewallClick} className="btn btn-light py-2 px-4">{t('view_all')}</a>
                        </div> : <></>}
                    </div>
                </div>
                <OwlCarousel className='owl-theme project-carousel wow fadeInUp' {...options}>
                {/* <div className="owl-carousel project-carousel wow fadeInUp" data-wow-delay="0.3s"> */}
                {comingsoon.map((data, index) =>  <div className="project-item pe-4 pb-3" style={{width:520}}>
                        <div className="project-img">
                        
                            <img className="img-fluid rounded-3" style={{height: 216}} src={data.event_thumbnail ? data.event_thumbnail : `assets/images/Rectangle.png`} alt="coming-soon-events-thumb-001"/>
                            <div className="project-card-data">
                            {/* <Box className="promotion-strip">Coming Soon</Box> */}
                                <div className="d-flex align-items-center justify-content-between">
                                    <a  onClick={(e) => shareTicketOnSocialMedia(e, data , data.event_name)}><img className="icon-peoples" src="img/peoples.svg" alt="peoples"/></a>
                                    <div className="pcd-event-like">
                                        <a id={`comingSoonEventLike${index}`} className="btn ms-3 circular-aura-effect" onClick={(e)=>checkLoggedIn(e,data,`comingSoonEventLike${index}`)}>
                                            <small className="bi bi-heart-fill"></small>
                                        </a>
                                    </div>
                                </div>
                                <div className="pcd-event-detail" onClick={(e) => handleeventdetails(e, data.id , data.event_name)}>
                                    <div className="pcd-event-title fw-medium " id={`comingSoonEvents_Name${index}`}>{updateEventName(index,data.event_name)}</div>
                                    <div className="pcd-ed-footer d-flex align-items-center justify-content-between">
                                        <div id={`coming_soon_lable${index}`} className="pcd-event-info fw-medium mb-1">{getComingTranslateText(`coming_soon_lable${index}`,t('Coming_Soon'))}</div>
                                        <div className="pcd-event-time fw-regular "><i className="bi bi-calendar4 me-2"></i> {t('Tenative_Date')}: {dateFormated(data.tentative_date)}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>)}
                </OwlCarousel>
            </div>
        </div>}
        {/* <!-- Coming Soon Events End --> */}



    <Box className="mainContainerNew">
     



      <Modal
        open={open1}
        onClose={handleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="ModalClass"
      >
        
        <Box sx={style} className="ModalClassBox">
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{ textAlign: "right", padding: "0px 16px" }}
              className="ModalTopGrid"
            >
              <CloseIcon className="closeIconOffer" onClick={handleClose1} />
              <Typography
                id="modal-modal-description"
                className="ModalPhomolong"
                sx={{ textAlign: "center" }}
              >
                Share with Social Media{" "}
              </Typography>
              <Grid
                item
                xs={12}
                sx={{ mb: "20px", marginTop: "40px" }}
                className="borderBoxNone"
                > 
                {localStorage.getItem("autoCountry") ? (<SocialSharePopup CopiedToclipBoardPopUp={CopiedToclipBoardPopUp}  ShareUrl={`/events/${EventName.replaceAll(' ' ,'-')}/` + seletedeventID + "?ts=cs&flag="  + JSON.parse(localStorage.getItem("autoCountry")).sortname}/>) : ""}
              </Grid>
            </Grid>
          </Grid>
        </Box>
        
      </Modal>
      {openLogin ? (
        <ModalPopup handleOpen={openLogin} handleClick={handleClose} />
      ) : (
        ""
      )}

<Snackbar
    open={CCBoardPopUp}
    autoHideDuration={1000}
    onClose={handleCloseCpy}
    message="Copied to Clipboard"
    action={action}
    anchorOrigin={{vertical:"bottom" , horizontal:"center"}} 
  />
    </Box>
    </>
  );
};
export default Comingsoon;
