import React from 'react'
import { Box, Card } from '@mui/material'
import { Grid } from '@mui/material'
import { Typography } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check';
import {useTranslation} from 'react-i18next'
import './style.css'

const Solutions = () => {
  const {t} = useTranslation("common")
  return (
    <Box className='mainContainer' sx={{ marginBottom: '80px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} md={4} lg={4} style={{ paddingLeft: '15px', paddingTop: '64px' }}>
          <div className='solSpacingBtwnR'>
            <Card className='solutionBox'>
              <div style={{ textAlign: 'center' }}>
                <img src='assets/images/swrist.png' alt='Wristband' className='solCommonClass' />
                <Typography className='SolWristTitle'>t{("Wristbands")}</Typography>
              </div>
              <Typography className='solWristText'>{t("Tyvek Wristbands are the preferred choice for event security and identification among event organizers globally")}. </Typography>
            </Card>
          </div>
        </Grid>

        <Grid item xs={12} sm={4} md={4} lg={4} style={{ paddingLeft: '15px', paddingTop: '64px' }}>
          <div className='solSpacingBtwnL'>
            <Card className='solutionBox'>
              <div style={{ textAlign: 'center' }}>
                <img src='assets/images/solStaff.png' alt='Wristband' className='solCommonClass' />
                <Typography className='SolWristTitle'>{t("Staff")}</Typography>
              </div>
              <Typography className='solWristText'>{t("With decade of experience in event management, We have best event support to ensure smooth running of your event access point")}.  </Typography>
            </Card>
          </div>
        </Grid>      
        <Grid item xs={12} sm={4} md={4} lg={4} style={{ paddingLeft: '15px', paddingTop: '64px' }}>
          <div className='solSpacingBtwnL'>
            <Card className='solutionBox'>
              <div style={{ textAlign: 'center' }}>
                <img src='assets/images/solMarketing.png' alt='Wristband' className='solCommonClass' />
                <Typography className='SolWristTitle'>{t("Event_Marketing")}</Typography>
              </div>
              <Typography className='solWristText'>{t(`Get your events in front of the right audience. Access our global live events audience database, while we comply to POPIA laws, GDPR and Privacy consent. We create the content for social media,  needed to wow your audience globally.`)}
              </Typography>
            </Card>
          </div>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Solutions