import { Grid, IconButton } from "@mui/material";
import React from "react";
 
//Icons
import EmailIcon from "@mui/icons-material/Email";
import LinkIcon from "@mui/icons-material/Link";
 



//React-social-Share
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  TwitterIcon,
  EmailShareButton,
} from "react-share";

const SocialSharePopup = ({ ShareUrl , CopiedToclipBoardPopUp }) => {
  

 console.log(ShareUrl , 'share')

      

  return (
    <div>
      <Grid
        item
        xs={12}
        sx={{ mb: "20px", marginTop: "40px" }}
        className="borderBoxNone"
      >
        <Grid
          container
          justifyContent="center"
          sx={{ textAlign: "center" }}
          spacing={2}
        >
          <Grid item sm={2} md={2}>
            <FacebookShareButton
              url={
                window.location.hostname === "localhost"
                  ? `http://localhost:3000${ShareUrl}`
                  : window.location.hostname === "staging.ticketkore.io"
                  ? `https://staging.ticketkore.io/website${ShareUrl}`
                  : `https://ticketkore.io/${ShareUrl}`
              }
             
            >
              <FacebookIcon size={32} round={true} />
            </FacebookShareButton>
          </Grid>
          <Grid item sm={2} md={2}>
            <TwitterShareButton
              url={
                window.location.hostname === "localhost"
                  ? `http://localhost:3000${ShareUrl}`
                  : window.location.hostname === "staging.ticketkore.io"
                  ? `https://staging.ticketkore.io/website${ShareUrl}`
                  : `https://ticketkore.io${ShareUrl}`
              }
              onClick={()=>  CopiedToclipBoardPopUp(false)}
            >
              <TwitterIcon size={32} round={true} />
            </TwitterShareButton>
          </Grid>
          <Grid item sm={2} md={2}>
            <WhatsappShareButton
              url={
                window.location.hostname === "localhost"
                  ? `http://localhost:3000${ShareUrl}`
                  : window.location.hostname === "staging.ticketkore.io"
                  ? `https://staging.ticketkore.io/website${ShareUrl}`
                  : `https://ticketkore.io${ShareUrl}`
              }
            >
              <WhatsappIcon size={32} round={true} />
            </WhatsappShareButton>
          </Grid>

          <Grid item sm={2} md={2}>
            <EmailShareButton
              url={
                window.location.hostname === "localhost"
                  ? `http://localhost:3000${ShareUrl}`
                  : window.location.hostname === "staging.ticketkore.io"
                  ? `https://staging.ticketkore.io/website${ShareUrl}`
                  : `https://ticketkore.io${ShareUrl}`
              }
            >
              <EmailIcon sx={{ fontSize: "32px" }} round={true} />
            </EmailShareButton>
          </Grid>
          <Grid item sm={2} md={2}>
          <IconButton
          onClick={() =>{            navigator.clipboard.writeText(
              window.location.hostname === "localhost"
                  ? `http://localhost:3000${ShareUrl}`
                  : window.location.hostname === "staging.ticketkore.io"
                  ? `https://staging.ticketkore.io/website${ShareUrl}`
                  : `https://ticketkore.io${ShareUrl}`
            )
            
            CopiedToclipBoardPopUp(false)
          }
          }
        >
          <LinkIcon className="share-social-icons" size={36} />
        </IconButton>
        </Grid>
        </Grid>
      </Grid>
    
    </div>
  );
};

export default SocialSharePopup;
