import React from "react";
import { Box } from "@mui/system";
import { Paper, styled } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { useLocation, useNavigate } from "react-router-dom";

import "./style.css";

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(0),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const BreadCrumbs = (props) => {
  let navigate = useNavigate();
  let { pathname } = useLocation();

  const pathnames = pathname.split("/").filter(Boolean);
  if (!isNaN(pathnames[pathnames.length - 1])) {
    pathnames.splice(pathnames.length - 1, 1);
  }

  return (
    <div>
      <Box className="mainContainer">
        <Stack spacing={2} className="breadCrumb">
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {pathnames.length ? (
              <Link onClick={() => navigate("/")}>Home</Link>
            ) : (
              <Typography> Home </Typography>
            )}
            {pathnames.map((name, index) => {
              const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
              const isLast = index === pathnames.length - 1;
              return isLast ? (
                <Typography key={name}>{name}</Typography>
              ) : (
                <Link key={name} onClick={() => navigate(routeTo)}>
                  {name}
                </Link>
              );
            })}
          </Breadcrumbs>
        </Stack>
      </Box>
    </div>
  );
};

export default BreadCrumbs;
