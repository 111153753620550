import { Box } from "@mui/material";
import React from "react";

const index = () => {
  return (
    <Box
      sx={{
        textAlign: "center",
        width: "100%",
        height: "50vh",
        mt: 4,
        fontWeight: 700,
        fontSize: "24px",
        fontFamily: "unset",
        textTransform: "capitalize",
      }}
    >
      Sorry! no offer availabel yet.
    </Box>
  );
};

export default index;
