import React from 'react'
import { Box, Card } from '@mui/material'
import { Grid } from '@mui/material'
import { Typography } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check';
import './style.css'

const MobilePolicy = () => {
  return (
    <Box className='mainContainer' sx={{paddingBottom: '30px'}}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} className="mobFooterCmn">
          <Typography className='policyIntroTypo'>INTRODUCTION </Typography>
          <Typography className='policyContent'>Respecting and protecting your privacy and Personal Information (refer to the definition of Personal Information at the end of this policy statement) is very important to Altarplus(Pty) Ltd trading as Ticketkore™. It is also a Constitutional right and good business practice requirement which we take very seriously.
            This policy applies to and is followed by, Altar Plus (Pty) Ltd and its related entities. This policy governs the relationship between you and the specific Ticketkore to which you have registered online or have otherwise provided personal information.
            In line with the 8 Conditions for Lawful Procession of Personal Information as set out in the Protection of Personal Information Act no 4 of 2013 (the Act), we:
          </Typography>
          <Typography>
            <ul className='policyUlList'>
              <li><CheckIcon className='ulCheckIcns' />Accept joint responsibility and accountability with you to responsibly manage and protect your Personal Information when providing our services and solutions to you;</li>
              <li><CheckIcon className='ulCheckIcns' />Undertake to collect and process only such Personal Information which is necessary given the purpose for which it is processed and to assist you with your required solutions, conclude the necessarily related agreements and consider the legitimate legal interests of everyone concerned, as required by the Act. We will at all times respect your right to withdraw your consent for the processing of your Personal Information;</li>
              <li><CheckIcon className='ulCheckIcns' />Undertake to only use your Personal Information for the purpose for which the information is essential to enable us to assist you or provide solutions to you;</li>
              <li><CheckIcon className='ulCheckIcns' />Undertake not to share or further process your Personal Information with anyone or for any reason if not required for assisting you with your solutions or as required in terms of legislation or regulations;</li>
              <li><CheckIcon className='ulCheckIcns' />Undertake to take reasonably practicable steps to ensure that information is complete, accurate, not misleading and, where necessary, is updated;</li>
              <li><CheckIcon className='ulCheckIcns' />Undertake to be open and transparent on the nature, extent, and reasons for processing Personal Information;</li>
              <li><CheckIcon className='ulCheckIcns' />Undertake to safeguard and protect your Personal Information in our possession;</li>
              <li><CheckIcon className='ulCheckIcns' />Undertake to freely confirm what Personal Information we hold of you, to update and rectify the Personal Information upon request, and to keep it for no longer than required.</li>
            </ul>
          </Typography>

          <Typography className='policyContent'>
            By providing us Ticketkore with your Personal Information, you agree to this Policy and authorise us to process such information as set out herein and you authorise Ticketkore and any associated entities or third parties (where applicable) for the purposes set out herein. We will not use your Personal Information for any other purpose than that set out in this Policy, and we will take the necessary steps to secure the integrity and confidentiality of Personal Information in our possession and under our control by taking appropriate and reasonable measures to prevent loss of, damage to or unauthorised destruction of your Personal Information and to prevent the unlawful access to or processing of Personal Information
          </Typography>
          <Typography className='policyIntroTypo'>REASONS FOR PROCESSING PERSONAL INFORMATION</Typography>
          <Typography className='policyContent'>We, or the entities who provide or assist with the solutions you required (if any), need to collect, use and keep your Personal Information as prescribed by relevant legislation and regulations and for reasons such as:</Typography>
          <Typography>
            <ul className='policyUlList'>
              <li> <CheckIcon className='ulCheckIcns' />To provide all relevant services listed on our website and in our Terms of Use;</li>
              <li> <CheckIcon className='ulCheckIcns' />To respond to your queries;</li>
              <li> <CheckIcon className='ulCheckIcns' />To confirm and verify your identity or to verify that you are an authorised user for security purposes;</li>
              <li> <CheckIcon className='ulCheckIcns' />To comply with all legislative or regulatory requirements related to services provided to you by us;</li>
              <li> <CheckIcon className='ulCheckIcns' />To fulfil our contractual obligations to you;</li>
              <li> <CheckIcon className='ulCheckIcns' />To communicate with you and to carry out instructions and requests,</li>
              <li> <CheckIcon className='ulCheckIcns' />For any other operational purposes required to assist you with the solutions you require;</li>
              <li> <CheckIcon className='ulCheckIcns' />In connection with possible requirements by the Information Regulator or other Government agencies allowed by law, legal proceedings, or court rulings.</li>
            </ul>
          </Typography>
          <Typography className='policyIntroTypo'>SHARING OR TRANSFER OF PERSONAL INFORMATION</Typography>
          <Typography className='policyContent'>Our employees will have access to your Personal Information to administer and manage our services and internal business processes. In general, we do not share your Personal Information with third parties (other than service providers acting on our behalf) unless we have a lawful basis for doing so.</Typography>
          <Typography className='policyInnerContent'>We may need to share your Personal Information and/or utilise software or online platforms to enter and process your information for business management purposes. This will only be done in strict adherence to the requirements of the Act. We also have agreements in place to ensure that they comply with the privacy requirements as required by the Act.
            We may also disclose your information:</Typography>

          <Typography>
            <ul className='policyUlList'>
              <li><CheckIcon className='ulCheckIcns' />Where we have a duty or a right to disclose in terms of legislation, regulations, or industry codes;</li>
              <li><CheckIcon className='ulCheckIcns' />Where we believe it is necessary to protect our rights;</li>
              <li><CheckIcon className='ulCheckIcns' />When explicitly requested by you;</li>
              <li><CheckIcon className='ulCheckIcns' />Professional advisers, for example, law firms, as necessary to establish, exercise, or defend our legal rights and obtain advice in connection with the running of our business. Personal Information may be shared with these advisers as necessary in connection with the services they have been engaged to provide.</li>
              <li><CheckIcon className='ulCheckIcns' />To law enforcement, regulatory and other government agencies, and professional bodies, as required by and/or following applicable law or regulation. We may also review and use your personal information to determine whether the disclosure is required or permitted.</li>
            </ul>
          </Typography>
          <Typography className='policyIntroTypo'>INFORMATION SECURITY</Typography>
          <Typography className='policyContent'>We are legally obliged to provide adequate protection for the Personal Information we hold and to stop unauthorised access and use thereof. We will, on an ongoing basis, continue to review our security controls and related processes to ensure that your Personal Information remains secure.</Typography>
          <Typography className='policyInnerContent'>Generally accepted standards of technology and operational security have been implemented to protect information from loss, misuse, alteration, or destruction. All our employees are trained on information security and are required to keep Personal Information confidential and only authorised persons have access to such information.</Typography>
          <Typography className='policyInnerContent'>When we contract with third parties, we impose appropriate security, privacy, and confidentiality obligations on them to ensure that personal information that we remain responsible for, is kept secure.</Typography>

          <Typography className='policyIntroTypo'>RETENTION OF PERSONAL INFORMATION</Typography>
          <Typography className='policyContent'>We shall only retain and store Personal Information for the period for which the data is required to serve its primary purpose or legitimate interest or for the period required to comply with any applicable legal requirement, whichever is longer.</Typography>

          <Typography className='policyIntroTypo'>YOUR RIGHTS: ACCESS TO INFORMATION</Typography>
          <Typography className='policyContent'>You have the right to request a copy of the Personal Information we hold about you. To do this, simply contact us via the numbers/addresses provided below or on our website and specify what information you require. We will need proof of authorisation or a copy of your ID document to confirm your identity before providing details of your personal information.</Typography>
          <Typography className='policyInnerContent'>Please note that any such access request may be subject to payment of a legally allowable fee.</Typography>

          <Typography className='policyIntroTypo'>AMENDMENTS TO YOUR INFORMATION</Typography>
          <Typography className='policyContent'>You have the right to ask us to update, correct or delete your personal information. We will require proof of identity and/or authority before making changes to the personal information we may hold of you. We would appreciate it if you would keep your personal information accurate and up to date.</Typography>

          <Typography className='policyIntroTypo'>COOKIE POLICY</Typography>
          <Typography className='policyContent'>We currently do not make use of cookies on this website, at such time as we introduce cookies on this website you will be informed of this when you visit this website, and our “cookie” policy will then be available on this website.</Typography>

          <Typography className='policyIntroTypo'>HOW TO CONTACT US</Typography>
          <Typography className='policyContent'>If you have any queries about this policy or need further information about our privacy practices, wish to withdraw consent, exercise preferences, or access or correct your personal information, please contact us at: help@ticketkore.io

            Alternatively, you can contact us on the numbers/addresses listed under the contact us section of our website.</Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

export default MobilePolicy