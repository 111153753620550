// import * as React from 'react';
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography"; 
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import Carousel, { consts } from "react-elastic-carousel";
import React, { useEffect, useState } from "react";
import { useNavigate} from "react-router-dom";
  
import http from "../../Services/http";
 
import "./style.css";
 
 
import ModalPopup from "../ModalPopup";

import Modal from "@mui/material/Modal";
 
import CloseIcon from "@mui/icons-material/Close";
import { ManagePageReloadState } from "../../Services/ManagePageReloadState";
 
import SocialSharePopup from "../SocialSharePopup";
import { Snackbar } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "95%",
  maxWidth: 600,
  bgcolor: "#FFFFFF",
  boxShadow: 24,
  overflow: "auto",
};

const breakPoints = [
  { width: 1, itemsToShow: 2, itemPadding: [10, 3] },
  { width: 550, itemsToShow: 3, itemPadding: [10, 5] },
  { width: 768, itemsToShow: 4, itemPadding: [10, 8] },
  { width: 1200, itemsToShow: 5, itemPadding: [10, 12] },
];

 

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(0),
  color: theme.palette.text.secondary,
}));

const UpcomingEvent = (props) => {
 

const {Talentedparent}= props


  // Module Hoc Function
  const [openLogin, setOpenLogin] = React.useState(false);
  const handleClose = () => {
    setOpenLogin(false);
  };
  

  // Sharemod
  const [open1, setOpen1] = React.useState(false); 
  const handleClose1 = () => setOpen1(false);
  const [seletedeventID, setseletedeventID] = useState(0);

  const[eventName , setEventName]  = useState('')
  const checkLoggedIn = (e, params , event_name) => {
    let modifyEventName = event_name.replaceAll(/[^a-zA-Z0-9 ]/g , "")
    setseletedeventID(params.id)
    // if (!localStorage.getItem("token")) {
    //    setOpenLogin(true);
    // } else {
    //   setOpen1(true);
    //   setEventName(modifyEventName.replaceAll(' ' , '-'))
    // }

    setOpen1(true);
      setEventName(modifyEventName.replaceAll(' ' , '-'))
  };

 
  const [upcomingevent, setupcomingevent] = useState([]);
  const history = useNavigate();
 
  const [CCBoardPopUp, setCCBoardPopUp] = useState(false);
  
  const CopiedToclipBoardPopUp = (data) => { 
  
  setOpen1(data)
  setCCBoardPopUp(true)
};



  const handleCloseCpy = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  setCCBoardPopUp(false);
};

  const action = (
    <React.Fragment>
   
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseCpy}
      >
       
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
 
 
  const dateFormated = (date) => {
    let dateNew = new Date(date);
    let day = dateNew.toLocaleString("en-us", { weekday: "short" });
    let newdate = new Date(date).getDate();
    let month = dateNew.toLocaleString("default", { month: "short" });
    return day + "," + newdate + " " + month;
  };
  

  const handleonviewallClick = (e, id) => {
    history(
      `/ViewAll/${"TalentParticipation"}/${"?flag=" + JSON.parse(localStorage.getItem("autoCountry")).sortname}`,
      {
        state: { id: 3, name: "Talent Participation" },
      }
    );
  };

  const handleeventdetails = (e, id,event_name) => {
    let modifyEventName = event_name.replaceAll(/[^a-zA-Z0-9 ]/g , "")
    history(`/events/${modifyEventName.replaceAll(' ', '-')}/`+ id + "?ts=ts&flag=" + JSON.parse(localStorage.getItem("autoCountry")).sortname);
  };
  // const location  = useLocation()
  // let SelectedCity = localStorage.getItem('SelectedCity') ? JSON.parse(localStorage.getItem('SelectedCity')) : JSON.stringify({});
  useEffect(() => {
    getUE();

    ManagePageReloadState.getPageReloadState().subscribe(res =>{
      if(res.state)
        getUE()
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

   // Upcoming events data
  const getUE = () => {  
      setupcomingevent([])
    let postData = { 
      country_id: localStorage.getItem("autoCountry") ? JSON.parse(localStorage.getItem("autoCountry")).id : '' ,
      city:  localStorage.getItem('SelectedCity') ? JSON.parse(localStorage.getItem('SelectedCity')).id : "",
    };
    http( "talent_participation_events/homepage_talent_participation_events", "POST",  postData)
      .then((res) => { 
         const data = res.data.data ?? [];
         
        // setupcomingevent(data ?? []);
      
        if (data.length > 0) {
          setupcomingevent(data ?? []);
          if (data.length > 5) {
            setShow(true);
          } else {
            setShow(false);
          }
        } else {
          Talentedparent(true);
        }
       }) 
         .catch((err) => {
      });
  };

  const [show, setShow] = React.useState(false);
 
  return (
    <Box className="mainContainer">
      {/* ShareMmodal Start */}

      <Modal
        open={open1}
        onClose={handleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="ModalClass"
      >
        <Box sx={style} className="ModalClassBox">
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{ textAlign: "right", padding: "0px 16px" }}
              className="ModalTopGrid"
            >
              <CloseIcon className="closeIconOffer" onClick={handleClose1} />
              <Typography
                id="modal-modal-description"
                className="ModalPhomolong"
                sx={{ textAlign: "center" }}
              >
                Share with Social Media{" "}
              </Typography>
               
            {localStorage.getItem("autoCountry") ? (<SocialSharePopup CopiedToclipBoardPopUp={CopiedToclipBoardPopUp} ShareUrl={`/events/${eventName}/` + seletedeventID + "?ts=ts&flag=" + JSON.parse(localStorage.getItem("autoCountry")).sortname}/>) : ""}
            </Grid>
          </Grid>
        </Box>
      </Modal>

      {/* Sharemodalend */}

      {upcomingevent.length > 0 ? (
        <Grid container>
          <Grid item md={6} xs={8} lg={6}>
            <Item Item sx={{ textAlign: "left" }} className="boxShadowNone">
              <Typography
                className="talentPart"
                style={{
                  fontFamily: "Lato",
                  fontWeight: "700",
                  fontSize: "22px",
                  color: "#2C2C2C",
                }}
              >
                Talent Participation
              </Typography>
            </Item>
          </Grid>
          {upcomingevent.length > 4 ? (
            <Grid item md={6} xs={4} lg={6}>
              <Item Item sx={{ textAlign: "right" }} className="boxShadowNone">
                <span
                  className="viewAllSpan"
                  style={{
                    border: "1px solid #0CA8F6",
                    display: "inline-flex",
                    color: "#0CA8F6",
                    borderRadius: "6px",
                    width: "92px",
                    justifyContent: "flex-end",
                    height: "32px",
                    alignItems: "center",
                  }}
                >
                  <button
                    className="viewAllButton mobViewAllBtn"
                    style={{
                      border: "none",
                      background: "transparent",
                      color: "#0CA8F6",
                      fontWeight: "400",
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                    onClick={handleonviewallClick}
                  >
                    View All{" "}
                  </button>
                  <KeyboardArrowRightOutlinedIcon />
                </span>
              </Item>
            </Grid>
          ) : (
            ""
          )}

          <Grid item lg={12} sx={{ width: "100%", padding: "20px 0px" }}>
            {/* {isLoading ? (
              <div className="loader">
                <ClipLoader />
              </div>
            ) : ( */}
            <div className="styling-example">
              <Carousel
                breakPoints={breakPoints}
                // renderArrow={myArrow}
                itemPosition={consts.START}
                // itemPosition={"START"}
                showEmptySlots
                spacing={3}
                showArrows={show}
                disableArrowsOnEnd={true}
              >
                {upcomingevent.map((data, index) => (
                    <Item
                      key={index}
                      className="boxShadowNone"
                      // onClick={(e) => handleeventdetails(e, data.id)}
                      // style={{ cursor: "pointer", borderRadius:"10px" }}
                      sx={{
                        cursor: "pointer",
                        borderRadius: "10px !important",
                      }}
                    >
                      {/* <div className="CardDiv" style={{ position: "relative" }}>
                        <CardMedia
                          component="img"
                          image={
                            data.event_thumbnail
                              ? data.event_thumbnail
                              : "assets/images/Rectangle.png"
                          }
                          alt="Paella dish"
                        /> */}
                      <div className="CardDiv" style={{ position: "relative" }}>
                        <CardMedia
                          component="img"
                          image={
                            data.event_thumbnail
                              ? data.event_thumbnail
                              : "assets/images/Rectangle.png"
                          }
                          alt="Paella dish"
                          onClick={(e) => handleeventdetails(e, data.id , data.event_name)}
                        />
                      </div>

                      <CardContent
                        style={{
                          display: "flex",
                          alignItems: "baseline",
                          justifyContent: "space-between",
                          padding: "0px",
                          cursor: "pointer",
                          position: "relative",
                        }}
                      >
                        <Typography className="sliderCartText">
                          {data.event_name}
                        </Typography>
                        <IconButton
                          aria-label="share"
                          className="IconButton"
                          onClick={(e) => checkLoggedIn(e, data , data.event_name)}
                        >
                          <ShareOutlinedIcon />
                        </IconButton>
                      </CardContent>
                      <Typography
                        style={{
                          fontWeight: "400",
                          fontSize: "14px",
                          color: "#686868",
                          padding: "10px 0px",
                        }}
                      >
                        {/* {dateFormated(data.event_start_date)} {data.event_time} */}
                        { dateFormated(data.event_start_date)} {  data.event_start_date && data.event_end_date? 'Onwards' : ""} 
                      </Typography>
                    </Item>              
                ))}
              </Carousel>
            </div>
            {/* )} */}
          </Grid>
        </Grid>
      ) : (
        ""
      )}
      {openLogin ? (
        <ModalPopup handleOpen={openLogin} handleClick={handleClose} />
      ) : (
        ""
      )}

<Snackbar
    open={CCBoardPopUp}
    autoHideDuration={1000}
    onClose={handleCloseCpy}
    message="Copied to Clipboard"
    action={action}
    anchorOrigin={{vertical:"bottom" , horizontal:"center"}} 
  />
    </Box>
  );
};
export default UpcomingEvent;
