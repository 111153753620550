import { Button, Card, Grid, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import "./style.css";
import http from "../../Services/http";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import ClipLoader from "react-spinners/ClipLoader";
import Backdrop from "@mui/material/Backdrop";
import CloseIcon from "@mui/icons-material/Close";
import Navbar from "../Navbar";
import getSymbolFromCurrency from "currency-symbol-map";
import { Breadcrumbs } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Dialog from "@mui/material/Dialog";
import CircularProgress from "@mui/material/CircularProgress";
import ShowLocationDates from "../ShowlocationDate&TimePopUp";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import {useTranslation} from 'react-i18next'
// stripe dependencies
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../CheckoutForm";

// Refund Terms and condition Pop Up
import RefTermsNCond from "../RefTermsNCond";
import moment from "moment/moment";

// images

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  borderRadius: "16px",
  background: "#fff",
  boxShadow: "1px 1px 5px #ccc",
  padding: "24px",
  maxWidth: "600px",
};

// Stripe Promise
const stripePromise =
  window.location.hostname === "ticketkore.io"
    ? loadStripe(
        "pk_live_51LShl9LcQPogeGPUUffgWb8S1IjNcie9i65kTndVoyvIfTVJBqcyRtUZmVAg5ECx4JPdjJZjNbgtc1lYIai4CPIH00OYVvangA"
      )
    : loadStripe(
        "pk_test_51LShl9LcQPogeGPUy8aBGDu542pjIF6BjKu47uZruUzoBPSvG9hWHIBP1fxJX6l5nkJ0IIoXGZna0eKhHPyJFD1s00KOi1TJ8X"
      );
// const stripePromise = loadStripe("pk_live_51LShl9LcQPogeGPUUffgWb8S1IjNcie9i65kTndVoyvIfTVJBqcyRtUZmVAg5ECx4JPdjJZjNbgtc1lYIai4CPIH00OYVvangA");

const SeatBooking = () => {
  const location = useLocation();
  const {t} = useTranslation("common")
  console.log(location);
  const {ex_category_id,upgrade_cat} = location?.state || {}
  const [openBackdrop, setopenBackdrop] = React.useState(false);
  const [openDate, setOpenDate] = useState(false);
  const [selectedLocationData, setSelectedLocationData] = useState({});
  const [selectedRefundRadioBtn, setSelectedRefundRadioBtn] =
    useState("refund-false");
  const [openRefSec, setOpenRefSec] = useState(0);
const [appliedDiscount, setAppliedDiscount] = useState(null)
const [appliedCouponDiscount, setAppliedCouponDiscount] = useState(null)
  const handleOpenDates = (data) => {
    setOpenDate(true);
    setSelectedLocationData(data);
  };
  const handleCloseDate = () => {
    setOpenDate(false);
  };
  const closePopup = (message) => {
    setOpenDate(message);
  };

  const handleCloseBackDrop = () => {
    setopenBackdrop(false);
  };

  const Eventname = location?.state?.eventData?.event_name?.replaceAll(
    /[^a-zA-Z0-9 ]/g,
    ""
  );
  const selectedSeatsObjects = localStorage.getItem("label")
    ? JSON.parse(localStorage.getItem("label"))
    : {};
  const labels = localStorage.getItem("labels")
    ? JSON.parse(localStorage.getItem("labels"))
    : [];
  const row =
    labels.length > 0
      ? labels.reduce((acc, current) => {
          const x = acc.find((item) => item.parent === current.parent);
          if (!x) {
            return [...acc, current];
          } else {
            return acc.map((x) =>
              x.id === current.id ? { ...x, msg: x.msg + current.msg } : x
            );
          }
        }, [])
      : "";

  // Stripe Integration
  const [clientSecret, setClientSecret] = useState(" ");
  const appearance = {
    theme: "flat",
  };

  const options = {
    clientSecret,
    appearance,
  };

  const EvenTIDloc = location?.state?.location.event_id
    ? location?.state?.location.event_id
    : "";

  const getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key, value) => {
      if (typeof value === "object" && value !== null) {
        if (seen.has(value)) {
          return;
        }
        seen.add(value);
      }
      return value;
    };
  };

  if (location.state) {
    let result = "";

    delete location.state.eventData.event_date_time;
    // debugger
    // result = JSON.stringify(location.state);
    result = JSON.stringify(location.state, getCircularReplacer());
    localStorage.setItem("locationState", result);
    localStorage.setItem("label", JSON.stringify(location.state.selSeatLabel));
  }

  let Orderdetailsdata =
    location.state && location.state.eventData
      ? location.state.eventData && location.state.eventD
      : JSON.parse(localStorage.getItem("locationState")).eventData;
  const navigate = useNavigate();

  const location1 =
    location.state && location.state.location
      ? location.state.location
      : JSON.parse(localStorage.getItem("locationState")).location;
  // location.state.location;
  const time =
    location.state && location.state.time
      ? location.state.time
      : JSON.parse(localStorage.getItem("locationState")).time;
  const ticketId =
    location.state && location.state.ticketId
      ? location.state.ticketId
      : JSON.parse(localStorage.getItem("locationState")).ticketId;
  const quantity =
    location.state && location.state.quantity
      ? location.state.quantity
      : JSON.parse(localStorage.getItem("locationState")).quantity;
 const hybrid =
    location.state && location.state.hybrid
      ? location.state.hybrid
      : JSON.parse(localStorage.getItem("locationState")).hybrid;
 const is_ticket_free =
    location.state && location.state.is_ticket_free
      ? location.state.is_ticket_free
      : JSON.parse(localStorage.getItem("locationState")).is_ticket_free;
  const Selecteddatetime =
    location.state && location.state.Selecteddatetime
      ? location.state.Selecteddatetime
      : JSON.parse(localStorage.getItem("locationState")).Selecteddatetime;

  const skip =
    location.state && location.state.skip
      ? location.state.skip
      : JSON.parse(localStorage.getItem("locationState")).skip;
  const holdingAdons =
    location.state && location.state.holdingAddons
      ? location.state.holdingAddons
      : JSON.parse(localStorage.getItem("locationState")).holdingAddons ?? [];

  const totalTicketAmountChillers =
    location.state && location.state.totalTicketAmountChillers
      ? location.state.totalTicketAmountChillers
      : JSON.parse(localStorage.getItem("locationState"))
          .totalTicketAmountChillers ?? 0;
  const [odnocoupAply, setODnocoupAply] = useState([]);
  const [couponCodeApply, setCodeCouponApply] = useState("");
  const [value, setValue] = useState("");
  const [ApplyButton, setApplyButton] = useState(false);
  const [arrayAddons, setarrayAddons] = useState([{ Bookeventticket: [] }]);
  const [resBeforeCouponApplied, setresBeforeCouponApplied] = useState({});
  const [openAddWallet, setOpenAddWallet] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [disablePaymentBtn, setDisablePaymentBtn] = useState(false);
  const [orderID, setOrderID] = useState(0);
  const [freeTkCouponAllowed, setFreeTkCouponAllowed] = useState("0");
  const [paymentMethod, setPaymentMethod] = useState("card");

  let Autocountry = JSON.parse(localStorage.getItem("autoCountry"))
    ? JSON.parse(localStorage.getItem("autoCountry"))
    : {};
  let userCountry = JSON.parse(localStorage.getItem("loginUser"))
    ? JSON.parse(localStorage.getItem("loginUser"))
    : {};

  // loginUser

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();
  const urlId = query.get("id");

  // console.log(location.pathname , 'navigate')
  const handleSubmodelClose = () => {
    if (selectedSeatsObjects) {
      navigate(`/events/${Eventname.replaceAll(" ", "-")}/${EvenTIDloc}`);
    }
    setOpenAddWallet(false);
    // Navigate("-1");
  };
  useEffect(() => {
    if (
      new URLSearchParams(window.location.search).get(
        "payment_intent_client_secret"
      )
    ) {
      paymentStatus(
        new URLSearchParams(window.location.search).get("payment_intent")
      );
    }
    let arrayAddonss = {};
    if (query.get("id")) {
      paymentStatus(query.get("id"));
    }

    holdingAdons.forEach((element) => {
      if (arrayAddonss[element.web_action_type_name]) {
        if (element.extra_fields) {
          let objValues = {};
          element.extra_fields.fields_data.forEach((ele) => {
            ele.values.forEach((newele) => {
              objValues.att_option_value_label = ele.label;
              objValues.att_option_value_id = ele.main_attr_id;
              objValues.attr_label = newele.label;
              objValues.attr_id = newele.attr_id;
            });
          });

          if (element.attr) {
            element.attr.push(objValues);
          } else {
            element.attr = [];
            element.attr.push(objValues);
          }
        }
        if (element.commission_percent) {
          element.admin_commission = element.commission_percent;
        }
        element.per_service_price = element.price;
        element.service_id = element.action_id;
        element.service_purchase_quantity = element.service_quantity;
        element.service_title = element.title;
        element.total_price = element.price * element.service_quantity;

        arrayAddonss[element.web_action_type_name].push(element);
      } else {
        arrayAddonss[element.web_action_type_name] = [];
        if (element.extra_fields) {
          let objValues = {};
          element.extra_fields.fields_data.forEach((ele) => {
            ele.values.forEach((newele) => {
              objValues.att_option_value_label = ele.label;
              objValues.att_option_value_id = ele.main_attr_id;
              objValues.attr_label = newele.label;
              objValues.attr_id = newele.attr_id;
            });
          });

          if (element.attr) {
            element.attr.push(objValues);
          } else {
            element.attr = [];
            element.attr.push(objValues);
          }
        }
        if (element.commission_percent) {
          element.admin_commission = element.commission_percent;
        }
        element.per_service_price = element.price;
        element.service_id = element.action_id;
        element.service_purchase_quantity = element.service_quantity;
        element.service_title = element.title;
        element.total_price = element.price * element.service_quantity;
        arrayAddonss[element.web_action_type_name].push(element);
      }
    });

    //clone.arrayAddonss = arrayAddonss;
    const clone = [...arrayAddons];
    clone.forEach((e) => (e.Bookeventticket = arrayAddonss));
    setarrayAddons(clone);

    query.get("id") ||
    new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    ) ? (
      <></>
    ) : (
      OD_noCoupAply(arrayAddonss)
    );
  }, [urlId]);

  const paymentStatus = (id) => {
    setopenBackdrop(true);
    const addedData = {
      id:
        JSON.parse(localStorage.getItem("paymentMethod")) === "card"
          ? id
          : JSON.parse(localStorage.getItem("merchantTID")),
      order_id: JSON.parse(localStorage.getItem("orderID")),
    };

    http(
      JSON.parse(localStorage.getItem("paymentMethod")) === "card"
        ? "orders/peach_order_payment_status"
        : "orders/eft_order_payment_status",
      "POST",
      addedData
    )
      .then((res) => {
        if (res.data.status === 1) {
          localStorage.removeItem("label");
          localStorage.removeItem("labels");
          localStorage.removeItem("Chart_Id");
          localStorage.removeItem("holdToken");
          localStorage.removeItem("paymentMethod");
          setTimeout(() => {
            setopenBackdrop(false);
            NotificationManager.success(res.data.msg);

            navigate(
              `/OrderDetails/${JSON.parse(
                localStorage.getItem("orderID")
              )}/?flag=${Autocountry.sortname}`,
              {
                replace: true,
                state: { fromMyBooking: true },
              }
            );
          }, 3000);
        } else {
          setTimeout(() => {
            setopenBackdrop(false);
            NotificationManager.error(res.data.msg);
            navigate(
              `/OrderDetails/${JSON.parse(
                localStorage.getItem("orderID")
              )}/?flag=${Autocountry.sortname}`,
              {
                replace: true,
                state: { fromMyBooking: true },
              }
            );
          }, 3000);
        }
      })
      .catch((err) => {});
  };

  // -----------------before_applied_coupon

  const OD_noCoupAply = (arrayAddonss) => {
    let seldateByUser = "";
    let timeString = "";
    let date = "";
    let formattedTime = "";
    setopenBackdrop(true);
    if (location.state.calSelDate !== "") {
      timeString = location.state.time.time;
      seldateByUser = moment(location.state.calSelDate)
        .format("YYYY MM DD")
        .replaceAll(" ", "-");
      date = new Date(`${seldateByUser} ${timeString}`);
      formattedTime = date.toString().slice(16, 24);
    }
    let postData = {
      location_id: time.location_id,
      user_selected_date: `${seldateByUser} ${formattedTime}`,
      ticket_categories: [
        {
          quantity: quantity,
          ticket_id: ticketId,
          hybrid: hybrid,
          is_ticket_free: is_ticket_free,
        },
      ],
    };
    postData.ticket_categories[0].upgrade_cat = upgrade_cat
    if(ex_category_id !== 0){
      postData.ticket_categories[0].ex_category_id = ex_category_id
    }
   
    setAppliedCouponDiscount(null)
    http(
      "orders/order_amount_calculation_before_applied_coupon",
      "POST",
      postData,undefined,undefined,'v17').then((res) => {
      if (res.data.status === 1) {
        setAppliedDiscount(res.data.data?.total_discount)
        console.log('res.data.data',res.data.data)
        const responseData = {...res.data.data}
        if(location?.state?.eventD?.allow_freeTicket_text === 1){
          responseData.total_paybal_amount = responseData.ticket_total_real_amount
        }
        setODnocoupAply(responseData);
        setCodeCouponApply("");
        setValue("");
        setresBeforeCouponApplied(res.data.data);
        setTicketDetailAllCases(res.data.data.ticket_calculation);
        setOpenRefSec(res.data.data.upgradeTicketFlag);
        setFreeTkCouponAllowed(res.data.data.freeTicketAllowed);
        // arrayAddons
        setApplyButton(false);
        setopenBackdrop(false);
      } else {
        NotificationManager.error(res.data.msg, "", 1000);
        setopenBackdrop(false);
      }
    });
  };

  // set ticket calc before coupoun applied and after coupoun applied with check date range
  const setTicketDetailAllCases = (ticket_calculation) => {
    let arrTicket = [];
    ticket_calculation.forEach((ele) => {
      let objTicket = {};
      objTicket.is_ticket_free = is_ticket_free;
      objTicket.hybrid = hybrid;
      objTicket.base_price = ele.base_price;
      objTicket.random_order_id = ele.random_order_id;
      objTicket.ticket_id = ele.ticket_id;
      objTicket.vat_percentage = ele.vat_percentage;
      objTicket.vat_amount = ele.vat_amount;
      objTicket.ticket_price_after_applied_tax =
        ele.ticket_price_after_applied_tax;
      objTicket.total_price = ele.real_price_mul_quantity;
      objTicket.commission_amount = ele.ticket_commission_amount;
      objTicket.commission_percentage = ele.ticket_commission_percentage;
      objTicket.per_ticket_price = ele.real_price;
      objTicket.ticket_quantity = ele.quantity;
      objTicket.ticket_category = ele.ticket_classs;
      objTicket.final_ticket_price = ele.ticket_price_after_applied_tax;
      objTicket.discount_amount = ele.discount_amount;
      objTicket.coupon_percentage = ele.coupon_percentage;
      objTicket.calc_amount_id = ele.id;
      arrTicket.push(objTicket);
    });
    const clone2 = [...arrayAddons];
    clone2.map((e) => (e.tickets = arrTicket));
    setarrayAddons(() => [...clone2]);
  };
  // -----------------check_coupon_status
  const CheckCouponstatus = (e) => {
    setValue(e);
    let Coupondata = {
      location_id: time.location_id,
      coupon_code: e ? e : couponCodeApply,
      event_id: Orderdetailsdata?.id,
      ticket_categories: [{ quantity: quantity, ticket_id: ticketId }],
    };
    setAppliedCouponDiscount(null)
    http("orders/check_coupon_status", "POST", Coupondata).then((res) => {
      if (res.data.status === 1) {
        setApplyButton(true);
        setODnocoupAply(res.data.data);
        setAppliedCouponDiscount(res.data.data.coupon_discount_amount)
        setTicketDetailAllCases(res.data.data.ticket_calculation);
        NotificationManager.success(res.data.msg, "", 1000);

        if (parseInt(res.data.data.total_paybal_amount) === 0) {
          if (selectedRefundRadioBtn === "refund-true") {
            setSelectedRefundRadioBtn("refund-false");
          }
        }
      } else {
        NotificationManager.error(res.data.msg, "", 1000);
      }
    });
  };

  // -----------------orders/book_event_tickets
  const OrderBookeventticket = () => {
    let userData = localStorage.getItem("loginUser")
      ? JSON.parse(localStorage.getItem("loginUser"))
      : {};
    // setDisablePaymentBtn(true);

    let Bookeventticket = {};
    arrayAddons.forEach((e) => {
      e.Bookeventticket.tickets = e.tickets;
      e.Bookeventticket.base_price_coupon_range_discount =
        odnocoupAply.coupon_range_discount;
      e.Bookeventticket.event_id = location.state.checkPrivateEvent
        ? location.state.eventData.event_id
        : location.state.eventData.id;
      e.Bookeventticket.final_amount =
        selectedRefundRadioBtn === "refund-true"
          ? parseFloat(
              parseFloat(odnocoupAply.total_paybal_amount) +
                parseFloat(totalTicketAmountChillers) +
                parseFloat(location.state?.eventData?.upgrade_ticket_price)
            ).toFixed(2)
          : parseFloat(
              parseFloat(odnocoupAply.total_paybal_amount) +
                parseFloat(totalTicketAmountChillers)
            ).toFixed(2);
      e.Bookeventticket.coupon_discount_amount =
        odnocoupAply.coupon_range_discount;
      e.Bookeventticket.coupon_id = odnocoupAply.coupon_details
        ? odnocoupAply.coupon_details.id
        : "";
      e.Bookeventticket.coupon_percentage = odnocoupAply.coupon_details
        ? odnocoupAply.coupon_percentage
        : "0";
      e.Bookeventticket.currency = odnocoupAply.currency;
      e.Bookeventticket.date_range_percentage =
        odnocoupAply.date_range_percentage;
      e.Bookeventticket.ticket_commission_total_amount =
        odnocoupAply.commission_amount; // (if not availabel send zero)
      e.Bookeventticket.ticket_total_base_amount =
        odnocoupAply.ticket_total_base_amount;
      e.Bookeventticket.ticket_total_real_amount =
        odnocoupAply.ticket_total_real_amount;
      e.Bookeventticket.total_amount = odnocoupAply.total_paybal_amount;
      e.Bookeventticket.user_id = userData.id;
      e.Bookeventticket.platform = "web";
      e.Bookeventticket.location_id = location.state.time.location_id;
      e.Bookeventticket.vat_amount =
        odnocoupAply.ticket_calculation[0].vat_amount;
      e.Bookeventticket.vat_percentage =
        odnocoupAply.ticket_calculation[0].vat_percentage;
      e.Bookeventticket.version_number = 1;
      e.Bookeventticket.holdToken = location?.state?.holdToken
        ? location.state.holdToken
        : "";
      e.Bookeventticket.selectedSeatsObjects = selectedSeatsObjects
        ? String(selectedSeatsObjects.map((lable) => lable.label))
        : "";
      Bookeventticket = e.Bookeventticket;
      e.Bookeventticket.is_group_tickets = location?.state?.groupCheck;
      e.Bookeventticket.group_company_name = location?.state?.cpName;
      e.Bookeventticket.group_company_vat_number = location?.state?.cpNum;
      e.Bookeventticket.group_company_address = location?.state?.cpAdd;
      e.Bookeventticket.select_calander_date = location?.state?.calSelDate;
      location?.state?.location_date_range ? (
        (e.Bookeventticket.multi_day_date_range =
          location?.state?.location_date_range)
      ) : (
        <></>
      );
      e.Bookeventticket.is_ticket_upgraded =
        selectedRefundRadioBtn === "refund-true" ? 1 : 0;

      e.Bookeventticket.ticket_upgraded_price =
        selectedRefundRadioBtn === "refund-true"
          ? location.state?.eventData?.upgrade_ticket_price
          : 0;
      e.Bookeventticket.payment_method = paymentMethod === "card" ? "1" : "2";

      e.Bookeventticket.eft_redir_url =
        window.location.hostname === "localhost"
          ? "http://localhost:3000/eft/payment-status"
          : window.location.hostname === "staging.ticketkore.io"
          ? "https://staging.ticketkore.io/website/eft/payment-status"
          : window.location.hostname === "dev.ticketkore.io"
          ? "https://dev.ticketkore.io/website/eft/payment-status"
          : window.location.hostname === "3.16.122.244"
                        ? "http://3.16.122.244:3000/website/eft/payment-status"
          : "https://ticketkore.io/eft/payment-status";
    });

    if (paymentMethod === "card") {
      http("orders/book_event_tickets", "POST", Bookeventticket, {}, true).then(
        (res) => {
          if (res.data.status === 1) {
            // setODnocoupAply(res.data.data);
            if (res.data.status === 0) {
              NotificationManager.error(res.data.msg);
              setDisablePaymentBtn(false);
            }
            if (res.data.status === 1) {
              // let orderID = res.data.order_id;
              setOrderID(res.data.order_id);
              localStorage.setItem(
                "orderID",
                JSON.stringify(res.data.order_id)
              );
              localStorage.setItem(
                "paymentMethod",
                JSON.stringify(paymentMethod)
              );
              if (res.data.free_event === 0) {
                if (userCountry.country_short_code !== "ZA") {
                  setClientSecret(res.data.checkout_id);
                  setOpenAddWallet(true);
                  setDisablePaymentBtn(false);
                } else {
                  var checkoutId = res.data.checkout_id;

                  var url =
                    window.location.hostname === "localhost" ||
                    window.location.hostname === "staging.ticketkore.io" ||
                    window.location.hostname === "dev.ticketkore.io" ||
                    window.location.hostname === "3.16.122.244"
                      ? `https://eu-test.oppwa.com/v1/paymentWidgets.js?checkoutId=${checkoutId}`
                      : `https://eu-prod.oppwa.com/v1/paymentWidgets.js?checkoutId=${checkoutId}`;

                  const script = document.createElement("script");

                  script.src = url;
                  script.async = true;

                  document.body.appendChild(script);
                  // setCheckPeachPaymentCheckoutId(checkoutId);
                  // setOpen(false);
                  setOpenAddWallet(true);
                  setDisablePaymentBtn(false);
                }
              } else {
                setopenBackdrop(true);
                setTimeout(() => {
                  setopenBackdrop(false);
                  navigate(
                    `/OrderDetails/${JSON.parse(
                      localStorage.getItem("orderID")
                    )}/?flag=${Autocountry.sortname}`,
                    {
                      state: { fromMyBooking: true },
                    }
                  );
                }, 3000);
              }
            } else {
              NotificationManager.error(res.data.msg);
            }
          } else {
            NotificationManager.error(res.data.msg);
          }
        }
      );
    } else {
      setopenBackdrop(true);
      http("orders/book_eft_tickets", "POST", Bookeventticket, {}, true).then(
        (res) => {
          if (res.data.status === 1) {
            // setODnocoupAply(res.data.data);
            setopenBackdrop(false);
            if (res.data.status === 0) {
              NotificationManager.error(res.data.msg);
              setDisablePaymentBtn(false);
            }
            if (res.data.status === 1) {
              // let orderID = res.data.order_id;
              setOrderID(res.data.order_id);
              localStorage.setItem(
                "orderID",
                JSON.stringify(res.data.order_id)
              );
              localStorage.setItem(
                "merchantTID",
                JSON.stringify(res.data.merchant_transaction_id)
              );
              localStorage.setItem(
                "paymentMethod",
                JSON.stringify(paymentMethod)
              );

              if (res.data.free_event === 0) {
                if (userCountry.country_short_code !== "ZA") {
                  setClientSecret(res.data.checkout_id);
                  setOpenAddWallet(true);
                  setDisablePaymentBtn(false);
                } else {
                  window.location.href = res.data.eft_payment_url;

                  setDisablePaymentBtn(false);
                }
              } else {
                setopenBackdrop(true);
                setTimeout(() => {
                  setopenBackdrop(false);
                  navigate(
                    `/OrderDetails/${JSON.parse(
                      localStorage.getItem("orderID")
                    )}/?flag=${Autocountry.sortname}`,
                    {
                      state: { fromMyBooking: true },
                    }
                  );
                }, 3000);
              }
            } else {
              NotificationManager.error(res.data.msg);
            }
          } else {
            NotificationManager.error(res.data.msg);
            setopenBackdrop(false);
          }
        }
      );
    }
  };

  /**get date event date format by date */

  const dateFormated = (date) => {
    let dateNew = new Date(date);
    let day = dateNew.toLocaleString("en-us", { weekday: "short" });
    let newdate = new Date(date).getDate();
    let month = dateNew.toLocaleString("default", { month: "short" });
    return day + "," + newdate + " " + month;
  };

  const breadcrumbs = [
    <Link to={"/"} key="1" className="link-decoration">
      {t("Home")}
    </Link>,
    Eventname ? (
      <Link
        to={`/events/${Eventname.replaceAll(" ", "-")}/${EvenTIDloc}`}
        key="1"
        className="link-decoration"
        style={{ color: "#0CA8F6" }}
      >
        {Eventname ? Eventname : "Event Details"}
      </Link>
    ) : (
      <></>
    ),

    <Typography
      key="3"
      sx={{
        fontFamily: "Lato",
        color: "#0CA8F6",
        fontWeight: 500,
        fontSize: "16px",
      }}
    >
      {t("Order_Details")}
    </Typography>,
  ];

  // Seat.io POPUP
  const [viewSeats, setViewSeats] = useState(false);
  const handleCloseSeatViews = () => {
    setViewSeats(false);
  };
console.log("Total Ticket Price ",odnocoupAply)
  return (
    <>
      <Navbar />
      <Box className="mainContainer">
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          sx={{ mb: 3, mt: 2 }}
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Box>
      <Box className="mainContainer">
        {/* add wallet model */}

        <Box>
          {isLoading ? (
            <div style={{ height: "400px" }}>
              <div className="loader ">
                <ClipLoader />
              </div>
            </div>
          ) : (
            <Modal
              hideBackdrop
              open={openAddWallet}
              onClose={handleSubmodelClose}
              aria-labelledby="child-modal-title"
              aria-describedby="child-modal-description"
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
              sx={{ background: "rgb(0 0 0 / 70%)" }}
              className="new-order-modal-style"
            >
              <Box sx={{ ...style }} className="new-order-modal-box">
                <CloseIcon
                  className="closeIconOffer"
                  onClick={handleSubmodelClose}
                />
                {userCountry.country_short_code === "ZA" ? (
                  <form
                    action={
                      window.location.hostname === "localhost"
                        ? "http://localhost:3000/order-preview"
                        : window.location.hostname === "staging.ticketkore.io"
                        ? "https://staging.ticketkore.io/website/order-preview"
                        : window.location.hostname === "dev.ticketkore.io"
                        ? "https://dev.ticketkore.io/website/order-preview"
                        : window.location.hostname === "3.16.122.244"
                        ? "http://3.16.122.244:3000/order-preview"
                        : "https://ticketkore.io/order-preview"
                    }
                    
                    className="paymentWidgets"
                    data-brands="VISA MASTER AMEX"
                  ></form>
                ) : (
                  clientSecret && (
                    <Elements options={options} stripe={stripePromise}>
                      <CheckoutForm
                        return_url={
                          window.location.hostname === "localhost"
                            ? "http://localhost:3000/order-preview/"
                            : window.location.hostname ===
                              "staging.ticketkore.io"
                            ? "https://staging.ticketkore.io/website/order-preview"
                            : window.location.hostname === "dev.ticketkore.io"
                            ? "https://dev.ticketkore.io/website/order-preview"
                            : window.location.hostname === "3.16.122.244"
                        ? "http://3.16.122.244:3000/order-preview"
                            : "https://ticketkore.io/order-preview"
                        }
                      />
                    </Elements>
                  )
                )}
              </Box>
            </Modal>
          )}
        </Box>

        {/* add wallet model */}

        <Grid container spacing={4}>
          <Grid item xs={12} md={12}>
            <Box className="textFieldBox">
              <Typography className="layoutTitle">{t("Order_Details")}</Typography>
              {/* <img
 src="assets/images/order_shareIcon.png"
 alt="share"
 className="cursor_pointer"
 /> */}
            </Box>
            <div className="hLine"></div>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={4}
          sx={{ marginTop: "0px", marginBottom: "40px" }}
        >
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Card className="mainTopSeatBook" sx={{ paddingTop: "18px" }}>
              <Box className="seatBookingRow">
                <item className="bookingDetails">
                  <Typography className="bookingTitle fontSize22">
                    {Orderdetailsdata?.event_name}
                  </Typography>
                  <Typography className="seatBookingAddressBox">
                    <img
                      src="assets/images/booking-icon/calendar-icon.png"
                      alt="calendar"
                      style={{ marginRight: "23px" }}
                    />
                    <Typography className="seatbookingAddress fontSize18">
                      {location?.state?.location_date_range
                        ? location?.state?.location_date_range
                        : Selecteddatetime}

                      {/* // location1?.event_date_time?.length >1 ? `${dateFormated( location1?.event_date_time[0]?.date)}-${dateFormated(location1?.event_date_time[location1?.event_date_time?.length-1]?.date)}` : location1?.event_date_time[0]?.date */}
                    </Typography>
                    {location?.state?.eventD.multiday_scan === "1" &&
                    !location?.state?.checkPrivateEvent ? (
                      <Button
                        className="seat_Booking_info"
                        onClick={() =>
                          handleOpenDates(location?.state?.location)
                        }
                      >
                        <img
                          src="assets/images/info_icon.png"
                          className="seat_Booking_info-icon"
                          alt=""
                        />
                        info
                      </Button>
                    ) : (
                      <></>
                    )}
                  </Typography>
                  <Typography className="seatBookingAddressBox">
                    <img
                      src="assets/images/booking-icon/map-pin.png"
                      alt="map pin"
                      style={{ marginRight: "23px" }}
                    />
                    <Typography className="seatbookingAddress fontSize18">
                      {/* 1123 Ode Street, Maokeng Ext, Thembisa, Tembisa */}
                      {location1?.venue_name}, {location1?.venue_address}
                    </Typography>
                  </Typography>
                </item>
              </Box>

              {odnocoupAply?.ticket_calculation?.map((ele, index) => {
                return (
                  <>
                    <Box className="SeatbookingTicketPriceDetail">
                      <item>
                        <Typography className="bookingTicketPriceText bookingTicketPriceTextBold">
                          {ele.ticket_classs}
                          {/* <span className="colorBlue" style={{ fontSize: '12px', display: 'block', fontWeight: '400' }}>
 (B11, C12, D13, E14)
 </span> */}
                        </Typography>
                      </item>
                      <div className="bookingvLine"></div>
                      {selectedSeatsObjects.length > 0 ? (
                        <Box>
                          <Button
                            sx={{ textTransform: "capitalize" }}
                            className="ticket-bg"
                            onClick={() => {
                              setViewSeats(true);
                            }}
                          >
                            <span className="colorBlue extra-design">
                              {ele.quantity}
                            </span>
                          </Button>
                        </Box>
                      ) : (
                        <item>
                          <Typography className="bookingTicketPriceText fontSize18">
                            Ticket:
                            <span className="colorBlue">{ele.quantity}</span>
                          </Typography>
                        </item>
                      )}
                      <div className="bookingvLine"></div>
                      <item>
                        <Typography className="bookingTicketPriceText fontSize18">
                          {t("Price")}:{" "}
                          <span className="colorBlue">
                            {parseFloat(
                              odnocoupAply.ticket_total_real_amount
                            ).toFixed(2)}
                          </span>
                        </Typography>
                      </item>
                    </Box>
                    <Box className="SeatCouponBtn">
                      <Typography className="seatOrderText">{t("Base_Price")}</Typography>
                      <Typography className="seatOrderText colorBlue">
                      {getSymbolFromCurrency(odnocoupAply.currency)}{" "}  
                      {parseFloat(
                              odnocoupAply.ticket_total_base_amount
                            ).toFixed(2)}
                      </Typography>
                    </Box>
                    <Box className="SeatCouponBtn">
                      <Typography className="seatOrderText">{t("Tax")}</Typography>
                      <Typography className="seatOrderText colorBlue">
                        {getSymbolFromCurrency(odnocoupAply.currency)}{" "}
                        {parseFloat(ele.vat_amount).toFixed(2)}
                      </Typography>
                    </Box>
                  </>
                );
              })}

              <Box className="SeatCouponBtn">
                <Typography className="seatOrderText">{t("Service_Fee")}</Typography>
                <Typography className="seatOrderText colorBlue">
                  {getSymbolFromCurrency(odnocoupAply.currency)}{" "}
                  {odnocoupAply.commission_amount}
                </Typography>
              </Box>

        {appliedDiscount && appliedDiscount > 0 && <Box className="SeatCouponBtn">
                  <Button className="SeatorderText colorGreen">
                    {t('Applied_Discount')}
                  </Button>
                  <Typography
                    className="seatOrderText colorGreen"
                    style={{ maxWidth: "140px" }}
                  >
                    - {getSymbolFromCurrency(odnocoupAply.currency)}{" "}
                    {parseFloat(appliedDiscount).toFixed(2)}
                  </Typography>
                </Box>}
                {appliedCouponDiscount && appliedCouponDiscount > 0 && <Box className="SeatCouponBtn">
                  <Button className="SeatorderText colorGreen">
                    {t('Coupon_Applied')}
                  </Button>
                  <Typography
                    className="seatOrderText colorGreen"
                    style={{ maxWidth: "140px" }}
                  >
                    - {getSymbolFromCurrency(odnocoupAply.currency)}{" "}
                    {parseFloat(appliedCouponDiscount).toFixed(2)}
                  </Typography>
                </Box>}        
        {/* {odnocoupAply.coupon_range_discount === "0.00" ? (
                ""
              ) : odnocoupAply.coupon_range_discount >= "0.00" ? (
                <Box className="SeatCouponBtn">
                  <Button className="SeatorderText colorGreen">
                    {odnocoupAply.coupon_percentage === "0.00"
                      ? ` Applied Discount`
                      : " Coupon Applied "}
                  </Button>
                  <Typography
                    className="seatOrderText colorGreen"
                    style={{ maxWidth: "140px" }}
                  >
                    - {getSymbolFromCurrency(odnocoupAply.currency)}{" "}
                    {parseFloat(odnocoupAply.coupon_range_discount).toFixed(2)}
                  </Typography>
                </Box>
              ) : (
                ""
              )} */}

              {selectedRefundRadioBtn === "refund-true" ? (
                <Box className="SeatCouponBtn">
                  <Typography className="seatOrderText">
                    {t("Refund_Protect")}
                  </Typography>
                  <Typography className="seatOrderText colorBlue">
                    {getSymbolFromCurrency(odnocoupAply.currency)}

                    {location.state?.eventData?.upgrade_ticket_price}
                  </Typography>
                </Box>
              ) : (
                <></>
              )}

              <Box
                className="flexGrid seatTotalOrderBg"
                sx={{ marginTop: "20px" }}
              >
                <Typography className="totalTicketTitle">
                  {t("Total_Ticket_Price")} :
                </Typography>
                <Typography className="totalTicketTitle colorDarkBlue">
                  {getSymbolFromCurrency(odnocoupAply.currency)}{" "}
                  {selectedRefundRadioBtn === "refund-true"
                    ? parseFloat(
                        parseFloat(odnocoupAply.total_paybal_amount) +
                          parseFloat(
                            location.state?.eventData?.upgrade_ticket_price
                          )
                      ).toFixed(2)
                    : parseFloat(odnocoupAply.total_paybal_amount).toFixed(2)}
                </Typography>
              </Box>

              {skip ? (
                ""
              ) : holdingAdons.length > 0 ? (
                <>
                  <Box className="flexGrid totalTicketTitleHeading SeatTotalTicketTitleHeading">
                    <Typography className="totalTicketTitle">Item</Typography>
                    <Typography className="totalTicketTitle">
                      {t("Quantity")}
                    </Typography>
                    <Typography className="totalTicketTitle">Price</Typography>
                  </Box>

                  {holdingAdons?.map((res) => (
                    <Box
                      className="SeatCouponBtn"
                      sx={{ alignItems: "center", paddingTop: "8px" }}
                    >
                      <Typography className="seatOrderText w33">
                        {res.title}{" "}
                        <span className="addons-size">
                          {" "}
                          {res.extra_fields
                            ? `(Size : ${res.extra_fields.fields_data[0].values[0].label})`
                            : ""}
                        </span>
                      </Typography>
                      <div className="seatGnrlImgGrid">
                        <div className="detailBottomL"></div>
                        <Box className="Textnumbers Textquantity">
                          {res.count}
                        </Box>
                      </div>
                      <Typography className="seatOrderText colorBlue">
                        {getSymbolFromCurrency(res.currency)}{" "}
                        {parseFloat(res.price * res.count).toFixed(2)}
                      </Typography>
                    </Box>
                  ))}
                  {totalTicketAmountChillers > 0 ? (
                    <Box
                      className="SeatCouponBtn seatTotalOrderBg"
                      sx={{ marginTop: "20px" }}
                    >
                      <Typography className="totalTicketTitle">
                        {t("Total_Items_Price")} :
                      </Typography>
                      <Typography className="totalTicketTitle colorDarkBlue">
                        {getSymbolFromCurrency(odnocoupAply.currency)}
                        {parseFloat(totalTicketAmountChillers).toFixed(2)}
                      </Typography>
                    </Box>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                ""
              )}
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Card
              className="bookingCardCoupon"
              sx={{ padding: "16px !important" }}
            >
              <>
                  <Box className="bookingGrandTotal" sx={{ padding: "0px" }}>
                    <item>
                      <Typography
                        className="bookingGrandTotalText"
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        {" "}
                        <img
                          src="assets/images/offers.png"
                          alt="offers"
                          className="OffersImg"
                        />{" "}
                        {t('Apply_Promo_Code')} :
                      </Typography>
                    </item>
                  </Box>
                  <div className="hLine"></div>
                  <Box sx={{ marginTop: "30px" }} className="textFieldBox">
                    <TextField
                      fullWidth
                      placeholder={t("Enter_Promo_Code")}
                      id="fullWidth"
                      className="textFieldFull"
                      size="small"
                      value={value ? value : couponCodeApply}
                      onChange={(e) => setCodeCouponApply(e.target.value)}
                    />

                    {ApplyButton ? (
                      <Button
                        className="seatApplyBtn"
                        onClick={(e) => OD_noCoupAply()}
                      >
                        {" "}
                        {t("Remove")}{" "}
                      </Button>
                    ) : (
                      <Button
                        className="seatApplyBtn"
                        onClick={(e) => CheckCouponstatus()}
                      >
                        {" "}
                        {t("Apply")}{" "}
                      </Button>
                    )}
                  </Box>
                  </>
              </Card> 

              {/* Logic of Cupon display */}
            {/* {Orderdetailsdata?.offers?.length > 0 ? (
              <Card
                className="bookingCardCoupon"
                sx={{ padding: "16px !important" }}
              >
                {Orderdetailsdata?.offers?.length > 0 ? (
                  <>
                    <Box className="bookingGrandTotal" sx={{ padding: "0px" }}>
                      <item>
                        <Typography
                          className="bookingGrandTotalText"
                          sx={{ display: "flex", alignItems: "center" }}
                        >
                          {" "}
                          <img
                            src="assets/images/offers.png"
                            alt="offers"
                            className="OffersImg"
                          />{" "}
                          Apply Promo Code :
                        </Typography>
                      </item>
                    </Box>
                    <div className="hLine"></div>
                    <Box sx={{ marginTop: "30px" }} className="textFieldBox">
                      <TextField
                        fullWidth
                        placeholder="Enter Promo Code"
                        id="fullWidth"
                        className="textFieldFull"
                        size="small"
                        value={value ? value : couponCodeApply}
                        onChange={(e) => setCodeCouponApply(e.target.value)}
                      />

                      {ApplyButton ? (
                        <Button
                          className="seatApplyBtn"
                          onClick={(e) => OD_noCoupAply()}
                        >
                          {" "}
                          Remove{" "}
                        </Button>
                      ) : (
                        <Button
                          className="seatApplyBtn"
                          onClick={(e) => CheckCouponstatus()}
                        >
                          {" "}
                          Apply{" "}
                        </Button>
                      )}
                    </Box>
                  </>
                ) : (
                  <></>
                )}
                <Box sx={{ marginTop: "30px" }}>
                  <Grid container spacing={3}>
                    {Orderdetailsdata?.offers?.map((ele1, index) => {
                      return (
                        <Grid
                          item
                          xs={6}
                          sm={6}
                          md={3}
                          lg={3}
                          textAlign="center"
                          style={{ cursor: "pointer" }}
                        >
                          {/* setvalue(ele1.coupon_code) */}
                          {/* <img
                            onClick={(e) => CheckCouponstatus(ele1.coupon_code)}
                            src={
                              ele1.coupon_image
                                ? ele1.coupon_image
                                : "assets/images/value.png"
                            }
                            style={{
                              border: "1px solid #ddd",
                              borderRadius: "4px",
                            }}
                            alt="valueImg"
                          />
                          {ele1.coupon_code} <br />
                          Valid till: {dateFormated(ele1.end_date)}
                        </Grid> */}
                      {/* );
                    })}
                  </Grid>
                </Box>
              </Card>
            ) : !Orderdetailsdata?.offers &&
              odnocoupAply &&
              freeTkCouponAllowed === "1" ? (
              <>
                <Box className="bookingGrandTotal" sx={{ padding: "0px" }}>
                  <item>
                    <Typography
                      className="bookingGrandTotalText"
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      {" "}
                      <img
                        src="assets/images/offers.png"
                        alt="offers"
                        className="OffersImg"
                      />{" "}
                      Apply Promo Code :
                    </Typography>
                  </item>
                </Box>
                <div className="hLine"></div>
                <Box sx={{ marginTop: "30px" }} className="textFieldBox">
                  <TextField
                    fullWidth
                    placeholder="Enter Promo Code"
                    id="fullWidth"
                    className="textFieldFull"
                    size="small"
                    value={value ? value : couponCodeApply}
                    onChange={(e) => setCodeCouponApply(e.target.value)}
                  />

                  {ApplyButton ? (
                    <Button
                      className="seatApplyBtn"
                      onClick={(e) => OD_noCoupAply()}
                    >
                      {" "}
                      Remove{" "}
                    </Button>
                  ) : (
                    <Button
                      className="seatApplyBtn"
                      onClick={(e) => CheckCouponstatus()}
                    >
                      {" "}
                      Apply{" "}
                    </Button>
                  )}
                </Box>
              </>
            ) :  location?.state?.eventD?.allow_freeTicket_text === 1 ?  <Card
              className="bookingCardCoupon"
              sx={{ padding: "16px !important" }}
            >
              <>
                  <Box className="bookingGrandTotal" sx={{ padding: "0px" }}>
                    <item>
                      <Typography
                        className="bookingGrandTotalText"
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        {" "}
                        <img
                          src="assets/images/offers.png"
                          alt="offers"
                          className="OffersImg"
                        />{" "}
                        Apply Promo Code :
                      </Typography>
                    </item>
                  </Box>
                  <div className="hLine"></div>
                  <Box sx={{ marginTop: "30px" }} className="textFieldBox">
                    <TextField
                      fullWidth
                      placeholder="Enter Promo Code"
                      id="fullWidth"
                      className="textFieldFull"
                      size="small"
                      value={value ? value : couponCodeApply}
                      onChange={(e) => setCodeCouponApply(e.target.value)}
                    />

                    {ApplyButton ? (
                      <Button
                        className="seatApplyBtn"
                        onClick={(e) => OD_noCoupAply()}
                      >
                        {" "}
                        Remove{" "}
                      </Button>
                    ) : (
                      <Button
                        className="seatApplyBtn"
                        onClick={(e) => CheckCouponstatus()}
                      >
                        {" "}
                        Apply{" "}
                      </Button>
                    )}
                  </Box>
                  </>
              </Card> : null} */} 
 {/* Logic of Cupon display end */}

            {openRefSec && odnocoupAply.total_paybal_amount !== "0.00" && paymentMethod ==="card" ? (
              <Card
                className="bookingCardCoupon"
                sx={{ padding: "16px !important", marginTop: "36px" }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography className="upgrade_my_typo">
                    {t("Upgrade_My_Refund_Terms")}
                  </Typography>
                  {location.state?.eventData?.upgrade_term_conditions.length >
                  0 ? (
                    <RefTermsNCond
                      TermsAndCond={
                        location.state?.eventData?.upgrade_term_conditions
                      }
                    />
                  ) : (
                    <></>
                  )}
                </Box>
                <FormControl className="radio_form_contrl">
                  {/* <FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel> */}
                  <RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    name="radio-buttons-group"
                    onChange={(e, val) => {
                      setPaymentMethod("card");
                      setSelectedRefundRadioBtn(val);
                      // console.log(val ,'value')
                    }}
                    value={selectedRefundRadioBtn}
                  >
                    <Grid
                      container
                      spacing={2}
                      sx={{
                        marginLeft: "0px",
                        marginTop: "20px",
                        padding: "0px 20px",
                      }}
                    >
                      <Grid xs={12} sm={12} md={12} lg={6}>
                        <FormControlLabel
                          className="radio_label_text_refund"
                          value="refund-true"
                          control={<Radio />}
                          label={`Yes, upgrade my refund terms for ${getSymbolFromCurrency(
                            odnocoupAply.currency
                          )}${
                            location.state?.eventData?.upgrade_ticket_price
                          } (Recommended)`}
                        />
                      </Grid>
                      <Grid xs={12} sm={12} md={12} lg={6}>
                        <FormControlLabel
                          sx={{ marginLeft: "10px" }}
                          className="radio_label_text_refund"
                          value="refund-false"
                          control={<Radio />}
                          label="No, don’t upgrade my booking,"
                        />
                      </Grid>
                    </Grid>
                  </RadioGroup>
                </FormControl>
              </Card>
            ) : (
              <></>
            )}
            {/* Company Name(optional) */}
            {location.state && location.state.cpName ? (
              <Box className="card-seat-Booking-company">
                <Box className="box-company-name">
                  {location.state.cpName ? (
                    <>
                      <Typography
                        className="box-company-name-text "
                        sx={{ paddingBottom: "14px" }}
                      >
                        {t("Company_Name(optional)")}
                      </Typography>
                      <Typography
                        className="company-name-textfield"
                        sx={{
                          borderBottom: "1px solid #D9D9D9",
                          padding: "6px 0",
                          wordBreak: "break-word",
                        }}
                      >
                        {" "}
                        {location.state.cpName}
                      </Typography>
                    </>
                  ) : (
                    <></>
                  )}

                  {location.state.cpNum ? (
                    <>
                      <Typography
                        className="box-company-name-text"
                        sx={{ paddingBottom: "14px", mt: 2 }}
                      >
                        {t("Company_VAT_Number(optional)")}
                      </Typography>
                      <Typography
                        className="company-name-textfield"
                        sx={{
                          borderBottom: "1px solid #D9D9D9",
                          wordBreak: "break-all",
                          padding: "6px 0",
                        }}
                      >
                        {" "}
                        {location.state.cpNum}{" "}
                      </Typography>
                    </>
                  ) : (
                    <></>
                  )}

                  {location.state.cpAdd ? (
                    <>
                      {" "}
                      <Typography
                        className="box-company-name-text"
                        sx={{
                          mt: 2,
                          paddingBottom: "14px",
                          wordBreak: "break-word",
                        }}
                      >
                        Company Address(optional)
                      </Typography>
                      <Typography
                        className="company-name-textfield"
                        sx={{
                          borderBottom: "1px solid #D9D9D9",
                          padding: "6px 0",
                        }}
                      >
                        {" "}
                        {location.state.cpAdd}{" "}
                      </Typography>
                    </>
                  ) : (
                    <></>
                  )}
                </Box>
              </Box>
            ) : (
              <></>
            )}
            {/*End Company Name(optional) */}

            <Card
              className="bookingCardCoupon"
              sx={{ marginTop: "36px", padding: "16px !important" }}
            >
              <Box className="bookingGrandTotal" sx={{ padding: "0px" }}>
                <item>
                  <Typography className="bookingGrandTotalText">
                    {t("Grand_Total_Price")} :
                  </Typography>
                </item>
                <item>
                  <Typography className="bookingGrandTotalAmount">
                    {getSymbolFromCurrency(odnocoupAply.currency)}
                    {selectedRefundRadioBtn === "refund-true"
                      ? parseFloat(
                          parseFloat(odnocoupAply.total_paybal_amount) +
                            parseFloat(totalTicketAmountChillers) +
                            parseFloat(
                              location.state?.eventData?.upgrade_ticket_price
                            )
                        ).toFixed(2)
                      : parseFloat(
                          parseFloat(odnocoupAply.total_paybal_amount) +
                            parseFloat(totalTicketAmountChillers)
                        ).toFixed(2)}
                  </Typography>
                </item>
              </Box>

              {labels.length > 0 ? (
                <Typography
                  className="bookingGrandTotalText"
                  sx={{ mt: 2, mb: 2 }}
                >
                  {t("Row")} :{" "}
                  <span className="colorBlue">
                    {" "}
                    {row.length > 1
                      ? row.map((label) => label.parent + " ")
                      : row.map((label) => label.parent)}
                  </span>
                </Typography>
              ) : (
                <></>
              )}

              <div className="hLine"></div>
              
              {userCountry.country_short_code === "ZA" &&
              (odnocoupAply.total_paybal_amount + totalTicketAmountChillers !== 0 ) ? (
                <Box>
                  <Typography className="paymentTitle">{t("Payment_Mode")}</Typography>

                  <Grid container spacing={2}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="card"
                      name="radio-buttons-group"
                      onChange={(e, value) => {
                        console.log(value, "val");
                        if (
                          openRefSec &&
                          odnocoupAply.total_paybal_amount !== "0.00"
                        ) {
                          if (value === "eft") {
                            setSelectedRefundRadioBtn("refund-false");
                          }
                          setPaymentMethod(value);
                        } else {
                          setPaymentMethod(value);
                        }
                      }}
                      value={paymentMethod}
                    >
                      <Grid container spacing={0}>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                          <Box className="paymentBorder">
                            <FormControlLabel
                              value="card"
                              control={<Radio />}
                              label="Card"
                            />
                            <img src="assets/images/card-icon.png" alt="Card" />
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                          <Box className="paymentBorder">
                            <FormControlLabel
                              value="eft"
                              control={<Radio />}
                              label="Secure EFT"
                            />
                            <img
                              src="assets/images/eft.png"
                              width={40}
                              alt="Instant EFT"
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </RadioGroup>
                  </Grid>
                </Box>
              ) : (
                <></>
              )}

              <Box sx={{ textAlign: "center", padding: "24px 0px 0px 0px" }}>
                <Button
                  type="button"
                  className="proceedBtnPay"
                  onClick={(e) => OrderBookeventticket(e)}
                  disabled={disablePaymentBtn}
                  id="payment-btn"
                >
                  {t("Proceed_to_Payment")}
                </Button>
                <Button
                  type="button"
                  className="proceedBtnPay"
                  onClick={(e) => OrderBookeventticket(e)}
                  disabled={disablePaymentBtn}
                  id="payment-btn"
                >
                  {t("Pay at Gate")}
                </Button>
              
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Box>
      {/* Seat.io ticket show popup */}
      <Box>
        <Dialog
          // fullScreen={fullScreen}
          open={viewSeats}
          onClose={handleCloseSeatViews}
          aria-labelledby="responsive-dialog-title"
          // sx={{width:'80vw'}}
        >
          <Box
            sx={{
              background: "#0CA8F6",
              display: "flex",
              justifyContent: "space-between",
              color: "white",
              height: "40px",
              p: "1rem",
            }}
          >
            <Typography
              variant="h6"
              sx={{ textTransform: "capitalize", fontWeight: "700" }}
            >
              {t("Booked_Seats")}
            </Typography>
            <CloseIcon
              onClick={handleCloseSeatViews}
              sx={{ cursor: "pointer" }}
            />
          </Box>
          {/* <Box>}</Box> */}
          {labels ? (
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
              sx={{ padding: "1rem" }}
            >
              {labels.map((selLabels, index) => (
                <Grid item xs={2} sm={4} md={4} key={index}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "1px dotted #0CA8F6",
                      p: "10px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {selLabels.parent}-{selLabels.own}
                  </Box>
                </Grid>
              ))}
            </Grid>
          ) : (
            <></>
          )}
        </Dialog>
      </Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
        onClick={handleCloseBackDrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Modal
        keepMounted
        open={openDate}
        onClose={handleCloseDate}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        className="seat-Modal-class-Date"
      >
        <ShowLocationDates
          selectedLocationData={selectedLocationData}
          closePopup={closePopup}
        />
      </Modal>
    </>
  );
};

export default SeatBooking;
