import React from 'react'
import { Box, } from '@mui/system';
import { Divider, Grid, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import parse from "html-react-parser";
import {useTranslation} from 'react-i18next'
import "./style.css";

const TermsCondition = (eventdetailspage1) => {
    const {t} = useTranslation('common')
    const Terms = eventdetailspage1.eventdetailspage1.event_terms_condition??""

    return (
        <div className='mainContainer' style={{   marginBottom: '40px', marginTop: '50px' }}>
            <Divider sx={{mb:4}} /> 
            <Box > 
                <Grid sx={{ padding: '30px 0px' }}>
                    <Accordion className='accordianMain'>
                        <AccordionSummary 
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            className='accordianSummary'
                        >
                            <Typography className='accordianTypography'>{t('Terms_&_Conditions')}</Typography>
                            <AccordionSummary className='arrowdownCantainer'   expandIcon={<ExpandMoreIcon className='ArrowDownIcon' />}/>
                        </AccordionSummary>
                        <AccordionDetails className='accordianDetail'>
                            <Typography>
                                {parse(Terms)} 
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </Grid> 
            </Box>
        </div>
    )
}

export default TermsCondition

// n bj