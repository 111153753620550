import { Typography } from '@mui/material';
import React from 'react'

import "./style.css";


const CommonBanner = (props) => {
  const prop = props;
  return (
    <div className='bgCommonImg'>        
        <Typography className='commonBannerTypo'>{prop.title}</Typography>
    </div>
  )
}

export default CommonBanner 