import React from 'react'
import { Box, Card } from '@mui/material'
import { Grid } from '@mui/material'
import { Typography } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check';
import { Link } from 'react-router-dom';
import "./style.css";

const FaqComponent = () => {
    return (
        <Box className='mainContainer' sx={{ paddingBottom: '30px' }}>
            <Grid container spacing={2}>

                <Grid item xs={12} sm={12} md={12}>
                    <Typography className='policyIntroTypo policyIntroTypoMedia'>FAQ FOR ORGANISERS AND VENDORS</Typography>
                    <div className='faqCommonPm'>
                        <Typography className='faqCheckText'> <img src='assets/images/faqQues.png' alt='Question' className='faqQuestionClass' /> How do I list an event ?</Typography>
                        <Typography className='policyUlList'><a href="https://ticketkore.me/3Dz5XhQ" target='_blank' style={{
                            textDecoration: 'none', color: '#0CA8F6'
                        }}>https://ticketkore.me/3Dz5XhQ</a></Typography>
                    </div>
                    <div className='faqCommonPm'>
                        <Typography className='faqCheckText'><img src='assets/images/faqQues.png' alt='Question' className='faqQuestionClass' />What documents do you need when registering account?</Typography>


                        <div>
                            <Typography className='FaqpolicyUlList'><CheckIcon className='ulCheckIcns' />For individuals</Typography>
                            <Typography className='FaqpolicyUlListInnerTypo'>ID copy, or passport, driver licences</Typography>
                        </div>

                        <div>
                            <Typography className='FaqpolicyUlList'><CheckIcon className='ulCheckIcns' />For business</Typography>
                            <Typography className='FaqpolicyUlListInnerTypo'>Company incorporation certificate</Typography>
                        </div>
                    </div>

                    <div className='faqCommonPm'>
                        <Typography className='faqCheckText'><img src='assets/images/faqQues.png' alt='Question' className='faqQuestionClass' />What Happens If the Event Is Cancelled or Postponed?</Typography>

                        <ul className='policyUlList' style={{ margin: '0px' }}>
                            <li><CheckIcon className='ulCheckIcns' />Cancellations made 7 days or more in advance of the event date, will receive a 100% refund. </li>
                            <li><CheckIcon className='ulCheckIcns' />Cancellations made within 3 - 6 days will incur a 20% fee.</li>
                            <li><CheckIcon className='ulCheckIcns' />Cancellations made within 48 hours to the event will incur a 30% fee.</li>

                        </ul>

                    </div>
                    <div className='faqCommonPm'>
                        <Typography className='faqCheckText'><img src='assets/images/faqQues.png' alt='Question' className='faqQuestionClass' />How are your prices structured?</Typography>
                        <Typography className='policyUlList'>Please review our pricing here</Typography>
                        <Typography className='policyUlList'><a href="https://ticketkore.io/#pricing" target='_blank' style={{ textDecoration: 'none', color: '#0CA8F6' }}>https://ticketkore.io/#pricing</a></Typography>
                    </div>

                    <div className='faqCommonPm'>
                        <Typography className='faqCheckText'><img src='assets/images/faqQues.png' alt='Question' className='faqQuestionClass' />How long does it take for payments to be made after an event?</Typography>
                        <Typography className='policyUlList'>Pay-outs are processed on the next business day after the event has ended and a settlement report is emailed to you. The amount is paid in to your registered and verified business account once all fees and commissions have been calculated. Depending on your bank, your pay-out may take up to 48 hours.
                        </Typography>
                    </div>

                    <div className='faqCommonPm'>
                        <Typography className='faqCheckText'><img src='assets/images/faqQues.png' alt='Question' className='faqQuestionClass' />How do I register as a vedor?</Typography>
                        <Typography className='policyUlList'> <a href='https://ticketkore.io/admin/vendor' target="_blank" style={{ textDecoration: 'none', color: '#0CA8F6' }} >click here </a>to register as vendor</Typography>
                    </div>
                    <div className='faqCommonPm'>
                        <Typography className='faqCheckText'><img src='assets/images/faqQues.png' alt='Question' className='faqQuestionClass' />How to order wristbands?</Typography>
                        <Typography className='policyUlList'><Link to="/Buy" style={{ textDecoration: 'none', color: '#0CA8F6' }}>Click here </Link></Typography>
                    </div>
                    <div className='faqCommonTopM'>
                        <Typography className='FaqpolicyContent'>FAQ FOR CUSTOMERS</Typography>
                    </div>
                    <div className='faqCommonPm'>
                        <Typography className='faqCheckText'><img src='assets/images/faqQues.png' alt='Question' className='faqQuestionClass' />How to book a ticket?</Typography>.
                        <img src='assets/images/faq_images.png' alt='faq_img' />
                        <Typography className='policyUlList'> <a href='https://www.youtube.com/watch?v=Q3zcZEP8FAo' target="_blank" style={{ textDecoration: 'none', color: '#0CA8F6' }} >click here</a></Typography>
                    </div>

                    <div className='faqCommonPm'>
                        <Typography className='faqCheckText'><img src='assets/images/faqQues.png' alt='Question' className='faqQuestionClass' />I deleted an email with my ticket QR code, are you able to assist with retrieving my ticket?</Typography>
                        <Typography className='policyUlList'>
                            <a href='#' style={{ textDecoration: 'none', color: '#0CA8F6' }} >Log In to Customer Portal.</a></Typography>
                    </div>

                    <div className='faqCommonPm'>
                        <Typography className='faqCheckText'><img src='assets/images/faqQues.png' alt='Question' className='faqQuestionClass' />How do I do group bookings?</Typography>
                        <Typography className='policyUlList'>Email us at <a href="mailto:gethelp@ticketkore.io" style={{ textDecoration: 'none', color: '#0CA8F6' }}>gethelp@ticketkore.io </a>, an invoice and payment link will be generated for you to book. </Typography>
                    </div>

                    <div className='faqCommonPm'>
                        <Typography className='faqCheckText'>Need human assistance, please call us on the numbers below:</Typography>
                        <ul className='policyUlList' style={{ margin: '0px' }}>
                            <li><CheckIcon className='ulCheckIcns' />Live Chat : Start a live chat with us from our web and mobile app. Available 7 days a week. </li>
                            <li><CheckIcon className='ulCheckIcns' />Call: Southern Africa :  087 164 2529  America: +1 212 372 7763</li>
                            <li><CheckIcon className='ulCheckIcns' />Email: <a href="mailto:gethelp@ticketkore.io" style={{ textDecoration: 'none', color: '#0CA8F6' }}>gethelp@ticketkore.io </a></li>

                        </ul>
                    </div>
                </Grid>
            </Grid>
        </Box >
    )
}

export default FaqComponent