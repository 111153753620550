import React from "react";
import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import checkValidationFormAllControls from "../../shared/validation";
import { useEffect, useState } from "react";
import http from "../../Services/http";
import { NotificationManager } from "react-notifications";
import { useNavigate } from "react-router-dom";
import "./style.css";
import { SpinnerCircular } from "spinners-react";
import {useTranslation} from 'react-i18next'
const ContactInfo = () => {
  const {t} = useTranslation("common")
  const navigate = useNavigate();

  const [errorDisplay, seterrorDisplay] = useState("");

  const [first_name, setFirst_name] = React.useState("");
  const [last_name, setLast_name] = React.useState("");
  const [email_address, setEmail_address] = React.useState("");
  const [phone_number, setPhone_number] = React.useState("");
  const [message, setMessage] = React.useState("");

  const [loading, setLoading] = useState(false);


  const handleSubmit = (event) => {
    event.preventDefault();
    let elements = event.target.form.elements;
    var objValidation = {};
    objValidation = checkValidationFormAllControls(
      document.forms[0].elements,
      false,
      []
    );
    if (objValidation.valid !== false) {
      seterrorDisplay(objValidation);
      return;
    } else {
      seterrorDisplay(objValidation);
    }
    setLoading(true);
    const ContactusData = {
      first_name: first_name,
      last_name: last_name,
      email_id: email_address,
      phone_number: phone_number,
      message,
    };
    http("website_contact_us/contact_us", "POST", ContactusData)
      .then((res) => {
        setLoading(false);
        if (res.data.status === 1) {
          setFirst_name("");
          setEmail_address("");
          setLast_name("");
          setMessage("");
          setPhone_number("");

          NotificationManager.success(res.data.msg);
        } else {
          NotificationManager.error(res.data.msg);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <Box sx={{ backgroundColor: "#EAF8FF" }}>
      <div className=" ">
        <Grid container>
          <Grid item xs={12} sm={12} md={6} className="ContactTopFstGd">
            <Typography className="cntctInfoTypo">
              {t("Contact_Information")}
            </Typography>
            <Typography className="cntctInform">{t("Contact us at the links below and a representative will be in touch")}.</Typography>


            <Typography className="NewOurLoc">
            {t("Email_Us")} 
            </Typography>
            <div className="contactBorderLeft">
              <div className="contactDisFlex" style={{ paddingBottom: '0px' }}>
                <img
                  src="assets/images/MessageIcon.png"
                  alt="CircleLoc"
                  className="commonImgCssCnt"
                />
                <Typography id="contactTypoMad" className="contactTypoMad">
                  gethelp@ticketkore.io
                </Typography>
              </div>
            </div>
            
            <Typography className="NewOurLoc">
            {t("Our_Locations")}
            </Typography>
            <div className="contactBorderLeft">
              <div className="contactDisFlex">
                <img
                  src="assets/images/circleLoc.png"
                  alt="CircleLoc"
                  className="commonImgCssCnt"
                />
                <Typography id="contactTypoMad"  className="contactTypoMad">
                {t("South_Africa")}
                </Typography>
              </div>
              <div className="contactDisFlex">
                <img
                  src="assets/images/locCircle.png"
                  alt="CircleLoc"
                  className="commonImgCssCnt"
                />
                <Typography className="contactTypoAdd">
                {t("131 7th Avenue Edenvale 1609")}
                </Typography>
              </div>

              <div className="contactDisFlex" style={{ paddingBottom: '0px' }}>
                <img
                  src="assets/images/circlePhone.png"
                  alt="CircleLoc"
                  className="commonImgCssCnt"
                />
                <Typography className="contactTypoAdd">087 164 2565</Typography>
              </div>

              {/* <div className="contactDisFlex" style={{ paddingBottom: '0px' }}>
                <img
                  src="assets/images/MessageIcon.png"
                  alt="CircleLoc"
                  className="commonImgCssCnt"
                />
                <Typography className="contactTypoMad">gethelp@ticketkore.io</Typography>
              </div> */}
            </div>

            <div className="contactBorderLeft">
              <div className="contactDisFlex">
                <img
                  src="assets/images/circleLoc.png"
                  alt="CircleLoc"
                  className="commonImgCssCnt"
                />
                <Typography id="contactTypoMad" className="contactTypoMad">
                  USA
                </Typography>
              </div>
              <div className="contactDisFlex">
                <img
                  src="assets/images/locCircle.png"
                  alt="CircleLoc"
                  className="commonImgCssCnt"
                />
                <Typography className="contactTypoAdd">
                  80 Broad Street,
                  New York, NY 10004
                </Typography>
              </div>

              <div className="contactDisFlex" style={{ paddingBottom: '0px' }}>
                <img
                  src="assets/images/circlePhone.png"
                  alt="CircleLoc"
                  className="commonImgCssCnt"
                />
                <Typography className="contactTypoAdd">+1 919 796 38611</Typography>
              </div>

              {/* <div className="contactDisFlex" style={{ paddingBottom: '0px' }}>
                <img
                  src="assets/images/MessageIcon.png"
                  alt="CircleLoc"
                  className="commonImgCssCnt"
                />
                <Typography className="contactTypoMad">gethelp@ticketkore.io</Typography>
              </div> */}
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} className="howCanHelpGrid">
            <Typography className="cntctHelp">{t("How_can_we_help")}</Typography>
            <form name="contact_form">
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6} md={6} className="">
                  <Typography className="commonEventTypo">
                    {t("First_Name")} <span className="ValidationRed">*</span>
                  </Typography>
                  <Box
                    sx={{ display: "flex", alignItems: "flex-start" }}
                    className="boxBorder_Bottom"
                  >
                    <img
                      src="assets/images/userIcon.png"
                      alt="userIcon"
                      className="userIconImg"
                    />
                    <input
                      id=""
                      placeholder="Enter"
                      required
                      fullWidth
                      variant="standard"
                      InputProps={{ disableUnderline: true }}
                      name="first_name"
                      value={first_name}
                      onChange={(e) => setFirst_name(e.target.value)}
                      className="inputFocusVisible"
                      style={{ border: 'none' }}
                      minlength="4" maxlength="8"
                    />
                  </Box>
                  <Box sx={{ marginTop: "5px" }}>
                    {errorDisplay.first_name ? (
                      <span className="red">{errorDisplay.first_name}</span>
                    ) : (
                      ""
                    )}
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={6} className="">
                  <Typography className="commonEventTypo">
                  {t("Last_Name")}<span className="ValidationRed">*</span>
                  </Typography>
                  <Box
                    sx={{ display: "flex", alignItems: "flex-start" }}
                    className="boxBorder_Bottom"
                  >
                    <img
                      src="assets/images/userIcon.png"
                      alt="userIcon"
                      className="userIconImg"
                    />
                    <input
                      id=""
                      placeholder="Enter"
                      required
                      fullWidth
                      variant="standard"
                      InputProps={{ disableUnderline: true }}
                      name="last_name"
                      value={last_name}
                      onChange={(e) => setLast_name(e.target.value)}
                      className="inputFocusVisible"
                      style={{ border: 'none' }}
                      minlength="4" maxlength="8"
                    />
                  </Box>
                  <Box sx={{ marginTop: "5px" }}>
                    {errorDisplay.last_name ? (
                      <span className="red">{errorDisplay.last_name}</span>
                    ) : (
                      ""
                    )}
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={6} className="">
                  <Typography className="commonEventTypo">
                    {t("Email_Id")}<span className="ValidationRed">*</span>
                  </Typography>
                  <Box
                    sx={{ display: "flex", alignItems: "flex-start" }}
                    className="boxBorder_Bottom"
                  >
                    <img
                      src="assets/images/EmailIcon.png"
                      alt="userIcon"
                      className="userIconImg"
                    />
                    <input
                      id=""
                      required
                      placeholder="Enter"
                      fullWidth
                      variant="standard"
                      InputProps={{ disableUnderline: true }}
                      name="email"
                      type="email"
                      value={email_address}
                      onChange={(e) => setEmail_address(e.target.value)}
                      className="inputFocusVisible"
                      style={{ border: 'none' }}

                    />
                  </Box>
                  <Box sx={{ marginTop: "5px" }}>
                    {errorDisplay.email ? (
                      <span className="red">{errorDisplay.email}</span>
                    ) : (
                      ""
                    )}
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={6} className="">
                  <Typography className="commonEventTypo">
                  {t("Phone_Number")}<span className="ValidationRed">*</span>
                  </Typography>
                  <Box
                    sx={{ display: "flex", alignItems: "flex-start" }}
                    className="boxBorder_Bottom"
                  >
                    <img
                      src="assets/images/cellPhone.png"
                      alt="cellPhone"
                      className="userIconImg"
                    />
                    <input
                      required
                      //   name="PhoneNumber"
                      //  type="tel"
                      id="phone"
                      placeholder="Enter"
                      type="number"
                      fullWidth
                      variant="standard"
                      InputProps={{ disableUnderline: true, maxLength: 15 }}
                      name="phone_number"
                      value={phone_number}
                      onChange={(e) => setPhone_number(e.target.value)}
                      inputProps={{ maxLength: 10 }}
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 15);
                      }}
                      className="inputFocusVisible"
                      style={{ border: 'none' }}
                    />
                  </Box>
                  <Box sx={{ marginTop: "5px" }}>
                    {errorDisplay.phone_number ? (
                      <span className="red">{errorDisplay.phone_number}</span>
                    ) : (
                      ""
                    )}
                  </Box>
                </Grid>

                <Grid item xs={12} sm={12} md={12} className="">
                  <Typography className="commonEventTypo">
                  {t("Message")}<span className="ValidationRed">*</span>
                  </Typography>
                  <TextareaAutosize
                    aria-label="empty textarea"
                    placeholder={t("Enter_message")}
                    maxLength={200}
                    required
                    name="message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    style={{ height: "" }}
                    className="messageTextArea"
                  ></TextareaAutosize>
                  <Box sx={{ marginTop: "5px" }}>
                    {errorDisplay.message ? (
                      <span className="red">{errorDisplay.message}</span>
                    ) : (
                      ""
                    )}
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  sx={{ textAlign: "center" }}
                  className="cntctSubmitGrid"
                >
                  {/* <Button
                    className="cntctSubmit"
                    onClick={(event) => handleSubmit(event)}
                  >
                    Submit
                  </Button> */}

                  <Button
              disabled={loading}
              className="cntctSubmit"
              onClick={(event) => handleSubmit(event)}
            >
              {loading ? (
                <SpinnerCircular
                  style={{ width: "1.2rem", height: "1.2rem" }}
                  color="light"
                />
              ) : (
                t("Submit")
              )}
            </Button>









                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </div>
    </Box>
  );
};

export default ContactInfo;
