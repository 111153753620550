const getLanguageSource = async(arrayToTranslater) => {
    
    const API_KEY = 'AIzaSyB9GtFt4JKfmKJuQTe3y2Gr8fe_CY62fqg';
    
    let url = `https://translation.googleapis.com/language/translate/v2?key=${API_KEY}`;
    
    
    const response = await fetch(url, {
      body:JSON.stringify({
        "q": arrayToTranslater,
        "target": localStorage.getItem('lang')
      }), 
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      }
    })
    const data  = await response.json()
    
    const data1 = data?.data?.translations
    return data1
    
}

export {getLanguageSource}