import React from "react";
import { GoogleMap, LoadScript, MarkerF } from "@react-google-maps/api";
import { Box } from "@mui/system";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "@mui/material/Modal";
import {useTranslation} from 'react-i18next'
const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "95%",
  maxWidth: 650,
  bgcolor: "#FFFFFF",
  boxShadow: 24,
  overflow: "auto",
};

const mapStyles = {
  height: "70vh",
  width: "100%",
};

export default function MapModalPopup(props) {
  const {t} = useTranslation('common')
  const { customData } = props;
  const defaultCenter = {
    lat: Number(customData.latitude ? customData.latitude : "0"),
    lng: Number(customData.longitude ? customData.longitude : "0"),
  };

  //const eventdetailspage = eventdetailspage1 ?? [];
  const [open3, setOpen3] = React.useState(false);
  const handleOpen3 = () => setOpen3(true);
  const handleClose3 = () => {
    setOpen3(false);
  };
  return (
    <div>
      <button
        className="viewBtn modelViewBtn"
        style={{ marginLeft: "15px" }}
        onClick={handleOpen3}
      >
        {" "}
        <img src="assets/images/map.png" style={{ marginRight: "10px" }}></img>
        {t('Map_View')}
      </button>
      <Modal
        open={open3}
        onClose={handleClose3}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="ModalClass"
      >
        <Box sx={style1} className="ModalClassBox">
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sx={{ textAlign: "right", padding: "16px", mt: "2" }}
              className="ModalTopGrid"
            >
              <CloseIcon className="closeIconOffer" onClick={handleClose3} />

              <Typography
                id="modal-modal-description"
                className="ModalPhomolong"
                sx={{ textAlign: "center", paddingBottom: "20px" }}
              >
                {/* Map View{" "} */}
              </Typography>

              <Box sx={{ paddingLeft: "16px" }} className="borderBoxNone">
                <div>
                  {window.google === undefined ? (
                    <LoadScript googleMapsApiKey="AIzaSyBTurTp7mlb4flvsaigkBALYKRomluAwpc">
                      <GoogleMap
                        mapContainerStyle={mapStyles}
                        zoom={13}
                        center={defaultCenter}
                      >
                        <MarkerF
                          key="marker_1"
                          text="My Marker"
                          position={{
                            lat: defaultCenter.lat,

                            lng: defaultCenter.lng,
                          }}
                        />
                      </GoogleMap>
                    </LoadScript>
                  ) : (
                    <GoogleMap
                      mapContainerStyle={mapStyles}
                      zoom={13}
                      center={defaultCenter}
                    >
                      <MarkerF
                        key="marker_1"
                        text="My Marker"
                        position={{
                          lat: defaultCenter.lat,

                          lng: defaultCenter.lng,
                        }}
                      />
                    </GoogleMap>
                  )}
                </div>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
