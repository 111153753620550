import React from 'react'
import { Grid } from '@mui/material'
import { Box } from '@mui/material'
import { Typography } from '@mui/material'
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';

import {useTranslation} from 'react-i18next'
import './style.css'

const Resource = () => {
    const {t} = useTranslation("common")
    return (
        <div className='resDivPTM'>            
            <Typography className='resource_Typo'> <span className='ResSapn'>{t("Resources")}</span></Typography>
            <Box sx={{ background: '#EAF8FF;' }}>
                <Box className='mainContainer'>
                    <Grid container spacing={2} className='resourceSectionPadding'>
                        <Grid item xs={12} sm={6} md={6} lg={6} style={{ paddingTop: '28px' }} className='howWorkImgGrid'>
                            <div className='PressDiv'>
                                <span className='pressSapn'>Learn</span>
                                <Typography className='GFounder'>{t("You_are_one_step_ahead_to_become_the_Ticketkore_Platform_Expert")}.
                                </Typography>
                                <div>
                                    <Typography className='howToBook'>{t("How_to_book_ticket_on_mobile_app")}?
                                    </Typography>
                                    <a href='https://www.youtube.com/watch?v=Q3zcZEP8FAo' target="_blank">
                                        <img src='assets/images/bookTImg.png' alt='Resource' className='' />
                                    </a>
                                </div>


                                <div style={{ marginTop: '20px' }}>
                                    <Typography className='howToBook'>{t("How to list Exclusive and By Invite Only Events")}?
                                    </Typography>
                                    <a href='https://www.youtube.com/watch?v=b_7rEZBOR1w' target="_blank">
                                        <img src='assets/images/VideoYou.png' alt='Resource' className='' />
                                    </a>
                                </div>

                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} sx={{ textAlign: 'center' }} className="Mres_comp_RImg">
                            <a href='https://www.youtube.com/watch?v=JHyZN8L3vrg' target="_blank">
                                <img src='assets/images/newResImg.png' alt='Resource' className='howWorkImg' />
                            </a>
                        </Grid>
                    </Grid>
                </Box>
            </Box>

            <Box className='mainContainer'>
                <Grid container spacing={2} className='getSpressS'>
                    <Grid item xs={12} sm={6} md={6} lg={6} sx={{ textAlign: 'center' }} className="Mres_comp_RImg">
                        <a href='https://www.youtube.com/watch?v=JHyZN8L3vrg' target="_blank">
                            <img src='assets/images/YTGetStart.png' alt='Resource' className='howWorkImg' />
                        </a>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} lg={6}>
                        <div className='PressDiv'>
                            <span className='pressSapn'>{t("Press")}</span>
                            <div>
                                <Typography className='GFounder'>
                                    <ul className='ulPaddingRm'><li>{t("A New Smart Ticketing App Has Launched")}.</li></ul>
                                </Typography>
                                <Typography className='howToBook' sx={{padding: '10px 20px 0px 20px'}}>{t("Khomotjo Lebepe, founder has launched a new smart ticketing app, Ticketkore. The ticketing app that will change the eventing industry positively and it will ease the frustration of event organizers with the new updated features")}. </Typography>
                                <Typography className='readmoreBtn' sx={{margin: '10px 20px 0px 20px'}}><a href="https://www.youtube.com/watch?v=JHyZN8L3vrg" target='_blank' style={{color: ' #0CA8F6', textDecoration: 'none'}}><span style={{borderBottom: '2px solid #0CA8F6'}}>Watch Now </span> </a><img src='assets/images/watchNow.png' alt='Resource' className='' style={{marginLeft: '10px'}}/></Typography>

                            </div>

                            <div style={{ paddingTop: '20px' }}>
                                <Typography className='GFounder'>
                                    <ul className='ulPaddingRm'><li>{t("A new ticketing platform brings innovation and technology")}</li></ul></Typography>
                                <Typography className='howToBook' sx={{padding: '10px 20px 0px 20px'}}>{t("Ticketing platform Ticketkore has recently launched")}. </Typography>

                                <Typography className='readmoreBtn' sx={{margin: '10px 20px 0px 20px'}}><a href="http://thedealmagazine.co.za/a-new-ticketing-platform-brings-innovation-and-technology/" target='_blank' style={{color: ' #0CA8F6', textDecoration: 'none'}}><span style={{borderBottom: '2px solid #0CA8F6'}}>{t("Read_more")} </span><img src='assets/images/hardArrow.png' alt='Resource' className='' style={{marginLeft: '10px'}}/> </a></Typography>
                            </div>
                        </div>
                    </Grid>
                </Grid>

            </Box>
        </div>
    )
}

export default Resource