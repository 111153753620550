import { Backdrop } from "@mui/material";
import React from "react";
import { Triangle } from "react-loader-spinner";
 import "./style.css"
const CustomBackdrop = () => {
  return (
    <Backdrop
      sx={{ color: "#fff" , zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
    >
      <Triangle
        height="180"
        width="180"
        color="#3498db"
        ariaLabel="triangle-loading"
        // wrapperStyle={{}}
        // wrapperClassName=""
        visible={true}
      />
      <h3 className="loader-text"> TICKET KORE</h3>
    </Backdrop>
  );
};

export default CustomBackdrop;
