import React, { useState } from "react";
import Box from "@mui/material/Box"; 
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import "../Navbar/style.css"
import { useEffect } from "react";
import SearchBar from "../Navbar/SearchBar";
import {useTranslation} from "react-i18next";
import "./Style.css"
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 400,
  //height: 350,
  bgcolor: "background.paper", 
  boxShadow: 24,  
  borderRadius:'10px',
  outline:'none',
  padding:'32px'
};

export default function ModalCountryListDropDown(props) {
  const {t} = useTranslation("common");
  // const [countryData, setCountryData] = useState()
  useEffect(()=>{
    // setCountryData(JSON.parse(localStorage.getItem('autoCountry')))
  },[])

  const handleSelectedCountry = () =>{
    
  }
  return (
    <div>
      {/* <Button onClick={props.handleOpen}>Open modal</Button> */}
      <Modal
        open={props.open}
        onClose={props.handleClose}
        disableEnforceFocus
      >
        <Box  sx={style} className={'countrySelectorModalBox'}>
          <Box>
            <Typography variant="h6" textTransform='capitalize' sx={{textAlign:'center' , mb:4}}>{t('select_country')}</Typography>
          </Box>
          {<Box sx={{fontWeight:600 , mb:4 ,fontSize:'18px' , textTransform:'capitalize', textAlign:'center' , lineHeight:'1.6' }}>Sorry No Event found in this country , please chose different country👇</Box>}
          <Box className='pop-list'>
      
        {/* <select
                className=" select-state SlectForm  flagDropDwonPop"
                onChange={(el) => {
                  props.setCountry(el.target.value)
                  props.getcountryList(el.target.value, true)
                }}
              >
                {props.searchCountry.map((e, index) => (
                  <option
                    value={e.sortname}
                    key={index + "option"}
                  >
                    {e.emoji} {e.country_name}
                  </option>
                ))}
                
              </select>   */}
              <SearchBar listData={props.searchCountry} selectedData={handleSelectedCountry} data={localStorage.getItem('autoCountry') ? JSON.parse(localStorage.getItem('autoCountry')) : ""} popupSearch={true} />
              </Box>
        </Box>
      </Modal>
    </div>
  );
}