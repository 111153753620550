import React from "react";
import Navbar from "../../Components/Navbar"; 
//import BreadCrumbs from '../../Components/BreadCrumbs';
import Wallet from "../../Components/Wallet";
import Footer from "../../Components/Footer";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link } from "react-router-dom";
import { Breadcrumbs, Typography } from "@mui/material";
import { Box } from "@mui/system";
import {useTranslation} from 'react-i18next'
import "./style.css";

const MyWallet = () => {
  const {t} = useTranslation('common')
  const breadcrumbs = [
    <Link to={"/"} key="1" className="link-decoration">
      {t('home')}
    </Link>,

    <Typography
      key="3"
      sx={{
        fontFamily: "Lato",
        color: "#0CA8F6",
        fontWeight: 500,
        fontSize: "16px",
      }}
    >
      {t('My_Wallet')}
    </Typography>,
  ];
  return (
    <>
      <Navbar />
      {/* <MenuBar /> */}
      <Box className="mainContainer">
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          sx={{ mb: 3, mt: 2 }}
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Box>
      <Wallet />
      <Footer />
    </>
  );
};

export default MyWallet;
