import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';


const CCSearch = ({getSelCountry,defaultCC }) => {

  let countrycodebyprofileapi = localStorage.getItem("UserData") ? JSON.parse(localStorage.getItem("UserData")) : {};

 
const CCode= countrycodebyprofileapi.country_code
 

  const countryList = require('../countries.json');
  const [ value , setValue] = useState( '+' +CCode)
  const [inputValue, setInputValue] = React.useState(999);
  const [ShortCode , setShortCode] = useState(localStorage.getItem('autoCountry')  ?  JSON.parse(localStorage.getItem('autoCountry')).sortname : '');
  
  // '+' +
  useEffect(()=>{
    getSelCountry(value , ShortCode)
      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[value])  
  return (
    
    <Autocomplete
    id="country-select-demo"
    disableClearable
    sx={{ width:135 }}
    options={countryList}  
    value= {value}
    className='custom-CCSearch'
    onChange={(event, value) => {
 
      setValue(value ?   '+' + value.phone  : '');
      setShortCode(value ? value.sortname  : '')
    }}
    inputValue={inputValue}
    onInputChange={(event, newInputValue) => {
      setInputValue(newInputValue);
    }}
    ListboxProps={
      {
        style:{
            maxHeight: '190px', 
            width: 'auto'
        }
      }
    }
    autoHighlight
    loading={true}
    getOptionLabel={(option) => option.phone ? '+'+option.phone + option.country_name : value}
 
    renderOption={(props, option) => (
      <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 }, width: '300px', position: 'relative' }} {...props}>
       
        <img
          loading="lazy"
          width="20"
          src={`https://flagcdn.com/w20/${option.sortname?.toLowerCase()}.png`}
          srcSet={`https://flagcdn.com/w40/${option.sortname?.toLowerCase()}.png 2x`}
          alt=""
        />
        {/* {option.label} ({option.phone}) + */}
         +{option.phone} {option.country_name}
      </Box>
    )}
    renderInput={(params) => (
      <TextField
        {...params}
        // label="Choose a country"
        
        sx={{background:'white'}}
        inputProps={{
          ...params.inputProps,
          autoComplete: 'new-password', // disable autocomplete and autofill
        }}
      />
    )}
  />
  )
}

export default CCSearch
 