import { Card, Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import http from "../../Services/http";
import { Box } from "@mui/material";
import { Typography } from "@mui/material";
import parse from "html-react-parser";
import { useLocation, useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import FavoriteIcon from "@mui/icons-material/Favorite";
import NotificationManager from "react-notifications/lib/NotificationManager";
import getSymbolFromCurrency from "currency-symbol-map";
// import nodataImg from "../../../public/assets/images/no-data.png"
import "./style.css";
import {useTranslation} from 'react-i18next'
const Favorite = () => {
  const {t} = useTranslation("common")
  const navigate = useNavigate();

  // const [page, setPage] = useState(0);

  const [page, setPage] = React.useState(1);
  const [orderList, setorderList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  // const [page, setPage] = React.useState(1);
  const [count, setCount] = React.useState(0);

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();

  const dateFormated = (date) => {
    let dateNew = new Date(date);
    let day = dateNew.toLocaleString("en-us", { weekday: "short" });
    let newdate = new Date(date).getDate();
    let month = dateNew.toLocaleString("default", { month: "short" });
    return day + "," + newdate + " " + month;
  };

  // Favevents function
  const HandleFavEvent = (event, page) => {
    setIsLoading(true);
    if (page) setPage(page);
    else setPage(1);
    const OrderListData = {
      page: page ? page : 1,
      keyword: "",
    };
    http("events/get_favourite_event", "POST", OrderListData).then((res) => {
      setIsLoading(false);
      setCount(0);
      setCount(Math.ceil(res.data.total_rows / res.data.page_size));
      setorderList(res.data.total_rows ?? []);
      const data = res.data.data ?? [];

      setOrders(data);
    });
  };

  const HandleFavEvent1 = (event, page) => {
    if (page) setPage(page);
    else setPage(1);
    const OrderListData = {
      page: page ? page : 1,
      keyword: "",
    };
    http("events/get_favourite_event", "POST", OrderListData).then((res) => {
      setCount(0);
      setCount(Math.ceil(res.data.total_rows / res.data.page_size));
      setorderList(res.data.total_rows ?? []);
      const data = res.data.data ?? [];

      setOrders(data);
    });
  };

  // Fav api
  const Favourites = (ele, data) => {
    let postData = {
      eventid: data.event_id,
    };
    http("events/favourite_event", "POST", postData).then((res) => {
      if (
        res.data.msg === "success! event is added in your favourite section"
      ) {
        HandleFavEvent1();
      } else {
        HandleFavEvent1();
        NotificationManager.success(
          "event is removed in your favourite section",
          "",
          800
        );
      }
    });
  };

  useEffect(() => {
    HandleFavEvent(1, "");
    HandleFavEvent1(1, "");
  }, []);

  const HandleClick = (e, id, event_name) => {
    let EventName = event_name.replaceAll(/[^a-zA-Z0-9 ]/g, "");

    navigate(
      `/events/${EventName.replaceAll(" ", "-")}/` +
        id +
        "?flag=" +
        query.get("flag")
    );
  };

  // Search  & Pagination Functionality

  const [orders, setOrders] = useState([]);
  const [results, setResults] = useState([]);

  const [searchedWorker, setSearchedWorker] = useState("");

  useEffect(() => {
    const filtered = orders.filter((order) =>
      order.event_name.toLowerCase().includes(searchedWorker)
    );
    setResults(filtered);
  }, [searchedWorker, orders]);

  return (
    <>
      <Box className="mainContainer">
        <Grid container alignItems="center">
          <Grid item xs={12} sm={6} md={6}>
            <Typography className="layoutTitle d-flex justify-align-item">
            {t('my_favourite')}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={6} className="searchEventFav">
            <div>
              <input
                type="text"
                class="input-bordered inputSearch"
                style={{ float: "right" }}
                placeholder="Search by EventName"
                value={searchedWorker} //property specifies the value associated with the input
                onChange={(e) =>
                  setSearchedWorker(e.target.value.toLowerCase())
                }
              />
            </div>
          </Grid>
          <div className="hLine"></div>
        </Grid>

        {isLoading ? (
          <div style={{ height: "400px" }}>
            <div className="loader ">
              <ClipLoader />
            </div>
          </div>
        ) : (
          <Grid
            container
            spacing={4}
            sx={{ marginTop: "0px", marginBottom: "40px" }}
          >
            <>
              {results.length > 0 ? (
                results.map((ele) => {
                  return (
                    <>
                      <Grid
                        key={ele.event_id}
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        lg={4}
                      >
                        <Card
                          className="favoriteCard"
                          style={{ cursor: "pointer" }}
                          // onClick={(e) => HandleClick(e, ele.event_id)}
                        >
                          {ele.eventExpireFlag ? (
                            <img
                              alt="expired event"
                              className="expired-event"
                              src="assets/icons/expired-event.png"
                            />
                          ) : (
                            <></>
                          )}
                          <Box className="flexGrid">
                            <item
                              className="w-45"
                              onClick={(e) =>
                                HandleClick(e, ele.event_id, ele.event_name)
                              }
                            >
                              <img
                                src={
                                  ele.event_thumbnail
                                    ? ele.event_thumbnail
                                    : "assets/images/favorite.png"
                                }
                                alt="event-thumbnail"
                              />
                            </item>
                            <item className="w-55">
                              <div className="d_flexFav">
                                <Typography
                                  className="bookingTitleNew"
                                  onClick={(e) =>
                                    HandleClick(e, ele.event_id, ele.event_name)
                                  }
                                >
                                  {ele.event_name}
                                </Typography>

                                <FavoriteIcon
                                  className="fav_Icon"
                                  onClick={(e) => Favourites(e, ele)}
                                />
                              </div>
                              <div
                                onClick={(e) =>
                                  HandleClick(e, ele.event_id, ele.event_name)
                                }
                              >
                                <Typography className="bookingAddressBox">
                                  <img
                                    src="assets/images/booking-icon/calendar-icon.png"
                                    alt="calendar"
                                    style={{ marginRight: "23px" }}
                                  />
                                  <Typography className="bookingAddress word-ellipsis">
                                    {dateFormated(
                                      ele.event_date
                                        ? ele.event_date
                                        : ele.event_date
                                    )}
                                  </Typography>
                                </Typography>
                                <Typography className="bookingAddressBox">
                                  <img
                                    src="assets/images/booking-icon/map-pin.png"
                                    alt="map pin"
                                    style={{ marginRight: "23px" }}
                                  />
                                  <Typography className="bookingAddress word-ellipsis">
                                    {ele.venue_address
                                      ? ele.venue_address
                                      : ele.country_name}
                                    ,
                                    {ele.event_city_name
                                      ? ele.event_city_name
                                      : ele.state_name}
                                  </Typography>
                                </Typography>
                                <Typography
                                  className="bookingOrderNo colorBlue"
                                  sx={{ fontWeight: "700 !important" }}
                                >
                                  <span className="colorDarkBlue">Price:</span>
                                  {getSymbolFromCurrency(ele.currency)}{" "}
                                  {ele.minprice}
                                </Typography>
                              </div>
                            </item>
                          </Box>
                          <div className="hLine"></div>

                          <Typography
                            className="favoriteText"
                            onClick={(e) =>
                              HandleClick(e, ele.event_id, ele.event_name)
                            }
                          >
                            {parse(
                              ele.event_description.substring(0, 180) + "..."
                            )}
                          </Typography>
                        </Card>
                      </Grid>
                    </>
                  );
                })
              ) : (
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src="assets/images/no-data.png"
                      className="no-fav-img"
                      alt="data-not-found"
                    />
                  </Box>
                  <Typography
                    variant="body1"
                    sx={{
                      textAlign: "center",
                      height: "50px",
                      fontSize: "22px",
                      mb: 4,
                    }}
                  >
                    No favorite event found. Make event favorite clicking on
                    heart button.
                  </Typography>
                </Grid>
              )}

              {orderList > 10 ? (
                <Grid
                  container
                  justifyContent="center"
                  flexWrap="wrap"
                  sx={{ padding: "30px inherit !important" }}
                >
                  <Stack spacing={2} className="customPagination">
                    <Pagination
                      count={count}
                      page={page}
                      onChange={HandleFavEvent}
                    />
                  </Stack>
                </Grid>
              ) : (
                ""
              )}
            </>
          </Grid>
        )}
      </Box>
    </>
  );
};

export default Favorite;
