import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import { styled as MUIStyled } from "@mui/material/styles";
import {
  Button,
  Card,
  Grid,
  StepConnector,
  stepConnectorClasses,
} from "@mui/material";
import { Box } from "@mui/material";
import { Typography } from "@mui/material";
import { NotificationManager } from "react-notifications";
import {getLanguageSource} from '../../Services/translateString'
import ClipLoader from "react-spinners/ClipLoader";
import Carousel from "react-elastic-carousel";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import http from "../../Services/http";
import { useNavigate, useParams } from "react-router-dom";
import getSymbolFromCurrency from "currency-symbol-map";
import styled from 'styled-components';
import "./style.css";
// import CloseIcon from '@mui/icons-material/Close';
import Modal from "@mui/material/Modal";
// import ModalPopup from "../ModalPopup";
import SocialSharePopuporders from "../SocialSharePopuporders";
// import parse from "html-react-parser";
import CloseIcon from "@mui/icons-material/Close";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import CircleSharpIcon from "@mui/icons-material/CircleSharp";
import RadioButtonCheckedOutlinedIcon from "@mui/icons-material/RadioButtonCheckedOutlined";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useForm } from "react-hook-form";
import _ from 'lodash'
import {useTranslation} from 'react-i18next'
//components
import ShowLocationDates from "../ShowlocationDate&TimePopUp";

// Radio Import
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
const UpgradeStyle = styled.div`
cursor: pointer;
color: #1976d2;
`
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 600,
  bgcolor: "#FFFFFF",
  boxShadow: 24,
  p: 4,
  width: "95%",
};
const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  background: "#FFFFFF",
  borderRadius: "16px",
};
const breakPoints = [
  { width: 1, itemsToShow: 1.2, itemPadding: [10, 3] },
  { width: 550, itemsToShow: 1.8, itemPadding: [10, 5] },
  { width: 768, itemsToShow: 1.8, itemPadding: [10, 8] },
  { width: 1200, itemsToShow: 1.8, itemPadding: [10, 12] },
];

const Item = MUIStyled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(0),
  color: theme.palette.text.secondary,
  width: "100%",
}));

const QontoConnector = MUIStyled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      border: "1px dashed #784af4",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      // border:"1px dashed #784af4",
      background: "#1F87BA",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderTopWidth: 3,
    borderRadius: 1,
    border: "1px dashed #784af4",
  },
}));

const BookingDetails = (props) => {
  const {t} = useTranslation('common')
  const [upgradeTicket, setUpgradeTicket] = useState(null)
const [eventDetails, setEventDetails] = useState(null)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [opengift, setOpengift] = React.useState(false);
  const handleOpengift = () => setOpengift(true);
  const handleClosegift = () => setOpengift(false);

  let userDetails = localStorage.getItem("loginUser")
    ? JSON.parse(localStorage.getItem("loginUser"))
    : {};
  const getusercountrycode = userDetails.country_code
    ? userDetails.country_code
    : "";

  const countryList = require("../../countries.json");
  const [value, setValue] = useState(getusercountrycode);

  const dateFormated = (date) => {
    let dateNew = new Date(date);
    let day = dateNew.toLocaleString("en-us", { weekday: "short" });
    let newdate = new Date(date).getDate();
    let month = dateNew.toLocaleString("default", { month: "short" });
    return day + "," + newdate + " " + month;
  };
 const FreedateFormated = (startdate) => {
    let dateNew = new Date(startdate);

    let day = dateNew.toLocaleString("en-us", { weekday: "short" });
    let newdate = new Date(startdate).getDate();
    let month = dateNew.toLocaleString("default", { month: "short" });
    let time = dateNew.toLocaleString("en-us", { hour: "2-digit", minute: "2-digit"});
       return day + "," + newdate + " " + month+ " " + time ;
  };

  const [expiryTime, setExpiryTime] = useState(
    localStorage.getItem("eventtimer")
  );
  const [seatNums, setSeatNums] = useState([]);
  const [viewSeats, setViewSeats] = useState(false);
  const handleCloseSeatViews = () => {
    setViewSeats(false);
  };
  const onSubmit = (data) => {
    if (
      data.phone_number ===
        JSON.parse(localStorage.getItem("loginUser")).phone ||
      data.email_address === JSON.parse(localStorage.getItem("loginUser")).email
    ) {
      toast.error("you can not transfer or gift ticket to yourself", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        toastId: "error1",
      });
      return;
    }
    let postData = {
      order_id: params.id,
      name: data.fullname,
      phone_code:
        value === getusercountrycode ? getusercountrycode : value.slice(4),
      phone: data.phone_number,
      email: data.email_address,
      transfer_type: giftvalue === "transferticket" ? "1" : "2", //1 for transfer 2 for gift
    };

    http("order_transfer/create", "POST", postData).then(async(res) => {
      // console.log(res, "transfer requerst");
      if (res.data.status) {
        const translatedText = await getLanguageSource(res.data.msg)
        NotificationManager.success(translatedText[0].translatedText || res.data.msg);
        giftvalue === "transferticket" ? navigate("/") : <></>;
        setOpengift(false);
        OrderDetailsevent();
      } else {
        const translatedText = await getLanguageSource(res.data.msg)
        NotificationManager.error(translatedText[0].translatedText || res.data.msg);
      }
    });
  };

  const [countdownTime, setCountdownTime] = useState({
    countdownDays: "",
    countdownHours: "",
    countdownlMinutes: "",
    countdownSeconds: "",
  });

  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const params = useParams();
  const orderId = params.id;
  // const cancelOrderId = params.id;

  // Modal for OrderCheckSubmit
  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);

  // Order Details Api
  // food_order_history
  const [foodorderDeatils, setfoodorderDeatils] = useState([]);

  const [orderDeatils, setOrderDeatils] = useState({});
  const [orderTicketDeatils, setTicketDeatils] = useState([]);
  const [addon, setAddon] = useState([]);
  const [currencyicon, setCurrencyicon] = useState("");
  const [showTimer, setShowTimer] = useState(false);

  // Sharemod
  const [open2, setOpen2] = React.useState(false);
  const handleClose2 = () => setOpen2(false);
  const handleOpen2 = () => setOpen2(true);

  // navigate

  const navigate = useNavigate();

  const translateData = async (orderDeatilss)=>{
    setOrderDeatils({})
   
      const stringArray = [
        orderDeatilss.event_name,
        orderDeatilss.city_name,
        orderDeatilss.venue_name,
        orderDeatilss.venue_address,

      ]
      const translatedText = await getLanguageSource(stringArray) 
      console.log(translatedText)
      orderDeatilss.event_name = translatedText[0].translatedText || orderDeatilss.event_name;
      orderDeatilss.city_name = translatedText[1].translatedText || orderDeatilss.city_name;
      orderDeatilss.venue_name = translatedText[2].translatedText || orderDeatilss.venue_name;
      orderDeatilss.venue_address = translatedText[3].translatedText || orderDeatilss.venue_address;

        
          setIsLoading(false)
           // const dangerouslySetInnerHTML={{__html: res.data.data.share_data}}

        setCurrencyicon(getSymbolFromCurrency(orderDeatilss.currency ?? []));
        setOrderDeatils(orderDeatilss);

        localStorage.setItem(
          "eventtimer",
          orderDeatilss.event_date + " " + orderDeatilss.event_time
        );
        if (
          new Date(
            orderDeatilss.event_date + " " + orderDeatilss.event_time
          ).getTime() > new Date().getTime()
        ) {
          setShowTimer(true);
          countdownTimer();
        } else {
          setShowTimer(false);
        }

        setTicketDeatils(orderDeatilss.ticket_details);
        setfoodorderDeatils(orderDeatilss.food_order_history ?? []);
        setAddon(orderDeatilss.add_on_services_details);
        
    
    

  }

  useEffect(()=>{
    setIsLoading(true)
   !_.isEmpty(orderDeatils)  && translateData(orderDeatils)
  },[localStorage.getItem('lang')])

  const OrderDetailsevent = () => {
    setIsLoading(true);
    const OrderListData = {};
    OrderListData.order_id = orderId ? orderId : 1;

    http("orders/order_details", "POST", OrderListData)
      .then((res) => {
        const orderDeatilss = res.data.data ?? [];
        localStorage.removeItem("orderID");
        translateData(orderDeatilss)
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const [orderCancelDeatils, setCancelDeatils] = useState({});
  const [cancelReason, setCancelReason] = useState("");

  const OrderCancelEvent = () => {
    const OrderCancelData = {
      order_id: params.id,
      order_cancellation_status: "0",
      event_cancel_reason: cancelReason,
    };
    http("orders/order_cancel", "POST", OrderCancelData).then(async(res) => {
      const orderCancelDeatils = res.data.data ?? {};

      setCancelDeatils(orderCancelDeatils);
      const data = res.data ?? [];

      if (data.status === 1) {
        const translatedText = await getLanguageSource(data.msg)
        NotificationManager.success(translatedText[0].translatedText || data.msg);
        setOpen(false);
        window.location.reload();
        // window.reload()
      } else {
        const translatedText = await getLanguageSource(data.msg)
        NotificationManager.error(translatedText[0].translatedText || data.msg);
      }
    });
  };

  //events POstpone

  const eventpostponrequestconfirm = () => {
    const OrderCancelData = {
      order_id: params.id,
      status: "1",
    };
    http("orders/event_postpon_request", "POST", OrderCancelData).then(
      async(res) => {
        if (res.data.status === 1) {
          OrderDetailsevent();
          const translatedText = await getLanguageSource(res.data.msg)
          NotificationManager.success(translatedText[0].translatedText || res.data.msg);
        } else {
          const translatedText = await getLanguageSource(res.data.msg)
          NotificationManager.error(translatedText[0].translatedText || res.data.msg);
        }
      }
    );
  };

  const eventpostponrequestrefund = () => {
    const OrderCancelData = {
      order_id: params.id,
      status: "2",
    };
    http("orders/event_postpon_request", "POST", OrderCancelData).then(
      async(res) => {
        if (res.data.status === 1) {
          OrderDetailsevent();
          const translatedText = await getLanguageSource(res.data.msg)
          NotificationManager.success(translatedText[0].translatedText || res.data.msg);
        } else {
          const translatedText = await getLanguageSource(res.data.msg)
          NotificationManager.error(translatedText[0].translatedText || res.data.msg);
        }
      }
    );
  };

  // onClick={handleClose(),handleOpen1()}
  const handlefcancel = () => {
    handleClose();
    handleOpen1();
  };

  const countdownTimer = () => {
    const timeInterval = setInterval(() => {
      const countdownDateTime = new Date(
        localStorage.getItem("eventtimer")
      ).getTime();
      const currentTime = new Date().getTime();
      const remainingDayTime = countdownDateTime - currentTime;
      const totalDays = Math.floor(remainingDayTime / (1000 * 60 * 60 * 24));
      const totalHours = Math.floor(
        (remainingDayTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const totalMinutes = Math.floor(
        (remainingDayTime % (1000 * 60 * 60)) / (1000 * 60)
      );
      const totalSeconds = Math.floor((remainingDayTime % (1000 * 60)) / 1000);

      const runningCountdownTime = {
        countdownDays: totalDays,
        countdownHours: totalHours,
        countdownMinutes: totalMinutes,
        countdownSeconds: totalSeconds,
      };

      setCountdownTime(runningCountdownTime);

      if (remainingDayTime < 0) {
        clearInterval(timeInterval);
        setExpiryTime(false);
      }
    }, 1000);
  };

  const onButtonClick = (ticketkore) => {
    fetch(orderDeatils.order_in_pdf).then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        // alink.download = orderDeatils.order_in_pdf.lastIndexOf('/') + 1;
        alink.download = orderDeatils.order_in_pdf.substring(
          orderDeatils.order_in_pdf.lastIndexOf("/") + 1
        );
        alink.click();
      });
    });
  };
  const [giftvalue, setGiftvalue] = React.useState("giftticket");

  // multiday range

  const [openDate, setOpenDate] = useState(false);
  const [selectedLocationData, setSelectedLocationData] = useState({});
  const handleOpenDates = (eventId, locationId, venueName, dateRange) => {
    let sendData = {
      event_id: eventId,
      location_id: locationId,
      venue_name: venueName,
      locationDateRange: dateRange,
    };
    setOpenDate(true);
    setSelectedLocationData(sendData);
  };
  const handleCloseDate = () => {
    setOpenDate(false);
  };
  const closePopup = (message) => {
    setOpenDate(message);
  };

  useEffect(() => {
    localStorage.removeItem("eventtimer");
    OrderDetailsevent();
  }, []);

  useEffect(()=>{
    if(!_.isEmpty(orderDeatils)){
      
      const countryDetails = JSON.parse(localStorage.getItem('autoCountry'))
      const url = 'events/getAllTicketsCatByEventId'
      const postData = {
        eventid:orderDeatils.event_id,
        country_id:countryDetails.id,
        locationid:orderDeatils.location_id,
        order_id:orderDeatils.id,
        category_id: orderDeatils?.ticket_details[0]?.category_id
      }
      //console.log(postData)
      setIsLoading(true);
      http(url, "POST", postData,undefined, undefined, 'v17').then((res) => {
          setIsLoading(false);
    
          const data = res.data.data ?? [];
          setUpgradeTicket(data)
          setEventDetails(res.data.event_details)
          
        });
    }
  },[orderDeatils])

  // timer ends here
const upgrateTicket = ()=>{
  navigate(`/bookticket/${orderDeatils.event_id}`,{state:{eventdetailspage1:{eventdetailspage1:{...eventDetails[0],
    upgradeSeatDetails:upgradeTicket,
     venue_address:orderDeatils.venue_address,
      event_date:orderDeatils.event_date,
      event_time:orderDeatils.event_time,
      previousCatId:orderTicketDeatils[0]?.category_id,
      upgrade_cat: orderTicketDeatils[0]?.upgrade_cat
    }}}})
}
  return (
    <Box className="mainContainer">
      <Grid container spacing={4}>
        <Grid item xs={12} md={12} sx={{ marginTop: "15px" }}>
          {/* <Typography className="layoutTitle">Order Details123</Typography> */}
          <Box className="textFieldBox">
            <Typography className="layoutTitle mobOlLayoutTitle">
              {t("Order_Details")}
            </Typography>

            {orderDeatils.status === "0" ||
            orderDeatils.status === "2" ||
            orderDeatils.event_cancel === "1" ? (
              <></>
            ) : (
              <Typography className="layoutTitle mobRespTypoEnd">
                {orderDeatils.package_activate === "1" ? (
                  <img
                    src="assets/images/package-details.png"
                    alt="package-details"
                    className="cursor_pointer packageDetails-img"
                    onClick={() =>
                      navigate("/package-details", {
                        state: {
                          OrderNum: orderDeatils.order_number,
                          OrderID: orderId,
                        },
                      })
                    }
                  />
                ) : (
                  <></>
                )}
                {orderDeatils.order_in_pdf ? (
                  <>
                    <a
                      href={`${orderDeatils.order_in_pdf}`}
                      download
                      target="_blank"
                    >
                      <img
                        src="assets/images/Group1.png"
                        alt="share"
                        className="cursor_pointer ViewmobResImg"
                        style={{ marginRight: "15px" }}
                      />
                    </a>

                    <img
                      src="assets/images/Group.png"
                      alt="share"
                      className="cursor_pointer downmobResImg"
                      style={{ marginRight: "15px" }}
                      onClick={onButtonClick}
                    />
                  </>
                ) : (
                  <></>
                )}
            {orderDeatils.complementary_order === "0" ?    <img
                  src="assets/images/order_shareIcon.png"
                  alt="share"
                  className="cursor_pointer ShareImobResImg"
                  onClick={handleOpen2}
                /> : <></>}
              </Typography>
            )}
          </Box>
          <div className="hLine"></div>
        </Grid>
      </Grid>

      {isLoading ? (
        <div style={{ height: "400px" }}>
          <div className="loader ">
            <ClipLoader />
          </div>
        </div>
      ) : (
        <Grid
          container
          spacing={5}
          sx={{ marginTop: "0px", marginBottom: "40px" }}
        >
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Card className="bookingCard" sx={{ paddingTop: "18px" }}>
              <Box className="rescheduledRow">
                {/* <item className="rescheduledImgGroup">
                  {orderDeatils.status === "0" ||
                  orderDeatils.status === "2" ? (
                    <img
                      src="assets/images/booking-icon/cancelled.png"
                      alt="cancelled"
                    />
                  ) : orderDeatils.event_cancel === "1" ? (
                    <img
                      src="assets/images/booking-icon/cancelled.png"
                      alt="cancelled"
                    />
                  ) : orderDeatils.event_postpond === "1" &&
                    orderDeatils.event_cancel === "0" ? (
                    <img
                      src="assets/images/booking-icon/rescheduled.png"
                      alt="rescheduled"
                    />
                  ) : (
                    <img
                      src="assets/images/booking-icon/Successful.png"
                      alt="ticket"
                    />
                  )}
                </item> */}

                <item className="rescheduledImgGroup">
                  {orderDeatils.status === "0" ? (
                    <img
                      src="assets/images/booking-icon/Pending.png"
                      alt="cancelled"
                    />
                  ) : orderDeatils.status === "2" ? (
                    <img
                      src="assets/images/booking-icon/Failed.png"
                      alt="cancelled"
                    />
                  ) : orderDeatils.event_cancel === "1" ? (
                    <img
                      src="assets/images/booking-icon/cancelled.png"
                      alt="cancelled"
                    />
                  ) : orderDeatils.event_postpond === "1" &&
                    orderDeatils.event_cancel === "0" ? (
                    <img
                      src="assets/images/booking-icon/rescheduled.png"
                      alt="rescheduled"
                    />
                  ) : orderDeatils?.giftDetails?.gift_transfer_status === "1" &&
                    orderDeatils?.giftDetails?.transfer_type === "2" ? (
                    <img src="assets/images/recived-gift.png" alt="cancelled" />
                  ) : (orderDeatils?.giftDetails?.gift_transfer_status ===
                      "1" &&
                      orderDeatils?.giftDetails?.transfer_type === "1") ||
                    (orderDeatils.giftDetails?.gift_transfer_status === "0" &&
                      orderDeatils.gift_transfer_type === "2") ? (
                    <img src="assets/images/gift-icon.png" alt="gift-icon" />
                  ) : (
                    <img
                      src={
                        orderDeatils.gift_transfer_type === "1"
                          ? "assets/images/booking-icon/transfer-icon.png"
                          : orderDeatils.event_postpond !== "1" &&
                            orderDeatils.event_cancel === "0" &&
                            orderDeatils.complementary_order === "1"
                          ? "assets/images/booking-icon/complementary-icon.png"
                          : "assets/images/booking-icon/Successful.png"
                      }
                      alt="ticket"
                    />
                  )}
                </item>

                <item className="bookingDetails">
                  <Typography
                    className="bookingTitle fontSize22"
                    sx={{ whiteSpace: "pre-wrap" }}
                  >
                    {" "}
                    {orderDeatils.event_name}{" "}
                  </Typography>
                  <Typography className="bookingAddressBox">
                    <img
                      src="assets/images/booking-icon/calendar-icon.png"
                      alt="calendar"
                      style={{ marginRight: "23px" }}
                    />
                    <Typography className="bookingAddress fontSize18">
                      {" "}
                      {orderDeatils.muliday_enabled_flag !== "1"
                        ? `${dateFormated(orderDeatils.event_date)} ${
                            orderDeatils.event_time
                          }`
                        : orderDeatils.multi_day_date_range}
                    </Typography>
                    {orderDeatils.muliday_enabled_flag === "1" ? (
                      <Button
                        className="seat_Booking_info"
                        onClick={() =>
                          handleOpenDates(
                            orderDeatils.event_id,
                            orderDeatils.location_id,
                            orderDeatils.venue_name,
                            orderDeatils.multi_day_date_range
                          )
                        }
                      >
                        <img
                          src="assets/images/info_icon.png"
                          className="seat_Booking_info-icon"
                          alt=""
                        />
                        {t("info")}
                      </Button>
                    ) : (
                      <></>
                    )}
                  </Typography>
                  <Typography className="bookingAddressBox">
                    <img
                      src="assets/images/booking-icon/map-pin.png"
                      alt="map pin"
                      style={{ marginRight: "23px" }}
                    />
                    <Typography className="bookingAddress fontSize18">
                      {orderDeatils.venue_name} {orderDeatils.venue_address}
                    </Typography>
                  </Typography>
                </item>
              </Box>

              {/* --------------Timer------------- */}
              {orderDeatils.status === "1" &&
              orderDeatils.event_cancel === "0" ? (
                showTimer ? (
                  <Box
                    className="BookingTimerBox"
                    sx={{ backgroundColor: "lightgray" }}
                  >
                    <img src="assets/images/booking-icon/time-icon.png" alt="timer" />
                    <Typography className="timerTextColor">
                      {countdownTime.countdownDays}D :{" "}
                      {countdownTime.countdownHours}H :{" "}
                      {countdownTime.countdownMinutes}M :
                      {countdownTime.countdownSeconds}S {t("Left")}
                    </Typography>
                  </Box>
                ) : (
                  ""
                )
              ) : (
                ""
              )}

              {/* {showTimer ? (
                <Box
                  className="BookingTimerBox"
                  sx={{ backgroundColor: "lightgray" }}
                >
                  <img src="assets/images/timerIcon.png" alt="timer" />
                  <Typography className="timerTextColor">
                    {countdownTime.countdownDays}D :{" "}
                    {countdownTime.countdownHours}H :{" "}
                    {countdownTime.countdownMinutes}M :
                    {countdownTime.countdownSeconds}S Left
                  </Typography>
                </Box>
              ) : (
                ""
              )} */}
              {/* ------------------------------  Ticket Data ------------------- */}

              {orderTicketDeatils.map((res) => (
                <Box className="bookingTicketPriceDetail">
                  <item>
                    <Typography className="bookingTicketPriceText bookingTicketPriceTextBold">
                      {res.ticket_category}
                    </Typography>
                  </item>

                  <div className="bookingvLine"></div>

                  {orderDeatils.sheet_numbers ? (
                    <Button
                      sx={{ textTransform: "capitalize" }}
                      className="ticket-bg"
                      onClick={() => {
                        setSeatNums(orderDeatils.sheet_numbers);
                        setViewSeats(true);
                      }}
                    >
                      <span className="colorBlue extra-design">
                        {res.ticket_quantity}
                      </span>
                    </Button>
                  ) : (
                    <item>
                      <Typography className="bookingTicketPriceText fontSize18">
                      {t("Ticket")} :{" "}
                        <span className="colorBlue">{res.ticket_quantity}</span>
                      </Typography>
                    </item>
                  )}
                  <div className="bookingvLine"></div>
                  <item>
                    <Typography className="bookingTicketPriceText fontSize18">
                    {t("Price")}:{" "}
                      <span className="colorBlue">
                        {orderDeatils.ticket_total_base_amount}
                      </span>
                    </Typography>
                  </item>
                </Box>
              ))}

              <Box className="flexGrid grid33">
                <Typography className="orderText">{t("Tax")}</Typography>
                <Typography></Typography>
                <Typography className="orderText colorBlue">
                  {currencyicon}{" "}
                  {parseFloat(orderDeatils.vat_amount).toFixed(2)}
                </Typography>
              </Box>

              <Box className="flexGrid grid33">
                <Typography className="orderText">{t("Service_Fee")}</Typography>
                <Typography></Typography>
                <Typography className="orderText colorBlue">
                  {currencyicon} {orderDeatils.ticket_commission_total_amount}{" "}
                </Typography>
              </Box>

              {orderDeatils.base_price_coupon_range_discount > "0.00" ? (
                <Box className="flexGrid grid33">
                  <Typography className="orderText">
                  {t("Applied_Discount")}
                  </Typography>
                  <Typography></Typography>
                  <Typography className="orderText colorGreen">
                    - {currencyicon}
                    {orderDeatils.base_price_coupon_range_discount}{" "}
                  </Typography>
                </Box>
              ) : (
                ""
              )}
              {orderDeatils.is_ticket_upgraded === "1" ? (
                <Box className="flexGrid grid33">
                  <Typography className="orderText">Refund Protect</Typography>
                  <Typography className="orderText colorBlue">
                    {currencyicon}

                    {orderDeatils.ticket_upgraded_price}
                  </Typography>
                </Box>
              ) : (
                <></>
              )}
              <Box className="flexGrid totalOrderBg grid33">
                <Typography className="totalTicketTitle">
                {t("Total_Ticket_Price")} :
                </Typography>
                <Typography></Typography>
                <Typography className="totalTicketTitle colorDarkBlue">
                  {currencyicon}{" "}
                  {orderDeatils.is_ticket_upgraded === "1"
                    ? parseFloat(
                        parseFloat(orderDeatils.total_amount) +
                          parseFloat(orderDeatils.ticket_upgraded_price)
                      ).toFixed(2)
                    : parseFloat(orderDeatils.total_amount).toFixed(2)}
                </Typography>
              </Box>
            </Card>

            {addon.length >= 0 && addon.length !== 0 ? (
              <>
                <Typography
                  className="fontBold colorDarkBlue"
                  sx={{ paddingTop: "20px" }}
                >
                  {t("Add_Ons_Merchandise")}
                </Typography>

                <Card className="bookingCard" sx={{ marginTop: "15px" }}>
                  <Box className="flexGrid totalTicketTitleHeading grid33">
                    <Typography className="totalTicketTitle">Item</Typography>
                    <Typography className="totalTicketTitle">
                    {t("Quantity")}
                    </Typography>
                    <Typography className="totalTicketTitle">Price</Typography>
                  </Box>

                  {addon.map((ele, index) => {
                    return (
                      <Box className="flexGrid grid33">
                        <Typography className="orderText">
                          {ele.service_title}{" "}
                          <span style={{ fontSize: "11px" }}>
                            {ele.label ? `(${ele.label}: ${ele.value})` : ""}
                          </span>
                        </Typography>
                        <Typography className="orderText colorBlue">
                          {/* setQuantity(ele.purchase_quantity) */}
                          {ele.purchase_quantity}
                        </Typography>
                        <Typography className="orderText colorBlue">
                          {" "}
                          {/* setprice(ele.total_price) */}
                          {currencyicon} {ele.total_price}
                        </Typography>
                      </Box>
                    );
                  })}

                  {/* {orderDeatils.refund_status === "1" ? (
                    <Box className="flexGrid flexGrid grid33">
                      <Typography className="orderText">
                        Refund Amountssss :
                      </Typography>
                      <Typography></Typography>
                      <Typography className="orderText red">
                        {" "}
                        -{currencyicon} {orderDeatils.refund_amount}
                      </Typography>
                    </Box>
                  ) : (
                    ""
                  )} */}

                  {/* {addon.map((ele, index) => {
                    return ( */}
                  <Box className="flexGrid totalOrderBg grid33">
                    <Typography className="totalTicketTitle">
                    {t("Total_Items_Price")} :
                    </Typography>
                    <Typography></Typography>
                    <Typography className="totalTicketTitle colorDarkBlue">
                      {" "}
                      {currencyicon}
                      {parseFloat(
                        parseInt(orderDeatils.chillers_total_amount) +
                          parseInt(orderDeatils.merchandise_total_amount) +
                          parseInt(orderDeatils.parking_total_amount)
                      ).toFixed(2)}
                    </Typography>
                  </Box>
                  {/* );
                  })} */}
                </Card>
              </>
            ) : (
              ""
            )}

            {/* ------------------------------  Food section ------------------- */}

            {foodorderDeatils.length >= 0 && foodorderDeatils.length !== 0 ? (
              <>
                <Typography
                  className="fontBold colorDarkBlue"
                  sx={{ paddingTop: "20px" }}
                >
                  {t("Ordered_Food_Details")}
                </Typography>
                <Card
                  className="bookingCard scrollDiv"
                  sx={{ marginTop: "15px" }}
                >
                  {foodorderDeatils.map((ele, index) => (
                    <Grid>
                      <Box className="flexGrid totalOrderBg">
                        <Typography className="">
                        {t("Order_No")}:{" "}
                          <span className="colorBlue">
                            {" "}
                            {ele.food_order_id}
                          </span>
                        </Typography>
                       
                        <Typography className="">
                        {t("Status")}:{" "}
                          <span
                            className={`fontBold ${
                              parseInt(ele.current_food_status) === 4
                                ? "delivered"
                                : parseInt(ele.current_food_status) === 5
                                ? "cancel"
                                : "orangeText"
                            }`}
                          >
                            {ele.food_order_status}
                          </span>
                        </Typography>
                      </Box>
                      <Box>
                        <Typography className="orderText">
                        {t("Vendor_Name")}:{" "}
                          <span className="colorBlue">{ele.vendor_name}</span>
                        </Typography>
                      </Box>
                      <Box>
                        <Stepper
                          activeStep={parseInt(ele.current_food_status) + 1}
                          connector={<QontoConnector />}
                          alternativeLabel
                        >
                          {/* {setactiveStep(parseInt(ele.current_food_status)+1)} */}

                          <Step
                            active={
                              parseInt(ele.current_food_status) === 0
                                ? true
                                : false
                            }
                          >
                            <StepLabel
                              icon={
                                parseInt(ele.current_food_status) === 5 ? (
                                  <CircleSharpIcon sx={{ color: "#1F87BA" }} />
                                ) : parseInt(ele.current_food_status) === 0 ? (
                                  <RadioButtonCheckedOutlinedIcon
                                    sx={{ color: "#1F87BA" }}
                                  />
                                ) : (
                                  ""
                                )
                              }
                            >
                              {t("Pending")}{" "}
                            </StepLabel>
                          </Step>
                          <Step
                            active={
                              parseInt(ele.current_food_status) === 1
                                ? true
                                : false
                            }
                          >
                            <StepLabel
                              icon={
                                parseInt(ele.current_food_status) === 5 ? (
                                  <CircleSharpIcon sx={{ color: "#1F87BA" }} />
                                ) : parseInt(ele.current_food_status) === 1 ? (
                                  <RadioButtonCheckedOutlinedIcon
                                    sx={{ color: "#1F87BA" }}
                                  />
                                ) : (
                                  ""
                                )
                              }
                            >
                              {t("Accepted")}{" "}
                            </StepLabel>
                          </Step>
                          <Step
                            active={
                              parseInt(ele.current_food_status) === 2
                                ? true
                                : false
                            }
                          >
                            <StepLabel
                              icon={
                                parseInt(ele.current_food_status) === 5 ? (
                                  <CircleSharpIcon sx={{ color: "#1F87BA" }} />
                                ) : parseInt(ele.current_food_status) === 2 ? (
                                  <RadioButtonCheckedOutlinedIcon
                                    sx={{ color: "#1F87BA" }}
                                  />
                                ) : (
                                  ""
                                )
                              }
                            >
                              {t("Preparing")}{" "}
                            </StepLabel>
                          </Step>
                          <Step
                            active={
                              parseInt(ele.current_food_status) === 3
                                ? true
                                : false
                            }
                          >
                            <StepLabel
                              icon={
                                parseInt(ele.current_food_status) === 5 ? (
                                  <CircleSharpIcon sx={{ color: "#1F87BA" }} />
                                ) : parseInt(ele.current_food_status) === 3 ? (
                                  <RadioButtonCheckedOutlinedIcon
                                    sx={{ color: "#1F87BA" }}
                                  />
                                ) : (
                                  ""
                                )
                              }
                            >
                              {" "}
                              {t("Prepared")} {" "}
                            </StepLabel>
                          </Step>
                          <Step
                            active={
                              parseInt(ele.current_food_status) === 5
                                ? true
                                : false
                            }
                          >
                            <StepLabel
                              className={
                                parseInt(ele.current_food_status) === 5
                                  ? "cancel"
                                  : parseInt(ele.current_food_status) === 4
                                  ? "delivered"
                                  : ""
                              }
                              icon={
                                parseInt(ele.current_food_status) === 5 ? (
                                  <CircleSharpIcon sx={{ color: "#1F87BA" }} />
                                ) : (
                                  ""
                                )
                              }
                            >
                              {parseInt(ele.current_food_status) === 5
                                ? "Cancelled"
                                : "Delivered"}{" "}
                            </StepLabel>
                          </Step>
                        </Stepper>
                      </Box>
                      <Grid container>
                        <Grid item lg={12} sx={{ width: "100%" }}>
                          <div
                            className="styling-example"
                            style={{ paddingBottom: "0px" }}
                          >
                            <Carousel
                              breakPoints={breakPoints}
                              // renderArrow={myArrow}
                              itemPosition={"START"}
                              // itemPosition={consts.START}
                              showEmptySlots
                              // showArrows={show}
                              disableArrowsOnEnd={true}
                            >
                              {ele.items.map((res) => {
                                return (
                                  <>
                                    <Item style={{ boxShadow: "none" }}>
                                      <Grid className="flexGrid cardDiv">
                                        <item className="foodImg">
                                          {/* <img src="assets/images/superMart.png" alt="cancelled" /> */}
                                          <img
                                            src={
                                              res.item_image
                                                ? res.item_image
                                                : "assets/images/superMart.png"
                                            }
                                            alt="cancelled"
                                          />
                                        </item>
                                        <item className="bookingDetails">
                                          <Typography
                                            className="fontSize18 fontBold padding7 word-ellipsis"
                                            sx={{ color: "#2C2C2C" }}
                                          >
                                            {/* Veg salad platter */}
                                            {res.item_name ? res.item_name : ""}
                                          </Typography>
                                          <Typography className="orderText padding7">
                                            Qty:{" "}
                                            <span className="colorBlue">
                                              {" "}
                                              {res.item_quantity}
                                            </span>
                                          </Typography>
                                          <Typography className="orderText padding7">
                                          {t("Price")}:
                                            <span className="colorBlue">
                                              {/* 100.00 */}
                                              {currencyicon}{" "}
                                              {res.total_item_price}{" "}
                                            </span>
                                          </Typography>
                                        </item>
                                      </Grid>
                                    </Item>
                                    {/* <Item>
                          <Grid className="flexGrid cardDiv">
                            <item className="foodImg">
                              <img src="assets/images/superMart.png" alt="cancelled" />
                            </item>
                            <item className="bookingDetails">
                            <Typography className="fontSize18 fontBold padding7" sx={{color: '#2C2C2C'}}>
                                Veg salad platter
                              </Typography>
                              <Typography className="orderText padding7">
                                Qty: <span className="colorBlue"> 2 </span>
                              </Typography>
                              <Typography className="orderText padding7">
                                Price:
                                <span className="colorBlue">
                                  100.00
                                </span>
                              </Typography>
                            </item>
                          </Grid>
                        </Item> */}
                                    {/* <Item>
                          <Grid className="flexGrid cardDiv">
                            <item className="foodImg">
                              <img src="assets/images/superMart.png" alt="cancelled" />
                            </item>
                            <item className="bookingDetails">
                            <Typography className="fontSize18 fontBold padding7" sx={{color: '#2C2C2C'}}>
                                Veg salad platter
                              </Typography>
                              <Typography className="orderText padding7">
                                Qty: <span className="colorBlue"> 2 </span>
                              </Typography>
                              <Typography className="orderText padding7">
                                Price:
                                <span className="colorBlue">
                                  100.00
                                </span>
                              </Typography>
                            </item>
                          </Grid>
                        </Item> */}
                                  </>
                                );
                              })}
                            </Carousel>
                          </div>
                        </Grid>
                      </Grid>
                      <Box>
                        {ele.current_food_status !== "4" &&
                        ele.current_food_status !== "5" ? (
                          <Typography
                            sx={{
                              textAlign: "left",
                              position: "relative",
                              // marginTop: "-10px",
                              marginBottom: "-25px",
                              marginLeft: "20px",
                            }}
                          >
                            {t("Delivery_OTP")}:{" "}
                            <span className="colorDarkBlue totalTicketTitle">
                              {ele.delivery_otp}{" "}
                            </span>
                          </Typography>
                        ) : (
                          <></>
                        )}
                        <Typography
                          sx={{
                            textAlign: "right",
                            position: "relative",
                            marginTop: "-10px",
                            marginBottom: "15px",
                            marginRight: "15px",
                          }}
                        >
                          {t("Total_Price")}:{" "}
                          <span className="colorDarkBlue totalTicketTitle">
                            {currencyicon} {ele.food_total_amount}{" "}
                          </span>
                        </Typography>
                      </Box>
                    </Grid>
                  ))}{" "}
                </Card>{" "}
              </>
            ) : (
              ""
            )}
          </Grid>
          {/* ------------------------------  Food section ------------------- */}

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Card className="bookingCard">
              <Box sx={{ padding: "18px" }}>
                <Box
                  className="bookingGrandTotal"
                  sx={{ paddingLeft: "10px", paddingRight: "10px" }}
                >
                  <item>
                    <Typography className="bookingGrandTotalText">
                    {t("Grand_Total_Price")} :
                    </Typography>
                  </item>
                  <item>
                    <Typography className="bookingGrandTotalAmount">
                      {currencyicon}{" "}
                      {parseFloat(orderDeatils.final_amount).toFixed(2)}
                    </Typography>
                  </item>
                  {!_.isEmpty(upgradeTicket) && <item>
                  <UpgradeStyle onClick={upgrateTicket}>Upgrade Ticket</UpgradeStyle>
                  </item>}
                  
                </Box>
                <Box
                  className="bookingGrandTotal"
                  sx={{ paddingLeft: "10px", paddingRight: "10px" }}
                >
                  {orderDeatils.refund_status === "1" ? (
                    <>
                      <item>
                        <Typography
                          sx={{
                            color: "#048217",
                            fontWeight: 700,
                            fontSize: "20px",
                          }}
                        >
                          {t("Refund_Amount")} :
                        </Typography>
                      </item>
                      <item>
                        <Typography
                          sx={{
                            color: "#048217",
                            fontWeight: 700,
                            fontSize: "20px",
                          }}
                        >
                          {" "}
                          {/* orderDeatils.is_ticket_upgraded ? orderDeatils.refund_amount : */}
                          -{currencyicon} {orderDeatils.refund_amount}
                        </Typography>
                      </item>
                    </>
                  ) : (
                    ""
                  )}
                </Box>
                <div className="hLine"></div>
              </Box>
              <Box
                sx={{
                  textAlign: "center",
                  display: "block",
                  width: "100%",
                  padding: "20px 0px 0px",
                }}
              >
                <img
                  src={
                    orderDeatils.gift_transfer_status === "1" &&
                    orderDeatils.giftDetails.transfer_type === "1"
                      ? "assets/images/transfer-gift-qr.png"
                      : orderDeatils.gift_transfer_type === "1"
                      ? orderDeatils.barcode
                      : orderDeatils.barcode
                  }
                  alt="qr-code"
                />
              </Box>

              {orderDeatils.giftDetails &&
              orderDeatils.giftDetails.gift_transfer_status === "1" ? (
                <Box className="gift-status-box" sx={{ mt: 2 }}>
                  {orderDeatils.giftDetails.gift_transfer_status === "1" &&
                  orderDeatils.giftDetails.transfer_type === "1" ? (
                    <span className="gift-status-box-span">Gift Status:</span>
                  ) : (
                    <></>
                  )}
                  {/* {orderDeatils.giftDetails.gift_transfer_status}<span className={orderDeatils.giftDetails.gift_transfer_status === "0" ?"gift-status-box-in-progress" : "gift-status-box-in-progress sent-success"}>{orderDeatils.giftDetails.transfer_type_text}</span> */}
                  {orderDeatils.giftDetails.gift_transfer_status === "1" &&
                  orderDeatils.giftDetails.transfer_type === "1" ? (
                    <span
                      style={{
                        background: "#048217",
                        borderRadius: "83.2857px",
                        fontWeight: 600,
                        fontSize: "12.5714px",
                        lineHeight: "15px",
                        color: "#FFFFFF",
                        padding: "3px 10px",
                        marginLeft: "10px",
                      }}
                    >
                      {t("Successful")}
                    </span>
                  ) : (
                    <span
                      style={{
                        background: "background: #FCA82A",
                        borderRadius: "83.2857px",
                        fontWeight: 600,
                        fontSize: "12.5714px",
                        lineHeight: "15px",
                        color: "#FFFFFF",
                        padding: "3px 10px",
                        marginLeft: "10px",
                      }}
                    >
                      {t("In_Process")}
                    </span>
                  )}
                </Box>
              ) : (
                <></>
              )}

              {orderDeatils.giftDetails?.gift_transfer_status === "0" ? (
                <Box className="gift-status-box" sx={{ mt: 2 }}>
                  <span className="gift-status-box-span">Transfer Status:</span>
                  <span
                    style={{
                      background: "#FCA82A",
                      borderRadius: "83.2857px",
                      fontWeight: 600,
                      fontSize: "12.5714px",
                      lineHeight: "15px",
                      color: "#FFFFFF",
                      padding: "3px 10px",
                      marginLeft: "10px",
                    }}
                  >
                    {t("In_Process")}
                  </span>
                </Box>
              ) : (
                <></>
              )}

              <Box className="orderNoBox">
                <Typography
                  className="bookingDetailsOrderNo"
                  sx={{ fontSize: "22px", lineHeight: "24px" }}
                >
                  <span className="colorBlue">{t("Order_No")}:</span>{" "}
                  {orderDeatils?.order_number}
                </Typography>
                {(orderDeatils?.ticket_details && orderDeatils?.ticket_details[0]?.free_ticket_checkout)?(
                    <Typography className="bookingDetailsOrderNo" sx={{ fontSize: "22px", lineHeight: "24px" }} >
                             <span className="colorBlue">{t("CheckIn_Time")}:</span>
                           {" "}  {FreedateFormated(orderDeatils.ticket_details[0]?.free_ticket_checkin)}
                    </Typography>):('')}



                {orderDeatils.floor_plan_details ? (
                  <Typography
                    className="bookingDetailsOrderNo"
                    sx={{
                      fontSize: "22px",
                      lineHeight: "38px",
                    }}
                  >
                    <span className="colorBlue">{t("Entry_Zone")}:</span>{" "}
                    {orderDeatils?.floor_plan_details.enterence_gate_zone}
                  </Typography>
                ) : (
                  ""
                )}

                {orderDeatils.seat_io === "0" &&
                orderDeatils.zone_name !== "" ? (
                  <Typography
                    className="bookingDetailsOrderNo"
                    sx={{
                      fontSize: "22px",
                      lineHeight: "38px",
                    }}
                  >
                    <span className="colorBlue">{t("Entry_Zone")}:</span>{" "}
                    {orderDeatils.zone_name ? orderDeatils.zone_name : ""}
                  </Typography>
                ) : (
                  ""
                )}

                {orderDeatils.floor_plan_details ? (
                  <Typography
                    className="bookingDetailsOrderNo"
                    sx={{
                      fontSize: "22px",
                      lineHeight: "38px",
                    }}
                  >
                    <span className="colorBlue">{t("Row")} :</span>{" "}
                    {orderDeatils.floor_plan_details.rows}
                  </Typography>
                ) : (
                  ""
                )}
              </Box>

              <Box className="order-detail-company-text-dispaly-table">
                <Box className="order-detail-company-text-right">
                  {orderDeatils.group_company_name ? (
                    <Typography className="order-detail-company-text">
                      {t("Company_Name")}:{" "}
                    </Typography>
                  ) : (
                    <></>
                  )}
                  {orderDeatils.group_company_vat_number ? (
                    <Typography className="order-detail-company-text">
                      {t("Company_VAT_Number")}:{" "}
                    </Typography>
                  ) : (
                    <></>
                  )}
                  {orderDeatils.group_company_address ? (
                    <Typography className="order-detail-company-text">
                      {t("Company_Address")} :{" "}
                    </Typography>
                  ) : (
                    <></>
                  )}
                </Box>

                <Box className="order-detail-company-number-left">
                  {orderDeatils.group_company_name ? (
                    <Typography className="order-detail-company-number">
                      {" "}
                      {orderDeatils.group_company_name}
                    </Typography>
                  ) : (
                    <></>
                  )}
                  {orderDeatils.group_company_vat_number ? (
                    <Typography className="order-detail-company-number">
                      {" "}
                      {orderDeatils.group_company_vat_number}
                    </Typography>
                  ) : (
                    <></>
                  )}
                  {orderDeatils.group_company_address ? (
                    <Typography className="order-detail-company-number">
                      {" "}
                      {orderDeatils.group_company_address}
                    </Typography>
                  ) : (
                    <></>
                  )}
                </Box>
              </Box>
              {/* <Box sx={{ textAlign: 'center', display: 'flex', justifyContent: 'center', marginTop: '8px' }}>
                <Typography className="order-detail-company-text">Company VAT Number: </Typography>
                <Typography className="order-detail-company-number">8789568978</Typography>
              </Box>
              <Box sx={{ textAlign: 'center', display: 'flex', justifyContent: 'center', marginTop: '8px' }}>
                <Typography className="order-detail-company-text">Company Address : </Typography>
                <Typography className="order-detail-company-number">B28,29 Sec 58 Noida, UP, 201301</Typography>
              </Box> */}

              {/* Gift/Transfer Ticket */}

              {orderDeatils.gift_transfer_flag ? (
                <Box
                  sx={{
                    textAlign: "center",
                    // padding: "50px 10px 60px 10px",
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    type="button"
                    className="GiftBtn"
                    onClick={handleOpengift}
                  >
                    {t("Gift_Transfer_Ticket")}
                  </Button>
                </Box>
              ) : (
                <></>
              )}

              {/*End Gift/Transfer Ticket */}

              {orderDeatils.status === "1" ? (
                <Box
                  sx={{
                    textAlign: "center",
                    padding: "10px 10px 60px 10px",
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                  }}
                >
                  {orderDeatils.event_cancel === "1" ? (
                    ""
                  ) : orderDeatils.cancel_button_show === "1" ? (
                    ""
                  ) : orderDeatils.event_cancel === "0" &&
                    orderDeatils.event_postpond === "0" &&
                    orderDeatils.payment_through_eft !== "1" ? (
                    <Button
                      type="button"
                      className="cancelBtn btnAuto"
                      onClick={handleClickOpen}
                    >
                      {t("Cancel_Order")}
                    </Button>
                  ) : orderDeatils.event_cancel === "0" &&
                    orderDeatils.event_postpond === "1" &&
                    orderDeatils.user_action_after_postpond === "0" ? (
                    <Box>
                      <Button
                        type="button"
                        className="CreateBtn btnAuto"
                        onClick={eventpostponrequestconfirm}
                      >
                        {t("Confirm")}
                      </Button>
                      <Button
                        type="button"
                        className="cancelBtn btnAuto"
                        onClick={eventpostponrequestrefund}
                      >
                        {t("Refund")}
                      </Button>
                    </Box>
                  ) : orderDeatils.event_cancel === "0" &&
                    orderDeatils.event_postpond === "1" &&
                    orderDeatils.user_action_after_postpond === "1" &&
                    orderDeatils.payment_through_eft !== "1" ? (
                    <Button
                      type="button"
                      className="cancelBtn btnAuto"
                      onClick={handleClickOpen}
                    >
                      {t("Cancel_Order")}
                    </Button>
                  ) : orderDeatils.event_cancel === "0" &&
                    orderDeatils.event_postpond === "1" &&
                    orderDeatils.user_action_after_postpond === "2" ? (
                    ""
                  ) : (
                    ""
                  )}
                </Box>
              ) : (
                <></>
              )}

              {orderDeatils.gift_transfer_status === "1" &&
              orderDeatils.gift_transfer_type === "2" ? (
                <Box className="main-ticket-box-top-bg">
                  <Box className="gift-this-ticket-box">
                    <img src="assets/images/giftimg.png" alt="gift-qr" />
                    <Typography className="gift-this-ticket-box-text">
                      {orderDeatils.giftDetails.gift_transfer_msg}
                    </Typography>
                  </Box>
                  <Typography className="gift-this-ticket-box-text-jan">
                    {orderDeatils.giftDetails.name}
                  </Typography>
                  <Typography className="gift-this-ticket-box-text-jan">
                    +{orderDeatils.giftDetails.phone_code}{" "}
                    {orderDeatils.giftDetails.phone_number}{" "}
                  </Typography>
                  <Typography className="gift-this-ticket-box-text-jan">
                    {orderDeatils.giftDetails.email}{" "}
                  </Typography>
                </Box>
              ) : orderDeatils.event_postpond !== "1" &&
                orderDeatils.event_cancel === "0" &&
                orderDeatils.complementary_order === "1" ? (
                <Box
                  className="main-ticket-box-top-bg"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: 700,
                    color: "#060251",
                    fontSize: "20px",
                  }}
                >
                  {orderDeatils.comp_ticket_type === "1"
                    ? "* This Ticket is Complementary Ticket *"
                    : `* valid for ${
                        orderDeatils.comp_ticket_type === "2"
                          ? "artist"
                          : "crew"
                      } member only *`}
                </Box>
              ) : (
                <></>
              )}

              {(orderDeatils.gift_transfer_status === "1" &&
                orderDeatils.gift_transfer_type === "1") ||
              orderDeatils.giftDetails?.gift_transfer_status === "0" ? (
                <Box className="main-ticket-box-top-bg">
                  <Box className="gift-this-ticket-box">
                    <img src="assets/images/transferimg.png" alt="" />
                    <Typography className="gift-this-ticket-box-text">
                      {orderDeatils.giftDetails.gift_transfer_msg}
                    </Typography>
                  </Box>
                  <Typography className="gift-this-ticket-box-text-jan">
                    {orderDeatils.giftDetails.name}
                  </Typography>
                  <Typography className="gift-this-ticket-box-text-jan">
                    +{orderDeatils.giftDetails.phone_code}{" "}
                    {orderDeatils.giftDetails.phone_number}{" "}
                  </Typography>
                  <Typography className="gift-this-ticket-box-text-jan">
                    {orderDeatils.giftDetails.email}{" "}
                  </Typography>
                </Box>
              ) : orderDeatils.gift_transfer_status === "0" &&
                orderDeatils.gift_transfer_type === "1" ? (
                <Box className="main-ticket-box-top-bg">
                  <Box className="gift-this-ticket-box">
                    <img src="assets/images/transferimg.png" alt="" />
                    <Typography className="gift-this-ticket-box-text">
                      {orderDeatils.giftDetails.gift_transfer_msg}
                    </Typography>
                  </Box>
                  <Typography className="gift-this-ticket-box-text-jan">
                    {orderDeatils.giftDetails.name}
                  </Typography>
                  <Typography className="gift-this-ticket-box-text-jan">
                    +{orderDeatils.giftDetails.phone_code}{" "}
                    {orderDeatils.giftDetails.phone_number}{" "}
                  </Typography>
                  <Typography className="gift-this-ticket-box-text-jan">
                    {orderDeatils.giftDetails.email}{" "}
                  </Typography>
                </Box>
              ) : (
                <></>
              )}
            </Card>
          </Grid>

          <div>
            <Dialog open={open} onClose={handleClose}>
              <DialogTitle
                sx={{
                  textAlign: "center",
                  fontSize: "24px",
                  fontWeight: "600",
                }}
              >
                {t("Cancel_Order")}
              </DialogTitle>
              <DialogContent>
                <DialogContentText sx={{ paddingBottom: "10px" }}>
                {t("Please_write_the_reason_of_cancellation")}
                </DialogContentText>
                <TextareaAutosize
                  cols={10}
                  onChange={(e) => setCancelReason(e.target.value)}
                  aria-label="empty textarea"
                  value={cancelReason}
                  placeholder="Write here..."
                  className="cancelTextarea"
                />
              </DialogContent>
              <DialogActions
                sx={{
                  justifyContent: "center",
                  display: "flex",
                  padding: "8px 24px 20px",
                }}
              >
                <Button onClick={handleClose} className=" cancelBtn w-50">
                {t("Cancel")}
                </Button>
                <Button
                  onClick={handlefcancel}
                  className="CreateBtn w-50"
                  // disabled={cancelReason.length === 0 ? true : false}
                >
                  {" "}
                  {t("Accept")}
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </Grid>
      )}

      {/* Confirmation modal */}
      <Modal
        open={open1}
        onClose={handleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="ModalClass"
      >
        <Box sx={style} className="ModalClassBox">
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{ textAlign: "right", padding: "16px", mt: "2" }}
              className="ModalTopGrid"
            >
              <CloseIcon className="closeIconOffer" onClick={handleClose1} />
              <Typography
                id="modal-modal-description"
                className="ModalPhomolong"
                sx={{ textAlign: "center" }}
              >
                {t("Alert")}{" "}
              </Typography>
              <Typography
                sx={{
                  margin: "15px 0px",
                  textAlign: "center",
                }}
              >
                {t("Do_you_want_to_cancel_seleted_order")}
              </Typography>

              <Typography
                sx={{
                  marginBottom: "10px",
                }}
              >
                <Box
                  sx={{ display: "flex", alignItems: "baseline" }}
                  justifyContent="center"
                >
                  <Button class="btn bookTicketBtn" onClick={handleClose1}>
                  {t("Cancel")}
                  </Button>
                  <Button
                    class="btn viewBtn"
                    onClick={(e) => OrderCancelEvent(e)}
                  >
                    {t("Continue")}
                  </Button>
                </Box>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      {/*  */}

      <Modal
        open={open2}
        onClose={handleClose2}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="ModalClass"
      >
        <Box sx={style} className="ModalClassBox">
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{ textAlign: "right", padding: "0px 16px" }}
              className="ModalTopGrid"
            >
              <CloseIcon className="closeIconOffer" onClick={handleClose2} />
              <Typography
                id="modal-modal-description"
                className="ModalPhomolong"
                sx={{ textAlign: "center" }}
              >
                 {t("Share_with_Social_Media")}
              </Typography>
              {localStorage.getItem("autoCountry") ? (
                <SocialSharePopuporders description={orderDeatils.share_data} />
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <Dialog
        // fullScreen={fullScreen}
        open={viewSeats}
        onClose={handleCloseSeatViews}
        aria-labelledby="responsive-dialog-title"
        // sx={{width:'80vw'}}
      >
        <Box
          sx={{
            background: "#0CA8F6",
            display: "flex",
            justifyContent: "space-between",
            color: "white",
            height: "40px",
            p: "1rem",
          }}
        >
          <Typography
            variant="h6"
            sx={{ textTransform: "capitalize", fontWeight: "700" }}
          >
            {t("Booked_Seats")}
          </Typography>
          <CloseIcon
            onClick={handleCloseSeatViews}
            sx={{ cursor: "pointer" }}
          />
        </Box>
        {/* <Box>}</Box> */}
        {seatNums ? (
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
            sx={{ padding: "1rem" }}
          >
            {seatNums.map((selLabels, index) => (
              <Grid item xs={2} sm={4} md={4} key={index}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "1px dotted #0CA8F6",
                    p: "10px",
                    whiteSpace: "nowrap",
                  }}
                >
                  {selLabels}
                </Box>
              </Grid>
            ))}
          </Grid>
        ) : (
          <></>
        )}
      </Dialog>
      {/*  */}
      {/* gift and transfer model */}
      <Modal
        open={opengift}
        onClose={handleClosegift}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        // className="jhdvjkgdf"
      >
        <Box sx={style1} className="gift-transfer-modal-Box">
          <Box
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="gift-transfer-model-hedding-cross"
            sx={{ cursor: "pointer" }}
          >
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              className="gift-tranfer-H-modal"
            >
              {t("Gift_Transfer_Ticket")}
            </Typography>
            <CloseIcon onClick={handleClosegift} />
          </Box>

          <div className="hLine"></div>
          <form name="contact_form" onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={12} className="">
                <FormControl className="gift-ticket-formCtrl">
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    className="gift-ticket-radioGrp"
                    // defaultValue="giftticket"
                    value={giftvalue}
                    onChange={(e) => {
                      setGiftvalue(e.target.value);
                    }}
                  >
                    <FormControlLabel
                      value="giftticket"
                      control={<Radio />}
                      label="Gift Ticket"
                      className="radio-btn-Gift-Ticket"
                    />
                    <FormControlLabel
                      value="transferticket"
                      control={<Radio />}
                      label="Transfer Ticket"
                      className="radio-btn-Transfer-Ticket"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                className="modal-gift-transfer-grid"
              >
                <Typography className="modal-commonEventTypo">
                {t("Full_Name")}<span className="ValidationRed">*</span>
                </Typography>
                <Box
                  sx={{ display: "flex", alignItems: "flex-start" }}
                  className="boxBorder_Bottom"
                >
                  <img
                    src="assets/images/userIcon.png"
                    alt="userIcon"
                    className="userIconImg"
                  />
                  <input
                    placeholder="First Name"
                    className="inputFocusVisible"
                    type="text"
                    name="fullname"
                    {...register("fullname", {
                      required: "First Name is required ",
                      minLength: {
                        value: 4,
                        message: "User must be atleast 4 character long",
                      },
                      maxLength: 30,
                    })}
                  />
                </Box>
                <p className="red">{errors.fullname?.message}</p>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                className="modal-gift-transfer-grid"
              >
                <Typography className="modal-commonEventTypo">
                {t("Email_Id")} <span className="ValidationRed">*</span>
                </Typography>
                <Box
                  sx={{ display: "flex", alignItems: "flex-start" }}
                  className="boxBorder_Bottom"
                >
                  <img
                    src="assets/images/EmailIcon.png"
                    alt="userIcon"
                    className="userIconImg"
                  />
                  <input
                    placeholder={t("Email_Address")}
                    type="email"
                    name="email_address"
                    className="inputFocusVisible resp-inputFocusVisible"
                    style={{ border: "none" }}
                    {...register("email_address", {
                      required: `${t("Email_Address_is_required")}`,
                      pattern: {
                        value:
                          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: "Please enter a valid email",
                      },
                    })}
                  />
                </Box>
                <p className="red">{errors.email_address?.message}</p>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                className="modal-gift-transfer-grid"
              >
                <Typography
                  className="modal-commonEventTypo"
                  style={{ paddingBottom: "0px" }}
                >
                 {t("Phone_Number")} <span className="ValidationRed">*</span>
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                sm={4}
                md={4}
                className="modal-gift-transfer-grid"
              >
                <Autocomplete
                  id="country-select-demo"
                  disableClearable
                  sx={{ width: 135 }}
                  options={countryList}
                  value={value}
                  className="custom-CCSearch gift-use-custom-CCSearch"
                  onChange={(event, value) => {
                    setValue(value ? value.emoji + " +" + value.phone : "");
                  }}
                  ListboxProps={{
                    style: {
                      maxHeight: "190px",
                      width: "auto",
                    },
                  }}
                  autoHighlight
                  loading={true}
                  getOptionLabel={(option) =>
                    option.phone
                      ? "+" + option.phone + option.country_name
                      : value
                  }
                  renderInput={(params) => (
                    <TextField
                      className="country-code-gift-transfer"
                      {...params}
                      // label="Choose a country"
                      value={value}
                      sx={{ background: "white" }}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={8}
                sm={8}
                md={8}
                className="modal-gift-transfer-grid"
              >
                <Box
                  sx={{ display: "flex", alignItems: "flex-start" }}
                  className="boxBorder_Bottom"
                >
                  <img
                    src="assets/images/cellPhone.png"
                    alt="userIcon"
                    className="userIconImg"
                  />
                  <input
                    placeholder={t("Mobile_No")}
                    name="phone_number"
                    className="inputFocusVisible"
                    type="number"
                    style={{ border: "none" }}
                    maxLength="15"
                    {...register("phone_number", {
                      required: "mobile number is required",
                      minLength: {
                        value: 8,
                        message: "number must be atleast 8 digit long",
                      },
                      maxLength: {
                        value: 15,
                        message: "number must be 15 digit number",
                      },
                    })}
                  />
                </Box>
                <p className="red">{errors.phone_number?.message}</p>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                className=""
                sx={{ textAlign: "center" }}
              >
                <Button className="gift-transfer-btwn" type="submit">
                {t("Submit")}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>

      {/* show multiday dates and times */}
      <Modal
        keepMounted
        open={openDate}
        onClose={handleCloseDate}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        className="seat-Modal-class-Date"
      >
        <ShowLocationDates
          selectedLocationData={selectedLocationData}
          closePopup={closePopup}
        />
      </Modal>
      {/* toast */}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* Same as */}
    </Box>
  );
};

export default BookingDetails;
