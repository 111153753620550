import React, { useEffect } from "react";
import Navbar from "../../Components/Navbar";
import MenuBar from "../../Components/MenuBar";
import CommonBanner from "../../Components/CommonBanner";
import ContactInfo from "../../Components/ContactInfo";
import Map from "../../Components/Map";
import CustomerServices from "../../Components/CustomerServices";
import Footer from "../../Components/Footer";
import BreadCrumbs from "../../Components/BreadCrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link } from "react-router-dom";
import { Breadcrumbs, Typography } from "@mui/material";
import { Box } from "@mui/system";
import "./style.css";
import {useTranslation} from 'react-i18next'
const ContactUs = () => {
  const {t} = useTranslation("common")
  const breadcrumbs = [
    <Link to={"/"} key="1" className="link-decoration">
      {t("Home")}
    </Link>,

    <Typography
      key="3"
      sx={{
        fontFamily: "Lato",
        color: "#0CA8F6",
        fontWeight: 500,
        fontSize: "16px",
      }}
    >
      {t("Contact_Us")}
    </Typography>,
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar />
      {/* <MenuBar /> */}
      <Box className="mainContainer">
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          sx={{ mb: 3, mt: 2 }}
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Box>
      <CommonBanner title={t("Contact_us")} />
      <ContactInfo />
      <Map />
      <CustomerServices />
      <Footer />
    </>
  );
};

export default ContactUs;
