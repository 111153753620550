import React, { useState, useEffect } from "react";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import http from "../../Services/http";
import axios from "axios";
import {useTranslation} from "react-i18next";
import "./style.css";

import { useNavigate, useLocation } from "react-router-dom";

import GoogleLogin from "react-google-login";
import { NotificationManager } from "react-notifications";

import PropTypes from "prop-types";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import checkValidationFormAllControls from "../../shared/validation";

import { SpinnerCircular } from "spinners-react";
import OtpInput from "react-otp-input";
import CCSearch from "../../shared/CCSearch";

import Switch from "@mui/material/Switch";

const label = { inputProps: { "aria-label": "Switch demo" } };

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 540,
  paddingBottom: "20px",
  width: "95%",
  boxShadow: 24,
  background: "url(assets/images/form-bg.png) #fff",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  borderRadius: "16px",
  opacity: 0.3,
};

export default function ModalPopup(props) {
  const {t} = useTranslation("common");
  const [otp, setOtp] = useState("");
  const [fullname, setfullname] = useState("");
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const [emaill, setEmaill] = useState("");
  const [countrycodelist, setCountrycodelist] = useState([]);

  const [country_codenew, setcountry_codenew] = useState("");
  const [countrycodeLogin, setCountrycodeLogin] = useState();
  const [useridget, setUseridget] = useState("");
  const [emailuserid, setEmailuserid] = useState("");
  const [google, setgoogle] = useState("");
  const [countryselector, setCountryselector] = useState("");
  const [countrySc, setCountryShortCode] = useState("");
  const [errorDisplay, seterrorDisplay] = useState("");
  const [counter, setCounter] = useState(30);
  const [activelink, setActivelinlk] = useState(true);
  const [counterStart, setcounterStart] = useState(false);
  const [lengthNumError, setlengthNumError] = useState(false);

  const [phoneregister, setphoneRegister] = useState("");
  const [isloading, setisloading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isGooleLoging, setGooleLoging] = useState(false);
  const [checkWalletactivation, setcheckWalletactivation] = useState("");

  const [gender, setGeneder] = useState("male");
  const navigate = useNavigate();
  const location = useLocation();
  const DynamicVar = location.pathname;

  const countrycodeLoginnew = countrycodeLogin ? countrycodeLogin : "";
  const [wantOtpOnWhatsApp, setWantOtpOnWhatsApp] = useState(
    localStorage.getItem("whatsAppCheck") ? 2 : 0
  );

  // Resend otp
  const resendOtp = () => {
    let Logindata = {
      email_phone:
        emaill != "" && phone == ""
          ? emaill
          : phone != "" && emaill == ""
          ? phone
          : phone != "" && emaill != ""
          ? emaill
          : "",
      country_code: emaill.includes("@") ? "" : selCountryCode,
      login_type: emaill.includes("@") ? "email" : "phone",
      get_otp_on:
        emaill.includes("@") && wantOtpOnWhatsApp !== 2
          ? "1"
          : wantOtpOnWhatsApp === 2
          ? wantOtpOnWhatsApp
          : "1",
    };

    http("user/loginOtpRequest/", "POST", Logindata)
      .then((res) => res.data)
      .then((res) => {
        if (res.status === 1) {
          NotificationManager.success(res.msg, "", 1500);
          setActivelinlk(true);
          setCounter(30);
          setcounterStart(true);
          setOpen(false) ? setOpen(false) : setOpen3(false);
          otpmodal();
        } else {
          NotificationManager.error(res.msg, "", 1500);
        }
      });
  };

  useEffect(() => {
    // if (counter > 0) setTimeout(() => setCounter(counter - 1), 1000);
    if (counter > 0) {
      const interval = setInterval(() => {
        setCounter(counter - 1);
      }, 1000);
      return () => clearInterval(interval);
    }
    if (counter === 0) setActivelinlk(false);
  }, [counter]);

  // whatsapp otp
  const whatsappCheck = (e) => {
    if (e.target.checked) {
      setWantOtpOnWhatsApp(2);
      localStorage.setItem("whatsAppCheck", true);
    } else {
      setWantOtpOnWhatsApp(1);
      localStorage.removeItem("whatsAppCheck");
    }
  };

  //get selected country function
  const [selCountryCode, setSelCountryCode] = useState(0);
  const [selCountrySortName, setSelCountrySortName] = useState("");
  const getSelCountry = (data, ShortCode) => {
    if (
      data ===
      "+" + JSON.parse(localStorage?.getItem("autoCountry"))?.phonecode
    ) {
      setSelCountryCode(data);
    } else {
      setSelCountryCode(data ? data.slice(4) : 0);
    }
    setSelCountrySortName(ShortCode);
  };

  // Registeration Api
  const handleSubmit = (e) => {
    e.preventDefault();
    var objValidation = {};
    objValidation = checkValidationFormAllControls(
      document.forms[0].elements,
      false,
      []
    );

    if (objValidation.valid !== false) {
      seterrorDisplay(objValidation);
      return;
    } else {
      seterrorDisplay(objValidation);
    }

    if (phoneregister.length >= 8) {
      objValidation.valid = true;
    } else {
      objValidation.valid = false;
      setlengthNumError(true);
      e.preventDefault();
      return;
    }

    setLoading(true);
    e.preventDefault();
    const Registerdata = {
      fullname,
      email: emaill,
      phone:
        Array.from(phoneregister)[0] == 0
          ? phoneregister.substring(1)
          : phoneregister,
      country_code: parseInt(selCountryCode),
      country_short_code: selCountrySortName,
      get_otp_on: localStorage.getItem("whatsAppCheck") ? 2 : 1,
      gender: gender === "male" ? 1 : gender === "female" ? 2 : 3,
    };
    http("user/registration", "POST", Registerdata).then((res) => {
      setLoading(false);
      setGooleLoging(false);
      const data = res.data ?? [];
      if (data.status === 1) {
        setphone(data.data.phone);
        setUseridget(data.data.userid);
        setEmaill(data.data.email);
        localStorage.setItem("loginUser", JSON.stringify(res.data));
        localStorage.setItem("fullname", fullname);
        localStorage.setItem("email", email);
        localStorage.setItem("phone", phone);
        NotificationManager.success(data.msg, "", 1500);
        setCounter(30);
        setcounterStart(true);
        otpmodal();
      } else {
        setLoading(false);
        NotificationManager.error(data.msg, "", 1500);
      }
    });
  };

  const WalletActivationGoogle = () => {
    var url = "wallet/new_wallet_start";
    http(url, "GET").then((res) => {
      let data = res.data;

      if (data.status === 1) {
        // data.data.wallet_activation_status === 0 ?
        // window.location.reload();
      }
    });
  };

  const WalletActivation = () => {
    var url = "wallet/new_wallet_start";
    http(url, "GET").then((res) => {
      let data = res.data;

      if (data.status === 1) {
        // data.data.wallet_activation_status === 0 ?
        window.location.reload();
      }
    });
  };

  // Otp
  const handleSubmitOtp = (e) => {
    setLoading(true);
    e.preventDefault();

    const otpdata = {
      userid: useridget ? useridget : emailuserid,
      otp: otp,
      device_token: "",
      device_id: "",
      device_type: "web",
    };
    var url = "user/verifyotp";
    if (google === "google") {
      url = "user/otp_verify";
      otpdata.id = useridget;
    }

    http(url, "POST", otpdata)
      .then((res) => {
        setLoading(false);
        const dataAfterLogin = res.data ?? [];

        if (dataAfterLogin.status === 1) {
          // NotificationManager.success(dataAfterLogin.msg);
          parseInt(checkWalletactivation) === 0 ? WalletActivation() : <></>;
          NotificationManager.success("Successfully Login", "", 1500);
          if (dataAfterLogin.token) {
            localStorage.setItem("loginUser", JSON.stringify(res.data.data));
            localStorage.setItem("token", dataAfterLogin.token);
            localStorage.setItem("email", dataAfterLogin.data.email);
            localStorage.setItem("fullname", fullname);
            localStorage.setItem("phone", phone);
          }

          if (parseInt(dataAfterLogin.data.wallet_activation_status) === 0) {
            // debugger
            WalletActivation();
          } else {
            window.location.reload();
          }
          setOpen2(false);
          setOpen4(false);
          navigate(`${DynamicVar}`, { replace: true });
        } else {
          setLoading(false);
          // NotificationManager.error(dataAfterLogin.msg);
          NotificationManager.error(dataAfterLogin.msg, "", 1500);
        }
      })
      .catch((error) => {
        setLoading(false);
        NotificationManager.error(
          error?.res?.data?.message ?? "Something Wrong",
          "",
          1500
        );
      });
  };

  // GoogleLogin Function
  const responseSuccessGoogle = (response) => {
    setLoading(true);
    localStorage.setItem("profilepic", response.profileObj.imageUrl);
    const googleloginData = {
      fullname: response.profileObj.name,
      email: response.profileObj.email,
      uid: response.profileObj.googleId,
      device_token:
        "c1XTU0Q7TxuStTmMbBR3jO:APA91bHAOe7a6VlsSFZCIcb1WdX3odz3ycavKP1ZW2zDV3RqKsDYUw8jIkozxcC60IUe8B_C9WwGjTup_YKqIG3HOnxbMwjH8d4D8cUBVBvYAM8GfwMcKWvCU3SeTeNynZR_IMyJouEs",
      device_type: "web",
      social_media_type: "google",
      device_id: "",
    };
    http("user/social_media_login", "POST", googleloginData)
      .then(function (response) {
        localStorage.setItem("loginUser", JSON.stringify(response.data.data));
        // if (parseInt(response.data.data.wallet_activation_status) === 0) {
        //   WalletActivation();
        // }

        WalletActivationGoogle();
        setGooleLoging(true);
        setgoogle("google");
        setphone(response.data.data.phone);
        setcountry_codenew(response.data.data.country_code);

        if (response.data.data.country_code) {
          setLoading(false);
          DCC(response.data.data.country_code);
        } else {
          DCC();
        }

        localStorage.setItem("token", response.data.token);
        // if (parseInt(response.data.data.wallet_activation_status) === 0) {
        //     WalletActivation();
        //   } else {

        //   }

        if (
          response.data.status === 1 &&
          response.data.data.phone_otp_verify == "0"
        ) {
          setcheckWalletactivation(response.data.data.wallet_activation_status);
          setOpen4(true) ? setOpen4(true) : setOpen(false);
        }

        // else if ( response.data.data.wallet_activation_status == 0){
        //   WalletActivation();
        // }
        else {
          setOpen(false);
          setOpen1(false);
          setOpen2(false);
          setOpen3(false);
          NotificationManager.success("Login Successfully", "", 1500);
          window.location.reload();
        }
      })
      .catch((error) => {
        NotificationManager.error(
          error?.response?.data?.error ?? "Something Wrong"
        );
      });
  };

  const responseFailureGoogle = (response) => {};

  const erroronChange = (e) => {
    var objValidation = {};
    if (phone.length < 8 && phone.length > 1) {
      setlengthNumError(true);
    } else {
      setlengthNumError(false);
    }
    objValidation = checkValidationFormAllControls(
      document.forms[0].elements,
      false,
      []
    );
    if (objValidation.valid !== false) {
      seterrorDisplay(objValidation);
      return;
    } else {
      seterrorDisplay(objValidation);
    }
  };

  // Wallet Activatiojn Api

  const handleNormalLogin = (e, socialLogin = "") => {
    e.preventDefault();
    var objValidation = {};
    objValidation = checkValidationFormAllControls(
      document.forms[0].elements,
      false,
      []
    );

    if (objValidation.valid !== false) {
      seterrorDisplay(objValidation);
      return;
    } else {
      seterrorDisplay(objValidation);
    }
    if (phone.length > 0) {
      if (phone.length >= 8) {
        objValidation.valid = true;
      } else {
        objValidation.valid = false;
        setlengthNumError(true);
        e.preventDefault();
        return;
      }
    }
    setLoading(true);
    let Logindata = {
      email_phone:
        emaill != "" && phone == ""
          ? emaill
          : phone != "" && emaill == ""
          ? phone
          : phone != "" && emaill != ""
          ? phone
          : "",
      country_code: emaill.includes("@") ? "" : parseInt(selCountryCode),
      get_otp_on: emaill.includes("@")
        ? "1"
        : wantOtpOnWhatsApp === 2
        ? wantOtpOnWhatsApp
        : "1",
      // emaill.includes("@") ? "" : selCountryCode,
    };
    if (socialLogin == "google") {
      Logindata.update_type = emaill.includes("@") ? "email" : "phone";

      Logindata.country_short_code = selCountrySortName;
      // countrySc
    } else {
      Logindata.login_type = emaill.includes("@") ? "email" : "phone";
    }

    var url = "user/loginOtpRequest";
    if (socialLogin == "google") {
      url = "user/profile_updates_with_single_fields";
    }
    http(url, "POST", Logindata).then((res) => {
      let data = res.data;

      setLoading(false);
      if (data.status === 1) {
        //  {data.data.wallet_activation_status === 0 ? WalletActivation(): ""}
        // if(data.data.wallet_activation_status === 0){
        //   WalletActivation()
        // }

        setCountrycodeLogin(data.data.country_code);
        NotificationManager.success(data.msg, "", 1500);
        if (data.data.userid) setEmailuserid(data.data.userid);
        // emaill !== '' ? setEmailuserid(data.data.userid)
        if (data.data.id) setUseridget(data.data.id);
        setOpen(false) ? setOpen(false) : setOpen3(false);
        otpmodal();
        setActivelinlk(true);
        setCounter(30);
      } else {
        setLoading(false);
        NotificationManager.error(data.msg, "", 1500);
      }
    });
  };

  // Country List data
  const DCC = (countryCode = "") => {
    if (countryCode) {
      setCountryselector(parseInt(countryCode));
    } else {
      getCountryCodeByCurrentIpAddress();
    }
  };
  const getCountryCodeByCurrentIpAddress = () => {
    axios.get("https://www.cloudflare.com/cdn-cgi/trace").then((resp) => {
      var objRes = resp.data
        .trim()
        .split("\n")
        .reduce(function (obj, pair) {
          pair = pair.split("=");

          return (obj[pair[0]] = pair[1]), obj;
        }, {});

      const ip = objRes.ip;
      axios
        .get(
          "https://api.ipdata.co/" +
            ip +
            "?api-key=91d3a57b6ed30ea0783d655e7bbe1989b95783a14ee19e80b92b7b18",
          "GET"
        )
        .then((response) => {
          setCountryselector(parseInt(response.data.calling_code));
          setCountryShortCode(response.data.country_code);
        });
    });
  };

  // Handle CHange Functionality

  const handleChangeotp = (element, index) => {
    if (isNaN(element.value)) return false;
    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);
    //Focus next input
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };
  useEffect(() => {
    setOpen(props.handleOpen);
    getcountryList();
    DCC();
    !localStorage.getItem("token") ? setisloading(false) : setisloading(true);
  }, []);

  const getcountryList = () => {
    const countriesData = require("../../countries.json");
    setCountrycodelist(countriesData);
  };

  const [value, setValue] = useState("");

  const changeHandler = (value) => {
    setValue(value);
  };

  //

  // Email Update By single vlaue
  const [open5, setOpen5] = React.useState(false);
  const handleClose5 = () => {
    setOpen5(false);
    setOpen1(false);
    props.handleClick();
  };

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    props.handleClick();
    setOpen(false);
  };

  // signupmodal
  const [open1, setOpen1] = React.useState(false);
  const handleClose1 = () => {
    props.handleClick();
    setOpen1(false);
    localStorage.removeItem("loginUser");
    localStorage.removeItem("fullname");
    localStorage.removeItem("email");
    localStorage.removeItem("phone");
  };

  // OTPverify Modal
  const [open2, setOpen2] = React.useState(false);
  const handleClose2 = () => {
    props.handleClick();
    setOpen2(false);
    localStorage.removeItem("loginUser");
    localStorage.removeItem("fullname");
    localStorage.removeItem("email");
    localStorage.removeItem("phone");
  };

  // Signup with Emailid
  const [open3, setOpen3] = React.useState(false);

  const handleClose3 = () => {
    props.handleClick();
    setOpen3(false);
  };

  // Signup with Mobile
  const [open4, setOpen4] = React.useState(false);

  const handleClose4 = () => {
    props.handleClick();
    setOpen4(false);
  };

  // Update Email&Phonenumber With single value

  // OTPverify Modal for single value
  const [open6, setOpen6] = React.useState(false);
  const handleClose6 = () => {
    props.handleClick();
    setOpen6(false);
  };

  // function of open and close the modals signin & signup
  const modalmanage = () => {
    setOpen(false);
    setOpen1(true);
  };

  const modalmanage1 = () => {
    setOpen1(false);
    setOpen(true);
    setEmaill("");
  };

  const modalmanage2 = () => {
    setOpen(false);
    setOpen3(true);
  };

  const modalmanage3 = () => {
    setOpen(true);
    setOpen3(false);
  };

  const otpmodal = () => {
    setOpen1(false);
    setOpen2(true);
  };

  //
  const handleClickModalLink = (path) => () => {
    navigate(path);
  };

  const windowreload = () => {
    localStorage.clear();
    navigate("/", { replace: true });
    handleClose4();
    window.location.reload();
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const menuId = "primary-search-account-menu";

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const [showType, setType] = useState(false);

  const onlyAlphaRegex = /^[a-z ,.'-]+$/i;

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {/* Email&phone update with single value modal */}

      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open5}
          onClose={handleClose5}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          className="formPopUp"
        >
          <Fade in={open5}>
            <Box sx={style}>
              <img
                src="assets/images/close-icon.png"
                alt="ticketkore"
                className="closeIcon"
                onClick={handleClose5}
              />

              <form id="form2">
                <Box className="formBox">
                  <Box sx={{ textAlign: "center" }} className="logoPbox">
                    <img
                      src="assets/images/logo-ticketkore.png"
                      alt="ticketkore"
                    />
                  </Box>

                  <Box noValidate autoComplete="off" className="formInputBox">
                    <img
                      src="assets/images/message-icon.png"
                      alt="ticketkore"
                      className="fieldIcon"
                    />
                    {!showType ? (
                      <TextField
                        required
                        id="email"
                        name="email"
                        type="email"
                        placeholder={t('Enter_email_id')}
                        size="small"
                        sx={{ width: "100%" }}
                        value={email}
                        // onChange={(e) => setEmailId(e.target.value)}
                      />
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginBottom: "-34px",
                        }}
                      >
                        <Box
                          sx={{
                            marginRight: "30px",
                          }}
                        >
                          {/* <FormControl fullWidth>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={"+" + countryselector}
                              required
                              size="small"
                              className="countryCode"
                              sx={{ background: "#fff" }}
                              onChange={(ev) => {
                                changePhoneCode(ev);
                              }}
                              // MenuProps={MenuProps}
                            >
                              {countrycodelist.map((e) => (
                                <MenuItem value={e}>
                                  {e.emoji} {e.country_name} {"+" + e.phone}{" "}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl> */}
                          <CCSearch getSelCountry={getSelCountry} />
                        </Box>

                        <Box
                          noValidate
                          autoComplete="off"
                          className="formInputBox formInputBoxDouble"
                        >
                          <img
                            src="assets/images/phone-icon.png"
                            alt="ticketkore"
                            className="fieldIcon"
                          />
                          <TextField
                            required
                            name="PhoneNumber"
                            type="tel"
                            id="phone"
                            variant="outlined"
                            size="small"
                            placeholder={t('Enter_mobile_no')}
                            value={phone}
                            onChange={(e) => setphone(e.target.value)}
                            sx={{ width: "100%" }}
                            inputProps={{ maxLength: 15 }}
                          />
                        </Box>
                      </div>
                    )}
                  </Box>

                  <Button
                    disabled={loading}
                    type="button"
                    id="sign"
                    className="signInBtn"
                    onClick={handleNormalLogin}
                    style={{ marginTop: "20px", marginBottom: "20px" }}
                  >
                    {" "}
                    {loading ? (
                      <SpinnerCircular
                        style={{ width: "1.2rem", height: "1.2rem" }}
                        color="light"
                      />
                    ) : (
                      "Update"
                    )}
                  </Button>
                </Box>
              </form>
            </Box>
          </Fade>
        </Modal>
      </div>

      {/* Otpverify Single value */}

      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open6}
          onClose={handleClose6}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          className="formPopUp"
        >
          <Fade in={open6}>
            <Box sx={style}>
              <img
                src="assets/images/close-icon.png"
                alt="ticketkore"
                className="closeIcon"
                onClick={handleClose6}
              />

              <Box className="formBox">
                <Box sx={{ textAlign: "center" }} className="logoPbox">
                  <img
                    src="assets/images/logo-ticketkore.png"
                    alt="ticketkore"
                  />
                </Box>

                <Box>
                  <Typography className="signUpText">Verification</Typography>

                  <Typography sx={{ marginBottom: "37px" }}>
                    We have sent a verification code to registered email
                    id/mobile number Please enter the sent verification code to
                    email/phone
                    {/* {phone ? "Mobile Number" : ""} */}
                    {/* We have
                    sent a verification code to registered{" "}
                    {phone ? "Mobile Number" : "Email"}{" "}
                    <span style={{ fontWeight: "700" }}>
                      {phone ? phone : email}
                    </span>{" "}
                    <a
                      href="#"
                      style={{
                        color: "#0CA8F6",
                        fontWeight: "700",
                        paddingLeft: "5px",
                      }}
                    >
                      Edit
                    </a> */}
                  </Typography>
                </Box>

                {/* <Box noValidate autoComplete="off" className="formOtpBox">
                  {otp.map((data, index) => {
                    return (
                      <input
                        type="text"
                        name="otp"
                        maxLength={1}
                        key={index}
                        value={data}
                        onChange={(e) => handleChangeotp(e.target, index)}
                        onFocus={(e) => e.target.select()}
                        className="otpInput activeBorder"
                      />
                    );
                  })}
                </Box> */}

                <Button
                  disabled={loading}
                  type="submit"
                  id="verify"
                  className="signInBtn"
                  onClick={handleSubmitOtp}
                >
                  {" "}
                  {loading ? (
                    <SpinnerCircular
                      style={{ width: "1.2rem", height: "1.2rem" }}
                      color="light"
                    />
                  ) : (
                    "Verifys"
                  )}
                </Button>
              </Box>
              <Typography className="formText" sx={{ marginTop: "20px" }}>
                {" "}
                Haven’t got the OTP? {counter > 0 ? counter : "0"}
                {/* {phone ?  "+"+ country_codenew +" "+ phone : emaill} */}
              </Typography>
            </Box>
          </Fade>
        </Modal>
      </div>

      {/* Signin popup */}

      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          backdrop="static"
          //onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          className="formPopUp"
        >
          <Fade in={open}>
            <Box sx={style}>
              <img
                src="assets/images/close-icon.png"
                alt="ticketkore"
                className="closeIcon"
                onClick={handleClose}
              />
              <form id="form1">
                <Box className="formBox">
                  <Box sx={{ textAlign: "center" }} className="logoPbox">
                    <img
                      src="assets/images/logo-ticketkore.png"
                      alt="ticketkore"
                    />
                  </Box>

                  <GoogleLogin
                    clientId="341940775510-11tko45heqqe81no30hfmane7fbug4k4.apps.googleusercontent.com"
                    render={(renderProps) => (
                      <Box
                        noValidate
                        autoComplete="off"
                        className="formInputBox cp"
                        onClick={renderProps.onClick}
                        sx={{
                          display: "flex",
                          justifyContent: "space-around",
                          alignItems: "center",
                          border: "1px solid rgba(0, 0, 0, 0.38)",
                          borderRadius: "4px",
                          height: "42px",
                        }}
                      >
                        <img
                          src="assets/images/Google-icon.png"
                          alt="ticketkore"
                          className="fieldIcon"
                        />

                        <Typography variant="body1" sx={{ cursor: "pointer" }}>
                          {`${t('Continue_with_Google')}${" "}`}
                        </Typography>
                      </Box>
                    )}
                    buttonText={`${t('Continue_with_Google')}`}
                    onSuccess={responseSuccessGoogle}
                    onFailure={responseFailureGoogle}
                    style={{
                      marginBottom: "20px !important",
                      cursor: "pointer",
                    }}
                  />

                  <Box
                    noValidate
                    autoComplete="off"
                    className="formInputBox margintop cp"
                    onClick={modalmanage2}
                    sx={{
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                      border: "1px solid rgba(0, 0, 0, 0.38)",
                      borderRadius: "4px",
                      height: "42px",
                    }}
                  >
                    <img
                      src="assets/images/message-icon.png"
                      alt="ticketkore"
                      className="fieldIcon"
                    />

                    <Typography variant="body1" sx={{ cursor: "pointer" }}>
                    {`${t('Continue_with_Email')}`}
                    </Typography>
                  </Box>

                  <Box className="orPadding">
                    <Box
                      sx={{
                        height: "1px",
                        width: "100%",
                        background: "#d4d4d4",
                      }}
                    ></Box>
                    <Typography className="orText">{`${t('or')}`}</Typography>
                  </Box>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "-18px",
                    }}
                  >
                    <Box
                      sx={{
                        marginRight: "10px",
                      }}
                    >
                      {/* <FormControl fullWidth>
                       
                        <Select
                          defaultValue=""
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={"+" + countryselector}
                          size="small"
                          name="code"
                          className="countryCode"
                          sx={{ background: "#fff" }}
                          onChange={(ev) => {
                            changePhoneCode(ev);
                          }}
                          required
                          MenuProps={MenuProps}
                        >
                          {countrycodelist.map((e) => (
                            <MenuItem value={e}>
                              {e.emoji ? e.emoji : ""}
                              {"+" + e.phone ? e.phone : ""}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl> */}
                      <CCSearch getSelCountry={getSelCountry} />
                    </Box>
                    <Box
                      noValidate
                      autoComplete="off"
                      className="formInputBox formInputBoxDouble"
                      sx={{}}
                    >
                      <img
                        src="assets/images/phone-icon.png"
                        alt="ticketkore"
                        className="fieldIcon"
                      />
                      <TextField
                        fullWidth
                        id="phone"
                        name="phone"
                        type="tel"
                        size="small"
                        required
                        variant="outlined"
                        placeholder={t('Enter_mobile_no')}
                        sx={{ width: "100%" }}
                        value={phone}
                        error={lengthNumError}
                        helperText={
                          lengthNumError
                            ? "Number length must be greater or equal to 8 digit"
                            : ""
                        }
                        inputProps={{ maxLength: 15 }}
                        onChange={(e) => {
                          setphone(e.target.value);
                          erroronChange(e);
                        }}
                      />
                    </Box>
                  </div>
                  {errorDisplay.code ? (
                    <span className="red">{errorDisplay.code}</span>
                  ) : (
                    ""
                  )}
                  <br />
                  {errorDisplay.phone ? (
                    <span className="red" style={{ marginLeft: "120px" }}>
                      {errorDisplay.phone}
                    </span>
                  ) : (
                    ""
                  )}
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Switch
                      {...label}
                      checked={localStorage.getItem("whatsAppCheck")}
                      onChange={(e) => whatsappCheck(e)}
                    />
                    <Typography variant="body1">{t('Get_Otp_on_WhatsApp')}</Typography>
                  </Box>

                  <Button
                    disabled={loading}
                    type="submit"
                    className="signInBtn"
                    onClick={(e) => handleNormalLogin(e, google)}
                    style={{ marginTop: "20px" }}
                  >
                    {" "}
                    {loading ? (
                      <SpinnerCircular
                        style={{ width: "1.2rem", height: "1.2rem" }}
                        color="light"
                      />
                    ) : (
                      `${t('sign_in')}`
                    )}
                  </Button>
                </Box>
              </form>
              <Typography className="formText" sx={{ marginTop: "20px" }}>
                {`${t('Not_register')}?${" "}`}
                <a onClick={modalmanage} href className="formLink">
                  {" "}
                  {`${t('Sign_Up')}${" "}`}
                </a>
              </Typography>
              <Typography className="formText pt55">
              {`${t('By_signing_in_you_agree_to_our')}${" "}`}
                {/* <a href="/termscondition" className="formLink">
                  Terms of Services
                </a> */}
                <Link
                  className="linkMenuStyle1"
                  onClick={handleClickModalLink("/termscondition")}
                >
                  {`${" "}
                  ${t('Terms_of_use')}${" "}`}
                </Link>{`${" "}
                  ${t('and')}${" "}`}
                {/* <a href="/termscondition" className="formLink">
                  Privacy Policy
                </a> */}
                <Link
                  className="linkMenuStyle1"
                  onClick={handleClickModalLink("/privacypolicy")}
                >
                 {`${" "}
                  ${t('Privacy_Policy')}${" "}`}
                </Link>
                .
              </Typography>
            </Box>
          </Fade>
        </Modal>
      </div>

      {/* Signin popup */}

      {/* SignUp popup */}

      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open1}
          backdrop="static"
          //onClose={handleClose1}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          className="formPopUp"
        >
          <Fade in={open1}>
            <Box sx={style}>
              <img
                src="assets/images/close-icon.png"
                alt="ticketkore"
                className="closeIcon"
                onClick={handleClose1}
              />

              <form id="form2" noValidate method="get">
                <Box className="formBox">
                  <Box sx={{ textAlign: "center" }} className="logoPbox">
                    <img
                      src="assets/images/logo-ticketkore.png"
                      alt="ticketkore"
                    />
                  </Box>

                  <Box>
                    <Typography className="signUpText">{t('Sign_Up')}</Typography>
                  </Box>

                  <Box noValidate autoComplete="off" className="formInputBox">
                    <img
                      src="assets/images/userIcon.png"
                      alt="user"
                      className="fieldIcon"
                    />
                    <TextField
                      required
                      id="fullname"
                      name="fullname"
                      type="name"
                      placeholder={t('Enter_full_name')}
                      size="small"
                      regex={onlyAlphaRegex}
                      sx={{ width: "100%" }}
                      value={fullname}
                      inputProps={{ maxLength: 30 }}
                      onChange={(e) => {
                        setfullname(e.target.value);
                        seterrorDisplay("");
                      }}
                    />
                    {errorDisplay.fullname ? (
                      <span className="red">{errorDisplay.fullname}</span>
                    ) : (
                      ""
                    )}
                  </Box>

                  <Box noValidate autoComplete="off" className="formInputBox">
                    <img
                      src="assets/images/message-icon.png"
                      alt="ticketkore"
                      className="fieldIcon"
                    />
                    <TextField
                      required
                      id="email"
                      name="email"
                      type="email"
                      placeholder={t('Enter_email_id')}
                      size="small"
                      sx={{ width: "100%" }}
                      value={emaill}
                      onChange={(e) => {
                        // setemail(e.target.value);
                        setEmaill(e.target.value);
                        seterrorDisplay("");
                      }}
                    />
                    {errorDisplay.email ? (
                      <span className="red">{errorDisplay.email}</span>
                    ) : (
                      ""
                    )}
                  </Box>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "-20px",
                    }}
                  >
                    <Box
                      sx={{
                        marginRight: "30px",
                      }}
                    >
                      <CCSearch getSelCountry={getSelCountry} />
                    </Box>

                    <Box
                      noValidate
                      autoComplete="off"
                      className="formInputBox formInputBoxDouble"
                    >
                      <img
                        src="assets/images/phone-icon.png"
                        alt="ticketkore"
                        className="fieldIcon"
                      />
                      <TextField
                        required
                        name="PhoneNumber"
                        type="tel"
                        id="phone"
                        variant="outlined"
                        size="small"
                        placeholder={t('Enter_mobile_no')}
                        sx={{ width: "100%" }}
                        value={phoneregister}
                        error={lengthNumError}
                        helperText={
                          lengthNumError
                            ? "Number length must be greater or equal to 8 digit"
                            : ""
                        }
                        inputProps={{ maxLength: 15 }}
                        onChange={(e) => {
                          setphoneRegister(e.target.value);
                          erroronChange(e);
                        }}
                      />
                    </Box>
                  </div>
                  {errorDisplay.countryselector ? (
                    <span className="red">{errorDisplay.countryselector}</span>
                  ) : (
                    ""
                  )}
                  <br />
                  {errorDisplay.PhoneNumber ? (
                    <span className="red" style={{ marginLeft: "120px" }}>
                      {errorDisplay.PhoneNumber}
                    </span>
                  ) : (
                    ""
                  )}
                  <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label">
                      {t('Gender')}
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue={gender}
                      value={gender}
                      onChange={(e) => setGeneder(e.target.value)}
                      name="radio-buttons-group"
                      className="gender-container"
                      row={true}
                    >
                      <FormControlLabel
                        value="male"
                        control={<Radio />}
                        label={t('Male')}
                      />
                      <FormControlLabel
                        value="female"
                        control={<Radio />}
                        label={t('Female')}
                      />
                      <FormControlLabel
                        value="other"
                        control={<Radio />}
                        label={t('Other')}
                      />
                    </RadioGroup>
                  </FormControl>

                  <Button
                    disabled={loading}
                    type="submit"
                    id="sign"
                    className="signInBtn"
                    onClick={handleSubmit}
                    style={{ marginTop: "20px" }}
                  >
                    {" "}
                    {loading ? (
                      <SpinnerCircular
                        style={{ width: "1.2rem", height: "1.2rem" }}
                        color="light"
                      />
                    ) : (
                      `${t('Sign_Up')}`
                    )}
                  </Button>
                </Box>
              </form>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  mt: "5px",
                }}
              >
                <Switch
                  {...label}
                  checked={localStorage.getItem("whatsAppCheck")}
                  onChange={(e) => whatsappCheck(e)}
                />
                <Typography variant="body1">{t('Get_Otp_on_WhatsApp')}</Typography>
              </Box>

              <Typography className="formText" sx={{ marginTop: "20px" }}>
                {t('Already_have_an_account')}?
                {/* Sign In */}
                <a onClick={modalmanage1} className="formLink cp">
                  {" "}
                  {t('sign_in')}{" "}
                </a>
              </Typography>
              <Typography className="formText pt55">
                {`${t('By_signing_in_you_agree_to_our')}${" "}`}
                <Link
                  className="linkMenuStyle1"
                  onClick={handleClickModalLink("/termscondition")}
                >
                  {`${" "}
                  ${t('Terms_of_use')}${" "}`}
                </Link>
                {/* <a href="/termscondition" className="formLink">
                  Terms of Services
                </a> */}{`${" "}
                  ${t('and')}${" "}`}
                {/* <a href="termscondition" className="formLink">
                  Privacy Policy
                </a> */}
                <Link
                  className="linkMenuStyle1"
                  onClick={handleClickModalLink("/privacypolicy")}
                >
                  {`${" "}
                  ${t('Privacy_Policy')}${" "}`}
                </Link>
                .
              </Typography>
            </Box>
          </Fade>
        </Modal>
      </div>

      {/* SignUp popup */}

      {/* OTP popup */}

      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open2}
          backdrop="static"
          //onClose={handleClose2}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          className="formPopUp"
        >
          <Fade in={open2}>
            <Box sx={style}>
              <img
                src="assets/images/close-icon.png"
                alt="ticketkore"
                className="closeIcon"
                onClick={handleClose2}
              />
              <form id="form1">
                <Box className="formBox">
                  <Box sx={{ textAlign: "center" }} className="logoPbox">
                    <img
                      src="assets/images/logo-ticketkore.png"
                      alt="ticketkore"
                    />
                  </Box>

                  <Box>
                    <Typography className="signUpText">{t('Verification')}</Typography>

                    <Typography
                      sx={{ marginBottom: "37px", textAlign: "center" }}
                    >
                      {/* We have sent a verification code to registered phonenumber  */}
                      {`${t('We_have_sent_a_verification_code_to_registered')}${" "}`}
                      {phone && emaill !== "" ? (
                        <span
                          style={{ fontWeight: "700", textAlign: "center" }}
                        >
                          {" "}
                          <br />
                          {t('email_id_mobile_number')}
                          <br />
                          {emaill} / {phone}
                        </span>
                      ) : (
                        <span
                          style={{ fontWeight: "700", textAlign: "center" }}
                        >
                          {phone ? t("Mobile_Number") : t("Email")}{" "}
                          {phone
                            ? (isGooleLoging ? "" : "+") +
                              countrycodeLoginnew +
                              "-" +
                              phone
                            : emaill}
                        </span>
                      )}
                    </Typography>
                  </Box>

                  <Box noValidate autoComplete="off" className="formOtpBox">
                    {/* {otp.map((data, index) => {
                      return (
                        <input
                          type="text"
                          name="otp"
                          // inputProps={{ maxLength: 1 }}
                          maxLength={1}
                          key={index}
                          value={data}
                          onChange={(e) => handleChangeotp(e.target, index)}
                          onFocus={(e) => e.target.select()}
                          className="otpInput activeBorder"
                        />
                      );
                    })} */}
                    <OtpInput
                      value={otp}
                      onChange={(otp) => setOtp(otp)}
                      numInputs={4}
                      className="otpInput activeBorder"
                      inputStyle={"otp-input-box"}
                      isInputNum={true}
                      // separator={<span>-</span>}
                    />
                  </Box>

                  <Button
                    // disabled={otp.join().length <= 3 ? true : false}
                    disabled={loading}
                    type="submit"
                    id="verify"
                    // className="signInBtn"
                    className={
                      otp.length <= 3 ? "disableEmailBtn" : " signInBtn"
                    }
                    onClick={handleSubmitOtp}
                  >
                    {" "}
                    {loading ? (
                      <SpinnerCircular
                        style={{ width: "1.2rem", height: "1.2rem" }}
                        color="light"
                      />
                    ) : (
                      "Verify"
                    )}
                  </Button>
                </Box>{" "}
                <Typography className="formText" sx={{ marginTop: "20px" }}>
                  <Box>
                    <Button
                      sx={{
                        fontFamily: "unset",
                        textTransform: "capitalize",
                        textDecoration: "underline",
                        fontWeight: "600",
                      }}
                      disabled={activelink}
                      onClick={resendOtp}
                      className={
                        activelink ? "resend-otp-active" : "resend-otp-deactive"
                      }
                    >
                      {t('Resend')}
                    </Button>
                  </Box>{" "}
                  {t('Havent_got_the_OTP')} - {counter > 0 ? counter : "0"}
                </Typography>
              </form>
            </Box>
          </Fade>
        </Modal>
      </div>

      {/* OTP popup */}

      {/* Sign in with Mobile Id popup */}

      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open4}
          // onClose={handleClose4}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          className="formPopUp"
        >
          <Fade in={open4}>
            <Box sx={style}>
              <img
                src="assets/images/close-icon.png"
                alt="ticketkore"
                className="closeIcon"
                // onClick={handleClose4}
                onClick={windowreload}
              />
              <form id="form2">
                <Box className="formBox">
                  <Box sx={{ textAlign: "center" }} className="logoPbox">
                    <img
                      src="assets/images/logo-ticketkore.png"
                      alt="ticketkore"
                    />
                  </Box>
                  <Box>
                    <Typography
                      className="signUpText"
                      sx={{ textAlign: "left" }}
                    >
                      <img
                        src="assets/images/left-dark-arrow.png"
                        alt="ticketkore"
                        style={{ marginRight: "16px", cursor: "pointer" }}
                        onClick={modalmanage3}
                      />
                      {t('Please_verify_your_mobile_number')}
                    </Typography>
                  </Box>

                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box
                      sx={{
                        marginRight: "0px",
                        width: "100%",
                      }}
                    >
                      {/* <FormControl fullWidth>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          required
                          name="code"
                          value={"+" + countryselector}
                          size="small"
                          className="countryCode"
                          sx={{ background: "#fff" }}
                          onChange={(ev) => {
                            setCountryselector(ev.target.value.phone);
                          }}
                          MenuProps={MenuProps}
                        >
                          {countrycodelist.map((e) => (
                            <MenuItem value={e}>
                              {e.emoji} {"+" + e.phone}{" "}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl> */}
                      <CCSearch getSelCountry={getSelCountry} />
                    </Box>
                    {errorDisplay.code ? (
                      <span className="red">{errorDisplay.code}</span>
                    ) : (
                      ""
                    )}
                    <Box
                      noValidate
                      autoComplete="off"
                      className="formInputBox formInputBoxDouble"
                      sx={{}}
                    >
                      <img
                        src="assets/images/phone-icon.png"
                        alt="ticketkore"
                        className="fieldIcon"
                      />
                      <TextField
                        id="phone"
                        name="phone"
                        required
                        type="tel"
                        inputProps={{ maxLength: 10, minLength: 10 }}
                        size="small"
                        variant="outlined"
                        sx={{ width: "100%" }}
                        value={phone}
                        onChange={(e) => setphone(e.target.value)}
                      />
                    </Box>
                  </div>
                  {errorDisplay.phone1 ? (
                    <span className="red">{errorDisplay.phone1}</span>
                  ) : (
                    ""
                  )}

                  <Button
                    type="button"
                    className="signInBtn"
                    sx={{ marginTop: "46px" }}
                    onClick={(e) => handleNormalLogin(e, google)}
                  >
                    {" "}
                    {t('Confirm')}{" "}
                  </Button>
                </Box>
              </form>
            </Box>
          </Fade>
        </Modal>
      </div>

      {/* Sign in with Mobile Id popup */}

      {/* Sign in with Email Id popup */}

      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open3}
          onClose={handleClose3}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          className="formPopUp"
        >
          <Fade in={open3}>
            <Box sx={style}>
              <img
                src="assets/images/left-dark-arrow.png"
                alt="ticketkore"
                className="popupBtnPos"
                onClick={modalmanage3}
              />
              {/* <img
                src="assets/images/close-icon.png"
                alt="ticketkore"
                className="closeIcon"
                onClick={handleClose3}
              /> */}
              <Box className="formBox">
                <Box sx={{ textAlign: "center" }} className="logoPbox">
                  <img
                    src="assets/images/logo-ticketkore.png"
                    alt="ticketkore"
                  />
                </Box>
                <Box>
                  <Typography className="signUpText" sx={{ textAlign: "left" }}>
                    {t('Sign_in_with_Email_Id')}
                  </Typography>
                </Box>
                <form id="form1" onSubmit={(e) => e.preventDefault()}>
                  <Box autoComplete="on" className="formInputBox">
                    <img
                      src="assets/images/message-icon.png"
                      alt="ticketkore"
                      className="fieldIcon"
                    />
                    <TextField
                      name="email"
                      type="email"
                      placeholder="Enter email id"
                      size="small"
                      sx={{ width: "100%" }}
                      value={emaill}
                      onChange={(e) => setEmaill(e.target.value)}
                      required
                    />
                    {errorDisplay.email ? (
                      <span className="red">{errorDisplay.email}</span>
                    ) : (
                      ""
                    )}
                  </Box>
                </form>

                <Button
                  disabled={loading}
                  type="submit"
                  id="EmailConfirm"
                  className={!emaill ? "disableEmailBtn" : " signInBtn"}
                  onClick={handleNormalLogin}
                >
                  {" "}
                  {loading ? (
                    <SpinnerCircular
                      style={{ width: "1.2rem", height: "1.2rem" }}
                      color="light"
                    />
                  ) : (
                    `${t("Continue")}`
                  )}
                </Button>
              </Box>
            </Box>
          </Fade>
        </Modal>
      </div>

      {/* Sign in with Email Id popup */}
    </Menu>
  );

  return (
    <Box className="MainBox">
      {renderMobileMenu}
      {renderMenu}
    </Box>
  );
}
