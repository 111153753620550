import React from 'react'
import Navbar from "../../Components/Navbar";
import MenuBar from "../Menu";
//import BreadCrumbs from '../../Components/BreadCrumbs';
import Footer from "../../Components/Footer";
import VendorList from '../../Components/VendorList';
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link } from "react-router-dom";
import { Breadcrumbs, Typography } from "@mui/material";
import { Box } from "@mui/system";
import "./style.css";

const VendorLists = () => {
  
    const breadcrumbs = [
        <Link to="/" key="1" className="link-decoration">
          Home
        </Link>,
    
        <Typography
          key="3"
          sx={{
            fontFamily: "Lato",
            color: "#0CA8F6",
            fontWeight: 500,
            fontSize: "16px",
            
          }}
        >
          Vendor List
        </Typography>,
      ];
      return (
        <>
          <Navbar />
          {/* <MenuBar /> */}
          <Box className="mainContainer">
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
              sx={{ mb: 3, mt: 2 }}
            >
              {breadcrumbs}
            </Breadcrumbs>
          </Box>
          <VendorList />
          <Footer />
        </>
      );

}

export default VendorLists;