import React from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check';
import "./style.css";
import { Link } from 'react-router-dom';

const PartnershipComponent = ({t}) => {
  return (
    <Box className='mainContainer' sx={{ paddingBottom: '30px' }}>
      <Grid container spacing={2} className='parnershipMobColR'>

        <Grid item xs={12} sm={6} md={6} lg={6}>
          <div className='parnershipTBPadding'>
            <Typography className='partnershipTypHead'>{t("Ticketkore delivers a top-notch event partnership with unmatched perks")}.</Typography>
            <ul className='partnershipUl'>
              <li>{t("Ticketing")}</li>
              <li>{t("Social_Marketing")}</li>
              <li>{t("Staff")}</li>
              <li>{t("Access_Control")}</li>
              <li>{t("Infrastructure_and_Hospitality")}</li>
            </ul>

            <Typography className='partnerWeTake'>{t(`We take true partnership approach to help you deliver fans
              experience that’s second to none`)}.</Typography>


            <div className='commonHardDivPart'><Link to="/contactus" className='linkDecoration'><span style={{borderBottom: '2px solid #0CA8F6'}}>{t("Lets_Partnership")} </span><img src='assets/images/hardArrow.png' alt='Resource' className='' style={{ marginLeft: '10px' }} /> </Link></div>

          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} className='partnershipGImg'>
          <img src='assets/images/partnership.png' alt='ticketkore' />
        </Grid>
      </Grid>
    </Box>
  )
}

export default PartnershipComponent