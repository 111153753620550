import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import {
  Grid,
  Typography 
} from "@mui/material";
import Modal from "@mui/material/Modal";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import CloseIcon from "@mui/icons-material/Close"; 
import "./style.css";
import ModalPopup from "../ModalPopup";
import moment from "moment";
import MapModalPopup from "../MapModalPopup"; 
import {useTranslation} from 'react-i18next'
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 600,
  bgcolor: "#FFFFFF",
  boxShadow: 24,
  width: "95%",
};

const AboutEvent = (props) => {
  const {t} = useTranslation('common')
  const { eventdetailspage1 } = props;

  const eventdetailspage = eventdetailspage1 ?? [];

 
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
const parse = require('html-react-parser');
  const [open1, setOpen1] = React.useState(false);
   
  const handleClose1 = () => setOpen1(false);

  // const [open3, setOpen3] = React.useState(false);
  // const handleOpen3 = () => setOpen3(true);
  // const handleClose3 = () => setOpen3(false);

  const [openLogin, setOpenLogin] = React.useState(false);
  const [datarangeOffers, setdatarangeOffers] = useState([]);
  const [allEventLocationAndDateTime1, setallEventLocationAndDateTime1] =useState([]);
   

 

  /**Use for the consent page navigation with event id */
  const dateFormated = (date) => {
    let dateNew = new Date(date);
    let day = dateNew.toLocaleString("en-us", { weekday: "short" });
    let newdate = new Date(date).getDate();
    let month = dateNew.toLocaleString("default", { month: "short" });
    return day + "," + newdate + " " + month;
  };

  //time function

  
  const [buttonText, setButtonText] = useState();
  const [disabled, setDisabled] = useState(false); 

  useEffect(() => {
    setdatarangeOffers(eventdetailspage1?.date_range_offers);
    setallEventLocationAndDateTime1(
      eventdetailspage1?.allEventLocationAndDateTime
    );
    if (eventdetailspage1.notify_status === 1) {
      setButtonText("Notified");
      setDisabled(true);
    } else {
      setButtonText("Notify Me12");
      setDisabled(false);
    }
  }, [eventdetailspage1]);
 



  const ReadMore = ({ children }) => { 
    const text = children[1];
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
    };
    return (
      <p className="text">
        {isReadMore ? text.slice(0, 200) : text}
        <span onClick={toggleReadMore} className="read-or-hide">
          {isReadMore ? (text.length >= 200 ? " ...show More" : "") : " show less"}
        </span>
      </p>
    );
  };


 




  return (
    <div style={{}}>
      <Box className="mainContainer">
        {/* modal offers */}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="ModalClass"
        >
          <Box sx={style} className="ModalClassBox">
            <Grid container>
              <Grid item xs={12} sx={{ textAlign: "right", padding: "16px" }}>
                <CloseIcon className="closeIconOffer" onClick={handleClose} />
                <Typography
                  id="modal-modal-description"
                  className="ModalPhomolong"
                  sx={{ textAlign: "center" }}
                >
                 {eventdetailspage1?.event_name}
                </Typography>
              </Grid>


              <Grid className="modelBoxScroll">
                {datarangeOffers?.length > 0 ? (
                  datarangeOffers.map((res, index) => (
                    <Box key={index}>
                      <Grid
                        item
                        xs={12}
                        sx={{ mb: "20px" }}
                        className="borderBoxNone"
                      >
                        <Accordion className="accordianFirst">
                          <AccordionSummary
                            expandIcon={
                              <ExpandMoreIcon
                                sx={{ color: "#0CA8F6", marginLeft: "10px" }}
                              />
                            }
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            className="ModAccordionSummaryBg"
                            backgroundColor="#F4FBFF"
                          >
                            <Box>
                              <Typography className="ModAccordTypoDt">
                                {dateFormated(res.event_date)}
                              </Typography>
                            </Box>

                            <Typography className="ModAccordTypoWt"></Typography>

                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                              }}
                            >
                              <Typography className="ModAccordTypoAdd">
                                {" "}
                                {res.venue_name ? res.venue_name : 0}{" "}
                              </Typography>
                              <Typography
                                sx={{ marginLeft: "auto" }}
                                className="ModAccordTm"
                              >
                                {moment(res.event_time ? res.event_time : 0, [
                                  "h:mm A",
                                ]).format("hh:mm:a")}
                              </Typography>
                            </Box>
                          </AccordionSummary>

                          <Box>
                            <Grid container>
                              {res.range.map((rangeRes, index) => (
                                <Grid item xs={6} sm={6} md={4} key={index}>
                                  <Box>
                                    <AccordionDetails className="ModBorderDashed">
                                      <div className="TextCenterDiv">
                                        <Typography className="ModOffTypo">
                                          {rangeRes.percentage
                                            ? rangeRes.percentage
                                            : 0}
                                          % Off
                                        </Typography>
                                        <Typography className="ModTypoDateAcc">
                                          {dateFormated(rangeRes.start_date)} to{" "}
                                          {dateFormated(rangeRes.end_date)}
                                        </Typography>
                                      </div>
                                    </AccordionDetails>
                                  </Box>
                                </Grid>
                              ))}
                            </Grid>
                          </Box>
                        </Accordion>
                      </Grid>
                    </Box>
                  ))
                ) : (
                  <Typography
                    sx={{
                      textAlign: "center",
                      // padding: "16px",
                      padding: "50px 0px",
                      width: "100%",
                    }}
                  >
                    {" "}
                    There are no offers availabe Currently{" "}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Box>
        </Modal>

        {/* View More */}
        <Modal
          open={open1}
          onClose={handleClose1}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="ModalClass"
        >
          <Box sx={style} className="ModalClassBox">
            <Grid container spacing={3}>
              <Grid item xs={12} sx={{ textAlign: "right", padding: "16px" }}>
                <CloseIcon className="closeIconOffer" style={{ paddingTop: '15px' }} onClick={handleClose1} />
                <Typography
                  onClick={handleClose1}
                  id="modal-modal-description"
                  className="ModalPhomolong"
                  sx={{ textAlign: "center" }}
                >
                  {/* More Venue  */}
                  {eventdetailspage1?.event_name}{" "}
                </Typography>
              </Grid>

              <Grid item xs={12} className="modelBoxScroll">
                {allEventLocationAndDateTime1?.length > 0 ? (
                  allEventLocationAndDateTime1.map((res, index) => (
                    <>
                      {res.event_date_time.map((rangeRes, index) => (
                        <Grid
                          key={index}
                          item
                          xs={12}
                          sx={{ mb: "20px" }}
                          className="borderBoxNone"
                        >
                          <Box>
                            <Accordion className="accordianFirst">
                              <AccordionSummary
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                className="ModAccordionSummaryBg"
                                backgroundColor="#F4FBFF"
                                style={{ cursor: 'default' }}
                              >
                                <>
                                  <Box sx={{ padding: "0px 10px" }}>
                                    <Typography
                                      key={index}
                                      className="ModAccordTypoDt"
                                    >
                                      {dateFormated(rangeRes.date)}
                                    </Typography>
                                  </Box>

                                  <Typography className="ModAccordTypoWt"></Typography>

                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",                                      
                                      padding: "0px 10px",
                                    }}
                                    
                                  >
                                    <Typography className="ModAccordTypoAdd">
                                      {" "}
                                      {res.venue_name}, {res.venue_address}{" "}
                                      {res.city_name}, {res.province_name}{" "}
                                    </Typography>
                                    <Typography
                                      sx={{ marginLeft: "auto" }}
                                      className="ModAccordTm"
                                    >
                                      <MapModalPopup
                                        customData={res}
                                        handleClick={handleClose1}
                                      />
                                    </Typography>
                                  </Box>
                                </>
                              </AccordionSummary>
                            </Accordion>
                          </Box>
                        </Grid>
                      ))}
                    </>
                  ))
                ) : (
                  <Typography
                    sx={{ textAlign: "center", padding: "16px", mt: "2" }}
                  >
                    There are no More Venue available Currently
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Box>
        </Modal>

        <Grid
          container
          spacing={3}
          className="mobTopDivAbout"
          sx={{ marginTop: "30px"  }}
        >
          <Grid item md={12} sx={{width:"100%"}}>
            <Typography
              sx={{
                fontSize: "22px",
                fontWeight: 700,
                color: "#2C2C2C",
                fontFamily: "Lato",
                marginBottom: "10px",
              }}
            >
              {t('About_The_Event')}
            </Typography>
            <div className="hrLine"></div>
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: 500,
                color: "#686868",
                fontFamily: "Lato",
                marginTop: "10px",
                width:'100% !important',
                overflow:'scroll',
                
              }}
            >
              {/* {eventdetailspage.event_description
                ? parse(eventdetailspage.event_description)
                : ""} */}
  {eventdetailspage.event_description
                ? parse( eventdetailspage.event_description )
                : ""} 
            </Typography>

            {eventdetailspage.ts === "ts" ? (
              <Grid item xs={12} sm={12} md={6} lg={5} sx={{ margin: "10px" }}>
                {openLogin ? (
                  <ModalPopup
                    handleOpen={openLogin}
                    handleClick={handleClose}
                  />
                ) : (
                  ""
                )}
              </Grid>
            ) : (
              ""
            )}

          </Grid>
          {eventdetailspage.ts == "ts" ? (
            ""
          ) : eventdetailspage.ts == "cs" ? (
            ""
          ) : (
            <>

              {datarangeOffers.length === 0 ? (
                ""
              ) : (
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Box>
                    <div className="ViewTopGrid">
                      <div className="viewOfferDetail">
                        <img src="assets/images/percent.png" alt=''></img>
                        <span>{t('View_Offers_Details')}</span>
                      </div>
                      <button className="viewBtn" onClick={handleOpen}>
                      {t('View')} 
                      </button>
                    </div>
                  </Box>
                </Grid>
              )}

            </>
          )}


          {eventdetailspage.ts == "ts" ? (
            ""
          ) : eventdetailspage.ts == "cs" ? (
            ""
          ) : (
            <> </>
          )}
        </Grid>
      </Box>
    </div>
  );
};

export default AboutEvent;
