import React, { useEffect } from 'react'
import MenuBar from "../../Components/MenuBar";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import BuyComponent from '../../Components/BuyComponent';
import CommonBanner from "../../Components/CommonBanner";
import {useTranslation} from 'react-i18next'
// import  Breadcrumbs  from '../../Components/BreadCrumbs'

import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link } from "react-router-dom";
import { Breadcrumbs, Typography } from "@mui/material";
import { Box } from "@mui/system";
import "./style.css";

const Buy = () => {
  const {t} = useTranslation("common")
    const breadcrumbs = [
        <Link to={"/"} key="1" className="link-decoration">
          {t("Home")}
        </Link>,
    
        <Typography
          key="3"
          sx={{
            fontFamily: "Lato",
            color: "#0CA8F6",
            fontWeight: 500,
            fontSize: "16px",
          }}
        >
          {t("Buy")}
        </Typography>,
      ];
      useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <>
      <Navbar />
      {/* <MenuBar /> */}
      <Box className="mainContainer">
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          sx={{ mb: 3, mt: 2 }}
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Box>
      <CommonBanner title={t("Buy")} />
      <BuyComponent />
      <Footer />
    </>
  )
}

export default Buy