import { Grid } from "@mui/material";
import React from "react";
import EmailIcon from "@mui/icons-material/Email";


//React-social-Share
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  TwitterIcon,
  EmailShareButton,
} from "react-share";


const SocialSharePopuporders = ({   description  }) => {
 
 
 
  const regex = /(http:\/\/|https:\/\/)((\w|=|\?|\.|\/|&|-)+)/g;
  const url = description.match(regex)[0];
  const text = description.replace(regex, '');
 

  return (
    <div>

      <Grid
        item
        xs={12}
        sx={{ mb: "20px", marginTop: "40px" }}
        className="borderBoxNone"
      >
        <Grid
          container
          justifyContent="center"
          sx={{ textAlign: "center" }}
          spacing={2}
        >
          <Grid item sm={2} md={2}>
            <FacebookShareButton  url={url} quote={`${text} ${url}`}>
              <FacebookIcon size={32} round={true} />
            </FacebookShareButton>
          </Grid>
          <Grid item sm={2} md={2}>
            <TwitterShareButton  url = {description.replace(/<[^>]+>/g, '')}>
              <TwitterIcon size={32} round={true} />
            </TwitterShareButton>
          </Grid>
          <Grid item sm={2} md={2}>
            <WhatsappShareButton
              url = {description.replace(/<[^>]+>/g, '')}
            >
              <WhatsappIcon size={32} round={true} />
            </WhatsappShareButton>
          </Grid>
         
          <Grid item sm={2} md={2}>
            <EmailShareButton  url = {description.replace(/<[^>]+>/g, '')}>
              <EmailIcon sx={{ fontSize: "32px" }} round={true} />
            </EmailShareButton>
          </Grid>
        </Grid>
      </Grid>

 
    </div>
  );
};

export default SocialSharePopuporders;
