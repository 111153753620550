import { Card, Grid, Pagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { Typography } from "@mui/material";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import http from "../../Services/http";
import ClipLoader from "react-spinners/ClipLoader";
import { NotificationManager } from "react-notifications";
import parse from "html-react-parser";
import {getLanguageSource} from '../../Services/translateString' 
 
import { ManagePageReloadState } from "../../Services/ManagePageReloadState";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import getSymbolFromCurrency from "currency-symbol-map";
import ModalPopup from "../ModalPopup";
import { styled } from '@mui/material/styles';

import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import {useTranslation} from 'react-i18next'
import "./style.css";
import { Stack } from "@mui/system";

const ViewAllSub = () => {
  const {t} = useTranslation("common")

          const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
});



  const location = useLocation();
  const Params = useParams();
   

  const navigate = useNavigate();
  // data.ele.category
  const eventnameViewall = location?.state?.ele?.category;
  const eventnameViewallhome = location?.state?.ele?.category_name;
  const eventname = eventnameViewall ? eventnameViewall : eventnameViewallhome;
  

  const [upcomingReviewData, setupcomingReviewData1] = useState([]);
  
  const [isLoading, setIsLoading] = useState(false);

  const [count, setCount] = React.useState(0);
  const [page, setPage] = React.useState(1);

  const [orders, setOrders] = useState([]);
  const [results, setResults] = useState([]);
  const [searchedWorker, setSearchedWorker] = useState("");
  const [filterEvents, setFilterEvents] = useState("all");
  const [totalEventCount , setTotalEventCount] = useState("")
  const dateFormated = (date) => {
    let dateNew = new Date(date);
    let day = dateNew.toLocaleString("en-us", { weekday: "short" });
    let newdate = new Date(date).getDate();
    let month = dateNew.toLocaleString("default", { month: "short" });
    return day + "," + newdate + " " + month;
  };
  const FreedateFormated = (startdate,enddate) => {
    let dateNew = new Date(startdate);

    let day = dateNew.toLocaleString("en-us", { weekday: "short" });
    let newdate = new Date(startdate).getDate();
    let month = dateNew.toLocaleString("default", { month: "short" });
    let time = dateNew.toLocaleString("en-us", { hour: "2-digit", minute: "2-digit"});
   

    let dateNewEnd = new Date(enddate);

    let dayend = dateNewEnd.toLocaleString("en-us", { weekday: "short" });
    let newdateend = new Date(enddate).getDate();
    let monthend = dateNewEnd.toLocaleString("default", { month: "short" });
    let timeend = dateNewEnd.toLocaleString("en-us", { hour: "2-digit", minute: "2-digit"});
// console.log( day + "," + newdate + " " + month+ " " + time + " - " + dayend + "," + newdateend + " " + monthend+ " " + timeend);
    return "Book within this timeframe for a Free Ticket! - "+day + "," + newdate + " " + month+ " " + time + " - " + dayend + "," + newdateend + " " + monthend+ " " + timeend;
  };

  const changePagination = ( e , page)=>{
    setIsLoading(true)
    page ? setPage(page) : setPage(1)

    let postData = {
      country_id: localStorage.getItem("autoCountry")
        ? JSON.parse(localStorage.getItem("autoCountry")).id
        : "",
      city: localStorage.getItem("SelectedCity")
        ? JSON.parse(localStorage.getItem("SelectedCity")).id
        : "",
      Keyword: "",
      event_type_id: Params.id,
      // page: "1",
      page: page ? page : 1,
    };
    http("events/event_by_city_id_and_event_type_for_website", "POST", postData)
      .then((res) => { 
        setIsLoading(false); 
        setTotalEventCount(res.data.total_rows)
        const data = res.data.data ?? [];
        setupcomingReviewData1(data ?? []);
        translateData(data)
        //setOrders(data);
      })
      .catch((err) => {});
  }
 
  const translateData = async(data)=>{
    setOrders([])
    setIsLoading(true)
    for(let idx=0; idx < data.length; idx++){
      //console.log("data",data)
      const qString = [
        data[idx].event_name || '',
        data[idx].venue_address || '',
        data[idx].event_type_name || '',
        data[idx].event_city_name || '',
        data[idx].state_name || '',
        data[idx].event_description || ''
      ]
      console.log("qString",qString)
      const translatedString = await getLanguageSource(qString)
        data[idx].event_name = translatedString[0]?.translatedText || data[idx].event_name
        data[idx].venue_address  = translatedString[1]?.translatedText || data[idx].venue_address
        data[idx].event_type_name = translatedString[2].translatedText || data[idx].event_type_name
        data[idx].event_city_name = translatedString[3]?.translatedText || data[idx].event_city_name
        data[idx].state_name = translatedString[4]?.translatedText ||  data[idx].state_name
        data[idx].event_description =  translatedString[5]?.translatedText || data[idx].event_description
        console.log(translatedString)
        if(idx === data.length-1){
          setIsLoading(false); 
          setOrders(data)
        }
    }
    
  }

  useEffect(()=>{
    console.log(orders)
    orders.length > 0  && translateData(orders)
  },[localStorage.getItem('lang')])

  const Subcategory = (page, data) => {
    setIsLoading(true);

    if (page) setPage(page);
    else setPage(1);
    

    let postData = {
      country_id: localStorage.getItem("autoCountry")
        ? JSON.parse(localStorage.getItem("autoCountry")).id
        : "",
      city: localStorage.getItem("SelectedCity")
        ? JSON.parse(localStorage.getItem("SelectedCity")).id
        : "",
      Keyword: "",
      event_type_id: Params.id,
       
      page: page ? page : 1,
    };
    http("events/event_by_city_id_and_event_type_for_website", "POST", postData)
      .then((res) => {
        
        setTotalEventCount(res.data.total_rows)
        setCount(Math.ceil(res.data.total_rows/ res.data.page_size));
        const data = res.data.data ?? [];
        setupcomingReviewData1(data ?? []);
        console.log(data)
        translateData(data)
        //setOrders(data);
      })
      .catch((err) => {});
  };

  // Module Hoc Function
  const [openLogin, setOpenLogin] = React.useState(false);
  const handleClose = () => {
    setOpenLogin(false);
  };

  // Fav api
  const Favourites = (e, data, params) => {
    if (!localStorage.getItem("token")) {
      setOpenLogin(true);
      return;
    }
    let postData = {
      eventid: data.id,
    };
    http("events/favourite_event", "POST", postData).then((res) => {
      if (
        res.data.msg === "success! event is added in your favourite section"
      ) {
        Subcategory();
        NotificationManager.success(res.data.msg, "", 800);
      } else {
        Subcategory();
        NotificationManager.success(
          "event is removed in your favourite section",
          "",
          800
        );
      }
    });
  };

  useEffect(() => {
    const filtered = orders.filter((order) =>
      order.event_name.toLowerCase().includes(searchedWorker)
    );
    setResults(filtered.reverse());
  }, [searchedWorker, orders]);

  {/* ////////////////////////////////////////////////////////// Maadima //////////////////////////////////////////// */}

  useEffect(() => {
      if(filterEvents == 'all'){
      setResults(orders);  }
      else  {
      const filtered = orders.filter(order => order.is_ticket_free == filterEvents)
      console.log(filtered);
      setResults(filtered);}

  }, [filterEvents, orders]);

  {/* ////////////////////////////////////////////////////////// Maadima //////////////////////////////////////////// */}




  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    Subcategory(1, ""); 
    ManagePageReloadState.getPageReloadState().subscribe((res) => {
      if (res.state) {
        Subcategory(1, "")
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);



  const HandleClick = (e, id , event_name) => {

    let modifyEventName = event_name.replaceAll(/[^a-zA-Z0-9 ]/g , "")

    navigate(`/events/${modifyEventName.replaceAll(" ", '-')}/` + id);
  };
  return (
    <Box className="mainContainer">
      <Grid container alignItems="center">
        <Grid item xs={12} sm={6} md={6}>
          <Typography className="layoutTitle d-flex justify-align-item">
            {eventname ? t(eventname) : ""}
          </Typography>
        </Grid>

       
 {/*//////////////////////////////////////////////////////////Maadima////////////////////////////////////////////*/}

         <Grid item xs={6} sm={3} md={3} className="searchEventFav">
          <div>
           <select className="select-state inputSearch"  onChange={(e) => setFilterEvents(e.target.value)}
               >
              <option value="all">{t('all_events')}</option>
              <option value="0">{t('paid_events')}</option>
              <option value="1">{t('free_events')}</option>
              </select>
          
          </div>
        </Grid>
 {/*/////////////////////////////////////////////////////////// Maadima /////////////////////////////////////////////*/}
       
        <Grid item xs={6} sm={3} md={3} className="searchEventFav">
          <div>
            <input
              type="text"
              className="input-bordered inputSearch"
              style={{ float: "right" }}
              placeholder={t('Search_by_Event_Name')}
              value={searchedWorker} //property specifies the value associated with the input
              onChange={(e) => setSearchedWorker(e.target.value.toLowerCase())}
              
            />
          </div>
        </Grid>
       
        <div className="hLine"></div>
      </Grid>


      {isLoading ? (
        <div style={{ height: "400px" }}>
          <div className="loader ">
            <ClipLoader />
          </div>
        </div>
      ) : (   
      <Grid
        container
        spacing={4}
        sx={{ marginTop: "0px", marginBottom: "40px" }}
      >
        <>

          {results.length > 0 ? (
            results.map((ele) => {

            
              return (
                <>
                  <Grid key={ele.event_id} item xs={12} sm={12} md={6} lg={4}>
                    <Card
                      className="favoriteCard"
                      style={{ cursor: "pointer" }}
                    >
                      { ele.eventExpireFlag ?  <img
                      alt="expired event"
                      className="expired-event"
                      src="assets/icons/expired-event.png"  
                    /> : <></>}
                      <Box className="flexGrid">
                        <item
                          className="w-45"
                          onClick={(e) => HandleClick(e, ele.id , ele.event_name)}
                        >
                          <img
                            src={
                              ele.event_thumbnail
                                ? ele.event_thumbnail
                                : "assets/images/favorite.png"
                            }
                            alt="rescheduled"
                          />
                        </item>
                        <item className="w-55">
                          <div className="d_flexFav">
                            <Typography
                              className="bookingTitle"
                              onClick={(e) => HandleClick(e, ele.id , ele.event_name)}
                            >
                              {ele.event_name}
                            </Typography>

                            {ele.favourite === 1 ? (
                              <FavoriteIcon
                                className="fav_Icon"
                                onClick={(e) => Favourites(e, ele)}
                              />
                            ) : ele.favourite === 0 ? (
                              <FavoriteBorderIcon
                                style={{
                                  color: "#000",
                                }}
                                //className="fav_Icon"
                                onClick={(e) => Favourites(e, ele)}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                          <div onClick={(e) => HandleClick(e, ele.id , ele.event_name)}>
                            <Typography className="bookingAddressBox">
                              <img src="assets/images/booking-icon/calendar-icon.png"  alt="calendar" style={{ marginRight: "23px" }} />
                              <Typography className="bookingAddress">
                                {dateFormated(
                                  ele.event_start_date_time
                                    ? ele.event_start_date_time
                                    : ele.tentative_date
                                )}
                              </Typography>
                            </Typography>
                            <Typography className="bookingAddressBox">
                              <img
                                src="assets/images/booking-icon/map-pin.png"
                                alt="map pin"
                                style={{ marginRight: "23px" }}
                              />
                              <Typography className="bookingAddress word-ellipsis">
                                {ele.venue_address
                                  ? ele.venue_address
                                  : ele.country_name}
                                ,
                                {ele.event_city_name
                                  ? ele.event_city_name
                                  : ele.state_name}
                              </Typography>
                            </Typography>
{/*///////////////////////////////////////////////////////////////////////////////Maadima.com/////////////////////////////*/}
                          { ele.is_ticket_free ?   <Typography
                              className=" "
                              sx={{ fontSize: "14px!important",fontWeight: "200 !important",color:"#686868 !important" }}
                            >
                             <Typography className=""> {t("FREE_SLOT_AVAILABLE")} 
                                <CustomWidthTooltip title={FreedateFormated(ele.free_booking_start,ele.free_booking_end)}
            >
                                      <img
                                      src="assets/icons/info.png"
                                      alt="info"
                                      style={{ marginRight: "45px" }}
                                    />
                                </CustomWidthTooltip>


                             </Typography>
                             </Typography> 
                     : <></>}





{/*///////////////////////////////////////////////////////////////////////////////Maadima.com/////////////////////////////  */
}                           

                            <Typography
                              className="bookingOrderNo colorBlue"
                              sx={{ fontWeight: "700 !important" }}
                            >
                              <span className="colorDarkBlue">{t("Price")}:</span>
                              {getSymbolFromCurrency(ele.currency)}{" "}
                              {ele.minprice} {t("Onwards")}
                            </Typography>
                          </div>
                        </item>
                      </Box>
                      <div className="hLine"></div>
                      <Typography
                        className="favoriteText"
                        onClick={(e) => HandleClick(e, ele.id ,ele.event_name)}
                      >
                        {parse(ele.event_description.substring(0, 100)+ "...")} 
                      </Typography>
                    </Card>
                  </Grid>
                </>
              );
            })
          ) : (
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography
                className="layoutTitle "
                sx={{ textAlign: "center", height: "400px" }}
              >
                {t("No_Event_found")}
              </Typography>
            </Grid>
          )}
        </>
      </Grid>
      
)}
<Box>
 
{parseInt(totalEventCount)> 10 ?
<Grid
              container
              justifyContent="center"
              flexWrap="wrap"
              sx={{ padding: "30px inherit !important" }}
            >
              <Stack spacing={2} className="customPagination">
                <Pagination count={count}page={page} onChange={changePagination} />
              </Stack>
            </Grid>
           :<></> }
</Box>
      {openLogin ? (
        <ModalPopup handleOpen={openLogin} handleClick={handleClose} />
      ) : (
        ""
      )}
    </Box>
  );
};

export default ViewAllSub;
