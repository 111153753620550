import React, { useEffect } from "react";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import SeatBookingComponent from "../../Components/SeatBookingComponent"
import { Link } from "react-router-dom";
import { Breadcrumbs, Typography } from "@mui/material";
import { Box } from "@mui/system";
import "./style.css";

const SeatBookingIo = () => {
    const breadcrumbs = [
        <Link to={"/"}  key="1" className="link-decoration">
          Home  Phomolong Comes Alive with Mashabela  Book Ticket
        </Link>,
    
        <Typography
          key="3"
          sx={{
            fontFamily: "Lato",
            color: "#0CA8F6",
            fontWeight: 500,
            fontSize: "16px",
          }}
        >
          Select Seat
        </Typography>,
      ];
      useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      return (
        <>
          <Navbar />
          {/* <MenuBar /> */}
          <Box className="mainContainer">
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
              sx={{ mb: 3, mt: 2 }}
            >
              {breadcrumbs}
            </Breadcrumbs>
          </Box>
         <SeatBookingComponent />
          <Footer />
        </>
      );
    };

export default SeatBookingIo