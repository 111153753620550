import React, {   useState } from "react";
import { debounce } from "lodash";
import SearchField from "./SearchField";
import List from "./ListItems";
// import { useDetectClickOutside } from 'react-detect-click-outside';
import { CommunicationService } from "../../../Services/CommunicationService";
// import { Box } from "@mui/material";
 
  
const DEBOUNCE = 1000;

/**
 * This the search function we need to fetch data from the API using axios
 * Also we need to set the value of isLoading to indicate to the user that the results are being fetched
 * and once the fetching is done we need to set the response data as our search results so they can be consumed by the component
 * @param {*} queryParam the search string
 * @param setResults a function to set update the state of the component with search result
 * @param setIsloading a function to control the loading state
 * @param location the location in which we can search
 */

const searchFun = (queryParam = "", setResults = [], setIsLoading = "false", location = "", data=[]) => {
  const newItem = data.filter((newVal) => {
    // debugger
    return newVal.country_name.toLowerCase().indexOf(queryParam) > -1
        // comparing for displaying data
  });
  setResults(newItem);
};

/**
 * This is the debounced function that we will run once the user hit a key
 * lodash debounce return a function, that can be invoked any time
 * this function takes a function, searchFunction, and a debounce time
 * in this way we guarantee that the we only fetch after certin time and we don't spam the api with calls every time the user hits a key
 */
const debouncedSearch = debounce(searchFun, DEBOUNCE);
/**
 * searchFun and debouncedSearch can live outside the component
 * we don't need to assign them whenever the component rerender
 * which in this case on every state change
 * They still can work as fine, but it is simply not necessary
 */

export default function AutoComplete(props) {
  const [results, setResults] = useState(props.list ?? []);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState(props.data)
  
  // const [ishidelist , setHidelist] = useState(false)
  // const closeDropdown  = ()=>{ 
  // setHidelist(true)
  // }
  // const ref = useDetectClickOutside({ onTriggered: closeDropdown });
 

  const onSerach = (v) => {
    const search = debouncedSearch;
    if (!v) {
      // when the user clear the field we don't want to perform a search, we need to clear the state and do nothing else
      debouncedSearch.cancel();
      setResults(props.list);
      setIsLoading(false);
      setSelectedValue("")
    } else {
      //setIsLoading(true);
      search(v, setResults, setIsLoading, props.location, results);
      setSelectedValue(v)

    }
  };

  const onSelect = (selectedValue) =>{
    setResults([]); 
    setSelectedValue(selectedValue) 
    // props.selectedData(selectedValue)
   CommunicationService.setData(selectedValue)  
  }

  const onBlur = () =>{
    setResults([]);
    setSelectedValue(selectedValue)
   
  }

  return (
    <>
      <SearchField selectedValue={selectedValue} onSearch={onSerach} isLoading={isLoading} onBlur={onBlur}/>
      {!!results.length && <List  items={results} onSelect={(i) => onSelect(i)} />}
    </>
  );
};

