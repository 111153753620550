import React, { useEffect } from 'react'
import MenuBar from "../../Components/MenuBar";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import PartnershipComponent from "../../Components/PartnershipComponent"
import { Box } from "@mui/system";
import PageNotFoundComponent from "../../Components/PageNotFoundComponent";
import "./style.css";

const PageNotFound = () => {
  return (
    <>
    <Navbar />
    {/* <MenuBar /> */}
    <Box className="mainContainer">
     
    </Box>
   
    <PageNotFoundComponent />
    <Footer />
  </>
  )
}

export default PageNotFound