import { Grid } from "@mui/material";
import { Box } from "@mui/material";
import { Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import InputAdornment from "@mui/material/InputAdornment";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { SpinnerCircular } from "spinners-react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Checkbox } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import "./style.css";
import checkValidationFormAllControls from "../../shared/validation";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import http from "../../Services/http";
import { NotificationManager } from "react-notifications";
import CCSearch from "../../shared/CCSearch";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Autocomplete from '@mui/material/Autocomplete';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Input = styled("input")({
  display: "none",
});

const Contestants = () => {
  const navigate = useNavigate();
  let userDetails = localStorage.getItem("loginUser") ? JSON.parse(localStorage.getItem("loginUser")) : {};
  const Fname = userDetails.fullname ? userDetails.fullname : "";
  const getuseremail = userDetails.email ? userDetails.email : "";
  const getuserphone = userDetails.phone ? userDetails.phone : "";
  const getusercountrycode = userDetails.country_code ? userDetails.country_code : ""; 

  // const Fname = localStorage.getItem("fullname");
  const [first, last] = Fname.split(" ");

  const [loading, setLoading] = useState(false);

  const params = useParams();
  // const location = useLocation()

  const [checked, setChecked] = useState(false);
  const [name, setName] = React.useState(first);
  const [surname, setSurname] = React.useState(last);
  const [stage_name, setStage_name] = React.useState("");
  const [brand_name, setBrand_name] = React.useState("");
  const [age, setage] = React.useState("");
  const [gender, setGender] = React.useState("");
  const [town, setTown] = React.useState("");
  const [district_id, setDistrict_id] = React.useState("");
  const [district_name, setDistricName] = React.useState("");
  const [email_address, setEmail_address] = React.useState(getuseremail);
  const [phone_number, setPhone_number] = React.useState(getuserphone);
  const [number_of_person, setNumber_of_person] = React.useState(16);
  const [audio_file, setAudio_file] = React.useState("");
  const [video_file, setVideo_file] = React.useState("");
  const [postImage, setPostImage] = useState({
    myFile: "",
  });
  const [postVideo, setPostVideo] = useState({
    myFile: "",
  });
  const [picture_file, setPicture_file] = React.useState("");
  const [errorDisplay, seterrorDisplay] = useState("");
  const [district, setDistrict] = useState([]);
  const [lengthNumError, setlengthNumError] = useState(false);
  const [startDate, setStartDate] = useState(new Date());

  //get selected country function 
  const countryList = require("../../countries.json");
  const [value, setValue] = useState(getusercountrycode) 
   // console.log(localStorage.getItem("DetailsEvent")); 
// console.log(localStorage.getItem("DetailsEvent"));

  // Autoc
  let Autocountry = localStorage.getItem("autoCountry")
    ? JSON.parse(localStorage.getItem("autoCountry"))
    : {};

  let picture_file_validation = localStorage.getItem("picture_file_validation")
    ? localStorage.getItem("picture_file_validation")
    : 'off';
 let video_file_validation = localStorage.getItem("video_file_validation")
    ? localStorage.getItem("video_file_validation")
    : 'off';
 let audio_file_validation = localStorage.getItem("audio_file_validation")
    ? localStorage.getItem("audio_file_validation")
    : 'off';

const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertToBase64(file);
    setPostImage({ ...postImage, myFile: base64 });
  };

const handleVideoUpload = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertToBase64(file);
    setPostVideo({ ...postVideo, myFile: base64 });
  };


  const handleChange1 = (e, key) => {
console.log(key);
    if (key === "audio_file") {
      setAudio_file(e.target.files[0]);
    } else if (key === "audio_file") {
      setVideo_file(e.target.files[0]);
    } else if (key === "Picture_file") {
    // alert("32132131312");
      setPicture_file(e.target.files[0]);
    }

    const target = e.target;
    if (target.files && target.files[0]) {
      const maxAllowedSize = 2 * 1000000;
      if (target.files[0].size > maxAllowedSize) {
        NotificationManager.error("File size must under 2MB!");
        target.value = "";
      }
    }
console.log(e);
  };
  useEffect(() => {
    District();
  }, []);

  // Get District Value

  const District = () => {
    http("talent_participation_events/talent_participation_district", "GET")
      .then((res) => {

        setDistrict(res.data.data);

      })
      .catch((error) => { });
  };

  const erroronChange = (e) => {
    var objValidation = {};
    if (phone_number.length < 8 && phone_number.length > 1) {
      setlengthNumError(true);
    } else {
      setlengthNumError(false);
    }
    objValidation = checkValidationFormAllControls(
      document.forms[0].elements,
      false,
      []
    ); 
    // if (objValidation.valid !== false) {
    //   seterrorDisplay(objValidation);
    //   return;
    // } else {
    //   seterrorDisplay(objValidation);
    // }

  };

  //

  const [open, setOpen] = React.useState(false);
  const [openstatus, setOpenstatus] = React.useState(false);
  const [alertmssg, setAlertmssg] = React.useState("");
  const [ageValErrorDisplay, setAgeValErrorDisplay] = useState('')
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  // register event Api

  const handleSubmit = (event) => {
    // console.log(getusercountrycode ,'upper sub')
    // console.log(value !==  getusercountrycode?value.slice(4): getusercountrycode ,'sub value')
    event.preventDefault();
    var objValidation = {};
    objValidation = checkValidationFormAllControls(
      document.forms[0].elements,
      false,
      []
    );
    if (objValidation.valid !== false) {
      seterrorDisplay(objValidation);
      return;
    } else {
      seterrorDisplay(objValidation);
    }

    // if (age >= 16 && age <= 30) {
    //   objValidation.valid = true;
    // } else {
    //   objValidation.age = "Age Only Allowed Between 16 to 30";
    //   objValidation.valid = false;
    //   seterrorDisplay(objValidation);
    //   return;
    // }

    if (phone_number.length >= 8) {
      objValidation.valid = true;
    } else {
      objValidation.valid = false;
      setlengthNumError(true);
      event.preventDefault();
      return;
    }
    // setLoading(true);
    event.preventDefault();
    const formData = new FormData();

    //formData.append("report_name", data.get("file"));
    formData.append("name", name);
    formData.append("surname", surname);
    //formData.append("stage_id", stage_id);
    formData.append("stage_name", brand_name);
    formData.append("age", age);
    formData.append("gender", gender);
    formData.append("town", town);
    // formData.append("district_id", district_id);
    formData.append("email_address", email_address);
    formData.append("phone_country_code", (value !== getusercountrycode?value.slice(4): getusercountrycode));
    
    formData.append("phone_number", phone_number);
    formData.append("number_of_person", number_of_person);
    formData.append("event_id", params.id);
    formData.append("district_id", district_id);
    formData.append("audio_file", audio_file);
    formData.append("video_file", postVideo.myFile);
    formData.append("picture_file", postImage.myFile);
    formData.append("dob", startDate);
    formData.append("web", 1);
    //alert(postImage.myFile);
 
    http(
      "talent_participation_events/talent_participation_registration",
      "POST",
      formData,
      "",
      true
    )
      .then((res) => res.data)

      .then((res) => {
        setLoading(false);
        if (res.status === 1) {
          setOpen(true);
          setAlertmssg(res.msg);
          NotificationManager.success(res.msg)
          setOpenstatus(true);
          navigate(
            `/events/${params.ename}/${params.id}` +
            "?ts=ts&flag=" +
            Autocountry.sortname,
            { replace: true }
          );
        } else {
          setAlertmssg(res.msg);
          setOpen(true);
          setOpenstatus(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };



  const Districtname = (e) => {
    setDistrict_id(e.target.value.id);
    setDistricName(e.target.value.district_name);
  };



  // ---------------------------**********-------------------------------

  return (
    <Box className="mainContainer">
      <Grid container spacing={4}>
        <Grid item xs={12} md={12}>
          <Typography className="layoutTitle">Contestants Form</Typography>
          <div className="hLine"></div>
        </Grid>
      </Grid>
      <form name="concents_form" noValidate>
        <Box
          className="profileModel"
          sx={{ marginTop: "0px", display: 'flex', justifyContent: 'space-between' }}
        >
          <Box
            item
            xs={12}
            sm={6}
            md={3}
            sx={{ paddingBottom: "30px" }}
            className="constant_img newWidth"
          >
            <img src="assets/images/constantImg.png" alt="constant_img" />
          </Box>
          <Box item xs={12} sm={6} md={9} sx={{ paddingBottom: "30px" }} className="secWidth">
            <Grid
              container
              spacing={4}
              className="profileModel"
              sx={{ marginTop: "0px" }}
            >
              <Grid item xs={12} sm={6} md={6} sx={{ paddingBottom: "30px" }}>
                <Typography className="profleFormLabel">
                  First Name <span className="colorRed">*</span>{" "}
                </Typography>
                <TextField
                  placeholder="First Name"
                  fullWidth
                  name="first_name"
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        className="profleFormIcon"
                      >
                        <img src="assets/images/userIcon.png" alt="userIcon" />
                      </InputAdornment>
                    ),
                  }}
                  variant="standard"
                  className="profleFormInput"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                    erroronChange(e);
                  }}
                  inputProps={{
                    maxLength: 30,
                  }}
                />
                {errorDisplay.first_name ? (
                  <span className="red">{errorDisplay.first_name}</span>
                ) : (
                  ""
                )}
              </Grid>

              <Grid item xs={12} sm={6} md={6} sx={{ paddingBottom: "30px" }}>
                <Typography className="profleFormLabel">
                  Last Name <span className="colorRed">*</span>{" "}
                </Typography>
                <TextField
                  id="input-with-icon-textfield"
                  placeholder="Last name"
                  fullWidth
                  name="lastname"
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        className="profleFormIcon"
                      >
                        <img src="assets/images/userIcon.png" alt="userIcon" />
                      </InputAdornment>
                    ),
                  }}
                  value={surname}
                  onChange={(e) => {
                    setSurname(e.target.value);
                    erroronChange(e);
                  }}
                  inputProps={{
                    maxLength: 30,
                  }}
                  variant="standard"
                  className="profleFormInput"
                />
                {errorDisplay.lastname ? (
                  <span className="red">{errorDisplay.lastname}</span>
                ) : (
                  ""
                )}
              </Grid>

              <Grid item xs={12} sm={6} md={6} sx={{ paddingBottom: "30px" }}>
                <Typography className="profleFormLabel">
                  Brand Name <span className="colorRed">*</span>{" "}
                </Typography>
                <TextField
                  id="input-with-icon-textfield"
                  placeholder="Brand Name"
                  type="text"
                  fullWidth
                  name="brand_name"
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        className="profleFormIcon"
                      >
                        <img src="assets/images/userIcon.png" alt="userIcon" />
                      </InputAdornment>
                    ),
                  }}
                  variant="standard"
                  className="profleFormInput"
                  value={brand_name}
                  onChange={(e) => {
                    setBrand_name(e.target.value);
                    erroronChange(e);
                  }}
                  inputProps={{
                    maxLength: 30,
                  }}
                />
                {errorDisplay.brand_name ? (
                  <span className="red">{errorDisplay.brand_name}</span>
                ) : (
                  ""
                )}
              </Grid>

              <Grid item xs={12} sm={6} md={6} sx={{ paddingBottom: "30px" }}>
               
               
 <Typography className="profleFormLabel">
                  {"Date of Birth"} <span className="colorRed">*</span>{" "}
                </Typography>
                 <DatePicker  name="dob"className="profleFormLabel" selected={startDate} onChange={(date) => setStartDate(date)} />
 
                {errorDisplay.age ? (
                  <span className="red">{errorDisplay.age} {ageValErrorDisplay}</span>
                ) : (
                  <span className="red">  {ageValErrorDisplay}</span>
                )}
              </Grid>

              <Grid item xs={12} sm={6} md={6} sx={{ paddingBottom: "30px" }}>
                <Typography className="profleFormLabel">
                  Email Id <span className="colorRed">*</span>{" "}
                </Typography>
                <TextField
                  id="input-with-icon-textfield"
                  placeholder="Email Address"
                  fullWidth
                  type="email"
                  name="email"
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        className="profleFormIcon"
                      >
                        <img
                          src="assets/images/message-icon.png"
                          alt="message-icon"
                        />
                      </InputAdornment>
                    ),
                  }}
                  variant="standard"
                  className="profleFormInput"
                  value={email_address}
                  onChange={(e) => {
                    setEmail_address(e.target.value);
                    erroronChange(e);
                  }}
                />
                {errorDisplay.email ? (
                  <span className="red">{errorDisplay.email}</span>
                ) : (
                  ""
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={6} sx={{ paddingBottom: "30px" }}>
                <Typography className="profleFormLabel">
                  Phone Number <span className="colorRed">*</span>{" "}
                </Typography>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "baseline",
                  }}
                >
                  <Box sx={{ minWidth: 120, marginRight: "25px" }}>



                    <Autocomplete
                      id="country-select-demo"
                      disableClearable
                      sx={{ width: 135 }}
                      options={countryList}  
                      value= {value}
                      className='custom-CCSearch'
                      onChange={(event, value) => {
                        setValue(value ? value.emoji + ' +' + value.phone : '');
                      }}
                      ListboxProps={
                        {
                          style: {
                            maxHeight: '190px',
                            width: 'auto'
                          }
                        }
                      }
                      autoHighlight
                      loading={true}
                      getOptionLabel={(option) => option.phone ? '+'+option.phone + option.country_name : value}


                      renderInput={(params) => (
                        <TextField
                          {...params}
                          // label="Choose a country"
                          value={value}

                          sx={{ background: 'white' }}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password', // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />




                    {/* <CCSearch getSelCountry={getSelCountry} /> */}
                    {/* <p>{selCountryCode}</p> */}
                  </Box>

                  <TextField
                    placeholder="Phone Number"
                    fullWidth
                    name="phone_number"
                    type="number"
                    id="phone"
                    required
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          className="profleFormIcon"
                        >
                          <img
                            src="assets/images/phone-icon.png"
                            alt="phone-icon"
                          />
                        </InputAdornment>
                      ),
                    }}
                    variant="standard"
                    className="profleFormInput"
                    value={phone_number}
                    error={lengthNumError}
                    helperText={
                      lengthNumError
                        ? "Number length must be greater or equal to 8 digit"
                        : ""
                    }
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 15);
                    }}
                    onChange={(e) => {
                      setPhone_number(e.target.value);
                      erroronChange(e);
                    }}
                  />
                </div>
                {errorDisplay.countryselector ? (
                  <span className="red">{errorDisplay.countryselector}</span>
                ) : (
                  ""
                )}
                <br />
                {errorDisplay.phone_number ? (
                  <span className="red" style={{ marginLeft: "150px" }}>
                    {errorDisplay.phone_number}
                  </span>
                ) : (
                  ""
                )}
              </Grid>

              <Grid item xs={12} sm={6} md={6} sx={{ paddingBottom: "30px" }}>
                <FormControl>
                  <Typography className="profleFormLabel">
                    Gender <span className="colorRed">*</span>{" "}
                  </Typography>

                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="gender"
                    required
                    value={gender}
                    onChange={(e) => {
                      setGender(e.target.value);
                      erroronChange(e);
                    }}
                  >
                    <FormControlLabel
                      value="0"
                      control={
                        <Radio required name="gender" className="colorBlue" />
                      }
                      label="Male"
                    />
                    <FormControlLabel
                      value="1"
                      control={<Radio className="colorBlue" />}
                      label="Female"
                    />
                    <FormControlLabel
                      value="2"
                      control={<Radio className="colorBlue" />}
                      label="Other"
                    />
                  </RadioGroup>
                  {errorDisplay.gender ? (
                    <span className="red">{errorDisplay.gender}</span>
                  ) : (
                    ""
                  )}
                </FormControl>
              </Grid>

            {/*  <Grid item xs={12} sm={6} md={6} sx={{ paddingBottom: "30px" }}>
                <FormControl>
                  <Typography className="profleFormLabel">
                    Number Of Person <span className="colorRed">*</span>{" "}
                  </Typography>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    className="flexGrid"
                    name="number_of_person"
                    value={number_of_person}
                    onChange={(e) => {
                      setNumber_of_person(e.target.value);
                      erroronChange(e);
                    }}
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio required className="colorBlue" />}
                      label="Single"
                    />
                    <FormControlLabel
                      value="2"
                      control={<Radio required className="colorBlue" />}
                      label="Duo"
                    />
                  </RadioGroup>
                  {errorDisplay.number_of_person ? (
                    <span className="red">{errorDisplay.number_of_person}</span>
                  ) : (
                    ""
                  )}
                </FormControl>
              </Grid>
*/}
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                sx={{ paddingBottom: "30px" }}
                className="newGridTopPadding"
              >
                <Typography className="profleFormLabel paddinfBtnDis">
                  District <span className="colorRed">*</span>
                </Typography>
                <div>
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth className="cnstFormPostion">
                      <img
                        src="assets/images/country-icon.png"
                        alt="country"
                        className="cnstFormPostionImgABs"
                      />
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={district_name}
                        size="small"
                        name="district"
                        required
                        fullWidth
                        className="countryCode profleFormSelect NewcountryCode"
                        sx={{ background: "#fff", height: "38px" }}
                        onChange={(e) => {
                          Districtname(e);
                          erroronChange(e);
                        }}
                      >
                        {district.map((e) => (
                          <MenuItem value={e}>{e.district_name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {errorDisplay.district_name ? (
                      <span className="red">{errorDisplay.district_name}</span>
                    ) : (
                      ""
                    )}
                  </Box>

                  {errorDisplay.district ? (
                    <span className="red">{errorDisplay.district}</span>
                  ) : (
                    ""
                  )}
                </div>
              </Grid>

              <Grid item xs={12} sm={6} md={6} style={{ paddingTop: "0px" }}>
                <Typography className="profleFormLabel">
                  Town <span className="colorRed">*</span>
                </Typography>
                <TextField
                  id="input-with-icon-textfield"
                  placeholder="Town"
                  fullWidth
                  name="town"
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        className="profleFormIcon"
                      >
                        <img src="assets/images/home.png" alt="home" />
                      </InputAdornment>
                    ),
                  }}
                  variant="standard"
                  className="profleFormInput"
                  value={town}
                  onChange={(e) => {
                    setTown(e.target.value);
                    erroronChange(e);
                  }}
                  inputProps={{
                    maxLength: 150,
                  }}
                />
                {errorDisplay.town ? (
                  <span className="red">{errorDisplay.town}</span>
                ) : (
                  ""
                )}
              </Grid>
              <Grid>
               <Box className="VideoFileFlex">
                  <Typography className="profleFormLabel">
                  
                  </Typography>
                  </Box>

                  <Box className="audioBox">
                  <label htmlFor="icon-button-file">
                  </label>
                  </Box>
              </Grid>
             
 {audio_file_validation == "on" ? ( <Grid item xs={12} sm={6} md={6}>
                <Box className="VideoFileFlex">
                  <Typography className="profleFormLabel">
                    Audio File *
                  </Typography>
                  <Typography className="maximumUpload">
                    {" "}
                    <p>{audio_file}</p>
                    <span className="colorRed">*</span> maximum 2 Mb size
                  </Typography>
                </Box>
                <Box className="audioBox">
                  <label htmlFor="icon-button-file">
                    <Input
                      accept=".mp3"
                      type="file"
                      name="audio_file"
                      required
                      // onChange={(e) => handleChange1(e, "audio_file")}
                      onChange={(e) => {
                        handleChange1(e, "audio_file");
                        setAudio_file(e.target.value);
                        erroronChange(e);
                      }}
                      id="icon-button-file"
                    />

                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                      className="audioUpload"
                    >
                      <img src="assets/images/audio.png" alt="audio" />
                    </IconButton>
                  </label>
                </Box>

                {errorDisplay.audio_file ? (
                  <span className="red">{errorDisplay.audio_file}</span>
                ) : (
                  ""
                )}
              </Grid>):("")}

              {/* const [video_file, setVideo_file] = React.useState(""); */}
              {/*   const [audio_file, setAudio_file] = React.useState(""); */}
 {video_file_validation == "on" ? (<Grid item xs={12} sm={6} md={6}>
                <Box className="VideoFileFlex">
                  <Typography className="profleFormLabel">
                    Video File *
                  </Typography>
                  <Typography className="maximumUpload">
                    {" "}
                    <p>{video_file}</p>
                    <span className="colorRed">*</span> maximum 2 Mb size
                  </Typography>
                </Box>
                <Box className="audioBox">
                  <label htmlFor="icon-button-file1">
                    <Input
                      accept=".mp4"
                      type="file"
                      name="video_file"
                      id="icon-button-file1"
                      required
                      value={video_file}
                      onChange={(e) => {
                        handleVideoUpload(e);
                        setVideo_file(e.target.value);
                        erroronChange(e);
                      }}
                    />
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                      className="audioUpload"
                    >
                      <img src="assets/images/vedio.png" alt="vedio" />
                    </IconButton>
                  </label>
                </Box>

                {errorDisplay.video_file ? (
                  <span className="red">{errorDisplay.video_file}</span>
                ) : (
                  ""
                )}
              </Grid>):("")}
 {picture_file_validation == "on" ? (        
   <Grid item xs={12} sm={6} md={6}>
              
                <Box className="VideoFileFlex">
                  <Typography className="profleFormLabel">
                    Picture File *
                  </Typography>
                  <Typography className="maximumUpload">
                    {" "}
                    <p>{picture_file}</p>
                    <span className="colorRed">*</span> maximum 2 Mb size
                  </Typography>
                </Box> <Box className="audioBox">
                  <label htmlFor="icon-button-file2">
                    <Input accept=".jpeg, .png, .jpg" required type="file" src={picture_file} name="picture_file" id="icon-button-file2" value={picture_file} onChange={(e) => {
                        handleFileUpload(e);
                        setPicture_file(e.target.value);
                        erroronChange(e);
                      }}
                    />
                    <IconButton color="primary" aria-label="upload picture" component="span" className="audioUpload"
                    >
                      <img src="assets/images/pic.png" alt="vedio" />
                    </IconButton>
                  </label>
                </Box>

                {errorDisplay.picture_file ? (
                  <span className="red">{errorDisplay.picture_file}</span>
                ) : (
                  ""
                )}
              </Grid>):("")}

              {/*-----------------check box field-------------*/}
              <Grid item xs={12} style={{ paddingTop: "20px" }}>
                <FormControlLabel
                  label="I  am Agree to Terms and Conditions"
                  control={<Checkbox />}
                  checked={checked}
                  onClick={() => setChecked(!checked)}
                />
              </Grid>

              <Grid
                item
                xs={12}
                md={12}
                sx={{
                  justifyContent: "center",
                  display: "flex",
                  marginTop: "50px",
                  marginBottom: "50px",
                }}
              >
                <Stack spacing={2} sx={{ width: "100%" }}>
                  {checked ? (
                    <Button
                      disabled={!checked || loading}
                      type="submit"
                      className="updateBtn"
                      onClick={(e) => handleSubmit(e)}
                    >
                      {loading ? (
                        <SpinnerCircular
                          style={{ width: "1.2rem", height: "1.2rem" }}
                          color="light"
                        />
                      ) : (
                        " Submit"
                      )}
                    </Button>
                  ) : (
                    <Button
                      type="submit"
                      disabled={true}
                      className="updateBtn1"
                    >
                      Submit
                    </Button>
                  )}
                  <Snackbar
                    open={open}
                    autoHideDuration={2000}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  >
                    <Alert
                      onClose={handleClose}
                      severity={openstatus ? "success" : "error"}
                      sx={{ width: "100%" }}
                    >
                      {alertmssg}
                    </Alert>
                  </Snackbar>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default Contestants;
