
const dev = {
  apiGateway: {
    //https://thingproxy.freeboard.io/fetch/
    // URL: "https://staging.ticketkore.io/api/",

    URL: "https://staging.ticketkore.io/api/",
    VERSION: "v16/",
  },
};

const prod = {
  //https://dev.ticketkore.io/
  //http://49.50.69.229/ticketkore/
  // https://ticketkore.io/admin/api/v9/
  apiGateway: {

    URL: "https://ticketkore.io/admin/api/",
    // URL: "https://staging.ticketkore.io/api/",
    VERSION: "v16/",
  },
};

const config = process.env.NODE_ENV === "production" ? prod : dev;

const configObj = {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config,
};
export default configObj;
