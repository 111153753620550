import React, { useEffect } from "react";

//components
import Celebration from "../../Components/Celebration/Index";
import Navbar from "../../Components/Navbar";
import Offer from "../../Components/Offer";
import UpcomingEvent from "../../Components/UpcomingEvent";
import TalentedParticipation from "../../Components/TalentedParticipation";
import Comingsoon from "../../Components/ComingSoon";
import Footer from "../../Components/Footer";

import PrivateEvent from "../../Components/PrivateEvent";
import AllEvents from "../../Components/AllEventComponent";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
// React Router
import { useLocation } from "react-router-dom";

const Home = () => {
  const isPrivateEvent = localStorage.getItem("token");
  const location = useLocation();

  useEffect(() => {}, [location]);

  return (
    <>
      <Navbar />
      
      <AllEvents />
      <UpcomingEvent />
      <Comingsoon />
      <Celebration />
      {isPrivateEvent ? <PrivateEvent /> : ""}
      <TalentedParticipation />

      <Offer />
      <Footer />
    </>
  );
};

export default Home;
