import React, { useEffect } from "react";
import Navbar from "../../Components/Navbar";
import MenuBar from "../Menu";
//import BreadCrumbs from '../../Components/BreadCrumbs';
import CommonBanner from "../../Components/CommonBanner";
import Policy from "../../Components/Policy";
import Footer from "../../Components/Footer";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link } from "react-router-dom";
import { Breadcrumbs, Typography } from "@mui/material";
// import BottomScroll from "../../Components/BottomScroll";
import { Box } from "@mui/system";
import "./style.css";

const PrivacyPolicy = () => {
  const breadcrumbs = [
    <Link to={"/"}  key="1" className="link-decoration">
      Home
    </Link>,

    <Typography
      key="3"
      sx={{
        fontFamily: "Lato",
        color: "#0CA8F6",
        fontWeight: 500,
        fontSize: "16px",
      }}
    >
      Privacy Policy
    </Typography>,
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Navbar />
      {/* <MenuBar /> */}
      <Box className="mainContainer">
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          sx={{ mb: 3, mt: 2 }}
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Box>
      <CommonBanner title={"Privacy Policy"} />
      {/* <CommonBanner /> */}
      <Policy />
      {/* <BottomScroll/> */}
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
