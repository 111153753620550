import { Card, Grid } from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import { Box } from "@mui/material";
import { Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import http from "../../Services/http";
 
import parse from "html-react-parser";
import { NotificationManager } from "react-notifications";
 
import { StoreContext } from "../../useContext/walletContext";

import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ModalPopup from "../ModalPopup";
import getSymbolFromCurrency from "currency-symbol-map";

import "./style.css";

const ViewAll = () => {
  const context = useContext(StoreContext);

  const navigate = useNavigate();
  const location = useLocation();
  const { name, id } = location.state;
  const [upcomingReviewData, setupcomingReviewData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openLogin, setOpenLogin] = React.useState(false);
  const handleClose = () => {
    setOpenLogin(false);
  };

  useEffect(() => {
    ReviewCreation();
    // window.scrollTo(0, 0);
  }, [context.headerTitle.id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleeventdetails = (e, id , event_name) => {
      let modifyEventName = event_name.replaceAll(/[^a-zA-Z0-9 ]/g , "")
    navigate(`/events/${modifyEventName.replaceAll(' ' , '-')}/` + id + "?ts=ts");
  };

  const handleeventdetailsComing = (e, id , event_name) => {
        let modifyEventName = event_name.replaceAll(/[^a-zA-Z0-9 ]/g , "")
    navigate(`/events/${modifyEventName.replaceAll(' ' , '-')}/`  + id + "?ts=cs");
  };

  const handleeventdetailsUpcoming = (e, id , event_name) => {
        let modifyEventName = event_name.replaceAll(/[^a-zA-Z0-9 ]/g , "")
    navigate(`/events/${modifyEventName.replaceAll(' ' , '-')}/`  + id);
  };

  const handleeventprivate = (e, id , event_name) => {
        let modifyEventName = event_name.replaceAll(/[^a-zA-Z0-9 ]/g , "")
    navigate(`/events/${modifyEventName.replaceAll(' ' , '-')}/`  + id + "?ts=PrivateEvent");
  };

  

  // Date Format Data
  const dateFormated = (date) => {
    let dateNew = new Date(date);
    let day = dateNew.toLocaleString("en-us", { weekday: "short" });
    let newdate = new Date(date).getDate();
    let month = dateNew.toLocaleString("default", { month: "short" });
    return day + "," + newdate + " " + month;
  };

  // Upcoming View All Data
  const ReviewCreation = () => {
    setIsLoading(true);
    let postData =
      id === 1
        ? {
            country_id: localStorage.getItem("autoCountry")
              ? JSON.parse(localStorage.getItem("autoCountry")).id
              : "",
            page: "1",
            keyword: "",
          }
        : id === 2
        ? {
            country_id: localStorage.getItem("autoCountry")
              ? JSON.parse(localStorage.getItem("autoCountry")).id
              : "",
            page: "1",
            keyword: "",
          }
        : id === 3
        ? {
            country_id: localStorage.getItem("autoCountry")
              ? JSON.parse(localStorage.getItem("autoCountry")).id
              : "",
            page: "1",
            keyword: "",
          }
        : id === 4
        ? {
            page: "1",
            keyword: "",
          }
        : "";

    http(
      id === 1
        ? "events/upcoming_events"
        : id === 2
        ? "coming_soon/all_coming_soon_events"
        : id === 3
        ? "talent_participation_events/homepage_talent_participation_events"
        : id === 4
        ? "events/all_private_events"
        : "",
      "POST",
      postData
    ).then(({ data }) => {
      // console.log("hello", data);
      const dataa = data.data ?? [];
      setupcomingReviewData(dataa ?? []);
      setIsLoading(false);
    });
  };

  // Fav api
  const Favourites = (e, data, params) => {
    if (!localStorage.getItem("token")) {
      setOpenLogin(true);
      return;
    }
    let postData = {
      eventid: data.id,
    };
    http("events/favourite_event", "POST", postData).then((res) => {
      if (
        res.data.msg === "success! event is added in your favourite section"
      ) {
        ReviewCreation();
        NotificationManager.success(res.data.msg, "", 800);
      } else {
        ReviewCreation();
        NotificationManager.success(
          "event is removed in your favourite section",
          "",
          800
        );
      }
    });
  };

  return (
    <Box className="mainContainer">
      <Grid container spacing={4}>
        <Grid item xs={12} md={12}>
          <Typography className="layoutTitle">{name}</Typography>
          <div className="hLine"></div>
        </Grid>
      </Grid>

      {/* {isLoading ? (
        <div style={{ height: "400px" }}>
          <div className="loader ">
            <ClipLoader />
          </div>
        </div>
      ) : ( */}
      <Grid
        container
        spacing={4}
        sx={{ marginTop: "0px", marginBottom: "40px" }}
      >
        {upcomingReviewData.map((ele, index) => {
          return (
            <Grid item xs={12} sm={12} md={4} lg={4} key={"viewall" + index}>
              <Grid>
                {name === "Talent Participation" ? (
                  <Card
                    className="favoriteCard"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => handleeventdetails(e, ele.id , ele.event_name)}
                  >
                    <Box className="flexGrid">
                      <item className="w-45">
                        <img
                          src={
                            ele.event_thumbnail
                              ? ele.event_thumbnail
                              : "assets/images/favorite.png"
                          }
                          alt="event-thumbnail"
                        />
                      </item>
                      <item className="w-55">
                        <Typography className="bookingTitle">
                          {ele.event_name}
                        </Typography>
                        <Typography className="bookingAddressBox">
                          <img
                            src="assets/images/booking-icon/calendar-icon.png"
                            alt="calendar"
                            style={{ marginRight: "23px" }}
                          />
                          <Typography className="bookingAddress">
                            {dateFormated(
                              ele.event_start_date
                                ? ele.event_start_date
                                : ele.tentative_date
                            )}
                            {/* {ele.week}, {ele.event_date}, {ele.event_time} */}
                          </Typography>
                        </Typography>
                        <Typography className="bookingAddressBox">
                          <img
                            src="assets/images/booking-icon/map-pin.png"
                            alt="map pin"
                            style={{ marginRight: "23px" }}
                          />
                          <Typography className="bookingAddress word-ellipsis">
                            {ele.venue_address
                              ? ele.venue_address
                              : ele.country_name}
                            ,
                            {ele.event_city_name
                              ? ele.event_city_name
                              : ele.state_name}
                            , {ele.city_name}
                          </Typography>
                        </Typography>
                      </item>
                    </Box>
                    <div className="hLine"></div>
                    <Typography className="favoriteText">
                      {parse(ele.event_description)}
                    </Typography>
                  </Card>
                ) : name === "Private Event" ? (
                  <Card
                    className="favoriteCard"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => handleeventprivate(e, ele.id, ele.event_name)}
                  >
                    <Box className="flexGrid">
                      <item className="w-45">
                        <img
                          src={
                            ele.event_thumbnail
                              ? ele.event_thumbnail
                              : "assets/images/favorite.png"
                          }
                          alt="rescheduled"
                        />
                      </item>
                      <item className="w-55">
                        <Typography className="bookingTitle">
                          {ele.event_name}
                        </Typography>
                        <Typography className="bookingAddressBox">
                          <img
                            src="assets/images/booking-icon/calendar-icon.png"
                            alt="calendar"
                            style={{ marginRight: "23px" }}
                          />
                          <Typography className="bookingAddress">
                            {/* {dateFormated(
                                ele.event_start_date
                                  ? ele.event_start_date
                                  : ele.tentative_date
                              )} */}
                            {ele.week}, {ele.event_date}, {ele.event_time}
                          </Typography>
                        </Typography>
                        <Typography className="bookingAddressBox">
                          <img
                            src="assets/images/booking-icon/map-pin.png"
                            alt="map pin"
                            style={{ marginRight: "23px" }}
                          />
                          <Typography className="bookingAddress word-ellipsis">
                            {ele.venue_address
                              ? ele.venue_address
                              : ele.country_name}
                            ,
                            {ele.event_city_name
                              ? ele.event_city_name
                              : ele.state_name}
                            , {ele.city_name}
                          </Typography>
                        </Typography>

                        <Typography
                          className="bookingOrderNo colorBlue"
                          sx={{ fontWeight: "700 !important" }}
                        >
                          <span className="colorDarkBlue">Price:</span>{" "}
                          {getSymbolFromCurrency(ele.currency)} {ele.minprice}{" "}
                          Onwards
                        </Typography>
                      </item>
                    </Box>
                    <div className="hLine"></div>
                    <Typography className="favoriteText">
                      {parse(ele.event_description)}
                    </Typography>
                  </Card>
                ) : name === "Comingsoon" ? (
                  <Card
                    className="favoriteCard"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => handleeventdetailsComing(e, ele.id, ele.event_name)}
                  >
                    <Box className="flexGrid">
                      <item className="w-45">
                        <img
                          src={
                            ele.event_thumbnail
                              ? ele.event_thumbnail
                              : "assets/images/favorite.png"
                          }
                          alt="rescheduled"
                        />
                      </item>
                      <item className="w-55">
                        <Typography className="bookingTitle">
                          {ele.event_name}
                        </Typography>
                        <Typography className="bookingAddressBox">
                          <img
                            src="assets/images/booking-icon/calendar-icon.png"
                            alt="calendar"
                            style={{ marginRight: "23px" }}
                          />
                          <Typography className="bookingAddress">
                            {dateFormated(
                              ele.event_start_date
                                ? ele.event_start_date
                                : ele.tentative_date
                            )}
                            {/* {ele.week}, {ele.event_date}, {ele.event_time} */}
                          </Typography>
                        </Typography>
                        <Typography className="bookingAddressBox">
                          <img
                            src="assets/images/booking-icon/map-pin.png"
                            alt="map pin"
                            style={{ marginRight: "23px" }}
                          />
                          <Typography className="bookingAddress word-ellipsis">
                            {ele.venue_address
                              ? ele.venue_address
                              : ele.country_name}
                            ,
                            {ele.event_city_name
                              ? ele.event_city_name
                              : ele.state_name}
                            , {ele.city_name}
                          </Typography>
                        </Typography>

                        {/* 
                            <Typography
                              className="bookingOrderNo colorBlue"
                              sx={{ fontWeight: "700 !important" }}
                            >
                              <span className="colorDarkBlue">Price:</span> {ele.currency} {ele.minprice} Onwards
                            </Typography> */}
                      </item>
                    </Box>
                    <div className="hLine"></div>
                    <Typography className="favoriteText">
                      {parse(ele.event_description)}
                    </Typography>
                  </Card>
                ) : (
                  <Card
                    className="favoriteCard"
                    style={{ cursor: "pointer" }}
                  
                  >
                    <Box className="flexGrid">
                      <item className="w-45"  onClick={(e) => handleeventdetailsUpcoming(e, ele.id, ele.event_name)}>
                        <img
                          src={
                            ele.event_thumbnail
                              ? ele.event_thumbnail
                              : "assets/images/favorite.png"
                          }
                          alt="rescheduled"
                        />
                      </item>
                      <item className="w-55">
                        <div className="d_flexFav">
                          <Typography className="bookingTitle"  onClick={(e) => handleeventdetailsUpcoming(e, ele.id , ele.event_name)}>
                            {ele.event_name}
                          </Typography>
                          {ele.favourite === 1 ? (
                            <FavoriteIcon
                              className="fav_Icon"
                              onClick={(e) => Favourites(e, ele)}
                            />
                          ) : ele.favourite === 0 ? (
                            <FavoriteBorderIcon
                              style={{
                                color: "#000",
                              }}
                              //className="fav_Icon"
                              onClick={(e) => Favourites(e, ele)}
                            />
                          ) : (
                            ""
                          )}
                        </div>


                        <div onClick={(e) => handleeventdetailsUpcoming(e, ele.id, ele.event_name)}>
                        <Typography className="bookingAddressBox">
                          <img
                            src="assets/images/booking-icon/calendar-icon.png"
                            alt="calendar"
                            style={{ marginRight: "23px" }}
                          />
                          <Typography className="bookingAddress">
                            {/* {dateFormated(
                                  ele.event_start_date
                                    ? ele.event_start_date
                                    : ele.tentative_date
                                )} */}
                            {dateFormated(ele.event_date)}
                            {/* {ele.week}, {ele.event_date}, {ele.event_time} */}
                          </Typography>
                        </Typography>
                        <Typography className="bookingAddressBox">
                          <img
                            src="assets/images/booking-icon/map-pin.png"
                            alt="map pin"
                            style={{ marginRight: "23px" }}
                          />
                          <Typography className="bookingAddress word-ellipsis">
                            {ele.venue_address
                              ? ele.venue_address
                              : ele.country_name}
                            ,
                            {ele.event_city_name
                              ? ele.event_city_name
                              : ele.state_name}
                            , {ele.city_name}
                          </Typography>
                        </Typography>

                        <Typography
                          className="bookingOrderNo colorBlue"
                          sx={{ fontWeight: "700 !important" }}
                        >
                          <span className="colorDarkBlue">Price:</span>{" "}
                          {getSymbolFromCurrency(ele.currency)} {ele.minprice}{" "}
                          Onwards
                        </Typography>
                        </div>
                      </item>
                    </Box>
                    <div className="hLine"></div>
                    <Typography className="favoriteText" onClick={(e) => handleeventdetailsUpcoming(e, ele.id, ele.event_name)}>
                      {parse(ele.event_description)}
                    </Typography>
                  </Card>
                )}
              </Grid>
            </Grid>
          );
        })}
      </Grid>
      {/* )} */}

      {openLogin ? (
        <ModalPopup handleOpen={openLogin} handleClick={handleClose} />
      ) : (
        ""
      )}
    </Box>
  );
};

export default ViewAll;
