export const ActionTypes = {
    GET_VENDORS: "GET_VENDORS",
    SELECTED_ITEM:"SELECTED_ITEM",
    GET_VENDORS_ITEM:"GET_VENDORS_ITEM",
    GET_VENDORS_FOOD_ITEM:"GET_VENDORS_FOOD_ITEM",
    ADD_INCREMENT:"ADD_INCREMENT",
    ADD_DECREMENT:"ADD_DECREMENT",
    ADD_TOTAL:"ADD_TOTAL",
    EVENTSEXPIRED :"EVENTSEXPIRED",
    COUNTRY :"COUNTRY",
    CITY :"CITY",
    EVENTS :"EVENTS"
    
}