import { Grid, Modal, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect }  from 'react'
import { useState } from 'react';
import { ColorRing } from 'react-loader-spinner';
import http from "../../Services/http";
import "../SelectLoction/style.css"
  
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "620px !important",
    bgcolor: "#fff",
    border: "0px solid #000",
    boxShadow: 24,
    p: 3,
    width: "95%",
  };    

const ShowLocationDates = ({selectedLocationData , closePopup }) => {
  
  //  console.log(selectedLocationData , 'selectedLocationData')
 
  const [multidayDatenTime , setMultidayDatenTime] = useState({})
  const [loading , setLoading] = useState(false)
  const getMultiDayRangeDates = ()=>{
    setLoading(true)
    let postData= 
    {
         location_id  :   selectedLocationData.location_id ? selectedLocationData.location_id : selectedLocationData?.event_date_time[0]?.time[0]?.location_id,
         event_id :   selectedLocationData.event_id
    }
    
    http('events/getMultDateRange' , 'POST' , postData).then((res)=>{
      // console.log(res , 'multiday locations')
      if(res.data.status){
        setLoading(false)
        // console.log(res.data.data , 'data')
        setMultidayDatenTime(res.data.data)
      }
      
    })
   }
   useEffect(()=>{
     Object.keys(selectedLocationData).length>0 ? getMultiDayRangeDates() : <></>
   },[selectedLocationData])
  
 

    // const [selectedLocationData ,setSelectedLocationData] = useState({})
 
   
// console.log(selectedLocationData ,'selectedLocationData')

    function getDayName(dateStr, locale) {
        var date = new Date(dateStr);
        return date.toLocaleDateString(locale, { weekday: "long" }).slice(0,3);
      }

      

  return (
      <>

        <Box sx={style} className="seat-Modal-class-Box-Date">
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography id="keep-mounted-modal-title" variant="h6" component="h2" className="open-date-modal-headding">
              {selectedLocationData.venue_name}
            </Typography>
            <img src="assets/images/close-icon.png" alt="close-icon" style={{cursor:'pointer'}} onClick={() => {
              closePopup(false)
              // setMultidayDatenTime({})
              }} />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
            <img src="assets/images/calander.png" alt="" />
            <Typography className="Open-Date-top-date">{selectedLocationData.locationDateRange} </Typography>
          </Box> 
    { 
     loading ?
     <Box sx={{display:'flex' , justifyContent:'center' , alignItems:'center' , height:'350px'}}>
     <ColorRing
     visible={true}
     height="100"
     width="100"
     ariaLabel="blocks-loading"
     wrapperStyle={{}}
     wrapperClass="blocks-wrapper"
     colors={[
       "#b8c480",
       "#B2A3B5",
       "#F4442E",
       "#51E5FF",
       "#429EA6",
     ]}
   /></Box>:
    Object.keys(multidayDatenTime).length>0  ? multidayDatenTime.event_date_time.map((dates)=>(
      <Grid container spacing={0} className="Open-Date-top-grid">
        
            <Grid md={3} sx={{ display: 'flex', alignItems: 'center' }} className="Open-Date-top-grid-col1">
              <Box className="Open-Date-top-grid-col-box1">
                <Typography className="Open-Date-top-grid-col-typo1">{getDayName(dates.date, "en-us")} </Typography>
                <Typography className="Open-Date-top-grid-col-typo2">{dates.date}</Typography>
              </Box> 
            </Grid>
            <Grid md={1} sx={{ display: 'flex', alignItems: 'center' }} className="Open-Date-V-Line-Mob-none">
              <div className="Open-Date-V-Line"></div>
            </Grid>
 
          { dates.time.map((times , index)=>(
            <Grid md={2} sx={{ padding: '8px' }}>
            <Box className={index%2 ===0 ? "Open-Date-Clock-Box-eight": "Open-Date-Clock-Box-one"}>
              <Typography className={index%2 ===0 ? "Open-Date-eight-Clock":"Open-Date-One-Clock" }>{times.time}</Typography> 
            </Box>
          </Grid>
          ))  } 
          </Grid>
    ))     : <></>
}
         
        </Box>
   
      </>
  )
}

export default ShowLocationDates