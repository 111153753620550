/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CardMedia, Grid, Paper, Snackbar, styled } from "@mui/material";
import { Box } from "@mui/system";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import CardContent from "@mui/material/CardContent";
import Rating from "@mui/material/Rating";
import Carousel, { consts } from "react-elastic-carousel";
import http from "../../Services/http";
import CloseIcon from "@mui/icons-material/Close";
import ModalPopup from "../ModalPopup";
import Modal from "@mui/material/Modal";
import { NotificationManager } from "react-notifications";
import SocialSharePopup from "../SocialSharePopup";
import FavoriteIcon from "@mui/icons-material/Favorite";
import getSymbolFromCurrency from "currency-symbol-map";
import OwlCarousel from 'react-owl-carousel'
import {getLanguageSource} from '../../Services/translateString'
import "./Style.css";
import {useTranslation} from "react-i18next";
const breakPoints = [
  { width: 1, itemsToShow: 2, itemPadding: [10, 3] },
  { width: 550, itemsToShow: 3, itemPadding: [10, 5] },
  { width: 768, itemsToShow: 4, itemPadding: [10, 8] },
  { width: 1200, itemsToShow: 5, itemPadding: [10, 12] },
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "95%",
  maxWidth: 600,
  bgcolor: "#FFFFFF",
  boxShadow: 24,
  overflow: "auto",
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(0),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const EventCard = (props) => {
  const {t} = useTranslation("common");
  const { eventData, getdatafromchild } = props;
  // Module Hoc Function
  const [openLogin, setOpenLogin] = React.useState(false);
  const [seletedeventID, setseletedeventID] = useState(0);
  const handleClose = () => {
    setOpenLogin(false);
  };
  const checkLoggedIn = (e, params, id, status) => {
    if (!localStorage.getItem("token")) {
      setOpenLogin(true);
    } else {
      Favourites(params,id, status);
    }
  };

  // Sharemod
  const [open1, setOpen1] = React.useState(false);
  const handleClose1 = () => setOpen1(false);
  const [EventName, setEventName] = useState("");

  const checkLoggedIn1 = (e, params, event_name) => {
    setseletedeventID(params.id);
    setOpen1(true);
    setEventName(event_name.replaceAll(/[^a-zA-Z0-9 ]/g, ""));
  };

  let Autocountry = localStorage.getItem("autoCountry")
    ? JSON.parse(localStorage.getItem("autoCountry"))
    : {};

  const arrEventsData = eventData ?? [];

  const navigate = useNavigate();
  const HandleClick = (e, id, event_name) => {
    let name = event_name.replaceAll(/[^a-zA-Z0-9 ]/g, "");

    navigate(
      `/events/${name.replaceAll(" ", "-")}/` +
        id +
        "?flag=" +
        Autocountry.sortname
    );
    setseletedeventID(id);
  };

  const [show, setShow] = React.useState(false);

  const [Fav, setFav] = useState(0);

  // Fav api
  const Favourites = (params,id, status) => {
    let postData = {
      eventid: params.id,
    };
    http("events/favourite_event", "POST", postData).then((res) => {
      if (
        res.data.msg === "success! event is added in your favourite section"
      ) {
        getdatafromchild(true);
        setFav(1);
        NotificationManager.success(res.data.msg, "", 800);
        document.getElementById(id).classList.add('selected')
      } else {
        getdatafromchild(true);
        document.getElementById(id).classList.remove('selected')
        setFav(0);
        NotificationManager.success(
          "event is removed in your favourite section",
          "",
          800
        );
      }
    });
  };

  const [CCBoardPopUp, setCCBoardPopUp] = useState(false);

  const CopiedToclipBoardPopUp = (data) => {
    setOpen1(data);
    setCCBoardPopUp(true);
  };

  const handleCloseCpy = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setCCBoardPopUp(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseCpy}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  useEffect(() => {
    if (arrEventsData.length > 4) {
      setShow(true);
    } else {
      setShow(false);
    }
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const dateFormated = (date) => {
    let dateNew = new Date(date);
    let day = dateNew.toLocaleString("en-us", { weekday: "short" });
    let newdate = new Date(date).getDate();
    let month = dateNew.toLocaleString("default", { month: "short" });
    return day + "," + newdate + " " + month;
  };
  const options = {
    autoplay: false,
    smartSpeed: 1000,
    margin: 0,
    loop: false,
    center: false,
    dots: false,
    nav: true,
    navContainer: `.${props?.category?.split(' ').join('_')}`,
    navText : [
        '<i class="fas fa-chevron-left"></i>',
        '<i class="fas fa-chevron-right"></i>'
    ],
    responsive: {
  0:{
            items:1
        },
        576:{
            items:1
        },
        768:{
            items:2
        },
        992:{
            items:2
        },
        1100:{
            items:3
        }
    }
};
const getInternalisationCatName = (id)=>{
  if(document.getElementById(id))
    document.getElementById(id).innerHTML = t(props?.category?.split(' ').join('_'))
    
}
const getInternalisationBuyBtn = (id, text) =>{
  if(document.getElementById(id)){
    setTimeout(()=>{
      document.getElementById(id).innerHTML = t(text)
    },500)
    
    return t(text)
  }
  return t(text)
   
}
useEffect(()=>{
  
  const eventNamearr = []
  if(arrEventsData && arrEventsData.length > 0){
    arrEventsData.forEach(celeEvents=>{
      eventNamearr.push(celeEvents.event_name)
    })
    translateString(eventNamearr)
  }
},[localStorage.getItem('lang')])

const translateString = async(stringsArr)=>{
  const translatedString = await getLanguageSource(stringsArr)
  translatedString.forEach((transString,idx)=>{
    if(document.getElementById(`${props?.category?.split(' ').join('_')}_event_name_${idx}`)){
      document.getElementById(`${props?.category?.split(' ').join('_')}_event_name_${idx}`).innerHTML = transString.translatedText || stringsArr[idx] 
    }
  })
}
  return (
    <div style={{marginBottom:48}}>
    




    <Box sx={{ flexGrow: 1, margin: "0px auto", fontFamily: "Lato" }}>
    <Modal
        open={open1}
        onClose={handleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="ModalClass"
      >
        <Box sx={style} className="ModalClassBox">
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{ textAlign: "right", padding: "0px 16px" }}
              className="ModalTopGrid"
            >
              <CloseIcon className="closeIconOffer" onClick={handleClose1} />
              <Typography
                id="modal-modal-description"
                className="ModalPhomolong"
                sx={{ textAlign: "center" }}
              >
                Share with Social Media
              </Typography>
              <SocialSharePopup
                CopiedToclipBoardPopUp={CopiedToclipBoardPopUp}
                ShareUrl={
                  `/events/${EventName.replaceAll(" ", "-")}/` +
                  seletedeventID +
                  "?flag=" +
                  Autocountry.sortname
                }
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>
      {openLogin ? (
        <ModalPopup handleOpen={openLogin} handleClick={handleClose} />
      ) : (
        ""
      )}
    </Box>
        <OwlCarousel  className='owl-theme project-carousel wow fadeInUp' {...options}>
            {arrEventsData.map((data, index) => (
            <div className="project-item pe-5 pb-3" key={`carousel_${props?.category?.split(' ').join('_')}${index}`}>
              
            <div className="project-img">
            
                <img className="img-fluid rounded-3"  style={{height:472}} src={data.event_thumbnail? data.event_thumbnail : 'assets/images/Rectangle.png'} alt="event-thumb-001"/>
                <div className="project-card-data">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="pcd-event-name text-white fw-bold" id={`${props?.category?.split(' ').join('_')}_eventname_${index}`}>{getInternalisationCatName(`${props?.category?.split(' ').join('_')}_eventname_${index}`)}</div>
                        {data.favourite === 0 || data.favourite === 1 ? <div className="pcd-event-like" onClick={(e) => checkLoggedIn(e, data,`${props?.category?.split(' ').join('_')}_${index}`,data.favourite)}>
                                    
                                        <a id={`${props?.category?.split(' ').join('_')}_${index}`} className={`btn ms-3 ${ data.favourite === 1 ? 'selected' : ''} circular-aura-effect`} >
                                            <small className="bi bi-heart-fill"></small>
                                        </a>
                                    </div> : <></>}
                    </div>
                    <div className="pcd-event-detail" style={{display:'flex',flexDirection:'column', gap:5}}>
                    {data.eventExpireFlag === 1   ? (
                    <img
                      alt="expired event"
                      className="expired-event"
                      src="assets/icons/expired-event.png"  
                      style={{width:100,top: 0, left:0}}
                    />
                  ) : (
                    <></>
                  )}
                        {data.PriceForTickets && <div className="pcd-event-code fw-medium mb-1">{`${getSymbolFromCurrency(data.PriceForTickets.currency)} ${data.PriceForTickets.price}`}</div>}
                        <div id={`${props?.category?.split(' ').join('_')}_event_name_${index}`} className="pcd-event-title fw-medium ">{data.event_name}</div>
                        <div className="pcd-event-time fw-light mb-2"><i className="bi bi-calendar4 me-2"></i>{dateFormated(data.event_date)}{" "}
                                  {data.date_range.length > 10
                                    ? "Onwards"
                                  : data.event_time}</div>
                        <div className="pcd-ed-footer d-flex align-items-center justify-content-between">
                            <a onClick={(e) => checkLoggedIn1(e, data , data.event_name)}><img className="icon-peoples" src="img/peoples.svg" alt="peoples"/></a>
                            <a id={`${props?.category?.split(' ').join('_')}_btn_${index}`} onClick={(e) => HandleClick(e, data.id , data.event_name)} className="btn btn-primary event-buyticket fw-regular p-2 px-3">{getInternalisationBuyBtn(`${props?.category?.split(' ').join('_')}_btn_${index}`, 'buy_ticket')}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            ))}
          </OwlCarousel>
      
    </div>
  );
};

export default EventCard;
