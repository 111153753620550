import React from 'react'
import Navbar from "../../Components/Navbar";
import MenuBar from "../Menu";
//import BreadCrumbs from '../../Components/BreadCrumbs';
import Footer from "../../Components/Footer";
import SuperMart from '../../Components/SuperMart'
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link } from "react-router-dom";
import { Breadcrumbs, Typography } from "@mui/material";
import { useLocation } from 'react-router-dom';
import { Box } from "@mui/system";
import "./style.css";

const SuperMarts = () => {


  const breadcrumbs = [
    <Link to={"/"}  key="1" className="link-decoration">
      Home
    </Link>,

    <Typography
      key="3"
      sx={{
        fontFamily: "Lato",
        color: "#0CA8F6",
        fontWeight: 500,
        fontSize: "16px",

      }}
    >
      Super Mart
    </Typography>,
  ];
  return (
    <>
      <Navbar />
      {/* <MenuBar /> */}
      <Box className="mainContainer">
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          sx={{ mb: 3, mt: 2 }}
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Box>
      <SuperMart />
      <Footer />
    </>
  );
}

export default SuperMarts