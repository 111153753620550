import React from "react";
import Navbar from "../../Components/Navbar";
import UpcomingEvent from "../../Components/UpcomingEvent";
const Upcoming = () => {
  return (
    <>
      <Navbar />
        <UpcomingEvent />
    </>
  );
};

export default Upcoming;
