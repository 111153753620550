import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import "./style.css";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { useNavigate, useLocation, json } from "react-router-dom";
import http from "../../Services/http";
import NotificationManager from "react-notifications/lib/NotificationManager";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import getSymbolFromCurrency from "currency-symbol-map";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 600,
  bgcolor: "#FFFFFF",
  boxShadow: 24,
  p: 4,
  width: "95%",
};

const FoodCart = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [curr, setCurr] = useState("");
  const [currentVendorID, setcurrentVendorID] = useState("");
  const [totalValueArray, setTotalValueArray] = useState(0);


  const locid = location.state.FoodSearch.location_id;
  const orderid = location.state.FoodSearch.orderid;
  const Eventid = location.state.event;

  const BookingorderNumber = location.state.FoodSearch.order_number
    ? location.state.FoodSearch.order_number
    : location.state.FoodSearch.orderNumber;
  const [addtocart, setaddtocart] = useState("");
  const [showAutoSelectData, setshowAutoSelectData] = useState(0);

  let autoCountry = localStorage.getItem("autoCountry")
    ? JSON.parse(localStorage.getItem("autoCountry"))
    : {};

  const constUsercurrencysymbol = autoCountry.currency_code;
  const newCurr = getSymbolFromCurrency(constUsercurrencysymbol);


  const [vendorid, setVendorid] = useState("");
  const localStorageCart = [];
  if (localStorage.getItem("cartValue")) {
    let localStorageArray = JSON.parse(localStorage.getItem("cartValue"));
    localStorageArray.forEach((elem) => {
      if (elem.select == true) {
        localStorageCart.push(elem);
      } else {
      }
    });
  }

  const [searchfoodData, setSearchfoodData] = useState(
    localStorageCart ? localStorageCart : []
  );
  if (addtocart && addtocart.length > 0) {
    const finalVendorList = addtocart.map(
      (obj) => searchfoodData.find((o) => o.id === obj.id) || obj
    );
    if (totalValueArray == 1) {
      localStorage.setItem("cartValue", JSON.stringify(finalVendorList));
    }
  }



  const [changeFood, setChangeFood] = useState("");
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);

  const [addfoodData, setaddfoodData] = useState([]);
  const [previousVendorname, setPreviousVendorname] = useState("");


  const [filters, setFilter] = useState(""); 
  const [count, setCount] = useState(1);
  const [totalamount, setTotalamount] = useState(0);
  const [totalitemcount, setTotalitemcount] = useState(0);

  const [vendorcheckid, setVendorcheckid] = useState("");

  const [Previousid, setPreviousid] = useState(
    location.state.check ? "" : location.state.FoodSearch.data.v_user_id
  );

  const [previousvendorname, setpreviousvendorname] = useState(
    location.state.check ? "" : location.state.FoodSearch.data.vendor_name
  );
  const [totalNewCartArray, settotalNewCartArray] = useState([]);

  const [nextvendorname, setnextvendorname] = useState("");
 

  const localOrderParamID = localStorage.getItem("Orderidoarams");
  const [searchItemsCountZero, setsearchItemsCountZero] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const [arrN, setArrN] = useState([]);
  let arrNewCart = [];



  useEffect(() => {
    setChangeFood(location.state.event);
    setVendorcheckid(
      location.state.check ? "" : location.state.FoodSearch.data.v_user_id
    );
    if (totalValueArray != 2) {
      setIndexOfSelectedData();
    }

    if (totalValueArray == 0) {
      grandtotalSearchFood();
    }
    localStorageCart.length > 0 ? (
      setCurr(getSymbolFromCurrency(localStorageCart[0].currency))
    ) : (
      <></>
    );
  }, [changeFood, searchfoodData]);
  const commisionfood = location.state.FoodSearch.commission_on_food;
  const CommissionServiceTax = (commisionfood * totalamount) / 100;
  const GrandTotalAmount = totalamount + CommissionServiceTax;

  // function to set values after useState

  const setIndexOfSelectedData = () => {
    const newarray = [...searchfoodData];
    newarray.map((element, index) => {
      if (element.select == true) {
        setpreviousVendorIndex(index);
        setGetFirstAddVendorID(element.vendor_id);
        setnextvendorname(element.vendor_name);
      } else {
        setPreviousVendorname(element.vendor_name);
        setVendorIndex(index);
      }
    });
  };

  // start of function to calculate grand total
  const grandtotalSearchFood = () => {
    let totalarray = 0;
    let totalitemcount = 0;
    const newarray = [...searchfoodData];
    newarray.map((item) => {
      totalarray += item.total;
      totalitemcount += item.count;
    });

    setTotalamount(totalarray);
    setTotalitemcount(totalitemcount);
  };

  const showTotalValueAndTotalCount = (element) => {
    if (localStorage.getItem("cartValue")) {
      let totalarray = JSON.parse(
        localStorage.getItem("totalAmountArr")
      ).grandTotal;
      let totalitemcount = JSON.parse(
        localStorage.getItem("totalAmountArr")
      ).grantTotalItem;
      const total = parseFloat(1) * parseFloat(element.food_price);
      setTotalamount(totalarray + total);
      setTotalitemcount(totalitemcount + 1);
      const grandTotalObj = {
        grandTotal: totalarray + total,
        grantTotalItem: totalitemcount + 1,
        vendorIDLocal: JSON.parse(localStorage.getItem("totalAmountArr"))
          .vendorIDLocal,
      };
      if (searchfoodData.length > 0) {
        localStorage.setItem("totalAmountArr", JSON.stringify(grandTotalObj));
      }
    }
  };

  const showTotalValueAndTotalCountAfterMinus = (element) => {
    if (localStorage.getItem("cartValue")) {
      let totalarray = JSON.parse(
        localStorage.getItem("totalAmountArr")
      ).grandTotal;
      let totalitemcount = JSON.parse(
        localStorage.getItem("totalAmountArr")
      ).grantTotalItem;
      const total = parseFloat(1) * parseFloat(element.food_price);
      setTotalamount(totalarray - total);
      setTotalitemcount(totalitemcount - 1);
      const grandTotalObj = {
        grandTotal: totalarray - total,
        grantTotalItem: totalitemcount - 1,
        vendorIDLocal: JSON.parse(localStorage.getItem("totalAmountArr"))
          .vendorIDLocal,
      };
      if (searchfoodData.length > 0) {
        localStorage.setItem("totalAmountArr", JSON.stringify(grandTotalObj));
      }
    }
  };

  // end of function to calculate grand total

  // start of function to add and subtract value to cart after search
  const [valueTocartSearch, setValueTocartSearch] = useState("");
  const [getFirstAddVendorID, setGetFirstAddVendorID] = useState("vendor");
  const addToCartArr = [];




 



  // function to set data to localStorage

  const addDataToLocal = () => {
    if (searchfoodData.length > 0) {
      searchfoodData.forEach((elem) => {
        if (elem.select == true) {
          addToCartArr.push(elem);
        }
      });

      localStorage.setItem("cartValue", JSON.stringify(addToCartArr));

      let totalarraySelect = 0;
      let totalitemCountSelect = 0;
      addToCartArr.forEach((element) => {
        totalarraySelect += element.total;
        totalitemCountSelect += element.count;
      });
      const vendorIDLocal = addToCartArr.map((elem) => {
        return elem.vendor_id;
      });
      const grandTotalObj = {
        grandTotal: totalarraySelect,
        grantTotalItem: totalitemCountSelect,
        vendorIDLocal: vendorIDLocal,
      };
      if (searchfoodData.length > 0) {
        localStorage.setItem("totalAmountArr", JSON.stringify(grandTotalObj));
      }
    }
  };

  const [vendorIndex, setVendorIndex] = useState();
  const [previousVendorIndex, setpreviousVendorIndex] = useState();

  const addOnClickFunction = (e, index) => {
    if (totalValueArray == 0) {
      addValueTotalValueArray(e, index);
    } else {
      addValueFromSearchToCart(e, index);
    }
  };

  const minusOnClickFunction = (e, index) => {
    if (totalValueArray == 0) {
      MinusOnTotalValueArray(e, index);
    } else {
      AddonscountMinus(e, index);
    }
  };

  const setDataFromLocalToAddCart = () => {
    const vendorCartLocal = JSON.parse(localStorage.getItem("cartValue"));
    if (vendorCartLocal && vendorCartLocal.length > 0) {
      let totalarraySelect = 0;
      let totalitemCountSelect = 0;
      vendorCartLocal.forEach((element) => {
        totalarraySelect += element.total;
        totalitemCountSelect += element.count;
      });
      const vendorIDLocal = vendorCartLocal.map((elem) => {
        return elem.vendor_id;
      });
      const grandTotalObj = {
        grandTotal: totalarraySelect,
        grantTotalItem: totalitemCountSelect,
        vendorIDLocal: vendorIDLocal,
      };
      if (searchfoodData.length > 0) {
        localStorage.setItem("totalAmountArr", JSON.stringify(grandTotalObj));
        setTotalamount(totalarraySelect);
        setTotalitemcount(totalitemCountSelect);
      }
    }
  };

  const addValueTotalValueArray = (e, index) => {
    const newarray = [...searchfoodData];
    newarray[index].count = newarray[index].count + 1;
    newarray[index].select = true;
    newarray[index].total =
      parseFloat(newarray[index].count) *
      parseFloat(newarray[index].food_price);
    setnextvendorname(newarray[index].vendor_name);
    setCount(count + 1);
    localStorage.setItem("cartValue", JSON.stringify(newarray));
    setTimeout(() => {
      setDataFromLocalToAddCart();
    }, 300);
  };

  const MinusOnTotalValueArray = (e, index) => {
    const newarray = [...searchfoodData];
    newarray[index].count =
      newarray[index].count <= 0 ? 0 : newarray[index].count - 1;
    newarray[index].total =
      parseFloat(newarray[index].count) *
      parseFloat(newarray[index].food_price);
    setnextvendorname(newarray[index].vendor_name);
    newarray[index].select = newarray[index].count <= 0 ? false : true;

    setCount(count - 1);
    localStorage.setItem("cartValue", JSON.stringify(newarray));
    setTimeout(() => {
      setDataFromLocalToAddCart();
    }, 300);
  };

  const addValueFromSearchToCart = (e, index) => {
    const newarray = [...searchfoodData];
    if (getFirstAddVendorID == "vendor") {
      newarray[index].count = newarray[index].count + 1;
      newarray[index].select = true;
      newarray[index].total =
        parseFloat(newarray[index].count) *
        parseFloat(newarray[index].food_price);
      localStorage.setItem("nextvendorId", newarray[index].vendor_id);
      localStorage.setItem("nextVendorName", newarray[index].vendor_name);
      setcurrentVendorID(newarray[index].vendor_id);
      setPreviousVendorname(newarray[index].vendor_name);
      let vendorID = newarray[index].vendor_id;
      setGetFirstAddVendorID(vendorID);
      grandtotalSearchFood();
      setpreviousVendorIndex(index);
      setValueTocartSearch(newarray);
      if (totalValueArray == 2) {
        addDataToLocal();
      }
    } else if (getFirstAddVendorID == newarray[index].vendor_id) {
      newarray[index].count = newarray[index].count + 1;
      newarray[index].select = true;
      newarray[index].total =
        parseFloat(newarray[index].count) *
        parseFloat(newarray[index].food_price);

      setcurrentVendorID(newarray[index].vendor_id);
      setPreviousVendorname(newarray[index].vendor_name);
      localStorage.setItem("nextvendorId", newarray[index].vendor_id);
      localStorage.setItem("nextVendorName", newarray[index].vendor_name);
      setCount(count + 1);
      grandtotalSearchFood();
      setpreviousVendorIndex(index);
      setValueTocartSearch(newarray);
      if (totalValueArray == 2) {
        addDataToLocal();
      } else {
        showTotalValueAndTotalCount(newarray[index]);
      }
    } else {
      setOpen(true);
      setVendorIndex(index);
      if (totalValueArray == 2) {
        setnextvendorname(
          localStorage.getItem("nextVendorName")
            ? localStorage.getItem("nextVendorName")
            : ""
        );
      }

      setPreviousVendorname(newarray[index].vendor_name);
    }
  };

  const AddonscountMinus = (e, index) => {
    const newarray = [...searchfoodData];
    newarray[index].count =
      newarray[index].count <= 0 ? 0 : newarray[index].count - 1;
    newarray[index].total =
      parseFloat(newarray[index].count) *
      parseFloat(newarray[index].food_price);

    setPreviousVendorname(newarray[index].vendor_name);
    setCount(count - 1);
    newarray[index].select = newarray[index].count <= 0 ? false : true;
    if (newarray[index].count < 1) {
      setGetFirstAddVendorID("vendor");
      localStorage.removeItem("cartValue");
      localStorage.removeItem("totalAmountArr");
    } else {
    }
    grandtotalSearchFood();
    setValueTocartSearch(newarray);
    showTotalValueAndTotalCountAfterMinus(newarray[index]);
  };

  // end of function to add and subtract value to cart after search

  // start of function to replace last order in cart

  const HandleReplace = () => {
    setOpen(false);
    HandleAddAfterReplace();
  };

  const HandleAddAfterReplace = () => {
    const newarray = [...searchfoodData];
    newarray[vendorIndex].count = 1;
    newarray[vendorIndex].select = true;
    newarray[vendorIndex].total =
      parseFloat(newarray[vendorIndex].count) *
      parseFloat(newarray[vendorIndex].food_price);
    let vendorID = newarray[vendorIndex].vendor_id;
    setcurrentVendorID(vendorID);
    setGetFirstAddVendorID(vendorID);
    setpreviousVendorIndex(vendorIndex);

    newarray.forEach((element) => {
      if (element.vendor_id == currentVendorID) {
        element.select = false;
        element.count = 0;
        element.total = 0;
      }
    });

    // setPreviousVendorname(newarray[previousVendorIndex].vendor_name);
    localStorage.setItem("nextvendorId", newarray[vendorIndex].vendor_id);
    localStorage.setItem("nextVendorName", newarray[vendorIndex].vendor_name);
    setnextvendorname(newarray[vendorIndex].vendor_name);
    grandtotalSearchFood();
    setValueTocartSearch(newarray);
    setaddtocart(searchfoodData);
    localStorage.removeItem("cartValue");
    localStorage.removeItem("totalAmountArr");
 

    addDataToLocal();
  };

  const AddonscountMinusSearch = (e, index) => {
    const newarray = [...addtocart];
    newarray[index].count = newarray[index].count - 1;
    newarray[index].total =
      parseFloat(newarray[index].count) *
      parseFloat(newarray[index].food_price);
    setCount(count - 1);
    if (totalValueArray == 2) {
      addDataToLocal();
    } else {
      showTotalValueAndTotalCount(newarray[index]);
    }
  };

  // useState for total value and total item

const [searchdata, setsearchdata] = useState(false)


  // Eventid
  const Searchfoodorder = (value) => {
    arrNewCart = [];
    setFilter(value);
    const postData = {
      event_id: location.state.event,
      location_id: locid,
      keyword: value,
      vendor_id: "",
    };
    http("event_food/event_food_search", "POST", postData).then((res) => {
      if (res.data.status === 1) {
        const Dynamiccuur = getSymbolFromCurrency(
          res.data.data[0].currency ? res.data.data[0].currency : ""
        );
        setCurr(Dynamiccuur);
        if (localStorage.getItem("cartValue")) {
          setTotalValueArray(1);
          const vendorCartLocal = JSON.parse(localStorage.getItem("cartValue"));
          setcurrentVendorID(vendorCartLocal[0].vendor_id);
          setaddtocart(vendorCartLocal);
          const finalVendorList = vendorCartLocal
            ? res.data.data.map(
                (obj) => vendorCartLocal.find((o) => o.id == obj.id) || obj
              )
            : [];
          finalVendorList.map((ele) => {
            ele.total = ele.total ? ele.total : 0;
            ele.select = ele.select ? ele.select : false;
            ele.count = ele.count ? ele.count : 0;
          });

          const leftOutVendorList = [];
          res.data.data.forEach((obj) =>
            vendorCartLocal.find((o) => {
              if (o.id != obj.id) {
                leftOutVendorList.push(obj);
              }
            })
          ); 
          setSearchfoodData(finalVendorList);
          setOrders(finalVendorList)
          setshowAutoSelectData(0);
          let vendorCartArray = [];
          res.data.data.map((ele) => {
            ele.total = ele.total ? ele.total : 0;
            ele.select = ele.select ? ele.select : false;
            ele.count = ele.count ? ele.count : 0;
            vendorCartArray.push(ele);
          });
          vendorCartLocal.map((element) => {
            vendorCartArray.push(element);
          });
          settotalNewCartArray(vendorCartArray);
          getNewTotalValueAndCount(vendorCartArray);
        } else {
          res.data.data.map((ele) => {
            ele.total = 0;
            ele.count = 0;
          }); 
          setSearchfoodData(res.data.data);
          setOrders(res.data.data)
          setaddtocart(res.data.data);
          setTotalValueArray(2);
          setshowAutoSelectData(0);
        }
      } else if (res.data.status === 0) {
        if (localStorage.getItem("cartValue")){
          setSearchfoodData(JSON.parse(localStorage.getItem("cartValue")));
        }else{
          setSearchfoodData([])
        }
        setsearchdata(true) 
      }
    });
  };

  // function to calculate total value after importing local and api

  const getNewTotalValueAndCount = (newarray) => {
    let totalarray = 0;
    let totalitemcount = 0;
    newarray.map((item) => {
      totalarray += item.total;
      totalitemcount += item.count;
    });
    setTotalamount(totalarray);
    setTotalitemcount(totalitemcount);
  };

  // Food Order Api
  const foodorder = () => {
    let arr = [];
 
    let newArr = totalValueArray == 0 ? searchfoodData : addtocart;
    newArr.forEach((ele) => {
      let obj = {};
      if (ele.select == true) {
        obj.item_quantity = ele.count;
        obj.per_item_price = ele.food_price;
        obj.total_price = ele.total;
        obj.item_id = ele.id;
        obj.food_item_id = ele.food_cat_id;
        arr.push(obj);
      }
    });
    let vendorId = newArr[0].vendor_id;
    const postData = {
      food_total_amount_without_service_fee: totalamount,
      service_fee_amount: CommissionServiceTax,
      service_fee_percentage: commisionfood,
      vendor_id: vendorId,
      final_food_amount: GrandTotalAmount,
      event_id: Eventid,
      location_id: locid,
      event_booking_number: BookingorderNumber,
      items: arr,
    };

    http("event_food/order_event_food", "POST", postData).then((res) => {
      if (res.data.status === 1) {
        NotificationManager.success(res.data.msg);
        localStorage.removeItem("cartValue");
        localStorage.removeItem("totalAmountArr");

        navigate(`/OrderDetails/${localOrderParamID}`);
      } else {
        NotificationManager.error(res.data.msg);
      }
    });
  };

  const Orderconfirm = (e) => {
    let newArr = totalValueArray == 0 ? searchfoodData : addtocart;
    let arr = [];
    newArr.forEach((element) => {
      if (element.select === true) {
        arr.push(element);
      }
    });
    if (arr.length > 0) {
      handleOpen1();
    } else {
      NotificationManager.error("Please select atleast one item", "", 1000);
    }
  };









  const [orders, setOrders] = useState([]);
  const [results, setResults] = useState([]);
 

  const [searchedWorker, setSearchedWorker] = useState("");

  useEffect(() => {
    const filtered = orders.filter((order) =>
      order.food_title.toLowerCase().includes(searchedWorker)
    );
    setResults(filtered);
  }, [searchedWorker, orders]);


 <input
                type="text"
                class="input-bordered inputSearch"
                style={{ float: "right" }}
                placeholder="Search by EventName"
                value={searchedWorker} //property specifies the value associated with the input
                onChange={(e) =>
                  setSearchedWorker(e.target.value.toLowerCase())
                }
              /> 








  return (
    <Box className="mainContainer">
      {" "}
      <Grid
        container
        alignItems="center"
        sx={{ marginBottom: "30px !important" }}
      >
        <Grid item xs={12} sm={6} md={6}>
          <Typography className="layoutTitle d-flex justify-align-item">
            FoodCart
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} md={6} className="searchEventFav">
        {/* <input
                type="text"
                class="input-bordered inputSearch"
                style={{ float: "right" }}
                placeholder="Search by EventName"
                value={searchedWorker} //property specifies the value associated with the input
                onChange={(e) => {
                  setSearchedWorker(e.target.value.toLowerCase())
                  Searchfoodorder(e.target.value);
                }}
              /> */}





          <div>
            <input
              type="text"
              class="input-bordered inputSearch"
              style={{ float: "right" }}
              placeholder="Search"
              onChange={(e) => {
                setFilter(e.target.value);
                Searchfoodorder(e.target.value);
              }}
            />
          </div>
        </Grid>
      </Grid>
      <div className="hLine"></div>
      {showAutoSelectData == 1 ? (
        <Grid spacing={3} container sx={{ marginTop: "10px", mb: 12 }}>
          {searchfoodData.map((ele, index) => (
            <Grid item xs={12} md={6} lg={6} sx={{ marginBottom: "10px" }}>
              {ele.company_name ? ele.company_name : ele.vendor_name}
              {/* {ele.vendor_name} */}
              <Box className="SuperMartBox">
                <img
                  src={
                    ele.item_image
                      ? ele.item_image
                      : "assets/images/grredSalad.png"
                  }
                  alt="googleImg"
                  style={{ height: "120px", borderRadius: "10px !important" }}
                />

                <div className="SuperMartInnDiv">
                  <Typography className="VegSaladTyp">
                    {ele.food_title}
                  </Typography>
                  <Typography className="VegPlatterTypo">
                    {ele.item_name}
                  </Typography>
                  <div className="SuperPlusMin">
                    <Typography className="SuperPrice">
                      Price:{" "}
                      <span style={{ color: "rgba(12, 168, 246, 1)" }}>
                        {" "}
                        {getSymbolFromCurrency(ele.currency)} {ele.food_price}{" "}
                      </span>
                    </Typography>

                    {ele.select ? (
                      <>
                        <Button
                          disabled={count <= 1 ? true : false}
                          aria-label="reduce"
                          onClick={(e) => {
                            AddonscountMinusSearch(e, index);
                          }}
                          sx={{
                            padding: "0px !important",
                            margin: "0px !important",
                          }}
                        >
                          <img src="assets/images/minus.png"></img>
                        </Button>
                        <div className="detailBottomL"></div>
                        <Box className="Textnumbers">{ele.count}</Box>
                        <Button
                          aria-label="increase"
                          onClick={(e) => addOnClickFunction(e, index)}
                          sx={{
                            padding: "0px !important",
                            margin: "0px !important",
                          }}
                        >
                          <img src="assets/images/plus.png"></img>
                        </Button>
                      </>
                    ) : (
                      <Button
                        className="SuperBtn"
                        onClick={(e) => addOnClickFunction(ele, index)}
                      >
                        Add
                      </Button>
                    )}
                  </div>
                </div>
              </Box>
            </Grid>
          ))}
        </Grid>
      ) : (
        ""
      )}
      {
        <Grid spacing={3} container sx={{ marginTop: "0px", mb: 12 }}>
       

       {/* {searchfoodData
          .filter((val) => {
            if (filters === "") {
              return val;
            } else if (
              val.food_title.toLowerCase().includes(filters?.toLowerCase())
            ) {
              return val;
            }
          }) */}


          {searchfoodData.length > 0 ? (
            searchfoodData
              .filter((val) => {
                if (filters === "") { 
                  return val;
                } else if (
                  val.food_title.toLowerCase().includes(filters?.toLowerCase()) ||
                  val.item_name.toLowerCase().includes(filters?.toLowerCase()) 
                  // item_name

                ) {
                  return val;
                }
              })

              .map((ele, index) => (
              
                <Grid
                  item
                  xs={12}
                  md={5.5}
                  lg={5.5}
                  sx={{ marginBottom: "10px" }}
                >
                  {ele.company_name ? ele.company_name : ele.vendor_name}
                  {/* {ele.company_name ? ele.company_name : ele.vendor_name} {ele.id} */}
               
                  <Box className="SuperMartBox">
                    <img
                      src={
                        ele.item_image
                          ? ele.item_image
                          : "assets/images/grredSalad.png"
                      }
                      alt="googleImg"
                      style={{
                        height: "120px",
                        borderRadius: "10px !important",
                      }}
                    />

                    <div className="SuperMartInnDiv">
                      <Typography className="VegSaladTyp">
                        {ele.food_title}
                      </Typography>
                      <Typography className="VegPlatterTypo">
                        {ele.item_name}
                      </Typography>
                      <div className="SuperPlusMin">
                        <Typography className="SuperPrice">
                          Price:{" "}
                          <span style={{ color: "rgba(12, 168, 246, 1)" }}>
                            {" "}
                            {getSymbolFromCurrency(ele.currency)}{" "}
                            {ele.food_price}{" "}
                          </span>
                        </Typography>

                        {ele.select ? (
                          <>
                            <Button
                              // disabled={count <= 1 ? true : false}
                              aria-label="reduce"
                              onClick={(e) => {
                                minusOnClickFunction(e, index);
                              }}
                              sx={{
                                padding: "0px !important",
                                margin: "0px !important",
                              }}
                            >
                              <img src="assets/images/minus.png"></img>
                            </Button>
                            <div className="detailBottomL"></div>
                            <Box className="Textnumbers">{ele.count}</Box>
                            <Button
                              aria-label="increase"
                              onClick={(e) => addOnClickFunction(e, index)}
                              sx={{
                                padding: "0px !important",
                                margin: "0px !important",
                              }}
                            >
                              <img src="assets/images/plus.png"></img>
                            </Button>
                          </>
                        ) : (
                          <Button
                            className="SuperBtn"
                            handleOpen1
                            onClick={(e) => addOnClickFunction(ele, index)}
                          >
                            Add
                            {/* Mukul Mukul */}
                          </Button>
                        )}
                      </div>
                    </div>
                  </Box>
                </Grid>
              ))
          ) : (
            <h2 style={{ textAlign: "center", width: "100%" }}>


              {searchdata ? "No Record Found": "No items(s) in cart. Search and add food item(s) to cart."}
             {/* No items(s) in cart. Search and add food item(s) to cart. */}
             
            </h2>
          )}
        </Grid>
      }
      <Box className="flexGrid grid33">
        <Typography className="orderText">
          {" "}
          {totalitemcount} item(s)Total
        </Typography>
        <Typography></Typography>
        <Typography className="orderText colorBlue">
          {curr}
          {parseFloat(totalamount).toFixed(2)}
        </Typography>
      </Box>
      <Box className="flexGrid grid33">
        <Typography className="orderText">Service Tax</Typography>
        <Typography></Typography>
        <Typography className="orderText colorBlue">
          {curr}
          {parseFloat(CommissionServiceTax).toFixed(2)}
        </Typography>
      </Box>
      <Box className="flexGrid grid33">
        <Typography className="orderText">Grand Total</Typography>
        <Typography></Typography>
        <Typography className="orderText colorBlue">
          {curr}
          {parseFloat(GrandTotalAmount).toFixed(2)}
        </Typography>
      </Box>
      <div className="hLine"></div>
      <Box
        sx={{
          textAlign: "center",
          padding: "24px 0px 0px 0px",
          marginBottom: "20px",
        }}
      >
        <Button
          type="button"
          className="proceedBtnPay"
          onClick={(e) => Orderconfirm(e)}
        >
          Order
        </Button>
      </Box>
      {/* Modal for VendorIdentify */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="ModalClass"
      >
        <Box sx={style} className="ModalClassBox">
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{ textAlign: "right", padding: "16px", mt: "2" }}
              className="ModalTopGrid"
            >
              <CloseIcon className="closeIconOffer" onClick={handleClose} />
              <Typography
                id="modal-modal-description"
                className="ModalPhomolong"
                sx={{ textAlign: "center" }}
              >
                Alert{" "}
              </Typography>
              <Typography
                sx={{
                  padding: "20px 0px",
                  textAlign: "center",
                }}
              >
                Your cart contains food(s) from {nextvendorname} .Do you want to
                removed all the selection and add food(s) from{" "}
                {previousVendorname} ?
              </Typography>

              <Typography
                sx={{
                  display: "flex",
                  padding: "20px 0px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button class="btn bookTicketBtn" onClick={handleClose}>
                  no
                </Button>
                <Button class="btn viewBtn" onClick={(e) => HandleReplace()}>
                  Replace
                </Button>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      {/* Modal for OrderCheck */}
      <Modal
        open={open1}
        onClose={handleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="ModalClass"
      >
        <Box sx={style} className="ModalClassBox">
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{ textAlign: "right", padding: "16px", mt: "2" }}
              className="ModalTopGrid"
            >
              <CloseIcon className="closeIconOffer" onClick={handleClose1} />
              <Typography
                id="modal-modal-description"
                className="ModalPhomolong"
                sx={{ textAlign: "center" }}
              >
                Alert{" "}
              </Typography>
              <Typography
                sx={{
                  padding: "20px 0px",
                  textAlign: "center",
                }}
              >
                Do you Want to order seleted food(s)
              </Typography>

              <Typography
                sx={{
                  display: "flex",
                  padding: "20px 0px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button class="btn bookTicketBtn" onClick={handleClose1}>
                  CANCEL
                </Button>
                <Button class="btn viewBtn" onClick={(e) => foodorder(e)}>
                  CONTINUE
                </Button>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Box>
  );
};

export default FoodCart;