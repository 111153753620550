import React, { Suspense, useEffect } from "react";
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";
// Loader
import { ColorRing } from "react-loader-spinner";
import { Box } from "@mui/system";
import Home from "../Screens/Home";
import Aboutus from "../Screens/Aboutus";
import Menu from "../Screens/Menu";
import Upcoming from "../Screens/Upcoming";
import Offer from "../Screens/Offers";
import EventDetails from "../Screens/EventDetails";
import VenueList from "../Screens/VenueList";
import MyBooking from "../Screens/MyBooking";
import Booking from "../Components/Booking";
import OrderDetails from "../Screens/OrderDetails";
import BookingDetails from "../Components/BookingDetails";
import MyFavorite from "../Screens/MyFavorite";
import ViewAll from "../Screens/ViewAll";
import ViewAllSubcategory from "../Screens/ViewAllSubOld";
import Favorite from "../Components/Favorite";
import BookTicket from "../Screens/BookTicket";
import OffersList from "../Components/OffersList";
import MyProfile from "../Screens/MyProfile";
import Profile from "../Components/Profile";
import MyWallet from "../Screens/MyWallet";
import SeatBookingOrder from "../Screens/SeatBookingOrder";
import Contestants from "../Components/Contestants";
import ContestantsForm from "../Screens/ContestantsForm";
import FeaturesDetails from "../Components/FeaturesDetails";
import Features from "../Screens/Features";
import Pricing from "../Screens/Pricing";
import HowItWorks from "../Screens/HowItWorks";
import CreateYourEvent from "../Screens/CreateYourEvent";
import ContactUs from "../Screens/ContactUs";
import PrivacyPolicy from "../Screens/PrivacyPolicy";
import TermsCondition from "../Screens/TermsCondition";
import MobileTermsConditions from "../Screens/MobileTermsCondition";
import DeliveryOption from "../Screens/DeliveryOption";
import PaymentOptions from "../Screens/PaymentOptions";
import RefundPolicy from "../Screens/RefundPolicy";
import PopiAct from "../Screens/PopiAct";
import SearchResult from "../Screens/SearchResult";
import MobilePrivacyPolicy from "../Screens/MobilePrivacyPolicy";

import SelectLocation from "../Components/SelectLoction";
import Solution from "../Screens/Solution";
import SeatBooking from "../Components/SeatBooking";
import Faq from "../Screens/Faq";
import VendorLists from "../Screens/VendorLists";
import SuperMarts from "../Screens/SuperMarts";
import Foodcart from "../Screens/Foodcart";

import Hardware from "../Screens/Hardware";
import Buy from "../Screens/Buy";
import Partnership from "../Screens/Partnership";
import AllEvents from "../Components/AllEventComponent";
import PageNotFound from "../Screens/PageNotFound";
import EventCategories from "../Screens/EventCategories";
import SeatBookingIo from "../Screens/SeatBooking";
import FloorPlan from "../Components/FloorPlan";
import ViewAllReview from "../Components/ViewAllReview";
import PackageDetails from "../Screens/PackageDetails";
import PromotionalDetails from "../Screens/PromotionalDetails";

// testing routes
import StripeIntegration from "../Screens/StripeIntegration";
import PaymentStatus from "../Screens/PaymentStatus";

const Navigation = () => {
  let islogged = localStorage.getItem("loginUser")
    ? JSON.parse(localStorage.getItem("loginUser"))
    : "";

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [islogged.id ? islogged.id : ""]);

  return (
    <BrowserRouter
    // basename = {"/website"}
    >
      <Suspense
        fallback={
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <ColorRing />
          </Box>
        }
      >
        <Routes>
          <Route path="/" element={<Navigate replace to={`/home`} />} />
          <Route path={`/home`} element={<Home />}>
            <Route path=":cityname" element={<Home />} />
          </Route>

          <Route path="/eventcategories" element={<EventCategories />} />
          <Route path="/aboutus" element={<Aboutus />} />
          <Route path="/menu" element={<Menu />} />
          <Route path="events/:eventname/:id" element={<EventDetails />} />
          <Route
            path="/vendorlist/:eventid/:locationid"
            element={<VendorLists />}
          />
          <Route path="/supermarts" element={<SuperMarts />} />
          <Route path="/seatbookingio" element={<SeatBookingIo />} />
          <Route path="/hardware" element={<Hardware />} />
          <Route path="/buy" element={<Buy />} />
          <Route path="/partnership" element={<Partnership />} />
          <Route path="/allevents" element={<AllEvents />} />

          <Route path="/foodsearch" element={<Foodcart />} />

          <Route path="/solution" element={<Solution />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/upcoming" element={<Upcoming />} />
          <Route path="/Offers" element={<Offer />} />
          <Route path="/bookticket/:id" element={<BookTicket />} />
          <Route path="/seatbookingorder" element={<SeatBookingOrder />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/howitworks" element={<HowItWorks />} />
          <Route path="/createyourwork" element={<CreateYourEvent />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route
            path="/mobile-privacy-policy"
            element={<MobilePrivacyPolicy />}
          />
          <Route path="/termscondition" element={<TermsCondition />} />
          <Route
            path="/mobile-terms-condition"
            element={<MobileTermsConditions />}
          />

          <Route path="/PaymentOptions" element={<PaymentOptions />} />
          <Route path="/DeliveryOption" element={<DeliveryOption />} />
          <Route path="/refundpolicy" element={<RefundPolicy />} />
          <Route path="/popiact" element={<PopiAct />} />
          <Route path="/searchresult" element={<SearchResult />} />
          <Route path="/venueList" element={<VenueList />} />
          <Route
            path="/MyBooking"
            element={islogged ? <MyBooking /> : <Navigate to="/home" />}
          />
          <Route path="/Booking" element={<Booking />} />
          <Route path="/OrderDetails/:id" element={<OrderDetails />} />
          <Route path="/BookingDetails" element={<BookingDetails />} />
          <Route
            path="/MyFavorite"
            element={islogged ? <MyFavorite /> : <Navigate to="/home" />}
          />
          <Route path="/ViewAll/:name" element={<ViewAll />} />
          <Route
            path="/ViewAllSubcategory/:id"
            element={<ViewAllSubcategory />}
          />
          <Route path="/Favorite" element={<Favorite />} />
          <Route path="/OffersList" element={<OffersList />} />

          <Route
            path="/MyProfile"
            element={islogged ? <MyProfile /> : <Navigate to="/home" />}
          />
          <Route path="/Profile" element={<Profile />} />
          <Route
            path="/MyWallet"
            element={islogged ? <MyWallet /> : <Navigate to="/home" />}
          />

          <Route
            path="/ContestantsForm/:ename/:id"
            element={<ContestantsForm />}
          />
          <Route path="/Contestants" element={<Contestants />} />
          <Route path="/Features" element={<Features />} />
          <Route path="/FeaturesDetails" element={<FeaturesDetails />} />
          <Route path="/floor-plan" element={<FloorPlan />} />
          <Route
            path="/BookTicket/select-location"
            element={<SelectLocation />}
          />
          <Route
            path="/order-preview"
            element={islogged ? <SeatBooking /> : <Navigate to={"/"} />}
          />
          <Route path="/ViewAllReview" element={<ViewAllReview />} />
          <Route path="/package-details" element={<PackageDetails />} />

          <Route path="/stripe-integration" element={<StripeIntegration />} />
          <Route path="*" element={<PageNotFound />} />
          <Route
            path="/promotional-details/:id"
            element={<PromotionalDetails />}
          />
          <Route path="/eft/payment-status" element={<PaymentStatus/>} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default Navigation;
