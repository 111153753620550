import React from 'react'
import { Grid } from '@mui/material'
import { Box } from '@mui/material'
import { Typography } from '@mui/material'
import Button from '@mui/material/Button';
import {useTranslation} from 'react-i18next'

import './style.css'

const HowWork = () => {
  const {t} = useTranslation("common")
  return (
    <Box className='mainContainer'>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6} lg={6} className='howWorkImgGrid'>
          <img src='assets/images/howWorkImg.png' alt='how-work' className='howWorkImg' />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Typography className='getStartNew'>{t("Whether_you_are_a_professional_social_entrepreneur_or_corporate_looking_for_ticketing_or_rsvp_platform,_Ticketkore_platform_is_smart_ticketing_and_reservation_platform_developed_to_cater_for_ticketing_and_reservation_needs")}.</Typography>
          <Typography className='getStartClick'>{t("Click")} <a href='#' className='getLinkClass'>here</a>  to get started or <a href='#' className='getLinkClass'>{t("log_in")}</a>  {t("of_you_already_have_an_account")}.</Typography>

        </Grid>
      </Grid>
    </Box>

    
  )
}

export default HowWork