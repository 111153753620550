import React, { useEffect } from "react";
import Navbar from "../../Components/Navbar";
//import BreadCrumbs from '../../Components/BreadCrumbs';
import Footer from "../../Components/Footer";
import BookingDetails from "../../Components/BookingDetails";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Breadcrumbs, Typography } from "@mui/material";
import { Box } from "@mui/system";
import {useTranslation} from 'react-i18next'
// import BottomScroll from "../../Components/BottomScroll";
import "./style.css";

const OrderDetails = () => {
  const {t} = useTranslation("common")
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation(); 

  
  //   const handleBackButton = (event) => {
  //     if(location.state && location.state.fromMyBooking){ 

  //     navigate(`/OrderDetails/${params.id}`) 
  //   }

  //  };

    // window.addEventListener('popstate', handleBackButton);
 useEffect(()=>{
  window.history.pushState(null, null, document.URL);
  window.addEventListener('popstate', function(event) {
    // event.preventDefault()
        if(location.state && location.state.fromMyBooking){   
       navigate(`/OrderDetails/${params.id}`) 
       return
  }else{
    navigate('/MyBooking')
  }
  });

 },[])



  const breadcrumbs = [
    <Link to={"/"} key="1" className="link-decoration">
      {t("Home")}
    </Link>,
    <Link to={"/MyBooking"} key="2" className="link-decoration">
     {t("My_Booking")}
  </Link>,

    <Typography
      key="3"
      sx={{
        fontFamily: "Lato",
        color: "#0CA8F6",
        fontWeight: 500,
        fontSize: "16px",
        
      }}
    >
      {t("Order_Details")}
    </Typography>,
  ];
  return (
    <>
      <Navbar />
      {/* <MenuBar /> */}
      <Box className="mainContainer">
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          sx={{ mb: 3, mt: 2 }}
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Box>
      <BookingDetails />
      {/* <BottomScroll/> */}
      <Footer />
    </>
  );
};

export default OrderDetails;
