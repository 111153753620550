import React from 'react'
import { Grid, Typography } from '@mui/material'
import { Box } from '@mui/material'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import {useTranslation} from 'react-i18next'
import './style.css'

const CustomerServices = () => {
    const {t} = useTranslation("common")
    return (

        <Box className='custmConatactDivWidth' sx={{marginBottom: '40px'}}>

            <Grid container spacing={2}>             
                <Grid item xs={12} sm={12} md={12} className='custmSerGrid'>
                    <Typography className='custmSer'>{t("Customer Services")}</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} className='GridCommonFullService' sx={{height: '100%'}}>
                    <div className='CommonFullService'>
                        <div className='CommonFullServiceInner'>
                            <Typography className='FullCustmTypo'>{t("Full_Service_Customer_Care")}</Typography>
                            <Typography className='DeserveTypo'>{t("You deserve hassle-free assistance from start to finish")}</Typography>
                        </div>
                    </div>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} className='GridCommonFullService' sx={{height: '100%'}}>
                    <div className='CommonFullService'>
                        <div className='CommonFullServiceInner'>
                            <Typography className='FullCustmTypo'>{t("Safe & Secure Transactions")}</Typography>
                            <Typography className='DeserveTypo'>{t("Your private and personal information should remain private and personal")}.</Typography>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </Box>

    )
}

export default CustomerServices