import Navigation from "./Routes/Navigation";
import 'react-notifications/lib/notifications.css';
import ModalCountryListDropDown from "./Components/ModalCountryListDropDown";
// import { Provider } from 'react-redux';
// import {store} from './store';
import http from"./Services/http"
import { useEffect, useState } from "react";
import { ManagePageReloadState } from "./Services/ManagePageReloadState";
import { CommunicationService } from "./Services/CommunicationService";


// import { useLocation } from "react-router-dom";

function App() {
  const [selectCountry, setSelectCountry] = useState(false)
  const countryList = require("./countries.json");
  


 
 
  useEffect(()=>{
    
    if(window.location.pathname === '/mobile-terms-condition' || window.location.pathname === '/website/mobile-terms-condition' || window.location.pathname === '/mobile-privacy-policy' || window.location.pathname === '/website/mobile-privacy-policy' ){
      // console.log('still calling')
    }else{
    getCurrentLocation()    
    CommunicationService.getData().subscribe(res => {
      if (res) {
        const { data } = res;
        // setSelectedCountryData(data)

        localStorage.setItem('autoCountry',JSON.stringify(data))
        setSelectCountry(false)
      }
    });
  }
  },[])
  const getCurrentLocation = ()=>{
    let lattitude = "";
    let longtitude = "";

    window.navigator.geolocation.getCurrentPosition(function name(params) {
      lattitude = params.coords.latitude;
      longtitude = params.coords.longitude;
      // setOpenMapDrop(props.OpenCountryPopup ? props.OpenCountryPopup : false);

      let usercountry = {
        lat: lattitude,
        long: longtitude,
      };
      !localStorage.getItem('autoCountry') ?
      http("country_details/website_country_list", "POST", usercountry).then(
        (response) => {
        if(response.data.status === 0){
          setSelectCountry(true)
        }
        localStorage.setItem('autoCountry',JSON.stringify(response.data.data.country))
        ManagePageReloadState.setPageReloadState(true)
        }
      ) : <></>
    }, err =>{
      if(!localStorage.getItem('autoCountry'))
        setSelectCountry(true)
      // localStorage.getItem('autoCountry') ? <></> : <ModalCountryListDropDown />
    });
  }


  return (
    <div className="App">
        <Navigation />
        {selectCountry ? <ModalCountryListDropDown  open={true} searchCountry={countryList}/> : ""}
   </div>
  );
}

export default App;
