import ArrowCircleUpRoundedIcon from '@mui/icons-material/ArrowCircleUpRounded';
import { IconButton } from "@mui/material";
	import "./Style.css"
const BottomScroll = () =>{
 
return (
    <IconButton className='bottomTotop'
    onClick={() => {
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }} 
  >
   <ArrowCircleUpRoundedIcon fontSize={"large"} />
  </IconButton>
);
}
	
export default BottomScroll;
