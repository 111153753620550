import React, { useEffect, useState } from 'react'
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";

import http from "../../Services/http"
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link, useLocation } from "react-router-dom";
import { Breadcrumbs, Typography } from "@mui/material";
import ClipLoader from "react-spinners/ClipLoader";
import { Box } from "@mui/system";
import "./style.css";
import { Grid } from "@mui/material";
import getSymbolFromCurrency from 'currency-symbol-map'

const PackageDetails = () => {
  const { state } = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const breadcrumbs = [
    <Link to={"/"} key="1" className="link-decoration">
      Home
    </Link>,
    <Link

      to={`/OrderDetails/${state.OrderID}`}
      key="2"
      className="link-decoration"
    >
      OrderDetails
    </Link>,


    <Typography
      key="3"
      sx={{
        fontFamily: "Lato",
        color: "#0CA8F6",
        fontWeight: 500,
        fontSize: "16px",

      }}
    >
      Package Details
    </Typography>,
  ];


  useEffect(() => {
    getPackageDetails()
  }, [])
  const [packageDetails, setpackageDetails] = useState({})
  const [packageHistory, setPackageHistory] = useState([])
  const getPackageDetails = () => {
    let postData = {
      order_number: state.OrderNum
    }
    http('orders/order_package_history', 'POST', postData)
      .then((res) => {
        setIsLoading(false); 
        setpackageDetails(res.data.data.package_details)
        setPackageHistory(res.data.data.package_txn_history)
      })
  }

  return (
    <>
      <Navbar />
      {/* <MenuBar /> */}
      <Box className="mainContainer">
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          sx={{ mb: 3, mt: 2 }}
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Box>
      {isLoading ? (
        <div style={{ height: "400px" }}>
          <div className="loader ">
            <ClipLoader />
          </div>
        </div>
      ) : (
        <Box className="mainContainer">
          <Box className='packageheading-box' sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingTop: '10px' }}>
            <item>
              <Typography className="Package-Details mobOlLayoutTitle">
                Package Details
              </Typography>
            </item>
            <item>
              <Typography className='Remaining-Balance'>
                {packageDetails.offer_type === "1" ? <Box> Remaining Balance: <span className='Remaining-price'> {getSymbolFromCurrency(packageDetails.currency)} {packageDetails.remaining_wallet_offer_amount}</span></Box>

                  : packageDetails.offer_type === "2" ? <Box> Remaining Items : <span className='Remaining-price'>{packageDetails.remaining_offer_item_count} </span></Box> : <></>
                }
              </Typography>
            </item>
          </Box>
          <div className="hLine"></div>
          <Grid container spacing={4} sx={{ marginBottom: '34px' }}>
            <Grid item xs={12} sm={12} md={4} lg={4} sx={{ marginTop: "15px" }} className="package-detail-Grid">
              <img
                src="assets/images/package-img.png"
                alt="package img"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8} sx={{ marginTop: "15px" }} >

              <Box sx={{ background: '#FFFFFF', boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.25)', borderRadius: '10px', padding: '37px 36px', marginBottom: '34px' }}>
                <Grid item xs={12} md={12}>
                  <Typography style={{
                    fontFamily: 'Lato',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: '22px',
                    lineHeight: '26px'
                  }}>
                    Descriptions
                  </Typography>
                  <Grid>
                    <div dangerouslySetInnerHTML={{ __html: packageDetails.package_details }} />
                  </Grid>
                </Grid>
              </Box>
              {packageHistory.length > 0 ?
                <Box sx={{ background: '#FFFFFF', boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.25)', borderRadius: '10px', padding: '37px 36px' }}>


                  <Grid item xs={12} md={12}>
                    <Typography className="Package-Details-heading">Package Details</Typography>
                    <div className="hLine"></div>
                    {packageHistory.map((packages) => (
                      <Box sx={{ width: '100%', background: '#FFFFFF', boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.25)', borderRadius: '10px', marginBottom: '30px' }}>
                        <Box sx={{ padding: "18px 18px 10px 18px" }}>
                          <Typography className='TXN-No'> TXN No.: <span className='colorBlue'>{packages.txn_number}</span></Typography>
                        </Box>
                        <div className="hLine"></div>
                        <Box sx={{ padding: "10px 18px 12px 18px" }}>
                          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingLeft: "10px", paddingRight: "10px" }}>
                            <item>
                              <Typography>
                                Type: <span className='colorBlue'>{packages.type}</span>
                              </Typography>
                            </item>
                            <item>
                              <Typography>
                                {packages.created_at}
                              </Typography>
                            </item>
                          </Box>
                          <Box className="">
                            <Box className="flexGrid totalTicketTitleHeading grid33" style={{ borderBottom: 'none' }}>
                              <Typography className="totalTicketTitle">Item Name</Typography>
                              <Typography className="totalTicketTitle">
                                Quantity
                              </Typography>
                            </Box>
                            {packages.items.map((item) => (
                              <Box className="flexGrid grid33">
                                <Typography className="packageText" sx={{overflowWrap:"break-word"}}>
                                  {item.item_name}
                                </Typography>
                                <Typography className="packageText">
                                  {item.item_quantity}
                                </Typography>
                              </Box>
                            ))}

                            <Box className="flexGrid grid33" style={{ background: '#F7F7F7', borderTop: '1px solid #d9d9d9', padding: '12px 16px' }}>
                              <Typography className="totalTicketTitle">
                                Total Price:
                              </Typography>
                              <Typography></Typography>
                              <Typography className="totalTicketTitle colorBlue">
                                {getSymbolFromCurrency(packages.currency)} {packages.wallet_txn_amount}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    ))}





                  </Grid>


                </Box> : <></>}
            </Grid>
          </Grid>
        </Box>
      )}
      <Footer />
    </>
  )
}

export default PackageDetails