import React from "react";
import Navbar from "../../Components/Navbar";
import MenuBar from "../Menu";
import BreadCrumbs from "../../Components/BreadCrumbs";
import SelectLoction from "../../Components/SelectLoction";
import TicketTotalPrice from "../../Components/TicketTotalPrice";
import Footer from "../../Components/Footer";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link, useLocation } from "react-router-dom";
import { Breadcrumbs, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useParams } from "react-router-dom";
import "./style.css";

const BookTicket = () => {
  const location = useLocation();
  const Eventname =
    location?.state?.eventdetailspage1?.eventdetailspage1?.event_name;
  const params = useParams();
 // console.log(Eventname)
let name = Eventname.replaceAll(/[^a-zA-Z0-9 ]/g , "");
  const breadcrumbs = [
    <Link to={"/"} key="1" className="link-decoration">
      Home
    </Link>,

    <Link to={`/events/${name.replaceAll(' ' ,'-')}/${params.id}`} key="1" className="link-decoration"   style={{color:"#0CA8F6"}}>
      {Eventname ? Eventname : "Event Details"}
    </Link>,

    <Typography
      key="3"
      sx={{
        fontFamily: "Lato",
        color: "#0CA8F6",
        fontWeight: 500,
        fontSize: "16px",
      }}
    >
      Book Tickets
    </Typography>,
  ];
  return (
    <>
      <Navbar />
      {/* <MenuBar /> */}
      <Box className="mainContainer">
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          sx={{ mb: 3, mt: 2 }}
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Box>
      <SelectLoction />
      {/* <TicketTotalPrice /> */}
      <Footer />
    </>
  );
};

export default BookTicket;
