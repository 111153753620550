import React from 'react'
import Navbar from '../../Components/Navbar'
import MenuBar from '../../Components/MenuBar'
const Menu = () => {
  return (
<>
 {/* <Navbar/> */}
{/* <MenuBar /> */}
</>
  )
}

export default Menu