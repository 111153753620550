import React from "react";
import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import checkValidationFormAllControls from "../../shared/validation";
import { useEffect, useState } from "react";
import http from "../../Services/http";
import { NotificationManager } from "react-notifications";
import { useNavigate } from "react-router-dom";
import { SpinnerCircular } from "spinners-react";

import "./style.css";
// import { NotificationManager } from "react-notifications";
// import { useNavigate } from "react-router-dom";
// import http from "../../Services/http";

const EventCreate = () => {
  const navigate = useNavigate();
  const [errorDisplay, seterrorDisplay] = useState("");
  const [name, setName] = React.useState("");
  const [email_address, setEmail_address] = React.useState("");
  const [last_name, setLast_name] = React.useState("");
  const [business_name, setBusiness_name] = React.useState("");
  const [registration_number, setRegistration_number] = React.useState("");
  const [country_name, setCountry_name] = React.useState("");
  const [phone_number, setPhone_number] = React.useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = (event) => {
   
    let elements = event.target.form.elements;
    var objValidation = {};
    objValidation = checkValidationFormAllControls(
      document.forms[0].elements,
      false,
      []
    );
    if (objValidation.valid !== false) {
      seterrorDisplay(objValidation);
      return;
    } else {
      seterrorDisplay(objValidation);
    }

    event.preventDefault();
    setLoading(true);
    const CreateEventData = {
      first_name: name,
      last_name: last_name,
      email_id: email_address,
      phone_number: phone_number,
      business_name: business_name,
      registration_name: registration_number,
      country_name: country_name,
    };

    http("website_create_event/create_your_events", "POST", CreateEventData)
      .then((res) => {
        setLoading(false);
        if (res.data.status === 1) {
          setName("");
          setEmail_address("");
          setLast_name("");
          setBusiness_name("");
          setRegistration_number("");
          setCountry_name("");
          setPhone_number("");

          NotificationManager.success(res.data.msg);
        } else {
          NotificationManager.error(res.data.msg);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <Box className="mainContainer">
      <form name="create_event">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} className="PaddingTopEventClm">
            <Typography className="commonEventTypo">
              First Name <span className="ValidationRed">*</span>
            </Typography>
            <Box
              sx={{ display: "flex", alignItems: "flex-start" }}
              className="boxBorder_Bottom"
            >
              <img
                src="assets/images/userIcon.png"
                alt="userIcon"
                className="userIconImg"
              />
              <input
                name="name"
                required
                placeholder="Jane"
                fullWidth
                variant="standard"
                InputProps={{ disableUnderline: true, }}
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="inputFocusVisible"
                style={{border: 'none'}}
                minlength="4" maxlength="8"
                
                
              />
            </Box>
            <Box sx={{ marginTop: "5px" }}>
              {errorDisplay.name ? (
                <span className="red">{errorDisplay.name}</span>
              ) : (
                ""
              )}
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={4} className="PaddingTopEventClm">
            <Typography className="commonEventTypo">
              Last Name<span className="ValidationRed">*</span>
            </Typography>
            <Box
              sx={{ display: "flex", alignItems: "flex-start" }}
              className="boxBorder_Bottom"
            >
              <img
                src="assets/images/userIcon.png"
                alt="userIcon"
                className="userIconImg"
              />
              <input
                id="input-with-icon-textfield"
                placeholder="Cooper"
                name="last_name"
                required
                fullWidth
                variant="standard"
                InputProps={{ disableUnderline: true }}
                value={last_name}
                onChange={(e) => setLast_name(e.target.value)}
                className="inputFocusVisible"
                style={{border: 'none'}}
                minlength="4" maxlength="8"
              />
            </Box>
            <Box sx={{ marginTop: "5px" }}>
              {errorDisplay.last_name ? (
                <span className="red">{errorDisplay.last_name}</span>
              ) : (
                ""
              )}
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={4} className="PaddingTopEventClm">
            <Typography className="commonEventTypo">
              Business Name<span className="ValidationRed">*</span>
            </Typography>
            <Box
              sx={{ display: "flex", alignItems: "flex-start" }}
              className="boxBorder_Bottom"
            >
              <img
                src="assets/images/userIcon.png"
                alt="userIcon"
                className="userIconImg"
              />
              <input
                id="input-with-icon-textfield"
                placeholder="Janecoop"
                fullWidth
                variant="standard"
                InputProps={{ disableUnderline: true }}
                required
                name="business_name"
                value={business_name}
                onChange={(e) => setBusiness_name(e.target.value)}
                className="inputFocusVisible"
                style={{border: 'none'}}
                minlength="4" maxlength="8"
              />
            </Box>

            <Box sx={{ marginTop: "5px" }}>
              {errorDisplay.business_name ? (
                <span className="red">{errorDisplay.business_name}</span>
              ) : (
                ""
              )}
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={4} className="PaddingTopEventClm">
            <Typography className="commonEventTypo">
              Registration Number <span className="ValidationRed">*</span>
            </Typography>
            <Box
              sx={{ display: "flex", alignItems: "flex-start" }}
              className="boxBorder_Bottom"
            >
              <img
                src="assets/images/register.png"
                alt="register"
                className="userIconImg"
              />
              <input
                id="input-with-icon-textfield"
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 15);
                }}
                min={0}
                placeholder="registration_number"
                name="registration_number"
                type="number"                
                fullWidth
                variant="standard"
                InputProps={{ disableUnderline: true }}
                required
                value={registration_number}
                onChange={(e) => setRegistration_number(e.target.value)}
                className="inputFocusVisible"
                style={{border: 'none'}}
              />
            </Box>
            <Box sx={{ marginTop: "5px" }}>
              {errorDisplay.registration_number ? (
                <span className="red">{errorDisplay.registration_number}</span>
              ) : (
                ""
              )}
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={4} className="PaddingTopEventClm">
            <Typography className="commonEventTypo">
              Country Name<span className="ValidationRed">*</span>
            </Typography>
            <Box
              sx={{ display: "flex", alignItems: "flex-start" }}
              className="boxBorder_Bottom"
            >
              <img
                src="assets/images/country.png"
                alt="countryIcon"
                className="userIconImg"
              />
              <input
                id="input-with-icon-textfield"
                name="country_name"
                placeholder="India"
                fullWidth
                variant="standard"
                InputProps={{ disableUnderline: true }}
                required
                value={country_name}
                onChange={(e) => setCountry_name(e.target.value)}
                className="inputFocusVisible"
                style={{border: 'none'}}
                minlength="4" maxlength="8"
              />
            </Box>

            <Box sx={{ marginTop: "5px" }}>
              {errorDisplay.country_name ? (
                <span className="red">{errorDisplay.country_name}</span>
              ) : (
                ""
              )}
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={4} className="PaddingTopEventClm">
            <Typography className="commonEventTypo">
              Phone Number<span className="ValidationRed">*</span>
            </Typography>
            <Box
              sx={{ display: "flex", alignItems: "flex-start" }}
              className="boxBorder_Bottom"
            >
              <img
                src="assets/images/cellPhone.png"
                alt="cellPhone"
                className="userIconImg"
              />
              <input
                id="input-with-icon-textfield"
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 15);
                }}
                min={0}
                type="number"
                placeholder="8978548965"
                fullWidth
                variant="standard"
                InputProps={{ disableUnderline: true }}
                required
                value={phone_number}
                name="phone_number"
                onChange={(e) => setPhone_number(e.target.value)}
                className="inputFocusVisible"
                style={{border: 'none'}}
              />
            </Box>

            <Box sx={{ marginTop: "5px" }}>
              {errorDisplay.phone_number ? (
                <span className="red">{errorDisplay.phone_number}</span>
              ) : (
                ""
              )}
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={4} className="PaddingTopEventClm">
            <Typography className="commonEventTypo">
              Email Id<span className="ValidationRed">*</span>
            </Typography>
            <Box
              sx={{ display: "flex", alignItems: "flex-start" }}
              className="boxBorder_Bottom"
            >
              <img
                src="assets/images/EmailIcon.png"
                alt="userIcon"
                className="userIconImg"
              />
              <input
                name="email"
                type="email"
                required
                id="input-with-icon-textfield"
                placeholder="janecooper12@gmail.com"
                className="inputFocusVisible"
                fullWidth
                variant="standard"
                InputProps={{ disableUnderline: true }}
                value={email_address}
                onChange={(e) => setEmail_address(e.target.value)}                
                style={{border: 'none'}}
              />
            </Box>

            <Box sx={{ marginTop: "5px" }}>
              {errorDisplay.email ? (
                <span className="red">{errorDisplay.email}</span>
              ) : (
                ""
              )}
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            className="PaddingTopEventClmBtn"
            sx={{ textAlign: "center" }}
          >
            {/* <Button className="CreateBtn" onClick={(e) => handleSubmit(e)}>
              Create
            </Button> */}

            <Button
              disabled={loading}
              className="updateBtn"
              onClick={(e) => handleSubmit(e)}
            >
              {loading ? (
                <SpinnerCircular
                  style={{ width: "1.2rem", height: "1.2rem" }}
                  color="light"
                />
              ) : (
                "  Create"
              )}
            </Button>









          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default EventCreate;
