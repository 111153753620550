import config from "../config";
import axios from "axios";

export const callAPI = (url, type, postData, params, contentType = false, version) => {
  // const token = localStorage.getItem("token");

  const baseUrl = config.apiGateway.URL+ (version ? version + '/' : config.apiGateway.VERSION)
  
  const requestOptions = {
    method: type,
    url: baseUrl + url,
    // headers: { 'Content-Type': 'application/json'},
    headers: {
      "content-type": contentType ? "multipart/form-data" : "application/json",
    },
    data: contentType ? postData : JSON.stringify(postData),
    params: params,
  };
  return axios(requestOptions);
};




export default callAPI;
