import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography'; 
import CloseIcon from '@mui/icons-material/Close';
import "./style.css";



const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  
};

export default function RefTermsNCond({TermsAndCond}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const parse = require('html-react-parser');

  return (
    <Box sx={{  mr:2}}>
      <Button onClick={handleOpen} variant={"text"} sx={{textTransform:'capitalize'  }} >Refund Protect Policy</Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        clasName="refund-policy"
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style} className="refund-policy-box">
            <Box sx={{textAlign: 'end' ,  cursor:'pointer'}} onClick = {()=>setOpen(false)} ><CloseIcon /></Box>
          <Typography id="transition-modal-title" variant="h6" sx={{textAlign:'center'}} component="h2">
             Refund Policy
            </Typography>
           { parse(TermsAndCond)}
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
}
