import React from "react";
import { Box } from "@mui/system";
import { Grid, Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import {useTranslation} from 'react-i18next'
import "./style.css";

const ArtistDetails = (eventdetailspage1) => {
  const artistData = eventdetailspage1.eventdetailspage1 ?? [];
  const ArtistDetails = artistData.eventsArtist ?? [];
  const {t} = useTranslation('common')
  return (
    <>
      {ArtistDetails.length === 0 ? (
        ""
      ) : (
        <div>
          <Box className="mainContainer">
            <Grid container spacing={2}>
              <Grid item md={12} xs={12} lg={12}>
                <div>
                  <Typography className="artisHeading">
                    {t('Artist_Details')}
                  </Typography>
                  <div className="hrLine"></div>

                  <Stack
                    direction="row"
                    spacing={3}
                    className="scrollclassdir"
                    sx={{ marginTop: "20px", overflowX: "scroll" }}
                  >
                    {ArtistDetails.map((data, index) => (
                      <div
                        key={index}
                        className="commonArtistDiv"
                        
                      >
                        <Avatar
                         
                          src={
                            data.artist_photo
                              ? data.artist_photo
                              : "assets/images/Ellipse 23.png"
                          }
                          className="MobAvtarMargin"
                        />
                        <Typography className="labelArtist">
                          {data.artist_name}
                        </Typography>
                      </div>
                    ))}
                  </Stack>
                </div>
              </Grid>
            </Grid>
          </Box>
        </div>
      )}
    </>
  );
};

export default ArtistDetails;
