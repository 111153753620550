import React, { useEffect, useState } from "react";
import { SpinnerCircular } from "spinners-react";
import { Box } from "@mui/system";
import {
  Grid,
  Typography
} from "@mui/material";
import {useTranslation} from 'react-i18next'
import http from "../../Services/http";
import { useParams } from "react-router-dom";
import Modal from "@mui/material/Modal";
import ModalPopup from "../ModalPopup";
import CloseIcon from "@mui/icons-material/Close";
import Rating from "@mui/material/Rating";
import { NotificationManager } from "react-notifications";
 
import checkValidationFormAllControls from "../../shared/validation";
 

import "./style.css";

 

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 600,
  bgcolor: "#FFFFFF",
  boxShadow: 24,
  p: 4,
  width: "95%",
};

// allReviewdata
const AllReview = (props) => {
 
  const {t} = useTranslation('common')
  const { eventdetailspage1, dataget, getreviewid } = props;
  
  const params = useParams();
  const [loading, setLoading] = useState(false);
   
  const [open, setOpen] = React.useState(false);
  const [event_review, setEvent_review] = React.useState("");
  const [event_ratings, setEvent_ratings] = React.useState(1);
  const [errorDisplay, seterrorDisplay] = useState("");

 
  const checkLoggedIn = (e, params) => {
    if (!localStorage.getItem("token")) {
      setOpenLogin(true);
    } else {
      setOpen(true);
    }
  };

  // Module Hoc Function
  const [openLogin, setOpenLogin] = React.useState(false);
  const handleClose = () => {
    // setEvent_ratings("")
    // setEvent_review("")
    setOpenLogin(false);
    setOpen(false);
  };

  const Reviewid = eventdetailspage1?.review_data?.id;

  // Upcoming events data
  const ReviewCreation = () => {
    var objValidation = {};
    objValidation = checkValidationFormAllControls(
      document.forms[0].elements,
      false,
      []
    );
    if (objValidation.valid !== false) {
      seterrorDisplay(objValidation);
      return;
    } else {
      seterrorDisplay(objValidation);
    }

    setLoading(true);
    let postData = {
      userid: eventdetailspage1.id,

      eventid: params.id,
      event_review,
      event_ratings,

      review_id:
        getreviewid === undefined
          ? ""
          : getreviewid.length !== 0
          ? getreviewid[0].review_id
          : Reviewid,
    };
    http("events/review_creation", "POST", postData)
      .then((res) => {
        setLoading(false);
        // const data = res.data.data ?? [];

        if (res.data.status === 1) {
          NotificationManager.success(res.data.msg);
          setOpen(false);
          dataget("yes");
        } else {
          NotificationManager.error(res.data.msg);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setEvent_review(eventdetailspage1?.review_data?.review_in_text);
    setEvent_ratings(eventdetailspage1?.review_data?.ratings);
  }, [eventdetailspage1]);

  return (
    <div>
      <Box className="mainContainer">
        <Modal
          open={open}
          backdrop="static"
          //onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="ModalClass"
        >
          <Box sx={style} className="ModalClassBox">
            <Grid container>
              <Grid
                item
                xs={12}
                sx={{ textAlign: "right", padding: "16px", mt: "2" }}
                className="ModalTopGrid"
              >
                <CloseIcon className="closeIconOffer" onClick={handleClose} />
                <Typography
                  id="modal-modal-description"
                  className="ModalPhomolong"
                  sx={{ textAlign: "center" }}
                >
                  Review/Rating{" "}
                </Typography>

                <form id="ReviewSubmit">
                  <Typography sx={{ textAlign: "center" }}>
                    <Rating
                      // required={true}

                      defaultValue={0}
                      // name="ratings"
                      value={event_ratings}
                      onChange={(event, newValue) => {
                        setEvent_ratings(newValue);
                      }}
                      className="RatingStar"
                      size="large"
                    />
                    <div className="hrLine"></div>
                    <Typography
                      className="ModalPhomolong"
                      sx={{ textAlign: "center", paddingTop: "20px" }}
                    >
                      Write a comment
                    </Typography>
                    <Box sx={{ marginBottom: "20px" }}>
                      {errorDisplay.Ratings ? (
                        <span className="red">{errorDisplay.Ratings}</span>
                      ) : (
                        ""
                      )}
                    </Box>

                    <Box
                      noValidate
                      autoComplete="off"
                      className="formInputBox formInputBoxDouble"
                    >
                      <textarea
                        // required
                        maxLength={300}
                        name="Review"
                        id="outlined-basic"
                        variant="outlined"
                        size="large"
                        placeholder="Please submit your review here"
                        value={event_review}
                        onChange={(e) => setEvent_review(e.target.value)}
                        rows={12}
                        className="p-1"
                        style={{
                          width: "93%",
                          border: "1px solid #ddd",
                          borderRadius: "12px",
                        }}
                      ></textarea>
                      <Typography
                        sx={{
                          color: "#808080",
                          fontSize: "14px",
                          paddingRight: "13px",
                          textAlign: "right",
                        }}
                      >
                        Maximum character Limit 300
                      </Typography>
                      {errorDisplay.Review ? (
                        <span className="red">{errorDisplay.Review}</span>
                      ) : (
                        ""
                      )}
                    </Box>

                    {event_review === undefined ||
                    event_review === '' ||
                    event_ratings === undefined ? (
                      <button
                        style={{ background: "grey", border: "1px solid grey" }}
                        disabled={true}
                        className="viewBtn"
                      >
                        Submit
                      </button>
                    ) : (
                      <button
                        disabled={loading}
                        className="viewBtn"
                        onClick={ReviewCreation}
                      >
                        {loading ? (
                          <SpinnerCircular
                            style={{ width: "1.2rem", height: "1.2rem" }}
                            color="light"
                          />
                        ) : (
                          "Submit"
                        )}
                      </button>
                    )}
                  </Typography>
                </form>
                <div className="hrLine"></div>
              </Grid>
            </Grid>
          </Box>
        </Modal>

        <Grid container spacing={2} className="MobAllReviewGrid">
          <Grid item md={12} xs={12} lg={12}>
            <div>
              <Typography className="artisHeading"> {t('Reviews')}</Typography>
              <div className="hrLine"></div>
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={5}>
            <div className="ViewTopGrid">
              <div className="">
                <Typography className="addRatingTyp">
                {t('Add_your_rating_&_review')}
                </Typography>
                <Typography className="YourRatintTyp">
                {t('Your_ratings_matters')}
                </Typography>
              </div>
              <button className={eventdetailspage1.eventExpireFlag !== 0 ? "viewBtn" : 'viewBtn1'} disabled = {eventdetailspage1.eventExpireFlag !== 0 ? false : true} onClick={checkLoggedIn}>
              {t('Rate_Now')}
              </button>
            </div>

            {openLogin ? (
              <ModalPopup handleOpen={openLogin} handleClick={handleClose} />
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default AllReview;
