import {
  CircularProgress,
  FormHelperText,
  Grid, 
  MenuItem,
  Select,
} from "@mui/material";
import { Box } from "@mui/material";
import { Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import InputAdornment from "@mui/material/InputAdornment";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import OtpInput from "react-otp-input";
import http from "../../Services/http";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import Modal from "@mui/material/Modal";
import { SpinnerCircular } from "spinners-react";
import checkValidationFormAllControls from "../../shared/validation";
import FormControl from "@mui/material/FormControl";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import RemoveIcon from "@mui/icons-material/Remove";
import WcIcon from '@mui/icons-material/Wc';
import CustomBackdrop from "../CustomBackdrop"
import {useTranslation} from 'react-i18next'
//import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import "./style.css";
import CCSearch from "../../shared/CCSearch1";

const Input = styled("input")({
  display: "none",
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "95%",
  maxWidth: 400,
  bgcolor: "#FFFFFF",
  boxShadow: 24,
  overflow: "auto",
  borderRadius: "16px",
  outline: "none",
};

const Profile = () => { 
const {t} = useTranslation('common')
  const [countryselector, setCountryselector] = useState("");
  const [countrySc, setCountryShortCode] = useState("");
  const [openBackDrop, setOpenBackDrop] = useState(false);
  const [openProfile, setopenProfile] = useState(false);
  // Country List data
  const DCC = (countryCode = "") => {
    if (countryCode) {
      setCountryselector(parseInt(countryCode));
    } else {
      let csc = JSON.parse(localStorage.getItem("autoCountry"));
      if (csc.sortname !== undefined && csc.sortname !== "")
        setCountryShortCode(csc.sortname);
    }
  };

  const CurrentDate = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    return yyyy + "-" + mm + "-" + dd;
  };

  //get selected country function
  const [selCountryCode, setSelCountryCode] = useState(0);
  const [selCountrySortName, setSelCountrySortName] = useState("");
  const getSelCountry = (data, ShortCode) => {
    setSelCountryCode(data);
    setSelCountrySortName(ShortCode);
  };

  const [errorDisplay, seterrorDisplay] = useState("");
  const [errorDisplay1, seterrorDisplay1] = useState("");

  const [loading, setLoading] = useState(false);
  const [pageLoading , setPageLoading] = useState(true)

  const [showType, setType] = useState(false);

  const [gender, setgender] = React.useState("");
  const [genderCheck , setGenderCheck]  = useState(false)
  const [genderError , setGenederError] = useState(false)

  const [otp, setOtp] = useState("");
  const [fullname, setFullName] = React.useState("");
  const [email, setEmailId] = React.useState("");
  const [phone, setPhoneNumber] = React.useState("");

  const [Modalemail, setModalemail] = React.useState("");
  const [Modalphone, setModalPhoneNumber] = React.useState("");

  const [dob, setDateofBirth] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [profile_pic, setprofile_pic] = React.useState("");

  const [country_code, setCountry_code] = React.useState("");

  const [countrycodelist, setCountrycodelist] = useState([]);

  const [uploadfile, setUploadfile] = useState({});

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
    setEmailId(Modalemail);
    setLoading(false);

    // setPhoneNumber(Modalphone);
  };
  const handleBackdropClose = () => {
    setOpenBackDrop(false);
  };

  // OTPverify Modal
  const [open2, setOpen2] = React.useState(false);

  const handleClose2 = () => {
    setOpen2(false);
    setEmailId(Modalemail);
    setOtp("");
  };

  //// Handle CHange Functionality

  const navigate = useNavigate();

  const [dynamicid, setDynamicid] = useState("");
  const [counter, setCounter] = useState(30);
  const [counterStart, setcounterStart] = useState(false);
  const [activelink, setActivelinlk] = useState(true);

  const [lengthNumError, setlengthNumError] = useState(false);

  const handleChangeotp = (element, index) => {
    if (isNaN(element.value)) return false;
    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);
    //Focus next input
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const handleCloseProfile = () => {
    setopenProfile(false);
  };

  const erroronChange = (e) => {
    var objValidation = {};
    if (Modalphone.length < 8 && Modalphone.length > 1) {
      setlengthNumError(true);
    } else {
      setlengthNumError(false);
    }
    objValidation = checkValidationFormAllControls(
      document.forms[1].elements,
      false,
      []
    );
    if (objValidation.valid !== false) {
      seterrorDisplay(objValidation);
      return;
    } else {
      seterrorDisplay(objValidation);
    }
  };

  // User Otp Verify Api
  const handleSubmitOtp = (e) => {
    e.preventDefault();

    const otpdata = {
      id: dynamicid,
      otp: otp,
    };
    http("user/otp_verify", "POST", otpdata).then((res) => {
      const dataAfterLogin = res.data ?? [];
      if (dataAfterLogin.status === 1) {
        NotificationManager.success(dataAfterLogin.msg, "", 800); 
        localStorage.setItem("loginUser", JSON.stringify(dataAfterLogin.data));
        setOpen2(false);
        navigate("/");
      } else {
        NotificationManager.error(dataAfterLogin.msg, "", 800);
      }
    });
  };

  const handleNormalLogin = (e) => {  
    e.preventDefault(); 
    var objValidation = {};
    objValidation = checkValidationFormAllControls(
      document.forms[1].elements,
      false,
      []
    );
    if (objValidation.valid !== false) {
      seterrorDisplay1(objValidation);
      return;
    } else {
      seterrorDisplay1(objValidation);
    }

    if (Modalphone.length >= 8) {
      objValidation.valid = true;
    } else {
      objValidation.valid = false;
      setlengthNumError(true);
      e.preventDefault();
      return;
    }

    setLoading(true);
    let Logindata = {
      email_phone: showType ? Modalphone : email, 
      update_type: showType ? "phone" : "email", 
      country_code: parseInt(selCountryCode),
      country_short_code: selCountrySortName,
      
    };

    let url = "user/profile_updates_with_single_fields";

    http(url, "POST", Logindata).then((res) => {
      setLoading(false);
      if (res.data.status === 1) {
        setOpen(false);
        NotificationManager.success(res.data.msg, "", 800);
        setDynamicid(res.data.data.id);
        setActivelinlk(true);
        setCounter(30);
        setcounterStart(true);
        setDynamicid(res.data.data.id);
        setOpen2(true);
      } else if (res.data.status === 0) {
        setLoading(false);
        NotificationManager.error(res.data.msg, "", 800);
      } else {
        NotificationManager.error(res.data.msg, "", 800);
        setLoading(false);
      }
    });
  };

  //
  const Modalhandleclick = (e, type) => {
    type === "email" ? setType(false) : setType(true);
    setOpen(true);
  };

  // User information get api

  const GetUserProfile = () => {
   
    http("user/update_profile", "GET").then((res) => {
      setEmailId(res.data.data.email);
      setModalemail(res.data.data.email);
      setFullName(res.data.data.fullname);
      setPhoneNumber(res.data.data.phone);
      setModalPhoneNumber(res.data.data.phone);
      setDateofBirth(res.data.data.dob);
      setAddress(res.data.data.address);
      setprofile_pic(res.data.data.profile_pic);
      setCountry_code(res.data.data.country_code);
      setCountryselector(res.data.data.country_code);
      setgender(res.data.data.gender_name);
      setGenderCheck(res.data.data.gender ? true : false);
      if (res.data.status === 1) {
        setPageLoading(false)
        localStorage.setItem("fullname", res.data.data.fullname);
        localStorage.setItem("phone", res.data.data.phone);
        localStorage.setItem("profilepic", res.data.data.profile_pic);
        localStorage.setItem("email", res.data.data.email);
        localStorage.setItem("UserData", JSON.stringify(res.data.data)); 
      } else {
        // NotificationManager.error(res.data.msg, "", 800);
      }
    });
  };

  const getcountryList = () => {
    const countriesData = require("../../countries.json");
    setCountrycodelist(countriesData);
  };

  useEffect(() => {
    // if (counter > 0) setTimeout(() => setCounter(counter - 1), 800);
    if (counter > 0) {
      const interval = setInterval(() => {
        setCounter(counter - 1);
      }, 800);
      return () => clearInterval(interval);
    }
    if (counter === 0) {
      setActivelinlk(false);
    }
  }, [counter]);

  // Update UserInfo api

  const handleSubmit = (event) => {
    event.preventDefault(); 
    // setOpenBackDrop(true)
    var objValidation = {};
    objValidation = checkValidationFormAllControls(
      document.forms[0].elements,
      false,
      []
    );
    if (objValidation.valid !== false) {
      seterrorDisplay(objValidation);
      return;
    } else {
      seterrorDisplay(objValidation);
    }

    const formData = new FormData();

    formData.append("device_token", "");
    formData.append("fullname", fullname ?? "");
    formData.append("email", email ?? "");
    formData.append("phone", phone ?? "");
    formData.append("dob", dob ?? "");
    formData.append("address", address ?? "");
    formData.append("country_code", "91");
    formData.append("gender" , gender === "male"? 1 : gender === "female"? 2 :3);

    if (uploadfile === {}) {
    } else {
      formData.append("profile_pic", uploadfile);
    }
    if (gender.length === 0){
      setGenederError(true);
      return;
    }
    setPageLoading(true)
    http("user/update_profile", "POST", formData, "", true)
      .then((res) => res.data)
      .then((res) => {
        // setOpenBackDrop(false)
        if (res.status === 1) {
         setPageLoading(false)
          NotificationManager.success(res.msg, "", 800);
          localStorage.setItem("updated_profile", JSON.stringify(res.data));
          localStorage.setItem("fullname", res.data.data.fullname);
          localStorage.setItem("phone", res.data.data.phone);
          localStorage.setItem("profilepic", res.data.data.profile_pic);
          localStorage.setItem("email", res.data.data.email);
          setgender(res.data.data.gender === "1" ? "male" : res.data.data.gender === "2"  ? "female" : "other" );
          setGenderCheck(true)
        }
        if (res.status === 0) {
          setLoading(false);
          NotificationManager.error(res.msg, "", 800);
        } else {
          NotificationManager.error(res.msg, "", 800);
        }
      });
  };

 

  const onchangeavater = (e) => {
    const [files] = e.target.files;
    const reader = new FileReader();
    setUploadfile(files);
    reader.onload = function () {
      setprofile_pic(reader.result);
    };
    reader.readAsDataURL(files);
  };

  useEffect(() => {
    GetUserProfile();
    getcountryList();
  }, []);

  useEffect(() => {
    DCC();
    if (counter > 0) {
      const interval = setInterval(() => {
        setCounter(counter - 1);
      }, 800);
      return () => clearInterval(interval);
    }
    if (counter === 0) {
      setActivelinlk(false);
    }
  }, [counter]);

  return (
    <>
    {pageLoading ? <Box sx={{height:"100vh"}}> <CustomBackdrop  /> </Box> : <>
      <Grid container spacing={4}>
        <Grid item xs={12} md={12}>
          <Box className="profileRow">
            <div className="profilePosition">
              <div className="mainContainer">
                <Typography variant="h6" className="layoutTitle">
                  {t("My_Profile")}
                </Typography>
                <div className="profileBox">
                  <div className="profileCircle">
                    <img
                      src={
                        profile_pic
                          ? profile_pic
                          : "assets/images/defaut-profile.jpg"
                      }
                      alt="profile"
                      style={{
                        borderRadius: "50%",
                        height: "100%",
                        width: "100%",
                        cursor: "pointer",
                      }}
                      onClick={() => setopenProfile(true)}
                    />
                  </div>
                  <label htmlFor="icon-button-file">
                    <Input
                      accept="image/*"
                      id="icon-button-file"
                      type="file"
                      onChange={onchangeavater}
                    />

                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                      className="uploadCircle"
                    >
                      <img src="assets/images/camraIcon.png" alt="camraIcon" />
                    </IconButton>
                  </label>
                </div>
              </div>
            </div>
          </Box>
        </Grid>
      </Grid>

      <Box className="mainContainer">
        <div>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open2}
            onClose={handleClose2}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            // NotificationManager.success(res.data.msg);
            className="formPopUp"
          >
            <Fade in={open2}>
              <Box sx={style}>
                <img
                  src="assets/images/close-icon.png"
                  alt="ticketkore"
                  className="closeIcon"
                  onClick={handleClose2}
                />

                <Box className="formBox">
                  <Box sx={{ textAlign: "center", margin: "40px auto" }}>
                    <img
                      src="assets/images/ticketkore-logo.png"
                      alt="ticketkore"
                    />
                  </Box>

                  <Box>
                    <Typography className="signUpText">{t("Verification")}</Typography>

                    <Typography sx={{ marginBottom: "37px" }}>
                    {t("We have sent a verification code to registered")}{" "}
                      {showType ? "phone" : "email"}
                    </Typography>
                  </Box>

                  <Box noValidate autoComplete="off" className="formOtpBox">
                    <OtpInput
                      value={otp}
                      onChange={(otp) => setOtp(otp)}
                      numInputs={4}
                      className="otpInput activeBorder"
                      inputStyle={"otp-input-box"}
                      isInputNum={true}
                    />
                  </Box>

                  <Button
                    disabled={loading}
                    type="button"
                    id="verify"
                    className="signInBtn"
                    onClick={handleSubmitOtp}
                  >
                    {loading ? (
                      <SpinnerCircular
                        style={{ width: "1.2rem", height: "1.2rem" }}
                        color="light"
                      />
                    ) : (
                      "Verify"
                    )}
                  </Button>
                </Box>

                <Typography className="formText" sx={{ marginTop: "20px" }}>
                  <Box>
                    <Button
                      sx={{
                        fontFamily: "unset",
                        textTransform: "capitalize",
                        textDecoration: "underline",
                        fontWeight: "600",
                      }}
                      disabled={activelink}
                      onClick={handleNormalLogin}
                      className={
                        activelink ? "resend-otp-active" : "resend-otp-deactive"
                      }
                    >
                      {t("Resend")}
                    </Button>
                  </Box>{" "}
                  {t("Havent_got_the_OTP")}- {counter > 0 ? counter : "0"}
                </Typography>
              </Box>
            </Fade>
          </Modal>
        </div>

        <div>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            className="formPopUp"
          >
            <Fade in={open}>
              <Box sx={style}>
                <img
                  src="assets/images/close-icon.png"
                  alt="ticketkore"
                  className="closeIcon"
                  onClick={handleClose}
                />

                <form id="form2">
                  <Box className="formBox">
                    <Box
                      sx={{
                        textAlign: "start",
                        margin: "40px auto",
                        mt: "4rem",
                      }}
                    >
                      <Typography sx={{ fontWeight: "700" }}>
                      {t("Edit")} {!showType ? "Email" : "Phone Number"}{" "}
                      </Typography>
                      <Typography>
                      {t("This")} {!showType ? "email" : "Phone Number"} {t(`will be
                        verified by an Otp`)}{" "}
                      </Typography>
                    </Box>

                    <Box
                      noValidate
                      autoComplete="off"
                      sx={{ position: "relative" }}
                    >
                      {!showType ? (
                        <img
                          src="assets/images/message-icon.png"
                          alt="ticketkore"
                          className="fieldIcon"
                        />
                      ) : (
                        <img
                          src="assets/images/phone-icon.png"
                          alt="ticketkore"
                          className="fieldIcon"
                        />
                      )}

                      {!showType ? (
                        <>
                          <TextField
                            required
                            id="email"
                            name="email"
                            type="email"
                            placeholder={t("Enter_email_id")}
                            size="small"
                            sx={{ width: "100%" }}
                            value={email}
                            inputProps={{
                              maxLength: 30,
                            }}
                            onChange={(e) => setEmailId(e.target.value)}
                            className="mailPopBox"
                          />
                          <Box sx={{ marginTop: "5px" }}>
                            {errorDisplay1.email ? (
                              <span className="red">{errorDisplay1.email}</span>
                            ) : (
                              ""
                            )}
                          </Box>
                        </>
                      ) : (
                        <>
                          <div className="NewflexGridS">
                            <Box
                              sx={{
                                marginRight: "15px",
                                maxWidth: "130px",
                                marginBottom: "15px",
                                width: "100%",
                              }}
                              className="flagBox"
                            >
                              <CCSearch
                                getSelCountry={getSelCountry}
                                // defaultCC={Useralldata.country_code}
                              />
                            </Box>

                            <Box
                              noValidate
                              autoComplete="off"
                              // className="formInputBox"
                              sx={{}}
                            >
                              <TextField
                                fullWidth
                                id="phone"
                                name="phone"
                                type="tel"
                                size="small"
                                required
                                variant="outlined"
                                placeholder={t("Enter_mobile_no")}
                                sx={{ width: "100%" }}
                                value={Modalphone}
                                error={lengthNumError}
                                helperText={
                                  lengthNumError
                                    ? t("Number length must be greater or equal to 8 digit")
                                    : ""
                                }
                                inputProps={{ maxLength: 15 }}
                                onChange={(e) => {
                                  setModalPhoneNumber(e.target.value);
                                  erroronChange(e);
                                }}
                              />

                              <Box sx={{ marginTop: "5px" }}>
                                {errorDisplay1.phone ? (
                                  <span className="red">
                                    {errorDisplay1.phone}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </Box>
                            </Box>
                          </div>
                        </>
                      )}
                    </Box>

                    <Button
                      disabled={loading}
                      type="button"
                      id="sign"
                      className="signInBtn"
                      onClick={handleNormalLogin}
                      style={{ marginTop: "20px", marginBottom: "20px" }}
                    >
                      {" "}
                      {loading ? (
                        <SpinnerCircular
                          style={{ width: "1.2rem", height: "1.2rem" }}
                          color="light"
                        />
                      ) : (
                        t("Update")
                      )}
                    </Button>
                  </Box>
                </form>
              </Box>
            </Fade>
          </Modal>
        </div>

        <form name="profil_form" className="profileForm">
          <Grid container spacing={4} className="profileModel">
            <Grid item xs={12} md={6}>
              <Typography className="profleFormLabel">Full Name</Typography>
              <TextField
                id="input-with-icon-textfield"
                placeholder="Enter Your Name"
                fullWidth
                required
                name="fullname"
                value={fullname}
                inputProps={{ maxLength: 30 }}
                onChange={(e) => setFullName(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" className="profleFormIcon">
                      <img src="assets/images/userIcon.png" alt="userIcon" />
                    </InputAdornment>
                  ),
                }}
                variant="standard"
                className="profleFormInput"
              />
              <Box sx={{ marginTop: "5px" }}>
                {errorDisplay.fullname ? (
                  <span className="red">{errorDisplay.fullname}</span>
                ) : (
                  ""
                )}
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography className="profleFormLabel">{t("Email_Id")}</Typography>
              <TextField
                id="input-with-icon-textfield"
                placeholder={t("Email_Id")}
                fullWidth
                value={email}
                type="email"
                // onChange={(e) => setEmailId(e.target.value)}
                disabled
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" className="profleFormIcon">
                      <img
                        src="assets/images/message-icon.png"
                        alt="message-icon"
                      />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      className="profleFormIcon cursor-pointer"
                      onClick={(e) => Modalhandleclick(e, "email")}
                      style={{ cursor: "pointer" }}
                    >
                      <img src="assets/images/edit-icon.png" alt="edit-icon" />
                    </InputAdornment>
                  ),
                }}
                variant="standard"
                className="profleFormInput"
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography className="profleFormLabel">{t("Phone_Number")}</Typography>
              <TextField
                id="input-with-icon-textfield"
                placeholder={t("Enter_Phone_Number")}
                fullWidth
                required
                name="phone"
                type="number"
                onkeypress="preventNonNumericalInput(event)"
                value={phone}
                onChange={(e) => setPhoneNumber(e.target.value)}
                disabled
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" className="profleFormIcon">
                      <img
                        src="assets/images/phone-icon.png"
                        alt="phone-icon"
                      />
                      <Typography
                        variant="body1"
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          mt: 0.5,
                          ml: 0.5,
                          mr: 0.5,
                          fontSize: "22px",
                          fontWeight: 500,
                          color: "rgba(0, 0, 0, 0.38) ",
                          alignItems: "center",
                        }}
                      >
                        {" "}
                        +{country_code} <RemoveIcon sx={{ width: "20px" }} />
                      </Typography>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      className="profleFormIcon"
                      onClick={(e) => Modalhandleclick(e, "phone")}
                      style={{ cursor: "pointer" }}
                    >
                      <img src="assets/images/edit-icon.png" alt="edit-icon" />
                    </InputAdornment>
                  ),
                }}
                variant="standard"
                className="profleFormInput"
              />
              {/* <Box sx={{ marginTop: "5px" }}>
              {errorDisplay.phone ? (
                <span className="red">{errorDisplay.phone}</span>
              ) : (
                ""
              )}

            </Box> */}
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography className="profleFormLabel">{t("Date_Of_Birth")}</Typography>
              <TextField
                id="input-with-icon-textfield"
                placeholder={t("Enter_Your_dob")}
                fullWidth
                required
                type="date" 
                name="dob"
                value={dob}
                onChange={(e) => setDateofBirth(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" className="profleFormIcon">
                      <img src="assets/images/calander.png" alt="calander" />
                    </InputAdornment>
                  ),
                  inputProps: { max: CurrentDate() },
                }}
                variant="standard"
                className="profleFormInput"
              />

              <Box sx={{ marginTop: "5px" }}>
                {errorDisplay.dob ? (
                  <span className="red">{errorDisplay.dob}</span>
                ) : (
                  ""
                )}
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography className="profleFormLabel">{t("Address")}</Typography>
              <TextField
                id="input-with-icon-textfield"
                placeholder={t("Enter_Your_Address")}
                fullWidth
                value={address}
                required
                name="address"
                onChange={(e) => setAddress(e.target.value)}
                inputProps={{ maxLength: 150 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" className="profleFormIcon">
                      <img src="assets/images/location.png" alt="location" />
                    </InputAdornment>
                  ),
                }}
                variant="standard"
                className="profleFormInput"
              />
              <Box sx={{ marginTop: "5px" }}>
                {errorDisplay.address ? (
                  <span className="red">{errorDisplay.address}</span>
                ) : (
                  ""
                )}
              </Box>
            </Grid>
        { !genderCheck ?    <Grid item xs={12} md={6}>

        <Typography className="profleFormLabel" sx={{paddingBottom:"4px !important "}}>{t("Gender")}</Typography>
              <FormControl fullWidth className="selectSearchInput" error={genderError}>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={gender ? gender.toLowerCase() : "error"}
                  onChange={(e) => {setgender(e.target.value) ;  setGenederError(false)}}
                  className="selectSearchInputField"
                  sx={{color:genderError  ? "error.main" : "black"}}
                >
                  <MenuItem value={"error"} disabled>
                  {t("Please Select the Gender")} 
                  </MenuItem>
                  <MenuItem value={"male"}>{t("Male")} </MenuItem>
                  <MenuItem value={"female"}>{t("Female")} </MenuItem>
                  <MenuItem value={"other"}>{t("Other")} </MenuItem>
                </Select> 
                {genderError ? <FormHelperText sx={{color: "error.main"}} >{t("Gender_is_required")}</FormHelperText> : <></>}
              </FormControl>
            </Grid>
           :
            <Grid item xs={12} md={6}>
              <Typography className="profleFormLabel">{t("Gender")}</Typography>
              <TextField
                id="input-with-icon-textfield"
 
                fullWidth
                value={gender} 
                disabled
                name="gender"
                onChange={(e) => setAddress(e.target.value)}
                inputProps={{ maxLength: 150 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" className="profleFormIcon">
                        <WcIcon sx={{color:'#1F87BA'}}/>
                    </InputAdornment>
                  ),
                }}
                variant="standard"
                className="profleFormInput"
              />
       
            </Grid>}
            <Grid
              item
              xs={12}
              md={12}
              sx={{
                marginTop: "20px",
                marginBottom: "50px",
              }}
            >
              <Button className="updateBtn" onClick={handleSubmit}>
              {t("Update_Profile")}
              </Button>
            </Grid>
          </Grid>
        </form>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openBackDrop}
          onClick={handleBackdropClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <Modal
          open={openProfile}
          onClose={handleCloseProfile}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={profile_pic ? profile_pic : "assets/images/defaut-profile.jpg"}
            alt="profile"
            style={{
              borderRadius: "50%",
            }}
            className="view-profile"
          />
        </Modal>
      </Box>
 
      </>}
    </>
  );
};

export default Profile;
