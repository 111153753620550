import React from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import './style.css'
import { Typography } from '@mui/material';
import {useTranslation} from "react-i18next"
const PricingOption = () => {
    const {t} = useTranslation("common")
    return (
        <Box className='mainContainer'>

            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} sx={{ textAlign: 'center' }}>
                    <Typography className='pFlexibleOpt'>{t("Flexible_Pricing_Options")}</Typography>
                    <Typography className='pNoHidden'>{t("No_hidden_fees")}</Typography>
                </Grid>
                {/* Fixed Pricing */}
                <Grid item xs={12} sm={4} md={4} lg={4}>
                    <div className='pricingBoxShadow'>
                        <Grid container spacing={2} >
                            <Grid item xs={6} sm={6} md={6}>
                                <img src='assets/images/thumb.png' alt='thumbImg' />
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} className='firstImgGrid'>
                                <div className='positionRelativeTrans'>
                                    <img src='assets/images/blueImg.png' className='frstImg' alt='BlueImg' />
                                    <div className='positionAbsoluteTran'>
                                        <Typography className='borderBottomFrom'>{t("From")}</Typography>
                                        <Typography className='borderBottomRuppes'>R2500.00</Typography>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>

                        <Box className='PCommonPaddingBox'>
                            <Typography className='PriceFixedPricing'>{t("Fixed_Pricing")}</Typography>
                            <div className='fixedPricingBottom'></div>
                            <Typography className='pricingIncludeHead'>{t("Includes")}</Typography>
                            <ul className='pricingULPadiingL'>
                                <li>{t("Contactless_Tickets")}</li>
                                <li>{t("Event_Listing")}</li>
                                <li>{t("Analytics")}</li>
                                <li>{t("100_Audiences")}</li>
                                <li>{t("Invitations")}</li>
                                <li>{t("Audience_Check_in")}</li>
                                <li>{t("24/7_Support")}</li>
                            </ul>
                        </Box>

                        <Box>
                            <Typography className='pricingIncludeHead'>{t("Optional_Add_on")}</Typography>
                            <ul className='pricingULPadiingL' style={{ paddingBottom: '60px' }}>
                                <li>{t("Cashless_Food_and_Beverage")}</li>
                                <li>{t("RFID_Wristbands")}</li>
                                <li>{t("Staff")}</li>
                                <li> {t("Integrated_Marketing")}</li>
                            </ul>
                        </Box>
                    </div>
                </Grid>
                {/* End Fixed Pricing */}

                {/* Standard Pricing */}
                <Grid item xs={12} sm={4} md={4} lg={4}>
                    <div className='pricingBoxShadow'>
                        <Grid container spacing={2} >
                            <Grid item xs={6} sm={6} md={6}>
                                <img src='assets/images/standardP.png' alt='thumbImg' />
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} className='firstImgGrid'>
                                <div className='positionRelativeTrans'>
                                    <img src='assets/images/blueImg.png' className='frstImg' alt='BlueImg' />
                                    <div className='positionAbsoluteTran'>
                                        <Typography className='borderBottomFrom'>{t("From")}</Typography>
                                        <Typography className='borderBottomRuppes'>3.50%</Typography>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>

                        <Box className='PCommonPaddingBox'>
                            <Typography className='PriceFixedPricing'>{t("Standard_Pricing")}</Typography>
                            <div className='SecondFixedPricingBottom'></div>
                            <Typography className='pricingIncludeHead'>{t("Includes")}</Typography>
                            <ul className='pricingULPadiingL'>
                            <li>{t("Contactless_Tickets")}</li>
                                <li>{t("Event_Listing")}</li>
                                <li>{t("Analytics")}</li>
                                <li>{t("Reporting")}</li>
                                <li>{t("Invitations")}</li>
                                <li>{t("Audience_Check_in")}</li>
                                <li>{t("Unlimited_audiences")}</li>
                                <li>{t("Social_Media")}</li>
                                <li>{t("24/7_Support")}</li>
                            </ul>
                        </Box>

                        <Box>
                            <Typography className='pricingIncludeHead'>{t("Optional_Add_on")}</Typography>
                            <ul className='pricingULPadiingL'>
                            <li>{t("Cashless_Food_and_Beverage")}</li>
                                <li>{t("RFID_Wristbands")}</li>
                                <li>{t("Staff")}</li>
                                <li> {t("Integrated_Marketing")}</li>
                            </ul>
                        </Box>
                    </div>
                </Grid>
                {/* End Standard Pricing */}

                {/* Integrated Pricing */}
                <Grid item xs={12} sm={4} md={4} lg={4}>
                    <div className='pricingBoxShadow'>
                        <Grid container spacing={2} >
                            <Grid item xs={6} sm={6} md={6}>
                                <img src='assets/images/integratedP.png' alt='thumbImg' />
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} className='firstImgGrid'>
                                <div className='positionRelativeTrans'>
                                    <img src='assets/images/blueImg.png' className='frstImg' alt='BlueImg' />
                                    <div className='positionAbsoluteTran'>
                                        <Typography className='borderBottomFrom'>{t("From")}</Typography>
                                        <Typography className='borderBottomRuppes'>7.00%</Typography>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>

                        <Box className='PCommonPaddingBox'>
                            <Typography className='PriceFixedPricing'>Integrated Pricing</Typography>
                            <div className='ThirdFixedPricingBottom'></div>
                            <Typography className='pricingIncludeHead'>Includes</Typography>
                            <ul className='pricingULPadiingL'>
                            <li>{t("Contactless_Tickets")}</li>
                            <li>{t("Cashless_Food_and_Beverage")}</li>
                             <li>{t("Unlimited_audiences")}</li>
                                <li>{t("Event_Listing")}</li>

                                <li>{t("Vendor_Management")}</li>
                                <li>{t("Food_and_Beverage")}</li>
                                <li>{t("Wristbands")}</li>
                                <li>{t("Audience_Check_in")}</li>
                                <li>{t("Analytics")}</li>
                                <li>{t("Reporting")}</li>
                                <li>{t("Invitations")}</li>
                                <li>{t("Staff")}</li>
                                <li>{t("Social_Media")}</li>
                                <li>{t("24/7_Support")}</li>
                            </ul>
                        </Box>

                        <Box>
                            <Typography className='pricingIncludeHead'>{t("Optional_Add_on")}</Typography>
                            <ul className='pricingULPadiingL' style={{ paddingBottom: '60px' }}>
                            <li>{t("Cashless_Food_and_Beverage")}</li>
                                <li>{t("RFID_Wristbands")}</li>
                                <li>{t("Staff")}</li>
                                <li> {t("Integrated_Marketing")}</li>
                            </ul>
                        </Box>
                    </div>
                </Grid>
                {/* End Integrated Pricing */}
            </Grid>
        </Box>
    )
}

export default PricingOption