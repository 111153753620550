import { Typography } from '@mui/material';
import React, { useEffect } from 'react'
import CommonBanner from '../../Components/CommonBanner';
import MobilePolicy from '../../Components/MobilePolicy';

const MobilePrivacyPolicy = () => {
    
      useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <>
      {/* <MenuBar /> */}
      {/* <CommonBanner title={"Privacy Policy"} />
       */}
       <Typography variant='h6' sx={{fontWeight:700 , textAlign:'center' , p:4 , fontSize:'32px' , fontFamily:'lato'}} >Privacy Policy</Typography>
      {/* <CommonBanner /> */}
      <MobilePolicy />
    </>
  )
}

export default MobilePrivacyPolicy