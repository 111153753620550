/* eslint-disable jsx-a11y/anchor-is-valid */
// import * as React from 'react';
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import Carousel, { consts } from "react-elastic-carousel";
import React, { useEffect, useState } from "react";
import ModalPopup from "../ModalPopup";
import Modal from "@mui/material/Modal";
import http from "../../Services/http";
import SocialSharePopup from "../SocialSharePopup";
import { ManagePageReloadState } from "../../Services/ManagePageReloadState";
import { useNavigate, useLocation } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import OwlCarousel from 'react-owl-carousel'
import getSymbolFromCurrency from "currency-symbol-map";
import CloseIcon from "@mui/icons-material/Close";
import {useTranslation} from "react-i18next";
import "./style.css";
import {getLanguageSource} from '../../Services/translateString'
import FavoriteIcon from "@mui/icons-material/Favorite";
import { Snackbar } from "@mui/material";
// import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "95%",
  maxWidth: 600,
  bgcolor: "#FFFFFF",
  boxShadow: 24,
  overflow: "auto",
};

const breakPoints = [
  { width: 1, itemsToShow: 2, itemPadding: [10, 3] },
  { width: 550, itemsToShow: 3, itemPadding: [10, 5] },
  { width: 768, itemsToShow: 4, itemPadding: [10, 8] },
  { width: 1200, itemsToShow: 5, itemPadding: [10, 12] },
];

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(0),
  color: theme.palette.text.secondary,
}));

const UpcomingEvent = (props) => {
  const {t} = useTranslation("common");
  const { UpcomingEvent1 } = props;

  const [upcomingevent, setupcomingevent] = useState([]);
  const [seletedeventID, setseletedeventID] = useState(0);
  const [show, setShow] = React.useState(false);
  const history = useNavigate();
  const [CCBoardPopUp, setCCBoardPopUp] = useState(false);
  const CopiedToclipBoardPopUp = (data) => { 
    setOpen1(data)
    // setOpen(data)
    setCCBoardPopUp(true)
  };
  
  
  
    const handleCloseCpy = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setCCBoardPopUp(false);
  };

  const action = (
    <React.Fragment>
   
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseCpy}
      >
       
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const dateFormated = (date) => {
    let dateNew = new Date(date);
    let day = dateNew.toLocaleString("en-us", { weekday: "short" });
    let newdate = new Date(date).getDate();
    let month = dateNew.toLocaleString("default", { month: "short" });
    return day + "," + newdate + " " + month;
  };

  // Module Hoc Function
  const [openLogin, setOpenLogin] = React.useState(false);
  const handleClose = () => {
    setOpenLogin(false);
  };

  const checkLoggedIn = (e, params, id) => {
    if (!localStorage.getItem("token")) {
      setOpenLogin(true);
    } else {
      Favourites(params,id);
    }
  };

  // Sharemod
  const [open1, setOpen1] = React.useState(false);
  const handleClose1 = () => setOpen1(false);
  const [EventName , setEventName] = useState('');
  
  
  const checkLoggedIn1 = (e, params , event_name) => {
    let modifyEventName = event_name.replaceAll(/[^a-zA-Z0-9 ]/g , "")
    setseletedeventID(params.id);
    // if (!localStorage.getItem("token")) {
    //   setOpenLogin(true);
    // } else {
    //   setOpen1(true);
    //   setEventName(modifyEventName.replaceAll(' ', '-'))
    // }
    setOpen1(true);
      setEventName(modifyEventName.replaceAll(' ', '-'))
  };

  // Fav api
  const Favourites = (params,id) => {
    let postData = {
      eventid: params.id,
    };
    http("events/favourite_event", "POST", postData).then((res) => {
      if (
        res.data.msg === "success! event is added in your favourite section"
      ) {
        getUE();
        document.getElementById(id).classList.add('selected')
        NotificationManager.success(res.data.msg, "", 800);
      } else {
        getUE();
        document.getElementById(id).classList.add('selected')
        NotificationManager.success(
          "event is removed in your favourite section",
          "",
          800
        );
      }
    });
  };
  // let SelectedCity = localStorage.getItem('SelectedCity') ? JSON.parse(localStorage.getItem('SelectedCity')) : {};
const translateDynamicContent =  (data)=>{

  
    let eventConts = 0
     
   data?.forEach(async (upcomgevents, idx)=>{
    let eventName = upcomgevents.event_name
    eventConts = eventConts + 1
    
     const translatedString = await getLanguageSource([upcomgevents.event_name])
      upcomgevents.event_name = translatedString[0].translatedText || upcomgevents.event_name;
      eventName = upcomgevents.event_name
   
     
     if(document.getElementById(`upComingevent_Name${idx}`)){
      document.getElementById(`upComingevent_Name${idx}`).innerHTML = eventName
     }
     if(idx === data.length - 1){
      setupcomingevent(data);
      if (data.length > 5) {
        setShow(true);
      } else {
        setShow(false);
      }
     }
    })
  
  
}

useEffect(()=>{
if(localStorage.getItem('lang') &&  upcomingevent && upcomingevent.length > 0){
  translateDynamicContent(upcomingevent)
}
},[localStorage.getItem('lang')])
  // Upcoming events data
  const getUE = () => {
    let postData = {
      country_id: localStorage.getItem("autoCountry")
        ? JSON.parse(localStorage.getItem("autoCountry")).id
        : "",

      city: localStorage.getItem("SelectedCity")
        ? JSON.parse(localStorage.getItem("SelectedCity")).id
        : "",
    };
    http("events/promotional", "POST", postData)
      .then((res) => {
        const data = res.data.data ? res.data.data : [];
        if (data.upcoming_events.length <= 0) {
          setupcomingevent([]);
        }
        if (data.upcoming_events.length > 0) {
         translateDynamicContent(data.upcoming_events)
        } else {
          UpcomingEvent1(true);
        }
      })
      .catch((err) => {});
  };
  useEffect(() => {
    getUE();
    ManagePageReloadState.getPageReloadState().subscribe((res) => {
      if (res.state) {
        getUE();
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();

  // Autoc
  let Autocountry = localStorage.getItem("autoCountry")
    ? JSON.parse(localStorage.getItem("autoCountry"))
    : {};

  
  const handleeventdetails = (e, id, event_name) => {
    let modifyEventName = event_name.replaceAll(/[^a-zA-Z0-9 ]/g , "")
    history( `/events/${modifyEventName.replaceAll(' ', '-')}/` + id + "?flag=" + Autocountry.sortname);
  };

  const handleonviewallClick = (e, id) => {
    history(`/ViewAll/${"Upcomingevent"}/${"?flag=" + query.get("flag")}  `, {
      state: { id: 1, name: "Upcoming Event" },
    });
  };

   const options = {
        autoplay: false,
        smartSpeed: 1000,
        margin: 0,
        
        loop: false,
        center: false,
        dots: false,
        nav: true,
        navContainer: '.upcomingEvent',
        navText : [
            '<i class="fas fa-chevron-left"></i>',
            '<i class="fas fa-chevron-right"></i>'
        ],
        responsive: {
			0:{
                items:1
            },
            576:{
                items:1
            },
            768:{
                items:2
            },
            992:{
                items:2
            },
            1100:{
                items:3
            }
        }
    };
const getEventNameTranslated = (id, data)=>{
  if(document.getElementById(id)){
    document.getElementById(id).innerHTML = t(data.split(' ').join('_'))
  }
}
   
const buyTicketTrans = (id, text) =>{
  if(document.getElementById(id)){
    document.getElementById(id).innerHTML = t(text)
  }
}

const eventName = (id, text)=>{
  if(document.getElementById(id)){
    console.log(text)
    document.getElementById(id).innerHTML = text
  }
}

  return (
    <>
      <Box className="mainContainerNew">
        <Modal
          open={open1}
          onClose={handleClose1}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="ModalClass"
        >
         
          <Box sx={style} className="ModalClassBox">
            <Grid container>
              <Grid
                item
                xs={12}
                sx={{ textAlign: "right", padding: "0px 16px" }}
                className="ModalTopGrid"
              >
                <CloseIcon className="closeIconOffer" onClick={handleClose1} />
                <Typography
                  id="modal-modal-description"
                  className="ModalPhomolong"
                  sx={{ textAlign: "center" }}
                >
                  Share with Social Media
                </Typography>
          
                {localStorage.getItem("autoCountry") ? (
                  <SocialSharePopup
                    ShareUrl={
                      `/events/${EventName}/` +
                      seletedeventID +
                      "?flag=" +
                      JSON.parse(localStorage.getItem("autoCountry")).sortname
                    }
                    CopiedToclipBoardPopUp={CopiedToclipBoardPopUp}
                  />
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          </Box>
        </Modal>

        {/* Sharemodalend */}


 {/* <!-- Event Category Start --> */}
 {upcomingevent?.length > 0 ? (<div className="container-xxl py-5">
  
            <div className="container">
                <div className="section-heading mb-5 mx-auto wow fadeInUp" data-wow-delay="0.1s">
                    <div className="row justify-content-between align-items-center ">
                        <div className="col-lg-8">
                            <p className="head-sub fw-semi-bold text-uppercase mb-1">{t('discover_the_fun')}</p>
                            <h2 className="fw-semi-bold">{t('upcoming_events')}</h2>
                        </div>
                        <div className="col-lg-2 d-flex align-items-center justify-content-end gap-4">
                        {upcomingevent?.length > 4 && <a className="btn btn-light py-2 px-4" onClick={handleonviewallClick}>{t('view_all')}</a>}
                        </div>
                        <div className="heading-btns upcomingEvent col-lg-2 d-flex align-items-center justify-content-end gap-4">
                        
                        </div>
                    </div>
                </div>

                <OwlCarousel className='owl-theme project-carousel wow fadeInUp' {...options}>
                {upcomingevent.map((data, index) => (
                    <div key={index + 'upcoming Event'} className="project-item pe-5 pb-3">
                     
                        <div className="project-img">
                            <img className="img-fluid rounded-3"
                            style={{height:472}}
                            src={
                              (data?.event_thumbnail?.indexOf('.png') > -1 || 
                              data?.event_thumbnail?.indexOf('.jpg') > -1|| 
                              data?.event_thumbnail?.indexOf('.jpeg') > -1||
                              data?.event_thumbnail?.indexOf('.gif') > -1 ||
                              data?.event_thumbnail?.indexOf('.web')> -1)
                                ? data.event_thumbnail
                                : "img/event-category-thumb-001.jpg"
                            }
                            
                             alt="event-category-thumb-001"/>

<div className="project-card-data" >
                                <div className="d-flex align-items-center justify-content-between">
                                <div id={`upComingCatgoryName${index}`} className="pcd-event-name text-white fw-bold">{getEventNameTranslated(`upComingCatgoryName${index}`,data?.event_type_name) || t(data?.event_type_name)}</div>
                                    {data.favourite === 0 || data.favourite === 1 ? <div className="pcd-event-like" onClick={(e) => checkLoggedIn(e, data,`upcoming${index}`)}>
                                    
                                        <a id={`upcoming${index}`} className={`btn ms-3 circular-aura-effect`} >
                                            <small className="bi bi-heart-fill"></small>
                                        </a>
                                    </div> : <></>}
                                </div>
                                <div className="pcd-event-detail" style={{display:'flex',flexDirection:'column', gap:5}}>
                                    <div className="pcd-event-code fw-medium mb-1">{`${getSymbolFromCurrency(data.currency_code)} ${data.minprice}`}</div>
                                    <div id={`upComingevent_Name${index}`} className="pcd-event-title fw-medium">{data.event_name}</div>
                                    <div className="pcd-event-time fw-light mb-2">
                                      <i className="bi bi-calendar4 me-2"></i> {dateFormated(data.event_date)}{" "}
                                  {data.date_range.length > 10
                                    ? "Onwards"
                                  : data.event_time}</div>
                                    <div className="pcd-ed-footer d-flex align-items-center justify-content-between">
                                        <a onClick={(e) => checkLoggedIn1(e, data , data.event_name)}><img className="icon-peoples" src="img/peoples.svg" alt="peoples"/></a>
                                        <a id={`upComingCatgoryBuy_ticket${index}`} className="btn btn-primary event-buyticket fw-regular p-2 px-3" onClick={(e) => handleeventdetails(e, data.id , data.event_name)}>{buyTicketTrans(`upComingCatgoryBuy_ticket${index}`,'buy_ticket') || t('buy_ticket')} </a>
                                    </div>
                                </div>
                            </div>
   
                        </div>
                    </div>
                ))}
                </OwlCarousel>
               {openLogin ? (
          <ModalPopup handleOpen={openLogin} handleClick={handleClose} />
        ) : (
          ""
        )}


            </div>
        </div>) : (<></>)}
        {/* <!-- Event Category End --> */}





       </Box>
    </>
  );
};
export default UpcomingEvent;
