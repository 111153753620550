import { ActionTypes } from "../contant/index";

const initializedState = {
    value: '',
    vendor: [],
    product: [],
    selecteditem: [],
    vendoritem: {},
    vendorFooditem: [],
    card: []
}
export const productReducrs = (state = initializedState, { type, payrole }) => {
    switch (type) {
        case ActionTypes.GET_VENDORS:

            return { ...state, vendor: payrole.data }
        case ActionTypes.SELECTED_ITEM:
            const newarray = [...state.vendorFooditem]
            newarray[payrole.index].count = 1
            newarray[payrole.index].select = true
            newarray[payrole.index].total = parseFloat(newarray[payrole.index].count) * parseFloat(newarray[payrole.index].food_price)
            // newarray[payrole].vendor_id
            return { ...state, vendorFooditem: newarray, selecteditem: [...state.selecteditem, payrole.element] }
        case ActionTypes.GET_VENDORS_ITEM:
            return { ...state, vendoritem: payrole, vendorFooditem: payrole.state.data.vendor_food_list }
        case ActionTypes.ADD_INCREMENT:
            const newarray1 = [...state.vendorFooditem]
            newarray1[payrole].count = newarray1[payrole].count + 1
            newarray1[payrole].total = parseFloat(newarray1[payrole].count) * parseFloat(newarray1[payrole].food_price)
       
            return { ...state, vendorFooditem: newarray1 }


        case ActionTypes.ADD_DECREMENT:
            const newarray2 = [...state.vendorFooditem]
            newarray2[payrole].count = newarray2[payrole].count - 1
            newarray2[payrole].total = parseFloat(newarray2[payrole].count) * parseFloat(newarray2[payrole].food_price)
            // setCount(count + 1)
            return { ...state, vendorFooditem: newarray2 }

        default:
            return state
    }
}




export const userReducer = (state = initializedState, { type, payload }) => {

    switch (type) {

        case ActionTypes.COUNTRY:
            return { ...state, value: payload };
        case ActionTypes.CITY:
            return { ...state, value: payload };
        case ActionTypes.EVENTS:
            return { ...state, value: payload };
        case ActionTypes.EVENTSEXPIRED:
            return { ...state, value: payload }

        default: return state;
    }
}