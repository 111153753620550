import { Alert, Grid, IconButton, Snackbar, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { Typography } from "@mui/material";
import Card from "@mui/material/Card";
import { Button } from "@mui/material";
import { TextField } from "@mui/material";
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import http from "../../Services/http";
import { NotificationManager } from "react-notifications";
import Backdrop from "@mui/material/Backdrop";
import ClipLoader from "react-spinners/ClipLoader";
import CloseIcon from "@mui/icons-material/Close";
import getSymbolFromCurrency from "currency-symbol-map";
import "./style.css";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import PropTypes from 'prop-types';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Fade from "@mui/material/Fade";
import OtpInput from "react-otp-input";
import "../ModalPopup/style.css"
 // stripe dependencies
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../CheckoutForm"
import {useTranslation} from 'react-i18next'
 
 
// const SITEURL="https://staging.ticketkore.io/website/"

import { useLocation, useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import checkValidationFormAllControls from "../../shared/validation";

import "./style.css";
import { useForm } from "react-hook-form"; 


// Stripe Promise
const stripePromise = loadStripe("pk_test_51LShl9LcQPogeGPUy8aBGDu542pjIF6BjKu47uZruUzoBPSvG9hWHIBP1fxJX6l5nkJ0IIoXGZna0eKhHPyJFD1s00KOi1TJ8X");


const style2 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 490,
  boxShadow: 24,
  p: 4,
  background: '#FFFFFF',
  borderRadius: '16px',
  border: 'unset',
};

// Cash Out Modal css
const style1 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  // bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  background: '#FFFFFF', 
  border: 'unset',
};
// End Cash Out Modal css
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  // boxShadow: "24",
  borderRadius: "16px",
  //p: 3,
  //backdropColor: "black",
};
 



function TabPanel(props) {
  const {t} = useTranslation('common')
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


const Wallet = () => {

  const [otp, setOtp] = useState("");
  // OTPverify Modal for single value
  const [open6, setOpen6] = React.useState(false);
  
  const [open1, setOpen1] = React.useState(false);
  const[openErrMsg , setOpenErrMsg] = useState(false) 
  const handleClose1 = () => setOpen(false);

  const [expanded, setExpanded] = React.useState(false);

  const handleChange1 = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const navigate = useNavigate();
  const [wallethistory, setWallethistory] = useState([]);


  const [checkout_new_id, setCheckPeachPaymentCheckoutId] = useState("");
  const [filterDateData, setFilterDateData] = useState([]);



  const [errorDisplay, seterrorDisplay] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingwallet, setIsLoadingwallet] = useState(false);
  const [page, setPage] = React.useState(1);
  const [count, setCount] = React.useState(0);

  // 
  const [orders, setOrders] = useState([]);



  const [results, setResults] = useState([]);
  const [searchedWorker, setSearchedWorker] = useState("");
  // 
  const [currencyval, setCurrencyval] = useState([]);

  const [counter, setCounter] = useState(0);
  const [activelink, setActivelinlk] = useState(true);

  const handleSubmodelClose = () => {
    setOpenAddWallet(false);
    setAmount("");
    walletapi();
    // Navigate("-1");
    
  };

  const dateFormated = (date) => {
    let dateNew = new Date(date);
    let day = dateNew.toLocaleString("en-us", { weekday: "short" });
    let newdate = new Date(date).getDate();
    let month = dateNew.toLocaleString("default", { month: "short" });
    return day + "," + newdate + " " + month;
  };
  // Upcoming events data


  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  }; 
    // Stripe Integration
    const [clientSecret, setClientSecret] = useState(" ");
    const appearance = {
      theme: 'flat',
    };
   
    const options = {
      clientSecret,
      appearance 
    };
  
    


  // Wallet Api 

  const walletapi = (event, page) => {
    setIsLoadingwallet(true);
    if (page) setPage(page);
    else setPage(1);
    const walletListData = {
      page: page ? page : 1,
      keyword: searchedWorker,
    };



    http("wallet/wallet_history", "POST", walletListData)
      .then((res) => {

        setIsLoadingwallet(false);
        setCount(0);
        setCount(Math.ceil(res.data.data.total_rows / res.data.data.page_size));
        const data = res.data.data ?? [];
        setWallethistory(data ?? []);
        const newCurr = getSymbolFromCurrency(data.currency);
        setCurrencyval(newCurr);
        const groups = data.transactionHistory.reduce((groups, game) => {
          const date = dateFormated(game.created_on);
          if (!groups[date]) {
            groups[date] = [];
          }
          groups[date].push(game);
          return groups;
        }, {});

        // Edit: to add it in the array format instead
        const groupArrays = Object.keys(groups).map((date, index) => {
          return {
            key: { index },
            date,
            transactions: groups[date],
          };
        });
        setFilterDateData(groupArrays);
        setOrders(groupArrays)

        // window.location.reload()
      })
      .catch((err) => { });
  };

  const walletSearch = (e)=>{
    
     
    const walletListData = {
      page: page ? page : 1,
      keyword: e.target.value.toLowerCase(),
    };



    http("wallet/wallet_history", "POST", walletListData)
      .then((res) => {      
      if(res.data.status === 1){
      const groups = res.data.data.transactionHistory.reduce((groups, game) => {
        const date = dateFormated(game.created_on);
        if (!groups[date]) {
          groups[date] = [];
        }
        groups[date].push(game);
        return groups;
      }, {});

      // Edit: to add it in the array format instead
      const groupArrays = Object.keys(groups).map((date, index) => {
        return {
          key: { index },
          date,
          transactions: groups[date],
        };
      });
      setFilterDateData(groupArrays);
      setOrders(groupArrays)
  }else{
    setFilterDateData([])
    setOrders([])
  }


})

}

  // Cashout Api
  const [cashoutData , setCashOutData] =  useState([]);
  const [cashoutPage ,setCashoutPage ] = useState(1)
  const [cashoutCount , setCashoutCount] = useState(0)
  const CashoutHistory = (e , page)=>{
    page ?? setCashoutPage(page)
    let postData = {
      page:page? page : cashoutPage
    }
    
    http('user_cashout/cash_out_history', "POST" ,postData)
    .then((res)=>{ 
      setCashOutData(res.data.data)
      setCashoutCount(Math.ceil(res.data.total_page_count/res.data.page_size))
    })
  }

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();
  const urlId = query.get("id");

  const backOtp = ()=>{
    setOpen6(false)
    setOpen1(true)
    setOtp("")
  }

  const handleCloseErmsg = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenErrMsg(false);
  };

  const resendCashoutOtp = ()=>{
    http('user_cashout/resend_otp' , 'POST')
    .then((res)=>{
      // console.log(res , 'resend otp')
      if(res.data.status){
        // setErMsg(res.data.msg)
        NotificationManager.success(res.data.msg)
        setCounter(30)
        setActivelinlk(true)
      }
    })
  }
  
//action
const action = (
  <React.Fragment>
     
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleCloseErmsg}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  </React.Fragment>
);

  useEffect(() => {
    walletapi(1, "");
    CashoutHistory()
    if(new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    )){
      paymentStatus(new URLSearchParams(window.location.search).get(
        "payment_intent"
      ))
    }
    if (query.get("id")) {
      paymentStatus(query.get("id"));
    }
  }, [urlId]);

  useEffect(()=>{
    if (counter > 0) {
      const interval = setInterval(() => {
        setCounter(counter - 1);
      }, 1000);
      return () => clearInterval(interval);
    }
    if (counter === 0) setActivelinlk(false);
  },[counter])

  const [open, setOpen] = React.useState(false);
  const [openAddWallet, setOpenAddWallet] = React.useState(false);

  const [amount, setAmount] = React.useState("");
  const [erMsg , setErMsg] = useState('')
 

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleSubmitForm = (e) => {
    //validation for the amount textarea;
    // debugger
    e.preventDefault()
    var objValidation = {};
    objValidation = checkValidationFormAllControls(
      wallethistory.total_wallet_amount && parseInt(wallethistory.total_wallet_amount)>0 ?  document.forms[1].elements : document.forms[0].elements ,
      false,
      []
    );
    if (amount > 10000) {
      objValidation.amount = "Maximum credit amount limit is 10000";
      seterrorDisplay(objValidation);
      return;
    }
    if (objValidation.valid !== false) {
      seterrorDisplay(objValidation);
      return;
    } else {
      seterrorDisplay(objValidation);
    }
    setIsLoading(true);
 

    //end validation
    const addedData = {
      amount: amount,
    };
    // debugger
    http("wallet/wallet_amount_credit", "POST", addedData)
      .then((res) => {
        setIsLoading(false);
        if (res.data.status === 1) {
          if(JSON.parse( localStorage.getItem("loginUser")).currency_code === "INR"){
            
            setClientSecret(res.data.checkout_id) 
            setOpen(false);
            setOpenAddWallet(true);
          }else{
          var checkoutId = res.data.checkout_id;
          var url =
            window.location.hostname === "localhost" ||
              window.location.hostname === "staging.ticketkore.io" || window.location.hostname === "dev.ticketkore.io"
              ? `https://eu-test.oppwa.com/v1/paymentWidgets.js?checkoutId=${checkoutId}`
              : `https://eu-prod.oppwa.com/v1/paymentWidgets.js?checkoutId=${checkoutId}`;
          const script = document.createElement("script");

          script.src = url;
          script.async = true;
          document.body.appendChild(script);
          setCheckPeachPaymentCheckoutId(checkoutId);
          setOpen(false);
          setOpenAddWallet(true);
        }
          // navigate(0);
          if (checkout_new_id) {
            //paymentStatus(checkout_new_id);
            //NotificationManager.success(res.data.msg);
          }
        } else {
          NotificationManager.error(res.data.msg);
        }
      })
      .catch((err) => setIsLoading(false));
  };
  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);
    }
    //setOpen(false);
    setAmount("");
  };

  const min = 1;

  const paymentStatus = (id) => {
    const addedData = {
      id: id,
    };
    http("wallet/peach_paymnent_status", "POST", addedData)
      .then((res) => {
        if (res.data.status === 1) {
          setOpenAddWallet(false);
          setAmount("");
          NotificationManager.success(res.data.msg);
         setTimeout(()=>{
          navigate(-1);
         },1000)

        } else {
          setOpenAddWallet(false);
          NotificationManager.error(res.data.msg);
          setTimeout(()=>{
            navigate(-1);
          },1000)
        }
      })
      .catch((err) => { });
  };

  const CopyQRCode = (wallethistoryQR) => {
    navigator.clipboard.writeText(`${wallethistoryQR}`);
    NotificationManager.success("Offer Copied Successful");
  };


  useEffect(() => {
    const filtered = orders.filter(order => order.date.toLowerCase().includes(searchedWorker));
    setResults(filtered)
  }, [searchedWorker, orders])
   

  const { register, handleSubmit, watch ,  formState: { errors } } = useForm();
  const [AccData , setAccData] = useState({})
  const onSubmit = (data) => {
    // console.log(data) 
    setAccData(data)
    let postData =     {
      account_number: data.AccNum,
      account_holder:data.AccHoldName,
      bank_name:data.BankName,
      branch_code: data.IFSCode,
      verify_otp:"" 
  }
    http('user_cashout/cash_out_request_sent_and_verify_otp' , "POST" , postData)
    .then((res)=>{ 
      if(res.data.status ===1){
        setCounter(30)
        setActivelinlk(true)
        setOpen6(true)
        setOpen1(false)
        
      }else{
        // NotificationManager.error(res.data.msg)
        setOpenErrMsg(true)
        setErMsg(res.data.msg)
      }

    })
  };



  const verifyBankOtp = ()=>{ 
    let postData =     {
      account_number: AccData.AccNum,
      account_holder:AccData.AccHoldName,
      bank_name:AccData.BankName,
      branch_code: AccData.IFSCode,
      verify_otp:otp
  }
    http('user_cashout/cash_out_request_sent_and_verify_otp' , "POST" , postData)
    .then((res)=>{ 
      if(res.data.status ===1){
        setOpen6(false)
        setOpen1(false)
        NotificationManager.success(res.data.msg)
        CashoutHistory('' , page)
      }else if(res.data.status === "0"){
        // NotificationManager.error(res.data.msg)
        setOpenErrMsg(true)
        setErMsg(res.data.msg)
      }
    })
  }
  const {t} = useTranslation('common')
  return (
    <Box className="mainContainer" sx={{ marginBottom: "40px" }}>
      <Grid container spacing={4}>
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            marginTop: "20px",
          }}
        >
          <Typography className="layoutTitle">My Wallet</Typography>
          <div className="hLine"></div>
        </Grid>
      </Grid>

      

      {isLoadingwallet ? (
        <div style={{ height: "400px" }}>
          <div className="loader ">
            <ClipLoader />
          </div>
        </div>
      ) : (
        <>
          <Grid
            container
            spacing={5}
            sx={{ marginTop: "0px", marginBottom: "40px" }}
          >
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Box
                sx={{
                  textAlign: "center",
                  display: "block",
                  width: "100%",
                }}
              >
                <img
                  src={
                    wallethistory.wallet_qr_image
                      ? wallethistory.wallet_qr_image
                      : ""
                  }
                  alt="qr-code"
                />
              </Box>
              <Box
                className="flexGrid "
                sx={{ justifyContent: "center !important" }}
              >
                <Typography className="">
                {t('QR_ID')} :{" "}
                  <span className="colorBlue" sx={{ paddingRight: "10px" }}>
                    {" "}
                    {wallethistory.qr_code_number}
                  </span>
                  <img
                    onClick={() => CopyQRCode(wallethistory.qr_code_number)}
                    src="assets/images/copy.png"
                    style={{ marginLeft: "10px", cursor: "pointer" }}
                    alt='QR-Code'
                  />
                </Typography>
              </Box>
            </Grid>
            {wallethistory.total_wallet_amount && parseInt(wallethistory.total_wallet_amount)>0 ?
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Grid
                container
                sx={{
                  background: "#F4FBFF",
                  border: "1px solid #D7D7D7",
                  borderRadius: "16px",
                  margin: "18px 0px 45px 0px !important",
                  overflow: "hidden",
                  padding: "30px 36px 30px",
                  alignItems: "center",
                  display: "flex",
                  height: '350px',
                }}
              >
                <Grid item xs={12} sm={6} md={10}>
                  <Typography
 
                    sx={{ paddingTop: "0px !important" , fontSize:'18px' , fontWeight:700 , color:' #282828' }}
                  >
                    {t('Transfer_wallet_amount_to_bank_account')}
                  </Typography>
                  <ul>
                    <li>{t('You_will_get_your_cashout_within_7_Days')}</li>
                  </ul>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={12}
                  sx={{ textAlign: "center", marginTop: "40px !important" }}
                >
                  <Button
                    type="button"
                    className="updateBtn"
                    sx={{
                      maxWidth: "100% !important",
                      width: "100% !important",
                    }}
                    onClick={() => {
                      setOpen1(true) 
 }}
                  >
                    <img
                      src="assets/images/cash_out_img.png"
                      alt="Wallet"
                      style={{ marginRight: '5px' }} />
                    {t('CashOut')}
                  </Button>
                </Grid>

                <div className="addModel">
                  <div>
                    <Dialog
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="responsive-dialog-title"
                      style={{
                        width: "482px !important",
                        margin: "0px auto !important",
                      }}
                      disableBackdropClick
                    >
                      <Grid
                        item
                        xs={12}
                        sx={{ textAlign: "right", padding: "16px" }}
                      >
                        <CloseIcon
                          className="closeIconOffer"
                          onClick={handleClose}
                        />
                      </Grid>
                      <DialogTitle
                        id="responsive-dialog-title"
                        sx={{ padding: "3px 45px 0px 45px" }}
                      >
                        <Typography
                          id="transition-modal-title"
                          className="addMoneyTitle"
                        >
                          {t('Add_Money')}
                        </Typography>
                        <div className="hLine"></div>
                      </DialogTitle>
                      <form name="wallet" onSubmit={(e) => e.preventDefault()}>
                        <DialogContent sx={{ padding: "0px 45px 30px 45px" }}>
                          <DialogContentText>
                            <Typography
                              id="transition-modal-description"
                              sx={{ mb: 2 }}
                              className="addMoneyLabel"
                            >
                              {t('Add_Money_to')}{" "}
                              <span className="colorBlue">Ticketkore</span>{" "}
                              {t('Wallet')}
                            </Typography>
                            <TextField
                              id="outlined-basic"
                              placeholder="Enter amount"
                              type="number"
                              size="small"
                              fullWidth
                              // value={amount} 
                              // oninput="event.target.value =);"
                              onInput={(e) => {
                                e.preventDefault()
                                e.target.value = e.target.value
                                  .toString()
                                  .slice(0, 5);
                                e.target.value = Math.round(e.target.value.replace(/\D/g, ''))


                              }}
                              required
                              className="inputAddField"

                              onChange={(e) => {
                                if (e.target.value === "") {
                                  setAmount(e.target.value);
                                  seterrorDisplay("");
                                  // return;
                                }
                                const value = +e.target.value;

                                if (value < min) {
                                  setAmount("");
                                } else {
                                  setAmount(value);
                                }
                              }}



                            />

                            <Box
                              sx={{ marginTop: "5px", position: "absolute" }}
                            >
                              {errorDisplay.amount ? (
                                <span className="red">
                                  {errorDisplay.amount}
                                </span>
                              ) : (
                                ""
                              )}
                            </Box>
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions
                          sx={{
                            padding: "0px 45px 0px 35px",
                            margin: "0px 0px 50px 0px",
                          }}
                        >
                          <Button
                            // type="sub"
                            className="updateBtn"
                            sx={{ maxWidth: "283px" }}
                            onClick={(e) => handleSubmitForm(e)}
                          >
                             {t('Proceed_to_Add')}
                          </Button>
                        </DialogActions>
                      </form>
                    </Dialog>
                  </div>
                </div>
              </Grid>
            </Grid>
:<></>}
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Grid
                container
                sx={{
                  background: "#F4FBFF",
                  border: "1px solid #D7D7D7",
                  borderRadius: "16px",
                  margin: "18px 0px 45px 0px !important",
                  overflow: "hidden",
                  padding: "30px 36px 30px",
                  alignItems: "center",
                  display: "flex",
                  height: '350px',
                }}
              >
                <Grid item xs={12} sm={6} md={12}>
                  <Typography
 
                    sx={{ paddingTop: "0px !important" , fontSize:'18px' , fontWeight:700 , color:' #282828' }}
                  >
                    {t('Wallet_Balance')}
                  </Typography>
                  <Typography className="tranactionWalletBalance">
                    {" "}
                    <img
                      src="assets/images/walletIcon.png"
                      alt="Wallet"
                      style={{ marginRight: "17px" }}
                    />{" "}
                    {currencyval} {wallethistory.total_wallet_amount}{" "}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={12}
                  sx={{ textAlign: "center", marginTop: "40px !important" }}
                >
                  <Button
                    type="button"
                    className="updateBtn"
                    sx={{
                      maxWidth: "100% !important",
                      width: "100% !important",
                    }}
                    onClick={handleClickOpen}
                  >
                    {t('+_Add_Money')}
                  </Button>
                </Grid>

                <div className="addModel">
                  <div>
                    <Dialog
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="responsive-dialog-title"
                      style={{
                        width: "482px !important",
                        margin: "0px auto !important",
                      }}
                      disableBackdropClick
                    >
                      <Grid
                        item
                        xs={12}
                        sx={{ textAlign: "right", padding: "16px" }}
                      >
                        <CloseIcon
                          className="closeIconOffer"
                          onClick={handleClose}
                        />
                      </Grid>
                      <DialogTitle
                        id="responsive-dialog-title"
                        sx={{ padding: "3px 45px 0px 45px" }}
                      >
                        <Typography
                          id="transition-modal-title"
                          className="addMoneyTitle"
                        >
                          {t('Add_Money')}
                        </Typography>
                        <div className="hLine"></div>
                      </DialogTitle>
                      <form name="wallet" onSubmit={(e) => e.preventDefault()}>
                        <DialogContent sx={{ padding: "0px 45px 30px 45px" }}>
                          <DialogContentText>
                            <Typography
                              id="transition-modal-description"
                              sx={{ mb: 2 }}
                              className="addMoneyLabel"
                            >
                              {t("Add_Money_to")}{" "}
                              <span className="colorBlue">Ticketkore</span>{" "}
                              {t("Wallet")}
                            </Typography>
                            <TextField
                              id="outlined-basic"
                              placeholder="Enter amount"
                              type="number"
                              size="small"
                              fullWidth
                              // value={amount} 
                              // oninput="event.target.value =);"
                              onInput={(e) => {
                                e.preventDefault()
                                e.target.value = e.target.value
                                  .toString()
                                  .slice(0, 5);
                                e.target.value = Math.round(e.target.value.replace(/\D/g, ''))


                              }}
                              required
                              className="inputAddField"

                              onChange={(e) => {
                                if (e.target.value === "") {
                                  setAmount(e.target.value);
                                  seterrorDisplay("");
                                  // return;
                                }
                                const value = +e.target.value;

                                if (value < min) {
                                  setAmount("");
                                } else {
                                  setAmount(value);
                                }
                              }}



                            />

                            <Box
                              sx={{ marginTop: "5px", position: "absolute" }}
                            >
                              {errorDisplay.amount ? (
                                <span className="red">
                                  {errorDisplay.amount}
                                </span>
                              ) : (
                                ""
                              )}
                            </Box>
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions
                          sx={{
                            padding: "0px 45px 0px 35px",
                            margin: "0px 0px 50px 0px",
                          }}
                        >
                          <Button
                            // type="sub"
                            className="updateBtn"
                            sx={{ maxWidth: "283px" }}
                            onClick={(e) => handleSubmitForm(e)}
                          >
                            {t("Proceed_to_Add")}
                          </Button>
                        </DialogActions>
                      </form>
                    </Dialog>
                  </div>
                </div>
              </Grid>
            </Grid>
 


          </Grid>


          {/* Tabs Div */}

          <Box sx={{ width: '100%', background: '#FFFFFF', boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.25)', borderRadius: '16px' }}>
            <Box sx={{}} className="tab-box-wallet">
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label={t("Wallet_History")} {...a11yProps(0)} className="label-wallet-history" />
                <Tab label={t("CashOut_History")} {...a11yProps(1)} className="label-wallet-history" />
              </Tabs>
              <Box className="searchEventFav">
                <div >
                  <input
                    type="text"
                    class="input-bordered inputSearch CstminputSearch"
                    style={{ float: "right" }}
                    placeholder={t("Search_by_Date")}
                    // value={searchedWorker} //property specifies the value associated with the input
                    onChange={(e) => walletSearch(e)}
                  />
                </div>
              </Box>
            </Box>
            <TabPanel value={value} index={0}>
              <Card className="walletCard">
        
                {results.length > 0 ? (
                  <>
                    {results.map((ele, index) => {
                      return (
                        <Box key={index}>
                          <Grid container>
                            <Grid item xs={12} md={12}>
                              <Typography className="tranactionSubtitle">
                                {" "}
                                {ele.date}{" "}
                              </Typography>
                            </Grid>
                          </Grid>

                          {ele.transactions.map((trans, index) => {

                            return (
                              <>
                                <Box className="walletRowBox">
                                  <Grid
                                    container
                                    sx={{ display: "flex", alignItems: "center" }}
                                  >
                                    <Grid
                                      item
                                      xs={3}
                                      sm={2}
                                      md={1}
                                      lg={2}
                                      sx={{ textAlign: "center" }}
                                    >
                                      {trans.credit_status === "Success" ? (
                                        <img
                                          src="assets/images/added-from-bank.png"
                                          alt="Added From Bank"
                                        />
                                      ) : trans.debit_status === "Success" ? (
                                        <img
                                          src="assets/images/added-from-bank.png"
                                          alt="Added From Bank"
                                        />
                                      ) : trans.credit_status === "Pending" ||
                                        "Cancel" === trans.credit_status ? (
                                        <img
                                          src="assets/images/removefrom-bank.png"
                                          alt="Added From Bank"
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </Grid>
                                    <Grid item xs={9} sm={4} md={5} lg={4} sx={{}}>
                                      <Typography className="orderTitle">
                                        {" "}
                                        {trans.credit_status === "Success"
                                          ? "Amount Credit In Wallet"
                                          : trans.debit_status === "Success"
                                            ? `${trans.debit_purpose}`
                                            : trans.credit_status === "Pending" ||
                                              "Cancel" === trans.credit_status
                                              ? "Transaction failed"
                                              : ""}{" "}
                                      </Typography>
                                      <Typography className="transactionDate">
                                        {moment(trans.created_on).format("h:mm a")}{" "}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={7} sm={3} md={4} lg={4} sx={{}}>
                                      <Box
                                        sx={{
                                          textAlign: "right",
                                          padding: "10px 0px",
                                        }}
                                      >
                                        <Typography
                                          className={
                                            trans.credit_status === "Success" ||
                                              trans.debit_status === "Success"
                                              ? "greenText"
                                              : "redText"
                                          }
                                          sx={{
                                            margin: "0px 0px",
                                            fontWeight: "500",
                                          }}
                                        >
                                          {trans.credit_status === "Success"
                                            ? "+"
                                            : ""}
                                          {currencyval}


                                          {trans.amount}
                                        </Typography>
                                        <Typography
                                          sx={{
                                            fontWeight: 400,
                                            fontSize: "12px",
                                            lineHeight: "11px",
                                          }}
                                        >
                                          {" "}
                                          <strong>
                                            {t("Closing_balance")}: {currencyval}
                                            {trans.closeing_balance}
                                          </strong>{" "}
                                        </Typography>
                                      </Box>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={5}
                                      sm={3}
                                      md={2}
                                      lg={2}
                                      sx={{ textAlign: "right" }}
                                    >
                                      <Typography
                                        className={
                                          trans.credit_status === "Success" ||
                                            trans.debit_status === "Success"
                                            ? "greenText"
                                            : "redText"
                                        }
                                        sx={{
                                          margin: "0px 30px",
                                          fontWeight: "500",
                                        }}
                                      >
                                        {trans.type === "Debit"
                                          ? trans.debit_status
                                          : trans.credit_status}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Box>
                              </>
                            );
                          })}
                        </Box>
                      );
                    })}
                  </>
                ) : <Box>
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img src="assets/images/no-data.png" className="no-wallet-history-img" alt="data-not-found" />
                  </Box>
                  <Typography
                    variant="body1"

                    sx={{ textAlign: "center", height: "50px", fontWeight: 700, fontSize: '22px', mb: 4 }}
                  >
                    {t("No_Data_Found")}.
                  </Typography>
                </Box>}
              </Card>

              {wallethistory.total_rows > 10 ? (
                <Grid
                  container
                  justifyContent="center"
                  flexWrap="wrap"
                  sx={{ padding: "30px inherit !important" }}
                >
                  <Stack spacing={2} className="customPagination">
                    <Pagination count={count} page={page} onChange={walletapi} />
                  </Stack>
                </Grid>
              ) : (
                ""
              )}
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Card className="cash-out-card">
                <Box className="cash-out-card-box">
               

            {cashoutData &&  cashoutData?.length>0 ?
             
                 cashoutData.map((data, index)=>(
 <>
 <Accordion className="cash-out-pending-accordian" sx={{ mt: 3 }} expanded={expanded === 'panel2' + index} onChange={handleChange1('panel2'+index)}>
 <AccordionSummary
   expandIcon={<ExpandMoreIcon className="accordian-ExpandMoreIcon" />}
   aria-controls="panel1bh-content"
   id="panel1bh-header"
   className="mob-accordian-summary success-accordian-borderB"
 >
   <Grid container spacing={2}>
     <Grid item xs={12} sm={12} md={6} lg={5}>
       <Box className="display-center-between">
         <img src="assets/images/cash-out-accordian-img.png" alt="" />
         <div className="accordian-div-Req">
           <Typography className="pending-accordian-BankTrans">{t("Bank_Transfer_Request")}</Typography>
           <Typography className="pending-accordian-txn">{t("TXN")}:  {data.txn_number}</Typography>
         </div>
       </Box>
     </Grid>
     <Grid item xs={6} sm={6} md={3} lg={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
       <Typography className="accordian-time-text">{data.created_at}</Typography>
     </Grid>
     <Grid item xs={6} sm={6} md={3} lg={3} className="display-center-end">
       <Box className="accordian-pending-box-text">
         <Button className={data.status === "0" ? "accordian-pending-btn-text" : data.status === "1" ? "accordian-pending-btn-text accordian-success-btn-text" : "accordian-pending-btn-text accordian-cancel-btn-text"}>
           {data.status_text}
         </Button>
       </Box>
     </Grid>
   </Grid>
 </AccordionSummary>
 <AccordionDetails>
   <Grid container spacing={2} className="grid-account-holder">
     <Grid xs={12} sm={6} md={6} lg={4} sx={{ mt: 2 }}>
       <Box className="success-account-holder-box">
         <Typography className="success-account-holder-Name">{t("Account_Holder_Name")}:</Typography>
         <Typography className="success-account-holder-surname">{data.account_holder}</Typography>
       </Box>
       <Box className="success-account-holder-box" sx={{ mt: 1 }}>
         <Typography className="success-account-holder-Name">{t("IFSC Code")}:</Typography>
         <Typography className="success-account-holder-surname">{data.branch_code}</Typography>
       </Box>
     </Grid>

     <Grid xs={12} sm={6} md={6} lg={4} sx={{ mt: 2 }}>
       <Box className="success-account-holder-box">
         <Typography className="success-account-holder-Name">{t("Bank_Name")}: </Typography>
         <Typography className="success-account-holder-surname">  {data.bank_name}</Typography>
       </Box>
{data.status  === "1" ?     <Box className="success-account-holder-box" sx={{ mt: 1 }}>
         <Typography className="success-account-holder-Name">Amount:</Typography>
         <Typography className="success-account-holder-surname">{getSymbolFromCurrency( data.wallet_currency)} {data.cashout_amount}</Typography>
       </Box> : <></>}
     </Grid>

     <Grid xs={12} sm={6} md={6} lg={4} sx={{ mt: 2 }}>
       <Box className="success-account-holder-box">
         <Typography className="success-account-holder-Name">{t("Account_Number")}: </Typography>
         <Typography className="success-account-holder-surname"> {data.account_number}</Typography>
       </Box>
{data.admin_action_at ?       <Box className="success-account-holder-box" sx={{ mt: 1 }}>
         <Typography className="success-account-holder-Name">{t("Admin_Action")}: </Typography>
         <Typography className="success-account-holder-surname">{data.created_at}</Typography>
       </Box> : <></>}
     </Grid>

   {data.remark ?  <Grid xs={12} md={12} lg={12} sx={{ mt: 1 }}>
       <Box className="success-account-holder-box">
         <Typography className="success-account-holder-Name">
         {t("Admin_Remarks")}:
         </Typography>
         <Typography className="success-account-holder-surname">{data.remark}  </Typography>
       </Box>
     </Grid>
       : <></>     }
   </Grid>

 </AccordionDetails>
</Accordion>

 
 {cashoutData.total_page_count>10 ?
  <Grid
    container
    justifyContent="center"
    flexWrap="wrap"
    sx={{ padding: "30px inherit !important" }}
  >
    <Stack spacing={2} className="customPagination">
      <Pagination count={cashoutCount} page={cashoutPage} onChange={CashoutHistory} />
    </Stack>
  </Grid>
: <></> }
  </>

                ))
             : <Box>
             <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
               <img src="assets/images/no-data.png" className="no-wallet-history-img" alt="data-not-found" />
             </Box>
             <Typography
               variant="body1"

               sx={{ textAlign: "center", height: "50px", fontWeight: 700, fontSize: '22px', mb: 4 }}
             >
               {t("No_Data_Found")}.
             </Typography>
           </Box>   }

 

                </Box>
              </Card>


            </TabPanel>

          </Box>

          {/* Cash Out Modal */}

          <Box>
            <Modal
              open={open1}
              onClose={handleClose1}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style1} className="cashout-modal">
                <Typography id="modal-modal-title" variant="h6" component="h2" className="modal-cashout-top-H">
                  {t("CashOut")}
                </Typography>
                <form onSubmit={handleSubmit(onSubmit)}>
                <Box sx={{ mt: 2 }}>
                  <Typography className="modal-typo-cashout-cmn">
                  {t("Account_Holder_Name")}<span style={{ color: '#E92626' }}>*</span>
                  </Typography>
                  <TextField 
                    fullWidth
                    inputProps={{ maxLength: 30 }}
                    name="first_name"
                    {...register("AccHoldName", { required: true , minLength:5 , maxLength:30   })}
                    className="input-focus-visbile-unset"
                  >
                  </TextField>
                  {errors.AccHoldName?.type === "required" &&  (<Typography variant="body1" sx={{color:'#E92626'}} > {t("This_field_is_required")} </Typography>)}
                  {errors?.AccHoldName?.type === "minLength" && ( <Typography variant="body1" sx={{color:'#E92626'}} > {t("Name_must_be_greater_than_5")} </Typography>)}
                  {errors?.AccHoldName?.type === "maxLength" && ( <Typography variant="body1" sx={{color:'#E92626'}} > {t("Name_must_not_be_greater_than_30")} </Typography>)}
                </Box>

                <Box sx={{ mt: 2 }}>
                  <Typography className="modal-typo-cashout-cmn">
                    {t("Bank_Name")}<span style={{ color: '#E92626' }}>*</span>
                  </Typography>
                  <TextField
                     inputProps={{ maxLength: 50 }}
                    fullWidth
                    name="BankName"
                    {...register("BankName", { required: true , minLength:5 , maxLength:50   })}
                    className="input-focus-visbile-unset"
                  >
                  </TextField>
                  {errors?.BankName?.type === "required" &&  <Typography variant="body1" sx={{color:'#E92626'}} > {t("This_field_is_required")} </Typography>}
                  {errors?.BankName?.type === "minLength" && ( <Typography variant="body1" sx={{color:'#E92626'}} > {t("Name_must_be_greater_than_5")} </Typography>)}
                  {errors?.BankName?.type === "maxLength" && ( <Typography variant="body1" sx={{color:'#E92626'}} > {t("Name_must_not_be_greater_than_50")} </Typography>)}
                </Box>

                <Box sx={{ mt: 2 }}>
                  <Typography className="modal-typo-cashout-cmn">
                    {t("Account_Number")}<span style={{ color: '#E92626' }}>*</span>
                  </Typography>
                  <TextField

                    fullWidth
                    inputProps={{ maxLength: 30 }}
                    name="first_name"
                    {...register("AccNum", { required: true , minLength:5 , maxLength:30 })}
                    className="input-focus-visbile-unset"
                  >
                  </TextField>
                 
                  {errors.AccNum?.type === "required" &&  (<Typography variant="body1" sx={{color:'#E92626'}} > {t("This_field_is_required")} </Typography>)}
                  {errors?.AccNum?.type === "minLength" && ( <Typography variant="body1" sx={{color:'#E92626'}} >{t("Name_must_be_greater_than_5")} </Typography>)}
                  {errors?.AccNum?.type === "maxLength" && ( <Typography variant="body1" sx={{color:'#E92626'}} > {t("Name_must_not_be_greater_than_30")} </Typography>)}
                </Box>

                <Box sx={{ mt: 2 }}>
                  <Typography className="modal-typo-cashout-cmn">
                    {t("Confirm_Account_Number")}<span style={{ color: '#E92626' }}>*</span>
                  </Typography>
                  <TextField

                    fullWidth
                    inputProps={{ maxLength: 30 }}
                    name="first_name"
                    // {...register("ConfirmAccNum", { required: true })}
                    {...register("ConfirmAccNum", {
                      required: true,
                      validate: (val) => {
                        if (watch('AccNum') !== val) {
                          return "Your passwords do no match";
                        }
                      },
                     })}
                    className="input-focus-visbile-unset"
                  >
                  </TextField>
                  {errors.ConfirmAccNum?.type === "required" &&  (<Typography variant="body1" sx={{color:'#E92626'}} > {t("This_field_is_required")} </Typography>)}
                  {errors.ConfirmAccNum?.type === "validate" &&  <Typography variant="body1" sx={{color:'#E92626'}} > {t("Confirm_Account_Number_must_be_same_Account_Number")} </Typography>}
                </Box>

                <Box sx={{ mt: 2 }}>
                  <Typography className="modal-typo-cashout-cmn">
                    {t("IFSC_Code")}<span style={{ color: '#E92626' }}>*</span>
                  </Typography>
                  <TextField

                    fullWidth
                    name="IFSC-code"
                     
                    className="input-focus-visbile-unset"
                    {...register("IFSCode", { required: true })}
                  >
                  </TextField>
                  {errors.IFSCode &&  <Typography variant="body1" sx={{color:'#E92626'}} > {t("This_field_is_required")} </Typography>}
                </Box>
               
                <Box sx={{ mt: 4, display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                  <Button className="modal-cancel-cashout" onClick={() => setOpen1(false)}>{t("Cancel")}</Button>
                  <Button className="modal-proced-cashout" type="submit"  >{t("Proceed_To_CashOut")}</Button>
                  
                </Box>
                </form>
              </Box>
            </Modal>
          </Box>
          {/* End Cash out modal */}

          {/* End Tabs div */}  
        </>
      )}

      {/* add wallet model */}
 
      <Box>
        {isLoading ? (
          <div style={{ height: "400px" }}>
            <div className="loader ">
              <ClipLoader />
            </div>
          </div>
        ) : (
          <Modal
            hideBackdrop
            open={openAddWallet}
            onClose={handleSubmodelClose}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Box sx={{ ...style, width: 600 }}>
              <CloseIcon
                className="closeIconOffer"
                onClick={handleSubmodelClose}
              />
              { JSON.parse(localStorage.getItem('loginUser')).currency_code !== "INR" ? 
                 (
                <form
                  action={
                    window.location.hostname === "localhost" ? "http://localhost:3000/MyWallet" :window.location.hostname === "3.16.122.244" ? "http://3.16.122.244:3000/MyWallet" : window.location.hostname === "staging.ticketkore.io" ? "https://staging.ticketkore.io/website/MyWallet" : window.location.hostname === "dev.ticketkore.io" ?
                      "https://dev.ticketkore.io/website/MyWallet" : "https://ticketkore.io/MyWallet"
                  }
                  class="paymentWidgets"
                  data-brands="VISA MASTER AMEX"
                ></form>
              ) : (           clientSecret && (
                <Elements options={options} stripe={stripePromise}>
                  <CheckoutForm   return_url={
                          window.location.hostname === "localhost"
                            ? "http://localhost:3000/MyWallet/"
                            : window.location.hostname ===
                              "staging.ticketkore.io"
                            ? "https://staging.ticketkore.io/website/MyWallet/"
                            : window.location.hostname === "dev.ticketkore.io"
                            ? "https://dev.ticketkore.io/website/MyWallet/"
                            : "https://ticketkore.io/MyWallet/"
                        }     />
                </Elements>
              ))}
            </Box>
          </Modal>
        )}
      </Box>

      {/* Offer Detail  */}

      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open6} 
          className="formPopUp"
                  // hideBackdrop={true}
        >
          <Fade in={open6}>
            <Box sx={style2}>

              <Box sx={{ style2 }}>
                <Typography id="modal-modal-title" variant="h6" component="h2" className="modal-cashout-top-H">
                  {t('Verify_OTP')}
                </Typography>

                <Typography variant="h6" component="h2" className="modal-cashout-otp-verify" sx={{ mt: 5 }}>
                {t('We_have_sent_a_verification_code_to_your_mobile_number')} <span className="cashout-num">+{JSON.parse( localStorage.getItem('loginUser')).country_code}{JSON.parse( localStorage.getItem('loginUser')).phone}</span>  
                </Typography>

                <Typography variant="h6" component="h2" className="modal-cashout-otp-verify-code" sx={{ mt: 4 }}>
                {t('Please_enter_the_send_verification_code')} 
                </Typography>

              </Box>
              <Box noValidate autoComplete="off" className="formOtpBox" sx={{ mt: 5 }}>
                <OtpInput
                  value={otp}
                  onChange={(otp) => setOtp(otp)}
                  numInputs={4}
                  className="otpInput activeBorder otpInput-cashout"
                  inputStyle={"otp-input-box"}
                  isInputNum={true}
                />
              </Box>

              <Box sx={{ mt: 5, mb: 4 }} className="cash-out-main-otp-box">
                <Button className="cash-out-back-otp" onClick={() => backOtp()}>
                {t('Back')} 
                </Button>
                <Button className={otp.length>=4 ? "cash-out-verify-otp":"disable-otp"} onClick={verifyBankOtp} disabled={otp.length>=4 ? false:true}>
                {t('Verify')} 
                </Button>

              </Box>
              <Typography className="formText" sx={{ marginTop: "20px" }}>
                  <Box>
                    <Button
                      sx={{
                        fontFamily: "unset",
                        textTransform: "capitalize",
                        textDecoration: "underline",
                        fontWeight: "600",
                      }}
                      disabled={activelink}
                      onClick={resendCashoutOtp}
                      className={
                        activelink ? "resend-otp-active" : "resend-otp-deactive"
                      }
                    >
                      {t('Resend')}
                    </Button>
                  </Box>{" "}
                  {t('Haven_t_got_the_OTP')}  - {counter > 0 ? counter : "0"}
                </Typography>
            </Box>
          </Fade>
        </Modal>
      </div>
      <Snackbar
        open={openErrMsg}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={2000}
        onClose={handleCloseErmsg} 
      action={action}
      > 
        <Alert  severity="error"> 
          {erMsg}
        </Alert>
        </Snackbar >

      {/* End Offer Detail  */}
    </Box>
  );
};

export default Wallet;
