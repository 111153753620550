import { COUNTRY } from "./index"
import { CITY } from "./index"
import {EVENTSEXPIRED} from "./index" 
export const countryData = (data) => {
    return ({
        type: COUNTRY,
        payload: data,
    })
}

export const cityData = (data) => {
    return ({
        type: CITY,
        payload: data,
    })
}

export const eventsData = (data) => {
    return ({
        type: CITY,
        payload: data,
    })
}
export const isEventsExpired = (data) => {
    
    return ({
        type: EVENTSEXPIRED,
        payload: data,
    })
}
