import React from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Button, Typography } from '@mui/material';
import Modal from '@mui/material/Modal';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import "./style.css";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '703px',
    width: '95%',
    margin: 'auto',
    bgcolor: 'background.paper',
    boxShadow: 24,
    padding: '30px 0px',
    borderRadius: '16px',
};

const TicketTotalPrice = () => {
    const [count, setCount] = React.useState(1);

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    return (
        <div>
            <Grid container spacing={2} alignItems="center" sx={{ backgroundColor: '#F6F6F6', margin: '20px 0px', padding: '10px 0px', width: '100%' }}>
                <Grid item xs={12} sm={6} md={6} sx={{ textAlign: 'right', paddingRight: '10px' }}>
                    <Typography className='BottomTotalP'>Total Price: R200.00</Typography>
                    <Typography className='BottomTickets'>(2 Tickets)</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6} sx={{}}>
                    <Button className='ProcedBtn' onClick={handleOpen}>
                        Proceed
                    </Button>
                </Grid>
            </Grid>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box sx={{ padding: '0px 48px'}}>
                        <div className='popUPTopHeadder'>
                            <Typography className='ArrowBack'><ArrowBackIosIcon sx={{ cursor: 'pointer' }} onClick={handleClose} />Add on Services</Typography>
                            <Button className='PopTopHeadderBtn' onClick={handleClose}>Skip</Button>
                        </div>
                        <div className='hLine'></div>
                    </Box>
                    <Box sx={{ padding: '0px 48px', overflowY: 'auto', height: '42vh' }}>


                        <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'center' }}>
                            <Grid item xs={12} md={3}>
                                <div className='popUptshirtsImg'>
                                    <img src='assets/images/tshirt.png'></img>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <Typography className='tshirtTypoPop'>T-Shirt</Typography>
                                <Typography className='SizeTypoPop'>Size: <span style={{ color: '#0CA8F6' }}>Large</span> </Typography>
                                <Typography className='SizePricePop'>Price: <span style={{ color: '#0CA8F6' }}>R200.00</span> </Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <div className='gnrlImgGrid'>
                                    <Button
                                        aria-label="reduce"
                                        onClick={() => {
                                            setCount(Math.max(count - 1, 0));
                                        }}
                                        sx={{ padding: '0px !important', margin: '0px !important' }}
                                    >
                                        <img src='assets/images/minus.png'></img>
                                    </Button>
                                    <div className='detailBottomL'></div>
                                    <Box className='Textnumbers'>{count}</Box>
                                    <Button
                                        aria-label="increase"
                                        onClick={() => {
                                            setCount(count + 1);
                                        }}
                                        sx={{ padding: '0px !important', margin: '0px !important' }}
                                    >
                                        <img src='assets/images/plus.png'></img>
                                    </Button>
                                </div>
                            </Grid>

                        </Grid>
                        <div className="hLine3"></div>

                        <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'center' }}>
                            <Grid item xs={12} md={3}>
                                <div className='popUptshirtsImg'>
                                    <img src='assets/images/tshirt.png'></img>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <Typography className='tshirtTypoPop'>T-Shirt</Typography>
                                <Typography className='SizeTypoPop'>Size: <span style={{ color: '#0CA8F6' }}>Large</span> </Typography>
                                <Typography className='SizePricePop'>Price: <span style={{ color: '#0CA8F6' }}>R200.00</span> </Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <div className='gnrlImgGrid'>
                                    <Button
                                        aria-label="reduce"
                                        onClick={() => {
                                            setCount(Math.max(count - 1, 0));
                                        }}
                                        sx={{ padding: '0px !important', margin: '0px !important' }}
                                    >
                                        <img src='assets/images/minus.png'></img>
                                    </Button>
                                    <div className='detailBottomL'></div>
                                    <Box className='Textnumbers'>{count}</Box>
                                    <Button
                                        aria-label="increase"
                                        onClick={() => {
                                            setCount(count + 1);
                                        }}
                                        sx={{ padding: '0px !important', margin: '0px !important' }}
                                    >
                                        <img src='assets/images/plus.png'></img>
                                    </Button>
                                </div>
                            </Grid>

                        </Grid>
                        <div className="hLine3"></div>

                        <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'center' }}>
                            <Grid item xs={12} md={3}>
                                <div className='popUptshirtsImg'>
                                    <img src='assets/images/tshirt.png'></img>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <Typography className='tshirtTypoPop'>T-Shirt</Typography>
                                <Typography className='SizeTypoPop'>Size: <span style={{ color: '#0CA8F6' }}>Large</span> </Typography>
                                <Typography className='SizePricePop'>Price: <span style={{ color: '#0CA8F6' }}>R200.00</span> </Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <div className='gnrlImgGrid'>
                                    <Button
                                        aria-label="reduce"
                                        onClick={() => {
                                            setCount(Math.max(count - 1, 0));
                                        }}
                                        sx={{ padding: '0px !important', margin: '0px !important' }}
                                    >
                                        <img src='assets/images/minus.png'></img>
                                    </Button>
                                    <div className='detailBottomL'></div>
                                    <Box className='Textnumbers'>{count}</Box>
                                    <Button
                                        aria-label="increase"
                                        onClick={() => {
                                            setCount(count + 1);
                                        }}
                                        sx={{ padding: '0px !important', margin: '0px !important' }}
                                    >
                                        <img src='assets/images/plus.png'></img>
                                    </Button>
                                </div>
                            </Grid>

                        </Grid>
                        <div className="hLine3"></div>

                        <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'center' }}>
                            <Grid item xs={12} md={3}>
                                <div className='popUptshirtsImg'>
                                    <img src='assets/images/tshirt.png'></img>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <Typography className='tshirtTypoPop'>T-Shirt</Typography>
                                <Typography className='SizeTypoPop'>Size: <span style={{ color: '#0CA8F6' }}>Large</span> </Typography>
                                <Typography className='SizePricePop'>Price: <span style={{ color: '#0CA8F6' }}>R200.00</span> </Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <div className='gnrlImgGrid'>
                                    <Button
                                        aria-label="reduce"
                                        onClick={() => {
                                            setCount(Math.max(count - 1, 0));
                                        }}
                                        sx={{ padding: '0px !important', margin: '0px !important' }}
                                    >
                                        <img src='assets/images/minus.png'></img>
                                    </Button>
                                    <div className='detailBottomL'></div>
                                    <Box className='Textnumbers'>{count}</Box>
                                    <Button
                                        aria-label="increase"
                                        onClick={() => {
                                            setCount(count + 1);
                                        }}
                                        sx={{ padding: '0px !important', margin: '0px !important' }}
                                    >
                                        <img src='assets/images/plus.png'></img>
                                    </Button>
                                </div>
                            </Grid>

                        </Grid>
                        <div className="hLine3"></div>

                        <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'center' }}>
                            <Grid item xs={12} md={3}>
                                <div className='popUptshirtsImg'>
                                    <img src='assets/images/tshirt.png'></img>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <Typography className='tshirtTypoPop'>T-Shirt</Typography>
                                <Typography className='SizeTypoPop'>Size: <span style={{ color: '#0CA8F6' }}>Large</span> </Typography>
                                <Typography className='SizePricePop'>Price: <span style={{ color: '#0CA8F6' }}>R200.00</span> </Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <div className='gnrlImgGrid'>
                                    <Button
                                        aria-label="reduce"
                                        onClick={() => {
                                            setCount(Math.max(count - 1, 0));
                                        }}
                                        sx={{ padding: '0px !important', margin: '0px !important' }}
                                    >
                                        <img src='assets/images/minus.png'></img>
                                    </Button>
                                    <div className='detailBottomL'></div>
                                    <Box className='Textnumbers'>{count}</Box>
                                    <Button
                                        aria-label="increase"
                                        onClick={() => {
                                            setCount(count + 1);
                                        }}
                                        sx={{ padding: '0px !important', margin: '0px !important' }}
                                    >
                                        <img src='assets/images/plus.png'></img>
                                    </Button>
                                </div>
                            </Grid>

                        </Grid>
                        <div className="hLine3"></div>

                        <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'center' }}>
                            <Grid item xs={12} md={3}>
                                <div className='popUptshirtsImg'>
                                    <img src='assets/images/tshirt.png'></img>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <Typography className='tshirtTypoPop'>T-Shirt</Typography>
                                <Typography className='SizeTypoPop'>Size: <span style={{ color: '#0CA8F6' }}>Large</span> </Typography>
                                <Typography className='SizePricePop'>Price: <span style={{ color: '#0CA8F6' }}>R200.00</span> </Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <div className='gnrlImgGrid'>
                                    <Button
                                        aria-label="reduce"
                                        onClick={() => {
                                            setCount(Math.max(count - 1, 0));
                                        }}
                                        sx={{ padding: '0px !important', margin: '0px !important' }}
                                    >
                                        <img src='assets/images/minus.png'></img>
                                    </Button>
                                    <div className='detailBottomL'></div>
                                    <Box className='Textnumbers'>{count}</Box>
                                    <Button
                                        aria-label="increase"
                                        onClick={() => {
                                            setCount(count + 1);
                                        }}
                                        sx={{ padding: '0px !important', margin: '0px !important' }}
                                    >
                                        <img src='assets/images/plus.png'></img>
                                    </Button>
                                </div>
                            </Grid>

                        </Grid>
                        <div className="hLine3"></div>
                    </Box>

                    <Grid container spacing={2} alignItems="center" sx={{ backgroundColor: '#F6F6F6', margin: '80px 0px 0px', padding: '10px 0px', width: '100%' }}>
                        <Grid item xs={12} sm={6} md={6} sx={{ textAlign: 'right', paddingRight: '10px' }}>
                            <Typography className='BottomTotalP'>Total Price: R200.00</Typography>
                            <Typography className='BottomTickets'>(2 Tickets)</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} sx={{}}>
                            <Button className='ProcedBtn' onClick={handleOpen}>
                                Proceed
                            </Button>
                        </Grid>
                    </Grid>

                </Box>
            </Modal>
        </div>



    )
}

export default TicketTotalPrice