import React from 'react'
import { Grid, Typography } from '@mui/material'

const Map = () => {
    return (
        <div>
            <Grid container spacing={2} >

                <Grid  xs={12} sm={12} md={12}>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3581.936989758098!2d28.144982714644005!3d-26.133597383469247!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e95125800e53515%3A0x567b2e9b7392bfbe!2s131%207th%20Ave%2C%20Edenvale%2C%201609%2C%20South%20Africa!5e0!3m2!1sen!2sin!4v1660114036758!5m2!1sen!2sin" width="100%" height="450" frameborder="0" style={{ border: 0 }} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                </Grid>
            </Grid>
        </div>
    )
}

export default Map