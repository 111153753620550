import React from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';
import {useTranslation} from 'react-i18next'
import "./style.css";
import { Typography } from '@mui/material';

const BuyComponent = () => {
  const {t} = useTranslation("common")
  return (
    <Box sx={{ flexGrow: 1 }}>
      <div className='buyCompTopDiv'>
        <Box className='mainContainer'>
          <Grid container spacing={2} sx={{ pt: 3, pb: 4 }}>
            <Grid item xs={12} sm={6} md={6} lg={6} sx={{ textAlign: 'center' }} className="tab_dsflex">
              <img src='assets/images/wovWristBand.png' alt='WristBand' />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} sx={{ md: 'center' }}>
              <div className='mainDivWhoWeAre'>
                <Typography className='HardTurnCH'>{t("Woven_Wristband")}
                </Typography>
                <div className='whoWeAreHLine'></div>
                <div className='BuyDivFlex' style={{ paddingTop: '19px' }}>
                  <Typography className='BuyDivMq'>MOQ:</Typography>
                  <Typography className='BuyTypoNum'>500</Typography>
                </div>
                <div className='BuyDivFlex'>
                  <Typography className='BuyDivMq'>Material:</Typography>
                  <Typography className='BuyTypoNum'>100% Polyester</Typography>
                </div>
                <div className='BuyDivFlex'>
                  <Typography className='BuyDivMq'>Dimension:</Typography>
                  <Typography className='BuyTypoNum'>25cm x 2.50cm</Typography>
                </div>
                <div className='BuyDivFlex'>
                  <Typography className='BuyDivMq'>Lead Time:</Typography>
                  <Typography className='BuyTypoNum'>1 – 3 days from confirmed order</Typography>
                </div>
                <div className='BuyDivFlex'>
                  <Typography className='BuyDivMq'>Branding:</Typography>
                  <Typography className='BuyTypoNum'>Full Color</Typography>
                </div>
                <div className='BuyDivFlex'>
                  <Typography className='BuyDivMq'>Colors:</Typography>
                  <Typography className='BuyTypoNum'>Variable</Typography>
                </div>
                <div className='BuyDivFlex'>
                  <Typography className='BuyDivMq'>Optional Extra:</Typography>
                  <Typography className='BuyTypoNum'>NTag 213/Mifare Card</Typography>
                </div>
                <div className='commonHardDiv'>
                  <Link to="/contactus" className='linkDecoration'>
                    <span style={{ borderBottom: '2px solid #0CA8F6' }}>{t("Enquire_now")}</span>
                    <img src='assets/images/hardArrow.png' alt='Arrow' style={{ marginLeft: '10px' }} />
                  </Link>
                </div>
              </div>
            </Grid>
          </Grid>
        </Box>
      </div>

      <div className='BuyTyvekDiv'>
        <Box className='mainContainer'>
          <Grid container spacing={2} sx={{ pt: 3, pb: 6 }} className='BuymobColReverse'>

            <Grid item xs={12} sm={6} md={6} lg={6} sx={{}}>
              <div className='mainDivWhoWeAre'>
                <Typography className='HardTurnCH' sx={{ paddingTop: '68px' }}>{t("Tyvek_Wristband")}
                </Typography>
                <div className='whoWeAreHLine'></div>
                <div className='BuyDivFlex' style={{ paddingTop: '19px' }}>
                  <Typography className='BuyDivMq'>MOQ:</Typography>
                  <Typography className='BuyTypoNum'>100</Typography>
                </div>
                <div className='BuyDivFlex'>
                  <Typography className='BuyDivMq'>Dimension:</Typography>
                  <Typography className='BuyTypoNum'>25cm x 2.50cm</Typography>
                </div>
                <div className='BuyDivFlex'>
                  <Typography className='BuyDivMq'>Lead Time:</Typography>
                  <Typography className='BuyTypoNum'>1 – 3 days from confirmed order</Typography>
                </div>
                <div className='BuyDivFlex'>
                  <Typography className='BuyDivMq'>Branding:</Typography>
                  <Typography className='BuyTypoNum'>1 Color Print, Black</Typography>
                </div>
                <div className='BuyDivFlex'>
                  <Typography className='BuyDivMq'>Colors:</Typography>
                  <Typography className='BuyTypoNum'>Variable</Typography>
                </div>
                <div className='commonHardDiv'>
                  <Link to="/contactus" className='linkDecoration'>
                    <span style={{ borderBottom: '2px solid #0CA8F6' }}>{t("Enquire_now")}</span>
                    <img src='assets/images/hardArrow.png' alt='Arrow' style={{ marginLeft: '10px' }} />
                  </Link>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} className='TyvekWristbandGrid'>
              <img src='assets/images/TyvekWristband.png' alt='TyvekWristband' />
            </Grid>
          </Grid>
        </Box>
      </div>
    </Box>
  )
}

export default BuyComponent