import React, { useState, useEffect } from "react";
import { Box } from "@mui/system";
import { Grid, Typography } from "@mui/material";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import Button from "@mui/material/Button";
import http from "../../Services/http";
import { useParams } from "react-router-dom";
import { SpinnerCircular } from "spinners-react";
import { NotificationManager } from "react-notifications";
import ModalPopup from "../ModalPopup";
import { useNavigate, useLocation } from "react-router-dom";
import {useTranslation} from 'react-i18next'
import "./style.css";
import { DynamicFeed } from "@mui/icons-material";

const Interested = (props) => {
  const {t} = useTranslation('common')
const location = useLocation()
const Dynamic=location.pathname

  const navigate = useNavigate()
  const params = useParams();
  const { eventdetailspage1, parentlike } = props;
  const [like, setLike] = useState(eventdetailspage1.interested);
  const [liketotal, setLiketotal] = useState(
    eventdetailspage1.total_interested
  );

  const [loading, setLoading] = useState(false);




  // Module Hoc Function
  const [openLogin, setOpenLogin] = React.useState(false);
  const handleClose = () => {
    setOpenLogin(false);
  };


 


 

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();


  const checkLoggedIn = (e, params) => {
    const id = params
    if (!localStorage.getItem("token")) {
      setOpenLogin(true);
    } else {
      InterstedApi()

    }
  };



  const InterstedApi = () => {
    setLoading(true);
    let postData = {
      eventid: params.id,
    };
    if (eventdetailspage1.ts == "cs") {
      var url = "coming_soon/interested_event";
    } else {
      var url = "events/interested_event";
    }

    http(url, "POST", postData)
      .then(({ data }) => {
        setLoading(false);
        if (data.msg === "success! event is added in your interested section") {
          setLike(1);
          // eventdetailspage1.interested =  eventdetailspage1.interested  + 1
          eventdetailspage1.total_interested =
            eventdetailspage1.total_interested + 1;
          setLiketotal(eventdetailspage1.total_interested);
        } else {
          setLike(0);
          // eventdetailspage1.interested =  eventdetailspage1.interested  - 1
          eventdetailspage1.total_interested =
            eventdetailspage1.total_interested - 1;
          setLiketotal(eventdetailspage1.total_interested);
          // NotificationManager.error("event is removed");
        }
        // parentlike();
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <div
      style={{
        backgroundColor: "#F6F6F6",
        marginTop: "35px",
        padding: "20px 0px",
        borderRadius: "10px",
        // marginBottom:'10px'
      }}
      className="mainContainer"
    >
      <Box
        sx={{ flexGrow: 1, ml: 2, fontFamily: "Lato" }}
        className="NewcommonWidth"
      >
        <Grid container>
          <Grid item md={12}>
            <div className="displaFlexProp mobDisplaFlexProp">
              <Button
                startIcon={
                  <ThumbUpIcon
                    sx={{ background: "none", fontSize: "30px !important" }}
                    className={like == 0 ? "thumbRedClr" : "thumbGreenClr"}
                  />
                }
                className="btnText"
              >
                {/* ({eventdetailspage1.total_interested}) */}
                {liketotal}
                <Typography className="clickTypo MobClickTypo">
                  {t('Click_on_interested_to_stay_updated_about_this_event')}
                </Typography>
              </Button>

              <button
                disabled={loading}
                className="IntBtn"

                onClick={(e) => checkLoggedIn(e)}
              // onClick={InterstedApi}
              >
                {loading ? (
                  <SpinnerCircular
                    style={{ width: "1.2rem", height: "1.2rem" }}
                    color="light"
                  />
                ) : (
                  `${t('Interested?')}`
                )}
              </button>
            </div>
          </Grid>
          {openLogin ? <ModalPopup handleOpen={openLogin} handleClick={handleClose} /> : ""}
        </Grid>
      </Box>
    </div>
  );
};

export default Interested;
