import React, { useEffect, useState } from "react";
import { SeatsioSeatingChart } from "@seatsio/seatsio-react";
import { Box } from "@mui/system";
import { Button, Grid, Typography } from "@mui/material";
import "./Style.css";
import http from "../../Services/http"
 
 

const FloorPlan = ({eventData , getAddon , stepOne  , stepTwo , quantity , selectedTicketData, editTickets , CallBackForSeatIOPayment}) => {   
  const [selSeatLabel , setSelSeatLabel] = useState([])
  const [selSeatLabels , setSelSeatLabels] = useState([])
  const [holdToken , setHoldToken] = useState('')
  // const {state} = useLocation()
 
  //variable created for managing the eventlistner
  let event = '';
  useEffect(()=>{
    localStorage.setItem('label' , JSON.stringify(selSeatLabel))
    localStorage.setItem('labels' , JSON.stringify(selSeatLabels))
   
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[selSeatLabel])
  const EditTickets = (labels)=>{
    
    let postData = {
      objects:  String(selSeatLabel.map((lable)=> lable.label)),
      chart_event_id:selectedTicketData.floor_plan.floor_event_id
    }
    http("orders/releaseChartObjects" , 'POST' , postData).then((res)=>{
 
      if(res.data.status === 1)
      setSelSeatLabel([])
      setSelSeatLabels([])
      localStorage.removeItem('label')
      localStorage.removeItem('labels')
      localStorage.removeItem('holdToken')
      localStorage.removeItem('Chart_Id')
      editTickets(false)
    })
  }

  const OrderPreviewSeatIo = ()=>{
    CallBackForSeatIOPayment(()=>true)     
    getAddon(event,selSeatLabel , holdToken , selSeatLabels , true)
  }
 

  return (
    <div>

      <Box className="mainContainer">
        <Grid container spacing={2} className="seatIoGrid">
          <Grid item xs={7} sm={9} md={10} lg={10}>
            <Typography className="seatIoGridTypo1">
              {eventData.event_name}
            </Typography>
            <Typography className="seatIoGridTypo2">
              {stepOne}  {stepTwo} 
            </Typography>
          </Grid>
          <Grid item xs={5} sm={3} md={2} lg={2} className="SeatBtn_Grid">
            <div className="seat_io_Div" onClick={EditTickets}>
              <img src="assets/images/seat_io_img.png" alt="ticket" />
              <button className="seat_io_Btn" >{quantity} Ticket(s)</button>
            </div>
          </Grid>
          <div className="hrlineBtm"></div>
          {selectedTicketData ?
           <div id='chart' className="floor-plan">
          <SeatsioSeatingChart
            workspaceKey={  window.location.hostname === "localhost" || window.location.hostname === 'staging.ticketkore.io' || window.location.hostname === "dev.ticketkore.io" ? "97e47ca0-4cc9-4313-9139-da41ec2edca3" : "dc1599ff-9e55-41b5-aacd-dc9ae16b419a"}
            event={selectedTicketData ? `${selectedTicketData?.floor_plan?.floor_event_id}` : ''}
            region="eu"
            multiSelectEnabled = {false}
            session = {"continue"}
            showSectionContents = {'always'}
            onChartRendered = {(chart)=>{
              setHoldToken(chart.holdToken)
              localStorage.setItem('Chart_Id' , JSON.stringify(selectedTicketData?.floor_plan?.floor_event_id))
              // localStorage.setItem('holdToken' , JSON.stringify(chart.holdToken))
            }}
            onHoldSucceeded  = {(object)=>{
            
              setSelSeatLabel(selSeatLabel=> [...selSeatLabel , {label:object[0].label}]) 
              setSelSeatLabels(selSeatLabels=> [...selSeatLabels , object[0].labels])
            }}
            onReleaseHoldSucceeded = {(object)=>{
              setSelSeatLabel(selSeatLabel=>selSeatLabel.filter(e=>e.label !==object[0].label))
              setSelSeatLabels(selSeatLabels=>selSeatLabels.filter(e=>e.own !==object[0].labels.own)) 
            }}
            objectWithoutCategorySelectable= {false}
            maxSelectedObjects =  {[
              { category:  selectedTicketData?.floor_plan?.floor_cat_key, quantity:  quantity }
          ]}
            showPricing = {true}
              pricing={[
                {'category':  selectedTicketData?.floor_plan?.floor_cat_key, 'price':  selectedTicketData?.floor_plan?.floor_cat_price} 
              ]}
              priceFormatter={price=> new Intl.NumberFormat('en-IN', { style: 'currency', currency: `${selectedTicketData.currency}` }).format(price) }
              onHoldTokenExpired ={ (obj)=>{
             
                setSelSeatLabel([])
              }}
              onChartRerenderingStarted = {(obj)=>{
                
              }}
              showLegend={true}
              showSectionPricingOverlay={true}
              objectLabel = {object => object.labels.own}
              onSessionInitialized = {(obj)=>{
                localStorage.setItem('holdToken' , JSON.stringify(obj.token))
              }}
          />
          </div>
:<></>}
          <Grid item xs={12} sm={12} md={12} lg={12} className="seatBtnProced">
      
     
            <Button className={ selSeatLabel.length < quantity ?"prcdBtnSeatIODisable" :"prcdBtnSeatIO" } disabled = {selSeatLabel.length < quantity ? true: false} onClick={OrderPreviewSeatIo} >Proceed</Button>
          </Grid>
        </Grid>
      </Box>

    
    </div>
  );
};

export default FloorPlan;
