import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { Box } from "@mui/material";
import { Typography } from "@mui/material";
import "./style.css";
import { useNavigate } from "react-router-dom";
import http from "../../Services/http";
import { ManagePageReloadState } from "../../Services/ManagePageReloadState";
import ClipLoader from "react-spinners/ClipLoader";
import {useTranslation} from 'react-i18next'
import {getLanguageSource} from '../../Services/translateString'
const EventCategoriesComponent = () => {
  const {t} = useTranslation("common")
  const navigate = useNavigate();

  const [AlleventsCategories, setAlleventsCategories] = useState([]);
  const [loader, setloader] = useState(false);



  

  //  Categories Click Function
  const Categories = (ele) => {
    navigate(
      `/ViewAllSubcategory/${ele.category_id}/${
        "?flag=" + JSON.parse(localStorage.getItem("autoCountry")).sortname
      }`,
      { state: {ele} }
    );
  };

  const aeventcategory = () => {
    setloader(true);
    let postData = {
      country_id: localStorage.getItem("autoCountry")
        ? JSON.parse(localStorage.getItem("autoCountry")).id
        : "",
      city: localStorage.getItem("SelectedCity")
        ? JSON.parse(localStorage.getItem("SelectedCity")).id
        : "",
    };
    http("event_category/categories", "POST", postData).then((res) => {
      const data = res.data.data ?? [];
     
      if (data.length > 0) {
        setloader(false);
        translateCategory(data)
      } else {
        // alert("hello");
      }
    });
  };

  const translateCategory = async (data)=>{
    const categoriesArr = []
    data.forEach((categories)=>{
      categoriesArr.push(categories.category_name)
    })
   const translateCat =  await getLanguageSource(categoriesArr)
   translateCat.forEach((transCat,idx)=>{
    data[idx].category_name = transCat.translatedText
   })
    setAlleventsCategories(data);
  }

  useEffect(() => {
    aeventcategory();
    ManagePageReloadState.getPageReloadState().subscribe((res) => {
      if (res.state) {
        aeventcategory();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

useEffect(()=>{
  AlleventsCategories.length > 0 &&  translateCategory(AlleventsCategories)
},[localStorage.getItem('lang')])

  return (
    <Box className="mainContainer">
      <Typography className="layoutTitle">{t('Event_Categories')}</Typography>
      <div className="hLine"></div>

      {loader ? (
        <div style={{ height: "400px" }}>
          <div className="loader ">
            <ClipLoader />
          </div>
        </div>
      ) : (
        <Grid container spacing={3} className="ECgridPaddingBtm">
          {AlleventsCategories.map((data, index) => (
            <Grid item xs={12} sm={6} md={4} lg={2.4}>
              <Box className="eventCatBoxPR" onClick={() => Categories(data)}   sx={{cursor:"pointer"}}>
                <img
                  src={
                    data.category_image
                      ? data.category_image
                      : "assets/images/event_sport.png"
                  }
                  alt="event_sport"
                  className="cmnWidthImg"
                />
                <div className="eventCatDivPA">
                  <Typography className="E_sportsTypoC">
                  {data.category_name}
                  </Typography>
                  <Typography className="Event_TypoC">
                    ( {data.total_events_count}+ {t("Event")}){" "}
                  </Typography>
                </div>
              </Box>
            </Grid>
          ))}

          {/* <Grid item xs={12} sm={6} md={4} lg={2.4}>
          <Box className='eventCatBoxPR'>
            <img src='assets/images/event_tickets.png' alt='event_tickets' className='cmnWidthImg' />
            <div className='eventCatDivPA'>
              <Typography className='E_sportsTypoC'>Free Ticket</Typography>
              <Typography className='Event_TypoC'>(10+ Event)</Typography>
            </div>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={2.4}>
          <Box className='eventCatBoxPR'>
            <img src='assets/images/event_webinar.png' alt='event_webinar' className='cmnWidthImg' />
            <div className='eventCatDivPA'>
              <Typography className='E_sportsTypoC'>Live Streaming And Webinar
              </Typography>
              <Typography className='Event_TypoC'>(10+ Event)</Typography>
            </div>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={2.4}>
          <Box className='eventCatBoxPR'>
            <img src='assets/images/event_worship.png' alt='event_worship' className='cmnWidthImg' />
            <div className='eventCatDivPA'>
              <Typography className='E_sportsTypoC'>House of Worship
              </Typography>
              <Typography className='Event_TypoC'>(10+ Event)</Typography>
            </div>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={2.4}>
          <Box className='eventCatBoxPR'>
            <img src='assets/images/event_celebrations.png' alt='event_celebrations' className='cmnWidthImg' />
            <div className='eventCatDivPA'>
              <Typography className='E_sportsTypoC'>Celebrations</Typography>
              <Typography className='Event_TypoC'>(10+ Event)</Typography>
            </div>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={2.4}>
          <Box className='eventCatBoxPR'>
            <img src='assets/images/event_musics.png' alt='event_musics' className='cmnWidthImg' />
            <div className='eventCatDivPA'>
              <Typography className='E_sportsTypoC'>Musical Concerts</Typography>
              <Typography className='Event_TypoC'>(10+ Event)</Typography>
            </div>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={2.4}>
          <Box className='eventCatBoxPR'>
            <img src='assets/images/event_exhibitions.png' alt='event_exhibitions' className='cmnWidthImg' />
            <div className='eventCatDivPA'>
              <Typography className='E_sportsTypoC'>Exhibition</Typography>
              <Typography className='Event_TypoC'>(10+ Event)</Typography>
            </div>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={2.4}>
          <Box className='eventCatBoxPR'>
            <img src='assets/images/event_corporate.png' alt='event_corporate' className='cmnWidthImg' />
            <div className='eventCatDivPA'>
              <Typography className='E_sportsTypoC'>Corporate</Typography>
              <Typography className='Event_TypoC'>(10+ Event)</Typography>
            </div>
          </Box>
        </Grid> */}
        </Grid>
      )}
    </Box>
  );
};

export default EventCategoriesComponent;
