// import React from "react";
import { Grid, Paper, styled } from "@mui/material";
import { Box } from "@mui/system";
import OfferCard from "../OfferCard";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// import ClipLoader from "react-spinners/ClipLoader";

// import Backdrop from "@mui/material/Backdrop";
// import CircularProgress from "@mui/material/CircularProgress";
// import { StoreContext } from "../../../src/useContext/walletContext";
import http from "../../Services/http";
import { ManagePageReloadState } from "../../Services/ManagePageReloadState";

const Item = styled(Paper)(({ theme }) => ({
  color: theme.palette.text.secondary,
  background: "transparent",
}));

const Offer = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [offersevent, setOffersevent] = useState([]);

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();

  const navigate = useNavigate();
  // const context = useContext(StoreContext);

  // Autoc
  // let Autocountry = localStorage.getItem("autoCountry")
  //   ? JSON.parse(localStorage.getItem("autoCountry"))
  //   : {};
  // const localstoragecountryid = Autocountry.id;

  const handleoffers = (e) => {
    // navigate("/Offers/" + "?flag=" + query.get("flag") ? query.get("flag") : Autocountry.sortname );
    navigate(`/Offers/?flag=${query.get("flag") ? query.get("flag") : JSON.parse(localStorage.getItem("autoCountry")).sortname}`);
  };

  // Handleeventoffer events data :
  const Handleeventoffer = () => {
    setIsLoading(true);
    let postData = {
        country_id: localStorage.getItem("autoCountry") ? JSON.parse(localStorage.getItem("autoCountry")).id : '' ,
        page: "1",
    };
    http("events/offers", "POST", postData)
      .then((res) => {
        const data = res.data.data ?? [];
        setIsLoading(false);
        setOffersevent(data);
      })
      .catch((err) => {
      });
  };

  useEffect(() => {
    Handleeventoffer();

    ManagePageReloadState.getPageReloadState().subscribe(res =>{
      if(res.state){
        Handleeventoffer() 
      }
       
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
  {/* {isLoading ? (
            <div style={{ height: "400px" }}>
              <div className="loader ">
                <ClipLoader />
               
              </div>
            </div>
          ) : (
            <>

 */}



{offersevent.length>0 ?  

      <Box sx={{ backgroundColor: "#060251", padding: "32px 0px" }}>
        <Box className="mainContainer">
          <Grid container spacing={2} sx={{ paddingBottom: "35px" }}>
            <Grid item md={6} xs={6} lg={6}>
              <Item
                sx={{
                  textAlign: "left",
                  fontWeight: "700",
                  fontFamily: "Lato",
                  fontSize: "22px",
                  color: "#fff",
                }}
                className="boxShadowNone"
              >
                Offers
              </Item>
            </Grid>

            <Grid item md={6} xs={6} lg={6}>
              <Item Item sx={{ textAlign: "right" }} className="boxShadowNone">
                <span
                  onClick={handleoffers}
                 style={{
                    border: "1px solid #fff",
                    display: "inline-flex",
                    color: "#0CA8F6",
                    borderRadius: "6px",
                    alignItems: "center",
                    width: "92px",
                    justifyContent: "flex-end",
                    height: "32px",
                    alignItems: "center",
                    cursor:'pointer',
                  }}
                >
                  <button
                    style={{
                      cursor: "pointer",
                      border: "none",
                      background: "transparent",
                      color: "white",
                      fontWeight: "400",
                      fontSize: "14px",
                    }}
                    className='mobViewAllBtn'
                   
                  >
                    View All{" "}
                  </button>
                  <KeyboardArrowRightOutlinedIcon style={{color:'white'}} />
                </span>
              </Item>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <OfferCard   offersevent={offersevent} />
          </Grid>
        </Box>
      </Box>

      : <></>            }
      </>
          )}
//     </>
//   );
// };

export default Offer;
