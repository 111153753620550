import { styled } from "@mui/material/styles";
import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import "./Style.css";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(0),
  color: theme.palette.text.secondary,
}));
const OfferCard = (offersevent) => {
  const dateFormated = (date) => {
    let dateNew = new Date(date);
    let day = dateNew.toLocaleString("en-us", { weekday: "short" });
    let newdate = new Date(date).getDate();
    let month = dateNew.toLocaleString("default", { month: "short" });
    return day + "," + newdate + " " + month;
  };

  return (
    <>
      {offersevent.offersevent
        .filter((offersData, index) => index < 4)
        .map((offersData, index) => (
          <Grid item md={2.4} xs={6} lg={2.4} key={"offerCard"+index}>
            <Item
              sx={{ textAlign: "left", backgroundColor: "transparent" }}
              className="boxShadowNone"
            >
              <Card
                className="boxShadowNone"
                sx={{ backgroundColor: "transparent" }}
              >
                <div className="CardDiv">
                  <CardMedia
                    component="img"
                    image={
                      offersData.coupon_image
                        ? offersData.coupon_image
                        : "assets/images/offer.png"
                    }
                    alt="Paella dish"
                  />
                </div>
                <CardContent sx={{ padding: "20px 0px 0px 0px" }}>
                  <Typography
                    sx={{
                      fontFamily: "Lato",
                      color: "#FFFFFF",
                      fontWeight: "500",
                      fontSize: "16px",
                    }}
                  >
                    {offersData.event_name}
                  </Typography>
                </CardContent>
                <Typography>
                  <span
                    style={{
                      color: "#FFFFFF",
                      fontWeight: "400",
                      fontSize: "14px",
                    }}
                  >
                    Valid till :{" "}
                  </span>
                  <span
                    style={{
                      color: "#0CA8F6",
                      fontWeight: "400",
                      fontSize: "14px",
                    }}
                  >
                    {/* 30 jun 2022 23:59 */}
                    {dateFormated(offersData.start_date)}
                  </span>
                </Typography>
              </Card>
            </Item>
          </Grid>
        ))}
    </>
  );
};

export default OfferCard;
