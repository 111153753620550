import React from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';
import {useTranslation} from 'react-i18next'
import "./style.css";
import { Typography } from '@mui/material';

const HardwareComponent = () => {
  const {t} = useTranslation("common")
  return (
    <Box sx={{ flexGrow: 1 }}>
      <div style={{ paddingBottom: '50px' }}>
        <Box className='mainContainer'>
          <Typography className='hardheddingTypo' style={{ textAlign: 'center' }}><span className='underlineStyle'> {t("Hardware_built_for_exceptional_experiences")}</span></Typography>
          <Grid container spacing={2} className='turntlistRowGrid'>
            <Grid item xs={12} sm={6} md={6} lg={6} className='turntlistGrid'>
              <img src='assets/images/turntlist.png' alt='turntlist' />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} sx={{ md: 'center' }}>
              <div className='mainDivWhoWeAre'>
                <Typography className='HardTurnCH'>{t("Turnstiles")}
                </Typography>
                <div className='whoWeAreHLine'></div>
                <Typography className='HardTypo'>{t("The_Ticketkore_Platform_can_be_integrated_with_a_multi-lane_Tripod_Turnstile_passage_for_easy_scanning_and_managing_the_traffic_flow_at_entry_points_equipped_with_QR_and_NFC_readers")}.
                </Typography>
                <div className='commonHardDiv'>
                  <Link to="/contactus" className='linkDecoration'>
                    <span style={{ borderBottom: '2px solid #0CA8F6' }}>{t("Enquire_now")}</span>
                    <img src='assets/images/hardArrow.png' alt='Arrow' style={{marginLeft: '10px'}}/>
                  </Link>
                </div>

              </div>
            </Grid>
          </Grid>
        </Box>
      </div>

      <div style={{ background: '#EAF8FF' }}>
        <Box className='mainContainer'>
          <Grid container spacing={2} sx={{ pt: 3, pb: 4 }} className='HardmobColReverse'>

            <Grid item xs={12} sm={6} md={6} lg={6} className='handledGridColF'>
              <div className='mainDivWhoWeAre'>
                <Typography className='HardTurnCH'>{t("Handheld")}
                </Typography>
                <div className='whoWeAreHLine'></div>
                <Typography className='HardTypo'>{t("Keep_your_audience_lane_moving_with_our_integrated_smart_handheld_ticket_scanners,_build_for_your_event_experience")}.
                </Typography>
                <div className='commonHardDiv'>
                  <Link to="/contactus" className='linkDecoration'>
                    <span style={{ borderBottom: '2px solid #0CA8F6' }}>{t("Enquire_now")}</span>
                    <img src='assets/images/hardArrow.png' alt='Arrow' style={{marginLeft: '10px'}}/>
                  </Link>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} sx={{ textAlign: 'center' }}>
              <img src='assets/images/handeled.png' alt='handeled' />
            </Grid>
          </Grid>
        </Box>
      </div>

      <div style={{}}>
        <Box className='mainContainer'>
          <Grid container spacing={2} className='smartPosRowGrid'>
            <Grid item xs={12} sm={6} md={6} lg={6} sx={{ textAlign: 'center' }}>
              <img src='assets/images/smartPos.png' alt='smartPos' />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} sx={{ md: 'center' }}>
              <div className='mainDivWhoWeAre'>
                <Typography className='HardTurnCH'>{t("Smart_POS")}
                </Typography>
                <div className='whoWeAreHLine'></div>
                <Typography className='HardTypo'>{t("Accept_payments_at_the_door_without_any_hustle_and_thermal_tickets_are_printed_only_once_payment_has_been_received_by_the_cashier_on_dual_screen_POS_integrated_to_the_ticketing_platform_engine")}.
                </Typography>
                <div className='commonHardDiv'>
                  <Link to="/contactus" className='linkDecoration'>
                    <span style={{ borderBottom: '2px solid #0CA8F6' }}>{t("Enquire_now")}</span>
                    <img src='assets/images/hardArrow.png' alt='Arrow' style={{marginLeft: '10px'}}/>
                  </Link>
                </div>
              </div>
            </Grid>

          </Grid>
        </Box>
      </div>
    </Box>

  )
}

export default HardwareComponent