import React from 'react'
import { Box, Card } from '@mui/material'
import { Grid } from '@mui/material'
import { Typography } from '@mui/material'


import './style.css'

const PopAct = () => {
    return (
        <Box className='mainContainer' sx={{paddingBottom: '30px'}}>
            <Grid container spacing={2}>
                
                <Grid item xs={12} sm={12} md={12}>
                    <Typography className='policyIntroTypo policyIntroTypoMedia'>Figure Out What Personal Information You Process And Why</Typography>
                    <Typography className='policyContent'>The POPI Act covers personal information, which means any information that relates to a specific person. The law notes that this isn't limited to a “natural person” (that is, a human being) but also a “juristic person”, which means an independent legal entity such as a company</Typography>

                    <Typography className='policyIntroTypo policyIntroTypoMedia'>Get Rid Of What You Do Not Need</Typography>
                    <Typography className='policyContent'>Under POPI, a business cannot keep a record of personal information once the reason for which it was collected no longer exists, unless required by law. For example, unless required by law, a business should not keep personal information of any former supplier when the relationship has ended. Businesses should therefore check whether they are holding onto any old records of personal information that they no longer need and dispose of them in a secure manner.</Typography>

                    <Typography className='policyIntroTypo policyIntroTypoMedia'>Look At Security</Typography>
                    <Typography className='policyContent'>Terms give you the opportunity to make the law work for you. They set the rules for how users may interact with your service, product, or site and protect you from potential liabilities and service abuses. One common scenario in which terms are absolutely vital is in the case of e-commerce — where customers must be made aware of the business owner's rules relating to return, withdrawal or cancellation policies.</Typography>

                    <Typography className='policyIntroTypo policyIntroTypoMedia'>Get Consent Before Sending Marketing Material</Typography>
                    <Typography className='policyContent'>Terms give you the opportunity to make the law work for you. They set the rules for how users may interact with your service, product, or site and protect you from potential liabilities and service abuses. One common scenario in which terms are absolutely vital is in the case of e-commerce — where customers must be made aware of the business owner's rules relating to return, withdrawal or cancellation policies.</Typography>

                    <Typography className='policyIntroTypo policyIntroTypoMedia'>Go For The Easy Wins</Typography>
                    <Typography className='policyContent'>Terms give you the opportunity to make the law work for you. They set the rules for how users may interact with your service, product, or site and protect you from potential liabilities and service abuses. One common scenario in which terms are absolutely vital is in the case of e-commerce — where customers must be made aware of the business owner's rules relating to return, withdrawal or cancellation policies.</Typography>

                    <Typography className='policyIntroTypo policyIntroTypoMedia'>THE PURPOSE OF THIS ACT</Typography>
                    <Typography className='policyContent'>According to POPI Act, the purpose of this act is to — give effect to the constitutional right to privacy, by safeguarding personal information when processed by a responsible party, subject to justifiable limitations that are aimed at — balancing the right to privacy against other rights, particularly the right of access to information; and protecting important interests, including the free flow of information within the Republic and across international borders.</Typography>
                    <Typography className='policyInnerContent'>Regulate the manner in which personal information may be processed, by establishing conditions, in harmony with international standards, that prescribe the minimum threshold requirements for the lawful processing of personal information. provide persons with rights and remedies to protect their personal information from processing that is not in accordance with this act; and</Typography>
                </Grid>
            </Grid>
        </Box>
    )
}

export default PopAct