/* eslint-disable jsx-a11y/anchor-is-valid */
import { Box } from "@mui/system";
import EventCard from "../EventCard";
import { Grid, Paper, styled } from "@mui/material";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import React, { useEffect, useState } from "react";
import http from "../../Services/http";
import { useNavigate } from "react-router-dom"; 
import { ManagePageReloadState } from "../../Services/ManagePageReloadState";
import {useTranslation} from "react-i18next";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(0),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Celebration = (props) => {
  const {t} = useTranslation("common");
  const [getchildata, setGetchildata] = useState(false) 
  const navigate = useNavigate(); 
  const [eventsbyid, seteventsbyid] = useState([]); 
// First parent function data pass 
  const getdatafromchild = (data) => { 
    setGetchildata(data)
    data ? HomepageComingevent() : <></>
  }
    
  const HomepageComingevent = () => {
    const HomepageeventsbyidData = {
      country_id:localStorage.getItem("autoCountry") ? JSON.parse(localStorage.getItem("autoCountry")).id :'',
      city:localStorage.getItem('SelectedCity') ? JSON.parse(localStorage.getItem('SelectedCity')).id : "",
      keyword: "",
    };
    http("events/event_by_city_id", "POST", HomepageeventsbyidData).then(
      (res) => { 
        
        const data = res.data.data ?? [];
        console.log(data)
        
        seteventsbyid(data ?? []);
      }
    );
  };

  const handleonviewallClick = (ele) => { 
    navigate(
      `/ViewAllSubcategory/${ele.categoryId}/${ "?flag="+JSON.parse(localStorage.getItem("autoCountry")).sortname }`,
      { state: { ele } }
    );
  };
 
  useEffect(() => {
    HomepageComingevent(); 
    ManagePageReloadState.getPageReloadState().subscribe(res =>{
      if(res.state){
        HomepageComingevent() 
      }
      
    }) 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="container-xxl py-5">
      {eventsbyid.length > 0 && (
        <div className="mainContainerNew">
          {eventsbyid.map((data, index) => (
            <>
            <div className="section-heading mb-5 mx-auto wow fadeInUp" data-wow-delay="0.1s">
                    <div className="row justify-content-between align-items-center ">
                        <div className="col-lg-8">
                            <p className="head-sub fw-semi-bold text-uppercase mb-1">{t('discover_the_fun!')}</p>
                            <h2 className="fw-semi-bold">{t(data?.category?.split(' ').join('_'))}</h2>
                        </div>
                        <div className="col-lg-2 d-flex align-items-center justify-content-end gap-4">
                        {data.events?.length > 5 ?<a className="btn btn-light py-2 px-4" onClick={() => handleonviewallClick(data)}>{t('view_all')}</a> : <></>}
                        </div>
                        <div className={`col-lg-1 d-flex align-items-center justify-content-end gap-4 heading-btns ${data.category.split(' ').join('_')}`}>
                        
                        </div>
                    </div>
                </div>
             
              <EventCard eventData={data.events} category={data.category} getdatafromchild={getdatafromchild}  HomepageComingevent={HomepageComingevent} />
            </>
          ))}
        </div>
      )}
      </div>
  );
};

export default Celebration;
