import React from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import "./style.css";
import { Typography } from '@mui/material';
const index = ({t}) => {
    return (
        <Box sx={{ flexGrow: 1 }}>
            <div>
                <Box className='mainContainer'>
                    <Grid container spacing={2} sx={{ pt: 3, pb: 4 }}>
                        <Grid item xs={12} sm={6} md={6} lg={6}  sx={{ md: 'center' }} className="tab_dFlex">
                            <div className='mainDivWhoWeAre'>
                                <Typography className='whoWeAreTypo' sx={{ paddingTop: '68px' }}>{t("Company")}
                                </Typography>
                                <div className='whoWeAreHLine'></div>
                                <Typography className='whoWeCTicket' sx={{color: '#686868'}}> <span style={{ color: '#0CA8F6' }}> TICKETKORE </span> {t("is a smart ticketing and reservation platform for the Live Experience industry")}.</Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} sx={{ textAlign: 'center' }}>
                            <img src='assets/images/whoweImg.png' alt='whoweImg' />
                        </Grid>
                    </Grid>
                </Box>
            </div>

            <div className='missionDivBg'>
                <Box className='mainContainer'>
                    <Grid container spacing={2} sx={{ pt: 3, pb: 4 }} className='mobColReverse'>
                        <Grid item xs={12} sm={6} md={6} lg={6} sx={{ textAlign: 'center' }}>
                            <img src='assets/images/mission.png' alt='missinImg' />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} sx={{}} className="tab_dFlex">
                            <div className='mainDivWhoWeAre'>
                                <Typography className='whoWeAreTypo' sx={{ paddingTop: '44px' }}>{t("Mission")}</Typography>
                                <div className='whoWeAreHLine'></div>
                                <Typography className='whoWeCTicket' sx={{ paddingRight: '48px' }}>{t("We aim to unlock creativity for creators and built fans experience")}.</Typography>
                            </div>
                        </Grid>
                    </Grid>
                </Box>
            </div>
            <Box className='mainContainer'>
                <Grid container spacing={2} className='whyWeRowGrid'>
                    <Grid item xs={12} sm={12} md={12} lg={12} className='whyChosseColGrid'>
                        <span className='AbPaymentTypWhy'>{t("Why Choose Ticketkore")}</span>
                    </Grid>
                    <Grid item xs={12} sm={4} md={3.5} lg={3.5} className='aboutImgPABsltGrid'>
                        <img src='assets/images/paymentAb.png' alt='Payment' className='aboutImgPABslt' />
                        <div className='aboutShadowDiv'>
                            <Typography className='AbPaymentTyp'>{t("Payment")}</Typography>
                            <Typography className='AbPaymentBTypo'>{t("Transactions are cashless, no need for patrons to use cash")} .</Typography>
                        </div>
                        <div className='GrediantLineaer'></div>
                    </Grid>

                    <Grid item xs={12} sm={4} md={3.5} lg={3.5} className='aboutImgPABsltGrid'>
                        <img src='assets/images/AbTrust.png' alt='Payment' className='aboutImgPABslt' />
                        <div className='aboutShadowDiv'>
                            <Typography className='AbPaymentTyp'>{t("Trust_and_Security")}</Typography>
                            <Typography className='AbPaymentBTypo'>{t("We deploy the highest 256 encryption and security technology to ensure data safety and access")}. </Typography>
                        </div>
                        <div className='GrediantLineaer'></div>
                    </Grid>

                    <Grid item xs={12} sm={4} md={3.5} lg={3.5} className='aboutImgPABsltGrid'>
                        <img src='assets/images/AbPay.png' alt='Payment' className='aboutImgPABslt' />
                        <div className='aboutShadowDiv'>
                            <Typography className='AbPaymentTyp'>{t("Pay_Out")}</Typography>
                            <Typography className='AbPaymentBTypo'> {t("Fastest pay out with 24 hours after the event or next business day")}. </Typography>
                        </div>
                        <div className='GrediantLineaer'></div>
                    </Grid>

                </Grid>

                <Grid container spacing={2} className='aboutImgPABsltGridRow'>
                <Grid item xs={12} sm={6} md={2} lg={2} className='aboutImgPABsltGrid displaNoneRes'></Grid>
                    <Grid item xs={12} sm={6} md={3.5} lg={3.5} className='aboutImgPABsltGrid'>
                        <img src='assets/images/AbAdab.png' alt='Payment' className='aboutImgPABslt' />
                        <div className='aboutShadowDiv'>
                            <Typography className='AbPaymentTyp'>{t("Flexibility and Adaptability")}</Typography>
                            <Typography className='AbPaymentBTypo'> {t("We deploy optimal teams to support your event with aggregate experience")}. </Typography>
                        </div>
                        <div className='GrediantLineaer'></div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={1} lg={1} className='aboutImgPABsltGrid displaNoneRes'></Grid>
                    <Grid item xs={12} sm={6} md={3.5} lg={3.5} className='aboutImgPABsltGrid'>
                        <img src='assets/images/singlePlt.png' alt='Platform' className='aboutImgPABslt' />
                        <div className='aboutShadowDiv'>
                            <Typography className='AbPaymentTyp'>{t("Single_Platform")}</Typography>
                            <Typography className='AbPaymentBTypo'>{t("Everything from single platform to simplify and build experience")}.</Typography>
                        </div>
                        <div className='GrediantLineaer'></div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={2} lg={2} className='aboutImgPABsltGrid displaNoneRes'></Grid>
                </Grid>
            </Box>

            <Box className='trustedByDiv'>
                <Box className='mainContainer'>
                    <Grid container spacing={3} sx={{ pt: 1, pb: 5, justifyContent: 'space-between' }} className=''>
                        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ textAlign: 'center', position: 'relative', paddingBottom: '10px' }}>
                            <span className='AbPaymentTypWhy'>{t("Trusted_By")}</span>
                        </Grid>

                        <Grid item xs={12} sm={6} md={3} lg={3}>
                            <img src='assets/images/allBlack.png' alt='allBlack' className='trustedByDivImgG' />
                        </Grid>

                        <Grid item xs={12} sm={6} md={3} lg={3}>
                            <img src='assets/images/BGt.png' alt='BGt' className='trustedByDivImgG' />
                        </Grid>

                        <Grid item xs={12} sm={6} md={3} lg={3}>
                            <img src='assets/images/fairki.png' alt='fairki' className='trustedByDivImgG' />
                        </Grid>

                        <Grid item xs={12} sm={6} md={3} lg={3}>
                            <img src='assets/images/spring.png' alt='spring' className='trustedByDivImgG' />
                        </Grid>
                    </Grid>
                </Box>
            </Box>


        </Box>

    )
}

export default index