// import * as React from 'react';
// import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
// import MobileStepper from "@mui/material/MobileStepper";
// import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
// import SwipeableViews from "react-swipeable-views";
// import { autoPlay } from "react-swipeable-views-utils";
import React, { useEffect, useState } from "react";
import http from "../../Services/http";
import { useNavigate } from "react-router-dom";
// import Typography from "@mui/material/Typography";
import { ManagePageReloadState } from "../../Services/ManagePageReloadState";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { ColorRing } from "react-loader-spinner";
import SearchBar from "../Navbar/SearchBar";
import "./style.css";
// import CustomBanner from "../CustomBanner";
import "slick-carousel/slick/slick.css";

import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { styled, alpha } from "@mui/material/styles";
import ReactPlayer from 'react-player'
import {useTranslation} from "react-i18next";
// const AutoPlaySwipeableViews = autoPlay(SwipeableViews);


const Search = styled("div")(({ theme }) => ({
  position: "relative",
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
    border: "0px !important",
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  borderTopRightRadius: "6px",
  borderBottomRightRadius: "6px",
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "100%",
  },
}));

const Sliders = (props) => {
  const {t} = useTranslation("common");
  const [open, setOpen] = React.useState(false);
  const [combineEvents, setCombineEvents] = useState(null);
  const [isloading, setisloading] = useState(false);
  const [isImageUrl, setIsImageUrl] = useState(true)

  function SampleNextArrow(propsNarroe) {
    const {  style, onClick } = propsNarroe;
    return (
      <button style={{...style, height:50, alignSelf:'center'}} onClick={onClick} className="carousel-control-next" type="button" data-bs-target="#header-carousel" data-bs-slide="next">
      <span className="carousel-control-next-icon"  aria-hidden="true"></span>
      <span className="visually-hidden" style={{color: '#0000'}}>Next</span>
  </button>
    );
  }

  function SamplePrevArrow(propsPrevArrow) {
    const {   style, onClick } = propsPrevArrow;
    return (
      <button style={{...style, height:50, alignSelf:'center'}} onClick={onClick} className="carousel-control-prev" type="button" data-bs-target="#header-carousel" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
    );
  }
  
                       
  const settings = {
    className: "center",
    centerMode: true, 
    infinite: true,
    centerPadding: "0",
    slidesToShow: 1,
    dots: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    nextArrow: <SampleNextArrow  />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          centerMode: false,
          adaptiveHeight:true,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          adaptiveHeight:true,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
 
          centerMode: false,
          adaptiveHeight:true,
          centerPadding: "0px",
        }
      }
    ]
  };

  useEffect(() => {
    Promotional();
    ManagePageReloadState.getPageReloadState().subscribe((res) => {
      if (res.state) {
        Promotional();
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Promotional = () => {
    setisloading(true);
    setOpen(true);
    let postData = {
      country_id: localStorage.getItem("autoCountry")
        ? JSON.parse(localStorage.getItem("autoCountry")).id
        : "",
      city: localStorage.getItem("SelectedCity")
        ? JSON.parse(localStorage.getItem("SelectedCity")).id
        : "",
    };
    http("events/promotional", "POST", postData).then((res) => {
      setisloading(false);
      setOpen(false);
      const data = res.data.data ?? [];
      const arr1 = data.promoted_talent_shows ?? [];
      arr1.map((res) => {
        return (res.ts = "ts");
      });
      const arr2 = data.promotional_events ?? [];
      arr2.map((res) => {
        return (res.ts = "");
      });
      const arr3 = data.promotional_banners ?? [];
      arr3.map((res)=>
        (res.ts = "promo")
      )
      const newarr = [...arr1, ...arr2 , ...arr3];
      setCombineEvents(newarr ?? []);
    });
  };

 

  // const theme = useTheme();
  // const [activeStep, setActiveStep] = React.useState(0);
  // const maxSteps = combineEvents.length;

  // const handleNext = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
  // };

  // const handleBack = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep - 1);
  // };

  // const handleStepChange = (step) => {
  //   setActiveStep(step);
  // };
  const navigate = useNavigate();

  const handleeventdetails = (e, id, ts = "", event_name) => {
    let modifyEventName = event_name.replaceAll(/[^a-zA-Z0-9 ]/g, "");
    if (ts === "") {
      navigate(
        `/events/${modifyEventName.replaceAll(" ", "-")}/` +
          id +
          "?flag=" +
          JSON.parse(localStorage.getItem("autoCountry")).sortname
      );
    } else if(ts === "promo"){
      navigate(`/promotional-details/${id}`)
    } else {
      navigate(
        `/events/${modifyEventName.replaceAll(" ", "-")}/` +
          id +
          "?ts=ts&flag=" +
          JSON.parse(localStorage.getItem("autoCountry")).sortname
      );
    }
  };

  return (
    <div >
      {isloading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ColorRing
            visible={true}
            height="100"
            width="100"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={["#b8c480", "#B2A3B5", "#F4442E", "#51E5FF", "#429EA6"]}
          />{" "}
        </Box>
      ) : (
        <>
          {combineEvents && combineEvents?.length === 0 ? (
            <>
            {/* <!-- Carousel Start --> */}
            <div className="container-fluid p-0 wow fadeIn" key={'NoImageBanner'} data-wow-delay="0.1s">
                <div id="header-carousel" className="carousel carousel-dark slide carousel-fade" data-bs-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img className="w-100" src="img/carousel-1.jpg" alt="carousel-1" style={{height:400}}/>
                            <div className="carousel-caption">
                                <div className="container">
                                    <div className="row justify-content-start">
                                        <div className="col-lg-8">
                                            &nbsp;
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Carousel End --> */}
    
    
            {/* <!-- Carousel Search Start --> */}
            <div className="mt-n5 mb-5 position-relative carousel-search-bar" style={{maxWidth:'66%',margin:'auto'}}>
            <div className="container">
                <div className="cta-block-2 p-3 mx-5 rounded border-1 bg-white shadow">
                    <div className="row justify-content-between align-items-center ">
                        <div className="col-lg-12 text-center">
                        <FormControl sx={{ m: 1 }} className="TFormControl" style={{margin:0, marginLeft: 8}}>
      <Box
              sx={{
                marginLeft: "auto",
                cursor: "pointer",
              }}
              className="cityDropDownHeader"
            > 
              {props.selectedCountryFlag ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                  onClick={props.handleToggleSearchBar}
                >
                  <img
                    src={`https://flagcdn.com/32x24/${props.selectedCountryFlag}.png`}
                    alt="South Africa"
                  />
                  <img
                    src="assets/images/output-onlinepngtools.png"
                    alt="flag"
                    style={{ margin: "0px 25px 0px 10px", color: "#0000" }}
                  />
                </Box>
              ) : (
                ""
              )}

              {props.toggleSearchBar ? (
                <SearchBar
                  listData={props.countryList}
                  selectedData={()=>props.handleSelectedCountry}
                />
              ) : (
                ""
              )}
            </Box>

            <Autocomplete
              disablePortal
              id="controllable-states-demo"
              options={props.top100Films}
              value={props.value ? props.value : ""}
              onChange={(event, value) => {
                props.setValue(value ? value.city_name : event.target.value);
                 
                value
                  ? localStorage.setItem("SelectedCity", JSON.stringify(value))
                  : localStorage.removeItem("SelectedCity");
                props.getAllCityByCountryId();
                ManagePageReloadState.setPageReloadState(props.setState);
                navigate(
                  `/home${
                    value ? "/" + value.city_name.split(" ").join("") : ""
                  }`
                );
              }}
              sx={{
                display: "table-cell",
                background: "#DCDCDC",
                border: "0px !important",
                paddingTopLeftRadias: "6px",
              }}
              freeSolo
              className="dropDownCountry"
              getOptionLabel={(option) => {
                return option ? option : "";
              }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option.city_name}
                </Box>
              )}
              filterOptions={(options) => options}
              renderInput={(params) => (
                <TextField
                  onKeyUp={(e) =>
                    props.getAllCityByCountryId("CHcek", e.target.value)
                  }
                  {...params}
                  placeholder={t("all_city")}
                  className="customSearchField"
                  sx={{ verticalAlign: "baseline" }}
                />
              )}
            />
            <Search
              className="searchBox"
              style={{
                marginLeft: "0",
                width: "100%",
                padding: "8.9px 0px 8.5px 0px",
                marginRight: "0px",
              }}
            >
 
              <Autocomplete
                // popupIcon={<SearchIcon />}
                disablePortal
                id="box-demo"
                options={props.popularSearch ? props.popularSearch : []}
                onChange={(el) => props.goForSearch(el)}
                renderInput={(params) => (
                  <TextField
                  {...params}
                    onFocus={props.popularSearchFunc}
                    onKeyUp={(e) => props.searchGlobalEvent("", e.target.value)}
                    onInput={(e) => {
                      e.target.value = e.target.value.toString().slice(0, 40);
                    }}
                    value={props.searchkey}
                    className="customTextField"
                    placeholder={t('Search_for_movies_events_plays_sports_and_activities')}
                  />
                )}
              />
              <img
                src="assets/images/search-icon.png"
                className="searchIcon"
                alt="search"
              />
            </Search>
          </FormControl>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            {/* <!-- Carousel Search End --> */}
        </>
          ) : (
            <>

              <Slider {...settings}>
                {combineEvents && combineEvents.length > 0
                  ? combineEvents.map((step, index) => ( 
                    <div  key={`promotionBanner_Slider_${index}`}className="container-fluid p-0 wow fadeIn" data-wow-delay="0.1s">
                    <div id="header-carousel" className="carousel carousel-dark slide carousel-fade" data-bs-ride="carousel">
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                              
                                {step.event_web_banner.match(/\.(jpg|jpeg|png|gif)$/i) ?<img className="w-100" 
                                src={step.event_web_banner}
                        alt={step.label}
                        style={{height:400}}
                        onClick={(e) =>
                          handleeventdetails(
                            e,
                            step.id,
                            step.ts,
                            step.event_name
                          )
                          
                        }
                        onError={(e)=>setIsImageUrl(false)}
                        /> : 
                        <div className='player-wrapper'>
                          <ReactPlayer 
                          className='react-player' 
                          autoplay={true} 
                          controls={true} width='100%'
                        height='100%' url={step.event_web_banner} /></div>
                        }
                                <div className="carousel-caption">
                                    <div className="container">
                                        <div className="row justify-content-start">
                                            <div className="col-lg-8">
                                                &nbsp;
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                    ))
                  : ""}
              </Slider>
              <div className="mt-n5 mb-5 position-relative carousel-search-bar carousel-search-bar-size" style={{maxWidth:'70%',margin:'auto'}}>
            <div className="container">
                <div className="cta-block-2 p-3 mx-5 rounded border-1 bg-white shadow">
                    <div className="row justify-content-between align-items-center ">
                        <div className="col-lg-12 text-center">
                        <FormControl sx={{ m: 1 }} className="TFormControl" style={{margin:0, marginLeft: 8}}>
      <Box
              sx={{
                marginLeft: "auto",
                cursor: "pointer",
              }}
              className="cityDropDownHeader"
            > 
              {props.selectedCountryFlag ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                  onClick={props.handleToggleSearchBar}
                >
                  <img
                    src={`https://flagcdn.com/32x24/${props.selectedCountryFlag}.png`}
                    alt="South Africa"
                  />
                  <img
                    src="assets/images/output-onlinepngtools.png"
                    alt="flag"
                    style={{ margin: "0px 25px 0px 10px", color: "#0000" }}
                  />
                </Box>
              ) : (
                ""
              )}

              {props.toggleSearchBar ? (
                <SearchBar
                  listData={props.countryList}
                  selectedData={()=>props.handleSelectedCountry}
                />
              ) : (
                ""
              )}
            </Box>

            <Autocomplete
              disablePortal
              id="controllable-states-demo"
              options={props.top100Films}
              value={props.value ? props.value : ""}
              onChange={(event, value) => {
                props.setValue(value ? value.city_name : event.target.value);
                 
                value
                  ? localStorage.setItem("SelectedCity", JSON.stringify(value))
                  : localStorage.removeItem("SelectedCity");
                props.getAllCityByCountryId();
                ManagePageReloadState.setPageReloadState(props.setState);
                navigate(
                  `/home${
                    value ? "/" + value.city_name.split(" ").join("") : ""
                  }`
                );
              }}
              sx={{
                display: "table-cell",
                background: "#DCDCDC",
                border: "0px !important",
                paddingTopLeftRadias: "6px",
              }}
              freeSolo
              className="dropDownCountry"
              getOptionLabel={(option) => {
                return option ? option : "";
              }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option.city_name}
                </Box>
              )}
              filterOptions={(options) => options}
              renderInput={(params) => (
                <TextField
                  onKeyUp={(e) =>
                    props.getAllCityByCountryId("CHcek", e.target.value)
                  }
                  {...params}
                  placeholder={t("all_city")}
                  className="customSearchField"
                  sx={{ verticalAlign: "baseline" }}
                />
              )}
            />
            <Search
              className="searchBox"
              style={{
                marginLeft: "0",
                width: "100%",
                padding: "8.9px 0px 8.5px 0px",
                marginRight: "0px",
              }}
            >
 
              <Autocomplete
                // popupIcon={<SearchIcon />}
                disablePortal
                id="box-demo"
                options={props.popularSearch ? props.popularSearch : []}
                onChange={(el) => props.goForSearch(el)}
                componentsProps={{ popper: { style: { zIndex: 2, position:'fixed' } } }}
                renderInput={(params) => (
                  <TextField
                  {...params}
                    onFocus={props.popularSearchFunc}
                    onKeyUp={(e) => props.searchGlobalEvent("", e.target.value)}
                    onInput={(e) => {
                      e.target.value = e.target.value.toString().slice(0, 40);
                    }}
                    value={props.searchkey}
                    className="customTextField"
                    placeholder={t("all_city")}
                  />
                )}
              />
              <img
                src="assets/images/search-icon.png"
                className="searchIcon"
                alt="search"
              />
            </Search>
          </FormControl>
                        </div>
                    </div>
                </div>
            </div>
        </div>
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={open}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Sliders;
