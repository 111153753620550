import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import {
    Alert,
  Backdrop,
  Box, 
  CircularProgress,
  Typography,
} from "@mui/material";
import { NotificationManager } from "react-notifications";
import { useLocation, useNavigate } from "react-router-dom";
import http from "../../Services/http";
const PaymentStatus = () => {
  const navigate = useNavigate();
  const [openBackdrop, setopenBackdrop] = React.useState(false);
  const [message , setMessage] = useState('')
  const [status , setStatus] = useState(2);
 
  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();

  useEffect(() => {
    if (query.get("id")) {
      paymentStatus(query.get("id"));
    }
  }, []);
  const paymentStatus = (id) => {
    setopenBackdrop(true);
    const addedData = {
      id:
        JSON.parse(localStorage.getItem("paymentMethod")) === "card"
          ? id
          : JSON.parse(localStorage.getItem("merchantTID")),
      order_id: JSON.parse(localStorage.getItem("orderID")),
    };

    http(
      JSON.parse(localStorage.getItem("paymentMethod")) === "card"
        ? "orders/peach_order_payment_status"
        : "orders/eft_order_payment_status",
      "POST",
      addedData
    )
      .then((res) => {
        if (res.data.status === 1) {
          localStorage.removeItem("label");
          localStorage.removeItem("labels");
          localStorage.removeItem("Chart_Id");
          localStorage.removeItem("holdToken");
          localStorage.removeItem("paymentMethod");
          
          setTimeout(() => {
            setopenBackdrop(false);
            setMessage(res.data.msg)
            setStatus(res.data.status)
            navigate("/MyBooking");
            NotificationManager.success(res.data.msg); 
          }, 3000);
        } else {
          setTimeout(() => {
            setopenBackdrop(false);
            setMessage(res.data.msg)
            setStatus(res.data.status)
            navigate("/MyBooking");
            NotificationManager.error(res.data.msg); 
          }, 3000);
        }
      })
      .catch((err) => {});
  };
  return (
    <div>
      <Navbar />
      <Box sx={{ textAlign: "center" }} className="AlertBoxCenter">
        <Alert severity="info" className="allertMessage" >Note: Please do not press back button or close the screen until the payment is complete</Alert>
        <Typography className="allertStatusText">Payment Status</Typography>
        <Typography className="allertStatusTextTwo">{message}</Typography>
        <Box sx={{display: 'block', marginBottom: '40px'}}>
      {/* {status === 0 ?  <img src="./assets/images/alert-image.png" alt=""/>
        :status === 1 ? <img src="./assets/images/Success.png" alt=""/> : <></>} */}
         <img src="./assets/images/Success.png" alt=""/>
        </Box>
        {/* <Button
          onClick={() => {
            navigate("/MyBooking");
          }}
          variant="contained"
          className="allertBtn"
        >
          Go to Booking Page{" "}
        </Button> */}
      </Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
        // onClick={handleCloseBackDrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Footer />
    </div>
  );
};

export default PaymentStatus;
