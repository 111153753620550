import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { Grid, Typography } from "@mui/material";
import { Paper, styled } from "@mui/material";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";

import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Rating from "@mui/material/Rating";
import "./style.css";
import http from "../../Services/http";
import { useParams, useNavigate } from "react-router-dom";
import Moment from "react-moment";
import moment from "moment";
import parse from "html-react-parser";
import Carousel, { consts } from "react-elastic-carousel";
import {useTranslation} from 'react-i18next'

const breakPoints = [
  // { width: 1, itemsToShow: 1, itemPadding: [10, 3] },
  // { width: 1200, itemsToShow: 2, itemPadding: [0] },
  { width: 1, itemsToShow: 1.2, itemPadding: [10, 3] },
  { width: 550, itemsToShow: 1.2, itemPadding: [10, 5] },
  { width: 768, itemsToShow: 2, itemPadding: [10, 8] },
  { width: 1200, itemsToShow: 2, itemPadding: [10, 12] },
];

// function myArrow({ type, onClick, isEdge }) {
//   const pointer =
//     type === consts.PREV ? (
//       <img src="assets/images/left-angle.png" alt="left arrow" />
//     ) : (
//       <img src="assets/images/right-angle.png" alt="left arrow" />
//     );
//   return (
//     <button onClick={onClick} disabled={isEdge} className="button-carousel topReviewCarBtn">
//       {pointer}
//     </button>
//   );
// }

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(0),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const TopReview = ({ getIdUser, getData, dataget, getreviewfromchild ,eventdetailspage1 ,getreviewid }) => {
                 
  // console.log( eventdetailspage1  , getreviewid )
  const navigate = useNavigate()
 
  const {t} = useTranslation('common')
  const params = useParams();
  const [value, setValue] = React.useState(2);
  const [reviewdata, setReviewdata] = React.useState([]);
  const [show, setShow] = React.useState(false)
  const [mssg, setMssg] = React.useState("");
  useEffect(() => {
    getAllReviewByEventId();
    if (getData === "yes") {
      dataget("");
    }
  }, [getData]);
  const getAllReviewByEventId = () => {
    let postData = {
      eventid: params.id,
    };
    http("events/getAllReviewByEventId", "POST", postData)
      .then((res) => {
        setMssg(res.data.msg)

        setReviewdata(res.data.data ?? []);
        getreviewfromchild(res.data.data)
        getIdUser(res.data.data);
        const data = res.data.data ?? [];

        if (data.length > 2) {
          setShow(true)
        } else {
          setShow(false)
        }
      })
      .catch((err) => {
      });
  };

  return (
    <div>


      {mssg === "No Review Is Found" ? "" :

        <Box className="mainContainer">
           
            {reviewdata.length>0 ?
            <Grid container spacing={2}>
            <Grid item md={6} lg={6} sm={6} xs={6}>
              <Typography className="artisHeading">{t('All_Reviews')}</Typography>
            </Grid>
            {reviewdata.length>=10 ?
            <Grid item md={6} xs={4} lg={6}>
              <Item Item sx={{ textAlign: "right" }} className="boxShadowNone">
                <span
                  className="viewAllSpan"
                  style={{
                    border: "1px solid #0CA8F6",
                    display: "inline-flex",
                    color: "#0CA8F6",
                    borderRadius: "6px",
                    width: "92px",
                    justifyContent: "flex-end",
                    height: "32px",
                    alignItems: "center",
                  }}
                >
                  <button
                    className="viewAllButton mobViewAllBtn"
                    style={{
                      border: "none",
                      background: "transparent",
                      color: "#0CA8F6",
                      fontWeight: "400",
                      fontSize: "14px",
                      cursor: "pointer",
                    }}

                    onClick={() => {
                      navigate("/ViewAllReview" , {state:{eventdetailspage1:eventdetailspage1 ,getreviewid:getreviewid }});
                  }}
                  >
                    {t('view_all')}{" "}
                  </button>
                  <KeyboardArrowRightOutlinedIcon />
                </span>
              </Item>
            </Grid>
            : <></>}
            </Grid>
            : <></>}




            <>
              <Grid item xs={12} sm={12} md={12} lg={12} >
                <div className="styling-example">
                  <Carousel
                    breakPoints={breakPoints}
                    // renderArrow={myArrow}
                    // itemPosition={"START"}
                    itemPosition={consts.START}
                    showEmptySlots
                    spacing={1}
                    showArrows={show}
                    disableArrowsOnEnd={true}
                    sx={{height: '100%'}}
                  >
                    {reviewdata.map((ele, index) => {
                      return (
                        <div className="paddingDiv" style={{ width: '100%', marginRight: '15px', height: '200px', overflowY:'auto' }} key={index}>
                          <div className="TopReviewBox">
                            <div className="viewOfferDetail">
                              <img src="assets/images/Frame 2.png"></img>
                              <span className="NameSide">{ele.reviewer_name}</span>
                            </div>

                            <Typography className="WeekClass">
                              <Moment fromNow>{ele.created_on}</Moment>
                            </Typography>
                          </div>
                          <Rating
                            // name="simple-controlled"
                            value={ele.event_ratings}
                            readOnly
                            className="RatingStar"
                          />
                          <Typography className="printingTypo">
                            {/* {parse(ele.review.substring(0, 100))} */}
                     {ele.review.replaceAll(/<[^>]*>/g, " ")}
                            {/* {ele.review} */}
                          </Typography>
                        </div>
                      );
                    })}

                  </Carousel>
                </div>
              </Grid>
            </>

 

        </Box>
      }

    </div>
  );
};

export default TopReview;
