import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import Stack from "@mui/material/Stack";
import http from "../../Services/http";
import { ManagePageReloadState } from "../../Services/ManagePageReloadState";
import {useNavigate } from "react-router-dom";
import "./style.css";
import { Typography } from "@mui/material";
import OwlCarousel from 'react-owl-carousel';
import {useTranslation} from "react-i18next";
import {getLanguageSource} from '../../Services/translateString'
const AllEventComponent = () => {
  const {t} = useTranslation("common");
  const options = {
    autoplay: false,
    smartSpeed: 1000,
    margin: 0,
    autoWidth:true,
    loop: false,
    center: false,
    dots: false,
    nav: true,
    navContainer: '.allCategoryNavBtn',
    navText : [
        '<i class="fas fa-chevron-left"></i>',
        '<i class="fas fa-chevron-right"></i>'
    ]
};
  const navigate = useNavigate();

  const [eventsCategories, seteventsCategories] = useState([]);
  const [eventsCount, setEventsCount] = useState(0);
  // All events Click Function
  const EventCategories = () => {
    navigate("/eventcategories");
  };

  //    Categories Click Function
  const Categories = (ele) => {
   navigate(
      `/ViewAllSubcategory/${ele.category_id}/${
        "?flag=" + JSON.parse(localStorage.getItem("autoCountry")).sortname
      }`,
      { state: { ele } }

    );
  };
  let totalEventCount = 0
  // Event Category For Application with total number of events
  const eventcategory = () => {
   
    let postData = {
      country_id: localStorage.getItem("autoCountry")
        ? JSON.parse(localStorage.getItem("autoCountry")).id
        : "",
      city: localStorage.getItem("SelectedCity")
        ? JSON.parse(localStorage.getItem("SelectedCity")).id
        : "",
    };

    http("event_category/categories", "POST", postData).then((res) => {
      const data = res.data.data ?? [];
      
      if (data.length > 0) {
        seteventsCategories(data);
        
        data.forEach(events=>{
          totalEventCount = totalEventCount + parseInt(events.total_events_count)
        })
        
        setEventsCount(totalEventCount)
      } else {
        // alert("hello");
      }
    });
  };

  useEffect(() => {
    eventcategory();
    ManagePageReloadState.getPageReloadState().subscribe((res) => {
      if (res.state) {
        eventcategory();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let colorSelectingIndex = 0
  const wrapperClassObj = (idx)=> {
    if(idx === 0){
      colorSelectingIndex = 0
    }
  
    const colors = {
      0: 'ecd-orange',
      1: 'ecd-green',
      2: 'ecd-yellow',
      3: 'ecd-blue',
    }
    if(colorSelectingIndex <= 3){
     const selctedColors = colors[colorSelectingIndex]
      colorSelectingIndex = colorSelectingIndex + 1
      return selctedColors
    }
    else {
      colorSelectingIndex = 0
      return colors[colorSelectingIndex]
    }
    
  }
    
const allEventCatTrans = (id,text,eventsCount)=>{
   getLanguageSource(text).then(data=>{
    setTimeout(()=>{
      document.getElementById(id).innerHTML = eventsCount ? `${eventsCount} ${data[0].translatedText}`: data[0].translatedText
    },1000)
      })
  return eventsCount ? `${eventsCount} ${text}`: text
 
}

  return (
    <>
    {eventsCategories.length > 0 ?  <div className="overflow-hidden py-5">
    <div className="container px-4">
        <div className="section-heading mb-5 mx-auto wow fadeInUp" data-wow-delay="0.1s">
            <div className="row justify-content-between align-items-center ">
                <div className="col-lg-6">
                    <p className="head-sub fw-semi-bold text-uppercase mb-1">{t('all_the_fun_starts_here')}</p>
                    <h2 className="fw-semi-bold">{t('explore_categories')}</h2>
                </div>
                <div className="allCategoryNavBtn col-lg-6 d-flex align-items-center justify-content-end gap-4">
                    
                </div>
            </div>
        </div>
      
        <OwlCarousel className='owl-theme categories-carousel wow fadeInUp' {...options}>
        {/* <div className="owl-carousel categories-carousel wow fadeInUp" data-wow-delay="0.3s"> */}
            <div className="item project-item pe-4 pb-3" key={'All_Events1'} onClick={(e) => EventCategories()}>
                <div className="project-img">
                    <img className="img-fluid rounded" style ={{width: 379, height: 329}} src="img/event-category-thumb-001.jpg" alt="event-category-thumb-001"/>
                    <div className="event-category-data ecd-blue">
                        <div id={`all_events_Categroies`} className="ecd-title fw-regular ">{allEventCatTrans('all_events_Categroies',"All Events")}</div>
                        <div id={`all_events_Categroies_Count`}className="ecd-count fw-light">{`${allEventCatTrans(`all_events_Categroies_Count`,'Events',eventsCount)}`} </div>
                    </div>
                </div>
            </div>
            {eventsCategories.map((data, index) => (
            <div className="project-item pe-4 pb-3"  key={`${data.category_name}${index}`} onClick={() => Categories(data)}>
                <div className="project-img">
                    <img className="img-fluid rounded" style ={{width: 379, height: 329, alignSelf:'center'}} src={data.category_image ? data.category_image :"img/event-category-thumb-001.jpg"}  alt="event-category-thumb-001"/>
                    <div className={`event-category-data ${wrapperClassObj(index)}`}>
                        <div className="ecd-icon mb-4"><img style ={{width: 75}} src={data.category_icon} alt="category-musical-concerts"/></div>
                   
                        <div id={`${data.category_name}_Categroies_${index}`} className="ecd-title fw-regular">{allEventCatTrans(`${data.category_name}_Categroies_${index}`,data.category_name)}</div>
                        <div id={`${data.category_name}_Count_${index}`} className="ecd-count fw-light ">{`${allEventCatTrans(`${data.category_name}_Count_${index}`,'Events',data.total_events_count)}`} </div>
                    </div>
                </div>
            </div>
))}
        </OwlCarousel>
    </div>
</div> : <></>
    }
  
    </>
  );
};

export default AllEventComponent;
