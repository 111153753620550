import React from 'react'
import { Box, Card } from '@mui/material'
import { Grid } from '@mui/material'
import { Typography } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check';

const Refund = () => {
  return (
    <Box className='mainContainer' sx={{paddingBottom: '30px'}}>
      <Grid container spacing={2}>
        
        <Grid item xs={12} sm={12} md={12}>
          <Typography className='policyIntroTypo'>CANCELLATION AND REFUNDS POLICY</Typography>
          <Typography className='policyContent'>A Ticket Holder may cancel any booking at any time before the date of the event to which such booking pertains, by sending Ticketkore a cancellation notice on the mobile application. On receipt of the booking cancellation notice, we will refund the Ticket Holder for the face value of the ticket(s), subject to reasonable cancellation charges as provided for by the relevant Organiser.Unless otherwise stipulated by an Organiser, and depending on the prevailing circumstances the following cancellation charges apply:</Typography>
          <Typography>
            <ul className='policyUlList'>
              <li><CheckIcon  className='ulCheckIcns'/>if the cancellation occurs at least 1 working day before the start of an event, a 10% cancellation fee will apply;</li>
              <li><CheckIcon  className='ulCheckIcns'/>if the cancellation occurs with less than 1 working days' notice, a 100% cancellation fee will apply;</li>
              <li><CheckIcon  className='ulCheckIcns'/>purchase service fees are non-refundable;</li>
              <li><CheckIcon  className='ulCheckIcns'/>all bank charges will be passed on to the Ticket Holder and Ticketkore will not be held liable for these fees.</li>
            </ul>
          </Typography>
          <Typography className='policyInnerContent'>Notwithstanding the aforesaid, where suitable documentary proof can be provided in respect of a Ticket Holder, and a statement that such person will not be able to attend the event because of such person’s death or hospitalization, no cancellation fee will apply. In such a case the Organiser will be obligated to approve a refund, even if they have selected 'No' for the 'Allow refunds' option.</Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Refund