import React from 'react'
import Navbar from '../../Components/Navbar'
import MenuBar from '../../Components/MenuBar'
import Footer from '../../Components/Footer'
import SearchBar from '../../Components/SearchBar'

const SearchResult = (props) => {
    const prop = props;
    return (
        <>
            <Navbar />
            {/* <MenuBar /> */} 
            <SearchBar props={prop}/>           
            <Footer />
        </>

    )
}

export default SearchResult