/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Box } from "@mui/system";
import { Grid, Typography } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Link from "@mui/material/Link";
import { useNavigate } from "react-router-dom";
import BottomScroll from "../BottomScroll";
import "./style.css";
import Stack from '@mui/material/Stack';
import {useTranslation} from "react-i18next";

const Footer = () => {
    const {t} = useTranslation("common");
  const navigate = useNavigate();

  const handleClick = (path) => () => {
    navigate(path);
  };

  const [istoken, setToken] = useState(false);

  useEffect(() => {
    const usertoken = localStorage.getItem("token");

    if (usertoken === null) {
      setToken(false);
    } else {
      setToken(true);
    }
  }, []);

  return (
    <>

<footer className="text-white footer mt-5 pt-5 wow fadeIn" data-wow-delay="0.1s">
            <div className="container pb-5">
                <div className="row gx-5 gy-3">
                    <div className="col-lg-4 col-md-6">
                        <div className="row gx-5 gy-3">
                            <div className="col-lg-8 col-md-8">
                                <p className="mb-3">
                                    <a href="index.html" className="navbar-brand ms-4 ms-lg-0">
                                        <img className="logo-img-footer" src="img/logo-tk-white.png" alt="logo-tk-white"/>
                                    </a>
                                </p>
                                <p className="mb-2">{t("TICKETKORE_is_a_smart_ticketing_and_reservation_platform_for_the_Live_Experience_industry")}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <h4 className="text-white mb-4">{t("legal_informations")}</h4>
                        <div className="row g-5">
                            <div className="col-lg-6 col-md-6">
                                <a className="btn btn-link" onClick={handleClick("/privacypolicy")}>{t("privacy_and_Data_Policy")}</a>
                                <a className="btn btn-link" onClick={handleClick("/termscondition")}>{t("terms_of_Use")}</a>
                                <a className="btn btn-link" onClick={handleClick("/PaymentOptions")}>{t("payment_Options")}</a>
                                <a className="btn btn-link" onClick={handleClick("/DeliveryOption")}>{t("delivery_Option")}</a>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <a className="btn btn-link" onClick={handleClick("/refundpolicy")}>{t("Refund_Policy")}</a>
                                <a className="btn btn-link" onClick={handleClick("/popiact")}>{t("POPI_Act")}</a>
                                <a className="btn btn-link" onClick={handleClick("/faq")}>{t("FAQ")}</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <h4 className="text-white mb-4">{t("Stay_In_The_Loop")}</h4>
                        <p>{t("Join_our_mailing_list_to_stay_in_the_loop_with_our_newest_for_Event_and_concert")}</p>
                        <div className="position-relative w-100">
                            <input className="form-control bg-white border-0 w-100 py-4 ps-4 pe-5" type="text"
                                placeholder={t("Enter_your_email_address")}/>
                            <button type="button"
                                className="btn btn-primary py-3 px-4 position-absolute top-0 end-0 mt-2 me-2">{t('Subscibe_Now')}</button>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                    <h4 className="text-white mb-2">{t('Connect_with_Us')}</h4>
                        <div className="d-flex pt-2">
                            <a className="btn btn-square rounded-circle me-3" href="/"><img className="logo-social-ftr" src="img/facebook.svg" alt="facebook icon"/></a>
                            <a className="btn btn-square rounded-circle me-3" href="/"><img className="logo-social-ftr" src="img/twitter.svg" alt="twitter icon"/></a>
                            <a className="btn btn-square rounded-circle me-3" href="/"><img className="logo-social-ftr" src="img/linkedin.svg" alt="linkedin icon"/></a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <h4 className="text-white mb-3">{t("Download_App")}</h4>
                        <div className="row g-3 g-lg-5">
                            <div className="col-lg-4 col-md-4">
                                <a href="https://play.google.com/store/apps/details?id=com.ticketkore.sa" className="navbar-brand ms-4 ms-lg-0" target="_blank" rel="noreferrer">
                                    <img className="logo-download-app" src="img/google-play.png" alt="google-play"/>
                                </a>
                            </div>
                            <div className="col-lg-4 col-md-4">
                                <a href="https://appgallery.huawei.com/app/C105701315" className="navbar-brand ms-4 ms-lg-0" target="_blank" rel="noreferrer">
                                    <img className="logo-download-app" src="img/app-gallery.png" alt="app-gallery"/>
                                </a>
                            </div>
                            <div className="col-lg-4 col-md-4">
                                <a href="https://apps.apple.com/us/app/itunes-connect/id376771144" className="navbar-brand ms-4 ms-lg-0" target="_blank" rel="noreferrer">
                                    <img className="logo-download-app" src="img/app-store.png" alt="app-store"/>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        
                    </div>
                </div>
            </div>
            {/* <!-- Copyright Start --> */}
            <div className="container-fluid copyright py-3">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center mb-3 mb-md-0">
                            {t("Copyright")} &copy;2024 <a href="/">Ticketkore</a> | {t("All_Right_Reserved")}
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Copyright End --> */}
        </footer>
        {/* <!-- Footer End --> */}

        {/* <!-- Back to Top --> */}
        <div style={{marginRight: 50}} className="btn btn-lg btn-primary btn-lg-square rounded-circle back-to-top"><i onClick={()=>window.scrollTo(0, 0)} className="bi bi-arrow-up"></i></div>



    
    </>
  );
};

export default Footer;
