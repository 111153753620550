import React   from "react";

  
 
const ListItems = ({ items, onSelect }) => { 
  
  return (
    <ul className="show dropdownSearchHome" style={{textAlign: 'left'}} >
      {items.map((item, index) => (
        <li
          key={item + index}
          onClick={() => onSelect(item)}
        >
           <img src={`https://flagcdn.com/32x24/${item.sortname?.toLowerCase()}.png`} alt={item.country_name}/>
           {item.country_name} 
        </li>
      ))}
    </ul>
  );
};

export default ListItems;
