import React, { useState, useEffect } from "react";
import { Card, Divider, getToolbarUtilityClass, Grid } from "@mui/material";
import { Box } from "@mui/material";
import { Typography } from "@mui/material";
import http from "../../Services/http";
import ClipLoader from "react-spinners/ClipLoader";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
// import { useLocation } from "react-router-dom";
import "./style.css";
import getSymbolFromCurrency from "currency-symbol-map";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {useTranslation} from 'react-i18next'
import {getLanguageSource} from '../../Services/translateString'
const Booking = () => {
  const {t} = useTranslation('common')
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  // Pagination
  const navigate = useNavigate();
  const [count, setCount] = React.useState(0);
  const [giftCount, setGiftCount] = React.useState(0);
  const [page, setPage] = React.useState(1);
  const [giftPage, setGiftPage] = React.useState(1);
  const [orderList, setorderList] = useState([]);
  const [isLoading, setIsLoading] = useState(false); 
  localStorage.removeItem("cartValue");
  localStorage.removeItem("totalAmountArr");
  localStorage.removeItem("nextvendorId");
  localStorage.removeItem("nextVendorName");
  /**Use for the consent page navigation with event id */
  const dateFormated = (date) => {
    let dateNew = new Date(date);
    let day = dateNew.toLocaleString("en-us", { weekday: "short" });
    let newdate = new Date(date).getDate();
    let month = dateNew.toLocaleString("default", { month: "short" });
    return day + "," + newdate + " " + month;
  };
 

   
 

  // Order list Api
  const goToOrderDetailPage = (orderId, data) => {
    navigate(`/OrderDetails/${orderId}` );
  };
  const goToVendorlist = (data) => {
    localStorage.setItem("Orderidoarams", data.id);
    navigate(`/vendorlist/${data.event_id}/${data.location_id}`, {
      state: { data },
    });
  };
  const [total_Count , setTotalCount] = useState(0)
  const [gift_total_Count , setGiftTotalCount] = useState(0)
  const OrderListevent = async(event, page) => {
    setIsLoading(true);
    if (page) setPage(page);
    else setPage(1);
    const OrderListData = {
      page: page ? page : 1,
      keyword: "",
    };
    http("orders/order_list", "POST", OrderListData).then(async(res) => {
      //setIsLoading(false);
      setCount(0);
      setCount(Math.ceil(res.data.total_page_count / res.data.page_size));
      const data = res.data.data ?? [];
      console.log(data)
      await translateData(data)
      setTotalCount(res.data.total_page_count)
    });
  };

  const translateData = async (data)=>{
    setorderList([])
    for(let idx = 0 ; idx < data.length; idx++){
      const stringArray = [
        data[idx].event_name,
        data[idx].city_name,
        data[idx].venue_name,
        data[idx].venue_address,

      ]
      const translatedText = await getLanguageSource(stringArray) 
      
      data[idx].event_name = translatedText[0].translatedText || data[idx].event_name;
      data[idx].city_name = translatedText[1].translatedText || data[idx].city_name;
      data[idx].venue_name = translatedText[2].translatedText || data[idx].venue_name;
      data[idx].venue_address = translatedText[3].translatedText || data[idx].venue_address;

        if(idx === data.length - 1){
          setIsLoading(false)
          setorderList(data ?? []);
        }
    }
    

  }

  useEffect(()=>{
    setTimeout(()=>{
      setIsLoading(true)
      orderList.length > 0 && translateData(orderList)
    },1000)
    
  },[localStorage.getItem('lang')])

  // Read More function

  const ReadMore = ({ children }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
    };
    return (
      <p className="text">
        {isReadMore ? text.slice(0, 25) : text}
        <span onClick={toggleReadMore} className="read-or-hide">
          {isReadMore ? (text.length >= 25 ? " ..." : "") : " show less"}
        </span>
      </p>
    );
  };

  // countdown timer

  const [countdownTime, setCountdownTime] = useState({
    countdownDays: "",
    countdownHours: "",
    countdownlMinutes: "",
    countdownSeconds: "",
  });

  

  const [giftTickets , setGiftTickets] = useState([])
  
  const getGiftOrders = (event , page)=>{
    setIsLoading(true);
    if (page) setGiftPage(page);
    else setGiftPage(1);
    let postData = {
      page: page ? page : giftPage
    }
    
    http('order_transfer/gift_tickets' , 'POST' , postData)
    .then((res)=>{
      if(res.data.status){
        setIsLoading(false);
        setGiftCount(Math.ceil(res.data.total_page_count / res.data.page_size));
        setGiftTotalCount(res.data.total_page_count)
        setGiftTickets(res.data.data)
      }
    })
  }
  useEffect(() => {
    OrderListevent(1, "");
    getGiftOrders(1 , '')
  }, []);

  return (
    <Box className="mainContainer">
      <Grid container spacing={4}>
        <Grid item xs={12} md={12} sx={{ marginTop: "10px" }}>
          <Typography className="layoutTitle">{t("My_Booking")}</Typography>
          <div className="hLine"></div>
        </Grid>
      </Grid>

      {/* Tabs Start */}
      {giftTickets.length>0 ? <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="grp-tabs-main-box">
      
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className="grp-tabs">
          <Tab label="My Tickets" {...a11yProps(0)} className="my-tickets-tabs" />
          <Tab label="Gift Tickets" {...a11yProps(1)} className="gift-tickets-tabs" />

        </Tabs> 
      </Box>: <></>}
      <TabPanel value={value} index={0} className="tabs-panel-my-ticket-tab">
        {isLoading ? (
          <div style={{ height: "400px" }}>
            <div className="loader ">
              <ClipLoader />
            </div>
          </div>
        ) : (
          <> 
            {orderList.length > 0 ? (
              <Grid
                container
                spacing={4}
                sx={{ marginTop: "0px", marginBottom: "40px" }}
              >
                {orderList.map((data, index) => (
                  <Grid item xs={12} sm={12} md={6} lg={4} className="booking-card-container" >
                    <Card
                      className="bookingCard"
                      sx={{ cursor: "pointer", height: "100%" }}
                    >
                      <grid onClick={(e) => goToOrderDetailPage(data.id, data)}>
                        <Box className="rescheduledRow">
                          <item className="rescheduledImgGroup">
                            {data.status === "0" ? (
                              <img
                                src="assets/images/booking-icon/Pending.png"
                                alt="cancelled"
                              />
                            ) : data.status === "2" ? (
                              <img
                                src="assets/images/booking-icon/Failed.png"
                                alt="cancelled"
                              />
                            ) : data.event_cancel === "1" ? (
                              <img
                                src="assets/images/booking-icon/cancelled.png"
                                alt="cancelled"
                              />
                            ) : data.event_postpond === "1" &&
                              data.event_cancel === "0" ? (
                              <img
                                src="assets/images/booking-icon/rescheduled.png"
                                alt="rescheduled"
                              />
                            ) :     data.event_postpond !== "1" &&
                            data.event_cancel === "0"&& data.complementary_order === "1" ?
                            <img src="assets/images/booking-icon/complementary-mob.png" alt="complementary-icon" />: (
                              <img
                                src={ data.gift_transfer_type === "1" ? "assets/images/booking-icon/transfer-icon.png":"assets/images/booking-icon/Successful.png"}
                                alt="ticket"
                              />
                            )}

                            <img
                              src={
                                data.gift_transfer_type === "1"?   "assets/images/booking-icon/transfer-qr-icon.png": data.barcode
                                  ? data.barcode
                                  : "assets/images/booking-icon/qr-code.png"
                              }
                              alt="qr-code"
                            />
                          </item>

                          <item className="bookingDetails">
                            <Typography className="bookingTitle">
                              {data.event_name}
                            </Typography>
                            <Typography className="bookingAddressBox">
                              <img
                                src="assets/images/booking-icon/calendar-icon.png"
                                alt="calendar"
                                style={{ marginRight: "23px" }}
                              />
                              <Typography className="bookingAddress">
                                {" "}
                                {data.muliday_enabled_flag !== "1" ? `${dateFormated(data.event_date)} ${data.event_time} `: data.multi_day_date_range }
                              </Typography>
                            </Typography>
                            <Typography className="bookingAddressBox">
                              <img
                                src="assets/images/booking-icon/map-pin.png"
                                alt="map pin"
                                style={{ marginRight: "23px" }}
                              />
                              <Typography className="bookingAddress">
                                <ReadMore>{data.venue_address}</ReadMore>
                              </Typography>
                            </Typography>
                            <Typography className="bookingOrderNo">
                              <span className="colorBlue">{t("Order_No")}.:</span>{" "}
                              {data.order_number}{" "}
                            </Typography>
                          </item>
                        </Box>

                        {/* <Box className="BookingTimerBox">                   
                    <img src="assets/images/timerIcon.png"  alt="timer" />
                    <Typography className="timerTextColor">02d : 16h : 26m : {countdownTime.countdownSeconds}s Left {countdownTimer(data.event_date ,data.event_time)} </Typography>
                   </Box> */}

                        <Box className="bookingTicketPriceDetail">
                          <item>
                            <Typography
                              key={index}
                              className="bookingTicketPriceText bookingTicketPriceTextBold"
                            >
                              {data.ticket_details.map(
                                (ticket_details, index) => (
                                  <div>{ticket_details.ticket_category}</div>
                                )
                              )}
                            </Typography>
                          </item>
                          <div className="bookingvLine"></div>
                          <item>
                            {data.seat_io === "0" ? (
                              <Typography className="bookingTicketPriceText">
                                {t("Ticket")} :
                                {data.ticket_details.map(
                                  (ticket_details, index) => (
                                    <span key={index} className="colorBlue">
                                      {ticket_details.ticket_quantity}{" "}
                                    </span>
                                  )
                                )}
                              </Typography>
                            ) : (
                              <Button
                                sx={{ textTransform: "capitalize" }}
                                className="ticket-bg"
                                disabled={true}
                              >
                                {data.ticket_details.map(
                                  (ticket_details, index) => (
                                    <span
                                      key={index}
                                      className="colorBlue order-booking-seats"
                                    >
                                      {ticket_details.ticket_quantity}
                                    </span>
                                  )
                                )}
                              </Button>
                            )}
                          </item>
                          <div className="bookingvLine"></div>
                          <item>
                            <Typography className="bookingTicketPriceText">
                              {t("Price")}:{" "}
                              <span className="colorBlue">
                                {getSymbolFromCurrency(data.currency)}{" "}
                                {data.actual_base_price_after_per_apply}{" "}
                              </span>
                            </Typography>
                          </item>
                        </Box>

                        {/*  */}
                        {data.zone_name || data.floor_plan_details?.enterence_gate_zone || data.seat_io === "1" ?
                          <>
                            <Box className="bookingGrandTotal">
                              <item>
                                {data.seat_io === "1" ? (
                                  <>
                                    {data.zone_name ||
                                      data.floor_plan_details?.enterence_gate_zone ? (
                                      <Typography className="bookingTicketPriceText bookingTicketPriceTextBold">
                                        {t("Entry_Zone")}:
                                      </Typography>
                                    ) : (
                                      <></>
                                    )}
                                    <Typography className="bookingTicketPriceText bookingTicketPriceTextBold">
                                    {t("Row")} :
                                    </Typography>
                                  </>
                                ) : (
                                  <>
                                    {data.zone_name ||
                                      data.floor_plan_details?.enterence_gate_zone ? (
                                      <Typography className="bookingTicketPriceText bookingTicketPriceTextBold">
                                        {t("Entry_Zone")}:
                                      </Typography>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                )}
                              </item>
                              <item>
                                {data.seat_io == "1" ? (
                                  <>
                                    <Typography className="bookingTicketPriceText">
                                      <span key={index} className="colorBlue">
                                        {data.floor_plan_details
                                          ? data.floor_plan_details
                                            .enterence_gate_zone
                                          : ""}
                                      </span>
                                    </Typography>
                                    <Typography className="bookingTicketPriceText">
                                      <span key={index} className="colorBlue">
                                        {data.floor_plan_details
                                          ? data.floor_plan_details.rows
                                          : ""}
                                      </span>
                                    </Typography>
                                  </>
                                ) : (
                                  <>
                                    {data.zone_name && data.zone_name !== "" ? (
                                      <Typography className="bookingTicketPriceText">
                                        <span key={index} className="colorBlue">
                                          {data.zone_name ? data.zone_name : ""}
                                        </span>
                                      </Typography>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                )}
                              </item>
                            </Box>
                            <Divider sx={{ mt: 4 }} />
                          </>
                          : <></>}

                        <Box className="bookingGrandTotal">
                          <item>
                            <Typography className="bookingGrandTotalText">
                              {t("Grand_Total_Price")} :
                            </Typography>
                          </item>
                          <item>
                            <Typography className="bookingGrandTotalAmount">
                              {getSymbolFromCurrency(data.currency)}{" "}
                              {parseFloat(data.final_amount).toFixed(2)}
                            </Typography>
                          </item>
                        </Box>
                        {/*  */}
                      </grid>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          paddingTop: "20px",
                          marginBottom: "20px",
                        }}
                      >
                        {data.qr_scan_by_pos_device == 1 &&
                          data.is_food_enable == 1 &&
                          data.status !== 0 ? (
                          <Button
                            className="ProcedBtn"
                            onClick={(e) => goToVendorlist(data)}
                          >
                            {t("ORDER_FOOD")}
                          </Button>
                        ) : (
                          " "
                        )}
                      </Box>
                    </Card>
                  </Grid>
                ))}
                {parseInt(total_Count) > 10 ? (
                  <Grid
                    container
                    justifyContent="center"
                    flexWrap="wrap"
                    sx={{ padding: "30px inherit !important" }}
                    className="commonGridPagination"
                  >
                    <Stack spacing={2} className="customPagination">
                      <Pagination
                        count={count}
                        page={page}
                        onChange={OrderListevent}
                      />
                    </Stack>
                  </Grid>
                ) : (
                  <></>
                )}
              </Grid>
            ) : (
              <Box
                sx={{
                  textAlign: "center",
                  width: "100%",
                  mt: 4,
                  fontWeight: 600,
                  height: "500px",
                  fontSize: "22px",
                  fontFamily: "unset",
                  textTransform: "capitalize",
                }}
              >
                <img
                  src="assets/images/no-data.png"
                  className="no-booking-img"
                  alt="data-not-found"
                />

                <Typography
                  variant="body1"
                  sx={{ mt: 2, mb: 2, fontWeight: 700, fontSize: "24px" }}
                >
                  {" "}
                  {t("No_booking_is_found_yet")}.
                </Typography>
              </Box>
            )}
          </>
        )}
      </TabPanel>
   {/* gift tickets */}
      <TabPanel value={value} index={1} className="tabs-panel-my-ticket-tab">
      {isLoading ? (
          <div style={{ height: "400px" }}>
            <div className="loader ">
              <ClipLoader />
            </div>
          </div>
        ) :(
        <>
          
            <Grid
            container
            spacing={4}
            sx={{ marginTop: "0px", marginBottom: "40px" }}
          >
           { giftTickets.map((ticket , index)=>(
              <Grid item xs={12} sm={12} md={6} lg={4} className="booking-card-container"  >
                <Card
                  className="bookingCard"
                  sx={{ cursor: "pointer", height: "100%" }}
                >
                  <grid onClick={(e) => goToOrderDetailPage(ticket.id )}>
                    <Box className="rescheduledRow">
                      <item className="rescheduledImgGroup">
                        {ticket.gift_transfer_status === "1" && ticket.transfer_type === "2" ? (
                          <img
                            src="assets/images/recived-gift.png"
                            alt="cancelled"
                          />
                        ) : (ticket.gift_transfer_status === "1" && ticket.transfer_type === "1") || (ticket.gift_transfer_status === "0" && ticket.transfer_type === "1") ? (
                          <img
                            src="assets/images/gift-icon.png"
                            alt="gift-icon"
                          />
                        ) : <></>}

                        <img
                          src={
                            (ticket.gift_transfer_status === "1" && ticket.transfer_type === "1") || (ticket.gift_transfer_status === "0" && ticket.transfer_type === "1")
                              ?  "assets/images/gift-tk.png":ticket.barcode 
                          }
                          alt="qr-code"
                        />
                      </item>

                      <item className="bookingDetails">
                        <Typography className="bookingTitle">
                          {ticket.event_name}
                        </Typography>
                        <Typography className="bookingAddressBox">
                          <img
                            src="assets/images/booking-icon/calendar-icon.png"
                            alt="calendar"
                            style={{ marginRight: "23px" }}
                          />
                          <Typography className="bookingAddress">
                            {" "}
                            {ticket.muliday_enabled_flag !== "1" ? `${dateFormated(ticket.event_date)} ${ticket.event_time} `: ticket.multi_day_date_range }
                           
                          </Typography>
                        </Typography>
                        <Typography className="bookingAddressBox">
                          <img
                            src="assets/images/booking-icon/map-pin.png"
                            alt="map pin"
                            style={{ marginRight: "23px" }}
                          />
                          <Typography className="bookingAddress">
                            <ReadMore>{ticket.venue_address}</ReadMore>
                          </Typography>
                        </Typography>
                        <Typography className="bookingOrderNo">
                          <span className="colorBlue">{t("Order_No")}.:</span>{" "}
                          {ticket.order_number}{" "}
                        </Typography>
                      </item>
                    </Box>

               

                    <Box className="bookingTicketPriceDetail">
                      <item>
                        <Typography
                          key={index}
                          className="bookingTicketPriceText bookingTicketPriceTextBold"
                        >
                          {ticket.ticket_details.map(
                            (ticket_details, index) => (
                              <div>{ticket_details.ticket_category}</div>
                            )
                          )}
 
                        </Typography>
                      </item>
                      <div className="bookingvLine"></div>
                      <item>
                        {ticket.seat_io === "0" ? (
                          <Typography className="bookingTicketPriceText">
                            {t("Ticket")} :
                            {ticket.ticket_details.map(
                              (ticket_details, index) => (
                                <span key={index} className="colorBlue">
                                  {ticket_details.ticket_quantity}{" "}
                                </span>
                              )
                            )}
                          </Typography>
                        ) : (
                          <Button
                            sx={{ textTransform: "capitalize" }}
                            className="ticket-bg"
                            disabled={true}
                          >
                            {ticket.ticket_details.map(
                              (ticket_details, index) => (
                                <span
                                  key={index}
                                  className="colorBlue order-booking-seats"
                                >
                                  {ticket_details.ticket_quantity}
                                </span>
                              )
                            )}
                          </Button>
                        )}
                      </item>
                      <div className="bookingvLine"></div>
                      <item>
                        <Typography className="bookingTicketPriceText">
                          {t("Price")}:{" "}
                          <span className="colorBlue">
                            {getSymbolFromCurrency(ticket.currency)}{" "}
                            {ticket.actual_base_price_after_per_apply}{" "}
                          </span>
                        </Typography>
                      </item>
                    </Box>

                    {/*  */}
                    {ticket.zone_name || ticket.floor_plan_details?.enterence_gate_zone || ticket.seat_io === "1" ?
                      <>
                        <Box className="bookingGrandTotal">
                          <item>
                            {ticket.seat_io === "1" ? (
                              <>
                                {ticket.zone_name ||
                                  ticket.floor_plan_details?.enterence_gate_zone ? (
                                  <Typography className="bookingTicketPriceText bookingTicketPriceTextBold">
                                    {t("Entry_Zone")}:
                                  </Typography>
                                ) : (
                                  <></>
                                )}
                                <Typography className="bookingTicketPriceText bookingTicketPriceTextBold">
                                  {t("Row")} :
                                </Typography>
                              </>
                            ) : (
                              <>
                                {ticket.zone_name ||
                                  ticket.floor_plan_details?.enterence_gate_zone ? (
                                  <Typography className="bookingTicketPriceText bookingTicketPriceTextBold">
                                    {t("Entry_Zone")}:
                                  </Typography>
                                ) : (
                                  ""
                                )}
                              </>
                            )}
                          </item>
                          <item>
                            {ticket.seat_io == "1" ? (
                              <>
                                <Typography className="bookingTicketPriceText">
                                  <span key={index} className="colorBlue">
                                    {ticket.floor_plan_details
                                      ? ticket.floor_plan_details
                                        .enterence_gate_zone
                                      : ""}
                                  </span>
                                </Typography>
                                <Typography className="bookingTicketPriceText">
                                  <span key={index} className="colorBlue">
                                    {ticket.floor_plan_details
                                      ? ticket.floor_plan_details.rows
                                      : ""}
                                  </span>
                                </Typography>
                              </>
                            ) : (
                              <>
                                {ticket.zone_name && ticket.zone_name !== "" ? (
                                  <Typography className="bookingTicketPriceText">
                                    <span key={index} className="colorBlue">
                                      {ticket.zone_name ? ticket.zone_name : ""}
                                    </span>
                                  </Typography>
                                ) : (
                                  ""
                                )}
                              </>
                            )}
                          </item>
                        </Box>
                        <Divider sx={{ mt: 4 }} />
                      </>
                      : <></>}

                    <Box className="bookingGrandTotal">
                      <item>
                        <Typography className="bookingGrandTotalText">
                          {t("Grand_Total_Price")} :
                        </Typography>
                      </item>
                      <item>
                        <Typography className="bookingGrandTotalAmount">
                          {getSymbolFromCurrency(ticket.currency)}{" "}
                          {parseFloat(ticket.final_amount).toFixed(2)}
                        </Typography>
                      </item>
                    </Box>
                    {/*  */}
                  </grid>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      paddingTop: "20px",
                      marginBottom: "20px",
                    }}
                  >
                    {ticket.qr_scan_by_pos_device == 1 &&
                      ticket.is_food_enable == 1 &&
                      ticket.status !== 0 ? (
                      <Button
                        className="ProcedBtn"
                        onClick={(e) => goToVendorlist(ticket)}
                      >
                        {t("ORDER_FOOD")}
                      </Button>
                    ) : (
                      " "
                    )}
                  </Box>
                </Card>
              </Grid>
              )) 

}
            
            {/* {orderList.length > 10 ? (
              <Grid
                container
                justifyContent="center"
                flexWrap="wrap"
                sx={{ padding: "30px inherit !important" }}
                className="commonGridPagination"
              >
                <Stack spacing={2} className="customPagination">
                  <Pagination
                    count={count}
                    page={page}
                    onChange={OrderListevent}
                  />
                </Stack>
              </Grid>
            ) : (
              <></>
            )} */}
            
          </Grid>
          {parseInt(gift_total_Count) > 10 ? (
                  <Grid
                    container
                    justifyContent="center"
                    flexWrap="wrap"
                    sx={{ padding: "30px inherit !important" }}
                    className="commonGridPagination"
                  >
                    <Stack spacing={2} className="customPagination">
                      <Pagination
                        count={giftCount}
                        page={giftPage}
                        onChange={getGiftOrders}
                      />
                    </Stack>
                  </Grid>
                ) : (
                  <></>
                )}
        </>)}
      </TabPanel>  

      {/* Tabs Close */}


    </Box>
  );
};

export default Booking;
