import React, { useEffect, useState } from 'react'
import Navbar from '../Navbar'
import Footer from "../../Components/Footer";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link, useLocation } from "react-router-dom";
import { Breadcrumbs, Typography, Grid, Rating } from "@mui/material"; 
import ModalPopup from "../ModalPopup";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import http from "../../Services/http"
// import BottomScroll from "../../Components/BottomScroll";

import { Box } from "@mui/system"; 
import "./style.css";
import ClipLoader from "react-spinners/ClipLoader";
 
const ViewAllReview = (props) => {

    const { state } = useLocation();
    
    const [count, setCount] = React.useState(0);
    const [page, setPage] = React.useState(1);

    const [isLoading, setIsLoading] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [avgRatting, setAvgRatting] = useState([])
    const [topReview, settopReview] = useState([])
    const [topaveragerating, settopaveragerating] = useState(0);
    const [totalCount , setTotalCount] = useState(0);
     
    // const Reviewid = eventdetailspage1?.review_data?.id;


    // Module Hoc Function
    const [openLogin, setOpenLogin] = React.useState(false);
    const handleClose = () => {
        setOpenLogin(false);
        setOpen(false);
    };
    const breadcrumbs = [
        <Link to={"/"} key="1" className="link-decoration">
            Home
        </Link>,

        <Typography
            key="3"
            sx={{
                fontFamily: "Lato",
                color: "#0CA8F6",
                fontWeight: 500,
                fontSize: "16px",
            }}
        >
            All Review
        </Typography>,
    ];
    useEffect(() => {
        getAllReviews(1, "")
    }, []);

    const getAllReviews = (e, page) => {
        let postData = {
            eventid: state.eventdetailspage1.id,
            page: page ? page : 1,
        }
        setIsLoading(true);
        if (page) setPage(page);
        else setPage(1);
        const reviewListData = {
            page: page ? page : 1,
            keyword: "",
        };
        http("events/eventViewAllReviews", 'POST', postData, reviewListData).then((res) => {
            setIsLoading(false); 
            res.data.data.ratings ? setAvgRatting(res.data.data.ratings) : <></>
            settopReview(res.data.data.top_reviews)
            setTotalCount(res.data.total_page_count)
            res.data.data.average_rating ? settopaveragerating(res.data.data.average_rating) : <></>
            setCount(0);
            setCount(Math.ceil(res.data.total_page_count / res.data.page_size));
        })
    }


    return (
        <>
            <Navbar />
            {/* <MenuBar /> */}
            <Box className="mainContainer">
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" />}
                    aria-label="breadcrumb"
                    sx={{ mb: 3, mt: 2 }}
                >
                    {breadcrumbs}
                </Breadcrumbs>
            </Box>
            <>
                <Box className="mainContainer reviewallContainer" style={{ paddingBottom: '30px' }}>
                {isLoading ? (
                <div style={{ height: "400px" }}>
                    <div className="loader ">
                        <ClipLoader />
                    </div>
                </div>
            ) : (
                    <>
                    <Grid container spacing={2}>

                        <Grid item xs={12} md={12}>
                            <Typography className="layoutTitle">All Review</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                            <Box className="">
                                <Box style={{ width: '83%', margin: 'auto' }}>
                                    <Box className="rating-viewreview" style={{
                                        border: '1px solid #D9D9D9', borderRadius: '40px',
                                        background: '#F4FBFF', padding: '13px 0'
                                    }}>
                                        <span><Rating name="half-rating" defaultValue={0} value={topaveragerating} precision={0.5} readOnly /></span>
                                        <span>{topaveragerating} out of 5</span>
                                    </Box>
                                </Box>
                            </Box>
                            <Box className="rating-viewreview">
                                <Typography className='totalreviewRating'>
                                    (150 overall rating)
                                </Typography>
                            </Box>
                            <Box className="margintop">
                                {avgRatting.map((avg, index) => (
                                    <Box className="rating-viewreview margintop" key={index}>
                                        <span>{avg.rating} star</span>
                                        <span><Rating name="half-rating" defaultValue={0} value={avg.rating} precision={0.5} readOnly /></span>
                                        <span>{avg.percentage}</span>
                                    </Box>
                                ))}
                            </Box>
                            {/* <AllReview  className="rate-n-review" eventdetailspage1={state.eventdetailspage1.id}  getreviewid = {state.getreviewid}  /> */}

                            {openLogin ? (
                                <ModalPopup handleOpen={openLogin} handleClick={handleClose} />
                            ) : (
                                ""
                            )}
                        </Grid>
                        <Grid item xs={12} sm={12} md={8}>
                            <Box>
                                <Grid item xs={12} md={12}>
                                    <Typography className="reviewallHeading">Top Reviews</Typography>
                                </Grid>
                                {topReview.map((topreviews, index) => (
                                    <div className="paddingDiv" style={{ width: '100%', marginRight: '15px', height: '200px', overflowY: 'auto' }} key={index}>
                                        <div className="TopReviewBox">
                                            <div className="viewOfferDetail">
                                                <img src={topreviews.profile_pic} alt='profile-pic' className="all-review-img" />
                                                <span className="NameSide">{topreviews.username}</span>
                                            </div>

                                            <Typography className="WeekClass">
                                                <moment fromNow>{topreviews.created_on}</moment>
                                            </Typography>
                                        </div>
                                        <Rating
                                            name="half-rating" defaultValue={0} precision={0.5}
                                            value={topreviews.ratings}
                                            readOnly
                                            className="RatingStar"
                                        />
                                        <Typography className="printingTypo-inreview"  >
                                            {topreviews.review_in_text}
                                        </Typography>
                                    </div>
                                ))}
                            </Box>
                        </Grid>
                    </Grid>
                    
{                    totalCount >10 ? <Grid
                        container
                        justifyContent="center"
                        flexWrap="wrap"
                        sx={{ padding: "30px inherit !important" }}
                        className="commonGridPagination"
                    >
                        <Stack spacing={2} className="customPagination">
                            <Pagination
                                count={count}
                                page={page}
                                onChange={getAllReviews}
                            />
                        </Stack>
                    </Grid> : <></>}

                    </>
                    )}
                </Box>
           
            </>
            <Footer />
        </>
    )
}

export default ViewAllReview