import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import ClipLoader from "react-spinners/ClipLoader";
import { CardActionArea, ListItemAvatar } from "@mui/material";

import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { useState } from "react";
import http from "../../Services/http";
import { useParams, useNavigate, useLocation } from "react-router-dom";

import "./style.css";

const VendorList = () => {
  const localGrandTotalCount = localStorage.getItem('totalAmountArr') ? JSON.parse(localStorage.getItem('totalAmountArr')).grantTotalItem : ''
  if (localGrandTotalCount == 0) {
    localStorage.removeItem('cartValue')
    localStorage.removeItem('totalAmountArr')
    localStorage.removeItem('nextvendorId')
  localStorage.removeItem('nextVendorName')
  }
  

  const navigate = useNavigate()
  const Location = useLocation()
  const FoodSearch = Location.state.data
  const orderNumber = Location.state.data.order_number;
  const commisiononfood = Location.state.data.commission_on_food;
  const orderid = Location.state.data.id;
  const [count, setCount] = React.useState(0);
  const [page, setPage] = React.useState(1);
  const [vendorList, setVendorList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const searchParams = useParams();

  const eventid = searchParams.eventid;

  const event = searchParams.eventid;
  const locid = searchParams.locationid;
  const location_id = searchParams.locationid;
  const VendorListEvent = (event, page) => {

    setIsLoading(true);
    if (page) setPage(page);
    else setPage(1);
    const postData = {
      event_id: eventid,
      location_id: locid,
      keyword: "",
    };
    http("event_food/event_food", "POST", postData).then((res) => {
      setIsLoading(false);

      const data = res.data.data ?? [];
      setVendorList(data ?? []);
    });
  };
  useEffect(() => {
     VendorListEvent();
    // dispatch(AddProduct())
  }, []);




  const supermartspage = (e, data) => {
    // dispatch(vendoritem({ state: { data, location_id, event, locid, orderNumber, commisiononfood, orderid } }))
    navigate("/supermarts", { state: { data, location_id, event, locid, orderNumber, commisiononfood, orderid } })
  }
  const FoodSearchPage = (e) => {
    // dispatch(vendoritem({ state: { location_id, event, locid, orderNumber, commisiononfood, orderid } }))

    navigate("/foodsearch", { state: { location_id, locid, event, orderNumber, commisiononfood, FoodSearch, orderid, vendorList, "check": true } })
  }


  return (
    <>
      {isLoading ?
        <div style={{ height: "500px" }}>
          <div className="loader ">
            <ClipLoader />
            {/* <img
                          src="assets/images/ticketkore-logo.gif"
                          alt="cancelled"
                        /> */}
          </div>
        </div> :

        <Box className="mainContainer">


          <Grid container alignItems="center" sx={{marginBottom: '30px !important'}}>
            <Grid item xs={12} sm={6} md={6}>
              <Typography className="layoutTitle d-flex" sx={{ paddingTop: '0px !important' }}>
                Vender List
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6} md={6} className="searchEventFav">
              {/* onClick={Searchfoodorder} */}


              <div onClick={(e) => FoodSearchPage(e)}>
                <input
                  type="text"
                  class="input-bordered inputSearch"
                  style={{ float: "right" }}
                  placeholder="Search"
                  disabled

                />
              </div>

            </Grid>

          </Grid>

          <div className="hLine"></div>

          <Grid container spacing={3} sx={{ marginTop: '10px' }}>
            {vendorList?.map((data, index) => {
              return (
                <>
                  <Grid item xs={12} md={6} lg={4}>
                    <Card className="VendorSupCard" onClick={(e) => supermartspage(e, data)}>
                      <CardActionArea>
                        <CardMedia
                          component="img"
                          image={
                            data.vendor_banner
                              ? data.vendor_banner
                              : "assets/images/superMart.png"
                          }
                          alt="green iguana"
                        />

                        <CardContent
                          className="VendorCardContent"
                          sx={{ paddingLeft: "0px" }}
                        >
                          <Typography className="vendorSuperMArt">
                            {data.company_type === "1"
                              ? data.company_name
                              : data.vendor_name}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                </>
              );
            })}
          </Grid>
          <Grid
            container
            justifyContent="center"
            flexWrap="wrap"
            sx={{ padding: "30px 0px !important" }}
          >
            <Stack spacing={2} className="customPagination">
              {count > 0 ? <Pagination count={count} page={page} onChange={VendorListEvent} /> : ""}
            </Stack>
          </Grid>
        </Box>}
    </>
  );
};

export default VendorList;