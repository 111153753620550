import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";


function SearchField(props) {
  const [value, setValue] = useState(props.selectedValue);
  const selectedCountryFlag = props.selectedValue ? props.selectedValue.sortname?.toLowerCase() : "";
   useEffect(() => {
    setValue(props.selectedValue.country_name);
  }, [props]);

  const handleClick = () => {
    if (value) props.onSearch(value);
    
  }; 
  return (
    <div
      className="searchInsideInput"
      style={{maxWidth:355,width:'86%'}}
    >
      {props.selectedValue && selectedCountryFlag? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
{          <img
            src={`https://flagcdn.com/32x24/${selectedCountryFlag}.png`}
            alt="South Africa"
          /> ? <img
          src={`https://flagcdn.com/32x24/${selectedCountryFlag}.png`}
          alt="South Africa"
        /> :''}

          {props.selectedValue.name}
        </Box>
      ) : (
        ""
      )}
      <input
        
        className="form-control"
        placeholder="Search country"
        onChange={(e) => props.onSearch(e.target.value.toLowerCase())}
        onClick={handleClick}
      />
      {props.isLoading && (
        <span className="m-1" style={{ right: 5, zIndex: 10000 }}>
          <div className="spinner-border spinner-border-small" role="status" />
        </span>
      )}
    </div>
  );
}

export default SearchField;
