import React, { useEffect } from "react";
import { Box, Card } from "@mui/material";
import { Grid } from "@mui/material";
import { Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import {useTranslation} from "react-i18next"
import "./style.css";

const TermsOfUse = () => {
  const {t} = useTranslation("common")
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box className="mainContainer" sx={{ paddingBottom: "30px" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} className="mobFooterCmn">
          <Typography className="policyIntroTypo">
            {t("WEBSITE & MOBILE APPLICATION TERMS OF USE")}
          </Typography>
          <Typography className="policyContent">
            {t(`Welcome_to_the_www.ticketkore.io_website_(the_'Site')_of_AltarPlus_(Pty)_Ltd_t/a_Ticketkore™_('Ticketkore',_'we',_'us'_and/or_'our')._This_Site_has_been_created_to_provide_information_about_Ticketkore,_to_provide_bespoke_online_event_management,_registration,_and_ticketing_services_(the_'Services'),_and_finally_to_provide_information_about_events_listed_on_the_Site.`)}
          </Typography>
          <Typography className="policyInnerContent">
            {t("The_use_of_the_Site_is_subject_to_these_terms_and_conditions_We_may_change_our_terms_from_time_to_time_and_by_using_the_Site_you_agree_to_such_terms_and_conditions")}
          </Typography>
          <Typography className="policyContent">
            {t("If you are not yet 18 you must obtain your parents’ or legal guardians’ consent and permission to be bound by the terms of our Site before you use any of the Ticketkore Services or participate in any of the events listed on the Site.")}
          </Typography>

          <Typography className="policyIntroTypo">
            {t("Ticketkore™ Services")}
          </Typography>
          <Typography className="policyContent">
            {t("Ticketkore provides an online ticketing service for organizers and/or promoters of events (\"Organisers\"), and to persons wishing to buy tickets for such events (\"Ticket Holders or “Users”). Tickekore allows Organisers to create an online profile for an event, manage all incoming payments related to event ticket sales through our online platforms being our website www.ticketkore.io and the Ticketkore mobile application (the “Site”). Ticketkore allows the issuing of detailed event tickets, with a unique barcode in respect of each ticket for verification purposes.")}
          </Typography>
          <Typography className="policyInnerContent">
            {t("Importantly, Ticketkore acts solely as an independent service provider to Organisers by providing the platform for Organisers to sell tickets to, and collect payment from, Ticket Holders. It does not have any responsibility or control over the Event or the conduct of the Organiser concerning the Event.")}
          </Typography>
          <Typography className="policyInnerContent">
           {t("Each ticket that a Ticket Holder purchases from us are subject to the Organiser’s event terms and conditions. It is the Ticket Holder's responsibility to familiarise itself with the relevant Organiser’s terms and rules before submitting a booking request.")}
          </Typography>

          <Typography className="policyIntroTypo">
            {t("Booking Process For Ticket Holders")}
          </Typography>
          <Typography className="policyContent">
            {t("To book event tickets through Ticketkore, the automated booking process must be completed and payment information submitted to us in the prescribed manner on the Site. Our booking process will provide Ticket Holders with an opportunity to review the entire transaction, correct any mistakes, and to withdraw from the transaction before finally submitting a booking request.")}
          </Typography>
          <Typography className="policyInnerContent">
            {t("When making a booking, a user account will be created automatically under the email address provided by the Ticket Holder, in the absence of an existing user account. An email will be sent to the email address explaining how to set a password for the account. This is done for convenience and allows Ticket Holders to be able to retrieve tickets at any point by logging into Ticketkore. This account will not be linked to any promotional mailing lists and will not be sent any unsolicited mail. If at any point a Ticket Holder would like its account to be removed, the Ticket Holder should contact Ticketkore.")}
          </Typography>
          <Typography className="policyInnerContent">
            {t("The supply of tickets offered depends upon the availability of tickets for a relevant event. We or the Event Organiser may limit bookings to a specified number of persons.")}
          </Typography>
          <Typography className="policyInnerContent">
            {t("The information submitted with a booking request will be processed \"as is\". Ticket Holders must contact us immediately if the information provided in the booking request is incorrect.")}
          </Typography>
          <Typography className="policyInnerContent">
           {t("The owner of a ticket purchased on Ticketkore is the Ticketkore, account holder. The owner of the credit card or bank account used in the purchase cannot claim ownership of a ticket.")}
          </Typography>

          <Typography className="policyIntroTypo">
            {t("User Name And Passwords")}
          </Typography>
          <Typography className="policyContent">
            {t("Ticket Holders and Organisers are responsible for keeping usernames and passwords for the Site secure. We are not responsible for losses incurred by Ticket Holders or Organisers arising as the result of passwords being misused by third parties. Ticket Holders and Organisers will be personally liable for all transactions concluded on their account.")}
          </Typography>

          <Typography className="policyIntroTypo">{t("Purchase Price")}</Typography>
          <Typography className="policyContent">
            {t("The purchase price (“Price”) of the Products is indicated on our Site and includes value-added tax (“VAT”) at the applicable current rate chargeable in South Africa for the time being.")}
          </Typography>
          <Typography className="policyInnerContent">
            {t("All Prices shown on our Sites are quoted in South African Rands and are valid and effective only in South Africa.")}
          </Typography>
          <Typography className="policyInnerContent">
            {t("The Prices reflected on the Sites are subject to availability. Prices may change daily.")}
          </Typography>
          <Typography className="policyInnerContent">
            {t("The Purchase Price is indicated during the check-out process before you confirm your order. The Purchase Price will be payable upon checkout.")}
          </Typography>

          <Typography className="policyIntroTypo">
            {t("Event Descriptions")}
          </Typography>
          <Typography className="policyContent">
           {("Details of all events (including the details of the relevant Organiser, venues, and arrangements) for which tickets are offered will be described on our Site. However, descriptions of events are provided to us by the relevant Organisers. Ticketkore does not endorse any of the events or the accuracy of event descriptions, and we do not determine or control ticket prices or the availability in respect of the events. We do not accept.")}
          </Typography>
          <Typography className="policyInnerContent">
            {t("Responsibility for any inaccuracy, unavailability, cancellations, or changes in pricing that may occur, nor for any cancellations or changes in the Event. The relevant Organiser should be contacted if a Ticket Holder is dissatisfied with an event, for any reason whatsoever.")}
          </Typography>

          <Typography className="policyIntroTypo" id="PaymentOption">
            {t("Payment Options")}
          </Typography>
          <Typography className="policyContent">
           {t(" Ticketkore platform reserves a right to select a method on which to accept payment.")}
          </Typography>
          <Typography className="policyInnerContent">
            {t("These payment methods may include, but may not be limited to")};{" "}
          </Typography>
          <img
            src="assets/images/paymentM.png"
            alt="paymentM"
            className="paymentMCss"
          />

          <Typography className="policyIntroTypo">
            Credit Card Security
          </Typography>
          <Typography className="policyContent">
            Credit card transactions for events in South Africa will be
            processed for Ticketkore via Peach Payments, an approved payment
            gateway for the major banks in South Africa. It uses strict
            encryption and no credit card details are stored on Ticketkore
            servers.
          </Typography>
          <Typography className="policyInnerContent">
            {t("Ticketkore does not store any credit card details on its servers and all personal details are stored separately from card details. All card details which are provided by users are stored on Peach Payments’ secure site as per secure credit card acquiring and security specifications. Ticketkore cannot be held liable in the unlikely event that there is a breach in the security of the Peach Payments or Stripes")}
          </Typography>
          <Typography className="policyInnerContent">
            {t("servers or systems, although we will take whatever action we deem necessary to ensure that such breach is rectified, to the extent possible, by Peach Payments and/or Stripe.")}
          </Typography>

          <Typography className="policyIntroTypo" id="DeliveryOption">
            {t("Delivery_Option")}
          </Typography>
          <Typography className="policyContent">Ticketkore:</Typography>
          <Typography>
            <ul className="policyUlList">
              <li>
                <CheckIcon className="ulCheckIcns" />
                {t("Ticketkore only issues tickets via electronic means, therefore no actual printed tickets shall be available.")}
              </li>
              <li>
                <CheckIcon className="ulCheckIcns" />
                {t("All tickets purchased via the App shall be available on my booking section.")}
              </li>
            </ul>
          </Typography>

          <Typography className="policyContent">{t("Wristbands")}:</Typography>
          <Typography className="policyInnerContent">
            {t("All orders placed and accepted shall be delivered to the customer’s selected address after 48 hours of approval of all artworks.")}
          </Typography>

          <Typography className="policyIntroTypo">{t("Currency")}</Typography>
          <Typography className="policyContent">
            {t("Ticketkore platform accepts and processes all currencies")}
          </Typography>

          <Typography className="policyIntroTypo">{t("Value Added Tax")}</Typography>
          <Typography className="policyContent">
            {t("All prices on the platform are inclusive")}
          </Typography>

          <Typography className="policyIntroTypo">{t("Disclaimer")}</Typography>
          <Typography className="policyContent">
            {t("The Ticketkore Services are provided subject to applicable law and consumer protection legislation, but are otherwise provided without any warranty, and not subject to any condition (express or implied), except as specifically stated in the Site.")}
          </Typography>
          <Typography className="policyInnerContent">
            {t("Ticketkore will endeavour to make payments to Organisers as soon as reasonably possible, however, Ticketkore makes no representations or warranties regarding the amount of time needed to complete processing of such payments because such processing is dependent upon many factors beyond our control.")}
          </Typography>
          <Typography className="policyInnerContent">
            {t("While Ticketkore undertakes to use reasonable endeavours to maintain the operation of the Ticketkore Services, we cannot guarantee continuous, uninterrupted, or secure access to the service and operation of the Site and mobile systems. Ticketkore shall not be liable for any delay or failure in the running of the Ticketkore Services, or the working of the Site, where such failure is due to factors beyond Ticketkore control.")}
          </Typography>
          <Typography className="policyInnerContent">
            {t("Ticketkore will not be held liable for any indirect, special, or consequential losses of any kind whatsoever arising from, or in connection with any booking (whether in contract, statute, ordelict).")}
          </Typography>
          <Typography className="policyInnerContent">
            {t("Users acknowledge that access to the Site may occasionally be restricted to allow for repairs, maintenance, or the introduction of new facilities or services. We will attempt to restore the Site as soon as reasonably possible.")}
          </Typography>

          <Typography className="policyIntroTypo">Privacy Policy</Typography>
          <Typography className="policyContent">
            {t("Ticketkore undertakes to take all due care with any information provided by users when accessing the Site, however, it cannot guarantee the security of any information which a user may provide. Information transmitted to Ticketkore is transmitted at the user's own risk. All compliance with privacy legislation is set out in the TicketKore Privacy Policy.")}
          </Typography>

          <Typography className="policyIntroTypo">
            {t("Storing Of Customer Details")}
          </Typography>
          <Typography className="policyContent">
            {("For verification purposes, Ticketkore reserves the right to request proof of identification in an offline format. Users' IP addresses and access times are tracked against their user ID to prevent fraud and to create an audit trail. Ticketkore uses cookies on the Site and links the information stored in cookies to the personal identification information that users submit while on the Site.")}
          </Typography>
          <Typography className="policyInnerContent">
            {t("Ticketkore collects the personal data provided by when users voluntarily provide such information on the Site. The personal data Ticketkore collects includes, without limitation, the user's name, email address, and other personally identifiable information. Ticketkore also collects financial information as is necessary to facilitate any payments/refunds.")}
          </Typography>

          <Typography className="policyIntroTypo">
            {t("Dispute Resolution And Chargebacks")}
          </Typography>
          <Typography className="policyContent">
            {("All users agree to work actively with the other parties to your transactions to resolve any disputes that may arise. If a transaction is suspected of fraud, Ticketkore reserves the right to request any proof deemed necessary to determine the validity of the transaction. This proof includes, but is not limited to, proof of identification, proof of credit card, and proof of address. Ticketkore does not act as the agent of either party to any dispute between Ticket Holders and Organisers, and is not responsible for resolving any disputes. If any charge-back claim occurs (as the result of a dispute or for any reason whatsoever), Ticket Holders agree to provide the Organiser with all the necessary documentation to resolve the claim. Organisers must:")}
          </Typography>

          <Typography>
            <ul className="policyUlList">
              <li>
                <CheckIcon className="ulCheckIcns" />
                {t("Acknowledge that Ticketkore does not control the outcome of the charge-back decision reached by the Ticket Holder's issuing bank;")}
              </li>
              <li>
                <CheckIcon className="ulCheckIcns" />
                {t("Accept the decision of the issuing bank as final and legally binding; and.")}
              </li>
              <li>
                <CheckIcon className="ulCheckIcns" />
                {t("recognise that payment may not be received if the issuing bank rules against the Organiser concerning the payment received.")}
              </li>
            </ul>
          </Typography>
          <Typography className="policyInnerContent">
            {"To report a potentially fraudulent transaction, error or dispute, please contact Ticketkore."}
          </Typography>
          <Typography className="policyInnerContent">
            {t("Organisers, where Ticketkore may have already paid you in respect of a ticket, and the Ticket Holder's bank subsequently voids the transaction under a charge-back, the Organiser will, on-demand, make good, and indemnify Ticketkore, for the loss suffered by Ticketkore as a result. The Organiser will refund Ticketkore for the amount previously deposited and subsequently withdrawn from Ticketkore by the Ticket Holder's bank.")}
          </Typography>

          <Typography className="policyIntroTypo">
            {t("Domicilium And Notices")}
          </Typography>
          <Typography className="policyContent">
            {t("The Site is governed by the laws of the Republic of South Africa and Ticketkore chooses as its dwelling (“domicilium”), for all purposes, whether in respect of court process, notice, or other documents or communication of whatsoever nature, its registered office, being 131 7th Street, Edenvale, 1609, Gauteng, South Africa.")}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TermsOfUse;
