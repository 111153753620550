import { Button, Card, Grid } from "@mui/material";
import React, { useEffect, useContext } from "react";
import { Box } from "@mui/material";
import { Typography } from "@mui/material";
import {useTranslation} from 'react-i18next'
// Dialog model

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

// Accordion

import { styled } from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

//

import http from "../../Services/http";
import { useState } from "react";
import parse from "html-react-parser";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import TablePagination from "@mui/material/TablePagination";
import ClipLoader from "react-spinners/ClipLoader";
import { NotificationManager } from "react-notifications";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import { StoreContext } from "../../../src/useContext/walletContext";

import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

import "./style.css";

import DataNotFound from "../../Components/DataNotFound";
import AllEvents from "../../Components/AllEventComponent";

// Accordion

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {},
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<KeyboardArrowDownIcon sx={{ fontSize: "1.6rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "#F6F6F6)" : "rgba(0, 0, 0, .03)",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const OffersList = () => {
  const {t} = useTranslation('common')
  const [open1, setOpen1] = React.useState(false);

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [offersevent, setOffersevent] = useState([]);

  // PAGINATION
  const [rowsPerPage, setRowsPerPage] = useState(3);
  const [page, setPage] = useState(0);
  const [count, setCount] = React.useState(0);
  const [orderList, setorderList] = useState([]);

  // flag

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();

  const context = useContext(StoreContext);

  // Autoc
  let Autocountry = localStorage.getItem("autoCountry")
    ? JSON.parse(localStorage.getItem("autoCountry"))
    : {};
  const localstoragecountryid = Autocountry.id;
  const dateFormated = (date) => {
    let dateNew = new Date(date);
    let day = dateNew.toLocaleString("en-us", { weekday: "short" });
    let newdate = new Date(date).getDate();
    let month = dateNew.toLocaleString("default", { month: "short" });
    return day + "," + newdate + " " + month;
  };

  // Handleeventoffer events data
  const Handleeventoffer = (event, page) => {
    setIsLoading(true);
    let postData = {
      country_id: localStorage.getItem("autoCountry")
        ? JSON.parse(localStorage.getItem("autoCountry")).id
        : "",
      page: page ? page : 1,
      keyword: "",
    };
    http("events/offers", "POST", postData)
      .then((res) => {
        const data = res.data.data ?? [];
        setIsLoading(false);
        setOffersevent(data);
        setCount(0);
        setCount(Math.ceil(res.data.total_page_count / res.data.page_size));
        setorderList(data ?? []);
      })
      .catch((err) => {});
  };

  const CopyCoupounCode = (CouponCode) => {
    navigator.clipboard.writeText(`${CouponCode}`);
    NotificationManager.success("Offer Copied Successful");
  };

  useEffect(() => {
    Handleeventoffer(1, "");
  }, [context.headerTitle.id]);

   

  const handleonviewallClick = (offersPopup , event_name) => {
    let EventName = event_name.replaceAll(/[^a-zA-Z0-9 ]/g , "")
    navigate(`/events/${EventName.replaceAll(' ', '-')}/${offersPopup.event_id}`, {
      state: { offersPopup },
    });
  };

  // accordian

  const [expanded, setExpanded] = React.useState("panel1");
  const [offersPopup, setOffersPopup] = React.useState([]);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : true);
  };

  // accordian

  // popup model

  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClickOpen = (ele) => {
    setOffersPopup(ele);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // popup model

  return (
    <Box className="mainContainer">
      <Grid container>
        <Grid item xs={12} md={12}>
          <Typography className="layoutTitle">{t("Offers")}</Typography>
          <div className="hLine"></div>
        </Grid>
      </Grid>
      <Grid spacing={3} container sx={{ marginTop: "0px", mb: 12, }} className="mombbRmOfferC">
        <>
          {isLoading ? (
            <div style={{ height: "400px" }}>
              <div className="loader ">
                <ClipLoader />
                {/* <img
                          src="assets/images/ticketkore-logo.gif"
                          alt="cancelled"
                        /> */}
              </div>
            </div>
          ) : (
            <>
              {offersevent.length > 0 ? (
                <>
                  {offersevent
                    //.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((ele, index) => {
                      return (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={3}
                          lg={2.4}
                          key={"offerlist" + index}
                        >
                          <Box key={"offers_list" + index}>
                            <Card className="offerGrid">
                              <Box className="offerHeadingBox">
                                <Typography className="offerListHeading word-ellipsis">
                                  {ele.event_name}
                                </Typography>
                              </Box>
                              <Box className="paddingX20">
                                <img
                                  src={
                                    ele.coupon_image
                                      ? ele.coupon_image
                                      : "assets/images/Rectangle.png"
                                  }
                                  alt="gift voucher"
                                  style={{
                                    marginTop: "12px",
                                    cursor: "pointer",
                                  }}
                                  onClick={(e) => handleClickOpen(ele)}
                                />
                                <Typography className="offerCopyText">
                                  {ele.coupon_code}
                                  <img
                                    onClick={() =>
                                      CopyCoupounCode(ele.coupon_code)
                                    }
                                    src="assets/images/copyText.png"
                                    alt="copyText"
                                    style={{
                                      marginLeft: "12px",
                                      cursor: "pointer",
                                    }}
                                  />
                                </Typography>
                                <Typography className="offerValiddate">
                                {t("Valid_till")} :{" "}
                                  <span className="colorBlue">
                                    {" "}
                                    {dateFormated(ele.end_date)}{" "}
                                  </span>
                                </Typography>
                              </Box>
                            </Card>
                          </Box>
                        </Grid>
                      );
                    })}
                  {offersevent.length > 10 ? (
                    <Grid
                      container
                      justifyContent="center"
                      flexWrap="wrap"
                      sx={{ padding: "30px 0px !important" }}
                    >
                      <Stack spacing={2} className="customPagination">
                        <Pagination
                          count={count}
                          page={page}
                          onChange={Handleeventoffer}
                        />
                      </Stack>
                    </Grid>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <DataNotFound />
              )}
            </>
          )}
        </>

        {/* Offer Details popup */}

        <div>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
            className="dialogBox"
          >
            <DialogTitle
              id="responsive-dialog-title"
              className="notificationTabPadding"
            >
              <Typography className="offerDetailsTitle">
                <img
                  src="assets/images/left-dark-arrow.png"
                  alt=""
                  style={{ marginRight: "16px", cursor: "pointer" }}
                  onClick={handleClose}
                />
                {t("Offer_Details")}
              </Typography>
              <div className="hLine"></div>
            </DialogTitle>
            <DialogContent className="notificationTabDetailPadding">
              <Box>
                <img
                  src={
                    offersPopup.coupon_image
                      ? offersPopup.coupon_image
                      : "assets/images/Rectangle.png"
                  }
                  alt="copyText"
                  className="popupImg"
                />
              </Box>
              <Box
                sx={{
                  background: "#F6F6F6",
                  padding: "15px 0px 15px 15px",
                  margin: "15px 0px",
                }}
              >
                <Typography className="offerDetailsHeading">
                  {offersPopup.event_name}
                </Typography>
              </Box>
              <Box className="flexGrid flexColumn">
                <item>
                  <Typography
                    className="offerCopyText"
                    sx={{ margin: "0px !important" }}
                  >
                    {" "}
                    {offersPopup.coupon_code}
                    <img
                      onClick={() => CopyCoupounCode(offersPopup.coupon_code)}
                      src="assets/images/copyText.png"
                      alt="copyText"
                      style={{ marginLeft: "12px", cursor: "pointer" }}
                    />
                  </Typography>
                </item>
                <item>
                  <Button
                    className="updateBtn"
                    sx={{ margin: "0px !important" }}
                    // onClick={(e)=>handleeventdetails(e, offersPopup.id)}
                    onClick={(e) => handleonviewallClick(offersPopup ,offersPopup.event_name)}
                  >
                    {t("Apply_On_Event")}
                  </Button>
                  <Typography
                    className="offerValiddate"
                    sx={{ margin: "10px 0px !important" }}
                  >
                    {t("Valid_till")} :{" "}
                    <span className="colorBlue">{offersPopup.end_date}</span>
                  </Typography>
                </item>
              </Box>
              <Box>
                <Accordion
                  expanded={expanded === "panel1"}
                  onChange={handleChange("panel1")}
                >
                  <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                  >
                    <Typography>{t("About_The_Offer")}:</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      {parse(
                        offersPopup.description
                          ? offersPopup.description
                          : "Description..."
                      )}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Box>
            </DialogContent>
          </Dialog>
        </div>

        {/* Offer Details popup */}
      </Grid>
    </Box>
  );
};

export default OffersList;
