import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import { Box, Divider, Typography } from "@mui/material";

import { Breadcrumbs } from "@mui/material";
import { Link, useParams } from "react-router-dom";

import http from "../../Services/http";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import "./Styles.css";
import { ColorRing } from "react-loader-spinner";

const PromotionalDetails = () => {
  useEffect(() => {
    getPromotionalDetails();
  }, []);

  const [PromotionalData, setGetPromotionalData] = useState({});
  const [isloading, setisLoading] = useState(false);
  const params = useParams();
  const parse = require("html-react-parser");

  const breadcrumbs = [
    <Link to={"/"} key="1" className="link-decoration">
      Home
    </Link>,

    <Typography
      key="3"
      sx={{
        fontFamily: "Lato",
        color: "#0CA8F6",
        fontWeight: 500,
        fontSize: "16px",
      }}
    >
      {PromotionalData.promotional_event_title}
    </Typography>,
  ];

  const getPromotionalDetails = () => {
    setisLoading(true);
    let postData = {
      event_id: params.id,
    };
    http("events/getPromotionalBannerDetails", "POST", postData).then((res) => {
      setisLoading(false);
      // console.log(res.data , 'details')
      setGetPromotionalData(res.data.data);
    });
  };

  return (
    <div>
      <Navbar />

      {isloading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            height: "70vh",
            alignItems: "center",
          }}
        >
          <ColorRing
            visible={true}
            height="100"
            width="100"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={["#b8c480", "#B2A3B5", "#F4442E", "#51E5FF", "#429EA6"]}
          />{" "}
        </Box>
      ) : Object.keys(PromotionalData).length > 0 ? (
        <Box>
          <Box className="mainContainer">
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
              sx={{ mb: 3, mt: 2 }}
            >
              {breadcrumbs}
            </Breadcrumbs>
          </Box>
          <img
            src={PromotionalData.event_web_banner}
            className="banner-details-banner-img"
            alt="banner"
          />
          <Box className="mainContainer">
            <Box> <Typography variant="h4" sx={{fontWeight:700}} > {PromotionalData.promotional_event_title} </Typography></Box>
            <Divider sx={{ mt: 2, mb: 2, color: "#D9D9D9" }} />
            <Box sx={{mb:4}}>{parse(PromotionalData.promotional_event_description)}</Box>
          </Box>
        </Box>
      ) : (
        <></>
      )}
      {/* {  Object.keys(PromotionalData).length >0  ? <Box>
        <img src={PromotionalData.event_web_banner}className="banner-details-banner-img"  alt="banner"/>
        <Box className="mainContainer">
        <Box>About Banner</Box>
        <Divider sx={{ mt:2 , mb:2 , color:'#D9D9D9'}} />
         <Box>
         {   parse(PromotionalData.promotional_event_description)}
         </Box>
        </Box>
        </Box> :<></> } */}
      <Footer />
    </div>
  );
};

export default PromotionalDetails;
