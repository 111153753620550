import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App.js";
import axios from "axios";
// import { useNavigate } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import {NotificationContainer} from "react-notifications";
import "react-notifications/lib/notifications.css";
import { StoreProvider } from "./useContext/walletContext";
import { Provider } from 'react-redux';
import store from './redux/Store'
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
import common_sw from "./translations/sw/common.json";
import common_en from "./translations/en/common.json";
import common_fr from "./translations/fr/common.json";
import common_pt from './translations/pt/common.json'
i18next.init({
  interpolation: { escapeValue: false },  // React already does escaping
  lng: localStorage.getItem('lang') || 'en',                              // language to use
    resources: {
        en: {
            common: common_en               // 'common' is our custom namespace
        },
        sw: {
            common: common_sw
        },
        fr:{
          common: common_fr
        },
        pt:{
          common: common_pt
        }
    }
});

axios.interceptors.request.use((request) => {
  let token = localStorage.getItem("token");
  if (token) {
    if (request.url.includes("user/loginOtpRequest/") && !token) return request;
    if (request.url.split("/")[2] == "ipapi.co") {
      request.headers = {
        "Content-Type": "application/json",
         timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };
    } else {
      request.headers = {
        authorisation: `${token}`,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        "Content-Type": "application/json",
      };
    }
  } else {
    request.headers = {
      //'authorisation': ` ${token}`,
      "Content-Type": "application/json",
      timezone:Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
  }

  return request;
});

axios.interceptors.response.use(
  (response) => {
    if (response.data.message_code === "tokenexpired") {
      localStorage.clear();
      sessionStorage.clear();
      window.location.reload();
      window.location.href = '/'
      return null;
    } else {
      return response;
    }
  },
  (error) => {
    if (error.response) {
      if (error.response.status === 401) {
          localStorage.clear();
        sessionStorage.clear();
 
        window.location.href = '/'
      } else {
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  }
);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  // <React.StrictMode>
    <Provider store={store}>
    <StoreProvider>
      <NotificationContainer />
      <I18nextProvider i18n={i18next}>
      <App />
      </I18nextProvider>
    </StoreProvider>
    </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
