import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import Carousel from "react-elastic-carousel";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; 
import http from "../../Services/http"; 
import { NotificationManager } from "react-notifications";
import { ManagePageReloadState } from "../../Services/ManagePageReloadState"; 
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import CloseIcon from "@mui/icons-material/Close";
   
import "./style.css"; 
import { Modal, Snackbar } from "@mui/material";
import SocialSharePopup from "../SocialSharePopup";

const breakPoints = [
  { width: 1, itemsToShow: 2, itemPadding: [10, 3] },
  { width: 550, itemsToShow: 3, itemPadding: [10, 5] },
  { width: 768, itemsToShow: 4, itemPadding: [10, 8] },
  { width: 1200, itemsToShow: 5, itemPadding: [10, 12] },
];
  
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(0),
  color: theme.palette.text.secondary,
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "95%",
  maxWidth: 600,
  bgcolor: "#FFFFFF",
  boxShadow: 24,
  overflow: "auto",
};

const PrivateEvent = (props) => { 
  const [upcomingevent, setupcomingevent] = useState([]); 
  const [seletedeventID, setseletedeventID] = useState(0);
  const [show, setShow] = React.useState(false); 
  const[eventName , setEventName]  = useState('')
  const [open1, setOpen1] = React.useState(false); 
  const history = useNavigate();
  const handleClose1 = () => setOpen1(false);
  const dateFormated = (date) => {
    let dateNew = new Date(date);
    let day = dateNew.toLocaleString("en-us", { weekday: "short" });
    let newdate = new Date(date).getDate();
    let month = dateNew.toLocaleString("default", { month: "short" });
    return day + "," + newdate + " " + month;
  }; 
  const checkLoggedIn = (e, params , event_name) => {
    let modifyEventName = event_name.replaceAll(/[^a-zA-Z0-9 ]/g , "")
    setseletedeventID(params.id)
    setOpen1(true);
      setEventName(modifyEventName.replaceAll(' ' , '-'))
  };

  useEffect(() => {
   
    getUE(); 
    ManagePageReloadState.getPageReloadState().subscribe(res =>{
      if(res.state)
        getUE()
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const getUE = () => {
    // setIsLoading(true);
    let postData = {
      country_id: localStorage.getItem("autoCountry") ? JSON.parse(localStorage.getItem("autoCountry")).id : '' , 
      city: localStorage.getItem('SelectedCity') ? JSON.parse(localStorage.getItem('SelectedCity')).id : "",
      keyword: "",
    };
    http("events/private_events", "POST", postData).then(({ data }) => {  
      if (data.status) { 
        setupcomingevent(data.data);
        
      } else {
        setupcomingevent([]);
      } 
      // arrow of slider condition based 
      
      if (data.length > 5) {
        setShow(true);
      } else {
        setShow(false);
      }
    });
  };
  


  const handleeventdetails = (e, id, event_name) => {
    let modifyEventName = event_name.replaceAll(/[^a-zA-Z0-9 ]/g , "")
    history(
       
      `/events/${modifyEventName.replaceAll(' ', '-')}/${id}?ts=PrivateEvent&flag=${JSON.parse(localStorage.getItem("autoCountry")).sortname}`
    );
  };

  const handleonviewallClick = (e, id) => {
    history(`/ViewAll/${"PrivateEvent"}/${"?flag=" + JSON.parse(localStorage.getItem("autoCountry")).sortname}`, {
      state: { id: 4, name: "Private Event" },
    });
  };
  const Favourites = (data) => {
    let postData = {
      eventid: data.id,
    };
    http("events/favourite_event", "POST", postData).then((res) => { 
      if (
        res.data.msg === "success! event is added in your favourite section"
      ) {
        getUE() 
        NotificationManager.success(res.data.msg, '', 800);
      } else {
        getUE()   
        NotificationManager.success("event is removed in your favourite section", '', 800);   
      }
    });
  };

  const [CCBoardPopUp, setCCBoardPopUp] = useState(false);
  
  const CopiedToclipBoardPopUp = (data) => { 
  
  setOpen1(data)
  setCCBoardPopUp(true)
};



  const handleCloseCpy = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  setCCBoardPopUp(false);
};

const action = (
  <React.Fragment>
 
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleCloseCpy}
    >
     
      <CloseIcon fontSize="small" />
    </IconButton>
  </React.Fragment>
);


  return (
    <Box className="mainContainer">
      {upcomingevent.length > 0 && (
        <Grid container>
          <Grid item md={6} xs={8} lg={6}>
            <Item Item sx={{ textAlign: "left" }} className="boxShadowNone">
              <Typography
                style={{
                  fontFamily: "Lato",
                  fontWeight: "700",
                  fontSize: "22px",
                  color: "#2C2C2C",
                  cursor: "pointer",
                }}
              >
                Private Event
              </Typography>
            </Item>
          </Grid>

          {upcomingevent.length > 4 ? (
            <Grid item md={6} xs={4} lg={6}>
              <Item Item sx={{ textAlign: "right" }} className="boxShadowNone">
                <span
                  style={{
                    border: "1px solid #0CA8F6",
                    display: "inline-flex",
                    color: "#0CA8F6",
                    borderRadius: "6px",
                    width: "92px",
                    justifyContent: "flex-end",
                    height: "32px",
                    alignItems: "center",
                  }}
                >
                  <button
                    style={{
                      border: "none",
                      background: "transparent",
                      color: "#0CA8F6",
                      fontWeight: "400",
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                    className="mobViewAllBtn"
                    onClick={handleonviewallClick}
                  >
                    View All{" "}
                  </button>

                  <KeyboardArrowRightOutlinedIcon />
                </span>
              </Item>
            </Grid>
          ) : (
            ""
          )}

          <Grid item lg={12} sx={{ width: "100%", padding: "20px 0px" }}>
            <div className="styling-example">
              <Carousel
                breakPoints={breakPoints}
                // renderArrow={myArrow}
                itemPosition={"START"}
                showEmptySlots
                spacing={3}
                showArrows={show}
                disableArrowsOnEnd={true}
              >
                {upcomingevent.map((data, index) => (
                  <Box key={index}>
                    <Item
                      className="boxShadowNone"
                      style={{ cursor: "pointer" }}
                    >
                      <div className="CardDiv" style={{ position: "relative" }}>
                        <CardMedia
                          component="img"
                          image={
                            data.event_thumbnail
                              ? data.event_thumbnail
                              : "assets/images/Rectangle.png"
                          }
                          alt="Paella dish"
                          onClick={(e) => handleeventdetails(e, data.id , data.event_name)}
                        />
                        <Box className="promotion-strip">Private</Box>
                        {/* {parseInt(data.favourite) === 1 ? (
                          <IconButton
                            aria-label="add to favorites"
                            style={{
                              backgroundColor: "red",
                              color: "#FFFFFF",
                              position: "absolute",
                              right: "8px",
                              bottom: "8px",
                            }}
                            className="AbsoluteClass"
                            onClick={() =>  Favourites(data)}
                          >
                            <FavoriteBorderIcon
                             
                            />
                          </IconButton>
                        ) : parseInt(data.favourite) === 0 ? (
                          <IconButton
                            aria-label="add to favorites"
                            style={{
                              backgroundColor: "#2C2C2C",
                              color: "#FFFFFF",
                              position: "absolute",
                              right: "8px",
                              bottom: "8px",
                            }}
                            className="AbsoluteClass"
                            onClick={() => Favourites(data)}
                          >
                            <FavoriteBorderIcon  />
                          </IconButton>
                        ) : (
                          ""
                        )} */}



{/* {data.favourite === 1 ? (
                            <FavoriteIcon
                              className=" fav_Icon AbsoluteClass"
                              style={{
                                position: "absolute",
                                right: "8px",
                                bottom: "8px",
                              }}
                              onClick={() =>  Favourites(data)}
                            />
                          ) : data.favourite === 0 ? (
                            <FavoriteBorderIcon
                              style={{
                                color: "#fff",
                                position: "absolute",
                                right: "8px",
                                bottom: "8px",
                              }}
                              onClick={() =>  Favourites(data)}
                            />
                          ) : (
                            ""
                          )} */}

                      </div>

                      <CardContent
                        style={{
                          display: "flex",
                          alignItems: "baseline",
                          justifyContent: "space-between",
                          padding: "0px",
                          cursor: "pointer",
                          position: "relative",
                        }}
                      >
                        <Typography
                          onClick={(e) => handleeventdetails(e, data.id,data.event_name)}
                          className="sliderCartText"
                        >
                          {data.event_name}
                        </Typography>
                        <IconButton
                          aria-label="share"
                          className="IconButton"
                          onClick={(e) => checkLoggedIn(e, data , data.event_name)}
                        >
                          <ShareOutlinedIcon />
                        </IconButton>
                      </CardContent>
                      <Typography
                        style={{
                          fontWeight: "400",
                          fontSize: "14px",
                          color: "#686868",
                          padding: "10px 0px",
                        }}
                      >
                        {dateFormated(data.event_date)} {data.date_range.length>10 ? 'Onwards' : data.event_time}  
                      </Typography>
                    </Item>
                  </Box>
                ))}
              </Carousel>
            </div>
          </Grid>
        </Grid>
      )} 

            {/* ShareMmodal Start */}

            <Modal
        open={open1}
        onClose={handleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="ModalClass"
      >
        <Box sx={style} className="ModalClassBox">
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{ textAlign: "right", padding: "0px 16px" }}
              className="ModalTopGrid"
            >
              <CloseIcon className="closeIconOffer" onClick={handleClose1} />
              <Typography
                id="modal-modal-description"
                className="ModalPhomolong"
                sx={{ textAlign: "center" }}
              >
                Share with Social Media{" "}
              </Typography>
               
            {localStorage.getItem("autoCountry") ? (<SocialSharePopup CopiedToclipBoardPopUp={CopiedToclipBoardPopUp} ShareUrl={`/events/${eventName}` + seletedeventID + "?ts=PrivateEvent&flag=" + JSON.parse(localStorage.getItem("autoCountry")).sortname}/>) : ""}
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <Snackbar
    open={CCBoardPopUp}
    autoHideDuration={1000}
    onClose={handleCloseCpy}
    message="Copied to Clipboard"
    action={action}
    anchorOrigin={{vertical:"bottom" , horizontal:"center"}} 
  />
    </Box>
  );
};
export default PrivateEvent;
