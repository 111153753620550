import React, { useEffect, useState, useRef } from "react";
import Navbar from "../../Components/Navbar";
//import BreadCrumbs from "../../Components/BreadCrumbs";
import Banner from "../../Components/Banner";
import AboutEvent from "../../Components/AboutEvent";
import Interested from "../../Components/Interested";
import ArtistDetails from "../../Components/ArtistDetails";
import AllReview from "../../Components/AllReview";
import TopReview from "../../Components/TopReview";
import TermsCondition from "../../Components/TermsCondition";
import Footer from "../../Components/Footer";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Breadcrumbs, Typography } from "@mui/material";
import { Box } from "@mui/system"; 
import { 
  Link,
  useParams,
  useLocation, 
  useNavigate,
} from "react-router-dom";
import http from "../../Services/http";
import { NotificationManager } from "react-notifications";
import ClipLoader from "react-spinners/ClipLoader";
import "./style.css";
import { isEventsExpired } from "../../store/action/actionTypes";
import { useDispatch } from "react-redux";
import ModalPopup from "../../Components/ModalPopup"; 
import Recommended from "../../Components/Recommended";
import {useTranslation} from 'react-i18next'
import axios from "axios";

const EventDetails = () => {
   const {t} = useTranslation('common')
  const navigate = useNavigate();
 const dispatch = useDispatch();
   const [eventdetailspage1, seteventdetailspage1] = useState([]);
  const [getreviewid, setGetreviewid] = useState([]);
  const [allReviewdata, setallReviewdata] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [getData, setGetdata] = useState("");
  const [eventName, setEventName] = useState("")
  
  const params = useParams();
   
  useEffect(() => {
 
    if (!renderAfterCalled.current) {
      EventsByid();
    }
    // renderAfterCalled.current = true;
    if(query.get("ts") === "PrivateEvent" && !localStorage.getItem('loginUser')){
     setOpenLoginPopup(true)
    }else{
      setOpenLoginPopup(false)
    }
  }, [params]);


  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();
 
  
  // Events By Events Id
  const EventsByid = () => {
    setIsLoading(true);
    let postData = {};
    let url = "";
    if (query.get("ts") === "ts") {
      postData = {
        event_id: params.id,
      };
      url = "talent_participation_events/talent_participation_details";
    } else if (query.get("ts") === "cs") {
      postData = {
        event_id: params.id,
      };
      url = "coming_soon/coming_soon_events_details";
    } else if (query.get("ts") === "PrivateEvent") {
      postData = {
        event_type_id: params.id,
        country_id: localStorage.getItem("autoCountry") ? JSON.parse(localStorage.getItem("autoCountry")).id : '' ,
        search: "",
        city_id: "",
      };
      url = "events/event_by_event_id";
    } else {
      postData = {
        event_type_id: params.id,
        country_id: localStorage.getItem("autoCountry") ? JSON.parse(localStorage.getItem("autoCountry")).id : '' ,
        search: "",
        city_id: "",
      };
      url = "events/event_by_event_id";
    }

    http(url, "POST", postData).then((res) => {
      setIsLoading(false);

      const data = res.data.data ?? [];

      if (res.data.status === 1) {
        //  NotificationManager.success(res.data.msg);
        if (query.get("ts") === "ts") {
          data.ts = "ts";
        } else if (query.get("ts") === "cs") {
          data.ts = "cs";
        } else if (query.get("ts") === "PrivateEvent") {
          data.ts = "PrivateEvent";
        }
        dispatch(isEventsExpired(data.eventExpireFlag));
        seteventdetailspage1(data);
        if(localStorage.getItem('lang') && localStorage.getItem('lang')!== 'en'){
          getLanguageSource(data)
        }
        setEventName(data?.event_name)
        
      } else {
        setIsLoading(false);
        NotificationManager.error(res.data.msg);

        navigate("/home");
      }
    });
  };

  useEffect(()=>{
    if(localStorage.getItem('lang'))
    getLanguageSource(eventdetailspage1)
  },[localStorage.getItem('lang')])

  const[OpenLoginPopup, setOpenLoginPopup] = useState(false)
  const renderAfterCalled = useRef(false);

  const getreviewfromchild = (data) => {
    setGetreviewid(data);
  };

  const parentlike = (data) => {
    EventsByid();
  };

 

  const getIdUser = (data) => {
    setallReviewdata(data);
  };

   // Data get Child Post Child
  const dataget = (data) => { 
    setGetdata(data);
  };

  const getLanguageSource = async(dataObject) => {
    let fromLang = '';
    let toLang = localStorage.getItem('lang'); // translate to norwegian
    let text = 'something to translate';
    
    const API_KEY = 'AIzaSyB9GtFt4JKfmKJuQTe3y2Gr8fe_CY62fqg';
    
    let url = `https://translation.googleapis.com/language/translate/v2?key=${API_KEY}`;
    // url += `&q=[${encodeURI(dataObject?.event_name)},${encodeURI(dataObject?.city_name)},${encodeURI(dataObject?.event_city_name)},
    // ,${encodeURI(dataObject?.event_description)},${encodeURI(dataObject?.venue_address)},${encodeURI(dataObject?.venue_name)}]`;
    // url += `&source=${fromLang}`;
    // url += `&target=${toLang}`;

    const eventLocationData = []
    dataObject?.allEventLocationAndDateTime.forEach((eventLocation,idx)=>{
      const temp = {}
      const tempArr = [ eventLocation?.city_name,
        eventLocation?.venue_address,
        eventLocation?.venue_name,
        eventLocation?.province_name]
        temp[idx] = tempArr
        eventLocationData.push(temp)
    })
    const response = await fetch(url, {
      body:JSON.stringify({
        "q": [dataObject?.event_name,
          dataObject?.city_name,
          dataObject?.event_city_name,
          dataObject?.event_description,
          dataObject?.venue_address,
          dataObject?.venue_name,
          dataObject?.event_terms_condition,
          ...eventLocationData.map((eventLocationTData,idx)=>{
            return eventLocationTData[idx]
          })
        ],
        "target": localStorage.getItem('lang')
      }), 
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      }
    })
    const data  = await response.json()
    
    const data1 = data?.data?.translations
    let startCount = 7
    let tempDataLocations = {}
    const locationTimeDataFinal = []
    dataObject?.allEventLocationAndDateTime.forEach((eventLocation,idx)=>{
      tempDataLocations = {...eventLocation}
      tempDataLocations.city_name = data1[startCount].translatedText;
      startCount = startCount + 1
      tempDataLocations.venue_address = data1[startCount].translatedText;
      startCount = startCount + 1
      tempDataLocations.venue_name = data1[startCount].translatedText;
      startCount = startCount + 1
      tempDataLocations.province_name = data1[startCount].translatedText;
      startCount = startCount + 1
      locationTimeDataFinal.push(tempDataLocations)
    })
    console.log("data1",data1)
    seteventdetailspage1({
      ...dataObject,
       event_name: data1[0].translatedText || dataObject?.event_name,
       city_name:data1[1].translatedText || dataObject?.city_name,
       event_city_name:data1[2].translatedText || dataObject?.event_city_name,
       event_description:data1[3].translatedText || dataObject?.event_description,
       venue_address:data1[4].translatedText || dataObject?.venue_address,
       venue_name:data1[5].translatedText || dataObject?.venue_name,
       event_terms_condition:data1[6].translatedText || dataObject?.event_terms_condition,
       allEventLocationAndDateTime:locationTimeDataFinal
      });
 
    
}

  const breadcrumbs = [
    <Link to={"/"}  key="1" className="link-decoration">
     {t('home')}
    </Link>,

    <Typography
      key="3"
      sx={{
        fontFamily: "Lato",
        color: "#0CA8F6",
        fontWeight: 500,
        fontSize: "16px",
      }}
    >
      
      {eventdetailspage1?.event_name ?
       eventdetailspage1?.event_name :
        t("event_details")}
 </Typography>,
  ];

  return (
    OpenLoginPopup ? <ModalPopup handleOpen={true} handleClick={false} /> :
    <>
      <Navbar />
      {/* <MenuBar /> */}
      <Box className="mainContainer">
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          sx={{ mb: 3, mt: 2 }}
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Box>

      {isLoading ? (
        <div className="loader">
          <ClipLoader />
        </div>
      ) : (
        <>
          {eventdetailspage1.length !== 0 ? (
            <>
              <Banner  eventdetailspage1={eventdetailspage1} />

              <AboutEvent
                eventdetailspage1={eventdetailspage1}
                alreadyRegister={eventdetailspage1.talent_register_flag}
              />

              {query.get("ts") === "ts" ? (
                ""
              ) : query.get("ts") === "cs" ? (
                <Interested
                  eventdetailspage1={eventdetailspage1}
                  parentlike={parentlike}
                />
              ) : (
                <>
                  <Interested
                    eventdetailspage1={eventdetailspage1}
                    parentlike={parentlike}
                  />
                  <ArtistDetails eventdetailspage1={eventdetailspage1} />
                  <AllReview
                    getreviewid={getreviewid}
                    eventdetailspage1={eventdetailspage1}
                    dataget={dataget}
                  />  
               {   eventdetailspage1.eventExpireFlag !== 0 ? 
               <>
                  <TopReview
                  getreviewfromchild={getreviewfromchild}
                  getIdUser={getIdUser}
                  getData={getData}
                  dataget={dataget}
                  eventdetailspage1={eventdetailspage1}
                  getreviewid={getreviewid}
                />
                </>
                  : <></>}
                 
                  <TermsCondition eventdetailspage1={eventdetailspage1} />
                  {eventdetailspage1.similar_events === 1 ?  <Recommended eventID = {params.id} /> : <></>}          
                </>
              )}
              {/* <BottomScroll/> */}

              <Footer />
            </>
          ) : null}
        </>
      )}
    </>
  );
};

export default EventDetails;

