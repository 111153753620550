import React, { createContext, useState } from "react";

const StoreContext = createContext();

const StoreProvider = (props) => {
  const [headerTitle, setHeaderTitle] = useState("");
   

  return (
    <StoreContext.Provider
      value={{
        headerTitle,
        setHeaderTitle,
      }}
    >
      {props.children}
    </StoreContext.Provider>
  );
};

export { StoreContext, StoreProvider };
