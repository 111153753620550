import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import "./style.css";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { json, useLocation } from "react-router-dom";
import http from "../../Services/http";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import getSymbolFromCurrency from "currency-symbol-map";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 600,
  bgcolor: "#FFFFFF",
  boxShadow: 24,
  p: 4,
  width: "95%",
};

const SuperMart = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Modal for VendorIdentify
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Modal for OrderCheckSubmit
  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);

  const currency = location.state.data.vendor_food_list[0].currency
    ? location.state.data.vendor_food_list[0].currency
    : "";
  const Dynamiccuurency = getSymbolFromCurrency(currency);
  const vendorCartList = location.state.data.vendor_food_list;
  const vendorCartLocal = JSON.parse(localStorage.getItem("cartValue"));

  const finalVendorList = vendorCartLocal
    ? vendorCartList.map(
        (obj) => vendorCartLocal.find((o) => o.id === obj.id) || obj
      )
    : [];

  const [vendorfoodlistdata, setvendorfoodlistdata] = useState(
    finalVendorList.length > 0 ? finalVendorList : vendorCartList
  );

  const Previousid = location.state.data.v_user_id;
  const PreviousVendorName = location.state.data.vendor_name;
  const commisionfood = location.state.commisiononfood;
  const BookingorderNumber = location.state.orderNumber;
  const locid = location.state.locid;
  location.state.location_id = location.state.locid;
  location.state.commission_on_food = location.state.commisiononfood;
  const Eventid = location.state.data.event_id;
 
  const totalAmountArrayLocal = localStorage.getItem("totalAmountArr")
    ? JSON.parse(localStorage.getItem("totalAmountArr"))
    : [];
  const localVendorName = localStorage.getItem("nextVendorName")
    ? localStorage.getItem("nextVendorName")
    : totalAmountArrayLocal.vendorLocalName
    ? totalAmountArrayLocal.vendorLocalName[0]
    : PreviousVendorName;



  const localVendorID = totalAmountArrayLocal.vendorIDLocal
    ? totalAmountArrayLocal.vendorIDLocal[0]
    : Previousid;


  const [vendorid, setVendorid] = useState("");
  const [count, setCount] = useState(1);
  const [totalamount, setTotalamount] = useState(0);
  const [totalitemcount, setTotalitemcount] = useState(0);
  const [searchfoodData, setSearchfoodData] = useState([]);
  const [filters, setFilter] = useState("");
  const FoodSearchPage = (e) => {
    navigate("/foodsearch", {
      state: {
        FoodSearch: location.state,
        event: Eventid,
        addtocart: vendorfoodlistdata,
      },
    });
  };
  const grandtotal = () => {
    const totalAmountArray = localStorage.getItem("totalAmountArr")
      ? JSON.parse(localStorage.getItem("totalAmountArr"))
      : [];
    const localVendorID = totalAmountArray.vendorIDLocal
      ? totalAmountArray.vendorIDLocal[0]
      : Previousid;

    if (totalAmountArray && localVendorID != Previousid) {
      let totalarray = totalAmountArray.grandTotal;
      let totalitemcount = totalAmountArray.grantTotalItem;
      setTotalamount(totalarray);
      setTotalitemcount(totalitemcount);
    } else {
      let totalarray = 0;
      let totalitemcount = 0;
      vendorfoodlistdata.map((item) => {
        totalarray += item.total;
        totalitemcount += item.count;
      });

      setTotalamount(totalarray);
      setTotalitemcount(totalitemcount);
    }
  };
  const grandtotalSearchFood = () => {
    let totalarray = 0;
    let totalitemcount = 0;
    searchfoodData.map((item) => {
      totalarray += item.total;
      totalitemcount += item.count;
    });
    setTotalamount(totalarray);
    setTotalitemcount(totalitemcount);
  };
  const [vendorcheckid, setVendorcheckid] = useState("");
  const HandleAddSearch = (e, index) => {
    const newarray = [...searchfoodData];

    if (newarray[index].vendor_id !== Previousid) {
      setOpen(true);
    } else {
      newarray[index].count = 1;
      newarray[index].select = true;
      newarray[index].total =
        parseFloat(newarray[index].count) *
        parseFloat(newarray[index].food_price);
      setVendorcheckid(newarray[index].vendor_id);
      setSearchfoodData(newarray);
      grandtotalSearchFood();
    }
  };

  const HandleReplace = () => {
    // alert("vivek")
    setOpen(false);
    localStorage.removeItem("cartValue");
    localStorage.removeItem("totalAmountArr");

    
    localStorage.removeItem('nextvendorId');
    localStorage.removeItem('nextVendorName')
    setTotalamount(0);
    setTotalitemcount(0);
    HandleAddAfterReplace();
  };

  const HandleAddSearchModal = (e, index) => {
    setOpen(false);
    const newarray = [...searchfoodData];
    newarray[index].count = 1;
    newarray[index].select = true;
    newarray[index].total =
      parseFloat(newarray[index].count) *
      parseFloat(newarray[index].food_price);
    setVendorcheckid(newarray[index].vendor_id);
    setSearchfoodData(newarray);
    grandtotalSearchFood();
  };

  const AddonscountSearch = (e, index) => {
    const newarray = [...searchfoodData];
    newarray[index].count = newarray[index].count + 1;
    newarray[index].total =
      parseFloat(newarray[index].count) *
      parseFloat(newarray[index].food_price);

    setCount(count + 1);
    setSearchfoodData(newarray);
    grandtotalSearchFood();
  };

  const AddonscountMinusSearch = (e, index) => {
    const newarray = [...searchfoodData];
    newarray[index].count = newarray[index].count - 1;
    newarray[index].total =
      parseFloat(newarray[index].count) *
      parseFloat(newarray[index].food_price);
    setCount(count - 1);
    setSearchfoodData(newarray);
    grandtotalSearchFood();
  };

  const [vendorIndex, setVendorIndex] = useState();

  // start of function to open model by condition
  const openModelOnClickAdd = (index) => {
    // alert("vivek")
    const totalAmountArray = localStorage.getItem("totalAmountArr")
      ? JSON.parse(localStorage.getItem("totalAmountArr"))
      : [];
    const previousVendorIdBySearch = localStorage.getItem("nextvendorId")
      ? localStorage.getItem("nextvendorId")
      : "";
    const localVendorID = previousVendorIdBySearch
      ? previousVendorIdBySearch
      : totalAmountArray.vendorIDLocal
      ? totalAmountArray.vendorIDLocal[0]
      : Previousid;
    if (localVendorID != Previousid) {
      setOpen(true);
      setVendorIndex(index);
    } else {
      HandleAdd(index);
    }
  };
  // end of function to open model by condition

  // Normal Count Function Starts From Here
  const HandleAdd = (index) => {
    const newarray = [...vendorfoodlistdata];
    newarray[index].count = 1;
    newarray[index].select = true;
    newarray[index].total =
      parseFloat(newarray[index].count) *
      parseFloat(newarray[index].food_price);
    newarray[index].vendor_name = PreviousVendorName;
    setVendorid(newarray[index].vendor_id);
    setvendorfoodlistdata(newarray);
    grandtotal();
    addDataToLocalStorage(newarray);
  };

  const HandleAddAfterReplace = () => {
    const newarray = [...vendorfoodlistdata];
    newarray[vendorIndex].count = 1;
    newarray[vendorIndex].select = true;
    newarray[vendorIndex].total =
      parseFloat(newarray[vendorIndex].count) *
      parseFloat(newarray[vendorIndex].food_price);
    newarray[vendorIndex].vendor_name = PreviousVendorName;
    setVendorid(newarray[vendorIndex].vendor_id);
    setvendorfoodlistdata(newarray);
    grandtotal();
    addDataToLocalStorage(newarray);
  };

  // start of  function to add cart in localstorage

  const addDataToLocalStorage = (arrName) => {
    localStorage.setItem("cartValue", JSON.stringify(arrName));
  
    let totalarraySelect = 0;
    let totalitemCountSelect = 0;
    arrName.forEach((element) => {
      totalarraySelect += element.total;
      totalitemCountSelect += element.count;
    });
    const vendorIDLocal = arrName.map((elem) => {
      return elem.vendor_id;
    });
    const vendorNameLocal = arrName.map((elem) => {
      return elem.vendor_name;
    });

    // const companyName = arrName.map((elem) => {
    //   return elem.company_name;
    // });

    const grandTotalObj = {
      grandTotal: totalarraySelect,
      grantTotalItem: totalitemCountSelect,
      vendorIDLocal: vendorIDLocal,
      vendorLocalName: vendorNameLocal
      // companyName:companyName
    };
    localStorage.setItem("totalAmountArr", JSON.stringify(grandTotalObj));
  };

  // end of function to add cart in localstorage

  const Addonscount = (index) => {
    const newarray = [...vendorfoodlistdata];
    newarray[index].count = newarray[index].count + 1;
    newarray[index].total =
      parseFloat(newarray[index].count) *
      parseFloat(newarray[index].food_price);
    newarray[index].vendor_name = PreviousVendorName;
    setCount(count + 1);
    setvendorfoodlistdata(newarray);
    grandtotal();
    addDataToLocalStorage(newarray);
  };

  const AddonscountMinus = (index) => {
    const newarray = [...vendorfoodlistdata];
    newarray[index].count =
      newarray[index].count <= 0 ? 0 : newarray[index].count - 1;
    newarray[index].total =
      parseFloat(newarray[index].count) *
      parseFloat(newarray[index].food_price);
    newarray[index].vendor_name = PreviousVendorName;
    setCount(count - 1);
    newarray[index].select = newarray[index].count <= 0 ? false : true;
    setvendorfoodlistdata(newarray);
    grandtotal();
    addDataToLocalStorage(newarray);
  };
  // Normal Count Function End Here

  useEffect(() => {
    const copyarray = [...vendorfoodlistdata];
    if (localStorage.getItem("cartValue")) {
      copyarray.map((ele) => {
        if (ele.total > 0 && ele.count > 0) {
        } else {
          ele.total = 0;
          ele.count = 0;
        }
      });
      setvendorfoodlistdata(copyarray);
      grandtotal();
    } else {
      copyarray.map((ele) => {
        ele.total = 0;
        ele.count = 0;
        ele.select = false;
      });
      setvendorfoodlistdata(copyarray);
    }
  }, []);

  const Orderconfirm = (e) => {
    let finalValueData = localStorage.getItem("cartValue")
      ? JSON.parse(localStorage.getItem("cartValue"))
      : vendorfoodlistdata;

    let newArr = [];
    finalValueData.forEach((element) => {
      if (element.select === true) {
        newArr.push(element);
      }
    });

    if (newArr.length > 0) {
      handleOpen1();
    } else {
      NotificationManager.error("Please select atleast one item", "", 1000);
    }
  };

  const foodorder = () => {
    let arr = [];
    if (Previousid == localVendorID) {
      vendorfoodlistdata.forEach((ele) => {
        let obj = {};
        if (ele.select == true) {
          obj.item_quantity = ele.count;
          obj.per_item_price = ele.food_price;
          obj.total_price = ele.total;
          obj.item_id = ele.id;
          obj.food_item_id = ele.food_item_id;
          arr.push(obj);
        }
      });
    } else {
      const localStorageCartValue = JSON.parse(
        localStorage.getItem("cartValue")
      );
      localStorageCartValue.forEach((ele) => {
        let obj = {};
        if (ele.select == true) {
          obj.item_quantity = ele.count;
          obj.per_item_price = ele.food_price;
          obj.total_price = ele.total;
          obj.item_id = ele.food_cat_id;
          obj.food_item_id = ele.food_item_id;
          arr.push(obj);
        }
      });
    }

    const postData = {
      food_total_amount_without_service_fee: totalamount,
      service_fee_amount: CommissionServiceTax,
      service_fee_percentage: commisionfood,
      vendor_id: Previousid == localVendorID ? Previousid : localVendorID,
      final_food_amount: GrandTotalAmount,
      event_id: location.state.event,
      location_id: locid,
      event_booking_number: BookingorderNumber,
      items: arr,
    };
    http("event_food/order_event_food", "POST", postData).then((res) => {
      if (res.data.status === 1) {
        NotificationManager.success(res.data.msg);
        localStorage.removeItem("cartValue");
        localStorage.removeItem("totalAmountArr");
        navigate(
          `/OrderDetails/${
            location.state.orderid ? location.state.orderid : ""
          }`
        );
      } else {
        NotificationManager.error(res.data.msg);
      }
    });
  };

  const Searchfoodorder = (value) => {
    setFilter(value);
    const postData = {
      event_id: Eventid,
      location_id: locid,
      keyword: value,
      vendor_id: vendorid,
    };
    http("event_food/event_food_search", "POST", postData).then((res) => {
      if (res.data.msg == "Searching item found successfully.") {
        res.data.data.map((ele) => {
          ele.total = 0;
          ele.count = 0;
        });
        setSearchfoodData(res.data.data);
      } else {
        // NotificationManager.error(res.data.msg)
      }
    });
  };
  const CommissionServiceTax = (commisionfood * totalamount) / 100;
  const GrandTotalAmount = totalamount + CommissionServiceTax;
  return (
    <Box className="mainContainer">
      <Grid container alignItems="center">
        <Grid item xs={12} sm={6} md={6}>
          <Typography className="layoutTitle d-flex justify-align-item">
            {location.state.data.company_name
              ? location.state.data.company_name
              : location.state.data.vendor_name}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} md={6} className="searchEventFav">
          <div>
            <input
              type="text"
              class="input-bordered inputSearch"
              style={{ float: "right" }}
              placeholder="Search"
              onFocus={(e) => {
                FoodSearchPage(e);
              }}
            />
          </div>
        </Grid>
        <div className="hLine"></div>
      </Grid>

      {/* <Grid spacing={3} container sx={{ marginTop: "0px", mb: 12 }}>
        {searchfoodData
          .filter((val) => {
            if (filters === "") {
              return val;
            } else if (
              val.food_title.toLowerCase().includes(filters?.toLowerCase())
            ) {
              return val;
            }
          })
      
          .map((ele, index) => (
            <Grid item xs={12} md={6} lg={6} sx={{ marginBottom: "10px" }}>
              <Typography>{ele.vendor_name}</Typography>
              <Box className="SuperMartBox">
                <img
                  src={
                    ele.item_image
                      ? ele.item_image
                      : "assets/images/grredSalad.png"
                  }
                  alt="googleImg"
                  style={{ width: "120px", height: "120px", borderRadius: '10px' }}
                />

                <div className="SuperMartInnDiv">
                  <Typography className="VegSaladTyp">
                    {ele.food_title}
                  </Typography>
                  <Typography className="VegPlatterTypo">
                    {ele.item_name}
                  </Typography>
                  <div className="SuperPlusMin">
                    <Typography className="SuperPrice">
                      Price:{" "}
                      <span style={{ color: "rgba(12, 168, 246, 1)" }}>
                        {" "}
                        {Dynamiccuurency} {ele.food_price}{" "}
                      </span>
                    </Typography>

                    {ele.select && ele.count > 0 ? (
                      <>
                        <Button
                          disabled={count <= 1 ? true : false}
                          aria-label="reduce"
                          onClick={(e) => {
                            AddonscountMinusSearch(e, index);
                          }}
                          sx={{
                            padding: "0px !important",
                            margin: "0px !important",
                          }}
                        >
                          <img src="assets/images/minus.png"></img>
                        </Button>
                        <div className="detailBottomL"></div>
                        <Box className="Textnumbers">{ele.count}</Box>
                        <Button
                          aria-label="increase"
                          onClick={(e) => AddonscountSearch(e, index)}
                          sx={{
                            padding: "0px !important",
                            margin: "0px !important",
                          }}
                        >
                          <img src="assets/images/plus.png"></img>
                        </Button>
                      </>
                    ) : (
                      <Button
                        className="SuperBtn"
                        onClick={(e) => HandleAddSearch(e, index)}
                      >
                        Add
                      </Button>
                    )}
                  </div>
                </div>
              </Box>
            </Grid>
          ))}
      </Grid> */}

      <Grid container spacing={3} className="">
        {vendorfoodlistdata.map((ele, index) => (
          <Grid item xs={12} md={6} lg={6} sx={{ marginBottom: "10px" }}>
            <Box className="SuperMartBox">
              <img
                src={
                  ele.item_image
                    ? ele.item_image
                    : "assets/images/grredSalad.png"
                }
                alt="googleImg"
                style={{
                  width: "120px",
                  height: "120px",
                  borderRadius: "10px",
                }}
              />

              <div className="SuperMartInnDiv">
                <Typography className="VegSaladTyp">
                  {ele.food_title}
                </Typography>
                <Typography className="VegPlatterTypo">
                  {ele.item_name}
                </Typography>

                <div className="SuperPlusMin">
                  <Typography className="SuperPrice">
                    Price:{" "}
                    <span style={{ color: "rgba(12, 168, 246, 1)" }}>
                      {" "}
                      {Dynamiccuurency} {ele.food_price}{" "}
                    </span>
                  </Typography>

                  {ele.select ? (
                    <>
                      <Button
                        aria-label="reduce"
                        onClick={() => {
                          AddonscountMinus(index);
                        }}
                        sx={{
                          padding: "0px !important",
                          margin: "0px !important",
                        }}
                      >
                        <img src="assets/images/minus.png"></img>
                      </Button>
                      <div className="detailBottomL"></div>
                      <Box className="Textnumbers">{ele.count}</Box>
                      <Button
                        aria-label="increase"
                        onClick={() => Addonscount(index)}
                        sx={{
                          padding: "0px !important",
                          margin: "0px !important",
                        }}
                      >
                        <img src="assets/images/plus.png"></img>
                      </Button>
                    </>
                  ) : (
                    <Button
                      className="SuperBtn"
                      onClick={() => {
                        openModelOnClickAdd(index);
                      }}
                    >
                      Add
                    </Button>
                  )}
                </div>
              </div>
            </Box>
          </Grid>
          // </>
          // )
        ))}
      </Grid>

      <Box className="flexGrid grid33">
        <Typography className="orderText">
          {" "}
          {totalitemcount} item(s)Total
        </Typography>
        <Typography></Typography>
        <Typography className="orderText colorBlue">
          {Dynamiccuurency}
          {parseFloat(totalamount).toFixed(2)}
          {/* {totalamount} */}
        </Typography>
      </Box>

      <Box className="flexGrid grid33">
        <Typography className="orderText">Service Tax</Typography>
        <Typography></Typography>
        <Typography className="orderText colorBlue">
          {Dynamiccuurency}
          {parseFloat(CommissionServiceTax).toFixed(2)}

          {/* {CommissionServiceTax} */}
        </Typography>
      </Box>

      <Box className="flexGrid grid33">
        <Typography className="orderText">Grand Total</Typography>
        <Typography></Typography>
        <Typography className="orderText colorBlue">
          {Dynamiccuurency}
          {parseFloat(GrandTotalAmount).toFixed(2)}
          {/* {GrandTotalAmount} */}
        </Typography>
      </Box>

      <div className="hLine"></div>

      <Box
        sx={{
          textAlign: "center",
          padding: "24px 0px 0px 0px",
          marginBottom: "20px",
        }}
      >
        <Button
          type="button"
          className="proceedBtnPay"
          onClick={(e) => Orderconfirm(e)}
        >
          Order
        </Button>
      </Box>

      {/* Modal for VendorIdentify */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="ModalClass"
      >
        <Box sx={style} className="ModalClassBox">
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{ textAlign: "right", padding: "16px", mt: "2" }}
              className="ModalTopGrid"
            >
              <CloseIcon className="closeIconOffer" onClick={handleClose} />
              <Typography
                id="modal-modal-description"
                className="ModalPhomolong"
                sx={{ textAlign: "center" }}
              >
                Alert{" "}
              </Typography>
              <Typography
                sx={{
                  margin: "15px 0px",
                  textAlign: "center",
                }}
              >
                Your cart contains food(s) from {localVendorName} .Do you want
                to removed all the selection and add food(s) from{" "}
                {PreviousVendorName} ?
              </Typography>

              <Typography
                sx={{
                  marginBottom: "10px",
                }}
              >
                <Box className="flexGrid" justifyContent="center">
                  <Button class="btn bookTicketBtn" onClick={handleClose}>
                    No
                  </Button>
                  <Button class="btn viewBtn" onClick={(e) => HandleReplace()}>
                    Replace
                  </Button>
                </Box>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      {/* Modal for OrderCheck */}
      <Modal
        open={open1}
        onClose={handleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="ModalClass"
      >
        <Box sx={style} className="ModalClassBox">
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{ textAlign: "right", padding: "16px", mt: "2" }}
              className="ModalTopGrid"
            >
              <CloseIcon className="closeIconOffer" onClick={handleClose1} />
              <Typography
                id="modal-modal-description"
                className="ModalPhomolong"
                sx={{ textAlign: "center" }}
              >
                Alert{" "}
              </Typography>
              <Typography
                sx={{
                  margin: "15px 0px",
                  textAlign: "center",
                }}
              >
                Do you Want to order seleted food(s)
              </Typography>

              <Typography
                sx={{
                  marginBottom: "10px",
                }}
              >
                <Box className="flexGrid" justifyContent="center">
                  <Button class="btn bookTicketBtn" onClick={handleClose1}>
                    Cancel
                  </Button>
                  <Button class="btn viewBtn" onClick={(e) => foodorder(e)}>
                    Continue
                  </Button>
                </Box>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Box>
  );
};

export default SuperMart;
