import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/system";
import { Grid, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import getSymbolFromCurrency from "currency-symbol-map";
import "./style.css";
import { NotificationManager } from "react-notifications";
import "fix-date";
import http from "../../Services/http";
import { SpinnerCircular } from "spinners-react";
// import CpyToClipBoard from "../CommonPopus/CpyToClipBoard";
// import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import {useTranslation} from 'react-i18next'
// POP-UP
import ModalPopup from "../ModalPopup";
import MapModalPopup from "../MapModalPopup";
import SocialSharePopup from "../SocialSharePopup";
// import moment from "moment";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
   
  return <MuiAlert elevation={6} ref={ref}  {...props} variant="filled" />;

});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "95%",
  maxWidth: 600,
  bgcolor: "#FFFFFF",
  boxShadow: 24,
  overflow: "auto",
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
}));

const Banner = (eventdetailspage1, Favourite) => {
  const {t} = useTranslation('common')
  const location = useLocation();
  const navigate = useNavigate();
  const handleviewButton = (eventdetailspage1) => {
    console.log(eventdetailspage1)
    navigate(
      `/bookticket/${eventdetailspage1.eventdetailspage1.id}` +
        "?flag=" +
        JSON.parse(localStorage.getItem("autoCountry")).sortname,
      {
        state: { eventdetailspage1 },
      }
    );
  };
  const [CCBoardPopUp, setCCBoardPopUp] = useState(false);
  const handleCloseCpy = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setCCBoardPopUp(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseCpy}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const [openLogin, setOpenLogin] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setOpenLogin(false);
  };

  const [open1, setOpen1] = React.useState(false);

  const handleClose1 = () => setOpen1(false);

  const [open2, setOpen2] = React.useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);

  const [open4, setOpen4] = React.useState(false);
  const handleOpen4 = () => setOpen4(true);
  const handleClose4 = () => setOpen4(false);

  const [allEventLocationAndDateTime1, setallEventLocationAndDateTime1] =
    useState([]);

  const eventdetailspage1ByEvent = eventdetailspage1?.eventdetailspage1;
  const [eventdetailspage, seteventdetailspage] = useState(
    eventdetailspage1ByEvent
  );

  const currencysymbol = getSymbolFromCurrency(
    eventdetailspage1ByEvent.currency
  );

  const registerinfo = eventdetailspage1ByEvent?.talent_register_flag;
  const Talentreferenece = eventdetailspage1ByEvent?.talent_reference_number;

  const [disabled, setDisabled] = useState(false);

  const CopiedToclipBoardPopUp = (data) => {
    setOpen(data);
    setCCBoardPopUp(true);
  };

  const checkLoggedIn2 = (e, id) => {
    if (!localStorage.getItem("token")) {
      setOpenLogin(true);
    } else {
      let postData = {
        eventid: params.id,
      };
      setLoading(true);
      http("coming_soon/event_notify", "POST", postData).then(({ data }) => {
        //  window.location.reload()
        // setNotifyValue(data.data.notify);
        // const notifyMe = data.data.notify;
        if (data.data.notify === 1) {
          setDisabled(true);
          setOpensnack(true);
          setAlertmssg(data.msg);
          setOpenstatus(true);
          // NotificationManager.success(data.msg);
          setLoading(false);
          //  setButtonText("Notified");
        } else {
          // NotificationManager.error(data.msg);
          // setButtonText("Notify MeNew");
          // setDisabled(false);
          setAlertmssg(data.msg);
          setOpenstatus(false);
        }
      });
    }
  };

  const [opensnack, setOpensnack] = React.useState(false);
  const [openstatus, setOpenstatus] = React.useState(false);
  const [alertmssg, setAlertmssg] = React.useState("");
  const handleClosesnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpensnack(false);
  };

  useEffect(() => {
    seteventdetailspage(eventdetailspage1ByEvent);
    setallEventLocationAndDateTime1(
      eventdetailspage1?.eventdetailspage1?.allEventLocationAndDateTime
    );
    if (eventdetailspage.notify_status === 1) {
      // setButtonText("Notified1vivek");
      setDisabled(true);
    } else {
      // setButtonText("Notify Mevivek");
      setDisabled(false);
    }
  }, [eventdetailspage1]);

  const checkLoggedIn = (e, id) => {
    if (!localStorage.getItem("token")) {
      setOpenLogin(true);
    } else {
      
      // if (eventdetailspage1.eventdetailspage1.seat_io === '1') {
      //   NotificationManager.error(
      //     "Sorry! This event having FLOOR PLANS for choosing sheet number but right now website not support this feature."
      //   );
      // } else {
        console.log(eventdetailspage1)
      navigate(`/bookticket/${eventdetailspage1.eventdetailspage1.id}`, {
        state: { eventdetailspage1 },
      });
    }
  };
  // };

  const checkLoggedIn1 = (e, id, event_name) => {
    if (!localStorage.getItem("token")) {
      setOpenLogin(true);
    } else {
      let EventName = event_name.replaceAll(/[^a-zA-Z0-9 ]/g, "");
      navigate(`/contestantsForm/${EventName.replaceAll(" ", "-")}/` + id);
    }
  };

  /**get date event date format by date  */
  const dateFormated = (date) => {
    let dateNew = new Date(date);

    let day = dateNew.toLocaleString("en-us", { weekday: "short" });
    let newdate = new Date(date).getDate();
    let month = dateNew.toLocaleString("default", { month: "short" });
    return day + "," + newdate + " " + month;
  };
const FreedateFormated = (startdate,enddate) => {
    let dateNew = new Date(startdate);

    let day = dateNew.toLocaleString("en-us", { weekday: "short" });
    let newdate = new Date(startdate).getDate();
    let month = dateNew.toLocaleString("default", { month: "short" });
    let time = dateNew.toLocaleString("en-us", { hour: "2-digit", minute: "2-digit"});
   

    let dateNewEnd = new Date(enddate);

    let dayend = dateNewEnd.toLocaleString("en-us", { weekday: "short" });
    let newdateend = new Date(enddate).getDate();
    let monthend = dateNewEnd.toLocaleString("default", { month: "short" });
    let timeend = dateNewEnd.toLocaleString("en-us", { hour: "2-digit", minute: "2-digit"});

    return day + "," + newdate + " " + month+ " " + time + " - " + dayend + "," + newdateend + " " + monthend+ " " + timeend;
  };

  const [Fav, setFav] = useState(eventdetailspage1ByEvent?.favourite);

  const params = useParams();
  // Favourites Functionality
  const checkLoggedIn3 = (e, params) => {
    if (!localStorage.getItem("token")) {
      setOpenLogin(true);
    } else {
      Favourites();
    }
  };
  const Favourites = () => {
    let postData = {
      eventid: params.id,
    };
    http("events/favourite_event", "POST", postData).then((res) => {
      const Favdata = res.data.msg ?? [];
      if (
        res.data.msg === "success! event is added in your favourite section"
      ) {
        setFav(1);
        NotificationManager.success(res.data.msg, "", 800);
      } else {
        setFav(0);
        NotificationManager.success(
          "event is removed in your favourite section",
          "",
          800
        );
      }
      Favourite();
    });
  };
  // console.log("rwerewrww"+eventdetailspage1ByEvent.picture_file_validation);
localStorage.setItem("picture_file_validation",eventdetailspage1ByEvent.picture_file_validation); 
localStorage.setItem("video_file_validation",eventdetailspage1ByEvent.video_file_validation); 
localStorage.setItem("audio_file_validation",eventdetailspage1ByEvent.audio_file_validation); 

 return (
    <div className="eventDetailsContainer">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="ModalClass"
      >
        <Box sx={style} className="ModalClassBox">
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{ textAlign: "right", padding: "0px 16px" }}
              className="ModalTopGrid"
            >
              <CloseIcon className="closeIconOffer" onClick={handleClose} />
              <Typography
                id="modal-modal-description"
                className="ModalPhomolong"
                sx={{ textAlign: "center" }}
              >
                Share with Social Media
              </Typography>
              <SocialSharePopup
                ShareUrl={location.pathname + location.search}
                CopiedToclipBoardPopUp={CopiedToclipBoardPopUp}
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>

      {/* google map */}

      <Modal
        open={open2}
        onClose={handleClose2}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="ModalClass"
      >
        <Box sx={style} className="ModalClassBox">
          <Grid container className="modal_boxFV modal_boxFV-overflow">
            <Grid
              item
              xs={12}
              sx={{
                padding: "16px",
                mt: "2",
                display: "flex",
                alignItems: "flex-start",
              }}
              className="ModalTopGrid"
            >
              <Typography
                id="modal-modal-description"
                className="ModalPhomolong"
                sx={{ marginTop: "-30px" }}
              >
                {/* More Venue */}
                {eventdetailspage1ByEvent.event_name}{" "}
              </Typography>
              <CloseIcon className="closeIconOffer" onClick={handleClose2} />
            </Grid>

            {allEventLocationAndDateTime1?.length > 0 ? (
              allEventLocationAndDateTime1.map((res, index) => (
                <Box key={index} className="map_boxView">
                  <Grid
                    item
                    xs={12}
                    sx={{ mb: "20px" }}
                    className="borderBoxNone"
                  >
                    <Accordion className="accordianFirst">
                      <AccordionSummary
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className="ModAccordionSummaryBg"
                        backgroundColor="#F4FBFF"
                      >
                        <Box className="Map_boxW_100">
                          <Box sx={{ padding: "0px 10px" }}>
                            <Typography
                              key={index}
                              className="ModAccordTypoDt-Banner"
                            >
                              {res.locationDateRange}
                            </Typography>
                          </Box>

                          <Typography className="ModAccordTypoWt"></Typography>

                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              flexWrap: "wrap",
                              padding: "0px 10px",
                            }}
                          >
                            <Typography className="ModAccordTypoAdd">
                              {" "}
                              {res.venue_name}, {res.venue_address}{" "}
                              {res.city_name}, {res.province_name}{" "}
                            </Typography>
                            <Typography
                              sx={{ marginLeft: "auto" }}
                              className="ModAccordTm"
                            >
                              <MapModalPopup
                                customData={res}
                                handleClick={handleClose1}
                              />
                            </Typography>
                          </Box>
                        </Box>
                      </AccordionSummary>
                    </Accordion>
                  </Grid>
                </Box>
              ))
            ) : (
              <Typography
                sx={{ textAlign: "center", padding: "16px", mt: "2" }}
              >
                {t('There_are_no_More_Venue_available_Currently')}
              </Typography>
            )}
          </Grid>
        </Box>
      </Modal>

      {/*  */}

      <Box className="mainContainer">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={5} md={4} lg={3}>
            {eventdetailspage1ByEvent.eventExpireFlag === 1 ? (
              <img
                src="assets/icons/expired-event.png"
                className="expired-icon-in-details-page"
                alt="expired-event"
              />
            ) : (
              <></>
            )}
            <img
              alt=""
              src={
                eventdetailspage1ByEvent.event_thumbnail
                  ? eventdetailspage1ByEvent.event_thumbnail
                  : "assets/images/Rectangle.png"
              }
              style={{ borderRadius: "9px", width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={7} md={8} lg={9}>
            <Item
              sx={{
                boxShadow: "none",
                backgroundColor: "transparent",
                textAlign: "left",
                fontFamily: "Lato",
              }}
              className="paddingL42"
            >
              <Box
                className="flexGrid"
                alignItems="center"
                flexWrap="wrap"
                sx={{}}
              >
                <span className="eventTitleBtn">
                  {eventdetailspage1ByEvent.event_type_name
                    ? t(eventdetailspage1ByEvent.event_type_name)
                    : eventdetailspage1ByEvent.ts === "cs"
                    ? t("Coming_soon")
                    : eventdetailspage1ByEvent.event_type}
                </span>

                <Item
                  sx={{
                    boxShadow: "none",
                    backgroundColor: "transparent",
                    textAlign: "center",
                    fontFamily: "Lato",
                    padding: "0px",
                  }}
                  className="MobIconsLikeShre"
                >
                  <Typography className="flexGrid">
                    {/* {!eventdetailspage1ByEvent.ts ? (
                      <FavoriteBorderRoundedIcon
                        className={Fav === 0 ? "thumbGreyClr" : "thumbRedClr"}
                        sx={{
                          width: "28px",
                          height: "28px",
                          cursor: "pointer",
                          color: "#FFFFFF",
                          borderRadius: "50px",
                          padding: "5px",
                          cursor: "pointer",
                        }}
                        onClick={(e) => checkLoggedIn3(e)}
                      />
                    ) : (
                      ""
                    )} */}

                    {!eventdetailspage1ByEvent.ts ? (
                      <>
                        {Fav === 1 ? (
                          <FavoriteIcon
                            onClick={(e) => checkLoggedIn3(e)}
                            className=" fav_Icon AbsoluteClass"
                            style={{
                              cursor: "pointer",
                            }}
                          />
                        ) : (
                          <FavoriteBorderIcon
                            style={{
                              cursor: "pointer",
                              color: "#000",
                            }}
                            onClick={(e) => checkLoggedIn3(e)}
                          />
                        )}
                      </>
                    ) : (
                      " "
                    )}

                    <Typography className="shareFlex" onClick={handleOpen}>
                      <ShareOutlinedIcon
                        sx={{ cursor: "pointer", marginRight: "12px" }}
                      />{" "}
                      {t('Share')}
                    </Typography>
                  </Typography>
                </Item>
              </Box>
              
              <Typography className="eventNameText">
              {eventdetailspage.event_name
                  ? eventdetailspage.event_name
                  : eventdetailspage.event_name}
              </Typography>
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "22px",
                }}
              >
                <img src="assets/images/calander.png" alt="" />{" "}
                <span
                  className="addressText"
                  style={{
                    color: "#686868",
                    fontSize: "24px",
                    fontWeight: 400,
                    marginLeft: "23px",
                  }}
                >
                  {eventdetailspage1ByEvent.ts !== "ts" &&
                  eventdetailspage1ByEvent.ts !== "cs"
                    ? eventdetailspage1ByEvent.ts === "PrivateEvent"
                      ? eventdetailspage1ByEvent.event_date
                        ? dateFormated(eventdetailspage1ByEvent.event_date) +
                          " " +
                          eventdetailspage1ByEvent.event_time
                        : ""
                      : eventdetailspage1ByEvent.created_on &&
                        eventdetailspage1ByEvent.date_range.length > "10"
                      ? dateFormated(
                          eventdetailspage1ByEvent.date_range.slice(0, 10)
                        ) +
                        " - " +
                        dateFormated(
                          eventdetailspage1ByEvent.date_range.slice(11, 21)
                        )
                      : dateFormated(eventdetailspage1ByEvent.event_date) +
                        " " +
                        eventdetailspage1ByEvent.event_time
                    : eventdetailspage1ByEvent.event_start_date &&
                      eventdetailspage1ByEvent.event_end_date
                    ? dateFormated(eventdetailspage1ByEvent.event_start_date) +
                      " to " +
                      dateFormated(eventdetailspage1ByEvent.event_end_date)
                    : eventdetailspage1ByEvent.ts == "cs"
                    ? "Tentative date : " +
                      dateFormated(eventdetailspage1ByEvent.tentative_date)
                    : ""}
                </span>
              </Typography>
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <img src="assets/images/location.png" alt="" />{" "}
                <span
                  className="addressText"
                  style={{
                    color: "#686868",
                    fontSize: "24px",
                    fontWeight: 400,
                    marginLeft: "27px",
                  }}
                >
                  {eventdetailspage1ByEvent.ts == "ts"
                    ? eventdetailspage1ByEvent.city_name +
                      "," +
                      eventdetailspage1ByEvent.state_name +
                      "," +
                      eventdetailspage1ByEvent.country_name
                    : eventdetailspage1ByEvent.ts == "cs"
                    ? eventdetailspage1ByEvent.state_name
                    : eventdetailspage1ByEvent.ts == "PrivateEvent"
                    ? eventdetailspage1ByEvent.venue_address
                    : eventdetailspage1ByEvent.venue_address}
                </span>
              </Typography>

              {!eventdetailspage1ByEvent.ts ? (
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    color: "#0CA8F6",
                    fontSize: "18px",
                    fontWeight: 400,
                    marginBottom: "10px",
                  }}
                >
                  <span
                    style={{ marginLeft: "48px", cursor: "pointer" }}
                    onClick={handleOpen2}
                  >
                    {" "}
                    {t('View_Other_Locations_Schedule')}{" "}
                  </span>
                  <KeyboardArrowRightIcon />
                </Typography>
              ) : (
                ""
              )}
{/*////////////////////////////////////////////Maadima ////////////////////////////////////////////////////////////*/}
     {eventdetailspage1ByEvent.allow_freeTicket_text ? (
             <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    color: "rgb(104, 104, 104)",
                    fontSize: "18px",
                    fontWeight: 400,
                    marginBottom: "10px",
                  }}
                >
                  <span
                    style={{ marginLeft: "-10px", cursor: "pointer" }}
                  ><img src="assets/images/freeTicket.png" style={{ fontSize: "18px" }} alt=""/>    {t('Book_within_this_timeframe_for_a_Free_Ticket')} - { FreedateFormated(eventdetailspage1ByEvent.free_booking_start,eventdetailspage1ByEvent.free_booking_end)}
                  </span>
                  
                </Typography>
                ) : (
                ""
              )}
{/*////////////////////////////////////////////Maadima ////////////////////////////////////////////////////////////*/}

              {!eventdetailspage1ByEvent.ts ? (
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <span
                    style={{
                      color: "#060251",
                      fontSize: "24px",
                      fontWeight: 700,
                    }}
                  >
                    {" "}
                    {t('Price')}:
                  </span>
                  <span
                    style={{
                      color: "#0CA8F6",
                      fontSize: "24px",
                      fontWeight: 700,
                      marginLeft: "5px",
                    }}
                  >
                    {" "}
                    {/* {eventdetailspage1ByEvent.currency} */}
                    {currencysymbol} {eventdetailspage1ByEvent.minprice} {t('Onwards')}
                  </span>
                </Typography>
              ) : (
                ""
              )}

              {eventdetailspage1ByEvent.ts === "PrivateEvent" ? (
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <span
                    style={{
                      color: "#060251",
                      fontSize: "24px",
                      fontWeight: 700,
                    }}
                  >
                    {" "}
                    Price:
                  </span>
                  <span
                    style={{
                      color: "#0CA8F6",
                      fontSize: "24px",
                      fontWeight: 700,
                      marginLeft: "5px",
                    }}
                  >
                    {" "}
                    {/* {eventdetailspage1ByEvent.currency} */}
                    {currencysymbol} {eventdetailspage1ByEvent.minprice} {t('Onwards')}
                  </span>
                </Typography>
              ) : (
                ""
              )}

              {eventdetailspage1ByEvent.ts === "ts" ? (
                <Grid item xs={12} sm={12} md={6} lg={5}>
                  {registerinfo === 0 ? (
                    <button
                      className="viewBtn"
                      onClick={(e) =>
                        checkLoggedIn1(
                          e,
                          eventdetailspage1ByEvent.id,
                          eventdetailspage1ByEvent.event_name
                        )
                      }
                    >
                      Register
                    </button>
                  ) : (
                    <button
                      className="viewBtn1"
                      style={{ width: "630px", padding: "15px 10px" }}
                    >
                      {t('You_have_already_registerd_Application_Number')} :{" "}
                      {Talentreferenece}
                    </button>
                  )}
                </Grid>
              ) : (
                ""
              )}

              {eventdetailspage.ts === "cs" ? (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={5}
                  sx={{ margin: "10px" }}
                >
                  <Stack spacing={2} sx={{ width: "100%" }}>
                    <button
                      disabled={disabled}
                      className={disabled ? "NewviewBtn1" : "viewBtn"}
                      onClick={(e) => checkLoggedIn2(e, eventdetailspage.id)}
                    >
                      {loading ? (
                        <SpinnerCircular
                          style={{ width: "1.2rem", height: "1.2rem" }}
                          color="light"
                        />
                      ) : (
                        <> {disabled ? "Notified" : "NotifyMe"} </>
                      )}
                    </button>

                    <Snackbar
                      open={opensnack}
                      autoHideDuration={2000}
                      onClose={handleClosesnack}
                      anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    >
                      <Alert
                        onClose={handleClosesnack}
                        severity={openstatus ? "success" : "error"}
                        sx={{ width: "100%" }}
                      >
                        {alertmssg}
                      </Alert>
                    </Snackbar>

                    {openLogin ? (
                      <ModalPopup
                        handleOpen={openLogin}
                        handleClick={handleClose}
                      />
                    ) : (
                      ""
                    )}
                  </Stack>
                </Grid>
              ) : (
                ""
              )}

              {!eventdetailspage1ByEvent.ts ? (
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "10px",
                    marginTop: "25px",
                    flexWrap: "wrap",
                  }}
                >
                  {eventdetailspage1.eventdetailspage1.stop_ticket_selling ===
                  "1" ? (
                    <Typography
                      disabled={disabled}
                      // className={disabled ? "NewviewBtn1" : "viewBtn"}
                      className="NewviewBtn1"
                    >
                      {" "}
                      {t('Booking_Closed')}
                    </Typography>
                  ) : eventdetailspage1.eventdetailspage1.eventExpireFlag ===
                    0 ? (
                    <Typography
                      className="bookTicketBtn"
                      onClick={(e) => checkLoggedIn(e, 1)}
                    >
                      <img src="assets/images/ticket.png" alt=""/>{" "}
                      <span
                        style={{ paddingLeft: "10px" }}
                        //  onClick={() => {
                        //     handleviewButton(eventdetailspage1);
                        //   }}
                      >
                        {" "}
                        {t('Book_Tickets')}
                      </span>
                    </Typography>
                  ) : (
                    <Typography
                      disabled={disabled}
                      // className={disabled ? "NewviewBtn1" : "viewBtn"}
                      className="NewviewBtn1"
                    >
                      {" "}
                      {t('Event_Expired')}
                    </Typography>
                  )}

                  <MapModalPopup
                    onClick={handleOpen4}
                    open={open4}
                    customData={eventdetailspage1ByEvent}
                    handleClick={handleClose4}
                    style={{ marginLeft: "15px" }}
                  ></MapModalPopup>
                </Typography>
              ) : eventdetailspage1ByEvent.ts == "PrivateEvent" ? (
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "10px",
                    marginTop: "25px",
                  }}
                >
                  <Typography
                    sx={{
                      backgroundColor: "#0CA8F6",
                      borderRadius: "6px",
                      display: "flex",
                      alignItems: "center",
                      padding: "7px 20px",
                      cursor: "pointer",
                    }}
                  >
                    <img src="assets/images/ticket.png" alt=""></img>{" "}
                    <span
                      style={{
                        color: "#FFFFFF",
                        fontSize: "20px",
                        fontWeight: 500,
                        paddingLeft: "10px",
                      }}
                      onClick={() => {
                        handleviewButton(eventdetailspage1);
                      }}
                    >
                      {" "}
                      {t('Accept')}
                    </span>
                  </Typography>

                  <MapModalPopup
                    onClick={handleOpen4}
                    open={open4}
                    customData={eventdetailspage1ByEvent}
                    handleClick={handleClose4}
                    style={{ marginLeft: "15px" }}
                  ></MapModalPopup>
                </Typography>
              ) : (
                ""
              )}
            </Item>
          </Grid>

          {openLogin ? (
            <ModalPopup handleOpen={openLogin} handleClick={handleClose} />
          ) : (
            ""
          )}
        </Grid>

        {/* copy to clipboard pop up */}
        <Snackbar
          open={CCBoardPopUp}
          autoHideDuration={1000}
          onClose={handleCloseCpy}
          message="Copied to Clipboard"
          action={action}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        />
      </Box>
    </div>
  );
};
export default Banner;
