import React, { useEffect } from 'react';
import { styled, alpha } from '@mui/material/styles';
import AutoComplete from '../CommonModules/input-search/AutoComplete'
import "./style.css";


const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: '100%',
    },
}));

export default function SearchBar(props) {
     
    const list = props.listData ?? [];
    const data = props.data ?? ""
    
     
    // debugger

    useEffect(() => {
        // getLocations()
    }, [])

    return (
        <Search className={!props.popupSearch ? 'searchCity' : 'searchInsidePopupCity '}> 
            <AutoComplete   list={list} data={data}/>
        </Search>
    );
}
