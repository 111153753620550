import { Box } from "@mui/system";
import EventCard from "../EventCard";
import { Divider, Grid, Paper, styled } from "@mui/material";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import React, { useEffect, useState } from "react";
import http from "../../Services/http";
import { ManagePageReloadState } from "../../Services/ManagePageReloadState";
import {useTranslation} from 'react-i18next'
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(0),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Recommended = ({eventID}) => {
  const {t} = useTranslation('common')
  // const [getchildata, setGetchildata] = useState(false) 
  
  const [eventsbyid, seteventsbyid] = useState([]); 
// First parent function data pass 
  const getdatafromchild = (data) => { 
    // setGetchildata(data)
    data ? HomepageComingevent() : <></>
  }
    
  const HomepageComingevent = () => {
    const HomepageeventsbyidData = {
      event_id:eventID ,
    };
    http("events/getSimilarEvents", "POST", HomepageeventsbyidData).then(
      (res) => { 
        
        const data = res.data.data ?? []; 
        seteventsbyid(data ?? []);
      }
    );
  };

  // const handleonviewallClick = (ele) => { 
  //   navigate(
  //     `/ViewAllSubcategory/${ele.categoryId}/${ "?flag="+JSON.parse(localStorage.getItem("autoCountry")).sortname }`,
  //     { state: { ele } }
  //   );
  // };
 
  useEffect(() => {
    HomepageComingevent(); 
    ManagePageReloadState.getPageReloadState().subscribe(res =>{
      if(res.state){
        HomepageComingevent() 
      }
      
    }) 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      {eventsbyid.length > 0 && (
        <Box className="mainContainer">
           <Divider sx={{mb:4 , mt:4}}/>
            <Box  >
              <Grid container spacing={2}>
                <Grid item md={6} xs={8} lg={6}>
                  <Item
                    key={"recommded"}
                    sx={{
                      textAlign: "left",
                      fontWeight: "700",
                      fontFamily: "Lato",
                      fontSize: "22px",
                      color: "#2C2C2C",
                       
                    }}
                    className="boxShadowNone"
                  > 
                  {t('You_might_also_like')}
                  </Item>
                </Grid>

                {eventsbyid.events?.length > 5 ? (
                  <Grid item md={6} xs={4} lg={6}>
                    <Item
                      Item
                      sx={{ textAlign: "right" }}
                      className="boxShadowNone"
                    >
                      <span
                        style={{
                          border: "1px solid #0CA8F6",
                          display: "inline-flex",
                          color: "#0CA8F6",
                          borderRadius: "6px",
                        }}
                      >
                        <button
                          style={{
                            cursor: "pointer",
                            border: "none",
                            background: "transparent",
                            color: "#0CA8F6",
                            fontWeight: "400",
                            fontSize: "14px",
                          }}
                          className='mobViewAllBtn'
                          // onClick={() => handleonviewallClick(data)}
                        >
                          {t('view_all')}{" "}
                        </button>
                        <KeyboardArrowRightOutlinedIcon />
                      </span>
                    </Item>
                  </Grid>
                ) : (
                  ""
                )}
              </Grid>
 
            </Box>
          
          <EventCard recommended = {true} eventData={eventsbyid} getdatafromchild={getdatafromchild}  HomepageComingevent={HomepageComingevent} />
        </Box>
      )}
    </div>
  );
};

export default Recommended;
